(function() {
  "use strict";
  app.directive('webinarSlides', WebinarSlidesDirective);
  function WebinarSlidesDirective() {
    return {
      templateUrl: '/app/partials/slides.html',
      controllerAs: 'webinarSlides',
      controller: WebinarSlidesController,
      scope: {}
    };
  }

  WebinarSlidesController.$inject = ['$scope','WordpressService'];
  function WebinarSlidesController($scope, WordpressService) {
    var vm = this;
    vm.slides = [];

    vm.init = function() {
      WordpressService.getSlides().then(function(data) {
        vm.slides = data.slides;
      });
    }

    vm.imageChooser = function(slide, sizes) {
      return WordpressService.imageChooser(slide, sizes);
    }

    vm.init();
  }
})();
