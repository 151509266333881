(function () {
  'use strict';
  app.factory('CompBuilderService', CompBuilderService);

  CompBuilderService.$inject = ['$http', '$q', 'apiSettings', 'SearchService'];

  function CompBuilderService($http, $q, apiSettings, SearchService) {
    var baseUrl = apiSettings.url + '/compbuilder';
    return {
      getData: getData,
      deleteAggregate: deleteAggregate,
      isAggregateNameUnique: isAggregateNameUnique,
      validate: validate,
      findCompSet: findCompSet,
      createAggregate: createAggregate,
      getPeerGroup: getPeerGroup,
      updatePeerGroup: updatePeerGroup
    }

    function getData(showSharedAggregate) {
      return $http.get(baseUrl + '?showSharedAggregate=' + showSharedAggregate)
        .then(function (rsp) {
          return rsp.data;
        })
        .catch(function (error) {
          console.error(error);
          return $q.reject(error);
        });
    }

    function findCompSet(compSetSearchCriteria) {
      return $http.post(baseUrl + '/findCompSet', compSetSearchCriteria)
        .then(function (compSetResponse) {
          return compSetResponse.data;
        })
        .catch(function (error) {
          console.log(error);
          return $q.reject(error);
        });
    }
    
    function validate(geocodedPeerGroups) {
      return $http.post(baseUrl + '/validate', geocodedPeerGroups)
        .then(function (validationResponse) {
          return validationResponse.data;
        })
        .catch(function (error) {
          console.log(error);
          return $q.reject(error);
        });
    }

    function isAggregateNameUnique(aggregateName) {
      return $http.get(baseUrl + '/isAggregateNameUnique?name=' + encodeURIComponent(aggregateName))
        .then(function (rsp) {
          return rsp.data;
        })
        .catch(function (err) {
          console.error(err);
          return $q.reject({data: false});
        });
    }

    function getPeerGroup(guid) {
      return $http.get(baseUrl + '/peergroup/' + guid)
        .then(function (rsp) {
          return rsp.data;
        })
        .then(function (peerGroupEditModel) {
          peerGroupEditModel.searchCriteria = rebuildSearchCriteriaFromApiResponse(peerGroupEditModel.searchCriteria);
          return peerGroupEditModel;
        })
        .catch(function (err) {
          console.error(err);
          return $q.reject({ data: false });
        });
    }

    function updatePeerGroup(guid, updatePeerGroupModel) {
      return $http.post(baseUrl + '/peergroup/' + guid, updatePeerGroupModel)
        .then(function (compSetResponse) {
          return compSetResponse.data;
        })
        .catch(function (error) {
          console.log(error);
          return $q.reject(error);
        });
    }

    function rebuildSearchCriteriaFromApiResponse(apiSearchCriteriaResponse) {
      var radiusOptions = SearchService.getRadius();
      if (apiSearchCriteriaResponse.isIncludeCCRC !== null && apiSearchCriteriaResponse.isIncludeCCRC !== undefined) {
        apiSearchCriteriaResponse.includeCCRC = apiSearchCriteriaResponse.isIncludeCCRC;
      }
      if (apiSearchCriteriaResponse.tradeArea.type === undefined) {
        apiSearchCriteriaResponse.tradeArea.type = apiSearchCriteriaResponse.tradeArea.radius != null ? 'radius' : 'driveTime';
        if (apiSearchCriteriaResponse.tradeArea.type === 'radius') {
          //custom radius handling
          var matchingRadius = radiusOptions.map(function (radius) { return radius.id; }).indexOf(apiSearchCriteriaResponse.tradeArea.radius);
          var hasCustomRadius = matchingRadius === -1;
          apiSearchCriteriaResponse.tradeArea.customRadius = hasCustomRadius ? apiSearchCriteriaResponse.tradeArea.radius : null;
          apiSearchCriteriaResponse.tradeArea.radius1 = hasCustomRadius ? -1 : radiusOptions[matchingRadius].id;
        }
      }
      return apiSearchCriteriaResponse;
    }

    function createAggregate(aggregateInfo) {
      return $http.post(baseUrl + '/createAggregate', aggregateInfo)
        .then(function (rsp) {
          console.log(rsp);
          return rsp.data;
        })
        .catch(function (err) {
          console.log(err);
          return $q.reject(err);
        });
    }

    function deleteAggregate(aggregateSavedQueryId){
      return $http.delete(baseUrl + '/aggregate/' + aggregateSavedQueryId)
        .then(function (rsp) {
          return rsp.data;
        })
        .catch(function (err) {
          console.error(err);
          return $q.reject(err);
        });
    }
  }
})();
