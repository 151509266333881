(function () {
  "use strict";

  //because this template needs to get passed to the bing API we can't use a regular directive template
  var template = '/app/partials/infobox.html';
  app.directive('nicInfoBox', infoBoxDirective);
  infoBoxDirective.$inject = ['$http', '$interpolate', 'AuditService', 'knownEventConstant', 'knownProductConstant']

  function infoBoxDirective($http, $interpolate, AuditService, knownEventConstant, knownProductConstant) {
    //get the infobox template once
    var htmlTemplateCompiled;
    $http.get(template)
      .then(function (rsp) {
        htmlTemplateCompiled = $interpolate(rsp.data); //prepare the angular document to binding
      });

    return {
      link: link,
      restrict: 'E',
      //scope: false,//works, but you have to match scope.foo to what ever is in the ng-repeat="foo in bar"
      scope: {
        pin: '=?',
        showPirLink: '<?',
        showAddToComps: '<?'
      },
      require: ['^bingMap']
    };

    function link(scope, element, attrs, ctrls) {
      ///scope section

      var closePinId = "#closePin-InfoBox";
      var propertyDetailsId = "#propertyDetails";
      var addToCompId = "#addToComp";

      scope.$watch('pin', updatePinChange);
      scope.$on('propertySelectionsChanged', propertySelectionsChanged);
      scope.$on("closePropertyPinInfobox", closeInfobox);
      scope.$on("infoBoxToggled", infoBoxToggled); //happens ANYtime any pin (property, hospital, site) is clicked
      scope.$on('$destroy', unregisterEventListeners);
      element.on('$destroy', unregisterEventListeners);
      //initalization section

      var visible = false;
      var addToCompChecked = false;
      var options = getDefaultOptions();
      if (scope.showPirLink == undefined) {
        scope.showPirLink = true;
      }
      if (scope.showAddToComps == undefined) {
        scope.showAddToComps = true;
      }

      //set the infobox
      var infobox = new Microsoft.Maps.Infobox(new Microsoft.Maps.Location(0, 0), options);
      infobox.setMap(ctrls[0].map);

      function getDefaultOptions() {
        return {
          visible: false,
          offset: new Microsoft.Maps.Point(-150, 50),
          width: 300
        };
      }

      function createInfoBoxHtml() {

        if (htmlTemplateCompiled && scope.pin) {
          //compile the html content that the info box will use
          var domTemplate = htmlTemplateCompiled(scope);

          var htmlFragment = $(document.createElement('div')).append(domTemplate);
          var htmlContentString = htmlFragment.html();

          return htmlContentString;
        }
      }

      function infoBoxToggled(event, clickedPin) {
        if (clickedPin.type != 'property') {
          // a diferent type of infobox is getting shown or hidden so just hide this one
          updateVisibility(false);
        } else {
          if (!scope.pin || scope.pin.id != clickedPin.id) {
            scope.pin = clickedPin.object;  // this will fire off the updatePinChange
            updateVisibility(true); 
          } else {
            if (scope.pin.timestamp != clickedPin.object.timestamp) {
              //same logical property but a different pin object so we need to wire up the events again
              scope.pin = clickedPin.object;  // this will fire off the updatePinChange
            }
            updateVisibility(!visible);
          }
        }
      }

      function setDOM(newValue) {
        var element = $(addToCompId);
        if (element) {
          element.prop("checked", newValue);
        }
        addToCompChecked = newValue;
      }

      function updateVisibility(newVisibility) {
        if (!newVisibility && !visible) {
          return;//no work necessary
        }
        visible = newVisibility;
        infobox.setOptions({
          visible: visible
        });

        if (!visible) {
          return;//rest of function has to do with positioning infobox, wiring in button click events, setting the checkbox's checked
        }

        var knownProduct = scope.pin.isTransactionSearch ? knownProductConstant.trans : knownProductConstant.local;

        var currentOffset = infobox.getOffset();
        var infoboxLocation = ctrls[0].map.tryLocationToPixel(infobox.getLocation(), Microsoft.Maps.PixelReference.control);
        var mapWidth = ctrls[0].map.getWidth();
        var directions = checkOverEdge(currentOffset.x, currentOffset.y, infobox, infoboxLocation, mapWidth);
        //does infobox need repositioned?
        if (currentOffset.x != directions.x || currentOffset.y != directions.y) {
          infobox.setOptions({
            offset: directions
          });
        }

        //set DOM before wiring in change event or else the wiring in will trigger the change event
        setDOM(scope.pin.selected);

        //add click event after re-positioning because re-positioning can re-move the click event.
        $(addToCompId).on("change", function() {
          scope.$apply(function () {
            var oldValue = addToCompChecked;
            addToCompChecked = !addToCompChecked;
            var newValue = addToCompChecked;  
            
            if (newValue) {
              AuditService.logEvent(knownEventConstant.selectProperty.id, knownEventConstant.selectProperty.message + " - Infobox - " + scope.pin.name,
                knownProduct, scope.pin.id);
            }
            //raise event so the local property controller can actually change the pin.selected value.  for some unknown reason the watches get out of sync if not
            scope.$emit("changePropertySelectedValue", { sourceProperty: scope.pin, oldValue: oldValue, newValue : newValue });
          });
        });

        $(closePinId).on("click", function() {
          updateVisibility(false);
        });

        $(propertyDetailsId).on("click", function() {
          scope.$emit("propertyDetailsInfoboxClick", { sourceProperty: scope.pin });
        });
      }

      function closeInfobox() {
        if(visible) {
          updateVisibility(false);
        }
      }

      function unRegisterDOMEvents() {
        $(addToCompId).off("change");
        $(closePinId).off("click");
        $(propertyDetailsId).off("click");
      }

      function unregisterEventListeners() {
        unRegisterDOMEvents();

        if (infobox) {
          infobox.setMap(null);
          infobox = null;
        }
      }

      function updatePinChange() {
        // try to move location
        unRegisterDOMEvents();

        var html = createInfoBoxHtml();
        if (!html) {
          infobox.setOptions({ visible: false });  // turn off if we can't create html
        } else {
          setDOM(scope.pin.selected);

          var currentOptions = infobox.getOptions();
          infobox.setOptions({ htmlContent: html });  
         
          updateLocation(scope.pin);  //move to new pin location

          // try to offset it correctly
          updateVisibility(currentOptions.visible);
        }
      }

      function updateLocation(pin) {
        if (pin.latitude && pin.longitude) {
          infobox.setOptions({ offset: getDefaultOptions().offset }); // put back to default offset
          infobox.setLocation(new Microsoft.Maps.Location(pin.latitude, pin.longitude));
        }
      }

      function propertySelectionsChanged(event, args) {
        //only update the checkbox if it is for the same property
        if (scope.pin && scope.pin.id == args.sourceProperty.id ){
          setDOM(args.sourceProperty.selected);
        }
      }

    } //end info box

    function checkOverEdge(offsetX, offsetY, infobox, infoboxLocation, mapWidth) {
      var directions = new Microsoft.Maps.Point(offsetX, offsetY);
      var infoboxAnchor = infobox.getAnchor();
      var height = infobox.getHeight();
      var offetDistance = directions.y;
      var distanceAbovePinNeeded = height + offetDistance;
      var distanceToTopEdge = infoboxLocation.y - distanceAbovePinNeeded;
      if (distanceToTopEdge < 0) {
        //console.log("Hanging over top by " + distanceToTopEdge + " pixels");
        directions.y = height * -1;
      }
      var distanceToLeftEdge = infoboxLocation.x + directions.x;
      if (distanceToLeftEdge < 0) {
        //console.log("Hanging over left by " + distanceToLeftEdge + " pixels");
        directions.x = infoboxLocation.x * -1; //flush against edge
        return directions;
      }
      var infoBoxWidth = infobox.getWidth();
      var pixelsToRightSideOfBox = infoboxLocation.x + directions.x + infoBoxWidth;

      var pixelsPastRightEdge = mapWidth - (infoboxLocation.x + directions.x + infoBoxWidth);
      if (pixelsPastRightEdge < 0) {
        //console.log("Hanging over right by " + pixelsPastRightEdge + " pixels");
        directions.x = mapWidth - infoboxLocation.x - infoBoxWidth;
      }
      return directions;
    }
  }
})();
