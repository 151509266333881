(function () {
  "use strict";

  app.factory("SubscriptionUsersService", SubscriptionUsersService);

  SubscriptionUsersService.$inject = ["$http", "$q", "$state", "apiSettings", "DateOffsetUtils"];

  function SubscriptionUsersService($http, $q, $state, apiSettings, DateOffsetUtils) {
    return {
      getSubscriptionContacts: getSubscriptionContacts,
      setAccountAdmin: setAccountAdmin,
      setDemographicUser: setDemographicUser,
      setAccountUserActiveStatus: setAccountUserActiveStatus,
      generateSubscriptionUsersExcel: generateSubscriptionUsersExcel,
      search: search,
      associateContacts: associateContacts,
      update: update,
      isDateTimewithinTimeout: isDateTimewithinTimeout
    }

    function isDateTimewithinTimeout(startDatewithOffset) {
      if (startDatewithOffset == null) return false;

      var now = new Date();
      var timezoneOffset = DateOffsetUtils.getTimezoneOffset(now);
      var nowWithOffset = DateOffsetUtils.setDateOffsetToDateTime(now, timezoneOffset);
      var twentyMinutesEarlier = DateOffsetUtils.setDateOffsetToDateTime(nowWithOffset, -20);

      return twentyMinutesEarlier < startDatewithOffset && nowWithOffset > startDatewithOffset ? true : false;
    }

    function getSubscriptionContacts(subscriptionId, hideDeactivatedUsers) {
      var defer = $q.defer();
      var appendUrl = "/subscriptions/GetContactsBySubscriptionID?subscriptionID=" + subscriptionId;
      if (!hideDeactivatedUsers) {
        appendUrl += "&ActiveOnly=0";
      }

      $http
        .get(apiSettings.url + appendUrl)
        .then(function (rsp) {
          defer.resolve(rsp.data);
        }, function (err) {
          defer.reject(err);
        });

      return defer.promise;
    };

  	function setAccountAdmin(subscriptionID, contactID, isPrimary) {
      var deferred = $q.defer();
      var uri = apiSettings.url + '/subscriptions/SetAccountAdmin?' +
        $.param({
          subscriptionID: subscriptionID,
          contactID: contactID,
          isPrimary: isPrimary
        });
      $http.post(uri)
        .then(function(rsp) {
          if(rsp.status == 200) {
            deferred.resolve(rsp.status);
          } else {
            deferred.reject();
          }
        }, function(err) {
          deferred.reject(err);
        });

      return deferred.promise;
    };

    function setDemographicUser(contactemail, demographicUserFlag) {
      var deferred = $q.defer();
      var promise;
      if(demographicUserFlag) {
        // insert new role
        var uri = apiSettings.url + "/Account/InsertRole/{userName}"
          .replace("{userName}", contactemail);
        promise = $http.post(uri, '"Demographic User"');
      } else {
        // remove from role
        var uri = apiSettings.url + "/Account/RemoveRole/{userName}"
          .replace("{userName}", contactemail);
        promise = $http({
          url: uri,
          method: 'DELETE',
          data: '"Demographic User"',
          headers: {
            "Content-Type": "application/json;charset=utf-8"
          } // header is required for this instance... DO NOT REMOVE
        });
      }
      promise
        .then(function(rsp) {
          if(rsp.status == 200) {
            deferred.resolve(rsp.status);
          } else {
            deferred.reject();
          }
        }, function(err) {
          deferred.reject(err);
        });

      return deferred.promise;
    };

    function setAccountUserActiveStatus(subscriptionID, sfcontactID, isActive) {
      var deferred = $q.defer();
      var uri = apiSettings.url + '/subscriptions/SetAccountUserActiveStatus';
      var dataToPass = {
        subscriptionID: subscriptionID,
        sfcontactID: sfcontactID,
        isActive: isActive,
        defaultURL: $state.href('home', {}, {
          absolute: true
        })
      };
      $http.put(uri, dataToPass)
        .then(function(rsp) {
          if(rsp.status == 200) {
            deferred.resolve(rsp.status);
          } else {
            deferred.reject();
          }
        }, function(err) {
          deferred.reject(err);
        });

      return deferred.promise;
    };

    function buildSubscriptionSort(sort) {
      return JSON.stringify({
        field: sort[0].field,
        dir: sort[0].dir
      });
    }

    function buildSubscriptionFilter(filters) {
      var filter = {
        firstName: null,
        lastName: null,
        title: null,
        email: null,
        isAccountAdmin: null,
        isPortfolioAdmin: null,
        isDemographicUser: null,
        lastLogin: null,
        isActive: null
      }
      angular.forEach(filters, function(filterValue) {
        switch (filterValue.field) {
          case "firstName":
            filter.firstName = filterValue.value;
            break;
          case "lastName":
            filter.lastName = filterValue.value;
            break;
          case "title":
            filter.title = filterValue.value;
            break;
          case "email":
            filter.email = filterValue.value;
            break;
          case "phone":
            filter.phone = filterValue.value;
            break;
          case "isPrimary":
            filter.isAccountAdmin = filterValue.value;
            break;
          case "isPortfolioAdmin":
            filter.isPortfolioAdmin = filterValue.value;
            break;
          case "isDemographicUser":
            filter.isDemographicUser = filterValue.value;
            break;
          case "lastLogin":
            filter.lastLogin = filterValue.value;
            break;
          case "active":
            filter.isActive = filterValue.value;
            break;
        }
      });
      return filter;
    }

    function generateSubscriptionUsersExcel(subscriptionId, title, filters, sort, hideDeactivated, date) {
      var deferred = $q.defer();
      var filter = filters.length > 0 ? buildSubscriptionFilter(filters) : {};
      var sortData = sort.length > 0 ? buildSubscriptionSort(sort) : {};
      var dateExportedOn = date;

      var uri = apiSettings.url + '/subscription/exports/contacts/'
        + subscriptionId + "?"
        + $.param({ date: dateExportedOn, dateOffset: DateOffsetUtils.getTimezoneOffset(dateExportedOn), title: title, sortData: sortData, hideDeactivated: hideDeactivated });

      $http
        .post(uri, filter, {
          responseType: 'arraybuffer'
        })
        .then(function(rsp) {
          if (rsp.data.byteLength === 0) {
            deferred.reject();
          } else {
            deferred.resolve(rsp.data);
          }
        }, function(err) {
          deferred.reject(err);
        });

      return deferred.promise;
    };

    function search(searchCriteria) {
      var defer = $q.defer();

      var uri = apiSettings.url + '/subscriptions/searchcontacts?timezoneOffset=' + DateOffsetUtils.getTimezoneOffset(new Date());
      $http
        .post(uri, searchCriteria)
        .then(function (rsp) {
          defer.resolve(rsp.data);
        }, function (err) {
          defer.reject(err);
        });

      return defer.promise;
    };


    function associateContacts(subscriptionId, contactsToAssociate) {
      var defer = $q.defer();

      var uri = apiSettings.url + '/subscriptions/associatecontacts?' +
        $.param({
          subscriptionID: subscriptionId,
          defaultURL: $state.href('home', {}, {
            absolute: true
          })
        });
      $http
        .post(uri, contactsToAssociate)
        .then(function (rsp) {
          defer.resolve(rsp.data);
        }, function (err) {
          defer.reject(err);
        });

      return defer.promise;
    };

    function update(data) {
      var defer = $q.defer();
      var uri = apiSettings.url + '/subscriptions/updatecontacts?' +
        $.param({
          defaultURL: $state.href('home', {}, {
            absolute: true
          })
        });
      $http
        .post(uri, data)
        .then(function (rsp) {
          defer.resolve(rsp);
        }, function (err) {
          defer.reject(err);
        });

      return defer.promise;
    };

  }
})();
