(function () {
  "use strict";

  app.factory("PropertyCompareService", PropertyCompareService);
  
  PropertyCompareService.$inject = ['$http', '$q', 'apiSettings'];
  
  function PropertyCompareService($http, $q, apiSettings) {

    function hasAccess(propertyId) {
      var deferred = $q.defer();
      $http.get(apiSettings.url + '/PropertyCompare/' + propertyId)
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });

      return deferred.promise;
    }

    function getPropertyInfo(propertyId) {
      var defer = $q.defer();
      var uri = apiSettings.url + "/PropertyCompare/" + propertyId + "/propertyInfo";
    
      $http
        .get(uri)
        .then(function (rsp) {
          defer.resolve(rsp.data);
        }, function (err) {
          defer.reject(err);
        });
    
      return defer.promise;
    }

    function getCompAnalysis(portfolioId, propertyId) {
      var defer = $q.defer();
      var uri = apiSettings.url + "/PropertyCompare/" + portfolioId + "/" + propertyId + "/companalysis";

      $http
        .get(uri)
        .then(function (rsp) {
          defer.resolve(rsp.data);
        }, function (err) {
          defer.reject(err);
        });

      return defer.promise;
    }
    
    function getCompDirectory(portfolioId, propertyId) {
      var defer = $q.defer();
      var uri = apiSettings.url + "/PropertyCompare/" + portfolioId + "/" + propertyId + "/compdirectory";
      $http
        .get(uri)
        .then(function (rsp) {
          defer.resolve(rsp.data);
        }, function (err) {
          defer.reject(err);
        });

      return defer.promise;
    }

    
    function getCompSetCharacteristics(portfolioId, propertyId) {
      var defer = $q.defer();
      var uri = apiSettings.url + "/PropertyCompare/" + portfolioId + "/" + propertyId + "/compsetcharacteristics";
      $http
        .get(uri)
        .then(function (rsp) {
          defer.resolve(rsp.data);
        }, function (err) {
          defer.reject(err);
        });

      return defer.promise;
    }

    function getTransAndConstruction(portfolioId, propertyId) {
      var defer = $q.defer();
      var uri = apiSettings.url + "/PropertyCompare/" + portfolioId + "/" + propertyId + "/transandconstruction";
      $http
        .get(uri)
        .then(function (rsp) {
          defer.resolve(rsp.data);
        }, function (err) {
          defer.reject(err);
        });

      return defer.promise;
    }

    function getHospitals(lat, long) {
      var defer = $q.defer();
      var uri = apiSettings.url + "/PropertyCompare/getHospitals?"+
        $.param({
          latitude: lat,
          longitude: long
        });
      $http
        .get(uri)
        .then(function (rsp) {
          defer.resolve(rsp.data);
        }, function (err) {
          defer.reject(err);
        });

      return defer.promise;
    }

    function getExcel(title, exportedOn, pcrVm) {
      var deferred = $q.defer();
      var uri = apiSettings.url + '/PropertyCompare/generateExcel?' +
        $.param({
          title: title,
          date: exportedOn
        });

      $http
        .post(uri, pcrVm, {
          responseType: 'arraybuffer'
        })
        .then(function (rsp) {
          if (rsp.data.length == 0) {
            deferred.reject();
          } else {
            deferred.resolve(rsp.data);
          }
        }, function (err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function getPdf(pcrVm) {
      var deferred = $q.defer();
      var uri = apiSettings.url + '/PropertyCompare/generatePdf';
      $http
        .post(uri, pcrVm, {
          responseType: 'arraybuffer'
        })
        .then(function (rsp) {
          if (rsp.data.length == 0) {
            deferred.reject();
          } else {
            deferred.resolve(rsp.data);
          }
        }, function (err) {
          deferred.reject(err);
        });
      return deferred.promise; 
    }

    return {
      hasAccess: hasAccess,
      getPropertyInfo: getPropertyInfo,
      getCompAnalysis: getCompAnalysis,
      getCompDirectory: getCompDirectory,
      getCompSetCharacteristics: getCompSetCharacteristics,
      getTransAndConstruction: getTransAndConstruction,
      getHospitals: getHospitals,
      getExcel: getExcel,
      getPdf: getPdf
    };
  }
}());
