(function () {
  "use strict";

  app.controller('MetroBankUsageController', MetroBankUsageController);

  MetroBankUsageController.$inject = ['$scope', 'Blob', 'FileSaver', 'AdminService', 'SpinnerService', 'SubscriptionDetailsService'];

  function MetroBankUsageController($scope, Blob, FileSaver, AdminService, SpinnerService, SubscriptionDetailsService) {
    $scope.showMetrosAvailableOnly = true; // by default this is checked
    $scope.metrosAvailableOnlyCompanies = metrosAvailableOnlyCompanies;
    $scope.exportExcel = exportExcel;
    $scope.subscriptionTypes = [];

    // this will hold the data that is being shown in the grid
    $scope.metroBankDataForGrid = [];
    // this holds the full data from api return
    var metroBankData = [];

    var gridSubscriptionTypeDataSource = new kendo.data.DataSource({
      transport: {
        read: function (options) {
          options.success($scope.subscriptionTypes);
        }
      }
    });

    SubscriptionDetailsService.getSubscriptionTypes()
      .then(function (data) {        
        $scope.subscriptionTypes = _.sortBy(data, ['subscriptionType']);
        gridSubscriptionTypeDataSource.read();
      });

    $scope.gridOpts = {
      columns: [
      {
        field: "subscriptionID",
        title: "SubscriptionID",
        hidden: "hidden"
      }, {
        field: "companyID",
        title: "CompanyID",
        hidden: "hidden"
      }, {
        field: "companyName",
        title: "Company",
        template: '<a ui-sref="#= \'admin.subscriptiondetails({ id: \' + subscriptionID + \', companyId: \' + companyID + \' })\' #" target="_blank">#=companyName#</a>',
        filterable: {
          cell: {
            showOperators: false,
            operator: "contains"
          }
        }
      }, {
        field: "startDate",
        title: "Start Date",
        format: "{0:MM/dd/yyyy}",
        filterable: {
          cell: {
            showOperators: true,
            operator: "gte"
          }
        }
      }, {
        field: "endDate",
        title: "End Date",
        format: "{0:MM/dd/yyyy}",
        filterable: {
          cell: { 
            showOperators: true,
            operator: "gte"
          }
        }
      }, {
        field: "subscriptionType",
        title: "Subscription Type",
          filterable: {
            cell: {
              showOperators: false,
              template: function (args) {
                args.element.removeAttr("data-bind");
                args.element.attr("id", "SubscriptionTypeMultiSelect"); //so I can find easily in dom
                args.element.kendoMultiSelect({
                  dataSource: gridSubscriptionTypeDataSource,
                  optionLabel: " ",
                  dataTextField: "subscriptionType",
                  dataValueField: "subscriptionType",
                  valuePrimitive: true,
                  change: subscriptionTypeFilterChange
                })
              }
            }
          }
      }, {
        field: "accountType",
        title: "Account Type",
        filterable: {
          cell: {
            showOperators: false,
            template : function(args){
              args.element.kendoDropDownList({
                autoBind: false,
                optionLabel: " ",
                dataTextField: "name",
                dataValueField: "name",
                valuePrimitive: true,
                dataSource: new kendo.data.DataSource({
                  data: [
                    { name: 'New' },
                    { name: 'Renewal' }
                  ]
                })                                                           
              });
            }        
          }
        }
      }, {
        field: 'accountStatus',
        title: 'Account Status',
        filterable: {
          cell: {
            showOperators: false,
            template: function (args) {
              args.element.kendoDropDownList({
                autoBind: false,
                optionLabel: ' ',
                dataTextField: 'name',
                dataValueField: 'name',
                valuePrimitive: true,
                dataSource: new kendo.data.DataSource({
                  data: [
                    { name: 'Active' },
                    { name: 'Pending' }
                  ]
                })
              });
            }
          }
        }
      }, {
        field: "metrosPurchased",
        title: "Metros Purchased",
        filterable: {
          cell: {
            showOperators: false,
            operator: "gte"
          }
        }
      }, {
        field: "metrosActivated",
        title: "Metros Activated",
        filterable: {
          cell: {
            showOperators: false,
            operator: "gte"
          }
        }
      }, {
        field: "metrosAvailable",
        title: "Metros Available",
        filterable: {
          cell: {
            showOperators: false,
            operator: "gte"
          }
        }
      }],
      dataSource: new kendo.data.DataSource({
        transport: {
          read: function (options) {
            options.success($scope.metroBankDataForGrid);
          }
        },
        pageSize: 25,
        error: function (err) {
          console.log('error - ' + err);
        },
        schema: {
          model: {
            fields: {
              'subscriptionID': { type: 'number' },
              'companyID': { type: 'number' },
              'companyName': { type: 'string' },
              'startDate': { type: 'date' },
              'endDate': { type: 'date' },
              'subscriptionType': { type: 'string' },
              'accountType': { type: 'string' },
              'metrosPurchased': { type: 'number' },
              'metrosActivated': { type: 'number' },
              'metrosAvailable': { type: 'number' }
            }
          }
        },
        sort: {
          field: "companyName",
          dir: "asc"
        },
        serverPaging: false,
        serverFiltering: false,
        serverSorting: false
      }),
      dataBinding: function(e) {
        Nic.kendoGrid.filtersOnTop('grid');
      },
      filterable: {
        mode: "row",
        extra: false,
        operators: {
          date: {
            eq: "Equal to",
            gte: "On or After",
            lte: "On or Before"
          }
        }
      },
      scrollable: false,
      sortable: {
        mode: "single",
        allowUnsort: false
      },
      pageable: true,
      resetFilters: function () {
        this.dataSource.filter({});
        var subTypeMulti = $('#SubscriptionTypeMultiSelect');
        if(subTypeMulti.length > 0) {
          subTypeMulti.data("kendoMultiSelect").value([]);
        }
        this.dataSource.sort({ field: "companyName", dir: "asc" });
      }
    };

    function subscriptionTypeFilterChange(e) {
      applyFilterChange("or", "subscriptionType", "eq", this.value());
    }

    function applyFilterChange (_logic, _field, _operator, _values) {
      var filter = { logic: _logic, filters: [] };
      $.each(_values, function (i, v) {
        filter.filters.push({field: _field, operator: _operator, value: v });
      });
      if (filter.filters.length > 0) {
        // filter added
        var currentFilters = $scope.gridOpts.dataSource.filter() || {};
        if (currentFilters.filters) {
          var filteredOut = _.filter(currentFilters.filters, function (eachFilter) {
            return (eachFilter.field && eachFilter.field != _field) || 
              (eachFilter.filters && eachFilter.filters.length > 0 && eachFilter.filters[0].field != _field);
          });
          filteredOut.push(filter);
          $scope.gridOpts.dataSource.filter(filteredOut);
        } else {
          $scope.gridOpts.dataSource.filter(filter);
        }
      } else {
        var currentFilters = $scope.gridOpts.dataSource.filter() || {};
        if (currentFilters.filters) {
          var filteredOut = _.filter(currentFilters.filters, function (eachFilter) {
            return (eachFilter.field && eachFilter.field != _field) || 
              (eachFilter.filters && eachFilter.filters.length > 0 && eachFilter.filters[0].field != _field);
          });
          $scope.gridOpts.dataSource.filter(filteredOut);
        } else {
          $scope.gridOpts.dataSource.filter(filter);
        }
      }
    } 

    function GetData() {
      SpinnerService.start();
      AdminService
        .getMetroBankRecords()
        .then(function (data) {
          metroBankData = data;
          $scope.metroBankDataForGrid = angular.copy(metroBankData);
          metrosAvailableOnlyCompanies();
        }, function (err) {
          console.error(err);
        })
        .finally(function () {
          SpinnerService.stop();
        });
    }

    GetData();

    function metrosAvailableOnlyCompanies() {
      if ($scope.showMetrosAvailableOnly) {
        $scope.metroBankDataForGrid = _.filter(metroBankData, function (val) {
          return val.metrosAvailable > 0;
        });
      } else {
        $scope.metroBankDataForGrid = angular.copy(metroBankData);
      }
      $scope.gridOpts.dataSource.read();
    }

    function exportExcel() {
      SpinnerService.start();
      
      var dataForExport = $scope.gridOpts.dataSource.data();
      var filtersAndSorts = getGridCurrentFilterAndSort($scope.gridOpts);

      var exportVm = {
        data: dataForExport,
        filtersAndSorts: filtersAndSorts
      };

      AdminService
        .metroBankRecordsToExcel(exportVm)
        .then(function (data) {
          var excelFile = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          });
          var currentDate = new Date();
          // March 8, 2020 -> 03082020
          var currentDateStr = ("0" + (currentDate.getMonth() + 1).toString()).slice(-2) + 
            ("0" + currentDate.getDate().toString()).slice(-2) + 
            currentDate.getFullYear().toString();
          var fileName = 'NICMAP_MetroBank_' + currentDateStr + '.xlsx';
          FileSaver.saveAs(excelFile, fileName);
        }, function (err) {
          console.error(err);
        })
        .finally(function () {
          SpinnerService.stop();
        });
    }
  
    function getGridCurrentFilterAndSort(sourceGrid) {
      var combinedFilters = [];
      var filters = sourceGrid.dataSource.filter();
      filters = (filters === undefined) ? [] : filters.filters;
      _.forEach(filters, function (eachFilterInfo) {
        // need to do this for the multiselect dropdowns that are in the grid
        if (eachFilterInfo.filters) {
           var concatenatedVal = '', sameFieldName = '', sameFieldOperator = '';
           _.forEach(eachFilterInfo.filters, function (sameFieldObj) {
            sameFieldName = sameFieldObj.field;
            sameFieldOperator = sameFieldObj.operator;
            concatenatedVal += (concatenatedVal.length > 0 ? ';' : '') + sameFieldObj.value;
          });
          combinedFilters.push({ field: sameFieldName, operator: sameFieldOperator, value: concatenatedVal });
        } else {
          combinedFilters.push(eachFilterInfo);
        }
      });
      
      var gridData = {
        filters: combinedFilters,
        sorts: sourceGrid.dataSource.sort() || []
      };
      return gridData;
    }
  }
})();
