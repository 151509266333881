(function () {
  "use strict";

  app.controller('PropertyCompareController', PropertyCompareController);

  PropertyCompareController.$inject = ['$rootScope', '$scope', '$state', '$stateParams', '$window', '$sce', '$filter', '$q', '$timeout'
    , 'knownEventConstant', 'knownProductConstant', 'gridPageSize', 'currentQuarter', 'propertyInfo', 'segmentTypes', 'pushpinImages'
    , 'DateOffsetUtils', 'PropertyCompareService', 'BingMapUtils', 'BingGeoDataService', 'GeoDataService', 'PinFactory', 'PinGeneratorUtil', 'AuditService'
    , 'SpinnerService', 'FileSaver','KendoTemplateFunctions', 'reportSectionTypeConstant', 'DataAttributionService'];

  function PropertyCompareController($rootScope, $scope, $state, $stateParams, $window, $sce, $filter, $q, $timeout
    , knownEventConstant, knownProductConstant, gridPageSize, currentQuarter, propertyInfo, segmentTypes, pushpinImages
    , DateOffsetUtils, PropertyCompareService, BingMapUtils, BingGeoDataService, GeoDataService, pinFactory, pinGeneratorUtil, AuditService
    , SpinnerService, FileSaver, KendoTemplateFunctions, reportSectionTypeConstant, DataAttributionService) {
    $scope.portfolioId = $stateParams.portfolioId;
    $rootScope.hideLeftNav = true;

    var bingMap;
    var isDestroyed = false;

    $scope.showComps = false;
    $scope.showMetroArea = false;
    $scope.showHospitals = false;
    $scope.showCounties = false;
    $scope.showConstruction = false;
    $scope.showTransaction = false;
    $scope.AttributionHtml = "";
    
    $scope.coveredCountiesPolygons = [];

    $scope.currentDate = new Date();
    $scope.currentQuarter = currentQuarter;
    $scope.subjectProperty = propertyInfo;
    $scope.openDate = propertyInfo.dateOpen ? DateOffsetUtils.convertToDate(propertyInfo.dateOpen) || null : null;
    $scope.backToDetails = backToDetails;
    $scope.exportExcel = exportExcel;
    $scope.pcrToPDF = pcrToPDF;
    $scope.propertyDetailsClick = propertyDetailsClick;
    $scope.onClickShowComps = onClickShowComps;
    $scope.onClickShowHospitals = onClickShowHospitals;
    $scope.onClickShowConstruction = onClickShowConstruction;
    $scope.onClickShowTransaction = onClickShowTransaction;
    $scope.onMapReady = onMapReady;

    var cbsaAreaLocationCache = null;
    var pcrVm = {};
    pcrVm["portfolioId"] = $scope.portfolioId;
    pcrVm["propertyInfo"] = angular.copy($scope.subjectProperty); // PropertyModel
    pcrVm["segmentTypes"] = segmentTypes; // SegmentTypeViewModel

    function onMapReady(map) {
      bingMap = map;
      BingGeoDataService.setupSdsManager();
      if($scope.subjectProperty.latitude && $scope.subjectProperty.longitude){
        var propLocation = new Microsoft.Maps.Location($scope.subjectProperty.latitude,$scope.subjectProperty.longitude);
        bingMap.setView({
          zoom: 10,
          center: propLocation
        });
      }
    }

    $scope.$on("$destroy", function() {
      isDestroyed = true;
    });

    function viewChange(){
      if (isDestroyed) {
        return;
      }
      $scope.toggleMetroArea();
      $scope.toggleCounties();
    }

    function checkChartDataForOverlay(segmentScope) {
      var chart = segmentScope.chartCompAnalysis;
      if (chart && chart.element && chart.element.length > 0) {
        var overlayDisplayVarName = chart.element[0].getAttribute("overlayDisplayVarName");
        if (overlayDisplayVarName) {
          segmentScope[overlayDisplayVarName].visible = false;
          segmentScope[overlayDisplayVarName].message = '';
          var allNull = checkChartDataForAllNulls(chart);
          if (allNull) {
            segmentScope[overlayDisplayVarName].visible = true;
            segmentScope[overlayDisplayVarName].message = getNullMessageForChart(chart);
            clearAllSeriesNamesForChart(chart);
          }
        }
      }
    };

    function clearAllSeriesNamesForChart(chart) {
      angular.forEach(chart.options.series, function (item) {
        item.name = '';
      });
    }

    function getNullMessageForChart(chart) {
      return "Due to there being no competitive set for this property, data cannot be displayed. To display data in this report, please update the competitive set for this property.";
    }

    function checkChartDataForAllNulls(chart) {
      var allSeriesEmpty = true;
      for (var i = 0; i < chart.options.series.length; i++) {
        var series = chart.options.series[i];
        var data = chart.options.series[i].data;
        var fieldname = series.field;
        var allAreNull = _.every(data, function (o) {
          var value = o[fieldname];
          return value == null;
        });

        if (!allAreNull) {
          allSeriesEmpty = false;
          break;
        }
      }

      return allSeriesEmpty;
    }

    $scope.gridOpts_MyProperty = {
      columns: [
        {
          field: 'id',
          title: 'Property ID',
          attributes: {
            'class': 'text-right'
          },
          width: 100
        },
        {
          field: 'name',
          title: 'Property Name',
          width: 250,
          template: '<a ui-sref="#= \'propertyDetails({ id: \' + id + \' })\' #" ng-click="propertyDetailsClick(this.dataItem.id, this.dataItem.name)" target="_blank">#= name #</a>'
        },
        {
          title: 'Open Date',
          template: $filter('date')($scope.openDate, 'MM/dd/yyyy'),
          format: '{0:MM/dd/yyyy}',
          width: 100
        },
        {
          field: 'buildingAgeInYears',
          title: 'Age',
          width: 75,
          format: '{0:n0}',
          attributes: {
            'class': 'text-right'
          }
        },
        {
          field: 'address',
          title: 'Address',
          width: 300,
          template: '#:address.addressLine1# #:address.city#, #:address.stateProvinceCode# #:address.zipCode#'
        },
        {
          field: 'units.ilUnits',
          title: 'IL Units',
          attributes: {
            'class': 'text-right'
          }
        },
        {
          field: 'units.alUnits',
          title: 'AL Units',
          attributes: {
            'class': 'text-right'
          }
        },
        {
          field: 'units.mcUnits',
          title: 'MC Units',
          attributes: {
            'class': 'text-right'
          }
        },
        {
          field: 'units.ncUnits',
          title: 'NC Beds',
          attributes: {
            'class': 'text-right'
          }
        },
        {
          title: 'Distance',
          template: "0",
          hidden: "hidden",
          attributes: {
            'class': 'text-right'
          }
        }
      ],
      dataSource: new kendo.data.DataSource({
        data: [propertyInfo],
        serverPaging: false,
        serverFiltering: false,
        serverSorting: false
      }),
      scrollable: false,
      sortable: false,
      pageable: false
    };

    $scope.compAnalysisData = null;
    $scope.compAnalysisFilteredBySegmentData = {};
    $scope.compAnalysisTableData = {};
    $scope.compBandwidthfilteredBySegment = {};
    $scope.compBandwidthTableData = {};

    $scope.compAnalysisSegments = segmentTypes;
    if (segmentTypes && segmentTypes.length > 0) {
      $scope.compAnalysisSelectedSegment = segmentTypes[0].code;
      _.map($scope.compAnalysisSegments, function (segment) {
        return _.extend({ rptCompAnalysisScope: {} }, segment);
      });
    }
    $scope.compAnalysisSegmentTypeChanged = compAnalysisSegmentTypeChanged;

    $scope.getIndexColor = getIndexColor;

    $scope.defaultLegend = KendoTemplateFunctions.defaultChartLegend;
    $scope.chartIndexLegend = {
      position: 'custom',
      offsetX: 40,
      offsetY: 20,
      orientation: 'vertical',
      align: 'start',
      background: 'rgba(255,255,255,0.8)',
      item: {
        visual: function (e) {
          var color = e.options.markers.background;
          var labelColor = e.options.labels.color;
          var rect = new kendo.geometry.Rect([0, 0], [60, 65]);
          var layout = new kendo.drawing.Layout(rect, {
            spacing: 1,
            alignItems: "start",
            orientation: "vertical"
          });          
          var overlay = kendo.drawing.Path.fromRect(rect, {
            fill: {
              color: "#fff",
              opacity: 0
            },
            stroke: {
              color: "none"
            },
            cursor: "pointer"
          });

          function createLegendRow(text, color) {
            var group = new kendo.drawing.Group();
            var rect = kendo.drawing.Path.fromRect(new kendo.geometry.Rect([0, 3], [7, 7])).fill(color).stroke(color);
            var label = new kendo.drawing.Text(text, [15, 0], {
              fill: {
                color: 'black'
              }
            });

            group.append(rect, label);
            return group;
          }

          layout.append(createLegendRow('< 0.95', 'red'), createLegendRow('0.95 - 1', '#F7D4CC'),
                      createLegendRow('1 - 1.05', '#CCE6CC'), createLegendRow('> 1.05', 'green'));

          layout.reflow();

          var group = new kendo.drawing.Group().append(layout, overlay);

          return group;
        }
      }
    };

    $scope.chart2Axes = [
      {
        name: "percentageWDecimalAxis",
        labels: {
          format: "p1",
          color: "#232323"
        },
        narrowRange: true
      },
      {
        name: "barAxis",
        color: "#959595",
        labels: {
          color: "#232323"
        },
        axisCrossingValues: [0, -9999999], //hidden xaxis displays at 0. Other axis is at bottom.
        narrowRange: true
      }];

    $scope.percentageNoDecimalAxis = [
      {
        name: "percentageNoDecimalAxis",
        labels: {
          format: "p0",
          color: "#232323"
        },
        narrowRange: true
      }];

    $scope.percentageWDecimalAxis = [
      {
        name: "percentageWDecimalAxis",
        labels: {
          format: "p1",
          color: "#232323"
        },
        narrowRange: true
      }];

    $scope.currencyAxis = [
      {
        name: "currencyAxis",
        labels: {
          format: "c0",
          color: "#232323"
        },
        narrowRange: true
      }];

    $scope.seriesDefaults = {
      type: 'line',
      style: 'smooth',
      markers: {
        visible: false
      },
      missingValues: 'gap'
    };

    var actualAxis = {
      axisCrossingValues: [0, 99], //only charts with differet Y axis on left and right "need" axisCrossingValues, but it doesn't seem to affect charts that don't need this.
      majorGridLines: {
        visible: false
      }
    };
    $scope.categoryAxis = actualAxis;

    $scope.unitTypeChartCategoryAxis = {
      axisCrossingValues: [0, 99], //only charts with differet Y axis on left and right "need" axisCrossingValues, but it doesn't seem to affect charts that don't need this.
      majorGridLines: {
        visible: false
      },
      labels: {
        rotation: -45
      }
    };

    $scope.constructionInventory = {
      properties: [],
      fiveMiles: {
        count: 0,
        expansionCount: 0,
        newCount: 0,
        trusted: {}
      },
      tenMiles: {
        count: 0,
        expansionCount: 0,
        newCount: 0,
        trusted: {}
      },
      spaces: {
        IL: 0,
        AL: 0,
        MC: 0,
        NC: 0,
        ILFormatted: function () {
          return $filter('number')(this.IL, 0);
        },
        ALFormatted: function () {
          return $filter('number')(this.AL, 0);
        },
        MCFormatted: function () {
          return $filter('number')(this.MC, 0);
        },
        NCFormatted: function () {
          return $filter('number')(this.NC, 0);
        },
        totalSpacesFormatted: function () {
          return $filter('number')(this.totalSpaces(), 0);
        },
        totalSpaces: function () {
          return this.IL + this.AL + this.MC + this.NC;
        },
        trusted: {}
      }
    };

    $scope.transactions = {
      properties: [],
      fiveMiles: {
        count: 0,
        expansionCount: 0,
        newCount: 0,
        trusted: {}
      },
      tenMiles: {
        count: 0,
        expansionCount: 0,
        newCount: 0,
        trusted: {}
      },
      spaces: {
        IL: 0,
        AL: 0,
        MC: 0,
        NC: 0,
        ILFormatted: function () {
          return $filter('number')(this.IL, 0);
        },
        ALFormatted: function () {
          return $filter('number')(this.AL, 0);
        },
        MCFormatted: function () {
          return $filter('number')(this.MC, 0);
        },
        NCFormatted: function () {
          return $filter('number')(this.NC, 0);
        },
        totalSpacesFormatted: function () {
          return $filter('number')(this.totalSpaces(), 0);
        },
        totalSpaces: function () {
          return this.IL + this.AL + this.MC + this.NC;
        },
        trusted: {}
      }
    };

    $scope.hospitals = {
      properties: [],
      medicareAPIFailure: false,
      fiveMiles: {
        count: 0
      },
      tenMiles: {
        count: 0
      }
    };

    init();

    function init() {
      SpinnerService.start();
      $q.all([
        PropertyCompareService.getCompAnalysis($scope.portfolioId, propertyInfo.id),
        PropertyCompareService.getCompDirectory($scope.portfolioId, propertyInfo.id),
        PropertyCompareService.getCompSetCharacteristics($scope.portfolioId, propertyInfo.id),
        PropertyCompareService.getTransAndConstruction($scope.portfolioId, propertyInfo.id),
        PropertyCompareService.getHospitals($scope.subjectProperty.latitude, $scope.subjectProperty.longitude),
        DataAttributionService.getAttributionHtml()
      ]).then(function (responses) {
        getCompAnalysisData(responses[0]);
        getMyPropertyCompsDirectory(responses[1]);
        getCompSetCharacteristics(responses[2]);
        getTransAndConstruction(responses[3]);
        getHospitals(responses[4]);
        $scope.AttributionHtml = $sce.trustAsHtml(responses[5]);

        SpinnerService.stop();

        if ($state.params && $state.params.exportType) {
          var exportType = $state.params.exportType.toLowerCase();
          if (exportType == "xlsx") exportExcel();    
          if (exportType == "pdf") $timeout(pcrToPDF, 1000); 
        }
      }, function (error) {
        // error
        SpinnerService.stop();
      });
    }

    $scope.mapOptions = BingMapUtils.getDefaultViewOptions();
    $scope.mapOptions.options.disableScrollWheelZoom = true;
    $scope.mapOptions.events =  {
      viewchangeend: viewChange
    };

    $scope.fiveRadius = BingMapUtils.getPolygonOptions('fiveRadius', BingMapUtils.buildCircle($scope.subjectProperty.latitude, $scope.subjectProperty.longitude, 5));
    $scope.tenRadius = BingMapUtils.getPolygonOptions('tenRadius', BingMapUtils.buildCircle($scope.subjectProperty.latitude, $scope.subjectProperty.longitude, 10));
    $scope.cbsaArea = BingMapUtils.getPolygonOptions('cbsaArea'); // just get the default options, the location points will come later 

    $scope.subjectProperty.pinOptions = new pinFactory.buildBlackPinOptions();

    function togglePushPins(pins, newValue) {
      _.each(pins, function (value) {
        value.visible = newValue;
      });
    };

    function onClickShowComps(pins, showComps) {
      togglePushPins(pins, showComps);
      var selected = showComps ? "Selected" : "Unselected";
      AuditService
        .logEvent(knownEventConstant.selectIncludeComps.id, knownEventConstant.selectIncludeComps.message + " - " + selected, knownProductConstant.port, $stateParams.propertyId);
    }

    function onClickShowHospitals(pins, showHospitals) {
      togglePushPins(pins, showHospitals);
    }

    function onClickShowConstruction(pins, showConstruction){
      togglePushPins(pins, showConstruction); 
    }

    function onClickShowTransaction(pins, showTransaction){
      togglePushPins(pins, showTransaction); 
    }

    $scope.toggleMetroArea = function () {
      var selected = $scope.showMetroArea ? "Selected" : "Unselected";
      AuditService
        .logEvent(knownEventConstant.selectMetroArea.id, knownEventConstant.selectMetroArea.message + " - " + selected, knownProductConstant.port, $stateParams.propertyId);
      if (!$scope.showMetroArea) {
        $scope.cbsaArea.sections = [];
        return;
      }

      if (cbsaAreaLocationCache) {
        $scope.cbsaArea.sections = angular.copy(cbsaAreaLocationCache);
        return;
      }

      SpinnerService.start();
      GeoDataService
        .getCBSAGeoData($scope.subjectProperty.cbsaCode)
        .then(function (data) {
          if (data && data.length > 0) {
            $scope.cbsaArea.sections = data;
            cbsaAreaLocationCache = angular.copy(data);
          }
          SpinnerService.stop();
        }, function (err) {
          SpinnerService.stop();
        });
    };

    $scope.toggleCounties = function () {
      if ($scope.showCounties){
        if(BingMapUtils.getCurrentZoom(bingMap) >= 9) {
          SpinnerService.start();
          BingGeoDataService
            .getAllCountiesBoundariesInBbox(bingMap, true)
            .then(function (bingPolygons) {
              $scope.coveredCountiesPolygons = bingPolygons;
              SpinnerService.stop();
            }, function (err) { 
              SpinnerService.stop();
            });
        }
      } 
      else
        $scope.coveredCountiesPolygons = [];
    };
      
    function getCompAnalysisData(data) {
      if ($scope.compAnalysisData) {
        displayCompAnaylsisChartAndTable();
        return;
      }

      $scope.compAnalysisFilteredBySegmentData = {};
      $scope.compAnalysisTableData = {};

      $scope.compBandwidthfilteredBySegment = {};
      $scope.compBandwidthTableData = {};

      if (data && data.compAnalysis) {
        // try to convert data to floats for charting purposes
        _.forEach(data.compAnalysis, function (compAnalysis) {
          compAnalysis.myPropertyOccupiedSpaces_ForChart = tryToConvertToFloat(compAnalysis.myPropertyOccupiedSpaces);
          compAnalysis.compSetOccupiedSpaces_ForChart = tryToConvertToFloat(compAnalysis.compSetOccupiedSpaces);
          compAnalysis.marketShareOccupiedSpaces_ForChart = tryToConvertToFloat(compAnalysis.marketShareOccupiedSpaces);
        });
      }

      pcrVm["compAnalysis"] = data.compAnalysis; // PCRCompAnalysis

      if (data && data.compBandwidth) {
        // try to convert data to floats for charting purposes
        _.forEach(data.compBandwidth, function (compBandwidth) {
          compBandwidth.compSetOccupancyRaw_Min_ForChart = tryToConvertToFloat(compBandwidth.compSetOccupancyRaw_Min);
          compBandwidth.compSetOccupancyRaw_Max_ForChart = tryToConvertToFloat(compBandwidth.compSetOccupancyRaw_Max);
          compBandwidth.myPropertyOccupancyRaw_ForChart = tryToConvertToFloat(compBandwidth.myPropertyOccupancyRaw);
          compBandwidth.compSetOccupancyRaw_ForChart = tryToConvertToFloat(compBandwidth.compSetOccupancyRaw);

          compBandwidth.compSetRPI_Min_ForChart = tryToConvertToFloat(compBandwidth.compSetRPI_Min);
          compBandwidth.compSetRPI_Max_ForChart = tryToConvertToFloat(compBandwidth.compSetRPI_Max);
          compBandwidth.myPropertyRPI_ForChart = tryToConvertToFloat(compBandwidth.myPropertyRPI);
          compBandwidth.compSetRPI_ForChart = tryToConvertToFloat(compBandwidth.compSetRPI);
        });
      }
      
      pcrVm["compBandwidth"] = data.compBandwidth; // PCRCompBandwidth

      $scope.compAnalysisData = data;
      displayCompAnaylsisChartAndTable();
    }

    function displayCompAnaylsisChartAndTable() {
      if ($scope.compAnalysisData) {
        filterCompAnalysisDataBySelectedSegment();

        if (!$scope.gridOpts_CompAnalysis) {
          buildCompAnalysisTable();
        }
        else {
          $scope.gridCompAnalysis.dataSource.read();
        }

        if (!$scope.gridOpts_CompBandwidth) {
          buildCompbandwidthTable();
        }
        else {
          $scope.gridCompBandwidth.dataSource.read();
        }

        _.each($scope.compAnalysisSegments, function (segment) {
          var categories = _.map($scope.compBandwidthfilteredBySegment[segment.code], function (data) {
            return data.quarter;
          });
        
          redrawChart(segment.rptCompAnalysisScope.chartCompAnalysis, $scope.compAnalysisFilteredBySegmentData[segment.code], categories);
          checkChartDataForOverlay(segment.rptCompAnalysisScope);

          redrawChart(segment.rptCompBandwidthIndexScope.chartOccAnalysis, $scope.compBandwidthfilteredBySegment[segment.code], categories);
          redrawChart(segment.rptCompBandwidthIndexScope.chartOccIndex, $scope.compBandwidthfilteredBySegment[segment.code], categories);
          redrawChart(segment.rptCompBandwidthIndexScope.chartRPIAnalysis, $scope.compBandwidthfilteredBySegment[segment.code], categories);
          redrawChart(segment.rptCompBandwidthIndexScope.chartRPIIndex, $scope.compBandwidthfilteredBySegment[segment.code], categories);
        });
      }
    }

    $scope.initCompAnalysisCharts = function (repeaterScope, currentSegment, rptIdx) {
      // add repeater scope for companalysis chart to the segment object of the dropdown
      currentSegment.rptCompAnalysisScope = repeaterScope;
      repeaterScope.currentSegment = currentSegment;
      repeaterScope.displayCompAnalysisChartOverlay = { visible: false, message: '' };
    }

    $scope.initCompBandwidthIndexCharts = function (repeaterScope, currentSegment, rptIdx) {
      // add repeater scope for bandwidth charts to the segment object of the dropdown
      currentSegment.rptCompBandwidthIndexScope = repeaterScope;
      repeaterScope.currentSegment = currentSegment;
    }

    $scope.showSegment = function (currentSegment) {
      return $scope.compAnalysisSelectedSegment == currentSegment.code;
    }

    function buildCompAnalysisTable() {
      if (!$scope.compAnalysisFilteredBySegmentData[$scope.compAnalysisSelectedSegment]) return;
      var dynamicColumns = ($scope.compAnalysisFilteredBySegmentData[$scope.compAnalysisSelectedSegment]).map(function (item, index) {
        if (item.quarter) {
          var fieldname = 'N' + item.quarter;  // field name must start with a character and not a number

          return {
            field: fieldname,
            title: item.quarter,
            template: '{{gridOpts_CompAnalysis.changeRowTemplate(dataItem.row, 0, dataItem.' + fieldname + ')}}',
            attributes: {
              'class': 'text-right'
            }
          };
        }

        return null;
      });

      dynamicColumns.unshift({ field: "row", title: " " });

      $scope.gridOpts_CompAnalysis = {
        columns: dynamicColumns,
        dataSource: new kendo.data.DataSource({
          transport: {
            read: function (options) {
              options.success($scope.compAnalysisTableData[$scope.compAnalysisSelectedSegment]);
            }
          },
          serverPaging: false,
          serverFiltering: false,
          serverSorting: false
        }),
        scrollable: false,
        sortable: false,
        pageable: false,
        changeRowTemplate: function (row, colIndex, value) {
          var returnValue = value;
          switch (row) {
            case "Market Share":
              var num = parseFloat(value);
              if (num) {
                returnValue = kendo.format("{0:p1}", num);
              }
          };
          return returnValue;
        }
      };
    }

    function buildCompbandwidthTable() {
      if (!$scope.compBandwidthfilteredBySegment[$scope.compAnalysisSelectedSegment]) return;
      var dynamicColumns = ($scope.compBandwidthfilteredBySegment[$scope.compAnalysisSelectedSegment]).map(function (item, index) {
        if (item.quarter) {
          var fieldname = 'N' + item.quarter; // field name must start with a character and not a number

          return {
            field: fieldname,
            title: item.quarter,
            template: '<span ng-style="gridOpts_CompBandwidth.getCellFontColor(dataItem, dataItem.' + fieldname + ')">{{gridOpts_CompBandwidth.changeRowTemplate(dataItem, dataItem.' + fieldname + ')}}</span>',
            attributes: {
              'class': 'text-right'
            }
          };
        }

        return null;
      });

      dynamicColumns.unshift({ field: "subGroup", title: " ", width: 150 });
      dynamicColumns.unshift({ field: "subGroupOrder", title: " ", hidden: true });
      dynamicColumns.unshift({ field: "groupOrderAndName", title: " ", hidden: true, groupHeaderTemplate: "#:value.slice(2) #" });

      $scope.gridOpts_CompBandwidth = {
        columns: dynamicColumns,
        dataSource: new kendo.data.DataSource({
          transport: {
            read: function (options) {
              options.success($scope.compBandwidthTableData[$scope.compAnalysisSelectedSegment]);
            }
          },
          group: {
            field: "groupOrderAndName",
            dir: "asc"
          },
          sort:
            [
              {
                field: "groupOrderAndName",
                dir: "asc"
              },
              {
                field: "subGroupOrder",
                dir: "asc"
              }
            ],
          serverPaging: false,
          serverFiltering: false,
          serverSorting: false
        }),
        scrollable: false,
        sortable: false,
        pageable: false,
        groupable: false,

        getCellFontColor: function (row, value) {

          if (row.subGroupOrder == 3) {
            var cellcolor;

            var indexValue = parseFloat(value);
            if (indexValue) {
              if (indexValue < 0.95) {
                return { color: 'red' };
              }

              if (indexValue > 1.05) {
                return { color: 'green' };
              }
            }
          }
        },
        changeRowTemplate: function (row, value) {
          var returnValue = value;
          var format = null;

          var groupNum = row.groupOrderAndName.slice(0, 1);
          switch (groupNum) {
            case '1':
              // OCC
              format = "p1";
              if (row.subGroupOrder == 3) {
                format = "n2";
              }
              break;
            case '2':
              // RPI
              format = "c0";
              if (row.subGroupOrder == 3) {
                format = "n2";
              }
              break;
            case '3':
              // AMR
              format = "c0";
              if (row.subGroupOrder == 3) {
                format = "n2";
              }
              break;
            case '4':
              //UNITS
              format = "n0";
              break;
          };

          if (format) {
            var num = parseFloat(value);
            if (num) {
              returnValue = kendo.format("{0:" + format + "}", num);
            }
          }
          return returnValue;
        }
      };
    }

    function filterCompAnalysisDataBySelectedSegment() {
      var hasCompAnalysis = $scope.compAnalysisData.compAnalysis ? true : false;
      var hasCompBandwidth = $scope.compAnalysisData.compBandwidth ? true : false;

      _.each($scope.compAnalysisSegments, function (segment) {
        var segmentCode = segment.code;

        if (hasCompAnalysis) {
          var compAnalysisfilteredBySegment = _.filter($scope.compAnalysisData.compAnalysis, ['segmentType', segmentCode]);
          $scope.compAnalysisFilteredBySegmentData[segmentCode] = compAnalysisfilteredBySegment;
          $scope.compAnalysisTableData[segmentCode] = pivotCompAnalysisData(compAnalysisfilteredBySegment);
        }

        if (hasCompBandwidth) {
          var compBandwidthfilteredBySegment = _.filter($scope.compAnalysisData.compBandwidth, ['segmentType', segmentCode]);
          $scope.compBandwidthfilteredBySegment[segmentCode] = compBandwidthfilteredBySegment;
          $scope.compBandwidthTableData[segmentCode] = pivotCompBandwidthData(compBandwidthfilteredBySegment);
        }
      });
    }

    function pivotCompAnalysisData(data) {
      var pivotedData = [{ row: 'My Property' },
                         { row: 'Comp Set' },
                         { row: 'Market Share' },
                         { row: 'Total Occ. Units' },
                         { row: 'Total Avail. Units' },
                         { row: 'Props Reporting *' }];

      angular.forEach(data, function (value) {
        pivotedData[0]['N' + value.quarter] = value.myPropertyOccupiedSpaces;
        pivotedData[1]['N' + value.quarter] = value.compSetOccupiedSpaces;
        pivotedData[2]['N' + value.quarter] = value.marketShareOccupiedSpaces;
        pivotedData[3]['N' + value.quarter] = value.totalOccUnits;
        pivotedData[4]['N' + value.quarter] = value.totalAvailUnits;
        pivotedData[5]['N' + value.quarter] = value.propsReporting;
      });

      return pivotedData;
    }

    function pivotCompBandwidthData(data) {

      var occupancy = [
        {
          groupOrderAndName: '1 OCCUPANCY',
          subGroup: 'My Property',
          subGroupOrder: 2,
        },
        {
          groupOrderAndName: '1 OCCUPANCY',
          subGroup: 'Comp Set',
          subGroupOrder: 2
        },
        {
          groupOrderAndName: '1 OCCUPANCY',
          subGroup: 'Index',
          subGroupOrder: 3
        }
      ];
      var rpi = [
        {
          groupOrderAndName: '2 RELATIVE PERFORMANCE INDICATOR',
          subGroup: 'My Property',
          subGroupOrder: 2,
        },
        {
          groupOrderAndName: '2 RELATIVE PERFORMANCE INDICATOR',
          subGroup: 'Comp Set',
          subGroupOrder: 2
        },
        {
          groupOrderAndName: '2 RELATIVE PERFORMANCE INDICATOR',
          subGroup: 'Index',
          subGroupOrder: 3
        }
      ];
      var amr = [
        {
          groupOrderAndName: '3 AMR',
          subGroup: 'My Property',
          subGroupOrder: 2
        },
        {
          groupOrderAndName: '3 AMR',
          subGroup: 'Comp Set',
          subGroupOrder: 2
        },
        {
          groupOrderAndName: '3 AMR',
          subGroup: 'Index',
          subGroupOrder: 3
        }
      ];
      var units = [
        {
          groupOrderAndName: '4 UNITS',
          subGroup: 'My Property',
          subGroupOrder: 2
        },
        {
          groupOrderAndName: '4 UNITS',
          groupName: 'UNITS',
          subGroup: 'Comp Set',
          subGroupOrder: 2
        }
      ];


      angular.forEach(data, function (value) {

        occupancy[0]['N' + value.quarter] = value.myPropertyOccupancyRaw;
        occupancy[1]['N' + value.quarter] = value.compSetOccupancyRaw;
        occupancy[2]['N' + value.quarter] = value.indexOccupancyRaw;

        rpi[0]['N' + value.quarter] = value.myPropertyRPI;
        rpi[1]['N' + value.quarter] = value.compSetRPI;
        rpi[2]['N' + value.quarter] = value.indexRPI;

        amr[0]['N' + value.quarter] = value.myPropertyAMR;
        amr[1]['N' + value.quarter] = value.compSetAMR;
        amr[2]['N' + value.quarter] = value.indexAMR;

        units[0]['N' + value.quarter] = value.myPropertySpaces;
        units[1]['N' + value.quarter] = value.compSetSpaces;
      });

      return occupancy.concat(rpi).concat(amr).concat(units);
    }

    function getMyPropertyCompsDirectory(data) {
      $scope.compPins = pinGeneratorUtil.getCompDirectoryPins(data, $scope, $scope.showComps);
      pcrVm["comps"] = data;      
      $scope.gridOpts_MyPropertyCompDir = {
        columns: [
          {
            field: "pinNumber",
            title: "Map Pin No.",
            headerTemplate: "<span>Map <br/> Pin No.</span>",
            width: "3%",
            attributes: {
              'class': 'text-right'
            },
            filterable: {
              cell: {
                showOperators: false,
                operator: "eq"
              }
            }
          },
          {
            field: 'id',
            title: 'Property ID',
            width: 100,
            attributes: {
              'class': 'text-right'
            },
            filterable: {
              cell: {
                showOperators: false,
                operator: "eq"
              }
            }
          },
          {
            field: 'name',
            title: 'Property Name',
            width: 250,
            template: '<a ui-sref="#= \'propertyDetails({ id: \' + id + \' })\' #" ng-click="propertyDetailsClick(this.dataItem.id, this.dataItem.name)" target="_blank">#= name #</a>',
            filterable: {
              cell: {
                showOperators: false,
                operator: "contains"
              }
            }
          },
          {
            field: 'operator',
            title: 'Operator',
            width: 250,
            template: "#= (operator && operator != 'Unknown Stakeholder') ? kendo.toString(operator) : 'N/A' #",
            filterable: {
              cell: {
                showOperators: false,
                operator: "contains"
              }
            }
          },
          {
            field: 'buildingAgeInYears',
            title: 'Age',
            width: 75,
            format: '{0:n0}',
            attributes: {
              'class': 'text-right'
            },
            filterable: {
              cell: {
                showOperators: false,
                operator: "eq"
              }
            }
          },
          {
            field: 'addressparsed',
            title: 'Address',
            width: 300,
            filterable: {
              cell: {
                showOperators: false,
                operator: "contains"
              }
            }
          },
          {
            field: 'ilUnitsParsed',
            title: 'IL Units',
            attributes: {
              'class': 'text-right'
            },
            filterable: {
              cell: {
                showOperators: false,
                operator: "eq"
              }
            }
          },
          {
            field: 'alUnitsParsed',
            title: 'AL Units',
            attributes: {
              'class': 'text-right'
            },
            filterable: {
              cell: {
                showOperators: false,
                operator: "eq"
              }
            }
          },
          {
            field: 'mcUnitsParsed',
            title: 'MC Units',
            attributes: {
              'class': 'text-right'
            },
            filterable: {
              cell: {
                showOperators: false,
                operator: "eq"
              }
            }
          },
          {
            field: 'ncUnitsParsed',
            title: 'NC Beds',
            attributes: {
              'class': 'text-right'
            },
            filterable: {
              cell: {
                showOperators: false,
                operator: "eq"
              }
            }
          },
          {
            field: 'distanceInMiles',
            title: 'Distance',
            format: '{0:n1}',
            attributes: {
              'class': 'text-right'
            },
            filterable: {
              cell: {
                showOperators: false,
                operator: "eq"
              }
            }
          }
        ],
        dataSource: new kendo.data.DataSource({
          pageSize: gridPageSize,
          data: data,
          error: function (err) {
            console.log('error - ' + err);
          },
          schema: {
            model: {
              fields: {
                'pinNumber': { type: 'number' },
                'id': { type: 'number' },
                'name': { type: 'string' },
                'operator': { type: 'string' },
                'buildingAgeInYears': { type: 'number' },
                'ilUnitsParsed' : { type: 'number' },
                'alUnitsParsed' : { type: 'number' },
                'mcUnitsParsed' : { type: 'number' },
                'ncUnitsParsed' : { type: 'number' }
              }
            },
            parse: function (parsedData) {
              _.forEach(parsedData, function (item, idx) {
                item.addressparsed = item.address.addressLine1 + " " + item.address.city + "," + item.address.stateProvinceCode + " " + item.address.zipCode;
                item.ilUnitsParsed = parseInt(item.units.ilUnits) || 0;
                item.alUnitsParsed = parseInt(item.units.alUnits) || 0;
                item.mcUnitsParsed = parseInt(item.units.mcUnits) || 0;
                item.ncUnitsParsed = parseInt(item.units.ncUnits) || 0;
              });
              return parsedData;
            }
          },
          sort: {
            field: "pinNumber",
            dir: "asc"
          },
          serverPaging: false,
          serverFiltering: false,
          serverSorting: false
        }),
        dataBinding: function (e) {
          Nic.kendoGrid.filtersOnTop('gridMyPropertyCompDir');
        },
        filterable: {
          mode: "row"
        },
        scrollable: false,
        sortable: {
          mode: "single",
          allowUnsort: false
        },
        pageable: data && data.length > gridPageSize ? true : false,
        resetFilters: function () {
          this.dataSource.filter([]);
          this.dataSource.sort({ field: "pinNumber", dir: "asc" });
        }
      };
    }

    function getCompSetCharacteristics(data) {
      $scope.compSetSummary = data.compSetSummary;
      pcrVm["compSetSummary"] = data.compSetSummary; // CompSetSummaryModel

      $scope.gridOpts_Top5Operators = {
        columns: [
          {
            field: 'stakeholderName',
            title: 'Operator'
          },
          {
            field: 'propertyCount',
            title: 'Properties',
            attributes: {
              'class': 'text-right'
            }
          },
          {
            field: 'units',
            title: 'Units',
            format: '{0:N0}',
            attributes: {
              'class': 'text-right'
            }
          },
          {
            field: 'unitPercentage',
            title: 'Comp Share*',
            format: '{0:P0}',
            attributes: {
              'class': 'text-right'
            }
          },
          {
            field: 'cbsaPercentage',
            title: 'Metro Share',
            format: '{0:P0}',
            attributes: {
              'class': 'text-right'
            }
          }
        ],
        dataSource: new kendo.data.DataSource({
          data: data.stakeHolderSummary,
          serverPaging: false,
          serverFiltering: false,
          serverSorting: false
        }),
        scrollable: false,
        sortable: false,
        pageable: false
      };

      pcrVm["stakeHolderSummary"] = data.stakeHolderSummary; // StakeHolderSummaryModel

      $scope.gridOpts_CompSegments = {
        columns: [{
          field: "displayName",
          title: " ",
          template: "<span ng-class=\"{'appendTab': #= displayName != 'Seniors Housing' && displayName != 'Nursing Care' # }\">#= displayName #</span>",
          attributes: {
            'class': 'text-left'
          }
        }, {
          title: " ",
          columns: [{
            title: "Inventory",
            field: "spaces",
            attributes: {
              'class': 'text-right'
            }
          }]
        }, {
          title: "Occupancy",
          columns: [{
            field: "occupancyRaw",
            title: currentQuarter,
            template: "#= (occupancyRaw == 'Protected') ? occupancyRaw : (!isNaN(parseFloat(occupancyRaw))) ? kendo.format('{0:p1}',parseFloat(occupancyRaw)) : ' ' #",
            attributes: {
              'class': 'text-right'
            }
          }, {
            field: "occupancyRaw_QOQ_BPS",
            title: "Quarterly Change",
            template: "#= (occupancyRaw_QOQ_BPS == 'Protected') ? occupancyRaw_QOQ_BPS : (!isNaN(parseFloat(occupancyRaw_QOQ_BPS))) ? kendo.format('{0:n0}',parseFloat(occupancyRaw_QOQ_BPS)) : ' ' #",
            attributes: {
              'class': 'text-right'
            }
          }, {
            field: "occupancyRaw_YOY_BPS",
            title: "Annual Change",
            template: "#= (occupancyRaw_YOY_BPS == 'Protected') ? occupancyRaw_YOY_BPS : (!isNaN(parseFloat(occupancyRaw_YOY_BPS))) ? kendo.format('{0:n0}',parseFloat(occupancyRaw_YOY_BPS)) : ' ' #",
            attributes: {
              'class': 'text-right'
            }
          }]
        }, {
          title: "Average Rent",
          columns: [{
            field: "amr",
            title: currentQuarter,
            template: "#= (amr == 'Protected') ? amr : (!isNaN(parseFloat(amr))) ? kendo.format('{0:c0}',parseFloat(amr)) : ' ' #",
            attributes: {
              'class': 'text-right'
            }
          }, {
            field: "amR_QoQ",
            title: "Quarterly Change*",
            template: "#= (amR_QoQ == 'Protected') ? amR_QoQ : (!isNaN(parseFloat(amR_QoQ))) ? kendo.format('{0:p1}',parseFloat(amR_QoQ)) : ' ' #",
            attributes: {
              'class': 'text-right'
            }
          }
          , {
            field: "amR_YoY",
            title: "Annual Change*",
            template: "#= (amR_YoY == 'Protected') ? amR_YoY : (!isNaN(parseFloat(amR_YoY))) ? kendo.format('{0:p1}',parseFloat(amR_YoY)) : ' ' #",
            attributes: {
              'class': 'text-right'
            }
          }]
        }],
        dataSource: new kendo.data.DataSource({
          data: data.compSegments,
          serverPaging: false,
          serverFiltering: false,
          serverSorting: false,
          schema: {
            model: {
              fields: {
                'displayName': { type: 'string' }
              }
            },
            parse: function (data) {
              _.each(data, function (item, idx) {
                item.displayName = item.segmentType;
                if (item.segmentTypeCode == 'IL' || item.segmentTypeCode == 'AL' || item.segmentTypeCode == 'MC') {
                  item.displayName = item.segmentTypeCode;
                }
              });
              return data;
            }
          }
        }),
        scrollable: false,
        sortable: false,
        pageable: false
      };

      pcrVm["compSegments"] = data.compSegments; // CompSegmentModel

      $scope.gridOpts_RoomUnitData = {
        columns: [{
          field: "segmentType",
          title: "Segment",
          attributes: {
            'class': 'text-left'
          }
        }, {
          field: "roomUnitType",
          title: "Unit Type",
          attributes: {
            'class': 'text-left'
          }
        },
        {
          title: "Inventory",
          columns: [
            {
              field: "subject_Space_Inventory",
              title: "Property",
              attributes: {
                'class': 'text-right'
              }
            },
            {
              field: "comp_Space_Inventory",
              title: "Comp Set",
              attributes: {
                'class': 'text-right'
              }
            }]
        },
        {
          title: "Unit Mix",
          columns: [
            {
              field: "subject_UnitMix",
              title: "Property",
              template: "#= (subject_UnitMix == 'Protected') ? subject_UnitMix : (!isNaN(parseFloat(subject_UnitMix))) ? kendo.format('{0:p1}',parseFloat(subject_UnitMix)) : ' ' #",
              attributes: {
                'class': 'text-right'
              }
            },
            {
              field: "comp_UnitMix",
              title: "Comp Set",
              template: "#= (comp_UnitMix == 'Protected') ? comp_UnitMix : (!isNaN(parseFloat(comp_UnitMix))) ? kendo.format('{0:p1}',parseFloat(comp_UnitMix)) : ' ' #",
              attributes: {
                'class': 'text-right'
              }
            }]
        },
        {
          title: "Occupancy",
          columns: [
            {
              field: "subject_AllOccupancy_Pct",
              title: "Property",
              template: "#= (subject_AllOccupancy_Pct == 'Protected') ? subject_AllOccupancy_Pct : (!isNaN(parseFloat(subject_AllOccupancy_Pct))) ? kendo.format('{0:p1}',parseFloat(subject_AllOccupancy_Pct)) : ' ' #",
              attributes: {
                'class': 'text-right'
              }
            },
            {
              field: "comp_AllOccupancy_Pct",
              title: "Comp Set",
              template: "#= (comp_AllOccupancy_Pct == 'Protected') ? comp_AllOccupancy_Pct : (!isNaN(parseFloat(comp_AllOccupancy_Pct))) ? kendo.format('{0:p1}',parseFloat(comp_AllOccupancy_Pct)) : ' ' #",
              attributes: {
                'class': 'text-right'
              }
            }]
        },
        {
          title: "Average Rent",
          columns: [
            {
              field: "subject_AMR",
              title: "Property",
              template: "#= (subject_AMR == 'Protected') ? subject_AMR : (!isNaN(parseFloat(subject_AMR))) ? kendo.format('{0:c0}',parseFloat(subject_AMR)) : ' ' #",
              attributes: {
                'class': "#= (subject_AMR == 'Protected') ? 'text-left' : 'text-right' #"
              }
            },
            {
              field: "comp_AMR",
              title: "Comp Set",
              template: "#= (comp_AMR == 'Protected') ? comp_AMR : (!isNaN(parseFloat(comp_AMR))) ? kendo.format('{0:c0}',parseFloat(comp_AMR)) : ' ' #",
              attributes: {
                'class': 'text-right'
              }
            }]
        },
        {
          title: "Entrance Fee",
          columns: [
            {
              field: "subject_EntranceFee",
              title: "Property",
              template: "#= (subject_EntranceFee == 'Protected') ? subject_EntranceFee : (!isNaN(parseFloat(subject_EntranceFee))) ? kendo.format('{0:c0}',parseFloat(subject_EntranceFee)) : ' ' #",
              attributes: {
                'class': 'text-right'
              }
            },
            {
              field: "comp_EntranceFee",
              title: "Comp Set",
              template: "#= (comp_EntranceFee == 'Protected') ? subject_EntranceFeecomp_EntranceFee : (!isNaN(parseFloat(comp_EntranceFee))) ? kendo.format('{0:c0}',parseFloat(comp_EntranceFee)) : ' ' #",
              attributes: {
                'class': 'text-right'
              }
            }]
        }],
        dataSource: new kendo.data.DataSource({
          data: data.roomUnitData,
          serverPaging: false,
          serverFiltering: false,
          serverSorting: false,
          schema: {
            model: {
              fields: {
                'segmentAndUnitType': { type: 'string' }
              }
            },
            parse: function (data) {
              _.each(data, function (item) {
                item.segmentAndUnitType = item.segmentTypeCode + ' ' + item.roomUnitType;
              });
              return data;
            }
          }
        }),
        scrollable: false,
        sortable: false,
        pageable: false
      };

      pcrVm["roomUnitData"] = data.roomUnitData; // RoomUnitDataModel

      drawUnitTypeChartData($scope.chartOccUnitType, data.roomUnitData, 'comp_AllOccupancy_Pct');
      drawUnitTypeChartData($scope.chartAMRUnitType, data.roomUnitData, 'comp_AMR');
    }

    function drawUnitTypeChartData(chart, data, fieldName) {
      var unProtectedData = _.filter(data, function (datum) {
        var currentVal = datum[fieldName];
        return currentVal && currentVal != 'Protected' && currentVal != 'N/A';
      });
      var categories = _.map(unProtectedData, function (item) {
        return item.segmentTypeCode + ' ' + item.roomUnitType;
      });
      redrawChart(chart, unProtectedData, categories);
    }

    $scope.getTransactions = function(transactions){
      $scope.gridOpts_Transaction = {
        columns: [
        {
          field: "pinNumber",
          title: "Map Pin No.",
          headerTemplate: "<span>Map <br/> Pin No.</span>",
          width: "3%",
          attributes: {
              'class': 'text-right'
          },
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          }
        },
        {
          field: "propertyId",
          title: "propertyID",
          hidden: "hidden"
        },
        {
          field: "saleDate",
          title: "Sale Date",
          format: "{0:MM/dd/yyyy}",
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          }
        },
        {
          field: "propertyName",
          title: "Property Name",
          template: '<a ng-show="#= propertyId #" ui-sref="#= \'propertyDetails({ id: \' + propertyId + \' })\' #" ng-click="propertyDetailsClick(this.dataItem.propertyId, this.dataItem.name)" target="_blank">#= propertyName #</a><span ng-hide="#= propertyId #">#= propertyName #</span>',
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        },
        {
          field: "address",
          title: "Address",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          },
          template: '#:address# #:city# #:state# #:zip#'
        },
        {
          field: "cbsa",
          title: "Metro",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        },
        {
          field: "propertyAndCampus",
          headerTemplate: '<span>Property Type / <br> Campus Type</span>',
          template: '<span> #:propertyType# <br> #:campusType# </span>',
          width: 100,
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        },
        {
          field: "SpaceCounts",//Added this dummy field just to get the sorting arrow.
          title: "Space Counts",
          filterable: {
            cell: {
              enabled: false
            }
          },
          template: '<div class="text-nowrap">IL Units : #:ilUnitCount#<br/>AL Units : #:alUnitCount# <br/>MC Units : #:mcUnitCount# <br/>NC Beds : #:ncUnitCount# </div>'
        },
        {
          field: "buyerandseller",
          title: "Buyer/Seller",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          },
          template: "<span popover-trigger='mouseenter' uib-popover-html='generateTransactionTooltipText(dataItem.buyerCapitalInvestmentTypeObj)' >Buyer: #:buyerCompanyName#</span></br><span popover-trigger='mouseenter' uib-popover-html='generateTransactionTooltipText(dataItem.sellerCapitalInvestmentTypeObj)'>Seller: #:sellerCompanyName#</span>"
        },
        {
          field: "closePrice",
          title: "Close Price",
          format: "{0:c0}",
          attributes: {
            'class': 'text-right'
          },
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          }
        },
        {
          field: "pricePerUnit",
          title: "Price Per Unit",
          format: "{0:c0}",
          attributes: {
            'class': 'text-right'
          },
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          },
        },
        {
          field: "distanceFromCenter",
          title: "Distance",
          attributes: {
            'class': 'text-right'
          },
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          }
        }],
        dataSource: new kendo.data.DataSource({
          data: transactions,
          pageSize: gridPageSize,
          error: function (err) {
            console.log('error - ' + err);
          },
          schema: {
            model: {
              fields: {
                'pinNumber': { type: 'number'},
                'saleDate': { type: 'date' },
                'closePrice': { type: 'number' },
                'pricePerUnit': { type: 'number' },
                'closeCapRate': { type: 'number' },
                'distanceFromCenter' : { type: 'string' }
              }
            },
            parse: function (data) {
              _.forEach(data, function (item, idx) {
                item.buyerCapitalInvestmentTypeObj = { description: item.buyerCapitalInvestmentType || '' };
                item.sellerCapitalInvestmentTypeObj = { description: item.sellerCapitalInvestmentType || '' };
                item.buyerandseller = item.buyerCompanyName + " " + item.sellerCompanyName;
                item.propertyAndCampus = item.propertyType + " " + item.campusType;
                item.distanceFromCenter = kendo.toString(item.distanceFromCenter, 'n1');
              });
              return data;
            }
          },
          sort: {
            field: "saleDate",
            dir: "desc"
          },
          serverPaging: false,
          serverFiltering: false,
          serverSorting: false
        }),
        dataBinding: function (e) {
          Nic.kendoGrid.filtersOnTop('gridTransaction');
        },
        filterable: {
          mode: "row"
        },
        scrollable: false,
        sortable: {
          mode: "single",
          allowUnsort: false
        },
        pageable: transactions && transactions.length > gridPageSize ? true : false,
        resetFilters: function () {
          this.dataSource.filter([]);
          this.dataSource.sort({ field: "saleDate", dir: "desc" });
        }
      };
    }

    function getTransAndConstruction(data) {
      //Transaction
      var transactionArray = pinGeneratorUtil.getTransactionPins( data.transactions, $scope.fiveRadius.locations, $scope.tenRadius.locations, $scope, $scope.showTransaction);
      $scope.transactions.properties = transactionArray.mapPins;
      $scope.transactions.fiveMiles = transactionArray.tradeAreaOne;
      $scope.transactions.tenMiles = transactionArray.tradeAreaTwo;
      $scope.transactions.spaces = transactionArray.spaces;
      pcrVm["transactions"] = transactionArray.properties;
      $scope.getTransactions(transactionArray.properties);

      //create pins for each construction property      
      var constructionArray = pinGeneratorUtil.generatePropertyData( data.constructionInventory, reportSectionTypeConstant.construction, $scope.fiveRadius.locations, $scope.tenRadius.locations, $scope, $scope.showConstruction);
      $scope.constructionInventory.properties = constructionArray.mapPins;
      $scope.constructionInventory.fiveMiles = constructionArray.tradeAreaOne;
      $scope.constructionInventory.tenMiles = constructionArray.tradeAreaTwo;
      $scope.constructionInventory.spaces = constructionArray.spaces;
      pcrVm["constructionInventory"] = constructionArray.properties;

      $scope.gridOpts_ConstructionInventory = {
        columns: [
        {
          field: "id",
          title: "propertyID",
          hidden: "hidden",
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          }
        }, 
        {
          field: "pinNumber",
          title: "Map Pin No.",
          headerTemplate: "<span>Map <br/> Pin No.</span>",
          width: "3%",
          attributes: {
              'class': 'text-right'
          },
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          }
        },
        {
          field: "constructionOpenDate",
          title: "U/C Scheduled Open Date",
          headerTemplate: '<span>U/C Scheduled <br> Open Date</span>',
          format: "{0:MM/dd/yyyy}",
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          }
        },
        {
          field: "name",
          title: "Property Name",
          template: '<a ui-sref="#= \'propertyDetails({ id: \' + id + \' })\' #" ng-click="propertyDetailsClick(this.dataItem.id, this.dataItem.name)" target="_blank">#= name #</a>',
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "address.addressLine1",
          title: "Address",
          template: '#:address.addressLine1# #:address.city# #:address.stateProvinceCode# #:address.zipCode#',
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        },
        {
          field: "cbsaName",
          title: "Metro",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        },
        {
          field: "typeDescription",
          headerTemplate: '<span>Property Type / <br> Campus Type</span>',
          template: '<span> #:typeDescription# <br> #:campusTypeDescription# </span>',
          width: 100,
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          },
        },
        {
          field: "constructionStatus",
          title: "Construction Type",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        },
        {
          field: "units",
          title: "Inventory Under Construction",
          headerTemplate: "<span>Inventory <br/> Under Construction</span>",
          filterable: {
            cell: {
              enabled: false
            }
          },
          template: '<div class="text-nowrap">IL Units : #:units.ilConstructionUnits#<br/>AL Units : #:units.alConstructionUnits# <br/>MC Units : #:units.mcConstructionUnits# <br/>NC Beds : #:units.ncConstructionUnits# </div>'
        },
        {
          field: "operator",
          title: "Operator",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          },
          template: "#= (operator && operator != 'Unknown Stakeholder') ? kendo.toString(operator) : 'N/A' #"
        }, {
          field: "distanceInMiles",
          title: "Distance",
          format: '{0:n1}',
          attributes: {
            'class': 'text-right'
          },
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          }
        }],
        dataSource: new kendo.data.DataSource({
          data: constructionArray.properties,
          pageSize: gridPageSize,
          error: function (err) {
            console.log('error - ' + err);
          },
          schema: {
            model: {
              fields: {
                'pinNumber': { type: 'number' },
                'id': { type: 'number' },
                'name': { type: 'string' },
                'address.addressLine1': { type: 'string' },
                'city': { type: 'string' },
                'stateProvinceCode': { type: 'string' },
                'zipCode': { type: 'string' },
                'constructionStatus': { type: 'string' },
                'constructionOpenDate': { type: 'date' },
                'distanceInMiles': { type: 'number' }
              }
            }
          },
          sort: {
            field: "pinNumber",
            dir: "asc"
          },
          serverPaging: false,
          serverFiltering: false,
          serverSorting: false
        }),
        dataBinding: function (e) {
          Nic.kendoGrid.filtersOnTop('gridConstructionInventory');
        },
        filterable: {
          mode: "row"
        },
        scrollable: false,
        sortable: {
          mode: "single",
          allowUnsort: false
        },
        pageable: constructionArray.properties && constructionArray.properties.length > gridPageSize ? true : false,
        resetFilters: function () {
          this.dataSource.filter([]);
          this.dataSource.sort({ field: "pinNumber", dir: "asc" });
        }
      };
    }

    function getHospitals(data) {
      $scope.hospitals.medicareAPIFailure = data.medicareAPIFailure;
      var hospitalArray = pinGeneratorUtil.generatePropertyData( data.hospitals, reportSectionTypeConstant.hospital, $scope.fiveRadius.locations, $scope.tenRadius.locations, $scope, $scope.showHospitals);
      $scope.hospitals.properties = hospitalArray.mapPins;
      $scope.hospitals.fiveMiles = hospitalArray.tradeAreaOne;
      $scope.hospitals.tenMiles = hospitalArray.tradeAreaTwo;
      pcrVm["hospitals"] = hospitalArray.properties;
      
      $scope.gridOpts_Hospital = {
        columns: [
        {
          field: "pinNumber",
          title: "Map Pin No.",
          headerTemplate: "<span>Map <br/> Pin No.</span>",
          width: "3%",
          attributes: {
            'class': 'text-right'
          },
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          }
        }, {
          field: "name",
          title: "Hospital Name",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "type",
          title: "Hospital Type",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "ownership",
          title: "Ownership Type",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "address",
          title: "Address",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "city",
          title: "City",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "state",
          title: "State",
          width: "3%",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "zipCode",
          title: "ZIP",
          width: "3%",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "emergencyServices",
          title: "Emergency Services",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "distance",
          title: "Distance",
          format: '{0:n1}',
          width: "3%",
          attributes: {
            'class': 'text-right'
          },
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          }
        }],
        dataSource: new kendo.data.DataSource({
          data: hospitalArray.properties,
          pageSize: gridPageSize,
          error: function (err) {
            console.log('error - ' + err);
          },
          schema: {
            model: {
              fields: {
                'pinNumber': { type: 'number' },
                'name': { type: 'string' },
                'type': { type: 'string' },
                'ownership': { type: 'string' },
                'address': { type: 'string' },
                'city': { type: 'string' },
                'state': { type: 'string' },
                'zipCode': { type: 'string' },
                'emergencyServices': { type: 'string' },
                'distance': { type: 'number' }
              }
            }
          },
          sort: {
            field: "pinNumber",
            dir: "asc"
          },
          serverPaging: false,
          serverFiltering: false,
          serverSorting: false
        }),
        dataBinding: function (e) {
          Nic.kendoGrid.filtersOnTop('gridHospital');
        },
        filterable: {
          mode: "row"
        },
        scrollable: false,
        sortable: {
          mode: "single",
          allowUnsort: false
        },
        pageable: hospitalArray.properties.length > gridPageSize ? true : false,
        resetFilters: function () {
          this.dataSource.filter([]);
          this.dataSource.sort({ field: "pinNumber", dir: "asc" });
        }
      };
    }

    $scope.popoverTotalSpacesContent = function (spaces) {
      var content = (spaces.IL != "0" ? '<b>IL:</b> ' + spaces.ILFormatted() : '')
        + (spaces.AL != "0" ? ' <b>AL:</b> ' + spaces.ALFormatted() : '')
        + (spaces.MC != "0" ? ' <b>MC:</b> ' + spaces.MCFormatted() : '')
        + (spaces.NC != "0" ? ' <b>NC:</b> ' + spaces.NCFormatted() : '');
      return spaces.trusted[content] || (spaces.trusted[content] = $sce.trustAsHtml(content));
    }

    $scope.popoverConstructionRingContent = function (ring) {
      var content = (ring.expansionCount != "0" ? '<b>Expansion:</b> ' + ring.expansionCount : '')
        + (ring.newCount != "0" ? ' <b>New Construction:</b> ' + ring.newCount : '')

      return ring.trusted[content] || (ring.trusted[content] = $sce.trustAsHtml(content));
    }

    $scope.generateTransactionTooltipText = function (dataItem) {
      var content = dataItem.description ? '<b>' + dataItem.description + '</b>' : '';
      return dataItem.tooltip || (dataItem.tooltip = $sce.trustAsHtml(content));
    };

    function backToDetails() {
      var portId = $stateParams.portfolioId;
      var propId = $stateParams.propertyId; // a placeholder for now
      $state.go("portfolios.details", { id: parseInt(portId) });
    }

    function compAnalysisSegmentTypeChanged() {
      var selectedSegmentCode = $scope.compAnalysisSelectedSegment;
      AuditService.logEvent(knownEventConstant.selectSegmentPCR.id, knownEventConstant.selectSegmentPCR.message + " " + selectedSegmentCode, 
        knownProductConstant.port, $stateParams.propertyId);

      var categories = _.map($scope.compBandwidthfilteredBySegment[selectedSegmentCode], function (data) {
        return data.quarter;
      });
      var segmentInfo = _.find($scope.compAnalysisSegments, ['code', selectedSegmentCode]);

      // comp analysis
      redrawChart(segmentInfo.rptCompAnalysisScope.chartCompAnalysis, $scope.compAnalysisFilteredBySegmentData[selectedSegmentCode], categories);
      checkChartDataForOverlay(segmentInfo.rptCompAnalysisScope);
      $scope.gridCompAnalysis.dataSource.read();

      // bandwidth
      var compBandwidthData = $scope.compBandwidthfilteredBySegment[selectedSegmentCode];
      redrawChart(segmentInfo.rptCompBandwidthIndexScope.chartOccAnalysis, compBandwidthData, categories);
      redrawChart(segmentInfo.rptCompBandwidthIndexScope.chartOccIndex, compBandwidthData, categories);
      redrawChart(segmentInfo.rptCompBandwidthIndexScope.chartRPIAnalysis, compBandwidthData, categories);
      redrawChart(segmentInfo.rptCompBandwidthIndexScope.chartRPIIndex, compBandwidthData, categories);
      $scope.gridCompBandwidth.dataSource.read();
    }

    function propertyDetailsClick(propId, propName) {
      AuditService.logEvent(knownEventConstant.propertyInformationReport.id, knownEventConstant.propertyInformationReport.message + " - " + propName,
        knownProductConstant.port, propId);
    }

    function getIndexColor(e) {
      if (e.value < .95) {
        return "red";
      }
      else if (e.value >= .95 && e.value < 1) {
        return "#F7D4CC";
      }
      else if (e.value >= 1 && e.value <= 1.05) {
        return "#CCE6CC";
      }
      else if (e.value > 1.05) {
        return "green";
      }
    }

    function exportExcel() {
      SpinnerService.start();
      var title = 'Portfolio ' + $scope.subjectProperty.name;
      PropertyCompareService
        .getExcel(title, new Date(), pcrVm)
        .then(function (data) {
          var excelFile = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          })
        
          var fileName = 'NICMAP_' + title.replace(/ /g, '_') + '_' + currentQuarter + '.xlsx';
          FileSaver.saveAs(excelFile, fileName);
        })
        .finally(function () {
          SpinnerService.stop();
        });
    }

    function tryToConvertToFloat(value) {
      var fvalue = parseFloat(value);
      if (!isNaN(fvalue) ) {
        return fvalue;
      }
     
      return undefined;      
    }

    function redrawChart(chart, data, categories) {
      if (chart && chart.options && chart.options.series) {
        var chartseries = chart.options.series;
        for (var i = 0; i < chartseries.length; i++) {
          chartseries[i].data = data;
        }
        chart.setOptions({
          series: chartseries,
          categoryAxis: { categories: categories }
        });
      }
    }

    function getMinMaxForChart(chart, hasSecondaryAxis) {
      if (chart) {
        var valueOptions = chart._plotArea.valueAxis.plotArea.axisY.options;
        var series1 = {
          majorUnit: valueOptions.majorUnit,
          max: valueOptions.max,
          min: valueOptions.min
        };

        if (!hasSecondaryAxis) {
          return series1;
        }

        var seriesArr = [];
        seriesArr.push(series1);
        var series2ValueOptions = chart._plotArea.axes[2]; // 0 = x-axis, 1 = y-axis (primary), 2 = y-axis (secondary) 
        var series2 = {
          majorUnit: series2ValueOptions.totalMajorUnit,
          max: series2ValueOptions.totalMax,
          min: series2ValueOptions.totalMin
        };
        seriesArr.push(series2);
        return seriesArr;
      }
      return {};
    }

    function pcrToPDF() {
      SpinnerService.start();
      
      var chartInformation = {};
      chartInformation['occupancyByUnitType'] = getMinMaxForChart($scope.chartOccUnitType, false);
      chartInformation['averageRentByUnitType'] = getMinMaxForChart($scope.chartAMRUnitType, false);
      chartInformation['segmentBasedCharts'] = [];
      _.forEach($scope.compAnalysisSegments, function (segment) {
        var chartInfoItem = {};
        var segmentCode = segment.code;
        chartInfoItem['segmentType'] = segmentCode;
        chartInfoItem['compAnalysis'] = getMinMaxForChart(segment.rptCompAnalysisScope.chartCompAnalysis, true);
        chartInfoItem['occAnalysis'] = getMinMaxForChart(segment.rptCompBandwidthIndexScope.chartOccAnalysis, false);
        chartInfoItem['occIndex'] = getMinMaxForChart(segment.rptCompBandwidthIndexScope.chartOccIndex, false);
        chartInfoItem['rpiAnalysis'] = getMinMaxForChart(segment.rptCompBandwidthIndexScope.chartRPIAnalysis, false);
        chartInfoItem['rpiIndex'] = getMinMaxForChart(segment.rptCompBandwidthIndexScope.chartRPIIndex, false);
        chartInformation['segmentBasedCharts'].push(chartInfoItem);
      });
      pcrVm["chartMinMaxInfo"] = chartInformation;

      var title = 'Portfolio ' + pcrVm.propertyInfo.name;
      PropertyCompareService
        .getPdf(pcrVm)
        .then(function (data) {
          var pdfFile = new Blob([data], {
            type: 'application/pdf'
          });
          var fileName = 'NICMAP_' + title.replace(/ /g, '_') + '_' + currentQuarter + '.pdf';
          SpinnerService.stop();
          FileSaver.saveAs(pdfFile, fileName);
        }, function (err) {
          console.log(err);
          SpinnerService.stop();
        })
        .finally(function () {
          SpinnerService.stop();
        });
    }
  }
})();
