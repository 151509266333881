(function () {
  "use strict";

  app.controller('SubscriptionPopupController', SubscriptionPopupController);

  SubscriptionPopupController.$inject = ['$scope',"$state", '$filter', '$stateParams', '$q',
    'SubscriptionDetailsService', 'SubscriptionHistoryService', 'SubscriptionUsersService', 
    'DateOffsetUtils', 'SubscriptionProductMapper', 'subscriptionTypesConstant', 'actionToPerform', 
    'paramsPassedIn', 'newCompanies', 'subscribedCBSAs', 'pageSubInfo',
    'allRegionsAndCBSAs', 'allProducts', 'allSubscriptionTypes', 'SpinnerService', 'knownSubscriptionIds', 'knownEventConstant', 'AuditService'];

  function SubscriptionPopupController($scope, $state, $filter, $stateParams, $q, 
    SubscriptionDetailsService, SubscriptionHistoryService, SubscriptionUsersService, 
    DateOffsetUtils, SubscriptionProductMapper, subscriptionTypesConstant, actionToPerform, 
    paramsPassedIn, newCompanies, subscribedCBSAs, pageSubInfo,
    allRegionsAndCBSAs, allProducts, allSubscriptionTypes, SpinnerService, knownSubscriptionIds, knownEventConstant, AuditService) {

    var isNewSubscription = actionToPerform === "new" ? true : false;
    var isEditSubscription = actionToPerform === "edit" ? true : false;
    var isRenewSubscription = actionToPerform === "renew" ? true : false;
    $scope.actionToPerform = actionToPerform;
    $scope.productSectionDataRetrieved = false;

    var contractValueMessage = "Contract value is required. Please enter a contract value to continue.";
    var subStatus = {
      expired: "Expired",
      active: "Active",
      pending: "Pending"
    };

    $scope.showCompanyDropDown = isNewSubscription ? true : false;
    $scope.hasPortfolioAccess = false;
    $scope.message = '';
    $scope.subscription = {
      startDate: null,
      endDate: null,
      price: null,
      isRenewal: null,
      isTerminated: null
    };
    $scope.toggleTerminate = function(){
      $scope.subscription.isTerminated ? $scope.subscription.endDate = new Date().toLocaleDateString() : $scope.subscription.endDate = null;
    }
    $scope.toggleIsMetroBank = function () {
      if(!$scope.subscription.isMetroBank) {
        $scope.subscription.metrosPurchased = null;
      }
    }
    $scope.saveSubscription = saveSubscription;
    $scope.companies = isNewSubscription && newCompanies ? newCompanies : {};
    $scope.companies.company = {
      id: null,
      name: '',
      contacts:[],
      contact: null
    };
    $scope.onSelectedCallback = onSelectedCallback;
    $scope.formatLabel = formatLabel;

    $scope.allRegionsAndCBSAs = [];
    $scope.scopeTracker = {};
    $scope.getOverallSelectionCount = getOverallSelectionCount;
    $scope.changeCBSAAccessFlag = changeCBSAAccessFlag;
    $scope.checkIfNeedToDisplay = checkIfNeedToDisplay;
    $scope.checkIfNeedToDisplayAsterisk = checkIfNeedToDisplayAsterisk;
    $scope.getPopupHeaderText = getPopupHeaderText;
    $scope.contractValueOptions = { format: "C2" };
    $scope.metrosPurchasedOptions = { decimals: 0, format: '###' };
    $scope.isInProgress = false;

    $scope.disableStartDate = false;
    if (isEditSubscription && paramsPassedIn.activeSubRowInfo.accountStatus == subStatus.expired) {
      // disable start date if an expired subscription is being edited and it has either all expired subscriptions or expired and pending subscriptions
      if (paramsPassedIn.activeSubRowInfo.activeSubscription == undefined || paramsPassedIn.activeSubRowInfo.activeSubscription == null)
        $scope.disableStartDate = true;
    }

    var isSubscriptionPendingWActive = false; // this variable will help when a subscription gettign edited is in pending status but it also has an active subscription

    function init() {
      SubscriptionDetailsService
        .getSubscriptionTypes()
        .then(function (data) {
          data = _.filter(data, function (subType) {
            return subType.id >= subscriptionTypesConstant.port && subType.id < subscriptionTypesConstant.proport;
          });

          $scope.subscriptionTypes = _.orderBy(data, ['subscriptionType'], ['asc']);

          $scope.subscriptionTypes.subscriptionType = {
            id: $scope.subscriptionTypes[0].id,
            subscriptionType: $scope.subscriptionTypes[0].subscriptionType,
            subscriptionCode: $scope.subscriptionTypes[0].subscriptionCode
          };

          if ((isEditSubscription && paramsPassedIn.activeSubRowInfo) || (isRenewSubscription && paramsPassedIn.lastSubscription && paramsPassedIn.lastSubscription.subscriptionTypeId)) {
            // select the correct subscription type
            var subscriptionTypeId;
            if (isEditSubscription) {
              subscriptionTypeId = paramsPassedIn.activeSubRowInfo.subscriptionTypeId;
            }
            else {
              subscriptionTypeId = paramsPassedIn.lastSubscription.subscriptionTypeId;
            }
           
            switch (subscriptionTypeId) {
              case subscriptionTypesConstant.proport:
                subscriptionTypeId = subscriptionTypesConstant.pro;
                $scope.hasPortfolioAccess = true;
                break;
              case subscriptionTypesConstant.locport:
                subscriptionTypeId = subscriptionTypesConstant.loc;
                $scope.hasPortfolioAccess = true;
                break;
              case subscriptionTypesConstant.funport:
                subscriptionTypeId = subscriptionTypesConstant.fun;
                $scope.hasPortfolioAccess = true;
                break;
            }

            var selectedSubscriptionType = _.find($scope.subscriptionTypes, ['id', subscriptionTypeId]);
            $scope.subscriptionTypes.subscriptionType = {
              id: selectedSubscriptionType.id,
              subscriptionType: selectedSubscriptionType.subscriptionType,
              subscriptionCode: selectedSubscriptionType.subscriptionCode
            };
          }
        }, function (err) {
          console.log('getSubscriptionTypes:' + err);
        });

      if (isEditSubscription) {
        // no need to setup start date and end date.
        if (paramsPassedIn.activeSubRowInfo){
          $scope.subscription.startDate = $filter('date')(paramsPassedIn.activeSubRowInfo.startDate, 'MM/dd/yyyy');
          $scope.subscription.endDate = $filter('date')(paramsPassedIn.activeSubRowInfo.endDate, 'MM/dd/yyyy');
          $scope.subscription.price = paramsPassedIn.activeSubRowInfo.price;
          $scope.subscription.isRenewal = paramsPassedIn.activeSubRowInfo.isRenewal;
          $scope.subscription.isTerminated = paramsPassedIn.activeSubRowInfo.isTerminated;
          $scope.subscription.isMetroBank = paramsPassedIn.activeSubRowInfo.isMetroBank;
          $scope.subscription.metrosPurchased = paramsPassedIn.activeSubRowInfo.metrosPurchased;


          // show the renew section if the current subscription getting edited is pending
          // 1) has an active subscription with one/multiple pending subscriptions
          // 2) has expired with one/multiple pending subscriptions
          isSubscriptionPendingWActive = paramsPassedIn.activeSubRowInfo.accountStatus.toLowerCase().indexOf('pending') >= 0
            && $stateParams.id != paramsPassedIn.activeSubRowInfo.id 
            && (paramsPassedIn.activeSubRowInfo.activeSubscription || paramsPassedIn.pendingSubscriptions);
        }
        // need to setup geography section if the subscription being edited is a pending subscription that has an active subscription as well
        if (isSubscriptionPendingWActive) {
          initSubscriptionProductsAccess();
          initGeographicalAccess();
        }
        return;
      }

      if (!isNewSubscription) {
        // seems like it will only go here if the subscription is not being edited and not a new subscription creation
        // so can we use isRenewSubscription flag instead of !isNewSubscription???
        initSubscriptionProductsAccess();
        initGeographicalAccess();
      }
      
      SubscriptionHistoryService
        .getSubscriptionRenewalDate($stateParams.companyId)
        .then(function (data) {
          if (isNewSubscription == false) {
            $scope.subscription.startDate = data.startDate;
            $scope.subscription.endDate = data.endDate;
          }
        }, function (err) {
          console.log('getSubscriptionRenewalDate:' + err);
        });
    }

    function checkIfNeedToDisplay(element) {
      var toReturn = false;
      switch (element){
        case 'newSubscriptionLbl':
        case 'newSubscriptionCtrl':
          if (isNewSubscription)
            toReturn = true;
          break;
        case 'chkTerminate':
          if(isEditSubscription)
            if(paramsPassedIn.activeSubRowInfo.accountStatus ==  subStatus.active || paramsPassedIn.activeSubRowInfo.isTerminated)
              toReturn = true;
          break;
        case 'renewSection':
          if (isRenewSubscription || isSubscriptionPendingWActive)
            toReturn = true;
          break;
        case 'duplicateMessageArea':
          var isRenewSectionVisible = checkIfNeedToDisplay('renewSection');
          if (isRenewSectionVisible && !$scope.isValid && $scope.message == contractValueMessage)
            return true;
          break;
      }
      return toReturn;
    }

    function checkIfNeedToDisplayAsterisk(element) {
      /*
      Add red asterisk next to the field labels for the following fields in the following windows (see screenshot for example of Renew):
        •Renew Subscription: Start Date, End Date, Contract Value
        •Edit Subscription for Active: Start Date, End Date, Contract Value
        •Edit Subscription for Pending: Start Date, End Date, Contract Value
        •Edit Subscription for Expired: End Date, Contract Value
      */
      if (isNewSubscription) return false;
      if (isRenewSubscription) return true;
      // Edit Mode
      if (element == 'endDate' || element == 'contractValue')
        return true;
      if (element == 'startDate' && !$scope.disableStartDate)
        return true;
      return false;
    }

    function getPopupHeaderText() {
      return isNewSubscription ? "Add New Subscription" 
        : (isRenewSubscription ? "Renew Subscription" : "Edit Subscription");
    }

    init();

    $scope.disablePortfolioAccess = false;

    $scope.$watch('subscriptionTypes.subscriptionType', function (newValue, oldValue) {
      if (!angular.equals(newValue, oldValue)) {
        if (subscriptionTypesConstant.port == newValue.id) {
          $scope.disablePortfolioAccess = true;
          // portfolio check box is checked than clear it
          $scope.hasPortfolioAccess = false;
        } else {
          $scope.disablePortfolioAccess = false;
        }
      }
    });

    function onSelectedCallback($item, $model, $label) {
      $scope.companies.company = $item;
      $scope.companies.contacts = [];
      $scope.companies.contact = null;
      if ($item.id) {
        // valid company selected
        SpinnerService.start();
        // fake check to make SAVE disable while users are being retrieved
        $scope.isInProgress = true;
        SubscriptionUsersService
          .search({
            companyName: $item.name,
            firstName: ' ', // need to pass this in to bypass the api side check of passing in at least 2 filters
            subscriptionIdToExclude: knownSubscriptionIds.nmv // exclude nmv from result
          })
          .then(function (users) {
            $scope.isInProgress = false;
            $scope.companies.contacts = _.chain(users)
              .filter(function(user) {
                return user.accountStatus !== 'Active';
              })
              .map(function (user) { 
                return _.extend(user, { 'fullName': user.firstName + ' ' + user.lastName }); 
              })
              .value();
          }, function (err) {
            $scope.isInProgress = false;
            console.error(err);
          })
          .finally(function () {
            $scope.isInProgress = false;
            SpinnerService.stop();
          });
      }
    }

    function formatLabel() {
      return $scope.companies.company.name;
    };

    $scope.$watchGroup(['subscription.startDate', 'subscription.endDate', 'subscription.isMetroBank', 'subscription.metrosPurchased'], function (newValues, oldValues) {
      $scope.isValid = checkValidation();
      if ($scope.isValid && checkIfNeedToDisplay('renewSection')) {
        // if valid and we have subscription type with products shown than we need to check the validation of subscription type
        $scope.$broadcast('parentIsSaveNotAllowed');
      }
    });

    var watchUpdated = false;
    $scope.$watch('subscription.price', function (newValues, oldValues) {
      if (!watchUpdated && newValues == '') {
        watchUpdated = true;
        $scope.subscription.price = null;
      }
      watchUpdated = false;
      $scope.isValid = checkValidation();
      if ($scope.isValid && checkIfNeedToDisplay('renewSection')) {
        // if valid and we have subscription type with products shown than we need to check the validation of subscription type
        $scope.$broadcast('parentIsSaveNotAllowed');
      }
    });

    function checkValidation() {
      $scope.message = '';
      if (isNewSubscription && !$scope.companies.company.id) {
        // since a new subscription... need to have a company selected
        return false;
      }

      if ($scope.subscription.startDate) {
        var convertedStartDate = DateOffsetUtils.convertToDate($scope.subscription.startDate);
        if (isNaN(convertedStartDate)) {
          $scope.message = 'A valid start date for a subscription is required.';
          return false;
        }

        if (isNewSubscription) {
          // we need to make sure the new subscription has the start date that is either current date or a date in future.
          var currentDate = $filter('date')(new Date(), 'MM/dd/yyyy');
          var dateCompare = DateOffsetUtils.compareDates($scope.subscription.startDate, currentDate);
          if (dateCompare == -1) {
            $scope.message = "The Start Date is not valid. Enter today's date or a date in the future to continue.";
            return false;
          }
        }
      } else {
        // start date was empty
        return false;
      }

      if ($scope.subscription.endDate) {
        // verify if startdate is less than or equal to end date
        var dateCompare = DateOffsetUtils.compareDates($scope.subscription.endDate, $scope.subscription.startDate);
        if (isNaN(dateCompare)) {
          $scope.message = 'Specified dates have invalid values.  Please correct them.';
          return false;
        } else if (dateCompare < 0) {
          $scope.message = 'The End Date is not valid. Enter a date that is after the Start Date to continue.';
          return false;
        } else {
          dateCompare = DateOffsetUtils.compareDates($scope.subscription.startDate, $scope.subscription.endDate);
          if (dateCompare > 0) {
            $scope.message = 'The Start Date is not valid. Enter a date that is before the End Date to continue.';
            return false;
          } else {
            if (isEditSubscription){
              // if it is active and the subscription getting edited is the same one then allow
              if (paramsPassedIn.activeSubRowInfo.accountStatus == subStatus.expired && paramsPassedIn.hasActiveOrPendingSub) {
                // When editing an expired subscription with a pending subscription, add validation on End Date that it cannot overlap with any pending subscriptions
                var resultOfCompare = true;
                _.forEach(paramsPassedIn.pendingSubscriptions, function (pendSub) {
                  resultOfCompare = checkIfSelectedDatesAreValid(pendSub, "endDate");
                  if (!resultOfCompare) {
                    var startDateToUse = $filter('date')(paramsPassedIn.pendingSubscriptions[0].startDate, 'MM/dd/yyyy');
                    $scope.message = "The End Date is not valid. Enter a date before " + startDateToUse + " to continue.";
                    return false; // this will break out of _.forEach
                  }
                });

                if (!resultOfCompare) {
                  return false;
                }
              }

              if (paramsPassedIn.activeSubRowInfo.accountStatus == subStatus.active && paramsPassedIn.pendingSubscriptions.length > 0) {
                // When editing an active subscription with a pending subscription, add validation on Start/End Dates so that they cannot overlap with any pending subscriptions.
                var resultOfCompare = true;
                _.forEach(paramsPassedIn.pendingSubscriptions, function (pendSub) {
                  resultOfCompare = checkIfSelectedDatesAreValid(pendSub, "startDate");
                  if (!resultOfCompare) {
                    var startDateToUse = $filter('date')(paramsPassedIn.pendingSubscriptions[0].startDate, 'MM/dd/yyyy');
                    $scope.message = "The Start Date is not valid. Enter a date before " + startDateToUse + " to continue.";
                    return false; // this will break out of _.forEach
                  }

                  resultOfCompare = checkIfSelectedDatesAreValid(pendSub, "endDate");
                  if (!resultOfCompare) {
                    var startDateToUse = $filter('date')(paramsPassedIn.pendingSubscriptions[0].startDate, 'MM/dd/yyyy');
                    $scope.message = "The End Date is not valid. Enter a date before " + startDateToUse + " to continue.";
                    return false; // this will break out of _.forEach
                  }
                });

                if (!resultOfCompare) {
                  return false;
                }
              }

              if (isSubscriptionPendingWActive) {
                // if it is pending with an active subscription then check the dates...
                if (!checkIfSelectedDatesAreValid(paramsPassedIn.activeSubRowInfo.activeSubscription, "both")) {
                  return false;
                }
              }
            } else {
              if (paramsPassedIn.activeSubRowInfo && paramsPassedIn.activeSubRowInfo.length) {
                if (!checkIfSelectedDatesAreValid(paramsPassedIn.activeSubRowInfo[0], "both")) {
                  return false;
                }
              }
            }
          }
        }
      }
      else {
        return false;
      }

      if ($scope.subscription.price == null) {
        // price is always required but for some reason we only want to show the message when we are trying to edit a subscription
        if (isEditSubscription) {
          $scope.message = contractValueMessage;
        }
        return false;
      }

      if ($scope.subscription.isMetroBank) {
        if ($scope.subscription.metrosPurchased === null ||
            $scope.subscription.metrosPurchased === undefined ||
            !_.inRange($scope.subscription.metrosPurchased, 0, 141)) {
          return false;
        }
      }

      return true;
    }

    function checkIfSelectedDatesAreValid(subInfo, whichFieldToCompare) {
      if (subInfo == null) return true;

      // check if the dates are are not in between subscription passed in
      var currentActiveSubStartDate = $filter('date')(subInfo.startDate, 'MM/dd/yyyy');
      var currentActiveSubEndDate = $filter('date')(subInfo.endDate, 'MM/dd/yyyy');
      
      if (whichFieldToCompare == "startDate" || whichFieldToCompare == "both") {
        if (DateOffsetUtils.inRange($scope.subscription.startDate, currentActiveSubStartDate, currentActiveSubEndDate)) {
          // the new subscription being created falls inside the range so show error message
          $scope.message = 'The Start/End dates are not valid. There is an Active Subscription from ' + currentActiveSubStartDate + ' - ' +
          currentActiveSubEndDate + '. Enter a Start Date that begins after the Active Subscription expires to continue.';
          return false;
        }
      }

      if (whichFieldToCompare == "endDate" || whichFieldToCompare == "both") {
        if (DateOffsetUtils.inRange($scope.subscription.endDate, currentActiveSubStartDate, currentActiveSubEndDate)) {
          // the new subscription being created falls inside the range so show error message
          $scope.message = 'The Start/End dates are not valid. There is an Active Subscription from ' + currentActiveSubStartDate + ' - ' +
          currentActiveSubEndDate + '. Enter an End Date that begins after the Active Subscription expires to continue.';
          return false;
        }
      }

      // the new subscription being created falls outside the range of active subscription so let this go through
      return true;
    }

    function getSelectedCbsas() {
      var cbsasAccessTo = [];
      _.forEach($scope.allRegionsAndCBSAs, function (eachRegionAndCBSA) {
        var scopeName = 'RegionId' + eachRegionAndCBSA.id;
        if ($scope.scopeTracker[scopeName].model.length <= 0) return;
        cbsasAccessTo.push(_.map($scope.scopeTracker[scopeName].model, function (cbsa) {
          return cbsa.id;
        }));
      });
      return cbsasAccessTo.join();
    }

    function changeCBSAAccessFlag(marketType, selectionFlag) {
      _.forEach($scope.allRegionsAndCBSAs, function (eachRegionAndCBSA) {
        var scopeName = 'RegionId' + eachRegionAndCBSA.id;
        if (marketType == null) {
          if (selectionFlag) {
            // select all clicked
            $scope.scopeTracker[scopeName].model = eachRegionAndCBSA.cbsAs;
          } else {
            // clear clicked
            $scope.scopeTracker[scopeName].model = [];
          }
        } else {
          var gatherAllCbsas = [];
          _.forEach(eachRegionAndCBSA.cbsAs, function (eachCbsa) {
            if (marketType == eachCbsa.marketType.toLowerCase()) {
              gatherAllCbsas.push(eachCbsa);
            }
          });
          _.forEach($scope.scopeTracker[scopeName].model, function (alreadySelectedCbsas) {
            var hasItInCollectionAlready = gatherAllCbsas.indexOf(alreadySelectedCbsas);
            if (hasItInCollectionAlready >= 0) return;
            gatherAllCbsas.push(alreadySelectedCbsas);
          });
          $scope.scopeTracker[scopeName].model = gatherAllCbsas;
        }
      });
    }

    function getOverallSelectionCount() {
      var selectedCbsas = 0, totalCbsas = 0;
      _.forEach($scope.allRegionsAndCBSAs, function (eachRegionAndCBSA) {
        var scopeName = 'RegionId' + eachRegionAndCBSA.id;
        selectedCbsas += $scope.scopeTracker[scopeName].model.length;
        totalCbsas += eachRegionAndCBSA.cbsAs.length;
      });
      return selectedCbsas + '/' + totalCbsas;
    }

    function initGeographicalAccess() {
      $scope.allRegionsAndCBSAs = _.orderBy(allRegionsAndCBSAs, ['sortOrder'], ['asc']);
      _.forEach($scope.allRegionsAndCBSAs, function (eachRegionAndCBSA, index) {
        var accessedRegions = [];
        _.forEach(eachRegionAndCBSA.cbsAs, function (eachCBSAs) {
          var hasAccess = _.find(subscribedCBSAs, function (eachSubscribedCBSA) {
            return eachSubscribedCBSA.cbsaCode == eachCBSAs.id;
          });
          if (hasAccess) {
            accessedRegions.push(eachCBSAs);
          }
          return eachCBSAs;
        });

        var scopeName = 'RegionId' + eachRegionAndCBSA.id;
        $scope.scopeTracker[scopeName] = {
          name: scopeName,
          model: accessedRegions, //holds the regions that subscription is subscribed to
          settings: {
            bootstrap2: false,
            filterClear: null,//'Show all!',
            filterPlaceHolder: null,//'Filter!',
            moveSelectedLabel: 'Move selected only',
            moveAllLabel: 'Move all!',
            removeSelectedLabel: 'Remove selected only',
            removeAllLabel: 'Remove all!',
            moveOnSelect: false,//true,
            preserveSelection: 'moved',
            selectedListLabel: 'Metro Markets Accessed',
            nonSelectedListLabel: 'Metro Markets Available',
            postfix: scopeName + '_helperz',
            selectMinHeight: 130,
            filter: false,//true,
            filterNonSelected: null,//'1',
            filterSelected: null,//'4',
            infoAll: null,//'Showing all {0}!',
            infoFiltered: null,//'<span class="label label-warning">Filtered</span> {0} from {1}!',
            infoEmpty: null,//'Empty list!',
            filterValues: false//true
          }
        };
      });
    }

    function getSubscriptionProductsData() {
      if (isEditSubscription) {
        var defer = $q.defer();

        SubscriptionDetailsService
          .getSubscriptionProductsData(paramsPassedIn.subscriptionId)
          .then(function (data) {
            defer.resolve(data);
          }, function (err) {
            defer.reject(err);
          });

        return defer.promise;
      } else {
        if (isRenewSubscription && pageSubInfo && pageSubInfo.subscribedProducts) {
          return $q.resolve(pageSubInfo.subscribedProducts);
        }
        return $q.resolve([]);
      }
    }

    function initSubscriptionProductsAccess() {
      getSubscriptionProductsData()
        .then(function (subProds) {
          var currentSubscriptionInfo = {};
          if (isEditSubscription) {
            currentSubscriptionInfo = paramsPassedIn.activeSubRowInfo;
            _.assign(currentSubscriptionInfo, { 'subscriptionTypeID': currentSubscriptionInfo.subscriptionTypeId });
          } else if (isRenewSubscription && pageSubInfo && pageSubInfo.parentPageSubInfo) {
            currentSubscriptionInfo = pageSubInfo.parentPageSubInfo;
          }

          $scope.subProductsData = {
            allSubscriptionTypes: angular.copy(allSubscriptionTypes),
            allProducts: angular.copy(allProducts),
            subscription: currentSubscriptionInfo,
            subscribedProducts: angular.copy(subProds),
            isInPopup: true
          };

          $scope.productSectionDataRetrieved = true;
        });
    }

    $scope.$on('subTypeWProds', function (evt, dataReturned) {
      proceedToSave(dataReturned);
    });

    $scope.$on('isSaveNotAllowed', function (evt, isNotAllowed) {
      $scope.isValid = !isNotAllowed;
      if ($scope.isValid) {
        $scope.isValid = checkValidation();
      }
    });

    function getSelectedSubscriptionTypeAndProducts() {
      if (isNewSubscription) {
        var dataToReturn = {};
        dataToReturn.subscriptionTypeId = $scope.subscriptionTypes.subscriptionType.id;
        if ($scope.hasPortfolioAccess) {
          switch (dataToReturn.subscriptionTypeId) {
            case subscriptionTypesConstant.pro:
              dataToReturn.subscriptionTypeId = subscriptionTypesConstant.proport;
              break;
            case subscriptionTypesConstant.loc:
              dataToReturn.subscriptionTypeId = subscriptionTypesConstant.locport;
              break;
            case subscriptionTypesConstant.fun:
              dataToReturn.subscriptionTypeId = subscriptionTypesConstant.funport;
              break;
          }
        }

        getSelectedProductsForNewSubscription(dataToReturn);
        proceedToSave(dataToReturn);
      } else {
        if (checkIfNeedToDisplay('renewSection')) {
          // get the data from the ng-include view
          $scope.$broadcast('parentSaveClicked');
        } else {
          // This will be the case where an Edit is happening for 
          // 1) an active subscription, or
          // 2) an expired subscription that has all other Expired subscriptions
          // 3) a pending subscription that does not have any active subscription but may have expired subscription(s)
          // for above scenarios we do not allow subscription type to get modified from this popup... 
          // so we can just pass in the same subscription type and subscription products can be null
          var dataToReturn = {};
          dataToReturn.subscriptionTypeId = $scope.subscriptionTypes.subscriptionType.id;
          dataToReturn.subscriptionProducts = null;
          proceedToSave(dataToReturn);
        }
      }
    }

    function getSelectedProductsForNewSubscription(dataToReturn) {
      // get the products based on the subscription type selected
      // needs to be after the subscription types have been confirmed based on portfolio access
      var subNonNatProducts = [];
      var subCode = $scope.subscriptionTypes.subscriptionType.subscriptionCode;
      switch (dataToReturn.subscriptionTypeId) {
        case subscriptionTypesConstant.pro:
        case subscriptionTypesConstant.loc:
        case subscriptionTypesConstant.fun:
        case subscriptionTypesConstant.port:
          var prodsByCode = SubscriptionProductMapper.getAvailableProducts(subCode, allProducts);
          subNonNatProducts = prodsByCode.availableProducts;
          break;
        case subscriptionTypesConstant.proport:
        case subscriptionTypesConstant.locport:
        case subscriptionTypesConstant.funport:
          var prodsByCode = SubscriptionProductMapper.getAvailableProducts(subCode, allProducts);
          var portProds = SubscriptionProductMapper.getAvailableProducts('port', allProducts);
          subNonNatProducts = _.union(prodsByCode.availableProducts, portProds.availableProducts);
          break;
      }

      var prodsToInsert = _.uniqBy(_.flattenDepth(subNonNatProducts), 'id');
      dataToReturn.subscriptionProducts = _.orderBy(prodsToInsert, 'id');
    }

    function saveSubscription() {
      if (!checkValidation()) return;
      
      SpinnerService.start();
      getSelectedSubscriptionTypeAndProducts();
      SpinnerService.start();
    }

    function activateSelectedContact(newSubData) {
      // only do an insertion of a contact if contact was selected
      var selectedContact = $scope.companies.company.contact;
      if (selectedContact && selectedContact.sfContactId) {
        var contactToAssociateInfo = [{
          sfContactId: selectedContact.sfContactId,
          firstName: selectedContact.firstName,
          lastName: selectedContact.lastName,
          email: selectedContact.email,
          isActive: false,
          accountAdmin: true,
          demographicUser: false
        }];
        return SubscriptionUsersService
          .associateContacts(newSubData.subscriptionId, contactToAssociateInfo)
          .then(function (rspSuccess) {
            // console.log(rspSuccess);
            return newSubData;
          }, function (err) {
            console.error(err);
            return newSubData;
          });
      }
      return $q.resolve(newSubData);
    }

    function proceedToSave(subTypeWProds) {
      $scope.isInProgress = true;
      var salesforceIdNameToPassIn = isNewSubscription ? $scope.companies.company.id : SubscriptionDetailsService.getCompany().salesforceIdName;
      var dataToSave = {
        startDate: $scope.subscription.startDate,
        endDate: $scope.subscription.endDate,
        price: $scope.subscription.price,
        isRenewal: $scope.subscription.isRenewal,
        isTerminated: $scope.subscription.isTerminated,
        isNewSub: isNewSubscription,
        currentSubscriptionId: paramsPassedIn.subscriptionId,
        geographicAccess: null,
        subscriptionTypeId: null,
        subscriptionProducts: [],
        clientApiSubscriptionTypeId: null,
        isMetroBank: $scope.subscription.isMetroBank,
        metrosPurchased: $scope.subscription.metrosPurchased,
        defaultURL: $state.href('home', {}, {
          absolute: true
        })
      };

      dataToSave.subscriptionTypeId = subTypeWProds.subscriptionTypeId;
      dataToSave.subscriptionProducts = subTypeWProds.subscriptionProducts;
      dataToSave.geographicAccess = getSelectedCbsas();
      dataToSave.clientApiSubscriptionTypeId = subTypeWProds.clientApiSubscriptionTypeId;

      if (isEditSubscription) {
        // complete: update will update the products and geography section of CBSA... This should only be allowed when the user is edting a pending subscription with an active subscription available
        // partial: is just an update type so that it only updates the startdate, enddate, and price on the API side.
        SubscriptionDetailsService
          .updateSubscriptionAccess(paramsPassedIn.subscriptionId, isSubscriptionPendingWActive ? 'complete' : 'partial', dataToSave)
          .then(function (rspSuccess) {
            // most of the time user should be redirected to an active subscription
            // for the scenario where all subscription are expired for a company then the passed in subscription id will be used
            var subscriptionIdToPassIn = paramsPassedIn.activeSubRowInfo.activeSubscription ?
               paramsPassedIn.activeSubRowInfo.activeSubscription.id : $stateParams.id;

            $scope.$close({ subscriptionId: subscriptionIdToPassIn, companyId: paramsPassedIn.companyId });
            $scope.isInProgress = false;
          }, function (rspError) {
            console.log(rspError);
            $scope.isInProgress = false;
          });
      } else {
        SubscriptionDetailsService
          .addSubscription(salesforceIdNameToPassIn, dataToSave)
          .then(function (newSubInfo) {
            // attach any selected user to the new subscription
            return activateSelectedContact(newSubInfo);
          })
          .then(function(newSubInfo){
            if (!isNewSubscription ){
              return newSubInfo;
            }
            else{
              var messageToLog = 'Created Subscription ID {subscriptionID} for {companyName} with {subscriptionType}, {startDate} - {endDate}, {contractValue}, {metrosPruchased} Metros Purchased, Account Admin {adminFullName}'
                .replace('{subscriptionID}', newSubInfo.subscriptionId)
                .replace('{companyName}', $scope.companies.company.name)
                .replace('{subscriptionType}', !$scope.hasPortfolioAccess ? $scope.subscriptionTypes.subscriptionType.subscriptionType : $scope.subscriptionTypes.subscriptionType.subscriptionType + ' & Portfolios' )
                .replace('{startDate}', dataToSave.startDate)
                .replace('{endDate}', dataToSave.endDate)
                .replace('{contractValue}', $filter('currency')(dataToSave.price))
                .replace('{metrosPruchased}', dataToSave.isMetroBank ? dataToSave.metrosPurchased : null)
                .replace('{adminFullName}', $scope.companies.company.contact ?  $scope.companies.company.contact.fullName : null);
              return AuditService
                .logEvent(knownEventConstant.adminAddNewSubscription.id, messageToLog, null, null, null, newSubInfo.subscriptionId)
                .then(function(){return newSubInfo;});
            }
          })
          .then(function (newSubInfo) {
            // pass in the companyid and subscriptionid back to the controller so the page can be refreshed.
            var subscriptionIdToPassIn;
            if (isNewSubscription || (!isNewSubscription && !paramsPassedIn.hasActiveOrPendingSub)) {
              // take the new subscriptionid if the user is creating new subscription
              // or the user is renewing a subscription that had all expired subscriptions
              subscriptionIdToPassIn = newSubInfo.subscriptionId;
            } else {
              subscriptionIdToPassIn = paramsPassedIn.subscriptionId;
            }
            $scope.$close({ subscriptionId: subscriptionIdToPassIn, companyId: newSubInfo.companyId });
            $scope.isInProgress = false;
          });
      }
    }
  }
})();
