(function () {
  "use strict";

  app.controller('AccountDetailsController', AccountDetailsController);

  AccountDetailsController.$inject = ['$scope', '$http', '$q', 'AuthService', 'apiSettings', '$state'];

  function AccountDetailsController($scope, $http, $q, authService, apiSettings, $state) {

    $scope.name = authService.identity.firstName() + " " + authService.identity.lastName();
    $scope.isAccountAdmin = authService.identity.isInRole('NIC.SubscriptionAdmin');

  };
})();
