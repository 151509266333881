(function() {
  "use strict";
  app.directive('quickSavedSearch', QuickSavedSearchDirective);
  function QuickSavedSearchDirective() {
    return {
      templateUrl: '/app/partials/quickSavedSearch.html',
      controllerAs: 'quickSaved',
      controller: QuickSavedSearchController,
      scope: {}
    }
  }

  QuickSavedSearchController.$inject = ['$scope','$state', 'SearchService','PopupHandlerService'];
  function QuickSavedSearchController($scope, $state, SearchService, PopupHandlerService) {
    var vm = this;
    vm.selectedSearch = {};
    vm.localSearchesDataSource = [];

    vm.init = function() {
      SearchService.getSavedSearches().then(function(data) {
        vm.localSearchesDataSource = data;
        vm.selectedSearch = _.head(data);
      });
    }

    vm.submitSearch = function() {
      if(!_.has(vm.selectedSearch, 'id')) {
        PopupHandlerService.openSimplePopup("Please fill in search.")
        return;
      }
      $state.go("localpropertysearch.base", {
        id: vm.selectedSearch.id
      });
    }

    vm.init();
  }
})();