(function () {
  "use strict";

  app.factory("WalkScoreService", WalkScoreService);
  
  WalkScoreService.$inject = ['$http', '$q', 'apiSettings'];
  
  function WalkScoreService($http, $q, apiSettings) {
    var cachedWalkScores = []; // in this cache... key is assumed to be a string that is an int... so use it wisely

    return {
      getWalkScoreForProperties: _.debounce(_.memoize(getWalkScoreForProperties), 250, { leading: true, trailing: false }),
      getWalkScoreForLocation: getWalkScoreForLocation
    };

    function getWalkScoreForProperties(properties) {
      if (!properties || !properties.length) return $q.resolve(properties);

      var needWalkScore = [];
      _.forEach(properties, function (property, idx) {
        if (!property.id || !property.address) return; // return is basically continue
        var hasBeenCached = _.find(cachedWalkScores, ['key', property.id.toString()]);
        if (hasBeenCached)
          property.walkScoreInfo = hasBeenCached;
        else
          needWalkScore.push({
            id: property.id,
            address: {
              addressLine1: property.address.addressLine1,
              city: property.address.city,
              stateProvinceCode: property.address.stateProvinceCode,
              zipCode: property.address.zipCode,
            },
            latitude: property.latitude,
            longitude: property.longitude
          });
      });

      if (!needWalkScore.length) return $q.resolve(properties);

      var defer = $q.defer();
      var url = apiSettings.url + "/walkScore/getWalkScoreForProperties";
      $http
        .post(url, needWalkScore)
        .then(function(rsp) {
          if (rsp.data) {
            _.forEach(rsp.data, function (walkScoreCacheResponse) {
              var property = _.find(properties, ['id', parseInt(walkScoreCacheResponse.key)]);
              if (property)
                property.walkScoreInfo = walkScoreCacheResponse;

              var isCached = _.find(cachedWalkScores, ['key', walkScoreCacheResponse.key]);
              if (!isCached)
                cachedWalkScores.push(walkScoreCacheResponse);
            });
          }
          defer.resolve(properties);
        }, function(err) {
          //defer.reject(err);
          defer.resolve(properties);
        });

      return defer.promise;
    }

    function getWalkScoreForLocation(query, latitude, longitude) {
      var defer = $q.defer();
      var url = apiSettings.url + "/walkScore/getWalkScoreForLocation?" +
        $.param({
          query: query,
          latitude: latitude,
          longitude: longitude
        });

      $http
        .get(url)
        .then(function(rsp) {
          defer.resolve(rsp.data);
        }, function(err) {
          defer.reject(err);
        });

      return defer.promise;
    }
  }
})();
