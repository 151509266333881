(function() {
  "use strict";

  app.factory("SubscriptionDetailsService", SubscriptionDetailsService);

  SubscriptionDetailsService.$inject = ["$http", "$q", "apiSettings", "CacheFactory", "iqDataAccessProdGroupConstant"];

  function SubscriptionDetailsService($http, $q, apiSettings, CacheFactory, iqDataAccessProdGroupConstant) {

    var companyInfo = null;
    var subscriberInfo = null;
    var subscribedProducts = null;
    var allProducts = null;
    var subscribedCBSAs = null;
    var allRegionsAndCBSAs = null;
    var allSubscriptionTypes = null;
    var subscriptionHistory = null;
    var clientApiKeys = null;
    var metroBankHistory = null;

    if (!CacheFactory.get('subDetailsCache')) {
      CacheFactory.createCache('subDetailsCache', {
        maxAge: 12 * 60 * 60 * 1000, // Items added to this cache expire after 12 hours
        cacheFlushInterval: 24 * 60 * 60 * 1000, // This cache will clear itself every day
        deleteOnExpire: 'aggressive', // Items will be deleted from this cache when they expire
        storageMode: 'sessionStorage'
      });
    }
    var subDetailsCache = CacheFactory.get('subDetailsCache');

    return {
      getSubscriptionData: getSubscriptionData,
      getSubscriptionProductsData: getSubscriptionProductsData,
      getAllProductGroupsAndProductsData: getAllProductGroupsAndProductsData,
      getSubscriptionCBSAsData: getSubscriptionCBSAsData,
      getAllRegionsAndCBSAsData: getAllRegionsAndCBSAsData,
      getCompanyData: getCompanyData,
      getSubscriber: getSubscriber,
      setSubscriber: setSubscriber,
      setSubscribedProducts: setSubscribedProducts,
      setAllProducts: setAllProducts,
      setSubscribedCBSAs: setSubscribedCBSAs,
      setAllRegionsAndCBSAs: setAllRegionsAndCBSAs,
      getSubscribedProducts: getSubscribedProducts,
      getAllProducts: getAllProducts,
      getSubscribedCBSAs: getSubscribedCBSAs,
      getAllRegionsAndCBSAs: getAllRegionsAndCBSAs,
      getCompany: getCompany,
      setCompany: setCompany,
      getSubscriptionTypes: getSubscriptionTypes,
      setAllSubscriptionTypes: setAllSubscriptionTypes,
      getAllSubscriptionTypes: getAllSubscriptionTypes,
      updateSubscriptionAccess: updateSubscriptionAccess,
      addSubscription: addSubscription,
      setSubscriptionHistory: setSubscriptionHistory,
      getSubscriptionHistory: getSubscriptionHistory,
      refreshFromSalesforce: refreshFromSalesforce,
      setClientApiKeys: setClientApiKeys,
      getClientApiKeys: getClientApiKeys,
      getMetroBankHistory: getMetroBankHistory, 
      setMetroBankHistory: setMetroBankHistory 
    };

    function getCompanyData(companyId) {
      var defer = $q.defer();
      var appendUrl = "/company/" + companyId;
      var completeUrl = apiSettings.url + appendUrl;

      $http
        .get(completeUrl)
        .then(function(rsp) {
          defer.resolve(rsp.data);
        }, function(err) {
          defer.reject(err);
        });
      return defer.promise;
    }

    function getSubscriptionData(subscriptionId) {
      var defer = $q.defer();
      var appendUrl = "/subscriptions/GetSubscription?subscriptionID=" + subscriptionId;

      $http
        .get(apiSettings.url + appendUrl)
        .then(function(rsp) {
          defer.resolve(rsp.data);
        }, function(err) {
          defer.reject(err);
        });

      return defer.promise;
    }

    function getSubscriptionProductsData(subscriptionId) {
      var defer = $q.defer();

      var appendUrl = "/subscriptions/GetProductsBySubscriptionID?subscriptionID=" + subscriptionId;

      $http
        .get(apiSettings.url + appendUrl)
        .then(function(rsp) {
          defer.resolve(rsp.data);
        }, function(err) {
          defer.reject(err);
        });

      return defer.promise;
    }

    function getAllProductGroupsAndProductsData() {
      var defer = $q.defer();
      var appendUrl = "/subscriptions/GetAllProductGroupsAndProducts";

      $http
        .get(apiSettings.url + appendUrl, { cache: subDetailsCache })
        .then(function (rsp) {
          // need to change some product text just for the UI screen
          _.each(rsp.data, function (obj) {
            _.each(obj.products, function (product) {
              if (product.id == iqDataAccessProdGroupConstant.iqLocal || product.id == iqDataAccessProdGroupConstant.iqTrends) {
                product.name = product.name.replace('Intra-Quarterly ', '');
              }
            });
          });

          defer.resolve(rsp.data);
        }, function(err) {
          defer.reject(err);
        });

      return defer.promise;
    }

    function getSubscriptionCBSAsData(subscriptionId) {
      var defer = $q.defer();
      var appendUrl = "/subscriptions/GetCBSAsBySubscriptionID?subscriptionID=" + subscriptionId;

      $http
        .get(apiSettings.url + appendUrl)
        .then(function(rsp) {
          defer.resolve(rsp.data);
        }, function(err) {
          defer.reject(err);
        });

      return defer.promise;
    }

    function getAllRegionsAndCBSAsData() {
      var defer = $q.defer();

      var appendUrl = "/subscriptions/GetAllRegionsAndCBSAs";

      $http
        .get(apiSettings.url + appendUrl, { cache: subDetailsCache })
        .then(function(rsp) {
          defer.resolve(rsp.data);
        }, function(err) {
          defer.reject(err);
        });

      return defer.promise;
    }

    function setCompany(value) {
      companyInfo = value;
    }

    function getCompany() {
      return companyInfo;
    }

    function setSubscriber(value) {
      subscriberInfo = value;
    }

    function getSubscriber() {
      return subscriberInfo;
    }

    function setSubscribedProducts(value) {
      subscribedProducts = value;
    }

    function getSubscribedProducts() {
      return subscribedProducts;
    }

    function setAllProducts(value) {
      allProducts = value;
    }

    function getAllProducts() {
      return allProducts;
    }

    function setSubscribedCBSAs(value) {
      subscribedCBSAs = value;
    }

    function getSubscribedCBSAs() {
      return subscribedCBSAs;
    }

    function setAllRegionsAndCBSAs(value) {
      allRegionsAndCBSAs = value;
    }

    function getAllRegionsAndCBSAs() {
      return allRegionsAndCBSAs;
    }

    function setAllSubscriptionTypes(value) {
      allSubscriptionTypes = value;
    }

    function getAllSubscriptionTypes() {
      return allSubscriptionTypes;
    }

    function getSubscriptionTypes() {
      var defer = $q.defer();
      var uri = apiSettings.url + "/subscriptions/getSubscriptionTypes";

      $http
        .get(uri, { cache: subDetailsCache })
        .then(function(rsp) {
          defer.resolve(rsp.data);
        }, function(err) {
          defer.reject(err);
        });

      return defer.promise;
    }

    function updateSubscriptionAccess(subscriptionId, updateType, dataToSave) {
      var defer = $q.defer();
      var uri = apiSettings.url + "/subscriptions/access/" + subscriptionId + "/" + updateType + "/update";

      // Need to make the product name back to the original for logging since the API uses this product name instead of looking up the real product name by the id.
      dataToSave.subscriptionProducts = _.map(dataToSave.subscriptionProducts, function (product) {
        if (product.id == iqDataAccessProdGroupConstant.iqLocal || product.id == iqDataAccessProdGroupConstant.iqTrends) {
          return { id: product.id, name: 'Intra-Quarterly ' + product.name, selected: product.selected }
        }

        return product;
      });

      $http
        .put(uri, dataToSave)
        .then(function(rsp) {
          defer.resolve(rsp);
        }, function(err) {
          defer.reject(err);
        });

      return defer.promise;
    }

    function addSubscription(salesforceIdNameToPassIn, dataToSave) {
      var defer = $q.defer();
      var uri = apiSettings.url + "/subscriptions/create/" + salesforceIdNameToPassIn;

      $http
        .post(uri, dataToSave)
        .then(function(rsp) {
          defer.resolve(rsp.data);
        }, function(err) {
          defer.reject(err);
        });

      return defer.promise;
    }

    function setSubscriptionHistory(value) {
      subscriptionHistory = value;
    }

    function getSubscriptionHistory() {
      return subscriptionHistory;
    }

    function refreshFromSalesforce(subscriptionId) {
      var deferred = $q.defer();
      var uri = apiSettings.url + '/subscriptions/' + subscriptionId + '/refreshsalesforce';

      $http
        .post(uri)
        .then(function (rsp) {
          if (rsp.data.byteLength == 0) {
            deferred.reject();
          } else {
            deferred.resolve(rsp.data);
          }
        }, function (err) {
          deferred.reject(err);
        });

      return deferred.promise;
    }

    function setClientApiKeys(value) {
      clientApiKeys = value;
    }

    function getClientApiKeys() {
      return clientApiKeys;
    }

    function setMetroBankHistory(value) {
      metroBankHistory = value;
    }

    function getMetroBankHistory() {
      return metroBankHistory;
    }

  }
}());