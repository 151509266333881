(function () {
  "use strict";

  app.controller('AdminController', AdminController);

  AdminController.$inject = ['$scope', '$state'];

  function AdminController($scope, $state) {
    $scope.onTabClick = onTabClick;

    $scope.tabs = [
      {
        heading: "Subscriptions",
        route: "admin.subscriptions",
        active: false,
        isActive: function () {
          return $state.is(this.route);
        }
      }, {
        heading: "Portfolios",
        route: "admin.portfolios",
        active: false,
        isActive: function () {
          return $state.is(this.route);
        }
      }, {
        heading: "Site Tools",
        route: "admin.sitetools",
        active: false,
        isActive: function () {
          return $state.is(this.route);
        }
      }, {
        heading: "Usage",
        route: function () {
          if (!$state.includes('admin.usage')) {
            $state.go('admin.usage.clientApi')
          }
        },
        active: false,
        isActive: function () {
          return $state.includes('admin.usage');
        }
      }
    ];

    $scope.tabs.forEach(function (tab) {
      tab.active = tab.isActive();
    });

    function onTabClick(route) {
      if (typeof route === 'string'){
        $state.go(route);
      } else if (typeof route === 'function') {
        route();
      }
    }
  }
})();
