(function () {
  /////////////////////////////////////////////////////////////////
  // http://jsfiddle.net/mrajcok/W8nhv/
  /////////////////////////////////////////////////////////////////

  "use strict";
  app.directive('onFinishRender', onFinishRender);
  onFinishRender.$inject = ['$timeout'];
  
  function onFinishRender($timeout) {
  	function link(scope, element, attrs) {
  	  if (scope.$last === true) {
  	    scope.$evalAsync(attrs.onFinishRender);
  	  }
  	}

  	return {
  	  restrict: 'A',
      link: link
  	};
  }
})();