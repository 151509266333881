(function() {
  "use strict";
  app.directive('backToTop', backToTop);
  backToTop.$inject = ['$anchorScroll', 'navBarHeight'];
  
  function backToTop($anchorScroll, navBarHeight) {
  	function link(scope, element, attrs) {
  		$anchorScroll.yOffset = navBarHeight;
  		scope.scrollTo = function(section) {
  			$anchorScroll(section);
  		};
  	}
  	return {
      scope: {},
  		template: '<a class="backToTop" href="javascript:void(0)" ng-click="scrollTo(\'Top\')"><i class="glyphicon glyphicon-chevron-up"></i></a>',
  		link: link
  	};
  }
})();