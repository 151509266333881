(function () {
  "use strict";
  app.directive('browserHelper', ['deviceDetector', function(deviceDetector) {
    function displaySafariWarning(){
        $.alert({
            closeIcon: false,
            title: '',
            content: 'To log into this site from your Apple device, please change the Safari privacy settings for cookies to Always Allow.',
            confirmButtonClass: 'btn btn-primary'
        });
    }

    function link(scope, element, attrs) {
      if(deviceDetector.browser == "safari"){
        displaySafariWarning();
      }
    }

    return {
      link: link
    };
  }]);
})();
