(function () {
  "use strict";

  //because this template needs to get passed to the bing API we can't use a regular directive template
  var template = '/app/partials/customSearchInfoBox.html';
  app.directive('nicCustomSearchInfoBox', customSearchInfoBoxDirective);
  customSearchInfoBoxDirective.$inject = ['$http', '$q', '$interpolate', '$uibModal', 'DemographicService', 'AuthService', 'GeoDataService', 
    'PopupHandlerService', 'AuditService', 'knownEventConstant'];
  
  function customSearchInfoBoxDirective($http, $q, $interpolate, $uibModal, DemographicService, AuthService, GeoDataService,
    PopupHandlerService, AuditService, knownEventConstant) {
    //get the infobox template once
    var htmlTemplateCompiled;
    var templatePromise = $q.defer();
    $http.get(template)
      .then(function (rsp) {
        var compiled = $interpolate(rsp.data); //prepare the angular document to binding
        htmlTemplateCompiled = compiled;
        templatePromise.resolve(compiled);
      });

    return {
      link: link,
      restrict: 'E',
      //scope: false,//works, but you have to match scope.foo to what ever is in the ng-repeat="foo in bar"
      scope: {
        pin: '=',
        tradeAreaShapes: '<'
      },
      require: ['^bingMap', '^pushpin']
    };

    function link(scope, element, attrs, ctrls) {
      ///scope section
      //positionUpdated broadcast when the parent pushpin is placed
      scope.$on('positionUpdated', function (event, location) {
        if (!location.latitude || !location.longitude) {
          return;
        }
        if (currentLocation.latitude == location.latitude && currentLocation.longitude == location.longitude) {
          return;
        }

        currentLocation.latitude = location.latitude;
        currentLocation.longitude = location.longitude;
        currentCBSAAndCountyInfo = null;

        geolocationPromise = GeoDataService.geocodeLocation(location.latitude, location.longitude);
        geolocationPromise
          .then(function (rsp) {
            var cbsaAndCountyInfo = { 
              cbsaName: rsp.data.cbsaName,
              cbsaCode: rsp.data.cbsaCode,
              countyName: rsp.data.countyName,
              countyFips: rsp.data.countyFIPS,
              hasAccess : false
            };
            cbsaAndCountyInfo.hasAccess = AuthService.identity.hasAccessToCBSA(rsp.data.cbsaCode)
            currentCBSAAndCountyInfo = cbsaAndCountyInfo;
            if (htmlTemplateCompiled) {
              templateDoneAndPinPositioned(htmlTemplateCompiled, location, cbsaAndCountyInfo);
            }
          });
      });

      scope.$on("infoBoxToggled", infoBoxToggled); //happens ANYtime ANY info box is opened or closed
      scope.$on("closeCustomSearchPinInfobox", closeCustomSearchPinInfobox);
      scope.$on('$destroy', unregisterEventListeners);
      element.on('$destroy', unregisterEventListeners);
      //initalization section

      var options = getDefaultOptions();

      //set the infobox
      var infobox = new Microsoft.Maps.Infobox(new Microsoft.Maps.Location(0, 0), options);
      infobox.setMap(ctrls[0].map);

      function getDefaultOptions() {
        return {
          visible: false,
          offset: new Microsoft.Maps.Point(-150, 50),
          width: 300
        };
      }

      var visible = false;
      var currentLocation = { latitude: 0, longitude: 0 };
      var currentCBSAAndCountyInfo = null;
      var geolocationPromise;
      var closePinId = "#closePin" + scope.pin.id;

      templatePromise.promise.then(function (templateFunction) {
        if (currentCBSAAndCountyInfo != null) {
          templateDoneAndPinPositioned(templateFunction, currentLocation, currentCBSAAndCountyInfo);
        }
      });

      //get notified when the pushpin for this infobox is placed
      var positionPromise;
      positionPromise = $q.defer();

      function templateDoneAndPinPositioned(templateFunction, location, cbsaAndCountyInfo) {
        //compile the html content that the info box will use
        scope.pin.cbsaName = cbsaAndCountyInfo.cbsaName;
        scope.pin.cbsaCode = cbsaAndCountyInfo.cbsaCode;
        scope.pin.countyName = cbsaAndCountyInfo.countyName;
        scope.pin.countyFips = cbsaAndCountyInfo.countyFips;
        scope.pin.shape1 = cbsaAndCountyInfo.shape1;
        scope.pin.shape2 = cbsaAndCountyInfo.shape2;

        var domTemplate = templateFunction(scope);
        var htmlContentElement = $(document.createElement('div')).append(domTemplate);
        if (!cbsaAndCountyInfo.hasAccess) {
          //remove link from html if user doesn't have access to CBSA
          var linkElement = $(htmlContentElement).find("#ssiPopupInit");
          if (linkElement.length > 0) {
            linkElement.remove();
          }
        }
        var htmlContent = htmlContentElement.html();

        // set info options for new html
        infobox.setOptions({ htmlContent: htmlContent, visible: false });  // turn off while moving
        updateLocation(location);  //move to new pin location
      }

      function updateLocation(location) {
        if (location.latitude && location.longitude) {
          infobox.setOptions({ offset: getDefaultOptions().offset }); // put back to default offset
          infobox.setLocation(new Microsoft.Maps.Location(location.latitude, location.longitude));
        }
      }

      function closeCustomSearchPinInfobox() {
        if(visible) { //different pin was clicked on so close this box
          updateVisibility(false);
        }
      }

      function infoBoxToggled(event, clickedPin) {
        if (infobox) {
          if (clickedPin.type != 'site') {
            // a diferent type of infobox is getting shown or hidden so just hide this one
            updateVisibility(false);
          } else {
            if (scope.pin.id == clickedPin.id) {
              updateVisibility(!visible);
              return;
            }
            if (visible) { //different pin was clicked on so close this box
              updateVisibility(false);
            }
          }
        }
      }

      function updateVisibility(newVisibility) {
        if (!infobox) {
          return;
        }

        if(!newVisibility && !visible){
          return;//no work necessary
        }
        visible = newVisibility;
        infobox.setOptions({
          visible: visible,
          offset: new Microsoft.Maps.Point(-150, 50)
        });
        if(!visible){
          return;//rest of function has to do with positioning infobox, wiring in button click events, setting the checkbox's checked
        }

        var currentOffset = infobox.getOffset();
        var infoboxLocation = ctrls[0].map.tryLocationToPixel(infobox.getLocation(), Microsoft.Maps.PixelReference.control);
        var mapWidth = ctrls[0].map.getWidth();
        var directions = checkOverEdge(currentOffset.x, currentOffset.y, infobox, infoboxLocation, mapWidth);
        //does infobox need repositioned?
        if (currentOffset.x != directions.x || currentOffset.y != directions.y) {
          infobox.setOptions({
            offset: directions
          });
        }

        //add click event after re-positioning because re-positioning can re-move the click event.
        $(closePinId).off("click");
        $('#ssiPopupInit').off("click");
        $('#ssiPopupInit').on("click", function () {
            var paramsToPass = {
              'areaAccessed': scope.pin.query, 
              'latitude': scope.pin.latitude,
              'longitude': scope.pin.longitude,
              'cbsaName': scope.pin.cbsaName,
              'cbsaCode': scope.pin.cbsaCode,
              'countyName': scope.pin.countyName,
              'countyFips': scope.pin.countyFips,
              'tradeAreaShapes': scope.tradeAreaShapes()
            };

            AuditService.logEvent(knownEventConstant.siteInformationReport.id, knownEventConstant.siteInformationReport.message + " - " + scope.pin.query,
              scope.pin.product);
              
            DemographicService
              .runSiteInformationReport(paramsToPass)
              .then(function (data) {
                PopupHandlerService.openReportInNewTab('siteInformationReport', data, 'Site Information Report');
              });            
        });

        $(closePinId).on("click",function(){
          updateVisibility(false);
        });
      }

      function unregisterEventListeners() {
        $(closePinId).off("click");
        $('#ssiPopupInit').off("click");

        if (infobox) {
          infobox.setMap(null);
          infobox = null;
        }
      }
    }

    function checkOverEdge(offsetX, offsetY, infobox, infoboxLocation, mapWidth) {
      var directions = new Microsoft.Maps.Point(offsetX, offsetY);
      var infoboxAnchor = infobox.getAnchor();
      var height = infobox.getHeight();
      var offetDistance = directions.y;
      var distanceAbovePinNeeded = height + offetDistance;
      var distanceToTopEdge = infoboxLocation.y - distanceAbovePinNeeded;
      if(distanceToTopEdge < 0) {
        //console.log("Hanging over top by " + distanceToTopEdge + " pixels");
        directions.y = height * -1;
      }
      var distanceToLeftEdge = infoboxLocation.x + directions.x;
      if(distanceToLeftEdge < 0) {
        //console.log("Hanging over left by " + distanceToLeftEdge + " pixels");
        directions.x = infoboxLocation.x * -1; //flush against edge
        return directions;
      }
      var infoBoxWidth = infobox.getWidth();
      var pixelsToRightSideOfBox = infoboxLocation.x + directions.x + infoBoxWidth;

      var pixelsPastRightEdge = mapWidth - (infoboxLocation.x + directions.x + infoBoxWidth);
      if(pixelsPastRightEdge < 0) {
        //console.log("Hanging over right by " + pixelsPastRightEdge + " pixels");
        directions.x = mapWidth - infoboxLocation.x - infoBoxWidth;
      }
      return directions;
    }
  }
}());
