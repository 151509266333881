(function () {
  'use strict';
  app.directive('nicDraggable', DraggableDirective);

  DraggableDirective.$inject = ['$document', 'ElementSizeWatcher'];
  function DraggableDirective($document, ElementSizeWatcher) {
    function link(scope, element, attr) {
      var instanceId = scope.id;
      var dragHandle = element.find('[nic-draggable-handle]').first();
      var container = $document.find('[nic-draggable-container="' + instanceId + '"]').first();
      if (!instanceId) { throw new Error('nic-draggable requires a unique Id'); }
      if (!dragHandle.length) { throw new Error('This directive requires a handle attach drag events to. This is added by the "nic-draggable-handle" attribute'); }
      if (!container.length) { throw new Error('This directive requires a container to define the draggable bounds. This is added by the "nic-draggable-container" attribute'); }

      var startX; // represents the beginning position of the click/drag
      var startY; // represents the beginning position of the click/drag
      var bounds;
      var hasBeenDragged = false;

      var startingPosX = convertPercentageToPixels(scope.onLoadX, container.outerWidth());
      var startingPosY = convertPercentageToPixels(scope.onLoadY, container.outerHeight());
      var x = startingPosX || 0; // Future pull from localstorage, then check startX then default to 0
      var y = startingPosY || 0;

      // when css changes or window changes need to verify it is still within bounds after
      var sizeWatcher = new ElementSizeWatcher(container.get(0), 500);
      scope.$watchGroup(sizeWatcher.group, reset);

      scope.$watch('enabled', reset);

      // Attach click events to the handle
      dragHandle.on('mousedown', function (event) {
        if (!scope.enabled) { return; }
        hasBeenDragged = true;
        dragHandle.addClass('active');
        event.preventDefault();
        startX = event.screenX - x;
        startY = event.screenY - y;
        $document.on('mousemove', onMouseMove);
        $document.on('mouseup', onMouseUp);

        bounds = getBounds();
      });

      function onMouseMove(event) {
        x = event.screenX - startX;
        y = event.screenY - startY;

        updatePosition();
      }

      function onMouseUp(event) {
        dragHandle.removeClass('active');
        cleanUpEventHandlers();
      }

      function getBounds() {
        var bounds = {};
        bounds.minX = container.offset().left;
        bounds.minY = 0;
        bounds.maxX = bounds.minX + container.outerWidth() - element.outerWidth();
        bounds.maxY = bounds.minY + container.outerHeight() - element.outerHeight();
        return bounds;
      }

      function updatePosition() {
        // Prevent going outside of boundaries
        x = Math.max(bounds.minX, Math.min(x, bounds.maxX));
        y = Math.max(bounds.minY, Math.min(y, bounds.maxY));

        element.css({
          top: y + 'px',
          left: x + 'px'
        });
      }

      // if disabled remove inline css created by this directive
      // if enabled return to previous placement or close as possible due to changed bounds
      function reset() {
        if (scope.enabled) {
          dragHandle.addClass('cursor-grabbable');
          bounds = getBounds();

          if(!hasBeenDragged) {
            x = convertPercentageToPixels(scope.onLoadX, container.outerWidth());
            y = convertPercentageToPixels(scope.onLoadY, container.outerHeight());
          }

          updatePosition();
        } else {
          dragHandle.removeClass('cursor-grabbable');
          element.css({
            top: '',
            bottom: '',
            left: '',
            right: ''
          });
        }
      }

      function cleanUpEventHandlers(event) {
        $document.off('mousemove', onMouseMove);
        $document.off('mouseup', cleanUpEventHandlers);
      }

      function convertPercentageToPixels(input, domProperty) {
        if (_.endsWith(input, '%')) {
          input = parseInt(input); // just pulls the number out (kinda magic)
          return (input / 100) * domProperty;
        }
        return parseInt(input);
      }

      element.css({
        position: 'absolute',
      });

      element.on('$destroy', function () { cleanUpEventHandlers(); sizeWatcher.cancel(); });
    }

    return {
      restrict: 'A',
      link: link,
      scope: {
        id: '@nicDraggable', // pass in a unique string to ID this instance with its helper attributes
        enabled: '<nicDraggableEnabled', // should drag handle events be captured
        onLoadX: '<nicDraggableStartX', // x pos of element (can be percentage string)
        onLoadY: '<nicDraggableStartY' // y pos of element (can be percentage string)
      }
    }
  }
})();
