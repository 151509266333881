(function() {
  "use strict";
  app.controller('AreaAccessController', AreaAccessController);

  AreaAccessController.$inject = ['$scope', '$state'];

  function AreaAccessController($scope, $state) {
    var title = '';
    switch ($state.params.productCode) {
      case "PropertyInformationReport": 
        title = "Property Information Report";
        break;
      case "CustomCohortDemographics": 
        title = "Custom Cohort Demographics";
        break;
      case "PropertyAdvisorReport":
        title = "Property Advisor Report";
        break;
    }
    $scope.productName = title;
  } 
})();