(function() {
  "use strict";
  app.factory("SearchService", SearchService);

  SearchService.$inject = ["$http", "$q", '$sce', "apiSettings"];

  function SearchService($http, $q, $sce, apiSettings) {

    return {
      getSegmentTypes: getSegmentTypes,
      getSegmentTypesPortfolio: getSegmentTypesPortfolio,
      constructionStatuses: constructionStatuses,
      propertyTypes: propertyTypes,
      searchBing: searchBing,
      getSavedSearches: getSavedSearches,
      getSavedSearch: getSavedSearch,
      getRecentSearch: getRecentSearch,
      getSearchAll: getSearchAll,
      getAgeCategory: getAgeCategory,
      getProfitStatus: getProfitStatus,
      searchStakeHolders: searchStakeHolders,
      getRadius: getRadius,
      search: search,
      exportProperties: exportProperties,
      createSavedSearch: createSavedSearch,
      deleteSavedSearchQuery: deleteSavedSearchQuery,
      getDriveTimeOptions: getDriveTimeOptions,
      propertyLocations: propertyLocations,
      getVintages: getVintages
    };

    function getProfitStatus() {
      return [getDescriptionIdObj("All Profit Statuses", null),
        getDescriptionIdObj("For Profit", "true"),
        getDescriptionIdObj("Non Profit", "false")
      ];
    };

    function getDriveTimeOptions() {
      return {
        breakouts: [
          { id: null, description: 'No Drive Time' },
          { id: 5, description: '5 Minutes' },
          { id: 10, description: '10 Minutes' },
          { id: 15, description: '15 Minutes' },
          { id: 20, description: '20 Minutes' }
        ],
        trafficFlows: [
          { id: 'High', description: 'High Traffic' },
          { id: 'Medium', description: 'Medium Traffic' },
          { id: 'Low', description: 'Low Traffic' }
        ]
      }
    };

    function searchStakeHolders(search) {
      var deferred = $q.defer();
      if(search) {
        $http.get(apiSettings.url + "/localpropertysearch/StakeHolder?search=" + search)
          .then(function(rsp) {
              deferred.resolve(rsp.data);
            },
            function() {
              deferred.reject();
            });
      } else {
        deferred.resolve([]);
      }

      return deferred.promise;
    };

    function getRadius() {
      return [getDescriptionIdObj("No Radius", null),
        getDescriptionIdObj("1 Mile", 1),
        getDescriptionIdObj("3 Miles", 3),
        getDescriptionIdObj("5 Miles", 5),
        getDescriptionIdObj("7 Miles", 7),
        getDescriptionIdObj("10 Miles", 10),
        getDescriptionIdObj("15 Miles", 15),
        getDescriptionIdObj("20 Miles", 20),
        getDescriptionIdObj("25 Miles", 25),
        getDescriptionIdObj("30 Miles", 30),
        getDescriptionIdObj("Custom", -1)
      ]
    };

    function getDescriptionIdObj(d, i) {
      return {
        description: d,
        id: i
      };
    }

    function searchBing(query) {
      var deferred = $q.defer();

      var uri = "//dev.virtualearth.net/REST/v1/Locations/{query}?inclnb=1&incl=ciso2&maxRes=50&key={key}";
      var search = uri
        .replace("{key}", apiSettings.bingMapsKey)
        .replace("{query}", query);

      $http
        .jsonp($sce.trustAsResourceUrl(search), {
          jsonpCallbackParam: 'jsonp'
        })
        .then(function (rsp) {
          if(!rsp.data.resourceSets[0].resources || !rsp.data.resourceSets[0].resources.length) {
            deferred.reject("No results for the query within the United States");
            return;
          }
          if (rsp.data.resourceSets[0].resources[0].address && rsp.data.resourceSets[0].resources[0].address.countryRegionIso2 != "US") {
            deferred.reject("No results for the query within the United States");
            return;
          }

          var bingResource = rsp.data.resourceSets[0].resources[0];
          var point = bingResource.point.coordinates;
          var entityType = bingResource.entityType;

          // IMPORTANT:  Entity type is not guaranteed to be correct... It seems like any set of combination in query that has a zipcode
          // will always become entity type of 'Postalcode1' causing our search functionality to break in the proc.
          // please use parameters like 'Confidence' and exact query match to detect a "Postalcode1" search
          // There are tests for this now.
          var result = {
            latitude: point[0],
            longitude: point[1],
            entityType: entityType,
            bbox: bingResource.bbox,
            query: query
          };
          if(entityType == "AdminDivision2") {
            result.countyName = bingResource.name;
            result.stateCode = bingResource.address.adminDistrict;
          } else if (entityType == 'Postcode1' && bingResource.address && bingResource.address.postalCode === query) {
            result.zipCode = query;
          }
          //uncomment this to limit pins to only the state searched for.
          //else if(entityType == 'AdminDivision1') {
          //result.stateCode = rsp.data.resourceSets[0].resources[0].address.adminDistrict;
          //}
          deferred.resolve(result);
        }, function(err) {
          deferred.reject(err);
        });


      return deferred.promise;
    }

    function apiGetCall(apiCallName, jsonParams) {
      var defer = $q.defer();
      $http
        .get(apiSettings.url + apiCallName, jsonParams)
        .then(function(rsp) {
          defer.resolve(rsp.data);
        }, function(err) {
          defer.reject(err);
        });

      return defer.promise;
    }

    function apiPostExportCall(apiCallName, jsonParams) {
      return apiPostCall(apiCallName, jsonParams, {
        responseType: 'arraybuffer'
      });
    }

    function apiPostCall(apiCallName, jsonParams, responseType) {
      var deferred = $q.defer();
      $http.post(apiSettings.url + apiCallName, jsonParams, responseType)
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });

      return deferred.promise;
    }

    function getAgeCategory() {
      return apiGetCall("/localpropertysearch/AllPropertyAgeCategories");
    }

    function saveRecentSearch() {
      return apiPostCall('/localpropertysearch/savedsearch/SaveRecentSearch');
    }

    function getRecentSearch() {
      return apiGetCall('/localpropertysearch/savedsearch/GetRecentSearch');
    }

    function getSavedSearches() {
      return apiGetCall('/localpropertysearch/savedsearch/');
    }

    function getSavedSearch(searchId) {
      return apiGetCall('/localpropertysearch/savedsearch/' + searchId);
    }

    function getSegmentTypesPortfolio() {
      return apiGetCall('/segmentType/portfolio');
    }

    function getSegmentTypes() {
      return apiGetCall('/segmentType/local');
    }

    function propertyTypes() {
      return apiGetCall("/localpropertysearch/propertytypes");
    }

    function getVintages() {
      return apiGetCall("/localpropertysearch/vintages");
    }

    function search(queryAndFilters, searchButtonClicked, isAdvancedSearchButtonClick, currentZoom) {
      var typeOfSearch = (queryAndFilters.transactionOnly ? "transaction" : "") + "search";
      var isButtonClickSearch = searchButtonClicked || false;
      var isAdvancedSearchButtonClickSearch = isAdvancedSearchButtonClick || false;

      queryAndFilters.search.zoom = currentZoom;

      var uri = apiSettings.url + "/localpropertysearch/" + typeOfSearch + "?searchClicked=" + isButtonClickSearch + "&advancedClick=" + isAdvancedSearchButtonClickSearch;

      var deferred = $q.defer();

      var isEmpty = isEmptySearch(queryAndFilters);
      var boundingBoxIsTooBig = isBoundingBoxTooBig(queryAndFilters);

      if (isEmpty && boundingBoxIsTooBig) {
        deferred.reject('Enter a location and click Search or click a pin group below to get started.');
      } else {
        $http.post(uri, queryAndFilters.search)
          .then(function (rsp) {
            //rsp.data.length must be >0
            if (!rsp || !rsp.data || !rsp.data.length) {
              //handle if there is IncludedProperties
              if (rsp.data.message) {
                if (rsp.data.includedProperties) {
                  deferred.reject(rsp.data); //has {message: "something", includedProperties:[]}
                  return;
                }
                deferred.reject(rsp.data.message);
                return;
              }
              deferred.reject("You have searched an area that does not contain any NIC MAP properties."); //can happen in transaction search because transaction search
            } else {
              deferred.resolve(rsp.data);
            }
          }, function (err) {
            if (err.data && err.data.message) {
              deferred.reject(err.data.message);
              return;
            }
            deferred.reject("An unknown error occurred. Error code:" + err.status);
          });
      }
      return deferred.promise;
    };

    function exportProperties(params, transaction) {
      var transactionRoute = transaction ? 'Transaction' : '';
      return apiPostExportCall("/localpropertysearch/generate" + transactionRoute + "Excel", params);
    };

    //handle lat/long?
    function getSearchAll(params) {
      if(params.query) {
        return searchBing(params.query);
      }
      if(params.resetState) {
        return {
          latitude: params.resetState.mapState.latitude,
          longitude: params.resetState.mapState.longitude,
          zoom: params.resetState.mapState.zoom
        };
      }
      if(params.id) {
        return getSavedSearch(params.id);
      }
      // USA!
      return {
        latitude: 37.760417532937176,
        longitude: -96.994336,
        bbox: [46.96525923259753, -128.61298834375, 28.55557583327682, -65.37568365625],
        no_query: true,
        default_view: true,
        message: 'Enter a location and click Search or click a pin group below to get started.'
      };
    }

    //should move this to server side once we start saving filters
    function constructionStatuses() {
      return [getDescriptionIdObj("Open", "Open"),
        getDescriptionIdObj("Expansion", "Expansion"),
        getDescriptionIdObj("New Construction", "New Construction")
      ];
    }

    function createSavedSearch(filterScope, currentZoom, filterName, saveAuditRecord) {
      var filter = angular.copy(filterScope);
      filter.zoom = currentZoom;
     
      filter.name = filterName;

      var url = apiSettings.url + "/localpropertysearch/savedsearch/" + filterName + "/" + saveAuditRecord;
      var deferred = $q.defer();
      $http.post(url, filter)
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function deleteSavedSearchQuery(queryName, queryGuid) {
      var url = apiSettings.url + "/localpropertysearch/savedsearch/" + queryGuid + "/" + queryName;
      var deferred = $q.defer();
      $http
        .delete(url)
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function isEmptySearch(queryAndFilters) {
      if (queryAndFilters){

        var allPropertiesAreEmpty = true;
        _.keys(queryAndFilters).forEach(function (key) {
          if (key != "search" && key != "circle") {
            if (queryAndFilters[key]) {
              if (!Array.isArray(queryAndFilters[key]) || queryAndFilters[key].length > 0) {
                allPropertiesAreEmpty = false;
              }
            }
          }
        });
        if (!allPropertiesAreEmpty){
          return false;
        }

        allPropertiesAreEmpty = true;
        _.keys(queryAndFilters.search).forEach(function (key) {
          // need to skip some keys
          if (key != "bbox" && key != "coordinates" && key != "zoom") {
            if (queryAndFilters.search[key]) {
              if (!Array.isArray(queryAndFilters.search[key]) || queryAndFilters.search[key].length > 0) {
                allPropertiesAreEmpty = false;
              }
            }
          }
        });
        if (!allPropertiesAreEmpty){
          return false;
        }     
      }

      return true;
    }

    function isBoundingBoxTooBig(queryAndFilters) {
      if (queryAndFilters && queryAndFilters.search && queryAndFilters.search.bbox && queryAndFilters.search.bbox.length == 4) {
          return (Math.abs(queryAndFilters.search.bbox[1] - queryAndFilters.search.bbox[3]) > 15);
      }
      return false;
    }

    function propertyLocations() {
      return apiGetCall('/localpropertysearch/propertyLocations');
    }
  }

})();
