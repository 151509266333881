(function () {

  HospitalsService.$inject = ["$http", "$q", "apiSettings",];

  app.factory('HospitalsService', HospitalsService);

  function HospitalsService($http, $q, settings) {

    function getByBBox(bbox) {
      var deferred = $q.defer();
      var uri = settings.url + "/hospitals/boundingbox";

      $http
        .post(uri, bbox)
        .then(function(rsp) {
          deferred.resolve(rsp.data)
        }, function(err) {
          deferred.reject(err);
        });

      return deferred.promise;
    }

    function getByTradeArea(params) {
      var deferred = $q.defer();
      var uri = settings.url + "/hospitals/tradearea";

      $http
        .post(uri, params)
        .then(function(rsp) {
          deferred.resolve(rsp.data)
        }, function(err) {
          deferred.reject(err);
        });

      return deferred.promise;
    }

    return {
      getByBBox: getByBBox,
      getByTradeArea: getByTradeArea
    };
  }

})();
