(function() {
  "use strict";

  app.controller('PortfolioChartsController', PortfolioChartsController);

  PortfolioChartsController.$inject = ['$rootScope', '$scope', '$state', '$stateParams', 'data', 'PortfolioService', 'KendoTemplateFunctions', 'SpinnerService'];

  function PortfolioChartsController($rootScope, $scope, $state, $stateParams, data, PortfolioService, KendoTemplateFunctions, SpinnerService) {
    var onDetailsPage = $state.current.name == "portfolios.details.charts";
    $scope.name = data.name;
   
    $scope.seriesDefaults = {
      type: 'line',
      style: 'smooth',
      markers: {
        visible: false
      },
      missingValues: 'gap'
    };
    $scope.chartCurrencyAxis = [{
      name: "currencyAxis",
      color: "#959595",
      labels: {
        format: "c0",
        color: "#232323"
      },
      narrowRange: true
    }];
    $scope.categoryAxis = {
      majorGridLines: {
        visible: false
      },
      labels: {
        template: xAxisLabels
      }
    };
    $scope.chartPercentageAxis = [{
      name: "percentageAxis",
      color: "#959595",
      labels: {
        format: "p1",
        color: "#232323" //232323 is the default text color of the axis labels
      },
      narrowRange: true
    }];

    $scope.occupancyKSeries = occupancyKSeries;
    $scope.amrKSeries = amrKSeries;
    $scope.rpiKSeries = rpiKSeries;

    $scope.drawChart = drawChart;
    $scope.drawCharts = drawCharts;
    $scope.defaultLegend = KendoTemplateFunctions.defaultChartLegend;

    SpinnerService.start();

    onDetailsPage ? PortfolioService.propertyCharts($stateParams.id, $stateParams.propertyId).then(renderChartData)
    : PortfolioService.portfolioCharts($stateParams.id).then(renderChartData);

    $scope.displayOccChartOverlay = { visible: false, message: '' };
    $scope.displayAMRChartOverlay = { visible: false, message: '' };
    $scope.displayRPIChartOverlay = { visible: false, message: '' };

    function checkChartDataForOverlay(chart) {
      if (chart && chart.element && chart.element.length > 0) {
        var overlayDisplayVarName = chart.element[0].getAttribute("overlayDisplayVarName");
        if (overlayDisplayVarName) {
          $scope[overlayDisplayVarName].visible = false;
          $scope[overlayDisplayVarName].message = '';
          var allNull = checkChartDataForAllNulls(chart);
          if (allNull) {
            $scope[overlayDisplayVarName].visible = true;
            $scope[overlayDisplayVarName].message = getNullMessageForChart(chart);
            clearAllSeriesNamesForChart(chart);
          } else {
            var compSetProtected = checkChartDataForCompsetAllProtected(chart);
            if (compSetProtected) {
              $scope[overlayDisplayVarName].visible = true;
              $scope[overlayDisplayVarName].message = getProtectedMessageForChart(chart);
              clearAllSeriesNamesForChart(chart);
            }
          }
        }
      }
    };

    function clearAllSeriesNamesForChart(chart) {
      angular.forEach(chart.options.series, function(item){
        item.name = '';
      });
    }

    function getNullMessageForChart(chart) {
      var title = chart.options.title.text;
      return title + " data cannot be displayed because subject and/or comp set properties do not include selected care segments. Please select another care segment to continue.";
    }

    function getProtectedMessageForChart(chart) {
      if ($scope.$parent && $scope.$parent.filter && $scope.$parent.filter.segmentType) {
        var segmentName = '';
        angular.forEach($scope.$parent.filters.segmentTypes, function (segment) {
          if (segment.code == $scope.$parent.filter.segmentType) {
            segmentName = segment.name;
          }
        });

        return "Due to protections, " + segmentName.toLowerCase() + " " + chart.options.title.text.toLowerCase() + " data cannot be displayed. To update your competitive set, please contact Client Services by emailing support@nic.org or calling 410-267-0504. We are available Monday - Friday from 9:00 AM - 5:00 PM ET to assist you.";
      }
    }

    function checkChartDataForAllNulls(chart) {
      
      var allSeriesEmpty = true;
      for (var i = 0; i < chart.options.series.length; i++) {
        var series = chart.options.series[i];
        var data = chart.options.series[i].data;
        var fieldname = series.field;
        if (series.name == 'Comp Set') {
          fieldname = fieldname + "Original";  // for compset , we nee dto use the original string data that may have protected in it.
        }
        var allAreNull = _.every(data, function (o) {
          var value = o[fieldname];
          return value == null;
        });

        if (!allAreNull) {
          allSeriesEmpty = false;
          break;
        }
      }

      return allSeriesEmpty;
    }

    function checkChartDataForCompsetAllProtected(chart) {
      var compsetAllProtected = true;
      var portfolioOrPropertyAllNull = true;
      for (var i = 0; i < chart.options.series.length; i++) {
        var series = chart.options.series[i];
        var data = chart.options.series[i].data;
        var fieldname = series.field;
        if (series.name == 'Comp Set') {
          fieldname = fieldname + "Original";  // for compset , we need to use the original string data that may have protected in it.
        }
        if (series.name == 'Comp Set') {
          compsetAllProtected = _.every(data, function (o) {
            var value = o[fieldname];
            return value == "Protected" || value == null;
          });
        } else {
          portfolioOrPropertyAllNull = _.every(data, function (o) {
            var value = o[fieldname];
            return value == null;
          });
        }
      }
      return compsetAllProtected && portfolioOrPropertyAllNull;
    }


    function xAxisLabels(kendoDataItem) {
      // need this here since we are not databinding the charts
      if ($scope.chartData && $scope.chartData.length > 0) {
        kendoDataItem.dataItem = { marketType: $scope.chartData[0].marketType };
      }

      return KendoTemplateFunctions.yearLabels(kendoDataItem, $scope.chartData.length);
    }

    function renderChartData(data) {
      $scope.chartData = massageChartData(data);
      drawCharts();
      SpinnerService.stop();
    }

    function mapProtected(val) {
      return val == 'Protected' ? null : val;
    }

    function occupancyKSeries() {
      return [{
        field: 'occupancy' + $scope.$parent.filter.segmentType,
        axis: 'percentageAxis',
        color: '#AF2027',
        name: $scope.name
      }, {
        dashType: "dot",
        field: 'occupancyCompSet' + $scope.$parent.filter.segmentType,
        axis: 'percentageAxis',
        color: '#142F53',
        name: 'Comp Set'
      }]
    }

    function amrKSeries() {
      return [{
        field: 'amr' + $scope.$parent.filter.segmentType,
        axis: 'currencyAxis',
        color: '#AF2027',
        name: $scope.name
      }, {
        dashType: "dot",
        field: 'amrCompSet' + $scope.$parent.filter.segmentType,
        axis: 'currencyAxis',
        color: '#142F53',
        name: 'Comp Set'
      }]
    }

    function rpiKSeries() {
      return [{
        field: 'rpi' + $scope.$parent.filter.segmentType,
        axis: 'currencyAxis',
        color: '#AF2027',
        name: $scope.name
      }, {
        dashType: "dot",
        field: 'rpiCompSet' + $scope.$parent.filter.segmentType,
        axis: 'currencyAxis',
        color: '#142F53',
        name: 'Comp Set'
      }]
    }

    function massageChartData(chart) {
      if (chart && chart.length >= 1 && onDetailsPage)
        $scope.name = chart[0].propertyName;

      angular.forEach(chart, function (i) {
        // save original data that has the "Protected" string in there
        i.occupancyCompSetMCOriginal = i.occupancyCompSetMC;
        i.occupancyCompSetSHOriginal = i.occupancyCompSetSH;
        i.occupancyCompSetILOriginal = i.occupancyCompSetIL;
        i.occupancyCompSetALOriginal = i.occupancyCompSetAL;
        i.occupancyCompSetNCOriginal = i.occupancyCompSetNC;

        i.amrCompSetMCOriginal = i.amrCompSetMC;
        i.amrCompSetSHOriginal = i.amrCompSetSH;
        i.amrCompSetILOriginal = i.amrCompSetIL;
        i.amrCompSetALOriginal = i.amrCompSetAL;
        i.amrCompSetNCOriginal = i.amrCompSetNC;

        i.rpiCompSetMCOriginal = i.rpiCompSetMC;
        i.rpiCompSetSHOriginal = i.rpiCompSetSH;
        i.rpiCompSetILOriginal= i.rpiCompSetIL;
        i.rpiCompSetALOriginal = i.rpiCompSetAL;
        i.rpiCompSetNCOriginal = i.rpiCompSetNC;


        i.occupancyCompSetMC = mapProtected(i.occupancyCompSetMC);
        i.occupancyCompSetSH = mapProtected(i.occupancyCompSetSH);
        i.occupancyCompSetIL = mapProtected(i.occupancyCompSetIL);
        i.occupancyCompSetAL = mapProtected(i.occupancyCompSetAL);
        i.occupancyCompSetNC = mapProtected(i.occupancyCompSetNC);

        i.amrCompSetMC = mapProtected(i.amrCompSetMC);
        i.amrCompSetSH = mapProtected(i.amrCompSetSH);
        i.amrCompSetIL = mapProtected(i.amrCompSetIL);
        i.amrCompSetAL = mapProtected(i.amrCompSetAL);
        i.amrCompSetNC = mapProtected(i.amrCompSetNC);

        i.rpiCompSetMC = mapProtected(i.rpiCompSetMC);
        i.rpiCompSetSH = mapProtected(i.rpiCompSetSH);
        i.rpiCompSetIL = mapProtected(i.rpiCompSetIL);
        i.rpiCompSetAL = mapProtected(i.rpiCompSetAL);
        i.rpiCompSetNC = mapProtected(i.rpiCompSetNC);
      });
      return chart;
    }

    $scope.$on("segmentTypeChanged", function (event, segmentType) {
      drawCharts();
    });

    function drawCharts() {
      var amrChartTitleText = "Average Monthly Rent (AMR)";
      if ($scope.$parent && $scope.$parent.filter && $scope.$parent.filter.segmentType == 'NC') {
        amrChartTitleText = "Average Daily Rate (ADR)";
      }

      var quarters = _.map($scope.chartData, function (data) {
        return data.quarter;
      });

      drawChart(quarters, $scope.occChart, $scope.occupancyKSeries(), $scope.chartData);
      drawChart(quarters, $scope.amrChart, $scope.amrKSeries(), $scope.chartData, amrChartTitleText);
      drawChart(quarters, $scope.rpiChart, $scope.rpiKSeries(), $scope.chartData);
    }

    function drawChart(quarters, chart, chartseries, chartData, titleText) {
      if (chart && chartseries) {
        for (var i = 0; i < chartseries.length; i++) {
          chartseries[i].data = chartData;
        }
     
        chart.setOptions({
          title: (titleText) ? { text: titleText } : {},
          series: chartseries,
          categoryAxis: { categories: quarters }
        });
      }

      checkChartDataForOverlay(chart);
    }
  }
}());