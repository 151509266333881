(function () {
  "use strict";

  app.controller('VideoLibraryController', VideoLibraryController);
  VideoLibraryController.$inject = ['$scope', '$rootScope', '$state', '$timeout', 'videos', 'recentlyViewed',
    'CacheFactory','AuditService', 'knownEventConstant', 'WordpressService', '$anchorScroll'];
  function VideoLibraryController($scope, $rootScope, $state, $timeout, videos, recentlyViewed,
    CacheFactory, AuditService, knownEventConstant, WordpressService, $anchorScroll) {
    var vm = this;
    vm.init = function() {
      $rootScope.hideLeftNav = true;
      vm.cache = vm.initCache();
      vm.showVideoControls = false;
      vm.showVideoPlayer = false;
      vm.fullscreen = false;
      vm.videoLibrary = vm.initVideoData(videos);
      vm.search = null;
      vm.autoplay = vm.cache.get('autoplay') || false;
      vm.playlist = [];
      vm.currentlyPlayingIndex = 0;
      vm.initialState = {
        videoLibrary: vm.initVideoData(videos)
      }
      vm.recentlyViewed = vm.initRecentlyViewed(recentlyViewed);

      if($state.params.directLinkVideo) {
        vm.directLinkVideo($state.params.directLinkVideo);
      }
    }

    vm.initCache = function() {
      if(!CacheFactory.get('videoLibraryCache')) {
        CacheFactory.createCache('videoLibraryCache', {
          storageMode: 'localStorage'
        });
      }
      return CacheFactory.get('videoLibraryCache');
    }

    vm.initVideoData = function(videos) {
      return _(videos).map(function(c) { 
                        var category = _.extend({offset: 0}, c);
                        if(c.name != "Recently Added") {
                          category.videos = _.orderBy(category.videos,['sortOrder','title'],['asc','asc']);
                        }
                        return category;
                      })
                      .remove(function(c) { return c.videos.length != 0; })
                      .orderBy(['sortOrder','desc'])
                      .value();
    }

    vm.initRecentlyViewed = function(activity) {
      var activities = _(activity).map(function(event) {
        return {
          title: _.chain(event.message).split('|').nth(-1).value(),
          id: _.chain(event.message).split('|').nth(-2).toNumber().value()
        }
      }).value();
      //Filter videos that have been deleted from WP
      return _.filter(activities, function(a) {
        if( _.find(vm.initialState.videoLibrary, { videos: [{ id: a.id }] })){
          return true;
        }
        return false;
      });
    }

    vm.searchResults = function(search, videos) {
      if(!search) return videos;
      var filteredVideos = _.cloneDeep(videos);
      _.each(filteredVideos, function(category) {
        category.videos = _.filter(category.videos, function(v) {
          var searchval = _.toLower(search);
          return _.includes(_.toLower(v.title), searchval) || 
                 _.includes(_.toLower(v.description), searchval) ||
                 _.includes(_.toLower(v.tags), searchval);
        });
      });
      _.remove(filteredVideos, function(category) {
        return category.videos.length == 0;
      });
      return filteredVideos;
    }

    vm.thumbnailImageChooser = function(image, sizes) {
      return WordpressService.imageChooser(image, sizes) || "/assets/logo/NicMapDataServiceLogo.png";
    }

    vm.carouselOffsetChange = function(amount, offset, videos) {
      if(offset + amount < videos.length && offset + amount >= 0){
        offset = offset + amount;
      }
      return offset;
    }

    vm.onVideoClicked = function(category, video) {
      vm.showVideoPlayer = true;
      vm.scrollTo('top');
      vm.playlist = [];
      var categoryStartPoint = _.findIndex(vm.initialState.videoLibrary, {'name': category.name, 'sortOrder': category.sortOrder} );
      if(categoryStartPoint != -1){
        var startPoint = _.findIndex(vm.initialState.videoLibrary[categoryStartPoint].videos, function(o) { 
          return o.fileURL === video.fileURL && o.title === video.title; 
        });
        if(startPoint != -1) {
          //Add remaining videos in category to playlist
          _.forEach(_.slice(vm.initialState.videoLibrary[categoryStartPoint].videos, startPoint), function(value, key) {
            vm.playlist.push(value);
          });
          //find which category we're in and add all videos from subsequent categories afterwards
          if(categoryStartPoint + 1 < vm.initialState.videoLibrary.length) {
            _.forEach(_.slice(vm.initialState.videoLibrary, categoryStartPoint + 1), function(c){
              _.forEach(c.videos, function(video) {
                vm.playlist.push(video);
              });
            });
          }
        }
      }
    }

    vm.onRecentlyViewedClicked = function(activity) {
      var category =  _.find(vm.initialState.videoLibrary, { videos: [{ id: activity.id }] });
      var video = _.find(category.videos, { id: activity.id });
      vm.onVideoClicked(category, video);
    }

    vm.upNextMetadata = function(playlist, currentIndex) {
      if(currentIndex + 1 >= playlist.length) {
        return {
          title: ""
        };
      } else {
        return playlist[currentIndex + 1];
      }
    }

    vm.onAutoplayChanged = function() {
      vm.cache.put('autoplay',vm.autoplay);
      $scope.$broadcast('autoplayChanged', vm.autoplay);
    }

    vm.scrollTo = function(section) {
      $anchorScroll(section);
    }

    vm.directLinkVideo = function(videoSlug) {
      var category =  _.find(vm.initialState.videoLibrary, { videos: [{ slug: videoSlug }] });
      if(category) {
        var video = _.find(category.videos, { slug: videoSlug });
      }
      if(video) {
        vm.onVideoClicked(category, video);
      }
    }

    var timeoutPromise;
    $scope.$watch(function() { return vm.search; }, function(newVal, oldVal) {
      $timeout.cancel(timeoutPromise);  //does nothing, if timeout already done
      timeoutPromise = $timeout(function () {   //Set timeout
        if (!newVal) return;
        AuditService.logEvent(knownEventConstant.videoLibrarySearch.id, 
          knownEventConstant.videoLibrarySearch.message + " Text: " + newVal);
      }, 2000);
    });

    $scope.$watch(function(){ return vm.playlist; }, function(){
      $scope.$broadcast('videoChosen');
    });
    $scope.$on('currentVideoChanged', function(event,args) {
      vm.currentlyPlayingIndex = args;
    });

    vm.init();
  }
}());