(function () {
  "use strict";
  
  app.controller('SubscriptionNotesController', SubscriptionNotesController);
  
  SubscriptionNotesController.$inject = ['$scope', '$rootScope', '$http', '$q', '$state', 'apiSettings', 'SubscriptionNotesService', '$uibModal', 'SubscriptionDetailsService', 'FileSaver', 'SpinnerService'];
  
  function SubscriptionNotesController($scope, $rootScope, $http, $q, $state, apiSettings, SubscriptionNotesService, $uibModal, SubscriptionDetailsService, FileSaver, SpinnerService) {
    $scope.notesData = [];
  
    function initGrid() {
      $scope.gridOpts = {
        columns: [
          {
            title: " ",
            filterable: false,
            template: showEditNoteHtml,
            attributes: {
              'class': 'text-center'
            }
          }, 
          {
            width: "15%",
            field: "dateModified",
            title: "Date/Time",
            template: "{{ dataItem.dateModified | nicDateFilter: 'MM/dd/yyyy hh:mm a' }}",
            filterable: {
              cell: {
                showOperators: false,
                operator: "gte"
              }
            }
          },
          {
            width: "15%",
            field: "user",
            title: "User",
            filterable: {
              cell: {
                showOperators: false,
                operator: "contains"
              }
            }
          },
          {
            width: "70%",
            field: "text",
            title: "Note",
            filterable: {
              cell: {
                showOperators: false,
                operator: "contains"
              }
            }
          }
        ],
        dataSource: new kendo.data.DataSource({
          transport: {
            read: function (options) {
              options.success($scope.notesData);
            }
          },
          sort: {
            field: "dateModified",
            dir: "desc"
          },
          pageSize: 5,
          error: function (err) {
            console.log('error - ' + err);
          },
          schema: {
            model: {
              fields: {
                'text': { type: 'string' },
                'dateModified': { type: 'date' },
                'user': { type: 'string' },
                'noteID': { type: 'number' }

              }
            }
          },
          serverPaging: false,
          serverFiltering: false,
          serverSorting: false
        }),
        dataBinding: function (e) {
          Nic.kendoGrid.filtersOnTop('notesGrid');
        },
        filterable: {
          mode: "row"
        },
        scrollable: false,
        sortable: {
          mode: "single",
          allowUnsort: false
        },
        pageable: true,
        resetFilters: function () {
          this.dataSource.filter([]);
          this.dataSource.sort({ field: "dateModified", dir: "desc" });
        }
      };
    }
  
    $scope.addNote = function () {
      var addNotePopup = $uibModal.open({
        templateUrl: "app/partials/subscriptionNotesAddNotePopup.html",
        backdrop: 'static',
        windowClass: 'modify-access-popup',
        controller: 'SubscriptionNotesPopupController',
        resolve: {
          companyId: function () { return $state.params.companyId; },
          subscriptionId: function () { return $state.params.id; },
          noteText: function () { return ''; }, 
          noteId: function () { return 0; },
        }
      });
  
      addNotePopup.result.then(function () {
        $rootScope.$broadcast("SilentSubscriptionDetailRefresh");
        getNotes();
        $scope.gridOpts.dataSource.read();
      });
    };

    $scope.editNote = function (dataItem) {
      var editNotePopup = $uibModal.open({
        templateUrl: "app/partials/subscriptionNotesEditPopup.html",
        backdrop: 'static',
        windowClass: 'modify-access-popup',
        controller: 'SubscriptionNotesPopupController',
        resolve: {
          companyId: function () { return $state.params.companyId; },
          subscriptionId: function () { return $state.params.id; },
          noteText: function() {
            return dataItem.text;
          },
          noteId: function() {
            return dataItem.noteID;
          }
        }
      });

      editNotePopup.result.then(function () {
        $rootScope.$broadcast("SilentSubscriptionDetailRefresh");
        getNotes();
        $scope.gridOpts.dataSource.read();
      });
    };
  
    $scope.exportExcel = function () {
      SpinnerService.start();
      var filtersAndSorts = getGridCurrentFilterAndSort($scope.gridOpts);
      var companyInfo = SubscriptionDetailsService.getCompany();

      SubscriptionNotesService
        .exportToExcel($state.params.companyId, filtersAndSorts, companyInfo.companyName)
        .then(function success(data) {
          SpinnerService.stop();
          var excelFile = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          });
          var fileName = 'NICMAPAdmin_Notes_' + companyInfo.companyName.replace(/ /g, '_') + '.xlsx';
          FileSaver.saveAs(excelFile, fileName);
        });
    };
  
    function getNotes() {
      SpinnerService.start();
      SubscriptionNotesService
        .getNotes($state.params.companyId)
        .then(function (data) {
          $scope.notesData = data;
          $scope.gridOpts.dataSource.read();
          SpinnerService.stop();
        });
    }
  
    function getGridCurrentFilterAndSort(sourceGrid) {
      var filters = sourceGrid.dataSource.filter();
      filters = (filters === undefined) ? [] : filters.filters;
      var gridData = {
        filters: filters,
        sorts: sourceGrid.dataSource.sort() || []
      };
      return gridData;
    }

    function showEditNoteHtml(dataItem) {
      return "<div ng-click='editNote(this.dataItem)'><span class='glyphicon glyphicon-pencil primary-blue-dark'></span></div>";
    }
  
    initGrid();
    getNotes();
  }
}());