(function () {
  'use strict';

    app.directive('onKendoGridEditBlur', onKendoGridEditBlurDirective);

  // This will attempt to trigger a digest when leaving one of the edit fields in a kendo grid
  // Main intent for this is to tell the controller to search the DOM for the tooltip popups we hid,
  // with the intent of creating our own error message/state based off of it.
  function onKendoGridEditBlurDirective() {
    return {
      restrict: 'A',
      scope: {
        callBack: '&onKendoGridEditBlur'
      },
      link: function ($scope, element, attrs) {
        $(element).on('blur', '.k-grid-edit-row .k-input', function(e) {
          $scope.callBack();
          $scope.$apply();
        });
      }
    }
  }
})();
