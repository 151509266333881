(function () {
  "use strict";

  app.factory("ActivityService", ActivityService);
  
  ActivityService.$inject = ['$http','$q','apiSettings'];
  
  function ActivityService($http, $q, apiSettings) {
    return {
      getRecentActivity: getRecentActivity,
      getVideoLibraryActivity: getVideoLibraryActivity
    };
   
    function getRecentActivity() {
      var deferred = $q.defer();
      $http.get(apiSettings.url + "/account/recentActivity")
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function getVideoLibraryActivity() {
      var deferred = $q.defer();
        $http.get(apiSettings.url + "/account/videoLibraryRecentActivity")
          .then(function(rsp) {
            deferred.resolve(rsp.data);
          }, function(err) {
            deferred.reject(err);
        });
      return deferred.promise;
    }
  }
})();
