app.directive('onElementResize', ['ElementSizeWatcher', function (ElementSizeWatcher) {
  return {
    restrict: 'A',
    scope: {
      width: '=',
      height: '=',
      rate: '=',
      onResize: '='
    },
    link: function ($scope, element) {
      var parent = element[0],
        watcher, groupWatcher,
        cancel = function () {
          if (watcher) watcher.cancel();
          if (groupWatcher) groupWatcher();
        },
        update = function () {
          cancel();
          watcher = new ElementSizeWatcher(parent, $scope.rate || 200);
          $scope.$watchGroup(watcher.group, function (values) {
            if ($scope.width) {
              $scope.width = values[0];
            }
            if ($scope.height) {
              $scope.height = values[1];
            }
            if (typeof $scope.onResize == 'function') {
              $scope.onResize(values[0]);
            }
          });
        };

      $scope.$watch('rate', update);
      $scope.$on('$destroy', cancel);
    }
  }
}]);
