(function() {
  // "use strict";
  app.directive('jumpListLink', JumpListLink);
  JumpListLink.$inject = ['$anchorScroll', 'navBarHeight', '$compile'];
  
  function JumpListLink($anchorScroll, navBarHeight, $compile) {
    function link(scope) {
      $anchorScroll.yOffset = navBarHeight;
      scope.scrollTo = function(section) {
        $anchorScroll(scope.section);
      };
    }
    return {
      restrict: 'E',
      scope: {
        section: '@section'
      },
      transclude: true,
      template: function() {
        return '<a class="normal-link" href="javascript:void(0)" ng-click="scrollTo(\'{{section}}\')" ng-transclude></a>'
      },
      link: link
    };
  }
})();