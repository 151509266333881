(function () {
  "use strict";

  app.controller('PasswordResetConfirmPopupController', PasswordResetConfirmPopupController);

  PasswordResetConfirmPopupController.$inject = ['$scope', 'paramsPassedIn'];

  function PasswordResetConfirmPopupController($scope, paramsPassedIn) {

    $scope.closePopupClicked = closePopupClicked;
    $scope.userName = paramsPassedIn.email;

    function closePopupClicked() {
      $scope.$close();
    };
  }
}());