(function() {
  "use strict";
  app.factory("PortfolioService", PortfolioService);

  PortfolioService.$inject = ["$http", "$q", "apiSettings", "DateOffsetUtils"];

  function PortfolioService($http, $q, apiSettings, DateOffsetUtils) {
    return {
      exportPropertyDetails: exportPropertyDetails,
      exportPortfolio: exportPortfolio,
      exportRawDataFileTrended: exportRawDataFileTrended,
      getPortfolioToEdit: getPortfolioToEdit,
      isPortfolioSubscriber: isPortfolioSubscriber,
      portfolio: portfolio,
      portfolios: portfolios,
      portfolioProperties: portfolioProperties,
      portfolioPropertyIds: portfolioPropertyIds,
      portfolioCharts: portfolioCharts,
      propertyCharts: propertyCharts,
      getAllPortfolios: getAllPortfolios,
      getSubscriptionPortfolios: getSubscriptionPortfolios,
      deletePortfolio: deletePortfolio,
      savePortfolio: savePortfolio,
      getProductAccess: getProductAccess,
      getSegmentTypesPortfolioProperty: getSegmentTypesPortfolioProperty,
      transformFlatPortfoliosArrayToMasterSubTree: transformFlatPortfoliosArrayToMasterSubTree      
    };

    function getSubscriptionPortfolios(subscriptionId, includeChildPortfolios) {
      var defer = $q.defer();
      var url = apiSettings.url + "/portfolio/" + subscriptionId + "/" + includeChildPortfolios;
      $http
        .get(url)
        .then(function(rsp) {
          defer.resolve(rsp.data);
        }, function(err) {
          defer.reject(err);
        });

      return defer.promise;
    }

    function deletePortfolio(id) {
      var defer = $q.defer();
      var action = "/portfolio/" + id;
      $http.delete(apiSettings.url + action)
        .then(function(rsp) {
          defer.resolve(rsp.data);
        }, function(err) {
          defer.reject(err);
        });

      return defer.promise;

    }

    function getAllPortfolios(viewExpiredPortfolios) {
      var defer = $q.defer();
      $http
        .get(apiSettings.url + "/portfolio/" + viewExpiredPortfolios)
        .then(function(rsp) {
          defer.resolve(rsp.data);
        }, function(err) {
          defer.reject(err);
        });
      return defer.promise;
    }

    function exportRawDataFileTrended(portfolioId) {
        var deferred = $q.defer();
        $http
          .get(apiSettings.url + '/userportfolio/export/rawdatafiletrended/' + portfolioId)
          .then(function (rsp) {
            deferred.resolve(rsp);
          }, function (err) {
            deferred.reject(err);
          });
        return deferred.promise;
    }

    function exportPortfolio(portfolioId) {
        var uri = apiSettings.url + '/userportfolio/export/{portfolioId}?timeoffset={timeoffset}'
          .replace("{portfolioId}", portfolioId)
          .replace("{timeoffset}", DateOffsetUtils.getTimezoneOffset(new Date()));
        return doExport(uri);
    }

    function exportPropertyDetails(portfolioId, propertyId) {
      var uri = apiSettings.url + '/userportfolio/export/{portfolioId}/details/{propertyId}?timeoffset={timeoffset}'
        .replace("{portfolioId}", portfolioId)
        .replace("{propertyId}", propertyId)
        .replace("{timeoffset}", DateOffsetUtils.getTimezoneOffset(new Date()));
      return doExport(uri);
    }

    function doExport(apiUri) {
      var deferred = $q.defer();

      $http
        .post(apiUri, {}, {
          responseType: 'arraybuffer'
        })
        .then(function(rsp) {
          if(rsp.data.length == 0) {
            deferred.reject();
          } else {
            deferred.resolve(rsp.data);
          }
        }, function(err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function isPortfolioSubscriber() {
      var deferred = $q.defer();
      $http.get(apiSettings.url + '/userportfolio/isPortfoliosubscriber')
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });

      return deferred.promise;
    }

    function getProductAccess(productCode) {
        var deferred = $q.defer();

        $http
          .get(apiSettings.url + "/subscriptions/CanUserAccessProduct?productCode=" + productCode)
          .then(function (rsp) {
              deferred.resolve(rsp.data);
          }, function (err) {
              deferred.reject(err);
          });

        return deferred.promise;
    }

    function portfolioProperties(portfolioId) {
      var deferred = $q.defer();
      $http.get(apiSettings.url + '/userportfolio/' + portfolioId + '/details')
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });

      return deferred.promise;
    }

    function portfolioPropertyIds() {
      var deferred = $q.defer();
      $http.get(apiSettings.url + '/userportfolio/portfoliopropertyIds')
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });

      return deferred.promise;
    }

    function portfolios() {
      var deferred = $q.defer();
      $http.get(apiSettings.url + '/userportfolio')
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });

      return deferred.promise;
    }

    function portfolio(portfolioId) {
      var deferred = $q.defer();
      $http.get(apiSettings.url + '/userportfolio/' + portfolioId)
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });

      return deferred.promise;
    }

    function portfolioCharts(portfolioId) {
      var deferred = $q.defer();
      $http.get(apiSettings.url + '/userportfolio/' + portfolioId + '/charts')
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });

      return deferred.promise;
    }

    function propertyCharts(portfolioId, propertyId) {
      var deferred = $q.defer();
      $http.get(apiSettings.url + '/userportfolio/' + portfolioId + '/details/' + propertyId + '/charts')
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });

      return deferred.promise;
    }

    function getPortfolioToEdit(portfolioId) {
      var defer = $q.defer();
      $http
        .get(apiSettings.url + "/UserPortfolio/Edit/" + portfolioId)
        .then(function(rsp) {
          defer.resolve(rsp.data);
        }, function(err) {
          defer.reject(err);
        });

      return defer.promise;
    }

    function savePortfolio(id, name, properties) {
      var data = {
        id: id,
        name: name,
        properties: properties
      };
      var defer = $q.defer();
      $http
        .post(apiSettings.url + "/UserPortfolio/Update/" + id, data)
        .then(function(rsp) {
          defer.resolve(rsp.data);
        }, function(err) {
          var name = "";
          var protections = "";
          if(err.data && err.data.errors && err.data.errors.properties) {
            if(err.data.errors.properties.name) {
              name = err.data.errors.properties.name.toString();
            }
            if(err.data.errors.properties.protections) {
              protections = err.data.errors.properties.protections.toString();
            }
            defer.reject({
              name: name,
              protections: protections
            });
          } else {
            defer.reject(err);
          }
        });

      return defer.promise;
    }


    function getSegmentTypesPortfolioProperty(portfolioId, propertyId) {      
      var defer = $q.defer();
      $http
        .get(apiSettings.url + '/segmentType/PortfolioProperty/' + portfolioId + '/' + propertyId)
        .then(function (rsp) {
          defer.resolve(rsp.data);
        }, function (err) {
          defer.reject(err);
        });

      return defer.promise;
    }

    function transformFlatPortfoliosArrayToMasterSubTree(allPortfolios) {
      // found function at https://gist.github.com/alonronin/2592a6a81db67804db1f

      var nodes = {};
      var newData = _.cloneDeep(allPortfolios);
      return newData.filter(function (obj) {
        var id = obj["id"];
        var parentId = obj["masterPortfolioId"];
        nodes[id] = _.defaults(obj, nodes[id], { subPortfolios: [] });
        parentId && (nodes[parentId] = (nodes[parentId] || { subPortfolios: [] }))["subPortfolios"].push(obj);
        return !parentId;
      });
    }
  }
})();