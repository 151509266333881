(function() {
  "use strict";
  app.controller("InsightsController", InsightsController);
  InsightsController.$inject = ['$scope', '$rootScope', '$sce', 'insights', 'AuditService', 'knownEventConstant'];
  function InsightsController($scope, $rootScope, $sce, insights, AuditService, knownEventConstant) {
    $rootScope.hideLeftNav = false; //show the left nav when going to the home page

    $scope.logUserEvent = function () {
      AuditService.logEvent(knownEventConstant.navInsightNews.id, knownEventConstant.navInsightNews.message);
    };
    $scope.logUserEvent();

    $scope.insights = insights[0];
    $scope.insights.content = $sce.trustAsHtml($scope.insights.content);
  }
})();
