(function () {
  "use strict";

  app.controller('ContactController', ContactController);
  ContactController.$inject = ['$scope', '$location', '$state', '$sce', '$window', 'apiSettings', 'vcRecaptchaService', 'AuthService', 'IntercomService'];
  function ContactController($scope, $location, $state, $sce, $window, apiSettings, vcRecaptchaService, authService, intercomService) {
    $scope.company = null;
    $scope.name = null;
    $scope.subscriptionType = null;
    $scope.email = null;
    $scope.phone = null;
    $scope.validPhone = false;
    $scope.selectedType = null;
    $scope.selectedReason = null;
    $scope.priority = "Medium";
    $scope.status = "New";
    $scope.subject = null;
    $scope.description = null;
    $scope.sfURL = $sce.trustAsResourceUrl(apiSettings.sfWebCaseURL);
    $scope.sfOrgId = apiSettings.sfOrgId;
    $scope.tryToSetDefaults =  tryToSetDefaults;
    $scope.submitClick = submitClick;
    $scope.isSubmitClicked = false;

    // set the returnURL to \contactresponse
    $scope.returnURL = $location.absUrl().split('?')[0];
    $scope.returnURL = $scope.returnURL + 'Response';

    $scope.response = null;
    $scope.widgetId = null;
    $scope.isCaptcha = false;

    $scope.model = {
      key: apiSettings.reCaptchaPublicKey
    };

    $scope.setResponse = function (response) {
      var valid;
      $scope.response = response;
      authService.verifyRecaptchaResponse($scope.response).then(function (data) {
        valid = data
        if (valid) {
            $scope.isCaptcha = true;
        } else {
          // In case of a failed validation you need to reload the captcha
          // because each response can be checked just once
          vcRecaptchaService.reload($scope.widgetId);
          $scope.isCaptcha = false;
        }
      })
    };

    $scope.setWidgetId = function (widgetId) {
      console.info('Created widget ID: %s', widgetId);
      $scope.widgetId = widgetId;
    };

    $scope.cbExpiration = function() {
      console.info('Captcha expired. Resetting response object');
      vcRecaptchaService.reload($scope.widgetId);
      $scope.response = null;
      $scope.isCaptcha = false;
    };

    $scope.types = [{
      name: 'Support',
      tagId: '5289875',
      reasons: [{
        name: 'Data Inquiry',
        tagId: '5295484'
      }, {
        name: 'General Feedback',
        tagId: '5295485'
      }, {
        name: 'Product Enhancement',
        tagId: '5295486'
      }, {
        name: 'Request Training',
        tagId: '5295487'
      },{
        name: 'Technical Assistance',
        tagId: '5295488'
      }]
    }, {
      name: 'Subscription',
      tagId: '5289873',
      reasons: [{
        name: 'Add/Remove Registered Users',
        tagId: '5295480'
      }, {
        name: 'Geographical Access',
        tagId: '5295481'
      }, {
        name: 'Product Access',
        tagId: '5295482'
      }, {
        name: 'Renew Subscription',
        tagId: '5295483'
      }]
    }, {
      name: 'Account',
      tagId: '5289874',
      reasons: [{
        name: 'Password Reset',
        tagId: '5295477'
      }, {
        name: 'Unlock Account',
        tagId: '5295478'
      }, {
        name: 'Update Contact Info',
        tagId: '5295479'
      }]
    }];

    $scope.reasons = [];

    $scope.isEmbedded = false;
    if ($state.current.name == 'login.contact') {
      $scope.isEmbedded = true;
      $scope.selectedType = $scope.types[2];
      $scope.reasons = $scope.selectedType.reasons;
      $scope.selectedReason = $scope.reasons[2];
      $scope.tryToSetDefaults();
    } else {
      $scope.selectedType = $scope.types[0];
      $scope.reasons = $scope.selectedType.reasons;
      $scope.selectedReason = $scope.reasons[4];
      $scope.tryToSetDefaults();
      authService.getAccountDetails()
        .then(function (data) {
          $scope.email = data.email;
          $scope.name = data.firstName + " " + data.lastName;
          $scope.phone = data.phone;
          $scope.validPhone = data.phone != null && data.phone != undefined;
          $scope.company = data.companyName;
          $scope.subscriptionType = data.subscriptionType;
        });
    }

    $scope.validatePhone = function () {
      var phone = $('#phone');
      var val = phone.val();
      if(val === "") {
        $scope.phone = null;
        $scope.validPhone = false
      }
      else{
        $scope.phone = val.match(/[0-9]/) == null ? null : val;
        $scope.validPhone = val.match(/_/) == null;
      }
      if ($scope.phone)
        phone.trigger('input');
    };

    $scope.typeChanged = function (e) {
      $scope.reasons = $scope.selectedType ? $scope.selectedType.reasons : null;
      $scope.selectedReason = $scope.selectedType ? $scope.selectedType.reasons[0] : null;
    };

    function tryToSetDefaults(){
      if ($state.params){
        if ($state.params.selectedType) {
          var index = _.findIndex($scope.types, function(o) { return o.name == $state.params.selectedType;} );
          if (index >= 0){
            $scope.selectedType = $scope.types[index];
            $scope.reasons = $scope.selectedType.reasons;
            $scope.selectedReason = $scope.reasons[0];
          }
        }
        if ($state.params.selectedReason) {
          var index = _.findIndex($scope.reasons, function(o) { return o.name == $state.params.selectedReason; } );
          if (index >= 0){
            $scope.selectedReason = $scope.reasons[index];
          }
        }
      }
    }

    $scope.isSubmitDisabled = function (isFormInvalid) {
      return (isFormInvalid && (!$scope.isCaptcha || !$scope.isEmbedded)) || !$scope.validPhone;
    }

    function submitClick() {
      if ($scope.isSubmitClicked)
        return;
      $scope.isSubmitClicked = true;
      intercomService
        .createConversation(
          {
            "fullName": $scope.name,
            "companyName": $scope.company,
            "email": $scope.email,
            "phone": $scope.phone,
            "subscriptionType": $scope.subscriptionType,
            "inquiryTypeTagId": $scope.selectedType.tagId,
            "inquiryReasonTagId": $scope.selectedReason.tagId,
            "subject": $scope.subject,
            "description": $scope.description
          })
        .then(function (rsp) {
          console.log(rsp);
        }, function (err) {
          console.log(err);
        })
        .finally(function () {
          $scope.isSubmitClicked = false;
          $window.open($scope.returnURL, '_self');
        });
    }
  }
})();
