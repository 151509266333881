(function () {
  'use strict';
  app.factory('SubscriptionGeographyService', SubscriptionGeographyService);

  SubscriptionGeographyService.$inject = ['$http', 'apiSettings'];
  function SubscriptionGeographyService($http, apiSettings) {
    // TODO: Breakout SubscriptionGeography calls from SubscriptionDetailsService
    return {
      exportToExcel: exportToExcel
    };

    function exportToExcel(subscriptionId) {
      var url = apiSettings.url + '/subscription/exports/{subscriptionId}/geography/'
        .replace('{subscriptionId}', subscriptionId);

      return $http.post(url, null, {
        responseType: 'arraybuffer'
      })
      .then(function (response) {
        if (response.data.byteLength === 0) {
          throw new Error("No Data");
        }
        return response;
      })
      .catch(function (error) {
        console.error(error);
        throw error;
      });
    }
  }
})();
