(function () {
  "use strict";

  app.controller('VideoPlayerController', VideoPlayerController);
  VideoPlayerController.$inject = ['$scope', '$sce', '$timeout','AuditService','knownEventConstant'];
  function VideoPlayerController($scope, $sce, $timeout, AuditService, knownEvents) {
    var vm = this;
    vm.init = function() {
      vm.API = null;
      vm.currentVideo = 0;
      vm.config = {
        theme: null,
        autoplay: false,
        autohide: true,
        autohideTime: 2000,
        sources: [{src: null, type: null}]
      };
    };
    
    vm.onPlayerReady = function(API) {
      vm.API = API;
    }

    vm.onCompleteVideo = function() {
      vm.isCompleted = true;
      if(vm.config.autoplay) {
        vm.currentVideo++;
        if (vm.currentVideo >= $scope.library.playlist.length)
          vm.currentVideo = 0;
        vm.setVideo(vm.currentVideo);
      }
    };

    vm.setVideo = function(index) {
      vm.API.stop();
      if($scope.library.playlist[index]) {
        vm.config.sources = [{
          src: $sce.trustAsResourceUrl($scope.library.playlist[index].fileURL),
          type: "video/" + $scope.library.playlist[index].fileType
        }];
      }
      $timeout(vm.API.play.bind(vm.API), 200);
      //Add Logging here for video playing
      if($scope.library.playlist[index])
        AuditService.logEvent(knownEvents.videoLibraryVideoPlayed.id, 
          knownEvents.videoLibraryVideoPlayed.message + "|" + 
          $scope.library.playlist[index].id + "|" + $scope.library.playlist[index].title);
    }

    $scope.$on('videoChosen', function(){
      vm.setVideo(0);
    });

    $scope.$on('autoplayChanged', function(event,value) {
      vm.config.autoplay = value;
    });

    $scope.$watch(function(){ return vm.currentVideo;}, function(newval, oldval) {
      if(newval != oldval) {
        $scope.$emit('currentVideoChanged', vm.currentVideo);
      }
    });

    vm.init();
  }
}());