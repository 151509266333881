
(function() {
  "use strict";

  app.controller('PortfolioEditController', PortfolioEditController);
  PortfolioEditController.$inject = ['$scope', '$state', '$rootScope', 'AuthService', 'PortfolioService', 'editPortfolio', 'SpinnerService'];

  function PortfolioEditController($scope, $state, $rootScope, AuthService, PortfolioService, editPortfolio, SpinnerService) {
    $rootScope.hideLeftNav = true;
    SpinnerService.start();
    $scope.isDirty = false;
    $scope.userIsOwner = false;
    $scope.paramPortfolioID = $state.params.id;
    $scope.paramSubscriptionID = $state.params.subscriptionId;
    $scope.selectedPropertyCount = 0;
    $scope.protectionsThresholdCount = 0;
    $scope.protectionsMet = false;
    $scope.errors = {};

    $scope.filters = [{
      name: 'Seniors Housing',
      value: 'SH',
      checked: true
    }, {
      name: 'IL',
      value: 'IL',
      checked: false
    }, {
      name: 'AL',
      value: 'AL',
      checked: false
    }, {
      name: 'MC',
      value: 'MC',
      checked: false
    }, {
      name: 'NC',
      value: 'NC',
      checked: false
    }];
    function setFilterWidth() {
      var filtersNameLength = _.join(_($scope.filters).map(function (f) { return f.name; }).value(), '').length;
      _.forEach($scope.filters, function (filter) {
        filter.width = 75 / $scope.filters.length + 25 * filter.name.length / filtersNameLength + '%';
      });
    }; setFilterWidth();

    var defaultPage = 20;
    $scope.gridOptPage = defaultPage;
    $scope.pageSizes = [{ value: defaultPage, text: defaultPage + " rows" }, { value: 40, text: "40 rows" }, { value: 60, text: "60 rows" }];
    $scope.resetFilters = function() {
      $scope.updateFilters({
        name: 'Seniors Housing',
        value: 'SH',
        checked: true
      });

      $scope.gridOpts.dataSource.filter([]);
      $scope.gridOpts.dataSource.sort({
        field: "name",
        dir: "asc"
      });
      $scope.gridOptPage = defaultPage;
    }
    $scope.$watch('gridOptPage', function() {
      $scope.gridOpts.dataSource.pageSize($scope.gridOptPage);
    });

    $scope.$watch('portfolioName', function(){
      $scope.isDirty = ($scope.portfolioName != $scope.originalPortfolioName);
    });

    $scope.selectRow = function(dataItem){
      dataItem.dirty = true;
      $scope.isDirty = true;
    };

    $scope.cleanForm = function(){
      for(var i = 0; i < $scope.gridOpts.dataSource.data().length; i++) {
        var ditem = $scope.gridOpts.dataSource.at(i)
        ditem.dirty = false;
      }
      $scope.isDirty = false;
    };

    $scope.toggleAll = function(dataItem){
      var selected = dataItem.isSelected;
      var rows = $scope.gridOpts.dataSource.view();
      for(var i = 0; i < rows.length; i++) {
        var item = rows[i];
        item.isSelected = selected;
        item.dirty = true;
      }
      $scope.isDirty = true;
    };

    $scope.updatePropertyCount = function() {
      $scope.selectedPropertyCount = 0;
      for(var i = 0; i < $scope.gridOpts.dataSource.data().length; i++) {
        var ditem = $scope.gridOpts.dataSource.at(i)
        if(ditem.isSelected) {
          $scope.selectedPropertyCount++;
        }
      }
      $scope.protectionsMet = ($scope.selectedPropertyCount >= $scope.protectionsThresholdCount);
    };

    $scope.updateFilters = function(filter) {
      angular.forEach($scope.filters, function(item) {
        item.checked = (item.value === filter.value);
      });

      var gridFilter = [];

      if(filter.value == "SH") {
        gridFilter.push({
          field: "segmentTypes",
          operator: "doesnotcontain",
          value: "NC"
        });
      } else {
        gridFilter.push({
          field: "segmentTypes",
          operator: "contains",
          value: filter.value
        });
      }

      $scope.gridOpts.dataSource.filter({
        filters: gridFilter
      });
      $scope.updatePropertyCount();
    };

    $scope.backToManageClick = function() {
      $state.go('portfolios.manage');
    };

    $scope.savePortfolio = function() {
      SpinnerService.start();
      $scope.errors = {};
      PortfolioService.savePortfolio($scope.paramPortfolioID, $scope.portfolioName, $scope.gridOpts.dataSource.data())
        .then(function(rsp) {
          $scope.updatePropertyCount();
          $scope.cleanForm();
          $scope.originalPortfolioName = $scope.portfolioName;
          SpinnerService.stop();
        }, function(err) {
          $scope.errors.name = err.name;
          $scope.errors.protections = err.protections;
          SpinnerService.stop();
        });
    };

    function initGrid() {
      var gridDatasource = new kendo.data.DataSource({
        data: editPortfolio.properties,
        error: function(err) {
          console.log('error - ' + err);
        },
        schema: {
          model: {
            id: 'id',
            fields: {
              'isSelected': {  type: 'boolean' },
              'id': { type: 'number' },
              'name': { type: 'string' },
              'ilUnitsTotal': { type: 'number' },
              'addressLine1': { type: 'string' },
              'city': { type: 'string' },
              'countyName': { type: 'string' },
              'stateProvinceCD': { type: 'string' },
              'zipCode': { type: 'string' },
              'stakeholders': { type: 'string' },
              'compCount': { type: 'number' },
              'compIds': { type: 'string' },
              'compsMeetProtections': { type: 'boolean' }
            }
          },
        },
        filter: {
          field: "segmentTypes",
          operator: "doesnotcontain",
          value: "NC"
        },
        sort: {
          field: "name",
          dir: "asc"
        },
        pageSize: defaultPage,
        serverPaging: false,
        serverFiltering: false,
        serverSorting: false
      });

      var authId = AuthService.identity.contactId();
      $scope.userIsOwner = authId == editPortfolio.ownerContactId || authId == editPortfolio.masterOwnerContactId;
      $scope.originalPortfolioName = editPortfolio.name;
      $scope.portfolioName = editPortfolio.name;
      $scope.protectionsThresholdCount = editPortfolio.portfoliosSetSizePropertyCount;

      $scope.gridOpts = {
        columns: [{
          field: "segmentTypes",
          title: "",
          hidden: "hidden"
        }, {
          field: "compIds",
          title: "",
          hidden: "hidden"
        }, {
          field: "",
          title: "",
          template: '<input type="checkbox" ng-disabled="!userIsOwner" ng-model="dataItem.isSelected" ng-change="selectRow(dataItem)" />',
          headerTemplate: '<input type="checkbox" ng-disabled="!userIsOwner" ng-model="dataItem.isSelected" ng-change="toggleAll(dataItem)" style="margin-left: 7px;" />'
        }, {
          field: "",
          title: "Map Pin No.",
          hidden: "hidden"
        }, {
          field: "id",
          title: "Property ID",
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          }
        }, {
          field: "name",
          title: "Property Name",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "ilUnitsTotal",
          title: "Unit Count",
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          }
        }, {
          field: "addressLine1",
          title: "Address",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "city",
          title: "City",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "countyName",
          title: "County",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "stateProvinceCD",
          title: "State",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "zipCode",
          title: "ZIP",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "stakeholders",
          title: "Operator",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "compCount",
          title: "Comps",
          template: '<span class="bold" ng-class="{green : dataItem.compsMeetProtections, \'bright-red\': !dataItem.compsMeetProtections}">{{dataItem.compCount}}<span>',
          filterable: {
            cell: {
              showOperators: false
            }
          }
        }],
        dataSource: gridDatasource,
        dataBinding: function(e) {
          Nic.kendoGrid.filtersOnTop('grid');
        },
        dataBound: function(e) {
          $scope.updatePropertyCount();
          SpinnerService.stop();
        },
        filterable: {
          mode: "row"
        },
        scrollable: false,
        sortable: true,
        pageable: true
      };
    };

    initGrid();
  };

})();
