app
  .factory("AuthService", [
    "$http", "$q", "$rootScope", "AuthError", "applicationInsightsService", "sessionStorageKeys", "apiSettings", "DateOffsetUtils", "CacheFactory",
    function ($http, $q, $rootScope, authError, applicationInsightsService, sessionStorageKeys, apiSettings, DateOffsetUtils, CacheFactory) {

      var isSiteOffLine = false;

      var auth = {
        roles: [],
        isAuth: false,
        isImpersonated: false,
        hasToAgreeToTermsOfService: false,
        hasToAgreeToDataAttribution: false,
        requiresPasswordChanged: false,
        isAccountAdmin: false,
        sessionId: null,
        currentSubscription : null,
        userProfile: null,
        userHash: null
      };

      var identity = {
        isImpersonated: function() {
          return auth.isImpersonated;
        },
        isAuth: function () {
          return auth.isAuth;
        },
        contactId: function() {
          return auth.contactId;
        },
        firstName: function() {
          return auth.firstName;
        },
        lastName: function() {
          return auth.lastName;
        },
        userName: function() {
          return auth.userName;
        },
        userHash: function () {
          return auth.userHash;
        },
        phone: function() {
          return auth.phone;
        },
        isInRole: function(roleName) {
          if (!roleName || !auth.roles || !auth.roles.length)
            return false;
          return auth.roles.indexOf(roleName) !== -1;
        },
        roles: auth.roles,
        isAgreeToTOU: function () {
          return auth.hasToAgreeToTermsOfService;
        },
        isAgreeToDataAttribution: function () {
          return auth.hasToAgreeToDataAttribution;
        },
        isPassChangeRequired: function () {
          return auth.requiresPasswordChanged;
        },
        isAccountAdmin: function () {
          return auth.isAccountAdmin;
        },
        sessionId: function () {
          return auth.sessionId;
        },
        currentSubscription: function () {
          return auth.currentSubscription;
        },
        hasAccessToCBSA: function (cbsaToTest) {
          if (!cbsaToTest || cbsaToTest === '') {
            return false;
          }
          if (auth.currentSubscription && auth.currentSubscription.cbsAs && auth.currentSubscription.cbsAs.indexOf(cbsaToTest) > -1) {
            return true;
          }
          return false;
        },
        userProfile: function() {
          return auth.userProfile;
        }
      }

      function resetAuth() {
        auth.contactId = 0;
        auth.isAuth = false;
        auth.firstName = "";
        auth.lastName = "";
        auth.userName = "";
        auth.phone = "";
        auth.roles = [];
        auth.hasToAgreeToTermsOfService = false;
        auth.hasToAgreeToDataAttribution = false;
        auth.requiresPasswordChanged = false;
        auth.isAccountAdmin = false;
        auth.sessionId = null;
        auth.currentSubscription = null;
        auth.userProfile = null;
        auth.userHash = null;
      }

      function getCurrentUser() {
        var deferred = $q.defer();

        $http
          .get(apiSettings.url + "/account/me?timezoneOffset=" + DateOffsetUtils.getTimezoneOffset(new Date()))
          .then(function(rsp) {
            var data = rsp.data;
            populateAuthWData(data);
            deferred.resolve(data);
          }, function(err) {
            resetAuth();
            deferred.reject(err);
          });

        return deferred.promise;
      }

      function populateAuthWData(data) {
        auth.contactId = data.contactId;
        auth.userName = data.email;
        auth.isAuth = true;
        auth.firstName = data.firstName;
        auth.lastName = data.lastName;
        auth.phone = data.phone;
        auth.roles = data.roles;
        auth.isImpersonated = data.impersonated ? true : false;
        auth.hasToAgreeToTermsOfService = data.hasToAgreeToTermsOfService;
        auth.hasToAgreeToDataAttribution = data.hasToAgreeToDataAttribution;
        auth.requiresPasswordChanged = data.requiresPasswordChanged;
        auth.isAccountAdmin = data.isAccountAdmin;
        auth.currentSubscription = data.currentSubscription;
        auth.userProfile = data.userProfile;
        if (data.userInfo) {
          if (data.userInfo.sessionId)
            auth.sessionId = data.userInfo.sessionId;
          if (data.userInfo.userHash)
            auth.userHash = data.userInfo.userHash;
        }
      }

      function getRecentActivity() {
        var deferred = $q.defer();

        $http
          .get(apiSettings.url + "/account/recentActivity")
          .then(function(rsp) {
            deferred.resolve(rsp.data);
          }, function(err) {
            deferred.reject(err);
          });

        return deferred.promise;
      }

      //a helper function if we press F5 and lose the local state
      function reAuthenticate() {
        return getCurrentUser()
          .then(function () {
            if (!auth.sessionId){
              assignNewSession()
                .then(function (userCacheValue) {
                  auth.sessionId = userCacheValue.sessionId;
                  auth.userHash = userCacheValue.userHash;
                  broadcastLoggedIn();
                });
            } else {
              broadcastLoggedIn();
            }
          });
      }

      function broadcastLoggedIn() {
        $rootScope.$broadcast("loggedIn", identity);
      }

      function broadcastLoggedOut() {
        $rootScope.$broadcast("loggedOut");
      }

      function kickOutUnauthenticatedSession() {
        resetAuth();
        broadcastLoggedOut();
      }

      function logout(doBroadcastLogout) {
        var defer = $q.defer();
        var uri = apiSettings.url + "/account/logoff";
        
        if (doBroadcastLogout === undefined || doBroadcastLogout === true) {
          isSiteOffLine = false;
        } else {
          isSiteOffLine = true;
        }

        $http.post(uri)
          .then(function (res) {
            defer.resolve(res);
          }, function (err) {
            defer.reject(err);
          })
          .finally(function () {
            resetAuth();
            clearCachedData();
            shutdownIntercom();
            if (!isSiteOffLine)
              broadcastLoggedOut();
          });

        return defer.promise;
      }

      function logoutHelper(deferred, errorCode) {
        logout().finally(function () {
          deferred.reject({
            errorCode: errorCode
          });
        });
      }

      function forceLogin(email) {
        var defer = $q.defer();
        var uri = apiSettings.url + "/account/forceLogin?email=" + email;

        $http
          .post(uri)
          .then(function (res) {
            defer.resolve(res);
          }, function (err) {
            defer.reject(err);
          });

        return defer.promise;
      }

      function assignNewSession() {
        var defer = $q.defer();
        if (!auth.userName) {
          defer.reject('Username does not exist.');
          return defer.promise;
        }
        var uri = apiSettings.url + "/account/assignNewSession?email=" + auth.userName + "&timezoneOffset=" + DateOffsetUtils.getTimezoneOffset(new Date());

        $http
          .get(uri)
          .then(function (res) {
            defer.resolve(res.data);
          }, function (err) {
            defer.reject(err);
          });

        return defer.promise;
      }

      function login(loginData) {
        var data = {
          email: loginData.userName,
          password: loginData.password,
          timezoneOffset: DateOffsetUtils.getTimezoneOffset(new Date())
        };

        var deferred = $q.defer();

        $http
          .post(apiSettings.url + "/account/login", JSON.stringify(data))
          .then(function (rsp) {
            if (rsp.data.succeeded) {
              applicationInsightsService.trackTraceMessage(data.email + ' login success.', 'info', rsp);
              getUserRoles()
                .then(function (userRoles) {
                  var appUser = rsp.data.applicationUser;
                  appUser.roles = userRoles;
                  var userInfo = appUser.userInfo;
                  populateAuthWData(appUser);
                  auth.sessionId = userInfo.sessionId;
                  auth.userId = appUser.userId;
                  auth.userHash = userInfo.userHash;
                  bootIntercom();
                  broadcastLoggedIn();
                  deferred.resolve(appUser);
                }, function (err) {
                  if (!isSiteOffLine)
                    logoutHelper(deferred, authError.Unknown);
                });
            } else {
              applicationInsightsService.trackTraceMessage(data.email + ' login failed.', 'warn', rsp);
              logoutHelper(deferred, authError.InvalidSubscription);
            }
          }, function errorCallback(err) {
            applicationInsightsService.trackTraceMessage(data.email + ' login failed.', 'warn', err);
            if (err.status == 400) {
              logoutHelper(deferred, authError.InvalidSubscription);
            } else if (err.data && err.data.duplicateLoginInfo) {
              deferred.reject({
                errorCode: authError.DuplicateLogin,
                lastActiveLogIn: err.data.duplicateLoginInfo.loggedIn,
                lastActiveSessionId: err.data.duplicateLoginInfo.sessionId
              });
            } else if (err.data && err.data.isLockedOut) {
              logoutHelper(deferred, authError.Locked);
            } else if (err.data && err.data.hasAllExpiredSubscriptions) {
              logoutHelper(deferred, authError.ExpiredSubscription);
            } else if (err.data && err.data.isSubTerminated) {
              logoutHelper(deferred, authError.isSubTerminated);
            } else if(err.data && err.data.failedAttempts == 0) {
              logoutHelper(deferred, authError.InvalidSubscription);
            } else {
              resetAuth();
              var failedAttempts, maxLoginAttempts;
              if (err.data) {
                failedAttempts = err.data.failedAttempts;
                maxLoginAttempts = err.data.maxLoginAttempts;
              }
              deferred.reject({
                errorCode: authError.Invalid,
                failedAttempts: failedAttempts,
                maxLoginAttempts: maxLoginAttempts
              });
            }
          });

        return deferred.promise;
      }

      function getUserRoles() {
        var defer = $q.defer();
        var uri = apiSettings.url + "/account/getUserRoles";

        $http
          .get(uri)
          .then(function (res) {
            defer.resolve(res.data);
          }, function (err) {
            defer.reject(err);
          });

        return defer.promise;
      }

      function getSubscriptionExpiration() {
        var deferred = $q.defer();
        $http
          .get(apiSettings.url + "/account/subscriptionExpiration")
          .then(function (rsp) {
            var data = rsp.data;
            if (data) {
              if (data.expiresOn) {
                var now = new Date().setHours(0, 0, 0, 0);
                var subEndDate = new Date(data.expiresOn).setHours(0, 0, 0, 0);

                var timeDiff = subEndDate - now;
                var daysExpiresIn = Math.ceil(timeDiff / (1000 * 3600 * 24));

                data.isValid = (daysExpiresIn >= 0) ? true : false;
                data.expiresIn = daysExpiresIn;
                data.expiresOn = subEndDate;
              } else {
                data.isValid = false;
                data.expiresIn = null;
                data.expiresOn = null;
              }
            };
            deferred.resolve(data);
          }, function (err) {
            deferred.reject(err);
          });

        return deferred.promise;
      }

      function isSubscriptionContactActive(subscriptionID) {
        var defer = $q.defer();
        var appendUrl = "/subscriptions/CanUserAccessSubscription?subscriptionID=" + subscriptionID;
        $http
          .get(apiSettings.url + appendUrl)
          .then(function (rsp) {
            defer.resolve(rsp.data);
          }, function (err) {
            defer.reject(err);
          });

        return defer.promise;
      }

      function init() {
        resetAuth();
      }

      function impersonate(userName) {
        var deferred = $q.defer();

        $http
          .post(apiSettings.url + "/account/impersonate/{userName}"
            .replace("{userName}", userName))
          .then( function ( rsp ) {
            deferred.resolve( rsp.data );
          }, function ( err ) {
            deferred.reject( err );
          })
          .finally(function () {
            clearCachedData();
          });

        return deferred.promise;
      }

      function stopImpersonate() {
        var deferred = $q.defer();

        $http.delete(apiSettings.url + "/account/impersonate")
          .then( function ( rsp ) {
            deferred.resolve( rsp.data );
          }, function ( err ) {
            deferred.reject( err );
          })
          .finally(function () {
            clearCachedData();
          });

        return deferred.promise;
      }

      function getAccountDetails() {
        var deferred = $q.defer();
        $http
          .get(apiSettings.url + "/account/details")
          .then(function(rsp) {
            deferred.resolve(rsp.data);
          }, function(err) {
            deferred.reject(err);
          });

        return deferred.promise;
      }

      function changePassword(changePassData) {
        var data = {
          email: auth.userName,
          currentpassword: changePassData.currentpassword,
          newpassword: changePassData.newpassword,
          confirmpassword: changePassData.confirmpassword
        };

        var deferred = $q.defer();

        $http.post(apiSettings.url + "/account/resetPassword", JSON.stringify(data))
        .then(function (rsp) {
          if (rsp.data.succeeded) {
            deferred.resolve(rsp);
          } else {
            deferred.reject(rsp);
          }
        }, function errorCallback(err) {
          deferred.reject(err);
        });

        return deferred.promise;
      }

      function resetPassEmail(resetEmailData) {
        var data = {
          recipient: resetEmailData.email,
          defaultLoginUrl: resetEmailData.returnHomeUrl,
          timezoneOffset: DateOffsetUtils.getTimezoneOffset(new Date())
        };

        var deferred = $q.defer();

        $http.post(apiSettings.url + "/account/resetEmail", JSON.stringify(data))
        .then(function (rsp) {
          if (rsp.status == 200) {
            deferred.resolve(rsp);
          } else {
            deferred.reject(rsp);
          }
        }, function errorCallback(err) {
          deferred.reject(err);
        });

        return deferred.promise;
      }

      function lockedAccountEmail(lockedEmailData) {
        var data = {
          email: lockedEmailData.email,
          defaultLoginUrl: lockedEmailData.returnHomeUrl
        };
        var deferred = $q.defer();
        $http.post(apiSettings.url + "/account/accountLockedEmail", JSON.stringify(data))
        .then(function (rsp) {
          if (rsp.status == 200) {
            deferred.resolve(rsp);
          } else {
            deferred.reject(rsp);
          }
        }, function errorCallback(err) {
          deferred.reject(err);
        });

        return deferred.promise;
      }

      function getProductAccess(productCode) {
        var deferred = $q.defer();

        $http
          .get(apiSettings.url + "/subscriptions/CanUserAccessProduct?productCode=" + productCode)
          .then(function (rsp) {
            deferred.resolve(rsp.data);
          }, function (err) {
            deferred.reject(err);
          });

        return deferred.promise;
      }

      function updateTOUDate() {
        var defer = $q.defer();
        
        $http
          .post(apiSettings.url + "/termsofuse/accept")
          .then(function (rsp) {
            defer.resolve(rsp.data);
          }, function (err) {
            defer.reject(err);
          });
        
        return defer.promise;
      }

      function updateDataAttributionAcceptanceDate() {
        var defer = $q.defer();
        $http
          .post(apiSettings.url + "/dataattribution/accept")
          .then(function (rsp) {
            defer.resolve(rsp.data);
          }, function (err) {
            defer.reject(err);
          });
        return defer.promise;
      }

      function setAttemptedUserName(userName){
        auth.userName = userName;
      }

      function clearCachedData() {
        CacheFactory.clearAll();
        if (sessionStorage.getItem(sessionStorageKeys.adminSubscriptions)) {
          sessionStorage.removeItem(sessionStorageKeys.adminSubscriptions);
        }
        if (sessionStorage.getItem(sessionStorageKeys.adminPortfolios)) {
          sessionStorage.removeItem(sessionStorageKeys.adminPortfolios);
        }
      }

      function verifyRecaptchaResponse(responseToken){
        var defer = $q.defer();
        $http
          .get(apiSettings.url + "/ReCaptcha/Verification?responseToken=" + responseToken)
          .then(function (rsp) {
            defer.resolve(rsp.data);
          }, function (err) {
            defer.reject(err);
          });
        
        return defer.promise;
      }

      function updateUserProfile(path, body) {
        var defer = $q.defer();
        $http
          .post(apiSettings.url + path, JSON.stringify(body))
          .then(function (rsp) {
            // update the auth profile variable to have the new value
            auth.userProfile = rsp.data;
            defer.resolve(rsp.data);
          }, function (err) {
            defer.reject(err);
          });
        
        return defer.promise;
      }

      function bootIntercom() {
        window.intercomSettings = {
          app_id: 'wv6d8s3b',
          //alignment: 'left',		  //Customize left or right position of messenger
          horizontal_padding: 80,	  //Customize horizontal padding
          vertical_padding: 15,		  //Customize vertical padding
          custom_launcher_selector: '#intercom',
          hide_default_launcher: true
        };

        window.Intercom("boot", {
          app_id: "wv6d8s3b",
          name: auth.firstName + ' ' + auth.lastName, // Full name
          email: auth.email, 
          user_hash: auth.userHash,
          custom_launcher_selector: '#intercom',
          hide_default_launcher: true
        });
      }

      function shutdownIntercom() {
        window.Intercom("shutdown");
      }

      init();

      return {
        impersonate: impersonate,
        init: init,
        login: login,
        logout: logout,
        changePassword: changePassword,
        reAuthenticate: reAuthenticate,
        identity: identity,
        getRecentActivity: getRecentActivity,
        getAccountDetails: getAccountDetails,
        getSubscriptionExpiration: getSubscriptionExpiration,
        stopImpersonate: stopImpersonate,
        resetPassEmail: resetPassEmail,
        lockedAccountEmail: lockedAccountEmail,
        getProductAccess: getProductAccess,
        forceLogin: forceLogin,
        updateTOUDate: updateTOUDate,
        updateDataAttributionAcceptanceDate: updateDataAttributionAcceptanceDate,
        kickOutUnauthenticatedSession: kickOutUnauthenticatedSession,
        setAttemptedUserName: setAttemptedUserName,
        verifyRecaptchaResponse: verifyRecaptchaResponse,
        updateUserProfile: updateUserProfile,
        bootIntercom: bootIntercom,
        shutdownIntercom: shutdownIntercom
      };
    }
  ])
  .run([
    "$rootScope", "$location", "$state", "$interval", "AuthService", "SpinnerService",
    function ($rootScope, $location, $state, $interval, authSvc, SpinnerService) {
      $rootScope.$on("$stateChangeStart", function(event, toState, toStateParams, fromState) {
        //this function is only for handling unauthenticated users
        //and validation of auth user's product access
        if (authSvc.identity.isAuth()) {
          var isOnChangePass = /^changePassword/i.test(toState.name);
          var isOnTou = /^termsOfUse/i.test(toState.name);
          var isOnDataAttribution = /^dataAttribution/i.test(toState.name);
          var isAnyPostLoginFlow = isOnChangePass || isOnTou || isOnDataAttribution;

          if (authSvc.identity.isPassChangeRequired() && !authSvc.identity.isImpersonated() && !isAnyPostLoginFlow) {
              // does the user need to reset the password?
              event.preventDefault();
              SpinnerService.reset();
              $state.go("changePassword");
              return;
          } else if (authSvc.identity.isAgreeToTOU() && !authSvc.identity.isImpersonated() && !isAnyPostLoginFlow) {
            // has the user accepted terms of use recently?
            event.preventDefault();
            SpinnerService.reset();
            $state.go("termsOfUse");
            return;
          } else if (authSvc.identity.isAgreeToDataAttribution() && !authSvc.identity.isImpersonated() && !isAnyPostLoginFlow) {
            // does the user need to accept data attribution?
            event.preventDefault();
            SpinnerService.reset();
            $state.go("dataAttribution");
            return;
          } else {

            if (toState.data && toState.data.productCode) {
              var productCode = toState.data.productCode;
              authSvc.getProductAccess(productCode).then(function (data) {
                if (!data) {
                  event.preventDefault();
                  SpinnerService.reset();
                  $state.go("noProductAccess", { productCode: productCode });
                }
              });
            }

            if (toState.data && toState.data.roles) {
              // check to see if user has at least 1 of the roles that are required
              for (var i = 0; i < toState.data.roles.length ; i++) {
                if (authSvc.identity.isInRole(toState.data.roles[i])) {
                  return;
                }
              }

              // didn't find role
              event.preventDefault();
              SpinnerService.reset();
              if (toState.data.stateNameToGoToWhenUserDoesNotHaveRole) {
                $state.go(toState.data.stateNameToGoToWhenUserDoesNotHaveRole);
              } else {
                $state.go("home");
              }
            }

            return;
          }
        }

        var isInLogin = /^login/i.test(toState.name);
        if (!isInLogin) {
          $rootScope.redirectState = null;
          $rootScope.redirectParams = null;
        }
        //they pressed F5 and blew away the internal javascript state
        if(!fromState.name && !isInLogin) {
          event.preventDefault();
          //are we authenticated. calls account/me and broadcasts the event if so
          authSvc.reAuthenticate().then(function () {
            $state.go(toState.name, toStateParams); //todo:handle any params
          }, function () {
            $rootScope.redirectState = toState;
            $rootScope.redirectParams = toStateParams;
            $state.go("login.home");
          })
          return;
        }
        //they're hacking the URL
        if(toState.data && toState.data.secure) {
          event.preventDefault();
          $rootScope.redirectState = toState;
          $rootScope.redirectParams = toStateParams;
          $state.go("login.home");
        }
      });
    }
  ]);
