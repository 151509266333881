(function () {
  "use strict";
  app.controller('SiteToolsPopupController', SiteToolsPopupController);
  SiteToolsPopupController.$inject = ['$rootScope', '$scope', '$state'];

  function SiteToolsPopupController($rootScope, $scope) {
    $scope.closePopupClicked = closePopupClicked;
    function closePopupClicked(leavePage) {
      if (leavePage) {
        $scope.$close(leavePage);
      } else {
        $scope.$dismiss(leavePage);
      }
    };
  }
}());
