(function (app) {
  'use strict';

  angular
    .module('NicMapApp')
    .factory('SignalRService', SignalRService)
    .run(['$rootScope', 'SignalRService', function ($rootScope, SignalRService) {
      $rootScope.$on('loggedIn', function (event, toState) {
        SignalRService.startConnection();
      });

      $rootScope.$on('loggedOut', function (event, toState) {
        SignalRService.stopConnection();
      });
    }]);

  SignalRService.$inject = ['$rootScope', '$http', '$interval', '$q', 'apiSettings', 'AuthService'];

  function SignalRService($rootScope, $http, $interval, $q, apiSettings, authService) {
    var isConnected = false;
    var connection = new signalR.HubConnectionBuilder()
      .withUrl(apiSettings.url + "/generichub")
      .configureLogging(signalR.LogLevel.Information)
      .build();

    connection.serverTimeoutInMilliseconds = 60 * 1000; // 60 seconds  

    connection.onclose(function () {
      isConnected = false;
      var isAuthenticated = authService.identity.isAuth();
      console.log('close', isAuthenticated);
      if (isAuthenticated)
        startConnection();
    });

    function startConnection() {
      if (isConnected) return;

      connection
        .start()
        .then(function (success) {
          // on success attach default events
          isConnected = true;
          console.log('start');
          setupHubOnEvt('updateGeneralAlert', null, function (message) {
            console.log('updateGeneralAlert', message);
            $rootScope.generalAlertMessage = message;
            $rootScope.$broadcast('generalAlertUpdated');
          });
        })
        .catch(function (err) {
          console.error(err);
        });
    }

    function stopConnection() {
      connection
        .stop()
        .then(function () {
          isConnected = false;
          console.log('stop');
        });
    }

    function genericHub(eventName, baseScope, callback) {
      setupHubOnEvt(eventName, baseScope, callback);
    }

    function genericHubWError(eventName, baseScope, callback) {
      setupHubOnEvt(eventName, baseScope, callback);
    }

    function setupHubOnEvt(eventName, baseScope, callback) {
      //if (!connection) startConnection();

      connection.on(eventName, function () {
        var args = arguments; // 'arguments' come from signalr
        //console.log(args); // uncomment this to see what the result comes back as...
        //$rootScope.$apply(function () {
        if (callback)
          callback.apply(connection, args);
        //});
      });
    }

    function removeHubEvent(eventName) {
      connection.off(eventName);
    }

    return {
      startConnection: startConnection,
      stopConnection: stopConnection,
      genericHub: genericHub, // main processing function
      genericHubWError: genericHubWError, // main process error function
      removeHubEvent: removeHubEvent,

      // sync helper functions --> mainly separating these calls to be able to test them
      syncDataRetrieving: genericHub,
      syncDataRetrieved: genericHub,
      syncComplete: genericHub,
      activeContactsSyncGenericHub: genericHub,
      activeContactsSyncGenericHubWError: genericHubWError,
      activeContactsSyncDataRetrieving: genericHub,
      activeContactsSyncDataRetrieved: genericHub,
      activeContactsSyncComplete: genericHub,

      // property advisor helper functions
      parGetCbsaOrCountyData: genericHub,
      parGetCbsaOrCountyDataWError: genericHubWError,

      // reports page
      reportsSilentRefresh: genericHub
    };
  }
})();
