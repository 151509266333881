(function() {
  "use strict";
  app.directive('cmsNews', NewsDirective);
  function NewsDirective() {
    return {
      templateUrl: '/app/partials/news.html',
      controllerAs: 'news',
      controller: NewsController,
      scope: {}
    }
  }

  NewsController.$inject = ['$scope','WordpressService','AuditService','knownEventConstant'];
  function NewsController($scope, WordpressService, AuditService, knownEventConstant) {
    var vm = this;
    vm.news = [];

    vm.init = function() {
      WordpressService.getNews().then(function(data) {
        vm.news = data;
      });
    }

    vm.logUserEvent = function(id, title) {
      if(title) {
        AuditService.logEvent(knownEventConstant.navNewsfeedStory.id, knownEventConstant.navNewsfeedStory.message + "-" + title);
      }
    }

    vm.imageChooser = function(image, sizes) {
      return WordpressService.imageChooser(image, sizes);
    }

    vm.init();
  }
})();
