(function() {
  "use strict";

  app.factory("SubscriptionProductMapper", subscriptionProductMapper);

  subscriptionProductMapper.$inject = ['subscriptionTypesConstant', 'productGroupConstant', 'nationalProdGroupConstant', 'qualityMetricsProdGroupConstant', 'iqDataAccessProdGroupConstant'];

  function subscriptionProductMapper(subscriptionTypesConstant, productGroupConstant, nationalProdGroupConstant, qualityMetricsProdGroupConstant, iqDataAccessProdGroupConstant) {
    var service = {
      getAvailableProducts: getAvailableProducts,
      hasAccessToProduct: hasAccessToProduct,
      hasAccessToSubscriptionType: hasAccessToSubscriptionType,
      getSelectedSubscriptionType: getSelectedSubscriptionType
    };
    return service;

    function getAvailableProducts(subscriptionCode, allProducts) {
      var availableProducts = {};
      var availableNationalProducts = {};
      var availableQualityMetricsProducts = {};
      var availableIQDataAccessProducts = {};
      var chunkSize = 5; // default
      var filteredNationalProductList = _.filter(allProducts, function (obj) { return obj.id == productGroupConstant.nat; });
      var filteredQualityMetricsProductList = _.filter(allProducts, function (obj) { return obj.id == productGroupConstant.qualmet; });
      var filteredIQDataAccessProductList = _.filter(allProducts, function (obj) { return obj.id == productGroupConstant.iqDataAccess; });
     
      switch (subscriptionCode.toLowerCase()) {
        case 'pro':
          // Professional
          chunkSize = 13;
          var filteredProductList = _.filter(allProducts, function (obj) { return obj.id ==  productGroupConstant.loc || obj.id ==  productGroupConstant.fun; });
          availableProducts = _.map(filteredProductList, function (obj) {
            return _.orderBy(obj.products, ['name'], ['asc']);
          });
      
          availableNationalProducts = _.map(filteredNationalProductList, function (obj) {
            return _.orderBy(obj.products, ['name'], ['asc']);
          });

          availableQualityMetricsProducts = _.map(filteredQualityMetricsProductList, function (obj) {
            return _.orderBy(obj.products, ['name'], ['asc']);
          });

          availableIQDataAccessProducts = _.map(filteredIQDataAccessProductList, function (obj) {
            return _.orderBy(obj.products, ['name'], ['asc']);
          });
          break;
        case 'loc': 
          // Local
          chunkSize = 7;
          var filteredProductList = _.filter(allProducts, function (obj) { return obj.id ==  productGroupConstant.loc; });
          availableProducts = _.map(filteredProductList, function (obj) {
            return _.orderBy(obj.products, ['name'], ['asc']);
          });
      
          availableNationalProducts = _.map(filteredNationalProductList, function (obj) {
            var filterOnProducts = _.filter(obj.products, function (prod) {
              return prod.id == nationalProdGroupConstant.conMonitor;
            });
            return _.orderBy(filterOnProducts, ['name'], ['asc']);
          });
      
          availableQualityMetricsProducts = _.map(filteredQualityMetricsProductList, function (obj) {
            var filterOnProducts = _.filter(obj.products, function (prod) {
              return prod.id == qualityMetricsProdGroupConstant.pir;
            });
            return _.orderBy(filterOnProducts, ['name'], ['asc']);
          });

          availableIQDataAccessProducts = _.map(filteredIQDataAccessProductList, function (obj) {
            var filterOnProducts = _.filter(obj.products, function (prod) {
              return prod.id == iqDataAccessProdGroupConstant.iqLocal || prod.id == iqDataAccessProdGroupConstant.iqPropertyInventory;
            });
            return _.orderBy(filterOnProducts, ['name'], ['asc']);

          });
          break;
        case 'fun': 
          // Fundamentals & Insights
          chunkSize = 7;
          var filteredProductList = _.filter(allProducts, function (obj) { return obj.id == productGroupConstant.fun; });
          availableProducts = _.map(filteredProductList, function (obj) {
            return _.orderBy(obj.products, ['name'], ['asc']);
          });
      
          availableNationalProducts = _.map(filteredNationalProductList, function (obj) {
            return _.orderBy(obj.products, ['name'], ['asc']);
          });
      
          availableQualityMetricsProducts = _.map(filteredQualityMetricsProductList, function (obj) {
            var filterOnProducts = _.filter(obj.products, function (prod) {
              return prod.id == qualityMetricsProdGroupConstant.pointRight;
            });
            return _.orderBy(filterOnProducts, ['name'], ['asc']);
          });

          availableIQDataAccessProducts = _.map(filteredIQDataAccessProductList, function (obj) {
            var filterOnProducts = _.filter(obj.products, function (prod) {
              return prod.id == iqDataAccessProdGroupConstant.iqTrends || prod.id == iqDataAccessProdGroupConstant.intraQuarterlyData;
            });
            return _.orderBy(filterOnProducts, ['name'], ['asc']);
          });
          break;
        case 'port': 
          // Portfolios Only
          var filteredProductList = _.filter(allProducts, function (obj) { return obj.id == productGroupConstant.port; });
          availableProducts = _.map(filteredProductList, function (obj) {
            return _.orderBy(obj.products, ['name'], ['asc']);
          });
          break;
      }
      return { 
        'availableProducts': availableProducts, 
        'availableNationalProducts': availableNationalProducts, 
        'availableQualityMetricsProducts': availableQualityMetricsProducts,
        'availableIQDataAccessProducts': availableIQDataAccessProducts,
        'chunkSize': chunkSize
      };
    }

    function hasAccessToProduct(productToCheck, iterationSubId, currentSubId, subscribedProducts) {
      var hasAccess = false;
      if (iterationSubId.id == currentSubId.subscriptionTypeID ||
          (currentSubId.subscriptionTypeID == subscriptionTypesConstant.proport && (iterationSubId.id == subscriptionTypesConstant.pro || iterationSubId.id == subscriptionTypesConstant.port)) ||
          (currentSubId.subscriptionTypeID == subscriptionTypesConstant.locport && (iterationSubId.id == subscriptionTypesConstant.loc || iterationSubId.id == subscriptionTypesConstant.port)) ||
          (currentSubId.subscriptionTypeID == subscriptionTypesConstant.funport && (iterationSubId.id == subscriptionTypesConstant.fun || iterationSubId.id == subscriptionTypesConstant.port))) {
        hasAccess = _.find(subscribedProducts, function (obj) { return productToCheck.id == obj.product.id; });
      }
      return hasAccess || false;
    }

    function hasAccessToSubscriptionType(iterationSubId, currentSubId) {
      return (iterationSubId.id == currentSubId.subscriptionTypeID ||
        (currentSubId.subscriptionTypeID == subscriptionTypesConstant.proport && (iterationSubId.id == subscriptionTypesConstant.pro || iterationSubId.id == subscriptionTypesConstant.port)) ||
        (currentSubId.subscriptionTypeID == subscriptionTypesConstant.locport && (iterationSubId.id == subscriptionTypesConstant.loc || iterationSubId.id == subscriptionTypesConstant.port)) ||
        (currentSubId.subscriptionTypeID == subscriptionTypesConstant.funport && (iterationSubId.id == subscriptionTypesConstant.fun || iterationSubId.id == subscriptionTypesConstant.port)));
    }

    function getSelectedSubscriptionType(allSubscriptionTypes) {
      var subscriptionTypeIdToReturn = null;

      var subscriptionTypesSelected = _.filter(allSubscriptionTypes, function (subType) {
        return subType.selected == true;
      });

      _.forEach(subscriptionTypesSelected, function (selectedSubType) {
        switch (selectedSubType.id){
          case subscriptionTypesConstant.port:
            if (!subscriptionTypeIdToReturn)
              subscriptionTypeIdToReturn = subscriptionTypesConstant.port;
            else
              switch (subscriptionTypeIdToReturn) {
                case subscriptionTypesConstant.fun:
                  subscriptionTypeIdToReturn = subscriptionTypesConstant.funport;
                  break;
                case subscriptionTypesConstant.loc:
                  subscriptionTypeIdToReturn = subscriptionTypesConstant.locport;
                  break;
                case subscriptionTypesConstant.pro:
                  subscriptionTypeIdToReturn = subscriptionTypesConstant.proport;
                  break;
              }
            break;
          case subscriptionTypesConstant.fun:
            if (!subscriptionTypeIdToReturn)
              subscriptionTypeIdToReturn = subscriptionTypesConstant.fun;
            else
              switch (subscriptionTypeIdToReturn) {
                case subscriptionTypesConstant.port:
                  subscriptionTypeIdToReturn = subscriptionTypesConstant.funport;
                  break;
              }
            break;
          case subscriptionTypesConstant.loc:
            if (!subscriptionTypeIdToReturn)
              subscriptionTypeIdToReturn = subscriptionTypesConstant.loc;
            else
              switch (subscriptionTypeIdToReturn) {
                case subscriptionTypesConstant.port:
                  subscriptionTypeIdToReturn = subscriptionTypesConstant.locport;
                  break;
              }
            break;
          case subscriptionTypesConstant.pro:
            if (!subscriptionTypeIdToReturn)
              subscriptionTypeIdToReturn = subscriptionTypesConstant.pro;
            else
              switch (subscriptionTypeIdToReturn) {
                case subscriptionTypesConstant.port:
                  subscriptionTypeIdToReturn = subscriptionTypesConstant.proport;
                  break;
              }
            break;
        }
      });

      return subscriptionTypeIdToReturn;
    }
  }
}());
