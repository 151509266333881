(function () {
  "use strict";
  app.factory("DemographicService", DemographicService);
  
  DemographicService.$inject = ['$http', '$q', 'apiSettings', 'DateOffsetUtils'];
  
  function DemographicService($http, $q, apiSettings, DateOffsetUtils) {
    function getDemographicPopupInformation() {
      var defer = $q.defer();
      
      var uri = apiSettings.url + "/siteInformationReports/getDemographicPopupInformation";

      $http
        .get(uri)
        .then(function (rsp) {
          defer.resolve(rsp.data);
        }, function (err) {
          defer.reject(err);
        });
      
      return defer.promise;
    }

    function runSiteInformationReport(paramsPassedIn) {
      var deferred = $q.defer();
      var uri = apiSettings.url + '/siteInformationReports/runSiteInformationReport';
      var tradeArea = {
        bbox: paramsPassedIn.tradeAreaShapes.bbox,
        shape1: paramsPassedIn.tradeAreaShapes.shape1,
        shape2: paramsPassedIn.tradeAreaShapes.shape2,
        vintageId: paramsPassedIn.tradeAreaShapes.vintageId
      };
      var data = {
        areaAccessed: paramsPassedIn.areaAccessed,
        latitude: paramsPassedIn.latitude,
        longitude: paramsPassedIn.longitude,
        cbsaName: paramsPassedIn.cbsaName,
        cbsaCode: paramsPassedIn.cbsaCode,
        countyName: paramsPassedIn.countyName,
        countyFips: paramsPassedIn.countyFips,
        tradeArea: tradeArea
      };
    
      $http
        .post(uri, data)
        .then(function(rsp) {
          if (rsp.data) {
            deferred.resolve(rsp.data);
          } else {
            deferred.reject();
          }
        }, function(err) {
          deferred.reject(err);
        });
    
      return deferred.promise;
    }

    function geocodeLocation(lat, long) {
      var deferred = $q.defer();

      var uri = apiSettings.url + '/geocode/geocodeLatLong?' +
        $.param({
          latitude: lat,
          longitude: long
        });

      $http
        .get(uri)
        .then(function (rsp) {
          deferred.resolve(rsp);
        }, function(err) {
          deferred.reject(err);
        });

      return deferred.promise;
    }

    function generateSIRExcel(subscriptionId, title, filters, sort) {
      var deferred = $q.defer();
      var filter = filters.length > 0 ? buildSIRFilter(filters) : {};
      var sortData = sort.length > 0 ? buildSIRSort(sort) : {};
      var dateExportedOn = new Date();

      var uri = apiSettings.url + '/siteInformationReports/getDeomgraphicUsagePerSubscription/export/excel/'
        + subscriptionId + "?"
        + $.param({ date: dateExportedOn, dateOffset: DateOffsetUtils.getTimezoneOffset(dateExportedOn), title: title, sortData: sortData });

      $http
        .post(uri, filter, {
          responseType: 'arraybuffer'
        })
        .then(function(rsp) {
          if (rsp.data.byteLength === 0) {
            deferred.reject();
          } else {
            deferred.resolve(rsp.data);
          }
        }, function(err) {
          deferred.reject(err);
        });

      return deferred.promise;
    }

    function buildSIRFilter(filters) {
      var filter = {
        accessedBy: null,
        createdDate: null,
        areaAccessed: null
      }
      angular.forEach(filters, function(filterValue) {
        switch (filterValue.field) {
          case "accessedBy":
            filter.accessedBy = filterValue.value;
            break;
          case "createdDate":
            filter.createdDate = filterValue.value;
            break;
          case "areaAccessed":
            filter.areaAccessed = filterValue.value;
            break;
        }
      });
      return filter;
    }

    function buildSIRSort(sort) {
      return JSON.stringify({
        field: sort[0].field,
        dir: sort[0].dir
      });
    }

    return {
      getDemographicPopupInformation:getDemographicPopupInformation,
      runSiteInformationReport: runSiteInformationReport,
      geocodeLocation: geocodeLocation,
      generateSIRExcel: generateSIRExcel
    };
  }
}());
