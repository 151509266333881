(function() {
  "use strict";

  app.factory("ReportsService", ReportsService);
  ReportsService.$inject = ['$http', '$q', 'apiSettings', "DateOffsetUtils", "CacheFactory"];

  function ReportsService($http, $q, apiSettings, DateOffsetUtils, CacheFactory) {
    if (!CacheFactory.get('reportsCache')) {
      CacheFactory.createCache('reportsCache', {
        maxAge: 60 * 60 * 1000, // Items added to this cache expire after 1 hour
        cacheFlushInterval: 24 * 60 * 60 * 1000, // This cache will clear itself every day
        deleteOnExpire: 'aggressive', // Items will be deleted from this cache when they expire
        storageMode: 'sessionStorage'
      });
    }
    var reportsCache = CacheFactory.get('reportsCache');
    var lastCounties, lastMetro;

    return {
      all: all,
      generateCountyTrendsByPropertyTypeExcel: generateCountyTrendsByPropertyTypeExcel,
      generateCountyTrendsBySegmentTypeExcel: generateCountyTrendsBySegmentTypeExcel,
      generateCbsaTrendsByPropertyTypeExcel: generateCbsaTrendsByPropertyTypeExcel,
      generateCbsaTrendsBySegmentTypeExcel: generateCbsaTrendsBySegmentTypeExcel,
      generateCbsaTrendsByCommunityTypeExcel: generateCbsaTrendsByCommunityTypeExcel,
      generateDodgeConstructionExcel: generateDodgeConstructionExcel,
      generateIntraQuarterlyDataExcel: generateIntraQuarterlyDataExcel,
      generateIntraQuarterlyPropertyInventoryExcel: generateIntraQuarterlyPropertyInventoryExcel,
      generatePropSalesTransExcel: generatePropSalesTransExcel,
      getDynamicReportFileNames: getDynamicReportFileNames,
      generateRankingsExcel: generateRankingsExcel,
      generateAnalystExcel: generateAnalystExcel,
      generatePropertyInventoryExcel: generatePropertyInventoryExcel,
      generateBLSExcel: generateBLSExcel,
      getMarkets: getMarkets,
      getRegionsAndStates: getRegionsAndStates,
      getMetros: getMetros,
      getFilteredMetros: getFilteredMetros,
      getCounties: getCounties,
      fileProxyDownload: fileProxyDownload,
      preCacheReports: preCacheReports,
      clearCache: clearCache,
      generatePointRightExcel: generatePointRightExcel,
      generateRateTiersExcel: generateRateTiersExcel,
      generateMedicareHospitalInventoryExcel: generateMedicareHospitalInventoryExcel
    }

    function fetchReportCoordinatorReport(uri) {
      var deferred = $q.defer();
      $http
        .get(uri, {
          responseType: 'json'
        })
        .then(function (rsp) {
          if (!rsp.data) {
            deferred.reject();
          } else {
            var reportCoordinatorUri = rsp.data.url + "?"
              + $.param({ timeoffset: DateOffsetUtils.getTimezoneOffset(new Date()) });
            $http
              .get(reportCoordinatorUri, {
                responseType: 'arraybuffer',
                headers: {
                  'Authorization': 'Bearer ' + rsp.data.token
                }
              })
              .then(function (rsp) {
                if (rsp) {
                  deferred.resolve(rsp.data);
                } else {
                  console.error(rsp);
                  deferred.reject('Something happened!');
                }
              }, function (err) {
                deferred.reject(err);
              });
          }
        }, function (err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function all() {
      var deferred = $q.defer();
      $http.get(apiSettings.url + "/reports/all")
        .then(function (rsp) {
          deferred.resolve(rsp.data);
        },
        function () {
          deferred.reject();
        });
      return deferred.promise;
    }

    function preCacheReports() {
      var deferred = $q.defer();
      var uri = apiSettings.url + '/reports/PreCacheGeneratedReports';

      $http
        .post(uri)
        .then(function (rsp) {
          deferred.resolve(rsp);
        }, function (err) {
          deferred.reject(err);
        });

      return deferred.promise;
    }

    function fileProxyDownload(params) {
      var deferred = $q.defer();
      var uri = apiSettings.url + "/fileproxy/" + params.fromWhere.replace(/ /g, '') + "?" +
        $.param(params);

      $http
        .get(uri, { responseType: 'arraybuffer' })
        .then(function (rsp) {
          deferred.resolve(rsp.data);
        }, function (err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function getDynamicReportFileNames() {
      var deferred = $q.defer();
      $http
        .get(apiSettings.url + '/reports/GetDynamicReportFileNames')
        .then(function (rsp) {
          deferred.resolve(rsp.data);
        },
        function () {
          deferred.reject();
        });
      return deferred.promise;
    }

    function generateCountyTrendsByPropertyTypeExcel(marketId) {
        var uri = apiSettings.url
            + '/reports/countyTrendsbyPropertyType/export/excel/?'
            + $.param({ marketId: marketId });
        return fetchReportCoordinatorReport(uri);
    }
    
    function generateCountyTrendsBySegmentTypeExcel(marketId) {
        var uri = apiSettings.url
            + '/reports/countyTrendsBySegmentType/export/excel/?'
            + $.param({ marketId: marketId });
        return fetchReportCoordinatorReport(uri);
    }

    function generateCbsaTrendsBySegmentTypeExcel() {
      var deferred = $q.defer();
      var uri = apiSettings.url + '/reports/cbsaTrendsbySegmentType/export/excel/?' +
        $.param({
          timeoffset: DateOffsetUtils.getTimezoneOffset(new Date())
        });

      $http
        .post(uri, {}, {
          responseType: 'arraybuffer'
        })
        .then(function (rsp) {
          if (rsp.data.length == 0) {
            deferred.reject();
          } else {
            deferred.resolve(rsp.data);
          }
        }, function (err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function generateCbsaTrendsByPropertyTypeExcel() {
      var deferred = $q.defer();
      var uri = apiSettings.url + '/reports/cbsaTrendsbyPropertyType/export/excel/?' +
        $.param({
          timeoffset: DateOffsetUtils.getTimezoneOffset(new Date())
        });

      $http
        .post(uri, {}, {
          responseType: 'arraybuffer'
        })
        .then(function (rsp) {
          if (rsp.data.length == 0) {
            deferred.reject();
          } else {
            deferred.resolve(rsp.data);
          }
        }, function (err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function generateCbsaTrendsByCommunityTypeExcel() {
      var deferred = $q.defer();
      var uri = apiSettings.url + '/reports/cbsaTrendsByCommunityType/export/excel/?' +
        $.param({
          timeoffset: DateOffsetUtils.getTimezoneOffset(new Date())
        });

      $http
        .post(uri, {}, {
          responseType: 'arraybuffer'
        })
        .then(function (rsp) {
          if (rsp.data.length == 0) {
            deferred.reject();
          } else {
            deferred.resolve(rsp.data);
          }
        }, function (err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function generateDodgeConstructionExcel(marketId, regionId, cbsaCode) {
      var deferred = $q.defer();
      var uri = apiSettings.url + '/reports/DodgeConstruction/export/excel/?' +
        $.param({
          marketId: marketId,
          regionId: regionId,
          cbsaCode: cbsaCode,
          timeoffset: DateOffsetUtils.getTimezoneOffset(new Date())
        });

      $http
        .post(uri, {}, {
          responseType: 'arraybuffer'
        })
        .then(function (rsp) {
          if (rsp.data.length == 0) {
            deferred.reject();
          } else {
            deferred.resolve(rsp.data);
          }
        }, function (err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function generateIntraQuarterlyDataExcel(marketId, cbsaCode, monthYear) {
      var uri = apiSettings.url
        + '/reports/intra-quarterly-data/export/excel?'
        + $.param({
          marketId: marketId,
          cbsaCode: cbsaCode,
          monthYear: monthYear
        });
      return fetchReportCoordinatorReport(uri);
    }

    function generateIntraQuarterlyPropertyInventoryExcel(monthYear) {
      var uri = apiSettings.url
        + '/reports/intra-quarterly-property-inventory/export/excel?'
        + $.param({
          monthYear: monthYear
        });
      return fetchReportCoordinatorReport(uri);
    }

    function generatePropSalesTransExcel(cbsaCode) {
      var deferred = $q.defer();
      var uri = apiSettings.url + '/reports/SalesCompSearch/export/excel/?' +
        $.param({
          cbsaCode: cbsaCode,
          timeoffset: DateOffsetUtils.getTimezoneOffset(new Date())
        });

      $http
        .post(uri, {}, {
          responseType: 'arraybuffer'
        })
        .then(function (rsp) {
          if (rsp.data.length == 0) {
            deferred.reject();
          } else {
            deferred.resolve(rsp.data);
          }
        }, function (err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function generateRateTiersExcel(cbsaCode) {
      var deferred = $q.defer();
      var uri = apiSettings.url + '/reports/RateTiers/export/excel/?' +
        $.param({
          cbsaCode: cbsaCode,
          timeoffset: DateOffsetUtils.getTimezoneOffset(new Date())
        });

      $http
        .post(uri, {}, {
          responseType: 'arraybuffer'
        })
        .then(function (rsp) {
          if (rsp.data.length == 0) {
            deferred.reject();
          } else {
            deferred.resolve(rsp.data);
          }
        }, function (err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }


    function generateRankingsExcel(market, region) {
        var deferred = $q.defer();
        var uri = apiSettings.url + '/reports/Rankings/export/excel/?' +
          $.param({
            market: market,
            region: region,
            timeoffset: DateOffsetUtils.getTimezoneOffset(new Date())
          });

        $http
          .post(uri, {}, {
            responseType: 'arraybuffer'
          })
          .then(function (rsp) {
            if (rsp.data.length == 0) {
              deferred.reject();
            } else {
              deferred.resolve(rsp.data);
            }
          }, function (err) {
            deferred.reject(err);
          });
        return deferred.promise;
    }

    function generateAnalystExcel(marketId, regionId, cbsaCode, countyFips) {
        var deferred = $q.defer();
        var uri = apiSettings.url + '/reports/Analyst/export/excel/?' +
          $.param({
            marketId: marketId,
            regionId: regionId,
            cbsaCode: cbsaCode,
            countyFips: countyFips,
            timeoffset: DateOffsetUtils.getTimezoneOffset(new Date())
          });

        $http
          .post(uri, {}, {
            responseType: 'arraybuffer'
          })
          .then(function (rsp) {
            if (rsp.data.length == 0) {
              deferred.reject();
            } else {
              deferred.resolve(rsp.data);
            }
          }, function (err) {
            deferred.reject(err);
          });

        return deferred.promise;
    }

    function generatePropertyInventoryExcel() {
        var uri = apiSettings.url + '/reports/PropertyInventory/export/excel/';
        return fetchReportCoordinatorReport(uri);
    }

    function generateBLSExcel(state, fileType, publishDate) {
      // fileType: 1) Excel, or 2) Raw Data
      var deferred = $q.defer();
      var uri = apiSettings.url + '/reports/BLS/export/' + fileType;

      var data = {
        state: state,
        publishDate: publishDate,
        timeoffset: DateOffsetUtils.getTimezoneOffset(new Date())
      };

      $http
        .post(uri, data, {
          responseType: 'arraybuffer'
        })
        .then(function (rsp) {
          if (rsp.data.length == 0) {
            deferred.reject();
          } else {
            deferred.resolve(rsp.data);
          }
        }, function (err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function generatePointRightExcel(stateId, stateName, publishDate) {
      var deferred = $q.defer();
      var uri = apiSettings.url + '/reports/pointRight/export/excel';

      var data = {
        stateId: stateId,
        stateName: stateName,
        publishDate: publishDate,
        timeoffset: DateOffsetUtils.getTimezoneOffset(new Date())
      };

      $http
        .post(uri, data, {
          responseType: 'arraybuffer'
        })
        .then(function (rsp) {
          if (rsp.data.length == 0) {
            deferred.reject();
          } else {
            deferred.resolve(rsp.data);
          }
        }, function (err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function generateMedicareHospitalInventoryExcel() {
      var deferred = $q.defer();
      var uri = apiSettings.url + '/reports/MedicareHospitalInventory/export/excel/?' +
        $.param({          
          timeoffset: DateOffsetUtils.getTimezoneOffset(new Date())
        });

      $http
        .post(uri, {}, {
          responseType: 'arraybuffer'
        })
        .then(function (rsp) {
          if (rsp.data.length == 0) {
            deferred.reject();
          } else {
            deferred.resolve(rsp.data);
          }
        }, function (err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function clearCache() {
      var allCacheKeys = reportsCache.keys();
      for (var idx = allCacheKeys.length - 1; idx >= 0; idx--) {
        var currentKey = allCacheKeys[idx];
        // remove all cache except for market types since they are always the same...
        if (currentKey.indexOf('/markets') < 0) {
          reportsCache.remove(currentKey);
        }
      }
      
      lastCounties = null;
      lastMetro = null;

      return reportsCache.keys().length;
    }

    function getMarkets() {
      var url = apiSettings.url + "/reports/markets";
      var deferred = $q.defer();
      $http.get(url, { cache: reportsCache })
        .then(function (rsp) {
          deferred.resolve(rsp.data);
        }, function (err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function getRegionsAndStates() {
      var url = apiSettings.url + "/reports/regionsandstates";
      var deferred = $q.defer();
      $http.get(url, { cache: reportsCache })
        .then(function (rsp) {
          deferred.resolve(rsp.data);
        }, function (err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function getMetros() {
      var url = apiSettings.url + "/reports/metros";
      var deferred = $q.defer();
      $http.get(url, { cache: reportsCache })
        .then(function (rsp) {
          deferred.resolve(rsp.data);
        }, function (err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function getFilteredMetros(marketName, regionId) {
      var deferred = $q.defer();
      var url = apiSettings.url + "/reports/filteredmetros?marketName=" + marketName;
      if (regionId) {
        url = url.concat("&regionId=" + regionId);
      }
      $http.get(url, { cache: reportsCache })
        .then(function (rsp) {
          deferred.resolve(rsp.data);
        }, function (err) {
          deferred.reject(err);
        });
      return deferred.promise;
    };

    function getCounties(metro) {
      var deferred = $q.defer();
      if (!metro) {
        //metro can be null, like when switching to a saved filter
        deferred.resolve([]);
        return deferred.promise;
      }
      if (lastCounties) {
        if (metro == lastMetro) {
          //simple caching
          deferred.resolve(lastCounties);
          return deferred.promise;
        }
      }

      var url = apiSettings.url + "/reports/counties?metro=" + metro;

      $http.get(url, { cache: reportsCache })
        .then(function (rsp) {
          lastCounties = rsp.data;
          lastMetro = metro;
          deferred.resolve(rsp.data);
        }, function (err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }
  }
})();
