(function () {
  return angular.module('NicMapApp.routes', ["ui.router", "ngAnimate"])
    .config([
      "$urlRouterProvider", "$stateProvider", "$urlMatcherFactoryProvider",
      function ($urlRouterProvider, $stateProvider, $urlMatcherFactoryProvider) {

        //https://github.com/angular-ui/ui-router/issues/1119
        $urlMatcherFactoryProvider.type("alwaysValid", {
          encode: function valToString(val) { return val != null ? val.toString() : val; },
          decode: function valFromString(val) { return val != null ? val.toString() : val; },
          is: function regexpMatches(val) { return true; }
        });
        $urlMatcherFactoryProvider.strictMode(false);
        //https://github.com/angular-ui/ui-router/wiki/Frequently-Asked-Questions#how-to-set-up-a-defaultindex-child-state
        $urlRouterProvider.otherwise("/");

        $stateProvider
          .state("login", {
            url: "/login",
            abstract: true,
            templateUrl: "/app/views/login/login.html",
            controller: "LoginController"
          })
          .state("login.home", {
            url: "",
            templateUrl: "/app/views/login/mainLoginCtrl.html",
            controller: "LoginController"
          })
          .state("login.forgotpassword", {
            url: "/forgotpassword",
            templateUrl: "/app/views/login/loginForgotPassword.html",
            controller: "LoginController"
          })
          .state("login.resetPasswordConfirmation", {
            url: "/resetPassword",
            templateUrl: "/app/views/login/loginResetPasswordConfirmation.html",
            controller: "LoginController"
          })
          .state("login.contact", {
            url: "/contact",
            templateUrl: "/app/views/contact.html",
            controller: "ContactController"
          })
          .state("login.contactResponse", {
            url: "/contactResponse",
            templateUrl: "/app/views/contactResponse.html",
          })
          .state("login.maxLoginAttempts", {
            url: "/maxLoginAttempts",
            templateUrl: "/app/views/login/maxLoginAttempts.html",
            controller: "LoginController"
          })
          .state("login.invalidSubscription", {
            url: "/invalidSubscription",
            templateUrl: "/app/views/login/invalidSubscription.html",
            controller: "LoginController"
          })
          .state("login.expiredSubscription", {
            url: "/expiredSubscription",
            templateUrl: "/app/views/login/expiredSubscription.html",
            controller: "LoginController"
          })
          .state("login.terminatedSubscription", {
            url: "/terminatedSubscription",
            templateUrl: "/app/views/login/terminatedSubscription.html",
            controller: "LoginController"
          })
          .state("login.forceKickout", {
            url: "/invalidSession",
            templateUrl: "/app/views/login/loginForceKickoutConfirmation.html"
          })
          .state("login.siteOffLine", {
            url: "/siteOffLine",
            templateUrl: "/app/views/login/loginSiteOffLine.html"
          })
          .state("login.termsOfUseStandalone", {
            url: "/terms",
            controller: "TermsOfUseController",
            templateUrl: "/app/views/touStandalone.html"
          })
          .state("home", {
            url: "/",
            templateUrl: "/app/views/home.html",
            controller: "HomeController",
            controllerAs: 'home',
            data: {
              secure: true
            }
          })
          .state("support", {
            url: "/support",
            templateUrl: "/app/views/support.html",
            controller: "SupportController",
            data: {
              secure: true,
              transition: false
            }
          })
          .state("contact", {
            url: "/contact?selectedType&selectedReason",
            params: {
              selectedType: {
                value: null,
                squash: true
              },
              selectedReason: {
                value: null,
                squash: true
              }
            },
            templateUrl: "/app/views/contact.html",
            controller: "ContactController"
          })
          .state("contactResponse", {
            url: "/contactResponse",
            templateUrl: "/app/views/contactResponse.html"
          })
          .state("changePassword", {
            url: "/changePassword",
            templateUrl: "/app/views/changePassword.html",
            controller: 'ChangePasswordController'
          })
          .state("changePasswordConfirmation", {
            url: "/changePassword",
            templateUrl: "/app/views/changePasswordConfirmation.html",
            controller: "ChangePasswordController"
          })
          .state("dataAttribution", {
            url: "/dataAttribution",
            templateUrl: "/app/views/dataAttribution.html",
            controller: "DataAttributionController"
          })
          .state("dataAttributionStandalone", {
            url: "/dataAttributionStandalone",
            templateUrl: "/app/views/dataAttributionStandalone.html",
            controller: "DataAttributionController"
          })
          .state("termsOfUse", {
            url: "/termsofuse",
            controller: "TermsOfUseController",
            templateUrl: "/app/views/tou.html"
          })
          .state("termsOfUseStandalone", {
            url: "/terms",
            controller: "TermsOfUseController",
            templateUrl: "/app/views/touStandalone.html"
          })
          .state("admin", {
            url: "/admin",
            abstract: true,
            templateUrl: "/app/views/admin/admin.html",
            controller: "AdminController",
            data: {
              secure: true,
              roles: ['Administrator'],
              stateNameToGoToWhenUserDoesNotHaveRole: 'accountDetails'
            }
          })
          .state("admin.subscriptions", {
            url: "/subscriptions",
            templateUrl: "/app/views/admin/subscriptions.html",
            controller: "SubscriptionController",
            data: {
              transition: false
            }
          })
          .state("admin.users", {
            url: "/users",
            templateUrl: "/app/views/admin/users.html"
          })
          .state("admin.portfolios", {
            url: "/portfolios",
            templateUrl: "/app/views/admin/portfolios.html",
            controller: "AdminPortfoliosController",
            data: {
              transition: false
            }
          })
          .state("admin.sitetools", {
            url: "/sitetools",
            templateUrl: "/app/views/admin/siteTools.html",
            controller: "SiteToolsController",
            data: {
              transition: false
            }
          })
          .state("admin.usage", {
            url: "/usage",
            templateUrl: "/app/views/admin/usage.html",
            controller: "UsageController",
            data: {
              transition: false
            }
          })
          .state("admin.usage.clientApi", {
            url: "/capi",
            templateUrl: "/app/views/admin/usageClientApi.html",
            controller: "ClientApiUsageController",
            data: {
              transition: false
            }
          })
          .state("admin.usage.metroBank", {
            url: "/metrobank",
            templateUrl: "/app/views/admin/usageMetroBank.html",
            controller: "MetroBankUsageController",
            data: {
              transition: false
            }
          })
          .state("admin.signalrinfo", {
            url: "/signalrinfo",
            templateUrl: "/app/views/admin/signalRInfo.html",
            controller: "SignalRInfoController",
            data: {
              transition: false
            }
          })
          .state("admin.subscriptiondetails", {
            url: "/subscriptions/:id/:companyId",
            views: {
              '@': {
                templateUrl: "/app/views/admin/subscriptionDetails.html",
                controller: "SubscriptionDetailsController",
                data: {
                  secure: true
                }
              }
            }
          })
          .state("admin.portfolios.details", {
            url: "/:id",
            views: {
              '@': {
                templateUrl: "/app/views/admin/portfolioDetails.html",
                controller: "AdminPortfolioDetailsController"
              }
            },
            resolve: {
              portfolio: ["AdminPortfolioService", '$stateParams', function (AdminPortfolioService, $stateParams) {
                return AdminPortfolioService.getPortfolioData($stateParams.id);
              }]
            }
          })
          .state("admin.portfolios.details.editcomps", {
            url: "/editcomps/:detailsId",
            views: {
              '@': {
                templateUrl: "/app/views/admin/portfolioDetailsEditComps.html",
                controller: "AdminEditCompsController",
              }
            }
          })
          .state("accountDetails", {
            url: "/accountDetails",
            templateUrl: "/app/views/accountDetails.html",
            controller: "AccountDetailsController",
            data: {
              secure: true
            }
          })
          .state("trends", {
            url: "/trends",
            templateUrl: "/app/views/trends/trends.html",
            controller: "TrendsController",
            data: {
              secure: true
            },
            abstract: true
          })
          .state("trends.propertyType", {
            url: "/propertyType?query&exportType&filterName",
            data: {
              productCode: "Trends",
              transition: false
            },
            views: {
              'propertyTypeFilters': {
                templateUrl: 'app/views/trends/trendsFilter.html',
                controller: 'TrendsFilterController as filterCtrl',
                resolve: {
                  savedFilters: ["TrendsService", function (TrendsService) {
                    return TrendsService.getSavedPropertyTypeFilters(true);
                  }],
                  filterName: ["$stateParams", function ($stateParams) {
                    if ($stateParams.filterName) {
                      return $stateParams.filterName;
                    } else {
                      return null;
                    }
                  }],
                  queryFilters: ["TrendsService", "$stateParams", function (TrendsService, $stateParams) {
                    if ($stateParams.query) {
                      return TrendsService.getSavedPropertyTypeFilterByQueryID($stateParams.query);
                    } else {
                      return null;
                    }
                  }],
                  TrendsServiceSubset: ['TrendsService', function (TrendsService) {
                    return {
                      createFilter: TrendsService.createSavedPropertyTypeFilter,
                      deleteFilter: TrendsService.deleteSavedPropertyTypeFilter,
                      getFilters: TrendsService.getSavedPropertyTypeFilters,
                      getFilterByName: TrendsService.getSavedPropertyTypeFilter,
                      getChartData: TrendsService.getChartData,
                      getExcel: TrendsService.getPropertyExcel,
                      getPdf: TrendsService.getPropertyPdf,
                      getFilterText: TrendsService.getFilterText,
                      getFilteredMetros: TrendsService.getFilteredMetros,
                      getFilteredCounties: TrendsService.getFilteredCounties,
                      getCampusTypes: TrendsService.getCommunityCampusTypes,
                      subHasAccessToFilters: TrendsService.subHasAccessToFilters
                    }
                  }]
                }
              },
              '': {
                templateUrl: '/app/views/trends/charts.html',
                controller: "TrendsChartsController",
                resolve: {
                  exportFilePrefix: function () {
                    return "NICMAP Trends";
                  }
                }
              }
            }
          })
          .state("trends.communityType", {
            url: "/communityType?query&exportType&filterName",
            data: {
              productCode: "Trends",
              transition: false
            },
            views: {
              'communityTypeFilters': {
                templateUrl: 'app/views/trends/trendsFilter.html',
                controller: 'TrendsFilterController as filterCtrl',
                resolve: {
                  savedFilters: ["TrendsService", function (TrendsService) {
                    return TrendsService.getSavedCommunityTypeFilters(true);
                  }],
                  filterName: ["$stateParams", function ($stateParams) {
                    if ($stateParams.filterName) {
                      return $stateParams.filterName;
                    } else {
                      return null;
                    }
                  }],
                  queryFilters: ["TrendsService", "$stateParams", function (TrendsService, $stateParams) {
                    if ($stateParams.query) {
                      return TrendsService.getSavedCommunityTypeFilterByQueryID($stateParams.query);
                    } else {
                      return null;
                    }
                  }],
                  TrendsServiceSubset: ['TrendsService', function (TrendsService) {
                    return {
                      createFilter: TrendsService.createSavedCommunityTypeFilter,
                      deleteFilter: TrendsService.deleteSavedCommunityTypeFilter,
                      getFilters: TrendsService.getSavedCommunityTypeFilters,
                      getFilterByName: TrendsService.getSavedCommunityTypeFilter,
                      getChartData: TrendsService.getCommunityChartData,
                      getExcel: TrendsService.getCommunityExcel,
                      getPdf: TrendsService.getCommunityPdf,
                      getFilterText: TrendsService.getFilterText,
                      getFilteredMetros: TrendsService.getFilteredMetros,
                      getFilteredCounties: TrendsService.getFilteredCounties,
                      getCampusTypes: TrendsService.getCommunityCampusTypes,
                      subHasAccessToFilters: TrendsService.subHasAccessToFilters
                    }
                  }]
                }
              },
              '': {
                templateUrl: '/app/views/trends/charts.html',
                controller: "TrendsChartsController",
                resolve: {
                  exportFilePrefix: function () {
                    return "NICMAP Trends Community Type";
                  }
                }
              }
            }
          })
          .state("trends.actualRents", {
            url: "/actualRents",
            views: {
              'actualRents': {
                template: 'Coming Soon'
              },
              '': {
                templateUrl: '/app/views/trends/charts.html',
                controller: "TrendsChartsController",
                resolve: {
                  exportFilePrefix: function () {
                    return "NICMAP Trends Actual Rents";
                  }
                }
              }
            }
          })
          .state("localpropertysearch", {
            url: "/localpropertysearch",
            abstract: true,
            template: "<ui-view/>",
            data: {
              secure: true,
              productCode: "Local"
            }
          })
          .state("localpropertysearch.base", {
            url: "?latitude&longitude&query&id",
            templateUrl: "/app/views/localPropertySearch.html",
            controller: "LocalPropertySearchController",
            params: {
              resetState: null
            },
            resolve: {
              initialBingResult: ["SearchService", "$stateParams", function (SearchService, $stateParams) {
                return SearchService.getSearchAll($stateParams);
              }],
              currentQuarter: ["TrendsService", function (TrendsService) {
                return TrendsService.getCurrentQuarter();
              }],
              propertyTypes: ["SearchService", function (SearchService) {
                return SearchService.propertyTypes();
              }],
              campusTypes: ["TrendsService", function (TrendsService) {
                return TrendsService.getFilterData('campusTypes');
              }],
              segmentTypes: ["SearchService", function (SearchService) {
                return SearchService.getSegmentTypes();
              }],
              ageCategories: ["SearchService", function (SearchService) {
                return SearchService.getAgeCategory();
              }],
              allRadiusList: ["SearchService", function (SearchService) {
                return SearchService.getRadius();
              }],
              savedSearches: ["SearchService", function (SearchService) {
                return SearchService.getSavedSearches();
              }],
              savedPARReports: ["PropertyAdvisorService", function (PropertyAdvisorService) {
                return PropertyAdvisorService.getSavedPARReports();
              }],
              pushpinImages: ["PushPinImageCache", function (PushPinImageCache) {
                return PushPinImageCache.getPushPinImages();
              }],
              vintages: ["SearchService", function (SearchService) {
                return SearchService.getVintages();
              }]
            }
          })
          .state("noProductAccess", {
            url: "/productAccess/:productCode",
            templateUrl: 'app/views/noProductAccess.html',
            controller: "ProductAccessController",
            data: {
              secure: true
            }
          })
          .state("reports", {
            url: "/reports",
            controller: "ReportsController",
            templateUrl: "/app/views/reports.html",
            data: {
              secure: true
            }
          })
          .state("reportsDownload", {
            url: "/reports/{directDownloadReport:alwaysValid}",
            controller: "ReportsController",
            templateUrl: "/app/views/reports.html",
            data: {
              secure: true
            },
          })
          .state("portfolios.manage", {
            url: "/manage",
            views: {
              '@': {
                templateUrl: "/app/views/portfolios/manage.html",
                controller: "PortfoliosManageController"
              }
            }
          })
          .state("portfolios", {
            url: "/portfolios",
            controller: "UserPortfolioController",
            templateUrl: "/app/views/portfolios/portfolios.html",
            resolve: {
              data: ["PortfolioService", function (PortfolioService) {
                return PortfolioService.portfolios();
              }],
              segmentTypes: ["SearchService", function (SearchService) {
                return SearchService.getSegmentTypesPortfolio();
              }],
              currentQuarter: ["TrendsService", function (TrendsService) {
                return TrendsService.getCurrentQuarter();
              }],
              isSubscriber: ["PortfolioService", function (PortfolioService) {
                return PortfolioService.getProductAccess("RawDataSegmentTrends");
              }]
            },
            data: {
              secure: true,
              productCode: "MAPPortfolios"
            },
          })
          .state("portfolios.charts", {
            url: "/:id/charts",
            controller: "PortfolioChartsController",
            templateUrl: "/app/views/portfolios/portfolio.charts.html",
            resolve: {
              data: ["PortfolioService", "$stateParams", function (PortfolioService, $stateParams) {
                return PortfolioService.portfolio($stateParams.id);
              }]
            },
            data: {
              transition: false
            }
          })
          .state("portfolios.details.edit", {
            url: "/edit",
            views: {
              '@': {
                templateUrl: "/app/views/portfolios/portfolio.edit.html",
                controller: "PortfolioEditController",
                resolve: {
                  editPortfolio: ["PortfolioService", "$stateParams", function (PortfolioService, $stateParams) {
                    return PortfolioService.getPortfolioToEdit($stateParams.id);
                  }]
                }
              }
            }
          })
          .state("portfolios.details", {
            url: "/:id/details",
            views: {
              '@': {
                controller: "UserPortfolioController",
                templateUrl: "/app/views/portfolios/portfolios.html",
              }
            },
            resolve: {
              data: ["PortfolioService", "$stateParams", function (PortfolioService, $stateParams) {
                return PortfolioService.portfolioProperties($stateParams.id);
              }],
              segmentTypes: ["SearchService", function (SearchService) {
                return SearchService.getSegmentTypesPortfolio();
              }],
              currentQuarter: ["TrendsService", function (TrendsService) {
                return TrendsService.getCurrentQuarter();
              }]
            },
            data: {
              secure: true
            }
          })
          .state("portfolios.details.charts", {
            url: "/:propertyId/charts",
            controller: "PortfolioChartsController",
            templateUrl: "/app/views/portfolios/portfolio.charts.html",
            resolve: {
              data: ["PortfolioService", "$stateParams", function (PortfolioService, $stateParams) {
                return PortfolioService.portfolio($stateParams.id);
              }]
            },
            data: {
              transition: false
            }
          })
          .state("propertyCompare", {
            url: "/propertyCompare/:portfolioId/:propertyId?exportType",
            templateUrl: "/app/views/portfolios/propertyCompare.html",
            controller: "PropertyCompareController",
            resolve: {
              report: ["PropertyCompareService", "$stateParams", "$state", function (PropertyCompareService, $stateParams, $state) {
                return PropertyCompareService.hasAccess($stateParams.propertyId).catch(function () {
                  $state.go('pageNotFound'); // may be a new warning page later on...
                });
              }],
              currentQuarter: ["TrendsService", function (TrendsService) {
                return TrendsService.getCurrentQuarter();
              }],
              propertyInfo: ["PropertyCompareService", "$stateParams", function (PropertyCompareService, $stateParams) {
                return PropertyCompareService.getPropertyInfo($stateParams.propertyId);
              }],
              segmentTypes: ["PortfolioService", "$stateParams", function (PortfolioService, $stateParams) {
                return PortfolioService.getSegmentTypesPortfolioProperty($stateParams.portfolioId, $stateParams.propertyId);
              }],
              pushpinImages: ["PushPinImageCache", function (PushPinImageCache) {
                return PushPinImageCache.getPushPinImages();
              }]
            },
            data: {
              secure: true
            }
          })
          .state("areaAccessWarning", {
            url: "/areaAccess/:productCode",
            templateUrl: "/app/views/noAreaAccess.html",
            controller: "AreaAccessController",
            data: {
              secure: true
            }
          })
          .state("propertyDetails", {
            url: "/propertyDetails/:id?exportType",
            controller: "PropertyDetailsController",
            templateUrl: "/app/views/propertyInfoRPT.html",
            resolve: {
              pushpinImages: ["PushPinImageCache", function (PushPinImageCache) {
                return PushPinImageCache.getPushPinImages();
              }]
            },
            data: {
              secure: true
            }
          })
          .state("customCohortDemographics", {
            url: "/customCohort/:id",
            controller: "CustomCohortDemographicsController",
            templateUrl: "/app/views/customCohortDemographics.html",
            resolve: {
              currentQuarter: ["TrendsService", function (TrendsService) {
                return TrendsService.getCurrentQuarter();
              }],
              property: ["PropertyDetailsService", '$stateParams', '$state', function (PropertyDetailsService, $stateParams, $state) {
                return PropertyDetailsService.getDemographicsData($stateParams.id, true).catch(function (errorInfo) {
                  if (errorInfo && errorInfo.status && errorInfo.status == 401) {
                    // user does not have access to product
                    $state.go("noProductAccess", { productCode: 'CustomCohortDemographics' });
                  } else {
                    // user has access to the product but not the property id being requested
                    $state.go('areaAccessWarning', { productCode: 'CustomCohortDemographics' });
                  }
                });
              }],
              demographicYear: ["PropertyDetailsService", function (PropertyDetailsService) {
                return PropertyDetailsService.getDemographicYear();
              }],
              ageBands: ["PropertyDetailsService", function (PropertyDetailsService) {
                return PropertyDetailsService.getAgeBands();
              }],
              householdTypes: ["PropertyDetailsService", function (PropertyDetailsService) {
                return PropertyDetailsService.getHouseholdTypes();
              }]
            },
            data: {
              secure: true
            }
          })
          .state("customSiteCohortDemographics", {
            url: "/customSiteCohort/:id",
            controller: "CustomSiteCohortDemographicsController",
            templateUrl: "/app/views/customSiteCohortDemographics.html",
            resolve: {
              currentQuarter: ["TrendsService", function (TrendsService) {
                return TrendsService.getCurrentQuarter();
              }],
              demographicData: ["SiteInformationReportService", '$stateParams', '$state', function (SiteInformationReportService, $stateParams, $state) {
                return SiteInformationReportService.getDemographics($stateParams.id).catch(function (errorInfo) {
                  if (errorInfo && errorInfo.status && errorInfo.status == 401) {
                    // user does not have access to product
                    $state.go("noProductAccess", { productCode: 'CustomCohortDemographics' });
                  } else {
                    // user has access to the product but not the property id being requested
                    $state.go('areaAccessWarning', { productCode: 'CustomCohortDemographics' });
                  }
                });
              }],
              sirReport: ["SiteInformationReportService", '$stateParams', '$state', function (SiteInformationReportService, $stateParams, $state) {
                return SiteInformationReportService.getReport($stateParams.id, true).catch(function () {
                  $state.go('siteInformationReportWarning');
                })
              }],
              demographicYear: ["PropertyDetailsService", function (PropertyDetailsService) {
                return PropertyDetailsService.getDemographicYear();
              }],
              ageBands: ["PropertyDetailsService", function (PropertyDetailsService) {
                return PropertyDetailsService.getAgeBands();
              }],
              householdTypes: ["PropertyDetailsService", function (PropertyDetailsService) {
                return PropertyDetailsService.getHouseholdTypes();
              }]
            },
            data: {
              secure: true
            }
          })
          .state("coverage", {
            url: "/coverage",
            onEnter: ['$window', '$state', 'SpinnerService', function ($window, $state, SpinnerService) {
              $window.open('https://www.nicmapvision.com/nic-map-data-service/nic-map-metro-coverage/', '_blank');
              SpinnerService.reset();
              $state.go('support');
            }],
            data: {
              secure: true,
              transition: false
            }
          })
          .state("document", {
            url: "/document",
            templateUrl: "/app/views/document.html",
            controller: "DocumentLibraryController",
            resolve: {
              libraryItems: ["WordpressService", function (WordpressService) {
                return WordpressService.getDocumentLibrary();
              }]
            },
            data: {
              secure: true
            }
          })
          .state("glossary", {
            url: "/glossary",
            templateUrl: "/app/views/glossary.html",
            controller: "GlossaryController",
            resolve: {
              glossaryTerms: ["WordpressService", function (WordpressService) {
                return WordpressService.getGlossary();
              }]
            },
            data: {
              secure: true
            }
          })
          .state("videoLibrary", {
            url: "/videoLibrary",
            templateUrl: "/app/views/videoLibrary.html",
            controller: "VideoLibraryController",
            controllerAs: "library",
            resolve: {
              videos: ["WordpressService", function (WordpressService) {
                return WordpressService.getVideoLibrary();
              }],
              recentlyViewed: ["ActivityService", function (ActivityService) {
                return ActivityService.getVideoLibraryActivity();
              }]
            },
            data: {
              secure: true
            }
          })
          .state("videoLibraryDirectLink", {
            url: "/videoLibrary/{directLinkVideo}",
            controller: "VideoLibraryController",
            controllerAs: "library",
            templateUrl: "/app/views/videoLibrary.html",
            resolve: {
              videos: ["WordpressService", function (WordpressService) {
                return WordpressService.getVideoLibrary();
              }],
              recentlyViewed: ["ActivityService", function (ActivityService) {
                return ActivityService.getVideoLibraryActivity();
              }]
            },
            data: {
              secure: true
            }
          })
          .state("faq", {
            url: "/faq",
            templateUrl: "/app/views/faq.html",
            controller: "FaqController",
            resolve: {
              faqItems: ["WordpressService", function (WordpressService) {
                return WordpressService.getFaq();
              }]
            },
            data: {
              secure: true
            }
          })
          .state("dataReleaseWebinar", {
            url: "/datareleasewebinar",
            templateUrl: "/app/views/datareleasewebinar.html",
            controller: "DataReleaseWebinarController",
            controllerAs: "vm",
            resolve: {
              libraryItems: ["WordpressService", function (WordpressService) {
                return WordpressService.getDataReleaseWebinar();
              }],
              currentQuarter: ["TrendsService", function (TrendsService) {
                return TrendsService.getCurrentQuarter();
              }],
            },
            data: {
              secure: true
            }
          })
          .state("pageNotFound", {
            url: "/PageNotFound",
            templateUrl: "/app/views/pageNotFound.html",
            data: {
              secure: true
            }
          })
          .state("propertyAdvisorWarning", {
            url: "/PropertyAdvisorReportWarning",
            templateUrl: "/app/views/propertyAdvisorWarning.html",
            data: {
              secure: true
            }
          })
          .state("propertyAdvisor", {
            url: "/propertyAdvisor/:id?exportType",
            templateUrl: "/app/views/propertyAdvisor.html",
            controller: "PropertyAdvisorController",
            resolve: {
              report: ["PropertyAdvisorService", "$stateParams", "$state", function (PropertyAdvisorService, $stateParams, $state) {
                return PropertyAdvisorService.hasReport($stateParams.id).catch(function (errorInfo) {
                  if (errorInfo && errorInfo.status && errorInfo.status == 403) {
                    // user does not have access to product
                    $state.go('areaAccessWarning', { productCode: 'PropertyAdvisorReport' });
                  } else {
                    $state.go('propertyAdvisorWarning');
                  }
                });
              }],
              pushpinImages: ["PushPinImageCache", function (PushPinImageCache) {
                return PushPinImageCache.getPushPinImages();
              }]
            },
            data: {
              secure: true
            }
          })
          .state("siteInformationReportWarning", {
            url: "/SiteInformationReportWarning",
            templateUrl: "/app/views/siteInformationReportWarning.html",
            data: {
              secure: true
            }
          })
          .state("siteInformationReport", {
            url: "/siteInformationReport/:id",
            templateUrl: "/app/views/siteInformationReport.html",
            controller: "SiteInformationReportController",
            resolve: {
              pushpinImages: ["PushPinImageCache", function (PushPinImageCache) {
                return PushPinImageCache.getPushPinImages();
              }]
            },
            data: {
              secure: true
            }
          })
          .state("insights", {
            url: "/insights",
            templateUrl: "/app/views/insights.html",
            controller: "InsightsController",
            resolve: {
              insights: ["WordpressService", function (WordpressService) {
                return WordpressService.getInsights();
              }]
            },
            data: {
              secure: true
            }
          })
          .state("conferenceRecordings", {
            url: "/conferenceRecordings",
            templateUrl: "/app/views/conferenceRecordings.html",
            controller: "ConferenceRecordingsController",
            resolve: {
              recordings: ["WordpressService", function (WordpressService) {
                return WordpressService.getRecordings();
              }]
            },
            data: {
              secure: true
            }
          })
          .state("wordpressPreview", {
            url: "/wordpress/preview/:postType/:id",
            templateUrl: "/app/views/wordpressPreview.html",
            controller: "WordpressPreviewController",
            resolve: {
              content: ["WordpressService", '$stateParams', function (WordpressService, $stateParams) {
                return WordpressService.previewContent($stateParams.postType, $stateParams.id);
              }]
            }
          })
          .state("wordpressContent", {
            url: "/content/:postType/:id",
            templateUrl: "/app/views/wordpressPreview.html",
            controller: "WordpressPreviewController",
            resolve: {
              content: ["WordpressService", '$stateParams', function (WordpressService, $stateParams) {
                return WordpressService.previewContent($stateParams.postType, $stateParams.id);
              }]
            }
          })
          .state("compBuilder", {
            url: "/compbuilder",
            templateUrl: "/app/views/compbuilder/compbuilder.html",
            controller: "CompBuilderController",
            data: {
              productCode: "CompBuilder",
              transition: false,
              secure: true
            }
          })
          .state('compBuilder.aggregateSummary', {
            url: '/summary',
            params: { aggregateCreationSummary: null },
            views: {
              '@': {
                templateUrl: '/app/views/compbuilder/compbuilder.addaggregatesummary.html',
                controller: "AddAggregateSummaryController"
              }
            }
          })
          .state('compBuilder.compBuilderAdd', {
            url: '^/compbuilder/add',
            abstract: true,
            views: {
              '@': {
                templateUrl: '/app/views/compbuilder/compbuilderadd.html',
                controller: "CompBuilderAddController"
              }
            },
            resolve: {
              step: function () {
                return 1;
              }
            }
          })
          .state('compBuilder.compBuilderAdd.aggregateName', {
            url: '/1',
            views: {
              'currentStep': {
                templateUrl: '/app/views/compbuilder/step.addaggregate.name.html',
                controller: "StepAddAggregateNameController"
              }
            },
            params: {
              step: {
                value: 1,
                squash: false
              }
            }
          })
          .state('compBuilder.compBuilderAdd.aggregateLocation', {
            url: '/2',
            views: {
              'currentStep': {
                templateUrl: '/app/views/compbuilder/step.addaggregate.location.html',
                controller: "StepAddAggregateLocation"
              }
            },
            params: {
              step: {
                value: 2,
                squash: false
              }
            }
          })
          .state('compBuilder.compBuilderAdd.aggregateCriteria', {
            url: '/3',
            views: {
              'currentStep': {
                templateUrl: '/app/views/compbuilder/step.addaggregate.searchcriteria.html',
                controller: "StepAddAggregateSearchCriteria",
                resolve: {
                  propertyTypes: ["SearchService", function (SearchService) {
                    return SearchService.propertyTypes();
                  }],
                  segmentTypes: ["SearchService", function (SearchService) {
                    return SearchService.getSegmentTypes();
                  }],
                  campusTypes: ["TrendsService", function (TrendsService) {
                    return TrendsService.getFilterData('campusTypes');
                  }]
                }
              }
            },
            params: {
              step: {
                value: 3,
                squash: false
              }
            }
          })
          .state('compBuilder.compBuilderAdd.aggregateDistance', {
            url: '/4',
            views: {
              'currentStep': {
                templateUrl: '/app/views/compbuilder/step.addaggregate.distance.html',
                controller: "StepAddAggregateDistance",
                resolve: {
                  // The following are all to populate the readonly options for the search criteria display
                  radiusOptions: ["SearchService", function (SearchService) {
                    return SearchService.getRadius();
                  }],
                  driveTimeOptions: ["SearchService", function (SearchService) {
                    return SearchService.getDriveTimeOptions();
                  }]
                },
              }
            },
            params: {
              step: {
                value: 4,
                squash: false
              }
            }
          })
          .state('compBuilder.compBuilderAdd.shareAggregate', {
            url: '/5',
            views: {
              'currentStep': {
                templateUrl: '/app/views/compbuilder/step.addaggregate.share.html',
                controller: "StepAddAggregateShare",
                controllerAs: 'vm'
              }
            },
            params: {
              step: {
                value: 5,
                squash: false
              }
            }
          })
          .state('compBuilderPeerGroupDetails', {
            url: '/compbuilder/details/:peerGroupGuid',
            templateUrl: '/app/views/compbuilder/compbuilderpeergroupdetails.html',
            controller: 'CompBuilderPeerGroupDetailsController',
            controllerAs: 'vm',
            resolve: {
              peerGroupDetails: ['$stateParams', '$state', 'CompBuilderService', function ($stateParams, $state, CompBuilderService) {
                return CompBuilderService.getPeerGroup($stateParams.peerGroupGuid)
                  .catch(function () {
                    $state.go('pageNotFound');
                  })
              }],
              hasLocalPropertySearchAccess: ['AuthService', function (AuthService) {
                return AuthService.getProductAccess('Local');
              }],
              // The following are all to populate the readonly options for the search criteria display
              radiusOptions: ["SearchService", function (SearchService) {
                return SearchService.getRadius();
              }],
              propertyTypes: ["SearchService", function (SearchService) {
                return SearchService.propertyTypes();
              }],
              segmentTypes: ["SearchService", function (SearchService) {
                return SearchService.getSegmentTypes();
              }],
              campusTypes: ["TrendsService", function (TrendsService) {
                return TrendsService.getFilterData('campusTypes');
              }],
              driveTimeOptions: ["SearchService", function (SearchService) {
                return SearchService.getDriveTimeOptions();
              }],
              pushpinImages: ["PushPinImageCache", function (PushPinImageCache) {
                return PushPinImageCache.getPushPinImages();
              }]
            },
            data: {
              productCode: "CompBuilder",
              transition: false,
              secure: true
            }
          });
      }
    ])
    .run([
      "$rootScope", "$state", "$location", "$window", "AuthService", "SpinnerService", "TransitionService", "VersioningService",
      function ($rootScope, $state, $location, $window, authSvc, SpinnerService, TransitionService, VersioningService) {
        // It's very handy to add references to $state and $stateParams to the $rootScope
        // so that you can access them from any scope within your applications.For example,
        // <li ng-class="{ active: $state.includes('contacts.list') }"> will set the <li>
        // to active whenever 'contacts.list' or one of its decendents is active.
        $rootScope.$state = $state;
        if ($rootScope.loading === undefined) { $rootScope.loading = 0; }

        //some empty functions for debugging routing issues
        $rootScope.$on("$stateNotFound", function (event, unfoundState, fromState, fromParams) {
          //console.log(unfoundState.to); // "lazy.state"
          //console.log(unfoundState.toParams); // {a:1, b:2}
          //console.log(unfoundState.options); // {inherit:false} + default options
        });
        $rootScope.$on("$stateChangeError", function (event, toState, toParams, fromState, fromParams, error) {
          //console.log("stateChangeError"); // "lazy.state"
          // console.log("stateChangeError occured. Look below for the error object.");
          // console.log(error);
          SpinnerService.reset();
          TransitionService.deactivate();
        });
        $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
          // console.log('$stateChangeStart-' + fromState.name + '->' + toState.name + ' ' + new Date().toLocaleTimeString());
          // send off an http request to client to see what the version is...
          // the response gets checked in the VersionMiddleware.
          VersioningService.pingForVersion();

          //Spinner everywhere except initial load to login
          if (fromState.name != "" && toState.name != "login.home") {
            SpinnerService.start();
          }
          TransitionService.activate(toState, fromState);
        });
        $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
          //console.log('$stateChangeSuccess-' + fromState.name + '->' + toState.name + ' ' + new Date().toLocaleTimeString());
          if (authSvc.identity.isAuth() && !authSvc.identity.isImpersonated()) {
            window.Intercom("update", {
              email: authSvc.identity.userName(), 
              user_hash: authSvc.identity.userHash()
            });
          }
          SpinnerService.reset();
          TransitionService.deactivate();
          //scroll to top of page on page load
          //http://stackoverflow.com/questions/26444418/autoscroll-to-top-with-ui-router-and-angularjs
          document.body.scrollTop = document.documentElement.scrollTop = 0;
          if (!$window.ga) {
            return;
          }
          $window.ga('send', 'pageview', {
            page: $location.path()
          });
        });
      }
    ]);
})();
