app.directive('recentActivity', ['AuthService', function(authService) {
  return {
    controller: ['$scope', '$state', 'FileSaver', 'recentActivitiesConstant', 'recentActivitiesLinkConstant', 'AuditService', 'knownEventConstant', 'SpinnerService',
      function ($scope, $state, FileSaver, recentActivitiesConstant, recentActivitiesLinkConstant, AuditService, knownEventConstant , SpinnerService) {
      SpinnerService.start();

      var trendsActivities = {
        propertyTypeExcel: function (activity) {
          AuditService.logEvent(recentActivitiesLinkConstant.propertyTypeExcel.id, recentActivitiesLinkConstant.propertyTypeExcel.message);
          $state.go("trends.propertyType", { query: activity.queryGuid, exportType: activity.fileFormat });
        },
        propertyTypePdf: function (activity) {
          AuditService.logEvent(recentActivitiesLinkConstant.propertyTypePdf.id, recentActivitiesLinkConstant.propertyTypePdf.message);
          $state.go("trends.propertyType", { query: activity.queryGuid, exportType: activity.fileFormat });
        },
        propertyTypeDisplayData: function (activity) {
          AuditService.logEvent(recentActivitiesLinkConstant.propertyTypeDisplayData.id, recentActivitiesLinkConstant.propertyTypeDisplayData.message);
          $state.go("trends.propertyType", { query: activity.queryGuid });
        },
        propertyTypeDisplayDataSavedFilter: function (activity) {
          AuditService.logEvent(recentActivitiesLinkConstant.propertyTypeGetFilter.id, recentActivitiesLinkConstant.propertyTypeGetFilter.message);
          $state.go("trends.propertyType", { query: activity.queryGuid, filterName: activity.filterName });
        },
        communityTypeExcel: function (activity) {
          AuditService.logEvent(recentActivitiesLinkConstant.communityTypeExcel.id, recentActivitiesLinkConstant.communityTypeExcel.message);
          $state.go("trends.communityType", { query: activity.queryGuid, exportType: activity.fileFormat });
        },
        communityTypePdf: function (activity) {
          AuditService.logEvent(recentActivitiesLinkConstant.communityTypePdf.id, recentActivitiesLinkConstant.communityTypePdf.message);
          $state.go("trends.communityType", { query: activity.queryGuid, exportType: activity.fileFormat });
        },
        communityTypeDisplayDataSavedFilter: function (activity) {
          AuditService.logEvent(recentActivitiesLinkConstant.communityTypeDisplayDataSavedFilter.id, recentActivitiesLinkConstant.communityTypeDisplayDataSavedFilter.message);
          $state.go("trends.communityType", { query: activity.queryGuid, filterName: activity.filterName });
        },
        communityTypeDisplayData: function (activity) {
          AuditService.logEvent(recentActivitiesLinkConstant.communityTypeDisplayData.id, recentActivitiesLinkConstant.communityTypeDisplayData.message);
          $state.go("trends.communityType", { query: activity.queryGuid });
        }
      };

      var parActivities = {
        run: function (activity) {
          AuditService.logEvent(recentActivitiesLinkConstant.runParReport.id, recentActivitiesLinkConstant.runParReport.message);
          $state.go("propertyAdvisor", { id: activity.queryGuid });
        },
        view: function (activity) {
          AuditService.logEvent(recentActivitiesLinkConstant.viewParReport.id, recentActivitiesLinkConstant.viewParReport.message);
          $state.go("propertyAdvisor", { id: activity.queryGuid });
        },
        compExcel: function (activity) {
          AuditService.logEvent(recentActivitiesLinkConstant.exportComps.id, recentActivitiesLinkConstant.exportComps.message);
          $state.go("propertyAdvisor", { id: activity.queryGuid, exportType: activity.action});
        },
        chartDataExcel: function (activity) {  
          AuditService.logEvent(recentActivitiesLinkConstant.exportChartData.id, recentActivitiesLinkConstant.exportChartData.message);
          $state.go("propertyAdvisor", { id: activity.queryGuid, exportType: activity.action});
        },
        transactionsExcel: function (activity) {
          AuditService.logEvent(recentActivitiesLinkConstant.exportSalesTrans.id, recentActivitiesLinkConstant.exportSalesTrans.message);
          $state.go("propertyAdvisor", { id: activity.queryGuid, exportType: activity.action});
        },
        pdf: function (activity) {
          AuditService.logEvent(recentActivitiesLinkConstant.exportParPdf.id, recentActivitiesLinkConstant.exportParPdf.message);
          $state.go("propertyAdvisor", { id: activity.queryGuid, exportType: activity.action});
        }
      }

      var pirActivities = {
        run: function (activity) {
          AuditService.logEvent(recentActivitiesLinkConstant.propertyInformationReport.id, recentActivitiesLinkConstant.propertyInformationReport.message);
          var idToUse = activity.queryGuid ? activity.queryGuid : activity.propertyId;
          $state.go("propertyDetails", { id: idToUse });
        },
        excel: function (activity) {
          AuditService.logEvent(recentActivitiesLinkConstant.propertyInfoReportExportExcel.id, recentActivitiesLinkConstant.propertyInfoReportExportExcel.message + ": " + " Excel",
            null, activity.propertyId);
          var idToUse = activity.queryGuid ? activity.queryGuid : activity.propertyId;
          $state.go("propertyDetails", { id: idToUse, exportType: activity.fileFormat });
        },
        pdf: function (activity) {
          AuditService.logEvent(recentActivitiesLinkConstant.propertyInfoReportExportPdf.id, recentActivitiesLinkConstant.propertyInfoReportExportPdf.message,
            null, activity.propertyId);
          var idToUse = activity.queryGuid ? activity.queryGuid : activity.propertyId;
          $state.go("propertyDetails", { id: idToUse, exportType: activity.fileFormat });
        }
      }

      var pcrActivities = {
        excel: function (activity) {
          if (activity.portfolioId && activity.propertyId) {
            AuditService.logEvent(recentActivitiesLinkConstant.pcrExportExcel.id, recentActivitiesLinkConstant.pcrExportExcel.message, 
              null, activity.propertyId);
            $state.go("propertyCompare", { portfolioId: activity.portfolioId, propertyId: activity.propertyId, exportType: activity.fileFormat });
          }
        },
        pdf: function (activity) {
          if (activity.portfolioId && activity.propertyId) {
            AuditService.logEvent(recentActivitiesLinkConstant.pcrExportPdf.id, recentActivitiesLinkConstant.pcrExportPdf.message, 
              null, activity.propertyId);
            $state.go("propertyCompare", { portfolioId: activity.portfolioId, propertyId: activity.propertyId, exportType: activity.fileFormat });
          }
        }
      }

      var localActivities = {
        search: function (activity) {
          AuditService.logEvent(recentActivitiesLinkConstant.search.id, recentActivitiesLinkConstant.search.message);
          $state.go("localpropertysearch.base", { id: activity.queryGuid });
        },
        savedSearch: function (activity) {
          AuditService.logEvent(recentActivitiesLinkConstant.viewSavedSearch.id, recentActivitiesLinkConstant.viewSavedSearch.message);
          $state.go("localpropertysearch.base", { id: activity.queryGuid });
        }
      }

      function formatActivity(activity) {
        return " " + activity.action.replace(" - Infobox -", ":");
      };

      function formatFilterName(activity) {
        if (activity.formattedActivity) {
         var message = activity.formattedActivity.replace(" Trends, Property Type Saved Filter: ", "");
         return message.replace(" Trends, Community Type Saved Filter: ", ""); 
        }
        return null;
      };

      function productNameMapping(value) {
        switch(value.productCode) {
          case "Performance": return "Local"; break;
          case "Local": return "Local"; break;
          case "Trans": return "Local"; break;
          case "MAPPortfolios": return "Portfolios"; break;
          case "Trends": return "Trends"; break;

        }
        return value.productName;
      }

      function doActivity() {
        var activity = this;
        switch (activity.userEventTypeId) {
          case recentActivitiesConstant.propertyTypeDisplayData: trendsActivities.propertyTypeDisplayData(activity); break;
          case recentActivitiesConstant.propertyTypeDisplayDataSavedFilter: trendsActivities.propertyTypeDisplayDataSavedFilter(activity); break;
          case recentActivitiesConstant.propertyTypeExcel: trendsActivities.propertyTypeExcel(activity); break;
          case recentActivitiesConstant.propertyTypePdf: trendsActivities.propertyTypePdf(activity); break;
          case recentActivitiesConstant.communityTypeDisplayData: trendsActivities.communityTypeDisplayData(activity); break;
          case recentActivitiesConstant.communityTypeDisplayDataSavedFilter: trendsActivities.communityTypeDisplayDataSavedFilter(activity); break;
          case recentActivitiesConstant.communityTypeExcel: trendsActivities.communityTypeExcel(activity); break;
          case recentActivitiesConstant.communityTypePdf: trendsActivities.communityTypePdf(activity); break;
          case recentActivitiesConstant.runParReport: parActivities.run(activity); break;
          case recentActivitiesConstant.viewParReport: parActivities.view(activity); break;
          case recentActivitiesConstant.exportComps: parActivities.compExcel(activity); break;
          case recentActivitiesConstant.exportChartData: parActivities.chartDataExcel(activity); break;
          case recentActivitiesConstant.exportSalesTrans: parActivities.transactionsExcel(activity); break;
          case recentActivitiesConstant.exportParPdf: parActivities.pdf(activity); break;
          case recentActivitiesConstant.propertyInformationReport: pirActivities.run(activity); break;
          case recentActivitiesConstant.propertyInfoReportExportExcel: pirActivities.excel(activity); break;
          case recentActivitiesConstant.propertyInfoReportExportPdf: pirActivities.pdf(activity); break;
          case recentActivitiesConstant.search: localActivities.search(activity); break;
          case recentActivitiesConstant.runMostRecentSearch: localActivities.search(activity); break;
          case recentActivitiesConstant.viewSavedSearch: localActivities.savedSearch(activity); break;
          case recentActivitiesConstant.propertyCompareReportExportExcel: pcrActivities.excel(activity); break;
          case recentActivitiesConstant.propertyCompareReportExportPdf: pcrActivities.pdf(activity); break;
        }
      };

      authService.getRecentActivity()
        .then(function(data) {
          $scope.recentActivity = [];
          angular.forEach(data, function (value, key) {
            value.tooltipOnBottom = key == 0 || key == 1;
            value.showToolTip = false;
            value.productName = productNameMapping(value);
            value.formattedActivity = formatActivity(value);
            value.performActivity = doActivity;
            value.filterName = formatFilterName(value);
            this.push(value);
          }, $scope.recentActivity);
          SpinnerService.stop();
          $scope.recentActivity = data;
        }, function(err) {});
    }],
    templateUrl: '/app/partials/recentActivity.html'
  };
}]);
