(function() {
  "use strict";
  app.controller("ConferenceRecordingsController", ConferenceRecordingsController);
  ConferenceRecordingsController.$inject = ['$scope', '$rootScope', '$sce', 'recordings', 'AuditService', 'knownEventConstant'];
  function ConferenceRecordingsController($scope, $rootScope, $sce, recordings, AuditService, knownEventConstant) {
    $rootScope.hideLeftNav = false; //show the left nav when going to the home page
  	
  	$scope.recordings = recordings[0];
    $scope.recordings.content = $sce.trustAsHtml($scope.recordings.content);
    $scope.logUserEvent = function () {
      AuditService.logEvent(knownEventConstant.navConfRecording.id, knownEventConstant.navConfRecording.message);
    };
    $scope.logUserEvent();
  }
})();