(function () {
  "use strict";

  app.controller('SubscriptionNotesPopupController', SubscriptionNotesPopupController);

  SubscriptionNotesPopupController.$inject = ['$scope', 'companyId', 'subscriptionId', 'SubscriptionNotesService', 'noteText', 'noteId', 'SpinnerService'];

  function SubscriptionNotesPopupController($scope, companyId, subscriptionId, SubscriptionNotesService, noteText, noteId, SpinnerService) {
    $scope.noteText = noteText;
    $scope.noteId = noteId;

    $scope.saveNote = function() {
      if ($scope.noteText.trim() !== "") {
        SpinnerService.start();
        SubscriptionNotesService
          .saveNote(companyId, subscriptionId, $scope.noteText, 0)
          .then(function success() {
            SpinnerService.stop();
            $scope.$close();
          }, function error() {
            //save failed
          });
      }
    };

    $scope.editNote = function () {
      if ($scope.noteText.trim() !== "") {
        SpinnerService.start();
        SubscriptionNotesService
          .saveNote(companyId, subscriptionId, $scope.noteText, $scope.noteId)
          .then(function success() {
            SpinnerService.stop();
            $scope.$close();
          }, function error() {
            //save failed
          });
      }
    };
  }
}());