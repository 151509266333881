(function () {
  "use strict";
  app.factory("AdminService", AdminService);
  
  AdminService.$inject = ['$http', '$q', '$rootScope', 'apiSettings'];

  function AdminService($http, $q, $rootScope, apiSettings) {
    var companyInfo = null;
    var subscriberInfo = null;

    function buildSubscriptionFilter(filters) {
      var filter = {
        companyName: null,
        startDate: null,
        endDate: null,
        type: null,
        price: null,
        accountStatus: null,
        accountType: null,
        cbsaCount: null
      }
      angular.forEach(filters, function(filterValue) {
        switch (filterValue.field) {
          case "companyName":
            filter.companyName = filterValue.value;
            break;
          case "startDate":
            filter.startDate = filterValue.value;
            break;
          case "endDate":
            filter.endDate = filterValue.value;
            break;
          case "subscriptionType":
            filter.type = filterValue.value;
            break;
          case "price":
            filter.price = filterValue.value;
            break;
          case "accountStatus":
            filter.accountStatus = filterValue.value;
            break;
          case "accountType":
            filter.accountType = filterValue.value;
            break;
          case "cbsaCount":
            filter.cbsaCount = filterValue.value;
            break;
          case "hasPending":
            filter.hasPending = filterValue.value;
            break;
          case "clientApiSubscriptionType":
            filter.clientApiSubscriptionType = filterValue.value;
            break;
        }
      });
      return filter;
    }
  
    function buildSubscriptionSort(sort) {
      return JSON.stringify({
        field: sort[0].field,
        dir: sort[0].dir
      });
    }
  
    function generateSubscriptionsList(activeonlyFlag, includeTerminated, title, filters, sort, date) {
      var deferred = $q.defer();
      var filter = filters.length > 0 ? buildSubscriptionFilter(filters) : {};
      var sortData = sort.length > 0 ? buildSubscriptionSort(sort) : {};
      var uri = apiSettings.url + '/subscription/exports?' +
        $.param({
          ActiveOnly: activeonlyFlag,
          IncludeTerminated: includeTerminated,  
          date: date,
          title: title,
          sortData: sortData
        });
  
      $http
        .post(uri, filter, {
          responseType: 'arraybuffer'
        })
        .then(function(rsp) {
          if (rsp.data.byteLength == 0) {
            deferred.reject();
          } else {
            deferred.resolve(rsp.data);
          }
        }, function(err) {
          deferred.reject(err);
        });
  
      return deferred.promise;
    }
  
    function setCompanyInfo(value) {
      companyInfo = value;
    }
  
    function getCompanyInfo() {
      return companyInfo;
    }
  
    function setSubscriberInfo(value) {
      subscriberInfo = value;
    }
  
    function getSubscriberInfo() {
      return subscriberInfo;
    }
  
    function syncThroughSignalR(type, syncStartedOn) {
      var deferred = $q.defer();
      var uri = apiSettings.url + '/salesforce/refresh' + type + 'Sync?syncStartedOn=' + syncStartedOn;

      $http
        .post(uri)
        .then(function(rsp) {
          if (rsp.data.byteLength == 0) {
            deferred.reject();
          } else {
            deferred.resolve(rsp.data);
          }
        }, function(err) {
          deferred.reject(err);
        });
      
      return deferred.promise;
    }
  
    function getNewCompanies(date) {
      // only return the companies that have never had any active, expired, or pending subscriptions
      var deferred = $q.defer();
      var uri = apiSettings.url + '/company/getNewCompanies?date=' + date;
      
      $http
        .get(uri)
        .then(function (rsp) {
          if (rsp.data && rsp.data.length) {
            deferred.resolve(rsp.data);
          } else {
            deferred.reject();
          }
        }, function(err) {
          deferred.reject(err);
        });
      
      return deferred.promise;
    }

    function getUserEventInfo(eventId) {
      var deferred = $q.defer();
      var uri = apiSettings.url + '/audit/GetMostRecentEventByUserEventTypeId?userEventTypeId=' + eventId;
      
      $http
        .get(uri)
        .then(function (rsp) {
          if (rsp.data) {
            deferred.resolve(rsp.data);
          } else {
            deferred.reject(rsp);
          }
        }, function(err) {
          deferred.reject(err);
        });
      
      return deferred.promise;
    }
    
    function getRecentUserNamesFromUserEvent() {
      var deferred = $q.defer();
      var uri = apiSettings.url + '/audit/GetRecentUserNamesFromUserEvent';

      $http
        .get(uri)
        .then(function (rsp) {
          if (rsp.data) {
            deferred.resolve(rsp.data);
          } else {
            deferred.reject(rsp);
          }
        }, function (err) {
          deferred.reject(err);
        });

      return deferred.promise;
    }
  
    function getSubscriptions(showExpired, showTerminated) {
      var defer = $q.defer();

      var appendUrl = "/subscriptions/getallsubscriptions";
      var showExpiredValue = showExpired ? 0 : 1;
      appendUrl += "?ActiveOnly=" + showExpiredValue +"&IncludeTerminated=" + showTerminated;
      
      $http
        .get(apiSettings.url + appendUrl)
        .then(function (rsp) {
          defer.resolve(rsp.data);
        }, function (err) {
          defer.reject(err);
        });

      return defer.promise;
    }

    function clearGeneralAlert() {
      var defer = $q.defer();
     
      var uri = apiSettings.url + "/cache/clearGeneralAlert";
      $http.post(uri)
        .then(function (rsp) {
          defer.resolve(rsp.data);
        }, function (err) {
          defer.reject(err);
        });

      return defer.promise;
    }

    function saveGeneralAlert(dataToSave) {
      var defer = $q.defer();
      var note = {
        text: dataToSave
      };
      var uri = apiSettings.url + "/cache/saveGeneralAlert";
      $http.post(uri, note)
        .then(function (rsp) {
          defer.resolve(rsp.data);
        }, function (err) {
          defer.reject(err);
        });

      return defer.promise;
    }

    function getGeneralAlert() {
      var defer = $q.defer();
      var appendUrl = "/cache/getGeneralAlert";
      $http.get(apiSettings.url + appendUrl)
        .then(function (rsp) {
          defer.resolve(rsp.data);
        }, function (err) {
          defer.reject(err);
        });

      return defer.promise;
    }

    function isSyncInProgress(type) {
      var deferred = $q.defer();
      var uri = apiSettings.url + '/salesforce/is' + type + 'SyncInProgress';

      $http
        .get(uri)
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });
      
      return deferred.promise;
    }
  
    function getMetroBankRecords() {
      var defer = $q.defer();

      var appendUrl = "/subscriptions/metroBankRecords";
      
      $http
        .get(apiSettings.url + appendUrl)
        .then(function (rsp) {
          defer.resolve(rsp.data);
        }, function (err) {
          defer.reject(err);
        });

      return defer.promise;
    }

    function metroBankRecordsToExcel(data) {
      var defer = $q.defer();
      var uri = apiSettings.url + "/subscription/exports/metrobankrecords";
      $http.post(uri, data, {
        responseType: 'arraybuffer'
      })
      .then(function success(rsp) {
        if(rsp.data.length === 0) {
          defer.reject();
        } else {
          defer.resolve(rsp.data);
        }
      }, function error(err) {
        defer.reject(err);
      });
      return defer.promise;
    }

    function companyMetroBankRecordsToExcel(data) {
      var defer = $q.defer();
      var uri = apiSettings.url + "/subscription/exports/" + data.id + "/metrobankrecords";
      $http.post(uri, data, {
        responseType: 'arraybuffer'
      })
      .then(function success(rsp) {
        if(rsp.data.length === 0) {
          defer.reject();
        } else {
          defer.resolve(rsp.data);
        }
      }, function error(err) {
        defer.reject(err);
      });
      return defer.promise;
    }


    return {
      generateSubscriptionsList: generateSubscriptionsList,
      setCompanyInfo: setCompanyInfo,
      getCompanyInfo: getCompanyInfo,
      setSubscriberInfo: setSubscriberInfo,
      getSubscriberInfo: getSubscriberInfo,
      syncThroughSignalR: syncThroughSignalR,
      getNewCompanies: getNewCompanies,
      getUserEventInfo: getUserEventInfo,
      getSubscriptions: getSubscriptions,
      saveGeneralAlert: saveGeneralAlert,
      getGeneralAlert: getGeneralAlert,
      clearGeneralAlert: clearGeneralAlert,
      getRecentUserNamesFromUserEvent: getRecentUserNamesFromUserEvent,
      isSyncInProgress: isSyncInProgress,
      getMetroBankRecords: getMetroBankRecords,
      metroBankRecordsToExcel: metroBankRecordsToExcel,
      companyMetroBankRecordsToExcel: companyMetroBankRecordsToExcel
    };
  }
})();
