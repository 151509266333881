(function() {
  "use strict";

  app.filter('truncate', truncate);

  function truncate() {
    return function(text, length, end) {
      if(isNaN(length))
        length = 10;

      if(end === undefined)
        end = "...";

      if(text.length <= length || text.length - end.length <= length) {
        return text;
      } else {
        return String(text).substring(0, length) + end;
      }
    };
  }

})();