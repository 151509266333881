(function() {
  "use strict";
  app.directive('onScrollToBottom', onScrollToBottom);
  function onScrollToBottom() {
    //This function will fire an event when the container/document is scrolled to the bottom of the page
    //Gives some vertical buffer to the scrollbar event firing so its not directly at the bottom
    //Optional attribute of on-scroll-to-bottom-buffer will allow you to set the BUFFER_AMT
    var BUFFER_AMT = 75;
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        var elem = element[0];
        $(elem).bind("scroll", function () {
          if(elem.scrollTop + elem.offsetHeight + (Number(attrs.onScrollToBottomBuffer) || BUFFER_AMT) >= elem.scrollHeight){
            scope.$apply(attrs.onScrollToBottom);
          } 
        });
      }
    };
  }
})();