(function() {
  "use strict";

  app.factory("GeocodeService", GeocodeService);

  GeocodeService.$inject = ['$q', '$http', 'apiSettings'];

  function GeocodeService($q, $http, apiSettings) {
    return {
      geocode : geocode
    };

    function geocode(addressValue) {
      return $http.get(apiSettings.url + '/geocode/address/' + encodeURIComponent(addressValue))
        .then(function (rsp) {
          return rsp.data;
        })
        .catch(function (err) {
          console.error(err);
          return $q.reject(err);
        });
    }

  }
})();