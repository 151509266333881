(function () {
  "use strict";

  app.controller('UsageController', UsageController);

  UsageController.$inject = ['$scope', '$state'];

  function UsageController($scope, $state) {

    $scope.tabs = [{
      heading: "Client API",
      route: "admin.usage.clientApi",
      active: false
    },
    {
      heading: "Metro Bank",
      route: "admin.usage.metroBank",
      active: false
    }];

    $scope.tabs.forEach(function (tab) {
      tab.active = $state.is(tab.route);
    });
  }
})();