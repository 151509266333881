(function() {
  "use strict";

  app.directive('kendoGridSaveChanges', kendoGridSaveChanges);
  kendoGridSaveChanges.$inject = ['$window', '$state'];

  function kendoGridSaveChanges($window, $state) {
    function doesDataSourceHaveChanges(ds) {
      var dirty = false;

      $.each(ds._data, function() {
        if(this.dirty == true) {
          dirty = true;
        }
      });

      if(ds._destroyed.length > 0) dirty = true;

      return dirty;
    };

    function link(scope, element, attrs) {

      $window.onbeforeunload = function() {
        if(doesDataSourceHaveChanges(scope.grid.dataSource)) {
          return "There are unsaved changes on this page. Do you want to continue?";
        }
      }

      scope.$on('$stateChangeStart', function(event, toState, toStateParams, fromState) {
        if(scope.isDirty && doesDataSourceHaveChanges(scope.grid.dataSource)) {
          event.preventDefault();
          $.confirm({
            title: '',
            content: "There are unsaved changes on this page. Do you want to continue?",
            confirmButton: 'Yes',
            cancelButton: 'No',
            confirmButtonClass: 'btn btn-primary',
            cancelButtonClass: 'btn btn-warning',
            confirm: function() {
              scope.isDirty = false;
              $state.go(toState.name);
            }
          });
        }
      });
    };

    return {
      link: link
    };
  }
})();
