(function() {
  "use strict";

  app.factory("ClientApiService", ClientApiService);

  ClientApiService.$inject = ["$http", "$q", "apiSettings"];
  
  function ClientApiService($http, $q, apiSettings) {
  	return {
      getClientApiKeys: getClientApiKeys,
      saveClientApiKey: saveClientApiKey,
      generateApiKey: generateApiKey,
      sendApiKeyEmail: sendApiKeyEmail
  	}
  
    function getClientApiKeys(companyId) {
  	  var deferred = $q.defer();
      var uri = apiSettings.url +"/companykeys/{companyId}"
      	.replace("{companyId}", companyId);

  	  $http.get(uri)
        .then(function (rsp) {
          deferred.resolve(rsp.data);
        }, function (err) {
          deferred.reject(err);
        });
    
      return deferred.promise;
    };

    function saveClientApiKey(id, companyId, newApiKey, callsPerDay ) {
      var defer = $q.defer();
      var apiKeyData = {
        companyId: companyId,
        apiKey: newApiKey,
        callsPerDay: callsPerDay
      };
      var uri = apiSettings.url + "/companykeys/{id}"
        .replace("{id}", id);

      $http.post(uri, apiKeyData)
        .then(function (rsp) {
          defer.resolve(rsp.data);
        }, function (err) {
          defer.reject(err);
        });

      return defer.promise;
    }

    function generateApiKey() {
      var deferred = $q.defer();
      var uri = apiSettings.url + "/companykeys/generateapikey"

      $http.get(uri)
        .then(function (rsp) {
          deferred.resolve(rsp.data);
        }, function (err) {
          deferred.reject(err);
        });

      return deferred.promise;
    };

    function sendApiKeyEmail(id, emailAddress) {
      var defer = $q.defer();
      var uri = apiSettings.url + "/companykeys/sendapikeyemail/{id}/{emailAddress}"
        .replace("{id}", id)
        .replace("{emailAddress}", emailAddress);

      $http.post(uri)
        .then(function (rsp) {
          defer.resolve(rsp.data);
        }, function (err) {
          defer.reject(err);
        });

      return defer.promise;
    }

  }
})();