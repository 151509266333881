(function () {
  "use strict";

  app.factory("PushPinImageCache", pushpinImageCache);

  pushpinImageCache.$inject = ['$q'];

  function pushpinImageCache($q) {

    var imageMap;
    var deferred = $q.defer();
    var promise = deferred.promise;

    return {
      getPushPinImages: getPushPinImages,
      getImage: getImage
    };

    function getImage(name) {
      if (imageMap && name) {
        return imageMap[name];
      }
    }

    function getPushPinImages() {

      if (imageMap) {
        return (promise);
      }


      var imageFiles = ['black-star.png', 'blue-empty.png', 'blue-empty-square.png', 'blue-solid.png', 'blue-solid-square.png',
        'darkGrey-empty.png', 'darkGrey-empty-square.png', 'darkGrey-solid.png', 'darkGrey-solid-square.png',
        'green-empty.png', 'green-empty-square.png', 'green-solid.png', 'green-solid-square.png',
        'red-empty.png', 'red-empty-square.png', 'red-solid.png', 'red-solid-square.png',
        'teal-empty.png', 'teal-empty-square.png', 'teal-solid.png', 'teal-solid-square.png', 'grey-striped-diamond.png',
      ];

      var hospitalFiles = ['Local-hospital-pin-24.png', 'Local-hospital-pins-06.png'];


      var files = [];
      for (var i = 0; i < imageFiles.length; i++) {
        files.push("assets/map/" + imageFiles[i]); 
      }
      for (var i = 0; i < hospitalFiles.length; i++) {
        files.push("assets/map/hospitals/" + hospitalFiles[i]);
      }

      var p = preLoadImages(files);
      return p;
    }


    function preLoadImages(imageFiles) {
      imageMap = {};
      var filesCount = imageFiles.length;
      for (var i = 0; i < imageFiles.length; i++) {
        var imageObj = new Image();
        imageObj.crossOrigin = "anonymous";  // This enables CORS
        imageObj.src = imageFiles[i];
        imageObj.name = imageFiles[i];
        imageObj.onload = (function (img) {
          imageMap[img.target.name] = img.target;

          filesCount--;

          if (filesCount === 0) {
            deferred.resolve(imageMap);
          }
        });

        imageObj.onerror = function () { //even image is not loaded

          filesCount--;
          if (filesCount === 0) {
            deferred.resolve(imageMap);
          }
        };
      }

      return promise;
    }

  }

}());
