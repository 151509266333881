(function () {
  "use strict";

  app.controller('SupportController', SupportController);
  SupportController.$inject = ['$scope', '$rootScope'];
  function SupportController($scope, $rootScope) {
    $rootScope.hideLeftNav = true;

    $scope.linkList = [
      { name: 'contact', text: 'Contact NIC MAP Vision' },
      { name: 'dataReleaseWebinar', text: 'Data Release Webinar Library' },
      { name: 'document', text: 'Document Library' },
      { name: 'faq', text: 'Frequently Asked Questions' },
      { name: 'glossary', text: 'Glossary' },
      { name: 'coverage', text: 'Metro Coverage Map' },
      { name: 'videoLibrary', text: 'Video Library' }
    ];
  }
}());