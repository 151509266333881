(function () {
  "use strict";

  app.controller('SubscriptionUserPopupController', SubscriptionUserPopupController);

  SubscriptionUserPopupController.$inject = ['$scope', '$uibModal', 'SubscriptionUsersService', 'SubscriptionDetailsService',
    'subscriptionId', 'companyId', 'subscriptionUsers', 'gridPageSize'];

  function SubscriptionUserPopupController($scope, $uibModal, SubscriptionUsersService, SubscriptionDetailsService,
    subscriptionId, companyId, subscriptionUsers, gridPageSize) {
    $scope.busy = false;    
    $scope.subscriptionId = subscriptionId;
    $scope.companyId = companyId;    
    $scope.companyInfo = null;
    $scope.searchMessage = '';
    $scope.saveMessage = null;
    $scope.numSearchUsersSelected = 0, $scope.numDeactiveUsersSelected = 0;
    $scope.searchData = [];
    $scope.search = {
      firstName: null,
      lastName: null,
      email: null,
      companyName: null,
      subscriptionIdToExclude: subscriptionId
    };
    $scope.alreadySavedOnce = false;

    getCompanyInfo();
    initSearchUsersGrid();
    initDeactiveUsersGrid();

    function initSearchUsersGrid() {
      $scope.searchUsersGridOpts = {
        columns: [{
          field: "id",
          title: "ContactID",
          hidden: "hidden"
        }, {
          field: "companyID",
          title: "CompanyID",
          hidden: "hidden"
        }, {
          title: "Active",
          field: "isSelected",
          width: '50px',
          template: '<input type="checkbox" ng-model="dataItem.isSelected" ng-change="toggleUserSelected(this.dataItem, \'search\')"/>',
          filterable: {
            cell: {
              enabled : false
            }
          }
        }, {
          title: "Acct Admin",
          field: "accountAdmin",
          width: '50px',
          headerAttributes: { style: "white-space: normal" },
          template: '<input type="checkbox" ng-model="dataItem.accountAdmin" ng-disabled="dataItem.isSelected == false"/>',
          filterable: {
            cell: {
              enabled: false
            }
          }
        }, {
          title: "First Name",
          field: "firstName",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          title: "Last Name",
          field: "lastName",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          title: "Title",
          field: "title",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          title: "Email",
          field: "email",
          attributes: { 'class': 'word-break-150-width' },
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          headerTemplate: '<span>SF Company<br>Name</span>',
          field: "companyName",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          headerTemplate: '<span>NIC MAP<br>Account<br>Name</span>',
          field: "subscriptionCompanyName",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          headerTemplate: '<span>Account<br>Status</span>',
          field: "accountStatus",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }],
        dataSource: new kendo.data.DataSource({
          transport: {
            read: function (options) {
              options.success($scope.searchData);
            }
          },
          schema: {
            model: {
              fields: {
                'id': { type: 'string' },
                'companyID': { type: 'number' },
                'firstName': { type: 'string' },
                'lastName': { type: 'string' },
                'title': { type: 'string' },
                'email': { type: 'string' },
                'companyName': { type: 'string' },
                'subscriptionCompanyName': { type: 'string' },
                'accountStatus': { type: 'string' }
              }
            },
            parse: gridParseFunc
          },
          sort: {
            field: "lastName",
            dir: "asc"
          },
          pageSize : gridPageSize,
          serverPaging: false,
          serverFiltering: false,
          serverSorting: false
        }),
        dataBinding: function (e) {
          Nic.kendoGrid.filtersOnTop('searchUsersGrid');
        },
        filterable: {
          mode: "row"
        },
        scrollable: false,
        sortable: {
          mode: "single",
          allowUnsort: false
        },
        pageable: true,
        resetFilters: function () {
          this.dataSource.filter([]);
          this.dataSource.sort({ field: "lastName", dir: "asc" });
        }
      };
    };

    function initDeactiveUsersGrid() {
      $scope.deactiveUsersGridOpts = {
        columns: [{
          field: "contactId",
          title: "ContactID",
          hidden: "hidden"
        }, {
          field: "companyId",
          title: "CompanyID",
          hidden: "hidden"
        }, {
          title: "Active",
          field: "isSelected",
          width: '50px',
          template: '<input type="checkbox" ng-model="dataItem.isSelected" ng-change="toggleUserSelected(this.dataItem, \'deactive\')"/>',
          filterable: {
            cell: {
              enabled : false
            }
          }
        }, {
          title: "Acct Admin",
          field: "accountAdmin",
          width: '50px',
          headerAttributes: { style: "white-space: normal" },
          template: '<input type="checkbox" ng-model="dataItem.accountAdmin" ng-disabled="dataItem.isSelected == false"/>',
          filterable: {
            cell: {
              enabled: false
            }
          }
        }, {
          title: "First Name",
          field: "firstName",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          title: "Last Name",
          field: "lastName",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          title: "Title",
          field: "title",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          title: "Email",
          field: "email",
          attributes: { 'class': 'word-break-150-width' },
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }],
        dataSource: new kendo.data.DataSource({
          transport: {
            read: function (options) {
              var gridData = _.filter(subscriptionUsers, ['active', false]);
              options.success(gridData);
            }
          },
          schema: {
            model: {
              fields: {
                'contactId': { type: 'number' },
                'companyId': { type: 'number' },
                'firstName': { type: 'string' },
                'lastName': { type: 'string' },
                'title': { type: 'string' },
                'email': { type: 'string' }
              }
            },
            parse: gridParseFunc
          },
          sort: {
            field: "lastName",
            dir: "asc"
          },
          pageSize : gridPageSize,
          serverPaging: false,
          serverFiltering: false,
          serverSorting: false
        }),
        dataBinding: function (e) {
          Nic.kendoGrid.filtersOnTop('deactiveUsersGrid');
        },
        filterable: {
          mode: "row"
        },
        scrollable: false,
        sortable: {
          mode: "single",
          allowUnsort: false
        },
        pageable: true,
        resetFilters: function () {
          this.dataSource.filter([]);
          this.dataSource.sort({ field: "lastName", dir: "asc" });
        }
      };
    }

    function gridParseFunc(data) {
      for (var i = 0; i < data.length; i++) {
        var user = data[i];
        if (user.isSelected == undefined) {
          user.isSelected = false;
        }
        if (user.accountAdmin == undefined) {
          user.accountAdmin = false;
        }
      }
      return data;
    }

    function getCompanyInfo() {
      $scope.companyInfo = SubscriptionDetailsService.getCompany();
    }

    $scope.toggleUserSelected = function (dataItem, gridType) {
      if (dataItem.isSelected) {
        gridType == 'search' ? $scope.numSearchUsersSelected++ : $scope.numDeactiveUsersSelected++;
      } else {
        gridType == 'search' ? $scope.numSearchUsersSelected-- : $scope.numDeactiveUsersSelected--;
      }
    };

    $scope.doSearch = function () {      
      $scope.searchMessage = '';
      if (!$scope.isSearchValid()) {
        $scope.searchMessage = 'At least 2 fields are required to search or email.';
      } else {
        var selectedUsers = _.filter($scope.searchUsersGridOpts.dataSource.data(), ['isSelected', true]);
        $scope.saveMessage = null;
        $scope.numSearchUsersSelected = 0;
        $scope.searchData = [];
        $scope.searchUsersGridOpts.dataSource.read();
        $scope.searchUsersGridOpts.resetFilters();
       
        $scope.busy = true;
        SubscriptionUsersService.search($scope.search)
        .then(function (data) {
          // remove out from the new search results any records that are selected from before
          if (selectedUsers && selectedUsers.length > 0){
            data = _.differenceBy(data, selectedUsers, 'sfContactId');
            data = data.concat(selectedUsers);
            $scope.numSearchUsersSelected = selectedUsers.length;
          }

          $scope.searchData = data;
          $scope.searchUsersGridOpts.dataSource.read();
        })
        .finally(function () {
          $scope.busy = false;
        })
      }
    };

    $scope.isSearchValid = function () {
      // check to see at least 2 of the fields have a value or just email is valid format
      var numPropertiesWithValues = 0;
      _.forOwn($scope.search, function (value, key) {
        if (value && key != "subscriptionIdToExclude") {
          numPropertiesWithValues++;
        }
      });

      if (numPropertiesWithValues >= 2) {
        return true;
      } else {
        if (numPropertiesWithValues == 1 && $scope.search.email) {
          return true;
        }
      }

      return false;
    };
  
    $scope.isValid = function () {
      return $scope.numSearchUsersSelected > 0 || $scope.numDeactiveUsersSelected > 0;
    }

    $scope.cancelClick = function () {
      // if we already saved, then we call the close so that the user section will reload on the callback.  
      // if the user clicked cancel without trying to save, then we can dismiss and not reload anything.
      if ($scope.alreadySavedOnce) {
        $scope.$close();
      } else {
        $scope.$dismiss();
      }
    };

    function openAssociateUserPopups(idx) {
      if (idx > $scope.anyDifferentSubscriptionUsersSelected.length - 1) {
        continueSaveUser();
        return;
      }

      var AssociatedUserPopupController = ['$scope', 'userInfo', 'currentCompany', function ($scope, userInfo, currentCompany) {
        $scope.email = userInfo.email;
        $scope.subscriptionCompanyName = userInfo.subscriptionCompanyName;
        $scope.currentCompanyName = currentCompany.companyName;
      }];

      var associatedUser = $scope.anyDifferentSubscriptionUsersSelected[idx],
        modalInstance = $uibModal.open({
          templateUrl: "app/partials/userAlreadyAssociatedPopup.html",
          controller: AssociatedUserPopupController,
          controllerAs: 'ctrl',
          backdrop: 'static',
          resolve: {
            userInfo: function () { return associatedUser; },
            currentCompany: function () { return $scope.companyInfo; }
          }
        });

      modalInstance.result.then(function (changeUserAssociation) {
        if (changeUserAssociation) {
          $scope.searchGridSelectedUsers.push(associatedUser);
        }
        openAssociateUserPopups(idx + 1);
      });
    }

    function continueSaveUser() {
      $scope.busy = true;

      var deactiveGridSelectedUsers = _.filter($scope.deactiveUsersGridOpts.dataSource.data(), ['isSelected', true]);

      var flat = _.union($scope.searchGridSelectedUsers, deactiveGridSelectedUsers);
      var selectedUsers = _.chain(flat)
        .map(function (obj) {
          var sfContactId = obj.sfContactId ? obj.sfContactId : obj.contactSalesforceId;
          return {
            sfContactId: sfContactId,
            firstName: obj.firstName,
            lastName: obj.lastName,
            email: obj.email,
            accountAdmin: obj.accountAdmin
          };
        })
        .value();

      $scope.alreadySavedOnce = true;

      // save the selectedUsers
      SubscriptionUsersService
        .associateContacts($scope.subscriptionId, selectedUsers)
        .then(function (result) {
            //handle success
            $scope.$close(result);
          }, function (err) {
            if (err.status == 403) {
              $scope.saveMessage = 'An error has occurred and the contact(s) cannot be associated to the subscription. Please contact the system administrator to update your system rights.';
            } else {
              $scope.saveMessage = 'An error has occurred and the contact(s) cannot be associated to the subscription. Please try again.';
            }
          })
        .finally(function () {
          $scope.busy = false;
        });
    }

    $scope.saveUsers = function(){
      if (!$scope.isValid()) return;

      // check if any of the users selected from search grid are already associated to some other active subscription or not...
      // if so start showing popups
      $scope.searchGridSelectedUsers = _.filter($scope.searchUsersGridOpts.dataSource.data(), ['isSelected', true]);
      $scope.anyDifferentSubscriptionUsersSelected = _.filter($scope.searchGridSelectedUsers, ['isInDifferentCompanySubscription', true]);
      if ($scope.anyDifferentSubscriptionUsersSelected) {
        _.remove($scope.searchGridSelectedUsers, function (searchGridUser) {
            return _.indexOf($scope.anyDifferentSubscriptionUsersSelected, searchGridUser) !== -1;
        });
      }
      openAssociateUserPopups(0);
    }
  }
})();