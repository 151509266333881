(function () {
  "use strict";

  app.controller('SubscriptionController', SubscriptionController);

  SubscriptionController.$inject = ['$scope', '$state', '$rootScope', '$http', '$q', '$uibModal', 'apiSettings', 'sessionStorageKeys', 'FileSaver', 'Blob', 'AdminService', 'PopupHandlerService', 'SubscriptionDetailsService', 'SpinnerService'];

  function SubscriptionController($scope, $state, $rootScope, $http, $q, $uibModal, apiSettings, sessionStorageKeys, FileSaver, Blob, AdminService, PopupHandlerService, SubscriptionDetailsService, SpinnerService) {
    $rootScope.hideLeftNav = true; //when you enter admin the left nav should collapse
    $scope.showExpired = false; // 1
    $scope.showTerminated = false;
    $scope.addNewSubscription = addNewSubscription;
    $scope.subscriptions = [];
    $scope.subscriptionTypes = [];
    $scope.gridDataBinding = gridDataBinding;

    var pageInfoToSave = {
      key: sessionStorageKeys.adminSubscriptions,
      value: {
        showExpired: false, // 1
        showTerminated: false,
        filters: null,
        sort: null
      }
    };
    
    var gridSubscriptionTypeDataSource = new kendo.data.DataSource({
      transport: {
        read: function (options) {
          options.success($scope.subscriptionTypes);
        }
      }
    });

    SubscriptionDetailsService.getSubscriptionTypes()
      .then(function (data) {        
        $scope.subscriptionTypes = _.sortBy(data, ['subscriptionType']);
        gridSubscriptionTypeDataSource.read();
      });

    $scope.gridOpts = {
      columns: [
      {
        field: "subscriptionID",
        title: "SubscriptionID",
        hidden: "hidden"
      }, {
        field: "companyID",
        title: "CompanyID",
        hidden: "hidden"
      }, {
        field: "companyName",
        title: "Company",
        template: '#if(parentCompanyName == null){#<a ui-sref="#= \'admin.subscriptiondetails({ id: \' + subscriptionID + \', companyId: \' + companyID + \' })\' #">#=companyName#</a>#}else{#<a ui-sref="#= \'admin.subscriptiondetails({ id: \' + subscriptionID + \', companyId: \' + companyID + \' })\' #">#=parentCompanyName# - #=companyName#</a>#}#',
        filterable: {
          cell: {
            showOperators: false,
            operator: "contains"
          }
        }
      }, {
        field: "startDate",
        title: "Start Date",
        format: "{0:MM/dd/yyyy}",
        filterable: {
          cell: {
            showOperators: true,
            operator: "eq"
          }
        }
      }, {
        field: "endDate",
        title: "End Date",
        format: "{0:MM/dd/yyyy}",
        filterable: {
          cell: { 
            showOperators: true,
            operator: "eq"
          }
        }
      }, {
        field: "subscriptionType",
        title: "Subscription Type",
          filterable: {
            cell: {
              showOperators: false,
              template: function (args) {
                args.element.removeAttr("data-bind");
                args.element.attr("id", "SubscriptionTypeMultiSelect"); //so I can find easily in dom
                args.element.kendoMultiSelect({
                  dataSource: gridSubscriptionTypeDataSource,
                  optionLabel: " ",
                  dataTextField: "subscriptionType",
                  dataValueField: "subscriptionType",
                  valuePrimitive: true,
                  change: subscriptionTypeFilterChange
                })
              }
            }
          }
      }, {
        field: "clientApiSubscriptionType",
        title: "Client API",
        filterable: {
          cell: {
            showOperators: false,
            template: function (args) {
              args.element.kendoDropDownList({
                autoBind: false,
                optionLabel: " ",
                dataTextField: "name",
                dataValueField: "name",
                valuePrimitive: true,
                dataSource: new kendo.data.DataSource({
                  data: [
                    { name: 'Basic' },
                    { name: 'Enterprise' },
                    { name: 'N/A' }
                  ]
                })
              });
            }
          }
        }
      }, {
        field: "cbsaCount",
        title: "Metro Count",
        filterable: {
          cell: {
            showOperators: false,
            operator: "gte"
          }
        }
      }, {
        field: "accountType",
        title: "Account Type",
        filterable: {
          cell: {
            showOperators: false,
            template : function(args){
              args.element.kendoDropDownList({
                autoBind: false,
                optionLabel: " ",
                dataTextField: "name",
                dataValueField: "name",
                valuePrimitive: true,
                dataSource: new kendo.data.DataSource({
                  data: [
                    { name: 'New' },
                    { name: 'Renewal' }
                  ]
                })                                                           
              });
            }        
          }
        }
      }, {
        field: "price",
        title: "Contract Value",
        format: "{0:c0}",
        filterable: {
          cell: {
            showOperators: false,
  	  	  	operator: "gte"
          }
        }
      }, {
        field: "accountStatus",
        title: "Account Status",
        filterable: {
          cell: {
            showOperators: false,
            template : function(args){
              args.element.removeAttr("data-bind");
              args.element.attr("id","AccountStatusMultiSelect"); //so I can find easily in dom
              args.element.kendoMultiSelect({
                optionLabel: " ",
                dataTextField: "name",
                dataValueField: "name",
                valuePrimitive: true,
                dataSource: new kendo.data.DataSource({
                  data: [
                    { name: 'Active' },
                    { name: 'Expired' },
                    { name: 'Pending' },
                    { name: 'Terminated' }                     
                  ]
                }),
                change: accountStatusFilterChange
              })
            }                      
          }
        }
      }, {
        title: "Salesforce",
        hidden: "hidden",
        template: '<a href="//login.salesforce.com/" target="_blank">Salesforce</a>',
        filterable: {
          cell: {
            enabled: false,
            showOperators: false,
            operator: "contains"
          }
        }
      }, {
        field: "hasPending",
        title: "Has Pending",
        filterable: {
          cell: {
            showOperators: false,
            template: function (args) {
              args.element.kendoDropDownList({
                autoBind: false,
                optionLabel: " ",
                dataTextField: "name",
                dataValueField: "name",
                valuePrimitive: true,
                dataSource: new kendo.data.DataSource({
                  data: [
                    { name: 'Yes' },
                    { name: 'No' }
                  ]
                })
              });
            }
          }
        }
      }],
      dataSource: new kendo.data.DataSource({
        transport: {
          read: function (options) {
            options.success($scope.subscriptions);
          }
        },
        pageSize: 25,
        error: function (err) {
          console.log('error - ' + err);
        },
        schema: {
          model: {
            fields: {
              'subscriptionID': { type: 'number' },
              'companyID': { type: 'number' },
              'companyName': { type: 'string' },
              'parentCompanyName': { type: 'string' },
              'startDate': { type: 'date' },
              'endDate': { type: 'date' },
              'subscriptionType': { type: 'string' },
              'clientApiSubscriptionType': { type: 'string' },
              'accountType': { type: 'string' },
              'price': { type: 'number' },
              'cbsaCount': { type: 'number' },
              'hasPending' : {type:'string'}
            }
          },
          parse: function (data) {
            $.each(data, function (idx, item) {
              if (item.hasPending ===  true) {
                item.hasPending = 'Yes';
              }
              else {
                item.hasPending = 'No';
              }
              if (item.isTerminated === true)
              {
                item.accountStatus = 'Terminated'
              }
              item.clientApiSubscriptionType = item.clientApiSubscriptionType || 'N/A';
              var parseEndDate = kendo.parseDate(item.endDate);
              parseEndDate.setHours(0,0,0,0);
              item.endDate = parseEndDate;
            });
            return data;
          }
        },
        sort: {
          field: "companyName",
          dir: "asc"
        },
        serverPaging: false,
        serverFiltering: false,
        serverSorting: false
      }),
      dataBinding: gridDataBinding,
      filterable: {
        mode: "row",
        extra: false,
        operators: {
          date: {
            eq: "Equal to",
            gte: "On or After",
            lte: "On or Before"
          }
        }
      },
      scrollable: false,
      sortable: {
        mode: "single",
        allowUnsort: false
      },
      pageable: true,
      resetFilters: function () {
        this.dataSource.filter({});
        var subTypeMulti = $('#SubscriptionTypeMultiSelect');
        var acctStatusMulti = $('#AccountStatusMultiSelect');
        if(subTypeMulti.length > 0) {
          subTypeMulti.data("kendoMultiSelect").value([]);
        }
        if(acctStatusMulti.length > 0) {
          acctStatusMulti.data("kendoMultiSelect").value([]); 
        }       
        this.dataSource.sort({ field: "companyName", dir: "asc" });
      }
    };

    function subscriptionTypeFilterChange(e) {
      applyFilterChange("or", "subscriptionType", "eq", this.value());
    }

    function accountStatusFilterChange(e) {
      applyFilterChange("or", "accountStatus", "eq", this.value());
    }

    function applyFilterChange (_logic, _field, _operator, _values) {
      var filter = { logic: _logic, filters: [] };
      $.each(_values, function (i, v) {
        filter.filters.push({field: _field, operator: _operator, value: v });
      });
      if (filter.filters.length > 0) {
        // filter added
        var currentFilters = $scope.gridOpts.dataSource.filter() || {};
        if (currentFilters.filters) {
          var filteredOut = _.filter(currentFilters.filters, function (eachFilter) {
            return (eachFilter.field && eachFilter.field != _field) || 
              (eachFilter.filters && eachFilter.filters.length > 0 && eachFilter.filters[0].field != _field);
          });
          filteredOut.push(filter);
          $scope.gridOpts.dataSource.filter(filteredOut);
        } else {
          $scope.gridOpts.dataSource.filter(filter);
        }
      } else {
        var currentFilters = $scope.gridOpts.dataSource.filter() || {};
        if (currentFilters.filters) {
          var filteredOut = _.filter(currentFilters.filters, function (eachFilter) {
            return (eachFilter.field && eachFilter.field != _field) || 
              (eachFilter.filters && eachFilter.filters.length > 0 && eachFilter.filters[0].field != _field);
          });
          $scope.gridOpts.dataSource.filter(filteredOut);
        } else {
          $scope.gridOpts.dataSource.filter(filter);
        }
      }
    } 

    function GetData(isPageLoad) {
      SpinnerService.start();
      
      var parsedOptions = null;
      if (isPageLoad) {
        // page load... check if session storage has the data saved or not?
        var options = sessionStorage[pageInfoToSave.key];
        if (options) {
          parsedOptions = JSON.parse(options);
          $scope.showExpired = parsedOptions.showExpired;
          $scope.showTerminated = parsedOptions.showTerminated;

        }
      }

      console.log('GetData started: ' + new Date().toLocaleTimeString());

      AdminService.getSubscriptions($scope.showExpired, $scope.showTerminated) // $scope.showExpired == 0
        .then(function (data) {
          console.log('GetData completed: ' + new Date().toLocaleTimeString());
          $scope.subscriptions = data;
          $scope.gridOpts.dataSource.read();
        
          if (parsedOptions) {
            $scope.gridOpts.dataSource.sort(parsedOptions.sort);
            var selectSubTypes = [], selectedAccountStatus = [];
            _.forEach(parsedOptions.filters.filters, function (eachFilterEntity) {
              if (eachFilterEntity.field && eachFilterEntity.field === "subscriptionType") {
                // object
                selectSubTypes.push(eachFilterEntity.value);
              } else {
                // array 
                _.forEach(eachFilterEntity.filters, function (nestedEntity) {
                  if (nestedEntity.field && nestedEntity.field === "subscriptionType") {
                    selectSubTypes.push(nestedEntity.value);
                  }
                });
              }
        
              if (eachFilterEntity.field && eachFilterEntity.field === "accountStatus") {
                // object
                selectedAccountStatus.push(eachFilterEntity.value);
              } else {
                // array 
                _.forEach(eachFilterEntity.filters, function (nestedEntity) {
                  if (nestedEntity.field && nestedEntity.field === "accountStatus") {
                    selectedAccountStatus.push(nestedEntity.value);
                  }
                });
              }
        
              if (eachFilterEntity.field && (eachFilterEntity.field === "startDate" || eachFilterEntity.field === "endDate")) {
                eachFilterEntity.value = new Date(eachFilterEntity.value);
              }
            });
            var subTypeMulti = $('#SubscriptionTypeMultiSelect');
            if (subTypeMulti.length > 0 && selectSubTypes.length > 0) {
              subTypeMulti.data("kendoMultiSelect").value(selectSubTypes);
            }
            var acctStatusMulti = $('#AccountStatusMultiSelect');
            if (acctStatusMulti.length > 0 && selectedAccountStatus.length > 0) {
              acctStatusMulti.data("kendoMultiSelect").value(selectedAccountStatus);
            }
        
            $scope.gridOpts.dataSource.filter(parsedOptions.filters);
          }

          console.log('Spinner stopped: ' + new Date().toLocaleTimeString());
          SpinnerService.stop();
        });
    }

    GetData(true);

    $scope.includeExpiredSubscription = function () {
      //refresh the datasource to see the new data
      GetData();
    };

    $scope.includeTerminatedSubscription = function () {
      //refresh the datasource to see the new data
      GetData();
    };

    function gridDataBinding(e) {
      Nic.kendoGrid.filtersOnTop('grid');
      
      pageInfoToSave.value.showExpired = $scope.showExpired;
      pageInfoToSave.value.showTerminated = $scope.showTerminated;
      
      var currSortVal = $scope.gridOpts.dataSource.sort();
      var currSort = currSortVal ? currSortVal : [];
      pageInfoToSave.value.sort = currSort;
      
      var currFilterObj = $scope.gridOpts.dataSource.filter() || {};
      pageInfoToSave.value.filters = currFilterObj;
      
      sessionStorage[pageInfoToSave.key] = JSON.stringify(pageInfoToSave.value);
    }

    function addNewSubscription() {
      SpinnerService.start();

      $q.all([
        SubscriptionDetailsService.getAllProductGroupsAndProductsData(),
        AdminService.getNewCompanies(),
        SubscriptionDetailsService.getSubscriptionTypes()
      ]).then(function (responses) {

        SpinnerService.stop();
        var allProducts = responses[0];
        var newCompanies = responses[1];
        var allSubscriptionTypes = responses[2];

        var newSubscriptionPopup = $uibModal.open({
          templateUrl: "app/partials/subscriptionPopup.html",
          controller: 'SubscriptionPopupController',
          backdrop: 'static',
          windowClass: 'modify-access-popup',
          resolve: {
            actionToPerform: function () { return 'new'; },
            paramsPassedIn: function () { return { subscriptionId: null, companyId: null, activeSubRowInfo: null, hasActiveOrPendingSub: null, pendingSubscriptions: null }; },
            newCompanies: function () { return newCompanies; },
            subscribedCBSAs: function () { return null; },
            pageSubInfo: function () { return null; },
            allRegionsAndCBSAs: function () { return null; },
            allProducts: function () { return allProducts; },
            allSubscriptionTypes: function () { return allSubscriptionTypes; }
          }
        });

        newSubscriptionPopup.result.then(function (dataReturned) {
          $state.go('admin.subscriptiondetails',
          {
            id: dataReturned.subscriptionId,
            companyId: dataReturned.companyId
          },
          { reload: true });
        });
      }, function (error) {
        // error
        SpinnerService.stop();
        PopupHandlerService.openSimplePopup("There was an error when trying to load the popup. Please try again or contact us.");
      });
    };

    $scope.exportExcel = function () {
      // get the array of sort values
      // currSort = [ { dir: 'asc' || 'desc', field: columnName }, ...]
      var currSortVal = $scope.gridOpts.dataSource.sort();
      var currSort = currSortVal ? currSortVal : [];

      // if no filters selected then the currFilterObj = undefined
      var currFilterObj = $scope.gridOpts.dataSource.filter();
      // get the array of filters
      // currFilterObj.filters = [ { field: columnName, operator: operator, value: filterValue }, ...]
      var currentFilters = currFilterObj ? currFilterObj.filters : [];
      var combinedFilters = [];
      _.forEach(currentFilters, function (eachFilterInfo) {
        // need to do this for the multiselect dropdowns that are in the grid
        if (eachFilterInfo.filters) {
           var concatenatedVal = '', sameFieldName = '', sameFieldOperator = '';
           _.forEach(eachFilterInfo.filters, function (sameFieldObj) {
            sameFieldName = sameFieldObj.field;
            sameFieldOperator = sameFieldObj.operator;
            concatenatedVal += (concatenatedVal.length > 0 ? ';' : '') + sameFieldObj.value;
          });
          combinedFilters.push({ field: sameFieldName, operator: sameFieldOperator, value: concatenatedVal });
        } else {
          combinedFilters.push(eachFilterInfo);
        }
      });

      //var param = $scope.showExpired;
      var param = $scope.showExpired == true ? 0 : 1;
      var title = 'NICMAP Client List';
      SpinnerService.start();
      AdminService 
        .generateSubscriptionsList(param, $scope.showTerminated, title, combinedFilters, currSort, new Date())
        .then(function (data) {
          var excelFile = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          });
          var fileName = title.replace(/ /g, '_') + '.xlsx';
          SpinnerService.stop()
          FileSaver.saveAs(excelFile, fileName);
        });
    };
  }
})();
