(function () {
  "use strict";

  app.factory("IntercomService", IntercomService);

  IntercomService.$inject = ["$http", "$q", "$state", "apiSettings", "DateOffsetUtils"];

  function IntercomService($http, $q, $state, apiSettings, DateOffsetUtils) {
    return {
      createConversation: createConversation
    }

    function createConversation(data) {
      var defer = $q.defer();

      var uri = apiSettings.url + '/intercom/conversation';
      $http
        .post(uri, data)
        .then(function (rsp) {
          console.log(rsp);
          defer.resolve(rsp.data);
        }, function (err) {
          console.error(err);
          defer.reject(err);
        });

      return defer.promise;
    };
  }
})();
