(function() {
  "use strict";

  app.controller('PropertyAdvisorController', PropertyAdvisorController);

  PropertyAdvisorController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$window', '$q', '$uibModal',
    'PropertyAdvisorService', 'KendoTemplateFunctions', 'BingMapUtils', 'GeoDataService', 'BingGeoDataService', 'FileSaver', 'PopupHandlerService', 'AuditService',
    'SignalRService', 'knownEventConstant', 'knownProductConstant', 'SpinnerService', 'TrendsService', 'pushpinImages', 'PinGeneratorUtil', 'reportSectionTypeConstant'
  ];

  function PropertyAdvisorController($scope, $rootScope, $state, $stateParams, $window, $q, $uibModal,
    PropertyAdvisorService, KendoTemplateFunctions, BingMapUtils, GeoDataService, BingGeoDataService, FileSaver, PopupHandlerService, AuditService,
    SignalRService, knownEventConstant, knownProductConstant, SpinnerService, TrendsService, pushpinImages, PinGeneratorUtil, reportSectionTypeConstant) {
    $rootScope.hideLeftNav = true;

    $scope.savedPARReport = { Name: '', Id: '' };
    $scope.saveReportClick = saveReportClick;
    $scope.exportOccupancy = exportOccupancy;
    $scope.exportAverageRent = exportAverageRent;
    $scope.exportRelativePerformance = exportRelativePerformance;
    $scope.parToPDF = parToPDF;
    $scope.parToExcel = parToExcel;
    $scope.isLoading = isLoading;
    $scope.showMetroArea = false;
    $scope.showCounties = false;
    $scope.geoTemplate = "<span># if (!data.isCbsa) {# &nbsp;&nbsp; #} ##: data.name #</span>";
    $scope.report = {
      cbsasAndCounties: [],
      operators: [],
      summary: {},
      segments: [],
      unitTypesBySegment: [],
      comps: [],
      segmentsByQuarter: [],
      constructionInventory: [],
      transactions: [],
      hospitals: [],
      medicareAPIFailure: false
    };
    $scope.pins = {
      comps: [],
      showComps: true,
      constructionInventory: [],
      showConstructionInventory: false,
      hospitals: [],
      showHospitals: false,
      closedTransactions: [],
      showClosedTransactions: false
    };
    $scope.onClickShowComps = onClickShowComps;
    $scope.onClickShowConstructionInventory = onClickShowConstructionInventory;
    $scope.onClickShowHospitals = onClickShowHospitals;
    $scope.toggleMetroArea = toggleMetroArea;
    $scope.toggleCounties = toggleCounties;
    $scope.onClickShowClosedTransactions = onClickShowClosedTransactions;

    $scope.invalidReportName = false;
    $scope.shouldBeFocused = false;
    $scope.limitKeypress = function($event, value, maxLength) {
      if(value != undefined && $event.keyCode != 8 && $event.keyCode != 37 && $event.keyCode != 39 && $event.keyCode != 46 && value.toString().length >= maxLength) {
        $event.preventDefault();
      }

      if(value != undefined && value.toString().length > 0) {
        $scope.invalidReportName = false;
      }
    }

    $scope.validateReportName = function () {
      // only valid characters are numbers, letters, spaces, comma, period, hashtag, semicolon, colon, hyphen, apostrophe, ampercent
      $scope.savedPARReport.Name = $scope.savedPARReport.Name.replace(/([^\w ,.#;:\-'&\\]+)/gi, '');
    }

    $scope.occupancySeriesWithSeriesCode = occupancySeriesWithSeriesCode;
    $scope.rentSeriesWithSeriesCode = rentSeriesWithSeriesCode;
    $scope.rpiSeriesWithSeriesCode = rpiSeriesWithSeriesCode;
    $scope.defaultLegend = KendoTemplateFunctions.defaultChartLegend;
    $scope.seriesDefaults = {
      type: 'line',
      style: 'smooth',
      markers: {
        visible: false
      },
      missingValues: 'gap'
    };

    $scope.chartNumberAxis = [{
      color: "#959595",
      labels: {
        format: "c0",
        color: "#232323"
      },
      narrowRange: true
    }];

    $scope.chartPercentageAxis = [{
      name: "percentageAxis",
      color: "#959595",
      labels: {
        format: "p0",
        color: "#232323"
      },
      max: 1.01,
      narrowRange: true
    }];

    $scope.chartCurrencyAxis = [{
      name: "currencyAxis",
      color: "#959595",
      labels: {
        format: "c0",
        color: "#232323"
      },
      narrowRange: true
    }];

    $scope.categoryAxis = {
      majorGridLines: {
        visible: false
      },
      labels: {
        rotation: {
          angle: -90
        }
      }
    };

    $scope.rentDataSource = [];
    $scope.rpiDataSource = [];
    $scope.occupancyDataSource = [];

    $scope.categoryQuarterAxis = {
      majorGridLines: {
        visible: false
      },
      labels: {
        template: xAxisLabels
      }
    };

    $scope.map = {};
    $scope.mapOptions = BingMapUtils.getDefaultViewOptions(10);
    $scope.mapOptions.options.disableScrollWheelZoom = true;
    $scope.onMapReady = onMapReady;
    $scope.exportTransactions = exportTransactions;

    SpinnerService.start();

    var pageSize = 5;
    var isPageLoad = true;
    var isSavedParReport = false;
    var cbsaAreaLocationCache = null;
    $scope.cbsaArea = BingMapUtils.getPolygonOptions('cbsaArea');

    function init() {
      $q.all([
        TrendsService.getCurrentQuarter(),
        PropertyAdvisorService.getSavedPARReports()
      ]).then(function(responses) {
        $scope.currentQuarter = responses[0];
        $scope.savedPARReports = responses[1];
        //check if PAR report is saved or not
        // if saved then retrieve the name
        _.forEach($scope.savedPARReports, function(eachSavedPAR) {
          if(eachSavedPAR.id == $stateParams.id) {
            $scope.savedPARReport.Name = eachSavedPAR.name;
            $scope.savedPARReport.Id = eachSavedPAR.userSavedQueryId;
            isSavedParReport = true;
            return;
          }
        });
      }, function(error) {
        // error
        SpinnerService.stop();
        PopupHandlerService.openSimplePopup("There was an error when trying to load the page. Please try again or contact us.");
      });
    }

    init();

    function initConstructionInventoryGrid() {
      $scope.constructionInventoryData = {
        columns: [{
          field: "id",
          title: "propertyID",
          hidden: "hidden"
        }, {
          field: "pinNumber",
          title: "Map Pin No.",
          headerTemplate: "<span>Map <br/> Pin No.",
          attributes: {
            'class': 'text-left'
          },
          width: "3%",
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          }
        }, {
          field: "name",
          title: "Property Name",
          template: '<a ng-show="#= id #" ui-sref="#= \'propertyDetails({ id: \' + id + \' })\' #" target="_blank">#= name #</a><span ng-hide="#= id #">#= name #</span>',
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "constructionOpenDate",
          title: "U/C Scheduled Open Date",
          headerTemplate: "<span>U/C Scheduled <br/> Open Date</span>",
          format: "{0:MM/dd/yyyy}",
          filterable: {
            cell: {
              showOperators: false,
              operator: "gte"
            }
          }
        }, {
          field: "addressLine1",
          title: "Address",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "city",
          title: "City",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "stateProvinceCode",
          title: "State",
          width: "3%",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "zipCode",
          title: "ZIP",
          width: "3%",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "operator",
          title: "Operator",
          template: "#= (operator && operator != 'Unknown Stakeholder') ? kendo.toString(operator) : 'N/A' #",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "constructionStatus",
          title: "Construction Type",
          headerTemplate: "<span>Construction Type</span>",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "units",
          title: "Inventory Under Construction",
          headerTemplate: "<span>Inventory <br/> Under Construction</span>",
          template: '<div class="text-nowrap">IL Units : #:units.ilConstructionUnits#<br/>AL Units : #:units.alConstructionUnits#<br/>MC Units : #:units.mcConstructionUnits#<br/>NC Beds : #:units.ncConstructionUnits#</div>',
          filterable: {
            cell: {
              enabled: false
            }
          }
        }],
        dataSource: new kendo.data.DataSource({
          pageSize: pageSize,
          transport: {
            read: function(options) {
              $scope.pins.constructionInventory = PinGeneratorUtil.getCompDirectoryPins(
                $scope.report.constructionInventory,
                $scope,
                $scope.showConstructionInventory
              );
              options.success($scope.pins.constructionInventory);
            }
          },
          schema: {
            model: {
              fields: {
                'pinNumber': { type: 'number' },
                'id': { type: 'number' },
                'name': { type: 'string' },
                'addressLine1': { type: 'string' },
                'city': { type: 'string' },
                'stateProvinceCode': { type: 'string' },
                'zipCode': { type: 'string' },
                'constructionStatus': { type: 'string' },
                'constructionOpenDate': { type: 'date' }
              }
            }
          },
          sort: {
            field: "pinNumber",
            dir: "asc"
          }
        }),
        dataBinding: function(e) {
          Nic.kendoGrid.filtersOnTop('constructionInventoryData');
        },
        filterable: {
          mode: "row"
        },
        scrollable: false,
        sortable: {
          mode: "single",
          allowUnsort: false
        },
        pageable: $scope.report.constructionInventory && $scope.report.constructionInventory.length > pageSize ? true : false,
        resetFilters: function() {
          this.dataSource.filter([]);
          this.dataSource.sort({ field: "pinNumber", dir: "asc" });
        }
      };
    }

    function initTransactionGrid() {
      $scope.transactionData = {
        columns: [
        {
          field: "pinNumber",
          title: "Map Pin No.",
          headerTemplate: "<span>Map <br/> Pin No.</span>",
          template: "#= (pinNumber == null) ? ' ' : pinNumber #",
          width: "75px",
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          }
        }, {
          field: "saleDate",
          title: "Sale Date",
          format: "{0:MM/dd/yyyy}",
          attributes: {
            'class': 'text-left'
          },
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          }
        }, {
          field: "name",
          title: "Property Name",
          template: '<a ng-show="#= propertyId #" ui-sref="#= \'propertyDetails({ id: \' + propertyId + \' })\' #" target="_blank">#= name #</a><span ng-hide="#= propertyId #">#= name #</span>',
          attributes: {
            'class': 'text-left'
          },
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          }
        }, {
          field: "address",
          title: "Address",
          attributes: {
            'class': 'text-left'
          },
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          }
        }, {
          field: "cbsaName",
          title: "Metro",
          attributes: {
            'class': 'text-left'
          },
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          }
        },
        {
          field: "propertyAndCampus",
          title: "Property Type / Campus Type",
          headerTemplate: "<span>Property Type /<br/> Campus Type</span>",
          template: "#:typeDescription#</br>#:campusTypeDesc#",
          attributes: {
            'class': 'text-left'
          },
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          }
        }, {
          field: "totalUnitCount",
          title: "Spaces",
          template: '<div class="text-nowrap">IL Units : #= (ilUnitCount == null) ? " " : ilUnitCount#<br/>AL Units : #= (alUnitCount == null) ? " " : alUnitCount#<br/>MC Units : #= (mcUnitCount == null) ? " " : mcUnitCount#<br/>NC Beds : #= (ncUnitCount == null) ? " " : ncUnitCount#</div>',
          attributes: {
            'class': 'text-left'
          },
          filterable: {
            cell: {
              enabled: false
            }
          }
        }, {
          field: "buyerAndSeller",
          title: "Buyer / Seller",
          template: "<span popover-trigger='mouseenter' uib-popover-html='generateTransactionTooltipText(dataItem.buyerCapitalInvestmentType)' >Buyer: #:buyerCompanyName#</span></br><span  popover-trigger='mouseenter' uib-popover-html='generateTransactionTooltipText(dataItem.sellerCapitalInvestmentType)'>Seller: #:sellerCompanyName#</span>",
          attributes: {
            'class': 'text-left'
          },
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          }
        }, {
          field: "salePrice",
          title: "Close Price",
          format: "{0:c0}",
          attributes: {
            'class': 'text-right'
          },
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          }
        }, {
          field: "pricePerUnit",
          title: "Price Per Unit",
          format: "{0:c0}",
          attributes: {
            'class': 'text-right'
          },
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          }
        }, {
          field: "yearBuilt",
          title: "Year Built / Renovated",
          headerTemplate: "<span>Year Built /<br/> Renovated</span>",
          template: "Built: #= (yearBuilt == null) ? ' ' : yearBuilt#</br>Ren.:#= (yearRenovated == null) ? ' ' : yearRenovated#",
          width: "100px",
          attributes: {
            'class': 'text-left'
          },
          filterable: {
            cell: {
               enabled: false
            }
          }
        }
        ],
        dataSource: new kendo.data.DataSource({
          pageSize: pageSize,
          transport: {
            read: function(options) {
              $scope.pins.closedTransactions = PinGeneratorUtil.getTransactionPins(
                $scope.report.transactions,
                null,
                null,
                $scope,
                $scope.pins.showClosedTransactions
              ).mapPins;
              options.success($scope.report.transactions);
            }
          },
          schema: {
            model: {
              fields: {
                'pinNumber': { type: 'number' },
                'saleDate': {
                  type: 'date'
                },
                'salePrice': {
                  type: 'number'
                },
                'pricePerUnit': {
                  type: 'number'
                },
                'id': {
                  type: 'number'
                }
              }
            },
            parse: function (data) {
              _.forEach(data, function (item, idx) {
                item.propertyAndCampus = item.typeDescription + " " + item.campusTypeDesc;
                item.buyerAndSeller = item.buyerCompanyName + " " + item.sellerCompanyName;
              });
              return data;
            }
          },
          sort: [{
            field: "saleDate",
            dir: "desc"
          }, {
            field: "id",
            dir: "asc"
          }],
          serverPaging: false,
          serverFiltering: false,
          serverSorting: false
        }),
        dataBinding: function(e) {
          Nic.kendoGrid.filtersOnTop('transactionData');
        },
        filterable: {
          mode: "row"
        },
        scrollable: false,
        sortable: {
          mode: "single",
          allowUnsort: false
        },
        pageable: $scope.report.transactions && $scope.report.transactions.length > pageSize ? true : false,
        resetFilters : function () {
          this.dataSource.filter([]);
          this.dataSource.sort({ field: "saleDate", dir: "desc" });
        }
      };
    }

    function initHospitalGrid() {
      $scope.hospitalData = {
        columns: [{
          field: "pinNumber",
          title: "Map Pin No.",
          headerTemplate: "<span>Map <br/> Pin No.</span>",
          template: "#= (pinNumber >= 9000) ? ' ' : pinNumber #",
          width: "3%",
          attributes: {
            'class': 'text-left'
          },
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          }
        }, {
          field: "name",
          title: "Hospital Name",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "type",
          title: "Hospital Type",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "ownership",
          title: "Ownership Type",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "address",
          title: "Address",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "city",
          title: "City",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "state",
          title: "State",
          width: "3%",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "zipCode",
          title: "ZIP",
          width: "3%",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "emergencyServices",
          title: "Emergency Services",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }],
        dataSource: new kendo.data.DataSource({
          pageSize: pageSize,
          transport: {
            read: function(options) {
              var hospitalData = PinGeneratorUtil.generatePropertyData(
                $scope.report.hospitals,
                reportSectionTypeConstant.hospital,
                null,
                null,
                $scope,
                $scope.pins.showHospitals
              );
              $scope.pins.hospitals = hospitalData.mapPins;
              $scope.report.hospitals = hospitalData.properties;
              // Needed to add this back because the requirement has Hospitals without a pin to show last.
              _.each($scope.report.hospitals, function(pin, index) {
                if(!pin.pinNumber) {
                  pin.pinNumber = 9000 + index;
                }
              });

              options.success($scope.report.hospitals);
            }
          },
          schema: {
            model: {
              fields: {
                'pinNumber': { type: 'number' },
                'name': { type: 'string' },
                'type': { type: 'string' },
                'ownership': { type: 'string' },
                'address': { type: 'string' },
                'city': { type: 'string' },
                'state': { type: 'string' },
                'zipCode': { type: 'string' },
                'emergencyServices': { type: 'string' }
              }
            },
            parse: function(data) {
              $.each(data, function(idx, item) {
                if(!item.emergencyservices) {
                  item.emergencyservices = item.emergency_services ? 'Yes' : 'No';
                }
              });
              return data;
            }
          },
          sort: {
            field: "pinNumber",
            dir: "asc"
          },
          serverPaging: false,
          serverFiltering: false,
          serverSorting: false
        }),
        dataBinding: function(e) {
          Nic.kendoGrid.filtersOnTop('hospitalData');
        },
        filterable: {
          mode: "row"
        },
        scrollable: false,
        sortable: {
          mode: "single",
          allowUnsort: false
        },
        pageable: $scope.report.hospitals && $scope.report.hospitals.length > pageSize ? true : false,
        resetFilters: function() {
          this.dataSource.filter([]);
          this.dataSource.sort({ field: "pinNumber", dir: "asc" });
        }
      };
    }

    //initalization
    var getFirstGeoData = $q.defer();

    // start getting the report
    // open port before anything
    SignalRService.genericHub('parGenerated', $scope, function(fullReport, reportId) {
      if(reportId != $stateParams.id) return;
      //console.log(reportId + ': done');
      var data = JSON.parse(fullReport.value);

      allUnitChartsDataSources = {};

      $scope.pins.comps = [];
      $scope.pins.constructionInventory = [];
      $scope.pins.hospitals = [];
      $scope.pins.showClosedTransactions = [];
      $scope.report.cbsasAndCounties = data.cbsasAndCounties || [];
      $scope.report.operators = data.operators || [];
      $scope.report.summary = data.summary || {};
      $scope.report.segments = data.segments || [];
      $scope.report.unitTypesBySegment = data.unitTypesBySegment || [];
      $scope.report.comps = data.comps || [];
      $scope.report.segmentsByQuarter = data.segmentsByQuarter || [];
      $scope.report.constructionInventory = data.constructionInventory || [];
      $scope.report.transactions = data.transactions || [];
      $scope.report.hospitals = data.hospitals || [];
      $scope.report.medicareAPIFailure = data.medicareAPIFailure;

      if($scope.savedPARReport.Name == '') {
        $scope.savedPARReport.Name = data.summary ? data.reportName || data.summary.metros || '' : data.reportName || '';
      }
      if($scope.savedPARReport.Name && $scope.savedPARReport.Name.length > 50) {
        $scope.savedPARReport.Name = $scope.savedPARReport.Name.substring(0, 50);
      }
      $scope.validateReportName();

      // have to init grid afer we have the data so that the dynamic paging will hide or show correctly.
      initTransactionGrid();
      initConstructionInventoryGrid();
      initHospitalGrid();
      initCompSetGrid();
      initSegmentDataGrid();

      $scope.topFiveOperators.dataSource.read();
      $scope.unitData.dataSource.read();
      $scope.compSet.dataSource.read();
      $scope.transactionData.dataSource.read();
      $scope.segmentData.dataSource.read();

      // drawUnitTypeChartData($scope.occupancyChartByUnitType, 'occupancy');
      // drawUnitTypeChartData($scope.averageRentChartByUnitType, 'averageRent');

      wireInCbsaAndCountyDropdowns($scope.report.cbsasAndCounties, $scope.report.segmentsByQuarter);

      SpinnerService.stop();
      $scope.$apply();
    });

    function drawUnitTypeChartData(chart, fieldName) {
      var unProtectedData = _.filter($scope.report.unitTypesBySegment, function(datum) {
        return datum[fieldName] != 'Protected' && datum[fieldName] != 'N/A';
      });
      var categories = _.map(unProtectedData, function(item) {
        return KendoTemplateFunctions.labelForSegmentAndUnitType({ dataItem: item });
      });
      var chartSeries = chart.options.series;
      _.forEach(chartSeries, function(series) {
        series.data = unProtectedData;
      });
      chart.setOptions({
        series: chartSeries,
        categoryAxis: { categories: categories }
      });
    }

    SignalRService.genericHubWError('parError', $scope, function(parError, reportId) {
      if(reportId != $stateParams.id) return;
      if(parError.value) {
        var errorMsg = JSON.parse(parError.value);
        PopupHandlerService
          .openSimplePopup("Error occured (" + errorMsg.statusCode + "): " + errorMsg.value);
      }
      SpinnerService.stop();
      $scope.$apply();
    });
    
    getReport();

    function getReport() {
      PropertyAdvisorService
        .getReport($stateParams.id)
        .then(function(rspSuccess) {}, function(rspError) {
          // console.log('error occured');
          // console.log(rspError);
        });
    }

    $scope.topFiveOperators = {
      columns: [{
        field: "stakeholderName",
        title: "Operator"
      }, {
        field: "propertyCount",
        title: "Properties",
        attributes: {
          'class': 'text-right'
        }
      }, {
        field: "units",
        title: "Units",
        attributes: {
          'class': 'text-right'
        }
      }, {
        field: "unitPercentage",
        title: "Comp Share",
        format: "{0:p1}",
        attributes: {
          'class': 'text-right'
        }
      }],
      dataSource: new kendo.data.DataSource({
        transport: {
          read: function(options) {
            options.success($scope.report.operators);
          }
        }
      }),
      scrollable: false
    };

    function initSegmentDataGrid() {
      $scope.segmentData = {
        columns: [{
          field: "displayName",
          title: " ",
          template: "<span ng-class=\"{'appendTab': #= displayName != 'Seniors Housing' && displayName != 'Nursing Care' # }\">#= displayName #</span>",
          attributes: {
            'class': 'text-left'
          }
        }, {
          title: " ",
          columns: [{
            title: "Inventory",
            field: "inventory",
            attributes: {
              'class': 'text-right'
            }
          }]
        }, {
          title: "Occupancy",
          columns: [{
            field: "occupancy",
            title: $scope.currentQuarter,
            format: "{0:p1}",
            attributes: {
              'class': 'text-right'
            }
          }, {
            field: "occupancyQoQ",
            title: "Quarterly Change",
            template: "#=kendo.format('{0:n0}', occupancyQoQ) # " +
              "# if (occupancyQoQ!='Protected' && occupancyQoQ!='N/A') { #  " +
              " bps" +
              "#}#",
            attributes: {
              'class': 'text-right'
            }
          }, {
            field: "occupancyYoY",
            title: "Annual Change",
            template: "#=kendo.format('{0:n0}', occupancyYoY) # " +
              "# if (occupancyYoY!='Protected' && occupancyYoY!='N/A') { #  " +
              " bps" +
              "#}#",
            attributes: {
              'class': 'text-right'
            }
          }]
        }, {
          title: "Average Rent",
          columns: [{
            field: "rent",
            title: $scope.currentQuarter,
            format: "{0:c0}",
            attributes: {
              'class': 'text-right'
            }
          }, {
            field: "rentYoY",
            title: "Annual Change",
            format: "{0:p1}",
            attributes: {
              'class': 'text-right'
            }
          }]
        }, {
          title: "RPI",
          columns: [{
            field: "relativePerformance",
            title: $scope.currentQuarter,
            format: "{0:c0}",
            attributes: {
              'class': 'text-right'
            }
          }]
        }],
        dataSource: new kendo.data.DataSource({
          transport: {
            read: function(options) {
              options.success($scope.report.segments);
            }
          }
        }),
        scrollable: false
      };
    }
    allUnitChartsDataSources = {};
    $scope.dataSourceForUnitCharts = dataSourceForUnitCharts;

    $scope.metrosAndCounties = new kendo.data.DataSource({
      transport: {
        read: function(options) {
          getFirstGeoData.promise.then(function(data) {
            options.success(data);
          });
        }
      }
    });

    $scope.unitData = {
      columns: [{
        field: "segment",
        title: "Segment",
        attributes: {
          'class': 'text-left'
        }
      }, {
        field: "unitType",
        title: "Unit Type",
        attributes: {
          'class': 'text-left'
        }
      }, {
        field: "inventory",
        title: "Inventory",
        attributes: {
          'class': 'text-right'
        }
      }, {
        field: "unitMix",
        title: "Unit Mix",
        format: "{0:p1}",
        attributes: {
          'class': 'text-right'
        }
      }, {
        field: "occupancy",
        title: "Occupancy",
        format: "{0:p1}",
        attributes: {
          'class': 'text-right'
        }
      }, {
        field: "averageRent",
        title: "Average Rent",
        format: "{0:c0}",
        attributes: {
          'class': 'text-right'
        }
      }, {
        field: "entranceFee",
        title: "Entrance Fee",
        format: "{0:c}",
        attributes: {
          'class': 'text-right'
        }
      }],
      dataSource: dataSourceForUnitCharts(),
      scrollable: false
    };

    $scope.exportComps = exportComps;

     function initCompSetGrid() {
      $scope.compSet = {
        columns: [{
          field: "pinNumber",
          title: "Map Pin No.",
          headerTemplate: "<span>Map <br/> Pin No.</span>",
          width: "80px",
          attributes: {
            'class': 'text-left'
          },
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          }
        }, {
          field: "name",
          title: "Property Name",
          template: '<a ng-show="#= id #" ui-sref="#= \'propertyDetails({ id: \' + id + \' })\' #" target="_blank">#= name #</a><span ng-hide="#= id #">#= name #</span>',
          attributes: {
            'class': 'text-left'
          },
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "buildingAgeInYears",
          title: "Age",
          attributes: {
            'class': 'text-right'
          },
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          }
        }, {
          field: "addressLine1",
          title: "Address",
          attributes: {
            'class': 'text-left'
          },
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "metro",
          title: "Metro",
          attributes: {
            'class': 'text-left'
          },
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "county",
          title: "County",
          attributes: {
            'class': 'text-left'
          },
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "propertyAndCampus",
          title: "Property Type/Campus Type",
          headerTemplate: "<span>Property Type /<br/> Campus Type",
          template: '<span> #:typeDescription# <br> #:campusTypeDesc# </span>',
          attributes: {
            'class': 'text-left'
          },
          width: "100px",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "units",
          title: "Total Open Spaces",
          template: '<div class="text-nowrap">IL Units : #:units.ilUnits#<br/>AL Units : #:units.alUnits#<br/>MC Units : #:units.mcUnits#<br/>NC Beds : #:units.ncUnits#</div>',
          width: "150px",
          attributes: {
            'class': 'text-left'
          },
          filterable: {
            cell: {
              enabled: false
            }
          }
        }, {
          field: "units",
          title: "Inventory Under Construction",
          template: '<div class="text-nowrap">IL Units : #:units.ilConstructionUnits#<br/>AL Units : #:units.alConstructionUnits#<br/>MC Units : #:units.mcConstructionUnits#<br/>NC Beds : #:units.ncConstructionUnits#</div>',
          attributes: {
            'class': 'text-left'
          },
          filterable: {
            cell: {
             enabled: false
            }
          }
        }, {
          field: "operator",
          title: "Operator",
          template: "#= (operator && operator != 'Unknown Stakeholder') ? kendo.toString(operator) : 'N/A' #",
          attributes: {
            'class': 'text-left'
          },
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }],
        dataSource: new kendo.data.DataSource({
          pageSize: pageSize,
          transport: {
            read: function(options) {
              $scope.pins.comps = PinGeneratorUtil.getPARCompDirectoryPins(
                $scope.report.comps,
                $scope,
                $scope.pins.showComps
              );

              if($scope.report.comps.length && $scope.pins.comps.length) {
                BingMapUtils.boundsFromPushpins(bingMap, $scope.pins.comps);
              }
              options.success($scope.pins.comps);
            }
          },
          schema: {
            model: {
              fields: {
                'pinNumber': { type: 'number' },
                'name': { type: 'string' },
                'buildingAgeInYears': { type: 'number' },
                'addressLine1': { type: 'string' },
                'metro': { type: 'string' },
                'county': { type: 'string' },
                'operator': { type: 'string' },
              }
            },
            parse: function (data) {
              _.forEach(data, function (item, idx) {
               item.propertyAndCampus = item.typeDescription + " " + item.campusTypeDesc;
              });
              return data;
            }
          },
          sort: {
            field: "pinNumber",
            dir: "asc"
          },
          serverPaging: false,
          serverFiltering: false,
          serverSorting: false
        }),
        dataBinding: function (e) {
          Nic.kendoGrid.filtersOnTop('compSetData');
        },
        filterable: {
          mode: "row"
        },
        scrollable: false,
        sortable: {
          mode: "single",
          allowUnsort: false
        },
        pageable: $scope.report.comps && $scope.report.comps.length > pageSize ? true : false,
        resetFilters: function () {
          this.dataSource.filter([]);
          this.dataSource.sort({ field: "pinNumber", dir: "asc" });
        }
      }
    }

    function getChartInformation() {
      //http://www.telerik.com/forums/get-current-minimum-and-maximum-ticks#QjNGkNuxR02qIR4P6GOFPA
      var chartInformation = {};
      getMinMaxForChart('occupancy', 'ByUnitType', $scope['occupancyChartByUnitType'], chartInformation);
      getMinMaxForChart('averageRent', 'ByUnitType', $scope['averageRentChartByUnitType'], chartInformation);
      getMinMaxForChart('occupancy', 'SH', !$scope['occupancyHideSH'], chartInformation);
      getMinMaxForChart('occupancy', 'IL', !$scope['occupancyHideIL'], chartInformation);
      getMinMaxForChart('occupancy', 'AL', !$scope['occupancyHideAL'], chartInformation);
      getMinMaxForChart('occupancy', 'MC', !$scope['occupancyHideMC'], chartInformation);
      getMinMaxForChart('occupancy', 'NC', !$scope['occupancyHideNC'], chartInformation);
      getMinMaxForChart('averageRent', 'SH', !$scope['averageRentHideSH'], chartInformation);
      getMinMaxForChart('averageRent', 'IL', !$scope['averageRentHideIL'], chartInformation);
      getMinMaxForChart('averageRent', 'AL', !$scope['averageRentHideAL'], chartInformation);
      getMinMaxForChart('averageRent', 'MC', !$scope['averageRentHideMC'], chartInformation);
      getMinMaxForChart('relativePerformance', 'SH', !$scope['relativePerformanceHideSH'], chartInformation);
      getMinMaxForChart('relativePerformance', 'IL', !$scope['relativePerformanceHideIL'], chartInformation);
      getMinMaxForChart('relativePerformance', 'AL', !$scope['relativePerformanceHideAL'], chartInformation);
      getMinMaxForChart('relativePerformance', 'MC', !$scope['relativePerformanceHideMC'], chartInformation);
      return chartInformation;
    }

    function getMinMaxForChart(prefix, postfix, conditionMet, chartInformation) {
      if(conditionMet) {
        var chartVarName = prefix + 'Chart' + postfix;
        var chart = $scope[chartVarName];
        if(chart) {
          var valueOptions = chart._plotArea.valueAxis.plotArea.axisY.options;
          chartInformation[prefix + postfix] = {
            majorUnit: valueOptions.majorUnit,
            max: valueOptions.max,
            min: valueOptions.min
          };
        }
      }
    }

    function parToPDF() {
      SpinnerService.start();
      var chartInformation = getChartInformation();
      PropertyAdvisorService
        .exportToPDF($stateParams.id, $scope.occupancyGeo, $scope.averageRentGeo, $scope.relativePerformanceGeo,
          chartInformation, $scope.savedPARReport.Name, bingMap.getCenter().latitude, bingMap.getCenter().longitude)
        .then(function(data) {
          SpinnerService.stop();
          var pdfFile = new Blob([data], {
            type: 'application/pdf'
          });
          var fileName = 'NICMAP_PropertyAdvisorReport_' + $scope.currentQuarter + '.pdf';
          FileSaver.saveAs(pdfFile, fileName);
        }, function(err) {
          // console.log(err);
        });
    }

    function parToExcel() {
      SpinnerService.start();
      var chartInformation = getChartInformation();
      PropertyAdvisorService
        .exportToExcel($stateParams.id, $scope.occupancyGeo, $scope.averageRentGeo, $scope.relativePerformanceGeo, chartInformation, $scope.savedPARReport.Name,
        $scope.pins.comps, $scope.pins.hospitals, $scope.pins.constructionInventory, $scope.report.transactions, bingMap.getCenter().latitude, bingMap.getCenter().longitude)
        .then(function(data) {
          var excelFile = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          });
          var fileName = 'NICMAP_PAR_' + $scope.savedPARReport.Name + '.xlsx';
          SpinnerService.stop();
          FileSaver.saveAs(excelFile, fileName);
        }, function(err) {
          // console.log(err);
        });
    }

    function fieldsForCompAndGeo(seriesCode, prefix) {
      return {
        geoField: prefix + seriesCode,
        compField: prefix + 'Comp' + seriesCode,
        benchField: prefix + 'Benchmark' + seriesCode
      }
    }

    function getSeries(fields, axis, geoName, marketName, dataSource) {
      if(!geoName) {
        geoName = ""
      }
      if (marketName){
        marketName += " Markets";
      }

      return [{
        style: "smooth",
        markers: {
          visible: false
        },
        field: fields.geoField,
        color: '#AF2027',
        name: geoName,
        axis: axis,
        data: dataSource
      }, {
        style: "smooth",
        markers: {
          visible: false
        },
        dashType: "dot",
        field: fields.compField,
        color: '#142f53',
        name: 'Comp Set',
        axis: axis,
        data: dataSource
      }, {
        style: "smooth",
        markers: {
          visible: false
        },
        field: fields.benchField,
        color: '#959595',
        name: marketName,
        axis: axis,
        data: dataSource
      }];
    }

    function xAxisLabels(kendoDataItem) {
      return KendoTemplateFunctions.yearLabels(kendoDataItem, $scope.rpiDataSource.length);
    }

    function occupancySeriesWithSeriesCode(seriesCode) {
      var fields = fieldsForCompAndGeo(seriesCode, 'occupancyForChart');
      return getSeries(fields, 'percentageAxis', null, null, $scope.occupancyDataSource);
    }

    function rentSeriesWithSeriesCode(seriesCode) {
      var fields = fieldsForCompAndGeo(seriesCode, 'averageRentForChart');
      return getSeries(fields, 'currencyAxis', null, null, $scope.rentDataSource);
    }

    function rpiSeriesWithSeriesCode(seriesCode) {
      var fields = fieldsForCompAndGeo(seriesCode, 'relativePerformanceForChart');
      return getSeries(fields, 'currencyAxis', null, null, $scope.rpiDataSource);
    }

    function onGeoChanged(compSetSegments, funcOnEnd, funcOnStart) {
      var geoChanged = function(countyOrMetro, oldCountyOrMetro) {
        if(!countyOrMetro.key) {
          return;
        }

        if(oldCountyOrMetro) {
          if(oldCountyOrMetro.isCbsa === countyOrMetro.isCbsa && oldCountyOrMetro.key == countyOrMetro.key) {
            return; //triggered for same CBSA, hapens when watch initially loaded
          }
        }

        if(funcOnStart) {
          funcOnStart();
        }

        SpinnerService.start();

        $scope.countyOrMetro = countyOrMetro;
        $scope.funcOnEnd = funcOnEnd;
        var getGeoDataOccupancy = PropertyAdvisorService
          .getGeoData($stateParams.id, $scope.countyOrMetro)
          .then(function(rsp) {
            //console.log(rsp);
          }, function(rspErr) {
            //console.log(rspErr);
          });

        return getGeoDataOccupancy;
      }

      return geoChanged;
    }

    SignalRService.parGetCbsaOrCountyData('parCbsaOrCountyData', $scope, function(cbsaOrCountyData, reportId) {
      if(reportId != $stateParams.id) return;
      var fnCombineData = function(collectionToMap, txtToInsertInMiddle) {
        return _.map(collectionToMap, function(segment) {
          var objToReturn = {};
          objToReturn["averageRentForChart" + txtToInsertInMiddle + "SH"] = segment.averageRentForChartSH;
          objToReturn["averageRentForChart" + txtToInsertInMiddle + "MC"] = segment.averageRentForChartMC;
          objToReturn["averageRentForChart" + txtToInsertInMiddle + "IL"] = segment.averageRentForChartIL;
          objToReturn["averageRentForChart" + txtToInsertInMiddle + "AL"] = segment.averageRentForChartAL;
          objToReturn["occupancyForChart" + txtToInsertInMiddle + "SH"] = segment.occupancyForChartSH;
          objToReturn["occupancyForChart" + txtToInsertInMiddle + "MC"] = segment.occupancyForChartMC;
          objToReturn["occupancyForChart" + txtToInsertInMiddle + "IL"] = segment.occupancyForChartIL;
          objToReturn["occupancyForChart" + txtToInsertInMiddle + "AL"] = segment.occupancyForChartAL;
          objToReturn["occupancyForChart" + txtToInsertInMiddle + "NC"] = segment.occupancyForChartNC;
          objToReturn["relativePerformanceForChart" + txtToInsertInMiddle + "SH"] = segment.relativePerformanceForChartSH;
          objToReturn["relativePerformanceForChart" + txtToInsertInMiddle + "MC"] = segment.relativePerformanceForChartMC;
          objToReturn["relativePerformanceForChart" + txtToInsertInMiddle + "IL"] = segment.relativePerformanceForChartIL;
          objToReturn["relativePerformanceForChart" + txtToInsertInMiddle + "AL"] = segment.relativePerformanceForChartAL;
          objToReturn["relativePerformanceForChart" + txtToInsertInMiddle + "NC"] = segment.relativePerformanceForChartNC;
          objToReturn["quarter"] = segment.quarter;
          objToReturn["sortIndex"] = segment.sortIndex;
          return objToReturn;
        });
      }

      var data = JSON.parse(cbsaOrCountyData.value);
      var combineGeoBenchmark = _.union(data.selectedGeoData, fnCombineData(data.marketTypeData, 'Benchmark'));
      var flat = _.union(fnCombineData($scope.report.segmentsByQuarter, 'Comp'), combineGeoBenchmark);
      //join new dataSource with old dataSource because of gaps in data. Protection? Your not gauranteed to have 1 record from each data source for each quarter
      var joinedDataSource = _.chain(flat)
        .groupBy('sortIndex')
        .map(function(grouping) {
          if(grouping.length == 1) {
            return grouping[0];
          }
          grouping = _.assign(grouping[0], grouping[1], grouping[2]);
          return grouping;
        })
        .sortBy('sortIndex')
        .value();

      SpinnerService.stop();

      $scope.funcOnEnd(joinedDataSource, $scope.countyOrMetro);

      //If coming from recent activity on homepage, call correct export
      if($stateParams.exportType && isPageLoad) {
        // only go here uplon page load
        recentActivityExport($stateParams.exportType);
      }
      isPageLoad = false;
      $scope.$apply();
    });

    SignalRService.parGetCbsaOrCountyDataWError('parCbsaOrCountyDataError', $scope, function(cbsaOrCountyDataError, reportId) {
      if(reportId != $stateParams.id) return;
      if(cbsaOrCountyDataError.value) {
        var errorMsg = JSON.parse(cbsaOrCountyDataError.value);
        PopupHandlerService.openSimplePopup("Error occured (" + errorMsg.statusCode + "): " + errorMsg.value);
      }
      SpinnerService.stop();
      $scope.$apply();
    });

    function rentStart() {
      $scope.rentDataSource = [];
      if(!isPageLoad) {
        logDropdownChange('Segment Average Rent Trends');
      }
    }

    function rpiStart() {
      $scope.rpiDataSource = [];
      if(!isPageLoad) {
        logDropdownChange('Segment Relative Performance Indicator Trends');
      }
    }

    function occupancyStart() {
      $scope.occupancyDataSource = [];
      if(!isPageLoad) {
        logDropdownChange('Segment Occupancy Trends');
      }
    }

    function allDone(joinedDataSource, countyOrMetro) {
      occupancyEnd(joinedDataSource, countyOrMetro);
      rentEnd(joinedDataSource, countyOrMetro);
      rpiEnd(joinedDataSource, countyOrMetro);
    }

    function rentEnd(joinedDataSource, countyOrMetro) {
      $scope.rentDataSource = joinedDataSource;
      var metroName = countyOrMetro.name;
      var marketName = countyOrMetro.marketType;

      _.forEach(['IL', 'AL', 'MC', 'SH'], function(seriesCode) {
        setChartOptions(metroName, marketName, 'averageRent', seriesCode, joinedDataSource, 'currencyAxis');
      });
    }

    function rpiEnd(joinedDataSource, countyOrMetro) {
      $scope.rpiDataSource = joinedDataSource;
      var metroName = countyOrMetro.name;
      var marketName = countyOrMetro.marketType;

      _.forEach(['IL', 'AL', 'MC', 'SH', 'NC'], function(seriesCode) {
        setChartOptions(metroName, marketName, 'relativePerformance', seriesCode, joinedDataSource, 'currencyAxis');
      });
    }

    function occupancyEnd(joinedDataSource, countyOrMetro) {
      $scope.occupancyDataSource = joinedDataSource;
      var metroName = countyOrMetro.name;
      var marketName = countyOrMetro.marketType;

      _.forEach(['IL', 'AL', 'MC', 'SH', 'NC'], function(seriesCode) {
        setChartOptions(metroName, marketName, 'occupancy', seriesCode, joinedDataSource, 'percentageAxis');
      });
    }

    function setChartOptions(geoName, marketName, fieldName, seriesCode, dataSource, axis) {
      var chart = $scope[fieldName + 'Chart' + seriesCode];
      if(!chart) {
        return; //helpful for testing
      }
      var fields = fieldsForCompAndGeo(seriesCode, fieldName + 'ForChart');

      var allAreFalsy = _.every(dataSource, function(quartersValues) {
        return !quartersValues[fields.geoField] 
          && !quartersValues[fields.compField]
          && !quartersValues[fields.benchField];
      });

      var categories = _.map(dataSource, function(eachPoint) {
        return eachPoint.quarter;
      });

      var scopeVariableForHideChart = fieldName + 'Hide' + seriesCode;
      if(allAreFalsy) {
        $scope[scopeVariableForHideChart] = true;
      } else {
        $scope[scopeVariableForHideChart] = false;
        chart.setOptions({
          series: getSeries(fields, axis, geoName, marketName, dataSource),
          categoryAxis: { categories: categories }
        });
      }
    }

    function exportOccupancy() {
      PropertyAdvisorService.logExportOccupancy($stateParams.id);
      exportChartData("PAR Segment Occupancy Data", "occupancy", $scope.occupancyGeo);
    }

    function exportAverageRent() {
      PropertyAdvisorService.logExportAverageRent($stateParams.id);
      exportChartData("PAR Segment Rent Data", "averageRent", $scope.averageRentGeo);
    }

    function exportRelativePerformance() {
      PropertyAdvisorService.logExportRpi($stateParams.id);
      exportChartData("PAR Segment RPI Data", "relativePerformance", $scope.relativePerformanceGeo);
    }

    function exportChartData(title, geoSection, countyOrMetro) {
      SpinnerService.start();
      PropertyAdvisorService
        .exportChartsData($stateParams.id, geoSection, countyOrMetro, title)
        .then(function(data) {
          var excelFile = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          });
          var fileName = 'NICMAP_' + title.replace(/ /g, '_') + '.xlsx';
          SpinnerService.stop();
          FileSaver.saveAs(excelFile, fileName);
        }, function(err) {
          // console.log(err);
        });
    }

    var bingMap;

    function onMapReady(map) {
      bingMap = map;
      BingGeoDataService.setupSdsManager();
      Microsoft.Maps.Events.addHandler(map, 'mousewheel', function(e) {
        if(e.targetType == 'map') {
          e.handled = true;
        }
      });
    }

    function wireInCbsaAndCountyDropdowns(countiesAndMetros, segmentsByQuarter) {
      if(countiesAndMetros && countiesAndMetros.length) {
        var isChangedFunc = onGeoChanged(segmentsByQuarter, allDone);
        isChangedFunc(countiesAndMetros[0]);

        $scope.occupancyGeo = countiesAndMetros[0];
        $scope.averageRentGeo = countiesAndMetros[0];
        $scope.relativePerformanceGeo = countiesAndMetros[0];

        $scope.$watch("occupancyGeo", onGeoChanged(segmentsByQuarter, occupancyEnd, occupancyStart));
        $scope.$watch("averageRentGeo", onGeoChanged(segmentsByQuarter, rentEnd, rentStart));
        $scope.$watch("relativePerformanceGeo", onGeoChanged(segmentsByQuarter, rpiEnd, rpiStart));

        getFirstGeoData.resolve(countiesAndMetros);
      } else {
        getFirstGeoData.resolve([]); //countiesAndMetros is null or empty
      }
    }

    function dataSourceForUnitCharts() {
      var cacheCheck = 'none';

      //Need to return the same reference to the datasource or else angular gets in an infinate digest loop.
      if(allUnitChartsDataSources[cacheCheck]) {
        return allUnitChartsDataSources[cacheCheck];
      }

      var dataSourceOptions = {
        transport: {
          read: function(options) {
            options.success($scope.report.unitTypesBySegment);
            return;
          }
        }
      };

      allUnitChartsDataSources[cacheCheck] = new kendo.data.DataSource(dataSourceOptions);

      return allUnitChartsDataSources[cacheCheck];
    };

    var allUnitChartsDataSources;

    function isLoading() {
      if(SpinnerService.getLoadingCount() > 0) {
        return true;
      }
      return false;
    }

    function exportComps() {
      SpinnerService.start();
      PropertyAdvisorService.logExportComps($stateParams.id);
      PropertyAdvisorService.exportComps($stateParams.id, new Date()).then(function(data) {
        var excelFile = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        var fileName = 'NICMAP_PAR_Comp_Set_Download.xlsx';
        SpinnerService.stop();
        FileSaver.saveAs(excelFile, fileName);
      });
    };

    function exportTransactions() {
      SpinnerService.start();
      PropertyAdvisorService.logExportTransactions($stateParams.id);
      PropertyAdvisorService.exportTransactions($stateParams.id, new Date()).then(function(data) {
        var excelFile = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        var fileName = 'NICMAP_PAR_Transaction_List.xlsx';
        SpinnerService.stop();
        FileSaver.saveAs(excelFile, fileName);
      });
    }
    var savePopupModalInstance;

    function isExistingName(newName) {
      var exists = false;
      if(!newName) {
        return false;
      }
      _.forOwn($scope.savedPARReports, function(v, k) {
        if(angular.lowercase(v.name) == angular.lowercase(newName)) {
          exists = true;
          return false;
        }
      });
      return exists;
    }

    function reportNameEnterClick(newName) {
      if((!newName) || (newName.length > 50)) {
        return false;
      }
    }

    function saveParReport() {
      PropertyAdvisorService
        .savePARReport($stateParams.id, $scope.savedPARReport.Name, $scope.savedPARReport.Id)
        .then(function(rsp) {
          var content = 'The <b>' + $scope.savedPARReport.Name + '</b> report was successfully ' + (isSavedParReport ? 'updated.' : 'saved.');
          isSavedParReport = true;
          PopupHandlerService.openSimplePopup(content);
        }, function(err) {
          PopupHandlerService.openSimplePopup('Saving of the PAR Report failed.');
        });
    }

    function saveReportClick() {
      if($scope.savedPARReport.Name.length == 0) {
        $scope.invalidReportName = true;
        $scope.shouldBeFocused = true;
        PopupHandlerService.openSimplePopup("A report name is required. Please enter a Report Name to continue.");
        return;
      }
      if($scope.savedPARReport.Name.indexOf('/') != -1 || $scope.savedPARReport.Name.indexOf('\\') != -1) {
        $window.alert("slashed are not allowed in report name. Nothing will be saved.");
        return;
      }

      popupController.$inject = ['$scope', 'currentPARName'];

      function popupController($scope, currentPARName) {
        $scope.isExistingName = isExistingName;
        $scope.filterNameEnterClick = reportNameEnterClick;
        $scope.saveType = 'report';
        $scope.placeholderText = 'Report Name';
        if(currentPARName.length > 0) {
          $scope.newFilterName = currentPARName;
        }
      };

      if(isExistingName($scope.savedPARReport.Name)) {
        savePopupModalInstance = $uibModal.open({
          templateUrl: "app/partials/duplicateNamePopup.html",
          controller: popupController,
          backdrop: 'static',
          resolve: {
            currentPARName: function() {
              return $scope.savedPARReport.Name;
            }
          }
        });
        savePopupModalInstance.result.then(function(isSaveReport) {
          if(isSaveReport) {
            saveParReport();
          }
        });
      } else {
        saveParReport();
      }
    }

    function recentActivityExport(exportType) {
      if(/\sComp\sSet\sProperties\sExcel/.test(exportType)) {
        exportComps();
      } else if(/\sOccupancy\sTrends\sExcel/.test(exportType)) {
        exportOccupancy();
      } else if(/\sAverage\sRent\sTrends\sExcel/.test(exportType)) {
        exportAverageRent();
      } else if(/\sRPI\sExcel/.test(exportType)) {
        exportRelativePerformance();
      } else if(/\sClosed\sTransactions\sExcel/.test(exportType)) {
        exportTransactions();
      } else if(/\sReport\sPDF/.test(exportType)) {
        parToPDF();
      }
    }

    function logDropdownChange(message) {
      AuditService.logEvent(knownEventConstant.parSegmentDropdownChanged.id, message, knownProductConstant.local);
    }

    function togglePushPins(pins, newValue) {
      _.each(pins, function(value, index) {
        value.visible = newValue;
      });

      var pinsOnMap = [];
      if($scope.pins.showComps) {
        pinsOnMap.push($scope.pins.comps);
      }
      if($scope.pins.showConstructionInventory) {
        pinsOnMap.push($scope.pins.constructionInventory);
      }
      if($scope.pins.showHospitals) {
        pinsOnMap.push($scope.pins.hospitals);
      }
      if($scope.pins.showClosedTransactions) {
        pinsOnMap.push($scope.pins.closedTransactions);
      }
      if(pinsOnMap.length) {
        BingMapUtils.boundsFromPushpins(bingMap, _.flattenDeep(pinsOnMap));
      }
    };

    function toggleMetroArea() {
      if(!$scope.showMetroArea) {
        $scope.cbsaArea.sections = [];
        return;
      }
      if(cbsaAreaLocationCache) {
        $scope.cbsaArea.sections = angular.copy(cbsaAreaLocationCache);
      } else {
        var cbsas = _.map(_.filter($scope.report.cbsasAndCounties, { 'isCbsa': true }), 'key');
        GeoDataService
          .getMultipleCBSAGeoData(cbsas)
          .then(function(data) {
            if(data && data.length > 0) {
              $scope.cbsaArea.sections = data;
              cbsaAreaLocationCache = angular.copy(data);
            }
          }, function(err) {
            // Do Nothing - No Shape to Render.
          })
          .finally(function() {
            // nothing to do
          });
      }
    };

    function toggleCounties() {
      if($scope.showCounties && BingMapUtils.getCurrentZoom(bingMap) >= 9) {
        SpinnerService.start();
        BingGeoDataService
          .getAllCountiesBoundariesInBbox(bingMap, true)
          .then(function(bingPolygons) {
            $scope.coveredCountiesPolygons = bingPolygons;
            SpinnerService.stop();
          }, function(err) {
            console.log(err);
            SpinnerService.stop();
          });
      } else
        $scope.coveredCountiesPolygons = [];
    };

    function onClickShowComps(pins, showComps) {
      togglePushPins(pins, showComps);
    }

    function onClickShowConstructionInventory(pins, showConstructionInventory) {
      togglePushPins(pins, showConstructionInventory);
    }

    function onClickShowHospitals(pins, showHospitals) {
      togglePushPins(pins, showHospitals);
    }

    function onClickShowClosedTransactions(pins, showClosedTransactions) {
      togglePushPins(pins, showClosedTransactions);
    }
  }
})();
