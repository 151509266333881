(function() {
  "use strict";

  app.factory("AdminPortfolioService", AdminPortfolioService);

  AdminPortfolioService.$inject = ["$http", "$q", "apiSettings"];

  function AdminPortfolioService($http, $q, apiSettings) {
    return {
      getPortfolioData: getPortfolioData,
      savePortfolioDetails: savePortfolioDetails,
      properties: properties,
      addProperties: addProperties,
      searchNewProperties: searchNewProperties,
      searchNewPropertiesNoComps: searchNewPropertiesNoComps,
      addCommaProperties: addCommaProperties,
      deleteSubjectProperty: deleteSubjectProperty,
      getSubscriptionsWithPortfolioAccess: getSubscriptionsWithPortfolioAccess,
      getPortfolioTypes: getPortfolioTypes,
      getSubscriptionPortfolios: getSubscriptionPortfolios,
      createNewPortfolio: createNewPortfolio,
      refreshPortfolio: refreshPortfolio
    };

    function adminPortfolioUrl(portfolioId) {
      return apiSettings.url + '/AdminPortfolios/' + portfolioId;
    }

    function searchNewProperties(compFilters, searchFilter) {
      var deferred = $q.defer();
      if(!searchFilter.cbsaCode && !searchFilter.countyId && !searchFilter.stakeHolderName && !searchFilter.zipCode && !searchFilter.propertyName) {
        deferred.resolve({properties:[]});
        return deferred.promise;
      }
      if(searchFilter.zipCode) {
        if(searchFilter.zipCode.length != 5 || !/\d{5}/.test(searchFilter.zipCode)) {
          deferred.reject({
            data: "Zip Code must be 5 digits"
          });
          return deferred.promise;
        }
      }

      var compsForURL = $.param(compFilters);
      var url = apiSettings.url + '/AdminPortfolios/PropertySearch?' + compsForURL;

      $http.post(url, searchFilter)
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });

      return deferred.promise;
    }

    function searchNewPropertiesNoComps(searchFilter) {
      var deferred = $q.defer();
      if(!searchFilter.cbsaCode && !searchFilter.countyId && !searchFilter.stakeHolderName && !searchFilter.zipCode && !searchFilter.propertyName) {
        deferred.resolve({properties:[]});
        return deferred.promise;
      }
      if(searchFilter.zipCode) {
        if(searchFilter.zipCode.length != 5 || !/\d{5}/.test(searchFilter.zipCode)) {
          deferred.reject({
            data: "Zip Code must be 5 digits"
          });
          return deferred.promise;
        }
      }
      var url = apiSettings.url + '/AdminPortfolios/PropertySearchNoComps';

      $http.post(url, searchFilter)
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });

      return deferred.promise;
    }
    function addCommaProperties(compFilters, portfolioId, commaDelimitedProperties) {
      //PropertySearch(int portfolioId, int distance, int minComps, int maxComps, [FromBody] List<int> propertyIds)
      var deferred = $q.defer();

      if(!commaDelimitedProperties) {
        deferred.reject();
        return deferred.promise;
      }
      var propertyIds = commaDelimitedProperties.split(",");

      if(!_.every(propertyIds, function(unTrimmedPropertyId) {
          var propertyId = unTrimmedPropertyId.trim();
          if(!/^[0-9]+$/.test(propertyId)) {
            return false;
          }
          var isInt = parseInt(propertyId);
          return isFinite(isInt);
        })) {
        deferred.reject();
        return deferred.promise;
      }

      var compsForURL = $.param(compFilters);

      var url = adminPortfolioUrl(portfolioId) + "/AddProperties?" + compsForURL;;
      $http.post(url, propertyIds)
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });

      return deferred.promise;
    }

    function addProperties(portfolioId, propertyList) {
      var deferred = $q.defer();
      $http.post(adminPortfolioUrl(portfolioId) + '/Properties', propertyList)
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });

      return deferred.promise;
    }

    function properties(portfolioId) {
      var defer = $q.defer();

      $http
        .get(adminPortfolioUrl(portfolioId) + '/Properties')
        .then(function(rsp) {
          defer.resolve(rsp.data);
        }, function(err) {
          defer.reject(err);
        });

      return defer.promise;
    }

    function deleteSubjectProperty(portfolioId, propertyId) {
      var defer = $q.defer();
      $http.delete(adminPortfolioUrl(portfolioId) + '/' + propertyId)
        .then(function(rsp) {
          defer.resolve(rsp.data);
        }, function(err) {
          defer.reject(err);

        });
      return defer.promise;
    }

    function getPortfolioData(portfolioId) {
      var defer = $q.defer();
      $http
        .get(adminPortfolioUrl(portfolioId))
        .then(function(rsp) {
          if(rsp.data) {
            if(rsp.data.dateModified) {
              rsp.data.dateModified = rsp.data.dateModified + "Z";
            }
            if(rsp.data.lastRefreshed) {
              rsp.data.lastRefreshed = rsp.data.lastRefreshed + "Z";
            }
          }
          defer.resolve(rsp.data);
        }, function(err) {
          defer.reject(err);
        });
      return defer.promise;
    };

    function savePortfolioDetails(portfolio) {
      var defer = $q.defer();
      $http
        .put(adminPortfolioUrl(portfolio.id), portfolio)
        .then(function(rsp) {
          defer.resolve(rsp.data);
        }, function(err) {
          defer.reject();
        });

      return defer.promise;
    };

    function getSubscriptionsWithPortfolioAccess() {
      var defer = $q.defer();

      $http
        .get(apiSettings.url + '/AdminPortfolios/SubsWithAccess')
        .then(function(rsp) {
          defer.resolve(rsp.data);
        }, function(err) {
          defer.reject(err);
        });

      return defer.promise;
    };

    function getPortfolioTypes() {
      var defer = $q.defer();

      $http
        .get(apiSettings.url + '/AdminPortfolios/PortfolioTypes')
        .then(function(rsp) {
          defer.resolve(rsp.data);
        }, function(err) {
          defer.reject(err);
        });

      return defer.promise;
    };

    function getSubscriptionPortfolios(subscriptionId) { 
      var defer = $q.defer(); 
      $http.get(adminPortfolioUrl(subscriptionId) + "/false") 
        .then(function(rsp) { 
            defer.resolve(rsp.data); 
          }, function(err) { 
            defer.reject(err); 
 
          }); 
      return defer.promise; 
    };

    function createNewPortfolio(dataToSave) {
      var defer = $q.defer();
      $http
        .post(apiSettings.url + '/AdminPortfolios/newPortfolio', dataToSave)
        .then(function(rsp) { 
          defer.resolve(rsp.data); 
        }, function(err) { 
          defer.reject(err); 
   
        }); 
      return defer.promise; 
    };

    function refreshPortfolio(portfolioId) {
      var defer = $q.defer();
      var uri = apiSettings.url + '/AdminPortfolios/refresh/' + portfolioId;

      $http
        .get(uri)
        .then(function (rsp) {
          defer.resolve(rsp);
        }, function (err) {
          defer.reject(err); 
        }); 
      return defer.promise; 
    }
  }
})();