(function () {
  "use strict";
  app.controller('ReportsController', ReportsController);

  ReportsController.$inject = ['$rootScope', '$scope', '$state', '$q', '$sce', '$compile', '$timeout', '$anchorScroll', 'FileSaver', 'Blob', 'AuthService',
    'AuditService', 'ReportsService', 'SignalRService', 'SpinnerService', 'PopupHandlerService', 'reportCategoryConstant', 'knownEventConstant', 'DataAttributionService'];

  function ReportsController($rootScope, $scope, $state, $q, $sce, $compile, $timeout, $anchorScroll, FileSaver, Blob, authService,
    AuditService, ReportsService, SignalRService, SpinnerService, PopupHandlerService,  reportCategoryConstant, knownEventConstant, DataAttributionService) {
    $rootScope.hideLeftNav = true;
    SpinnerService.start();

    $scope.reportCount = reportCount;
    $scope.showGeographyDropdowns = showGeographyDropdowns;
    $scope.showDownloadLinks = showDownloadLinks;
    $scope.showDownloadButton = showDownloadButton;
    $scope.downloadClick = downloadClick;
    $scope.noReportAccess = noReportAccess;
    $scope.getExcelIcon = getExcelIcon;
    $scope.setHrefLink = setHrefLink;
    $scope.categories = [];
    $scope.content = "Your subscription does not include access to this product.";
    $scope.search = search;
    $scope.query = null;
    $scope.AttributionHtml = "";
    $scope.getDataAttribution = getDataAttribution;
    $scope.isDisabled = isDisabled;

    var regions = [];
    var states = [];
    var pointRightStates = [];
    var markets = [];
    var metros = [];
    var counties = [];
    var reportsGroupedByCategory = [];
    var flattenedReports = [];
    var contactusLink = "<a ui-sref=\"contact\">contact us</a>";
    var trusted = {};
    var excelFileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    var pdfFileType = "application/pdf";
    var productCodes = {
      cbsaOrCounty: "trends",
      mhcData: "mhcdata",
      propertyInventory: "supplydownloadfull",
      propSales: "trans",
      analyst: "analyst",
      bls: "bls",
      mSupply: "msupply",
      mReport: "mreport",
      rankings: "rankings",
      pointRight: "pointright",
      rateTiers: "ratetiersxls",
      local: "local",
      intraQuarterlyData: 'intraquarterlydata',
      intraQuarterlyPropertyInventory: 'iqpropertyinventory'
    };
    var cbsaTrendsSubCode = {
      propertyType: "Property Type",
      segmentType: "Segment Type",
      communityType: "Community Type"
    };
    var countyTrendsSubCode = {
      propertyType: "Property Type",
      segmentType: "Segment Type"
    };

    var timeoutPromise;
    $scope.$watch("query", function(newVal, oldVal) {
      $timeout.cancel(timeoutPromise);  //does nothing, if timeout already done
      timeoutPromise = $timeout(function () {   //Set timeout
        if (!newVal) return;
        AuditService.logEvent(knownEventConstant.reportsSearchText.id, 
          knownEventConstant.reportsSearchText.message + " Text: " + newVal);
      }, 2000);
    });

    function fileProxyDownload(name, eventId) {
      var type = name.indexOf('.xls') == -1
        ? pdfFileType
        : excelFileType;

      SpinnerService.start();
      var params = {
        fileName: name,
        fromWhere: "Reports",
        userEventId: eventId
      };
      ReportsService
        .fileProxyDownload(params)
        .then(function (data) {
          streamReport(data, type, name);
        }, reportHasError);
    } 

    function reportHasError(message, showRawError) {
      SpinnerService.stop();
      $scope.errorMessage = showRawError ? message : "There was a problem generating your report, please try again.";
    }

    function streamReport(data, type, name) {
      var file = new Blob([data], { type: type });
      SpinnerService.stop();
      FileSaver.saveAs(file, name.replace(/ /g, '_'));
    }

    function updateMetros(report, regionId, selectedMarket) {
      if (!report.cbsas || !report.cbsas.selectedCbsa) return;

      report.cbsas = _.cloneDeep(report.availableCbsas.filter(function (cbsa) {
        if (!regionId) { return true; }
        return cbsa.regionId === regionId || cbsa.id.toString() == '0';
      }).filter(function (cbsa) {
        if (!selectedMarket || selectedMarket.name == 'All Markets') { return true; }
        // Handle Primary and Secondary option
        if (selectedMarket.name == 'Primary and Secondary') {
          return cbsa.marketType == 'Primary' || cbsa.marketType == 'Secondary' || cbsa.id.toString() == '0';
        }
        return cbsa.marketType == selectedMarket.name || cbsa.id.toString() == '0';
      }));

      report.cbsas.selectedCbsa = report.cbsas[0];
    };

    function updateCounties(report, cbsaCode) {
      if (!report.counties || !report.counties.selectedCounty) return;

      return ReportsService.getCounties(cbsaCode)
        .then(function (rsp) {
          report.counties = rsp;
          report.counties.selectedCounty = report.counties[0];
        });
    };

    function onMarketSelectionChange(newVal, oldVal, report) {
      if (!newVal || !oldVal) return;
      if (angular.equals(newVal, oldVal)) return;

      if (report.regions && report.regions.selectedRegion) {
        report.regions.selectedRegion = report.regions[0];
      }
      updateMetros(report, null, newVal);
    }

    function onRegionSelectionChange(newVal, oldVal, report) {
      if (!newVal || !oldVal) return;
      if (angular.equals(newVal, oldVal)) return;

      updateMetros(report, newVal.id, report.markets.selectedMarket);
    }

    function onCbsaSelectionChange(newVal, oldVal, report) {
      if (!newVal || !oldVal) return;
      if (angular.equals(newVal, oldVal)) return;
      // update counties
      if (newVal.id === "" || newVal.id === "0") {
        // either "Select Metro" or "All Metros" selected... what to do??
        updateCounties(report, "0");
      } else {
        // find matching id in the downloadableFiles and assign it to the report.downloadableFiles.selected
        for (var i = 0; i <= report.downloadableFiles.length - 1; i++) {
          var file = report.downloadableFiles[i];
          if (file.cbsaCode && file.cbsaCode.toString() === newVal.id) {
            report.downloadableFiles.selected = file.files;
            break;
          }
        }
        updateCounties(report, newVal.id);
      }
    }

    SignalRService.reportsSilentRefresh('reportsSilentRefresh', $scope, function (subscriptionId) {
      if (!subscriptionId) return;
      var identity = authService.identity;
      var currentSubscription = identity.currentSubscription();
      if (currentSubscription.id.toString() === subscriptionId.toString()) {
        // only refresh if the subscription is the same as the one that was updated by Admin
        SpinnerService.start();
        ReportsService.clearCache();
        init();
      }
    });

    function wireupGeographySectionForReport(report, categoryIdx, reportIdx, markets, regions, metros, counties, states) {
      var watchPrefix = 'categories[' + categoryIdx + '].reports[' + reportIdx + ']';
      if (report.geographyFilterWithMarkets) {
        var reportMarkets = angular.copy(markets);
        switch(report.productCode.toLowerCase()){
          case productCodes.cbsaOrCounty:
            reportMarkets = _.chain(reportMarkets).filter(function(market){
              return (market && (market.id != 0));
            }).value();
            break;
        }
        report.markets = reportMarkets;
        report.markets.selectedMarket = report.markets[0];
        $scope.$watch(watchPrefix + '.markets.selectedMarket', function (newVal, oldVal) {
          onMarketSelectionChange(newVal, oldVal, report);
        }, true);
      }
      if (report.geographyFilterWithCBSAs) {
        var reportCbsas = angular.copy(metros);
        switch (report.productCode.toLowerCase()) {
          case productCodes.mSupply:
            // We need to remove the 'Additional Markets' from here
            // get the cbsa and name combination from the files
            var nonAdditionalCbsas = _.chain(report.downloadableFiles)
              .map(function (file) {
                return _.find(reportCbsas, function (cbsa) { return cbsa && cbsa.id.toString() === file.cbsaCode.toString(); });
              })
              .sortBy(['name'], ['asc'])
              .value();
            reportCbsas = angular.copy(nonAdditionalCbsas);
          case productCodes.mReport:
            // remove "All Metros"
            reportCbsas = _.chain(reportCbsas)
            .sortBy('name')
            .filter(function(cbsa){
              return cbsa && cbsa.id.toString() != "0";
            })
            .value();
          case productCodes.propSales:
            //Check for metros other than options ALL and Select 
            if(_.find(reportCbsas, function(cbsa) { return cbsa.id.toString() != "0" && cbsa.id.toString() != "";})){
              reportCbsas.unshift({ id: "", name: "Select Metro" });
            }
            else{
              reportCbsas = [{ id: "", name: "Select Metro" }];
            }
            break;
          case productCodes.rateTiers:
            reportCbsas = _.chain(reportCbsas)
            .filter(function(cbsa){
              return (cbsa && (cbsa.marketType == "Primary") || (cbsa.id.toString() == "0"));
            })
            .value();
            break;
        }
        report.availableCbsas = reportCbsas; // placeholder to store filtered list
        report.cbsas = reportCbsas;
        report.cbsas.selectedCbsa = report.cbsas[0];
        $scope.$watch(watchPrefix + '.cbsas.selectedCbsa', function (newVal, oldVal) {
          onCbsaSelectionChange(newVal, oldVal, report);
        }, true);
      }
      if (report.geographyFilterWithRegions) {
        var reportRegions = angular.copy(regions);
        report.regions = reportRegions;
        report.regions.selectedRegion = report.regions[0];
        $scope.$watch(watchPrefix + '.regions.selectedRegion', function (newVal, oldVal) {
          onRegionSelectionChange(newVal, oldVal, report);
        }, true);
      }
      if (report.geographyFilterWithCounties) {
        var reportCounties = angular.copy(counties);
        report.counties = reportCounties;
        report.counties.selectedCounty = report.counties[0];
      }
      if (report.geographyFilterWithStates) {
        var reportStates = report.productCode.toLowerCase() == productCodes.pointRight
          ? angular.copy(pointRightStates)
          : angular.copy(states);

        switch (report.productCode.toLowerCase()) {
          case productCodes.pointRight:
          case productCodes.bls:
            // insert "Select State"
            reportStates.unshift({ id: "", name: "Select State" });
            break;
        }
        report.states = reportStates;
        report.states.selectedState = report.states[0];
      }
    }

    function init() {
      ReportsService.preCacheReports();
      $q.all([
        ReportsService.all(),
        ReportsService.getDynamicReportFileNames(),
        ReportsService.getRegionsAndStates(),
        ReportsService.getMarkets(),
        ReportsService.getFilteredMetros("All Markets", 0),
        ReportsService.getCounties('0')
      ]).then(function (responses) {
        $scope.dynamicReportFileNames = responses[1];
        var regionsAndStates = responses[2];
        regions = regionsAndStates.regions;
        states = regionsAndStates.states;
        pointRightStates = regionsAndStates.pointRightStates;
        markets = responses[3];
        metros = responses[4];
        counties = responses[5];
        flattenedReports = responses[0];
        reportsGroupedByCategory = _.groupBy(responses[0], 'category');
        getCategoriesWReports();
        if($state.params.directDownloadReport) {
          directDownload($state.params.directDownloadReport);
        }

        SpinnerService.stop();
      }, function (error) {
        // error
        SpinnerService.stop();
        PopupHandlerService.openSimplePopup("There was an error when trying to load the page. Please try again or contact us.");
      });
    } 

    function getCategoriesWReports() {
      $scope.categories = _.values(reportCategoryConstant); //[ { id: 10, name: "Batch Data Files" }, ..., { id: 30, name: "National Reports"]
      var queryTosearch = '';
      if ($scope.query && $scope.query != '') {
        queryTosearch = $scope.query.toLowerCase();
      }
      _.forEach($scope.categories, function (category, categoryIdx) {
        var reportsInCategory = reportsGroupedByCategory[category.id];
        var reportsToAttach = [];
        var reportIdx = 0;
        _.forEach(reportsInCategory, function (report) {
          if (queryTosearch != '') {
            // check if search query is part of any properties
            if (report.name.toLowerCase().indexOf(queryTosearch) == -1 &&
                report.descriptionText.toLowerCase().indexOf(queryTosearch) == -1 &&
                report.purposeText.toLowerCase().indexOf(queryTosearch) == -1) {
              // no match found in name, description, or purpose
              // check tags
              if (!report.tags || report.tags.length <= 0) return;
              var hasMatchingTag = _.find(report.tags, function (tag) {
                return tag.toLowerCase().indexOf(queryTosearch) !== -1;
              });
              if (!hasMatchingTag) {
                // no match found in tags either so no need to show the report
                return;
              }
            }
          }
          reportsToAttach.push(report);
          if (showGeographyDropdowns(report)) {
            wireupGeographySectionForReport(report, categoryIdx, reportIdx, markets, regions, metros, counties, states);
          }
          if (report.availableReportTypes) {
            report.selectedReport = _.find(report.availableReportTypes, function(o) { return o.hasProductAccess; });
          }
          if(report.downloadableFiles.length > 0)
            _.assign(report.downloadableFiles, { 'selected': report.downloadableFiles[0].files });
          reportIdx += 1;
        });
        _.assign(category, { 'reports': reportsToAttach, 'open': false });
      });
    }

    init();
    getDataAttribution();

    function getDataAttribution() {
      DataAttributionService.getAttributionHtml()
        .then(function (htmlString) {
          $scope.AttributionHtml = $sce.trustAsHtml(htmlString);
        })
        .catch(function (error) {
          console.error(error);
        });
    }
   
    function reportCount(category) {
      if (!category.reports) return 0;
      return category.reports.length;
    }

    function showGeographyDropdowns(report) {
      return report.geographyFilterWithMarkets 
        || report.geographyFilterWithRegions 
        || report.geographyFilterWithCBSAs 
        || report.geographyFilterWithStates 
        || report.geographyFilterWithCounties;
    }

    function showDownloadLinks(report) {
      var showDownloadRow = true;
      if (report.productCode) {
        switch (report.productCode.toLowerCase()) {
          case productCodes.propSales:
          case productCodes.mSupply:
          case productCodes.mReport:
            if (!report.cbsas.selectedCbsa || (report.cbsas.selectedCbsa && report.cbsas.selectedCbsa.id == "")) {
              showDownloadRow = false;
            }
            break;
          case productCodes.bls:
          case productCodes.pointRight:
            if (!report.states.selectedState || (report.states.selectedState && report.states.selectedState.id == "")) {
              showDownloadRow = false;
            }
            break;
        }
      }
      return showDownloadRow;
    }

    function showDownloadButton(report, file, buttonType) {
      if (!file.fileType) return false;
      var loweredFileType = file.fileType.toLowerCase();
      if (loweredFileType === buttonType ||
          loweredFileType === "data" && buttonType === "excel") {
        return true;
      }
      return false;
    }

    function directDownload(report) {
      var matchedReport = _.find(flattenedReports, { downloadableFiles: [{ files: [{ urlFriendlyFileName: report }] }] });
      var matchedFile = null;
      var idxForFile = null;
      if(matchedReport) {
        // returns array of length 1 with the matching file based on the urlFriendlyFileName
        matchedFile = _.filter(_.flatMap(matchedReport.downloadableFiles, 'files'), { urlFriendlyFileName: report })[0];
      } else if (/^actual-rates/i.test(report)) {
        // if actual-rates then handle this differently
        /*
          actual-rates/property-type/pdf
          actual-rates/property-type/excel
          actual-rates/segment-type/pdf
          actual-rates/segment-type/excel
        */
        var actualRatesReport = _.find(flattenedReports, { downloadableFiles: [{ files: [{ urlFriendlyFileName: 'actual-rates/' }] }] });
        var reportToMatch = report.replace('actual-rates/', '');
        _.each(actualRatesReport.availableReportTypes, function (art) {
          if (!art.hasProductAccess) return;
          _.each(art.downloadableFiles[0].files, function (artFile, idx) {
            if (artFile.urlFriendlyFileName == reportToMatch) {
              actualRatesReport.selectedReport = art;
              matchedReport = actualRatesReport;
              matchedFile = artFile;
              idxForFile = idx;
              return false;
            }
          });
          if (matchedFile) return false;
        });
      }
      if(matchedReport && matchedFile) {
        $scope.downloadClick(matchedReport, matchedFile, null, idxForFile);
      }
      $state.go('reports', {}, { location: 'replace' });
    }

    function downloadClick(report, file, $event, $index) {
      $scope.errorMessage = ''; // Clear any existing error displayed
      if($event){
        // When link clicked from UI, prevent the href of the anchor tag from running.
        $event.preventDefault();
      }
      if (!report.hasProductAccess || (report.selectedReport && !report.selectedReport.hasProductAccess)) {
        // fail safe in case someone gets rid of the 'disabled' class
        reportHasError("Your subscription does not include access to this product. Please contact us for further assistance.", true);
        return;
      }

      if (report.availableReportTypes && report.selectedReport) {
        if(!report.selectedReport.needToBeGenerated) {
          // we can not always assume an index of 0 for the files array since now a pdf and an excel can exist
          // in case of Actua rates report compared to CountyTrends and CBSATrends which were only excel.
          var idx = $index || 0;
          var fileToDownload = report.selectedReport.downloadableFiles[0].files[idx];
          fileProxyDownload(fileToDownload.fileName, fileToDownload.userEventId);
          return;
        }
      }

      var loweredProductCode = report && report.productCode ? report.productCode.toLowerCase() : null;
      var loweredFileType = file && file.fileType ? file.fileType.toLowerCase() : null;
      if (!report.needToBeGenerated ||
        (loweredProductCode == productCodes.intraQuarterlyData && loweredFileType == 'pdf')) {
        /*
          The report does not need to be generated.
          So we need to appropriately get the file that needs to be streamed back.
          */
        fileProxyDownload(file.fileName, file.userEventId);
      } else {
        /*
          The report needs to be generated based on either some selections or default settings.
          */
        if (!report.productCode && !report.selectedReport) {
          reportHasError("Report does not have a product code so can not be generated.");
          return;
        }

        var promise = null;
        switch (loweredProductCode) {
          // Batch Data Files
          case productCodes.cbsaOrCounty:
            if(report.name == "CBSA Trends"){
              switch (report.selectedReport.name) {
                case cbsaTrendsSubCode.propertyType:
                  promise = ReportsService.generateCbsaTrendsByPropertyTypeExcel();
                  break;
                case cbsaTrendsSubCode.segmentType:
                  promise = ReportsService.generateCbsaTrendsBySegmentTypeExcel();
                  break;
                case cbsaTrendsSubCode.communityType:
                  promise = ReportsService.generateCbsaTrendsByCommunityTypeExcel();
                  break;
              }
            }
            else if(report.name == "County Trends"){
                switch (report.selectedReport.name) {
                  case countyTrendsSubCode.propertyType:
                    promise = ReportsService.generateCountyTrendsByPropertyTypeExcel(report.markets.selectedMarket.id);
                    break;
                  case countyTrendsSubCode.segmentType:
                    promise = ReportsService.generateCountyTrendsBySegmentTypeExcel(report.markets.selectedMarket.id);
                    break;
                }
            }
            break;
          case productCodes.mhcData:
            var mhcMarket = report.markets.selectedMarket;
            var mhcRegion = report.regions.selectedRegion;
            var mhcCbsa = report.cbsas.selectedCbsa;
            promise = ReportsService.generateDodgeConstructionExcel(mhcMarket.id, mhcRegion.id, mhcCbsa.id);
            break;
          case productCodes.propertyInventory:
            promise = ReportsService.generatePropertyInventoryExcel();
            break;
          case productCodes.propSales:
            var propSalesCbsa = report.cbsas.selectedCbsa;
            promise = ReportsService.generatePropSalesTransExcel(propSalesCbsa.id);
            break;

          // Metro/Regional Reports
          case productCodes.analyst:
            var analystMarket = report.markets.selectedMarket;
            var analystRegion = report.regions.selectedRegion;
            var analystCbsa = report.cbsas.selectedCbsa;
            var analystCounty = report.counties.selectedCounty;
            promise = ReportsService.generateAnalystExcel(analystMarket.id, analystRegion.id, analystCbsa.id, analystCounty.fipsCode);
            break;
          case productCodes.bls:
            var blsState = report.states.selectedState;
            var blsPublishDate = report.publishDate;
            promise = ReportsService.generateBLSExcel(blsState.name, file.fileType.replace(/ /g, ''), blsPublishDate);
            break;
          case productCodes.rankings:
            var rankingsMarket = report.markets.selectedMarket;
            var rankingsRegion = report.regions.selectedRegion;
            promise = ReportsService.generateRankingsExcel(rankingsMarket.name, rankingsRegion.name);
            break;
          case productCodes.pointRight:
            var pointRightSelectedState = report.states.selectedState;
            var pointRightPublishDate = report.publishDate;
            promise = ReportsService.generatePointRightExcel(pointRightSelectedState.id, pointRightSelectedState.name, pointRightPublishDate);
            break;
          case productCodes.rateTiers:
            var propSalesCbsa = report.cbsas.selectedCbsa;
            promise = ReportsService.generateRateTiersExcel(propSalesCbsa.id);
            break;
          case productCodes.local:
            promise = ReportsService.generateMedicareHospitalInventoryExcel();
            break;
          case productCodes.intraQuarterlyData:
            var iqMarket = 0;
            var iqCbsa = 0;
            var monthYear = report.publishDate;
            promise = ReportsService.generateIntraQuarterlyDataExcel(iqMarket, iqCbsa, monthYear);
            break;
          case productCodes.intraQuarterlyPropertyInventory:
            var monthYear = report.publishDate;
            promise = ReportsService.generateIntraQuarterlyPropertyInventoryExcel(monthYear);
            break;
        }

        if (promise == null) {
          // error
          reportHasError("No promise found for the report that needs to be generated");
          return;
        }

        SpinnerService.start();
        
        promise
          .then(function (data) {
            var fileType = excelFileType; // all the reports generated are EXCEL so hard coding it.
            var fileName = $scope.dynamicReportFileNames[loweredProductCode];
            if(report.selectedReport) {
              var reportType = loweredProductCode;
              if(loweredProductCode == "trends" && report.name == "County Trends"){
                reportType = "countytrends";
              }
              fileName = $scope.dynamicReportFileNames[reportType + " " + report.selectedReport.name];
            }
            // Handle Adding Dynamic Filename based on dropdown selection
            switch(loweredProductCode) {
              case productCodes.bls:
                var blsState = report.states.selectedState;
                fileName += "_" + blsState.name
                  + (file.fileType.search(/data/gi) !== -1 ? "_Data" : "_Report")
                  + ".xlsx";
                break;
              case productCodes.pointRight:
                var pointRightSelectedState = report.states.selectedState;
                fileName += "_" + pointRightSelectedState.name + ".xlsx";
                break;
              case productCodes.rateTiers:
                var cbsa = report.cbsas.selectedCbsa;
                fileName = cbsa.id === "0" ? fileName.replace('{cbsaName}', 'AllData')
                                           : fileName.replace('{cbsaName}', cbsa.name.substr(0, cbsa.name.indexOf(','))); // Drop State from CBSA name
                fileName += ".xlsx";
                break;
              case productCodes.intraQuarterlyData:
                fileName = ('NICMAP_Intra_Quarterly_Data_' + report.publishDate).replace(/\//, '_') + '.xlsx';
                break;
              case productCodes.intraQuarterlyPropertyInventory:
                fileName = ('NICMAP_Intra_Quarterly_Property_Inventory_' + report.publishDate).replace(/\//, '_') + '.xlsx';
                break;
            }
            streamReport(data, fileType, fileName);
          }, function(error) {
            if (error.status == 400)
            {
              switch(loweredProductCode) {
                case productCodes.local:
                case productCodes.propertyInventory:
                case productCodes.mhcData:
                case productCodes.cbsaOrCounty:
                  var decodedString = String.fromCharCode.apply(null, new Uint8Array(error.data));
                  var errorMessage = JSON.parse(decodedString);
                  reportHasError(errorMessage, true);
                  $anchorScroll("Top");
                  break;
                default:
                  reportHasError;
              }
            } else {
              reportHasError;
            }
          }).finally(function() {
          SpinnerService.stop();
        });
      }
    }

    function setHrefLink(report, file, $index) {
      if (report.selectedReport &&
          /^actual-rates/i.test(report.downloadableFiles[0].files[0].urlFriendlyFileName)) {
        return '#/reports/actual-rates/' + report.selectedReport.downloadableFiles[0].files[$index].urlFriendlyFileName;
      }
      return file.urlFriendlyFileName ? '#/reports/' + file.urlFriendlyFileName : '';
    }

    function noReportAccess(content) {
      // https://stackoverflow.com/questions/27945785/ui-sref-and-sce-trustashtml
      var _link = $compile(contactusLink)($scope);
      content += " Please " + _link[0].outerHTML + " for further assistance.";
      return trusted[content] || (trusted[content] = $sce.trustAsHtml(content));
    }

    function getExcelIcon(report, file) {
      var iconToReturn = "assets/recentactivity/xls.png";
      if (report.productCode && file.fileType &&
          report.productCode.toLowerCase() === productCodes.bls && file.fileType.search(/excel/gi) !== -1) {
        iconToReturn = "assets/recentactivity/xls-report.png";
      }
      return iconToReturn;
    }

    function search() {
      getCategoriesWReports();
      // open or close accordion
      _.forEach($scope.categories, function (category) {
        category.open = reportCount(category) > 0;
      });
    }

    function isDisabled(report) {
      if (
        report.productCode &&
        report.productCode.toLowerCase() == productCodes.intraQuarterlyData &&
        report.cbsas &&
        report.cbsas.selectedCbsa.id != '0'
      ) {
        return true;
      }
      return false;
    }
  }
})();
