(function() {
  app.controller('TrendsChartsController', TrendsChartsController);

  TrendsChartsController.$inject = ['$scope', '$timeout', '$state', 'KendoTemplateFunctions', 'FileSaver',
    'PopupHandlerService', 'SpinnerService', 'exportFilePrefix'];

  function TrendsChartsController($scope, $timeout, $state, KendoTemplateFunctions, FileSaver,
    PopupHandlerService, SpinnerService, exportFilePrefix) {
    var exportAction;
    if ($state.params.exportType == "PDF") {      
      exportAction = exportPdf;
    } else if ($state.params.exportType == "XLSX") {      
      exportAction = exportExcel;
    }

    $scope.defaultLegend = KendoTemplateFunctions.defaultChartLegend;
    $scope.disableExportBtn = false;
    $scope.chartPercentageAxis = [{
      name: "percentageAxis",
      color: "#959595",
      labels: {
        format: "p1",
        color: "#232323" //232323 is the default text color of the axis labels
      },
      axisCrossingValues: [0, -9999999], //hidden xaxis displays at 0. Other axis is at bottom.
      narrowRange: true
    }];

    $scope.chart2Axes = [{
      name: "barAxis",
      color: "#959595",
      labels: {
        color: "#232323"
      },
      axisCrossingValues: [0, -9999999], //hidden xaxis displays at 0. Other axis is at bottom.
      narrowRange: true
    }, {
      name: "percentageAxis",
      labels: {
        format: "p1",
        color: "#232323"
      },
      narrowRange: true
    }];

    $scope.seriesDefaults = {
      type: 'line',
      style: 'smooth',
      markers: {
        visible: false
      },
      missingValues: 'gap'
    };

    $scope.exportPdf = exportPdf; 
    $scope.exportExcel = exportExcel;

    //initalization
    hiddenAxisAtZero = {
      axisCrossingValues: [0, 99], //only charts with differet Y axis on left and right "need" axisCrossingValues, but it doesn't seem to affect charts that don't need this.
      line: {
        visible: false
      },
      labels: {
        visible: false
      }
    };
    actualAxis = {
      axisCrossingValues: [0, 99], //only charts with differet Y axis on left and right "need" axisCrossingValues, but it doesn't seem to affect charts that don't need this.
      majorGridLines: {
        visible: false
      },
      labels: {
        template: xAxisLabels
      }
    };        
    $scope.categoryAxis = actualAxis;
    
    //give parent a hook to update this controller's scope
    $scope.$emit("chartReady", {
      chartLoading: chartLoading,
      chartLoaded: chartLoaded
    });

    function exportPdf() {
      exportAction = null;
      $scope.disableExportBtn = true;
      SpinnerService.start();
      var dataToPost = {
        inventoryChart: getMinMaxForChart($scope.inventoryChart, true),
        occupancyChart: getMinMaxForChart($scope.occupancyChart),
        constructionChart: getMinMaxForChart($scope.constructionChart, true),
        rentChart: getMinMaxForChart($scope.rentChart),
        annualInventoryChart: getMinMaxForChart($scope.annualInventoryChart)
      };

      var subTitle = $scope.filterText.substring(0, $scope.filterText.lastIndexOf('|') - 1);
      lastPayloadGetPdf(subTitle, lastPayloadSelection, dataToPost)
        .then(function (data) {
          var pdfFile = new Blob([data], {
            type: 'application/pdf'
          });
          subTitle = subTitle.replace(' \ ', '')
            .replace(/\//g, '')
            .replace(/\|/g, '');
          var fileName = (exportFilePrefix + ' - ' + subTitle).replace(/ /g, '_').replace(/__/g, '_') + '.pdf';
          FileSaver.saveAs(pdfFile, fileName);
        }, function (err) {
          console.error(err);
        })
        .finally(function () {
          SpinnerService.stop();
          $scope.disableExportBtn = false;
        });
    }

    function exportExcel() {
      exportAction = null;
      $scope.disableExportBtn = true;
      SpinnerService.start();
      lastPayloadGetExcel($scope.filterText, lastPayloadSelection)
        .then(function(data) {
          var excelFile = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          });
          var title = $scope.filterText.replace(' \ ', '')
            .replace(/\//g, '')
            .replace(/\|/g, '');
          var fileName = (exportFilePrefix + ' - ' + title).replace(/ /g, '_').replace(/__/g, '_') + '.xlsx';
          FileSaver.saveAs(excelFile, fileName);
        }, function (err) {
          console.error(err);
        })
        .finally(function () {
          SpinnerService.stop();
          $scope.disableExportBtn = false;
        });
    }

    function chartLoading() {
      SpinnerService.start();
      $scope.chartData = [];
    }

    function chartLoaded(payload) {
      if(payload.noData) {
        displayNoDataModal();
        $scope.dataLength = 0;
        displayChart([]);
      } else if (payload.filterAccessViolation) {
        $scope.filterText = '';
        $scope.disableExportBtn = true;
        $scope.dataLength = 0;
        displayChart([]);
        return;
      } else {
        $scope.dataLength = payload.length;
        displayChart(payload.data);
      }
      $scope.disableExportBtn = false;
      lastPayloadGetExcel = payload.getExcel;
      lastPayloadGetPdf = payload.getPdf;
      lastPayloadSelection = angular.copy(payload.selection); //need angular.copy because we don't want any updates since the user last clicked "Display Data"
      SpinnerService.stop();

      $scope.filterText = payload.filterText;

      // this should only be true the first time the page is loaded if export type is in the query params
      // this is triggered from the recent activity links.            
      if (exportAction) {        
        $timeout(exportAction, 100);
      }
    }

    var lastPayloadGetExcel;
    var lastPayloadGetPdf;
    var lastPayloadSelection;
    var hiddenAxisAtZero;
    var actualAxis;
    var actualMonthlyAxis;

    function displayChart(data) {      
      // don't use a datasource because we don't need it to be observable, this just slows it down
      //$scope.chartData = new kendo.data.DataSource({
      //  data: data
      //});
      console.log("Data Retrieved: " + new Date().toLocaleTimeString());
      $scope.chartData = data;
      
      var categories = _.map(data, function (eachPoint) {
        return eachPoint.quarter;
      });

      var monthlyCategories = _.map(data, function (eachPoint) {
        return eachPoint.monthly;
      });

      function isEmpty(str) {
        return (!str || 0 === str.length);
      }

      if (isEmpty(monthlyCategories[0]))
      {
        actualAxis.categories = categories;
        hiddenAxisAtZero.categories = categories;
      }
      else
      {
        actualAxis.categories = monthlyCategories;
        hiddenAxisAtZero.categories = monthlyCategories;
      }  
      _.forEach([$scope.inventoryChart, $scope.occupancyChart, $scope.constructionChart, $scope.rentChart, $scope.annualInventoryChart], setXAxisForChartDependingOnData(data));
    }

    function setXAxisForChartDependingOnData(data) {
      return function(chart) {
        if(!chart) {
          return; //helpful for unit tests
        }
        //get fields that are graphed in this chart
        var fields = _.map(chart.options.series, function (series) {
          series.data = angular.copy($scope.chartData);
          return series.field;
        });
        //check that each field is null, zero, or greater than zero
        var isZeroOrMore = _.every(data, function(datum) {
          return _.every(fields, function(field) {
            return datum[field] >= 0; //doing >= will allow null be 'true'.
          });
        });

        if(isZeroOrMore) {
          chart.setOptions({
            series: chart.options.series,
            categoryAxis: [actualAxis]
          });
        } else {
          //this is a hack to get kendo to display the x-axis at the bottom. You put a hidden axis at location zero. Then the actual access at -999999.
          //Without the 'hidden' axis. Bars display awkwardly; If the xaxis is at -5000, the the bar is at +2500; it will display a bar from -5000 to +2500.
          //With the hidden axis the bar will display from 0 to +2500.
          chart.setOptions({
            series: chart.options.series,
            categoryAxis: [hiddenAxisAtZero, actualAxis]
          });
        }
      };
    }

    function displayNoDataModal() {
      $scope.chartData = [];
      PopupHandlerService.openSimplePopup("The filter selections are not valid. Please update the selections to display data in the Trends charts.");
    }

    function xAxisLabels(dataItem) {
      //Because of scope issues, this is to allow the Kendo template access to the dataLength variable which will decide whether to
      //display only the year or display quarters based on the length of the dataset.
      return KendoTemplateFunctions.yearLabels(dataItem, $scope.dataLength);
    }

    function getMinMaxForChart(chart, hasSecondaryAxis) {
      if (!chart) return {};
      var _hasSecondaryAxis = hasSecondaryAxis || false;
      var valueOptions = chart._plotArea.valueAxis.plotArea.axisY.options;
      var series1 = {
        majorUnit: valueOptions.majorUnit,
        max: valueOptions.max,
        min: valueOptions.min
      };

      if (!_hasSecondaryAxis) return series1;

      var seriesArr = [];
      _.forEach(chart._plotArea.axes, function (axis) {
        var axisOptions = axis.options;
        if (axisOptions.type != "numeric") return; // continue
        var series = {
          majorUnit: axis.totalMajorUnit,
          max: axis.totalMax,
          min: axis.totalMin
        };
        seriesArr.push(series);
      });
      return seriesArr;
    }

    $scope.hideCountyExport = function(){
      return true;
      //return $state.is('trends.communityType');
    }

    $scope.disableCountyExportBtn = function(){
      if(lastPayloadSelection != undefined && lastPayloadSelection.market == 0) {
        return true;
      }
      return false;
    }
  }
})();
