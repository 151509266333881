(function () {
  "use strict";
  app.controller('MetroBankHistoryController', MetroBankHistoryController);

  MetroBankHistoryController.$inject = ['$scope', 'SubscriptionDetailsService', 'FileSaver', 'AdminService', 'SpinnerService'];

  function MetroBankHistoryController($scope, SubscriptionDetailsService, FileSaver, AdminService, SpinnerService) {
    $scope.subscriptionTypes = [];
    $scope.exportExcel = exportExcel;

    $scope.$on('SilentSubscriptionDetailRefreshComplete', productCheck);

    function productCheck() {
      // this will hold the data that is being shown in the grid
      $scope.metroBankHistoryGrid = SubscriptionDetailsService.getMetroBankHistory();
      refreshGrid();
    }

    function refreshGrid() {
      $scope.gridOpts.dataSource.read();
      $scope.gridOpts.resetFilters();
    }

    var gridSubscriptionTypeDataSource = new kendo.data.DataSource({
      transport: {
        read: function (options) {
          options.success($scope.subscriptionTypes);
        }
      }
    });
    SubscriptionDetailsService.getSubscriptionTypes()
    .then(function (data) {
      $scope.subscriptionTypes = _.sortBy(data, ['subscriptionType']);
      gridSubscriptionTypeDataSource.read();
    });
    $scope.gridOpts = {
      columns: [
      {
        field: "subscriptionID",
        title: "Subscription ID",
        filterable: {
          cell: {
            showOperators: false,
            operator: "equals"
          }
        }
      }, {
        field: "startDate",
        title: "Start Date",
        format: "{0:MM/dd/yyyy}",
        filterable: {
          cell: {
            showOperators: false,
            operator: "gte"
          }
        }
      }, {
        field: "endDate",
        title: "End Date",
        format: "{0:MM/dd/yyyy}",
        filterable: {
          cell: { 
            showOperators: false,
            operator: "lte"
          }
        }
      }, {
        field: "subscriptionType",
        title: "Subscription Type",
          filterable: {
            cell: {
              showOperators: false,
              template: function (args) {
                args.element.removeAttr("data-bind");
                args.element.attr("id", "SubscriptionTypeMultiSelect");
                args.element.kendoMultiSelect({
                  dataSource: gridSubscriptionTypeDataSource,
                  optionLabel: " ",
                  dataTextField: "subscriptionType",
                  dataValueField: "subscriptionType",
                  valuePrimitive: true,
                  change: subscriptionTypeFilterChange
                })
              }
            }
          }
      }, {
        field: "accountType",
        title: "Account Type",
        filterable: {
          cell: {
            showOperators: false,
            template : function(args){
              args.element.kendoDropDownList({
                autoBind: false,
                optionLabel: " ",
                dataTextField: "name",
                dataValueField: "name",
                valuePrimitive: true,
                dataSource: new kendo.data.DataSource({
                  data: [
                    { name: 'New' },
                    { name: 'Renewal' }
                  ]
                })
              });
            }
          }
        }
      }, {
        field: 'accountStatusCalculated',
        title: 'Account Status',
        filterable: {
          cell: {
            showOperators: false,
            template: function (args) {
              args.element.kendoDropDownList({
                autoBind: false,
                optionLabel: ' ',
                dataTextField: 'name',
                dataValueField: 'name',
                valuePrimitive: true,
                dataSource: new kendo.data.DataSource({
                  data: [
                    { name: 'Active' },
                    { name: 'Pending' },
                    { name: 'Expired' },
                    { name: 'Terminated' },
                  ]
                })
              });
            }
          }
        }
      }, {
        field: "metrosPurchased",
        title: "Metros Purchased",
        filterable: {
          cell: {
            showOperators: false,
            operator: "gte"
          }
        }
      }, {
        field: "metrosActivated",
        title: "Metros Activated",
        filterable: {
          cell: {
            showOperators: false,
            operator: "gte"
          }
        }
      }, {
        field: "metrosAvailable",
        title: "Metros Available",
        filterable: {
          cell: {
            showOperators: false,
            operator: "gte"
          }
        }
      }],
      dataSource: new kendo.data.DataSource({
        transport: {
          read: function (options) {
            options.success($scope.metroBankHistoryGrid);
          }
        },
        pageSize: 5,
        error: function (err) {
          console.log('error - ' + err);
        },
        schema: {
          model: {
            fields: {
              'subscriptionID': { type: 'string' },
              'startDate': { type: 'date' },
              'endDate': { type: 'date' },
              'subscriptionType': { type: 'string' },
              'accountType': { type: 'string' },
              'accountStatus': { type: 'string' },
              'metrosPurchased': { type: 'number' },
              'metrosActivated': { type: 'number' },
              'metrosAvailable': { type: 'number' }
            }
          }
        },
        sort: {
          field: "endDate",
          dir: "desc"
        },
        serverPaging: false,
        serverFiltering: false,
        serverSorting: false
      }),
      dataBinding: function(e) {
        Nic.kendoGrid.filtersOnTop('grid');
      },
      filterable: {
        mode: "row",
        extra: false,
        operators: {
          date: {
            eq: "Equal to",
            gte: "On or After",
            lte: "On or Before"
          }
        }
      },
      scrollable: false,
      sortable: {
        mode: "single",
        allowUnsort: false
      },
      pageable: true,
      resetFilters: function () {
        this.dataSource.filter({});
        var subTypeMulti = $('#SubscriptionTypeMultiSelect');
        if(subTypeMulti.length > 0) {
          subTypeMulti.data("kendoMultiSelect").value([]);
        }
        this.dataSource.sort({ field: "endDate", dir: "desc" });
      }
    };

    function subscriptionTypeFilterChange(e) {
      applyFilterChange("or", "subscriptionType", "eq", this.value());
    }

    function applyFilterChange (_logic, _field, _operator, _values) {
      var filter = { logic: _logic, filters: [] };
      $.each(_values, function (i, v) {
        filter.filters.push({field: _field, operator: _operator, value: v });
      });
      if (filter.filters.length > 0) {
        // filter added
        var currentFilters = $scope.gridOpts.dataSource.filter() || {};
        if (currentFilters.filters) {
          var filteredOut = _.filter(currentFilters.filters, function (eachFilter) {
            return (eachFilter.field && eachFilter.field != _field) ||
              (eachFilter.filters && eachFilter.filters.length > 0 && eachFilter.filters[0].field != _field);
          });
          filteredOut.push(filter);
          $scope.gridOpts.dataSource.filter(filteredOut);
        } else {
          $scope.gridOpts.dataSource.filter(filter);
        }
      } else {
        var currentFilters = $scope.gridOpts.dataSource.filter() || {};
        if (currentFilters.filters) {
          var filteredOut = _.filter(currentFilters.filters, function (eachFilter) {
            return (eachFilter.field && eachFilter.field != _field) ||
              (eachFilter.filters && eachFilter.filters.length > 0 && eachFilter.filters[0].field != _field);
          });
          $scope.gridOpts.dataSource.filter(filteredOut);
        } else {
          $scope.gridOpts.dataSource.filter(filter);
        }
      }
    }

    function exportExcel() {
      SpinnerService.start();

      var dataForExport = $scope.gridOpts.dataSource.data();
      var filtersAndSorts = getGridCurrentFilterAndSort($scope.gridOpts);

      var exportVm = {
        id: $scope.companyInfo.id,
        name : $scope.companyInfo.companyName,
        data: dataForExport,
        filtersAndSorts: filtersAndSorts
      };

      AdminService
        .companyMetroBankRecordsToExcel(exportVm)
        .then(function (data) {
          var excelFile = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          });
          var fileName = 'NICMAP_Admin_Metro_Bank_History_' + exportVm.name.replace(/ /g, '_') + '.xlsx';
          FileSaver.saveAs(excelFile, fileName);
        }, function (err) {
          console.error(err);
        })
        .finally(function () {
          SpinnerService.stop();
        });
    }

    function getGridCurrentFilterAndSort(sourceGrid) {
      var combinedFilters = [];
      var filters = sourceGrid.dataSource.filter();
      filters = (filters === undefined) ? [] : filters.filters;
      _.forEach(filters, function (eachFilterInfo) {
        // need to do this for the multiselect dropdowns that are in the grid
        if (eachFilterInfo.filters) {
           var concatenatedVal = '', sameFieldName = '', sameFieldOperator = '';
           _.forEach(eachFilterInfo.filters, function (sameFieldObj) {
            sameFieldName = sameFieldObj.field;
            sameFieldOperator = sameFieldObj.operator;
            concatenatedVal += (concatenatedVal.length > 0 ? ';' : '') + sameFieldObj.value;
          });
          combinedFilters.push({ field: sameFieldName, operator: sameFieldOperator, value: concatenatedVal });
        } else {
          combinedFilters.push(eachFilterInfo);
        }
      });

      var gridData = {
        filters: combinedFilters,
        sorts: sourceGrid.dataSource.sort() || []
      };
      return gridData;
    }

    productCheck();
  }
})();