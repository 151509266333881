(function () {
  "use strict";

  app.factory("DateOffsetUtils", DateOffsetUtils);
  
  DateOffsetUtils.$inject = [];
  
  function DateOffsetUtils() {

    function getTimezoneOffset(datePassedIn) {
      var offsetInMinutes = datePassedIn.getTimezoneOffset();
      if (offsetInMinutes > 0) {
        // EST or some other timezone where UTC >
        return -(offsetInMinutes);
      } else if (offsetInMinutes < 0) {
        // IST or some other timezone where UTC <
        return Math.abs(offsetInMinutes);
      } else {
        // UTC
        return 0;
      }
    }

    function setDateOffsetToDateTime(datePassedIn, offsetInMinutes) {
      if (offsetInMinutes == 0)
        return datePassedIn;

      var currentMinutes = datePassedIn.getMinutes();
      datePassedIn.setMinutes(offsetInMinutes);
      datePassedIn.setTime(datePassedIn.getTime() + currentMinutes * 60000);

      return datePassedIn;
    }

    function convertToDate(d) {
      // Converts the date in d to a date-object. The input can be:
      //   a date object: returned without modification
      //  an array      : Interpreted as [year,month,day]. NOTE: month is 0-11.
      //   a number     : Interpreted as number of milliseconds
      //                  since 1 Jan 1970 (a timestamp) 
      //   a string     : Any format supported by the javascript engine, like
      //                  "YYYY/MM/DD", "MM/DD/YYYY", "Jan 31 2009" etc.
      //  an object     : Interpreted as an object with year, month and date
      //                  attributes.  **NOTE** month is 0-11.
      return (
        d.constructor === Date ? d :
        d.constructor === Array ? new Date(d[0],d[1],d[2]) :
        d.constructor === Number ? new Date(d) :
        d.constructor === String ? new Date(d) :
        typeof d === "object" ? new Date(d.year,d.month,d.date) :
        NaN
      );
    }

    function compareDates(a, b) {
      // Compare two dates (could be of any type supported by the convert
      // function above) and returns:
      //  -1 : if a < b
      //   0 : if a = b
      //   1 : if a > b
      // NaN : if a or b is an illegal date
      // NOTE: The code inside isFinite does an assignment (=).
      return (
        isFinite(a = convertToDate(a).valueOf()) &&
        isFinite(b = convertToDate(b).valueOf()) ?
        (a > b) - (a < b) :
        NaN
      );
    }

    function inRange(d, start, end) {
      // Checks if date in d is between dates in start and end.
      // Returns a boolean or NaN:
      //    true  : if d is between start and end (inclusive)
      //    false : if d is before start or after end
      //    NaN   : if one or more of the dates is illegal.
      // NOTE: The code inside isFinite does an assignment (=).
      return (
        isFinite(d = convertToDate(d).valueOf()) &&
        isFinite(start = convertToDate(start).valueOf()) &&
        isFinite(end = convertToDate(end).valueOf()) ?
        start <= d && d <= end :
        NaN
      );
    }

    return {
      getTimezoneOffset: getTimezoneOffset,
      setDateOffsetToDateTime: setDateOffsetToDateTime,
      convertToDate: convertToDate,
      compareDates: compareDates,
      inRange: inRange
    };
  }
}());