(function() {
  "use strict";
  app.controller('LeftNavController', LeftNavController);
    
  LeftNavController.$inject = ['$scope', '$rootScope', '$interval', 'AuthService'];
  function LeftNavController ($scope, $rootScope, $interval, authService) {
    $rootScope.hideLeftNav = true; //by default hide it
    $scope.hasAccessToPortfolio = false;

    if(!authService.identity.isAuth()) {
      $rootScope.isLoggedIn = false;
    }

    $scope.$on("loggedIn", function() {
      checkPortfolioAccess();
      $rootScope.isLoggedIn = true;
      $scope.isImpersonated = authService.identity.isImpersonated();
      //almost every page has "hideLeftNav=true"! so leave it as 'true'
      //$rootScope.hideLeftNav = false;
    });

    $scope.$on("loggedOut", function() {
      $rootScope.isLoggedIn = false;
    });

    function checkPortfolioAccess() {
      authService.getProductAccess('MAPPortfolios')
      .then(function(value) {
        $scope.hasAccessToPortfolio = value;
      }, function(reason) {
        $scope.hasAccessToPortfolio = false;
      });
    }

    var tick = function () {
      $scope.copyrightYear = new Date().getFullYear();
    };
    
    tick();
    $interval(tick, 60000);
  }
}());
