(function() {
  "use strict";
  //should probably be a PortfolioService
  app.factory("SaveFilterService", SaveFilterService);

  SaveFilterService.$inject = ["$uibModal"];

  function SaveFilterService($uibModal) {
    PopupController.$inject = ['$scope', 'namesOfcurrentFilters'];
    return {
      openSaveDialog: openSaveDialog
    }

    function openSaveDialog(namesOfcurrentFilters) {
      var savePopupModalInstance = $uibModal.open({
        templateUrl: "app/partials/saveDataPopup.html",
        controller: PopupController,
        resolve: {
          namesOfcurrentFilters: function() {
            return namesOfcurrentFilters;
          }
        }
      });
      return savePopupModalInstance.result;
    }

    function PopupController($scope, namesOfcurrentFilters) {

      $scope.isExistingName = isExistingName;
      $scope.filterNameEnterClick = filterNameEnterClick;
      $scope.saveType = 'filter';
      $scope.placeholderText = 'Filter Name';

      function isExistingName(newName) {
        var exists = false;
        if(!newName) {
          return false;
        }
        var exists = _.find(namesOfcurrentFilters, function(name) {
          return angular.lowercase(name) == angular.lowercase(newName);
        });
        if(!exists) {
          return false;
        }
        return true;
      }

      function filterNameEnterClick(newName) {
        if((!newName) || (newName && newName.length > 32)) {
          return false;
        }
        $scope.$close(newName);
      }
    }
  }




})();