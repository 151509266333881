(function(){
  "use strict";
  app.factory('VersioningMiddleware', VersioningMiddleware);
  
  VersioningMiddleware.$inject = ['$q', '$window', 'buildId', 'environment', 'CacheFactory']
  
  function VersioningMiddleware($q, $window, buildId, environment, CacheFactory) {
    var clientHeaderName = 'X-Client-Version';
    var apiHeaderName = 'X-API-Version';

    return {
      response: response,
      responseError: responseError
    };

    function response(rsp) {
      processResponse(rsp);
      return rsp;
    }

    function responseError(rspErr) {
      processResponse(rspErr);
      return $q.reject(rspErr);
    }

    function processResponse(rsp) {
      var reload = false;
      if (rsp) {
        if (/site-version.js?/i.test(rsp.config.url)) {
          reload = checkIfNeedToReload(rsp, 'client', clientHeaderName);
        } else if (rsp.headers(apiHeaderName)) {
          // may be if this does not work out... we can leverage ping instead.
          reload = checkIfNeedToReload(rsp, 'api', apiHeaderName);
        } else {
          // console.log('------>SKIP<------');
        }
      }

      if (reload) {
        // console.log('Reloading...');
        CacheFactory.destroyAll(); //Old API values are still stored here
        $window.location.reload(true);
      }
    }
    
    function checkIfNeedToReload(rsp, whichSection, headerToUser) {
      var appVersion = rsp.headers(headerToUser);
      var objToPrint = {
        buildId: buildId,
        environment: environment,
        appVersion: appVersion,
        section: whichSection
      };
      //console.log(objToPrint);
      if (buildId != appVersion) {
        // console.log('perform refresh!');
        return true;
      } else {
        // console.log('------>NOPE<------');
        return false;
      }
    }
  }
})();
