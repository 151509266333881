(function() {
  "use strict";

  app.factory("GeoJsonUtils", function() {
    return {
      toCoordinates: toCoordinates,
      toName: toName
    };
    
    function toCoordinates(geoJson) {
      var shapesLocations = [];

      // assuming multi shapes are always provided, otherwise adapt for single geojson definitions.
      //var geoJsonLines = (geoJson.geometry.type == "LineString") ? [geoJson.geometry.coordinates] : geoJson.geometry.coordinates;
      var geoJsonLines;
      switch (geoJson.geometry.type) {
        case "LineString":
          geoJsonLines = [geoJson.geometry.coordinates];
          break;
        case "MultiPolygon":
          geoJsonLines = geoJson.geometry.coordinates[0];
          break;
        default:
          geoJsonLines = geoJson.geometry.coordinates;
          break;
      }

      angular.forEach(geoJsonLines, function(geoJsonLine) {
        var shape = geoJsonLine;
        var shapeLocations = [];
        angular.forEach(shape, function(point) {
          shapeLocations.push({
            latitude: point[1],
            longitude: point[0]
          });
        });
        shapesLocations.push(shapeLocations);
      });

      return shapesLocations;
    }

    function toName(geoJson) {
      return geoJson.properties.name;
    }
  });
}());
