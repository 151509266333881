(function() {
  "use strict";
  app.directive('trendsDeleteFilter', trendsDeleteFilter);
  trendsDeleteFilter.$inject = ["$timeout", "$parse"]

  function trendsDeleteFilter($timeout, $parse) {
    return {
      link: link,
      //require: ['kendoDropDownList'], //need to be on a kendoDropDownList, but harder to unit test if we do
      scope: {
        kDataSource: '=',
        filterChange: '&',
        filterDelete: '&',
        filterMessage: '=',
        kNgModel: '='
      }
    };

    function wireInClickEvent(scope) {
      var model = $parse("kNgModel");
      var inProgressSelect;
      var previouslySelectedValue;
      scope.$watch("kNgModel", function(newValue, oldValue) {
        if(!newValue) {
          return;
        }
        if(!newValue.id) {
          return;
        }
        previouslySelectedValue = oldValue;
        //it's actually getting set to something.
        inProgressSelect = $timeout(function() {
          if(oldValue == null || oldValue.id !== newValue.id){
            scope.filterChange(); //savedFilterChanged();
          }
        });

      });

      $("body").on("click", ".deleteFilter", function(e) {
        if(!scope.kNgModel) {
          return;
        }

        var id = $(e.currentTarget).data().id;
        if(id != scope.kNgModel.id){
          return;//click was on other dropdown
        }

        var filtername = scope.kNgModel.name;
        var filtervalue = scope.kNgModel.id;
        var deleteMessage = scope.filterMessage.replace('SAVED_NAME', filtername);

        //if you click delete, Kendo treats the whole row as a select button.
        //So when you click delete it's actually a Select + Delete. This should cancel the Select and revert the option to the previous value
        //For instance Saved Displays is displayed. Delete the first Filter. We should not select and lookup that filter.
        if($timeout.cancel(inProgressSelect)) { //don't trigger a filter select and reset to previous value
          scope.$apply(function() {
            //need angular.copy() to appease the demon that lives inside kendo ui
            //without it SOMETIMES kendo doesn't re-select the previous value
            scope.kNgModel = angular.copy(previouslySelectedValue); //un select the current item
          });
        }
        $.confirm({
          title: '',
          content: deleteMessage,
          confirmButton: 'Yes',
          cancelButton: 'No',
          confirmButtonClass: 'btn btn-primary',
          cancelButtonClass: 'btn btn-warning',
          confirm: function() {
            if(filtername) {
              scope.filterDelete({
                filtername: filtername,
                filtervalue: filtervalue
              });
            }
          }
        });
      });
    }

    function link(scope) {
      wireInClickEvent(scope);
      scope.$on('$destroy', function() {
        $("body").off("click", ".deleteFilter");
      });
    }
  }

})();