(function() {
  "use strict";

  app.controller('GlossaryController', GlossaryController);
  GlossaryController.$inject = ['$scope', '$rootScope', '$state', '$filter', 'Blob', 'FileSaver', 'glossaryTerms', 'SpinnerService', 'WordpressService'];

  function GlossaryController($scope, $rootScope, $state, $filter, Blob, FileSaver, glossaryTerms, SpinnerService, WordpressService) {
    $rootScope.hideLeftNav = true;
    var vm = this;

    $scope.backToSupportClick = function () {
      $state.go('support');
    };

    // init
    $scope.sortingOrder = "title";
    $scope.pageSizes = [5, 10, 25, 50];
    $scope.reverse = false;
    $scope.filteredItems = [];
    $scope.groupedItems = [];
    $scope.itemsPerPage = 50;
    $scope.pagedItems = [];
    $scope.currentPage = 0;
    $scope.items = glossaryTerms;
    $scope.glossaryToPDF = glossaryToPDF;

    var searchMatch = function (haystack, needle) {
      if (!needle) {
        return true;
      }
      return haystack.toString().toLowerCase().indexOf(needle.toLowerCase()) !== -1;
    };

    // init the filtered items
    $scope.search = function () {
      $scope.filteredItems = $filter('filter')($scope.items, function (item) {
        for (var attr in item) {
          if (searchMatch(item[attr], $scope.query)){
            return true;
          }
        }
        return false;
      });
      // take care of the sorting order
      if ($scope.sortingOrder !== '') {
        $scope.filteredItems = $filter('orderBy')($scope.filteredItems, $scope.sortingOrder, $scope.reverse);
      }
      $scope.currentPage = 0;
      // now group by pages
      $scope.groupToPages();
    };

    // show items per page
    $scope.perPage = function () {
      $scope.groupToPages();
    };

    // calculate page in place
    $scope.groupToPages = function () {
      $scope.pagedItems = [];

      for (var i = 0; i < $scope.filteredItems.length; i++) {
        if (i % $scope.itemsPerPage === 0) {
          $scope.pagedItems[Math.floor(i / $scope.itemsPerPage)] = [$scope.filteredItems[i]];
        } else {
          $scope.pagedItems[Math.floor(i / $scope.itemsPerPage)].push($scope.filteredItems[i]);
        }
      }
    };

    $scope.deleteItem = function (idx) {
      var itemToDelete = $scope.pagedItems[$scope.currentPage][idx];
      var idxInItems = $scope.items.indexOf(itemToDelete);
      $scope.items.splice(idxInItems, 1);
      $scope.search();

      return false;
    };

    $scope.range = function (start, end) {
      var ret = [];
      if (!end) {
        end = start;
        start = 0;
      }
      for (var i = start; i < end; i++) {
        ret.push(i);
      }
      return ret;
    };

    $scope.prevPage = function () {
      if ($scope.currentPage > 0) {
        $scope.currentPage--;
      }
    };

    $scope.nextPage = function () {
      if ($scope.currentPage < $scope.pagedItems.length - 1) {
        $scope.currentPage++;
      }
    };

    $scope.setPage = function () {
      $scope.currentPage = this.n;
    };

    // functions have been described. Process the data for display
    $scope.search();

    // change sorting order
    $scope.sort_by = function (newSortingOrder) {
      if ($scope.sortingOrder == newSortingOrder)
        $scope.reverse = !$scope.reverse;

      $scope.sortingOrder = newSortingOrder;
    };
      
    function glossaryToPDF() {
      SpinnerService.start();

      WordpressService
        .exportGlossaryToPDF()
        .then(function (data) {
          var pdfFile = new Blob([data], {
            type: 'application/pdf'
          });
          var fileName = 'NICMAP_Glossary.pdf';
          SpinnerService.stop();
          FileSaver.saveAs(pdfFile, fileName);
        }, function (err) {
          SpinnerService.stop();
          console.log(err);
        });
    }
  }
}());