(function() {
  "use strict";
  app.factory("CompanySearchService", CompanySearchService);

  CompanySearchService.$inject = ["$http", "$q", '$sce', "apiSettings"];

  function CompanySearchService($http, $q, $sce, apiSettings) {

    return {
      search: search,
    };

    function search(nameToSearch) {
      var deferred = $q.defer();
      if(search) {
        $http.get(apiSettings.url + "/company/search?nameToSearch=" + nameToSearch)
          .then(function(rsp) {
              deferred.resolve(rsp.data);
            },
            function() {
              deferred.reject();
            });
      } else {
        deferred.resolve([]);
      }

      return deferred.promise;
    };
  }
})();
