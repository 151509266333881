(function () {
  'use strict';
  app.factory('NotificationService', NotificationService);

  NotificationService.$inject = ['$http', '$q', 'apiSettings'];
  function NotificationService($http, $q, apiSettings) {
    var baseUrl = apiSettings.url + '/notification';
    return {
      remove: remove
    }

    function remove(notificationId) {
      var url = baseUrl + '/' + notificationId;
      return $http.delete(url)
        .then(function (rsp) {
          return rsp.data;
        })
        .catch(function (err) {
          console.error(err);
          return $q.reject(err);
        });
    }
  }
})();
