(function () {
  "use strict";

  app.factory("TrendsService", TrendsService);

  TrendsService.$inject = ['$http', '$q', '$state', 'apiSettings', 'CacheFactory'];

  function TrendsService($http, $q, $state, apiSettings, CacheFactory) {
    if (!CacheFactory.get('trendsCache')) {
      CacheFactory.createCache('trendsCache', {
        maxAge: 60 * 60 * 1000, // Items added to this cache expire after 1 hour
        cacheFlushInterval: 24 * 60 * 60 * 1000, // This cache will clear itself every day
        deleteOnExpire: 'aggressive', // Items will be deleted from this cache when they expire
        storageMode: 'sessionStorage'
      });
    }
    var trendsCache = CacheFactory.get('trendsCache');

    function getFilterData(dataType) {
      var deferred = $q.defer();
      $http.get(apiSettings.url + "/trends/" + dataType, { cache: trendsCache })
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });
      return deferred.promise;
    };

    function getFilteredMetros(marketType, regionIds) {
      var deferred = $q.defer();
      var url = apiSettings.url + "/trends/metros?marketType=" + marketType;
      if(regionIds && regionIds.length) {
        url = url.concat("&regionIds=" + regionIds.join(','));
      }
      $http.get(url, { cache: trendsCache })
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });
      return deferred.promise;
    };

    function getFilteredCounties(metros) {
      var deferred = $q.defer();
      if(!metros || !metros.length) {
        //metro can be null, like when switching to a saved filter
        deferred.resolve([]);
        return deferred.promise;
      }
      var url = apiSettings.url + "/trends/counties?metros=" + metros.join(',');
      $http.get(url, { cache: trendsCache })
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });
      return deferred.promise;
    };

    function getChartData(filterScope, title, savedFilterName) {
      var deferred = $q.defer();
      var filter = buildFilter(filterScope);
      if (savedFilterName) {
        filter.savedFilterName = savedFilterName;
      }

      var uri = apiSettings.url + '/trends/chartData?' +
        $.param({
          title: title
        });

      $http
        .post(uri, filter)
        .then(function(rsp) {
          if(rsp.data.length == 0) {
            deferred.reject();
          } else {
            var data = massageProtectedData(rsp.data);
            deferred.resolve(data);
          }
        }, function(err) {
          deferred.reject(err);
        });

      return deferred.promise;
    }

    //kendo doesn't like word "Protected" to graph. So convert those to null for graphs. OR could switch to the *ForChart field that was introduced for ExcelExport
    function massageProtectedData(data) {
      _.forEach(data,
        function(quarter) {
          _.forEach(quarter,
            function(value, field) {
              if(value == "Protected") {
                quarter[field] = null;
              }
            });
        });
      return data;
    }

    function getCommunityChartData(filterScope, title, savedFilterName) {
      var deferred = $q.defer();
      var filter = buildCommunityFilter(filterScope);
      if (savedFilterName) {
        filter.savedFilterName = savedFilterName;
      }
      var uri = apiSettings.url + '/trends/communityChartData?' +
        $.param({
          title: title
        });

      $http
        .post(uri, filter)
        .then(function(rsp) {
          if(rsp.data.length == 0) {
            deferred.reject();
          } else {
            var data = massageProtectedData(rsp.data)
            deferred.resolve(data);
          }
        }, function(err) {
          deferred.reject(err);
        });

      return deferred.promise;
    }

    function getPropertyPdf(subTitle, filterScope, dataToPost) {
      var deferred = $q.defer();
      var filter = buildFilter(filterScope);
      var uri = apiSettings.url + '/trends/generatePropertyPdf?' +
        $.param({
          subTitle: subTitle
        });

      dataToPost["propertyTypeFilter"] = filter;

      $http
        .post(uri, dataToPost, {
          responseType: 'arraybuffer'
        })
        .then(function(rsp) {
          if(rsp.data.length == 0) {
            deferred.reject();
          } else {
            deferred.resolve(rsp.data);
          }
        }, function(err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function getPropertyExcel(title, filterScope) {
      var deferred = $q.defer();
      var filter = buildFilter(filterScope);
      var uri = apiSettings.url + '/trends/generatePropertyExcel?' +
        $.param({
          title: title
        });

      $http
        .post(uri, filter, {
          responseType: 'arraybuffer'
        })
        .then(function(rsp) {
          if(rsp.data.length == 0) {
            deferred.reject();
          } else {
            deferred.resolve(rsp.data);
          }
        }, function(err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function getCommunityPdf(subTitle, filterScope, dataToPost) {
      var deferred = $q.defer();
      var filter = buildCommunityFilter(filterScope);
      var uri = apiSettings.url + '/trends/generateCommunityPdf?' +
        $.param({
          subTitle: subTitle
        });

      dataToPost["communityTypeFilter"] = filter;

      $http
        .post(uri, dataToPost, {
          responseType: 'arraybuffer'
        })
        .then(function(rsp) {
          if(rsp.data.length == 0) {
            deferred.reject();
          } else {
            deferred.resolve(rsp.data);
          }
        }, function(err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function getCommunityExcel(title, filterScope) {
      var deferred = $q.defer();
      var filter = buildCommunityFilter(filterScope);
      var uri = apiSettings.url + '/trends/generateCommunityExcel?' +
        $.param({
          title: title
        });

      $http
        .post(uri, filter, {
          responseType: 'arraybuffer'
        })
        .then(function(rsp) {
          if(rsp.data.length == 0) {
            deferred.reject();
          } else {
            deferred.resolve(rsp.data);
          }
        }, function(err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function logResetClick(stateName) {
      var deferred = $q.defer();
      stateName = stateName.replace('trends.', '');
      var uri = apiSettings.url + '/trends/' + stateName + '/reset';

      $http
        .post(uri)
        .then(function(rsp) {
          deferred.resolve(rsp);
        }, function(err) {
          deferred.reject(err);
        });

      return deferred.promise;
    }

    function buildFilter(filterScope) {
      if(!filterScope) {
        filterScope = {
          propertyType: {
            id: 0
          }
        };
      }

      var countyIds;
      if(filterScope.countyIds && 
        filterScope.countyIds.length && 
        filterScope.cbsaCodes &&
        filterScope.cbsaCodes.length) {
        countyIds = filterScope.countyIds;
      }

      var filter = {
        propertyType: filterScope.propertyType,
        campusTypes: filterScope.campusTypes,
        stabilityStatus: parseStatus(filterScope.stabilityStatus),
        profitStatus: parseStatus(filterScope.profitStatus),
        propertyAges: filterScope.propertyAges,
        market: filterScope.market,
        regionIds: filterScope.regionIds || [],
        cbsaCodes: filterScope.cbsaCodes || [],
        countyIds: countyIds || [],
        vintageId: filterScope.vintageId
      }
      
      return filter;
    }

    function buildCommunityFilter(filterScope) {
      if(!filterScope) {
        filterScope = {
          communityType: null,
          communityCampusTypes: null,
        };
      }

      var countyIds;
      if(filterScope.countyIds && 
        filterScope.countyIds.length && 
        filterScope.cbsaCodes &&
        filterScope.cbsaCodes.length) {
        countyIds = filterScope.countyIds;
      }
      
      var filter = {
        communityType: filterScope.communityType,
        campusTypes: filterScope.campusTypes,
        stabilityStatus: parseStatus(filterScope.stabilityStatus),
        profitStatus: parseStatus(filterScope.profitStatus),
        propertyAges: filterScope.propertyAges,
        market: filterScope.market,
        regionIds: filterScope.regionIds || [],
        cbsaCodes: filterScope.cbsaCodes || [],
        countyIds: countyIds || [],
        vintageId: filterScope.vintageId
      }

      return filter;
    }

    function parseStatus(val) {
      if (val === undefined ||
          val === null ||
          val === 'null') 
        return null;
      if (val.toString().toLowerCase() == 'false' ||
          val.toString().toLowerCase() == 'true')
        return val;
    	return null;
    }

    function getFilterText(filterScope, currentQtr, classicFilters) {
      if(!filterScope) {
        return "";
      }
      var filterText = "";
      // Geography section
      if(filterScope.countyIds && 
        filterScope.countyIds.length && 
        filterScope.cbsaCodes &&
        filterScope.cbsaCodes.length) {
        _.each(filterScope.countyIds, function(countyId, idx) {
          if(idx > 0) {
            filterText += ", ";
          }
          filterText = getFriendlyNameForFilterText(filterText, classicFilters.counties, countyId, 'fipsCode', 'description', null);
        });
      } else if(filterScope.cbsaCodes && filterScope.cbsaCodes.length) {
        _.each(filterScope.cbsaCodes, function(cbsaCode, idx) {
          if(idx > 0) {
            filterText += ", ";
          }
          filterText = getFriendlyNameForFilterText(filterText, classicFilters.metros, cbsaCode, 'cbsaCode', 'cbsaName', null);
        });
      } else if(filterScope.regionIds && filterScope.regionIds.length) {
        _.each(filterScope.regionIds, function(regionId, idx) {
          if(idx > 0) {
            filterText += ", ";
          }
          filterText = getFriendlyNameForFilterText(filterText, classicFilters.regions, regionId, 'id', 'name', null);
        });
      } else if(filterScope.market != 4 && filterScope.market !== undefined) {
        filterText = getFriendlyNameForFilterText(filterText, classicFilters.markets, filterScope.market, 'value', 'text', null);
      } else {
        filterText = "Primary and Secondary Markets";
      }

      if(filterScope.hasOwnProperty('propertyType')) {
        filterText = getFriendlyNameForFilterText(filterText, classicFilters.propertyTypes, filterScope.propertyType, 'id', 'description', '-');
      } else if(filterScope.hasOwnProperty('communityType')) {
        filterText = getFriendlyNameForFilterText(filterText, classicFilters.communityTypes, filterScope.communityType, 'communityByPluralityID', 'communityByPlurality', '-');
      } else {
        // Extra case...
      }

      if(filterScope.campusTypes && filterScope.campusTypes.length >= 1) {
        for(var i = 0; i < filterScope.campusTypes.length; i++) {
          if(i == 0) {
            filterText += " - ";
          } else {
            filterText += ", ";
          }
          if(filterScope.hasOwnProperty('propertyType')){
            // Property Type tab
            filterText = getFriendlyNameForFilterText(filterText, classicFilters.campusTypes, filterScope.campusTypes[i], 'id', 'description', null);
          } else if(filterScope.hasOwnProperty('communityType')){
            // Community Type tab
            filterText = getFriendlyNameForFilterText(filterText, classicFilters.communityCampusTypes, filterScope.campusTypes[i], 'id', 'description', null);
          } else {
            // Extra case...
          }
        }
        filterText += " Campuses";
      }

      if(filterScope.profitStatus != undefined &&
         filterScope.profitStatus != null && 
         filterScope.profitStatus != 'null') {
        filterText = getFriendlyNameForFilterText(filterText, classicFilters.profitStatus, filterScope.profitStatus.toString().toLowerCase(), 'id', 'description', '|');
      }
      if(filterScope.propertyAges) {
        filterText = getFriendlyNameForFilterText(filterText, classicFilters.propertyAges, filterScope.propertyAges, 'id', 'description', '|');
      }
      if(filterScope.stabilityStatus != undefined &&
         filterScope.stabilityStatus != null && 
         filterScope.stabilityStatus != 'null') {
        filterText = getFriendlyNameForFilterText(filterText, classicFilters.stabilityStatus, filterScope.stabilityStatus.toString().toLowerCase(), 'id', 'description', '|');
      }

      if (filterScope.vintageId != undefined &&
        filterScope.vintageId != null &&
        filterScope.vintageId != 'null' &&
        filterScope.vintageId == '2') {
        var timeframeStart = _.find(classicFilters.vintages, function (t) { return t.vintageId == filterScope.vintageId; }).vintageTimeFrameStart;
        filterText += " | " + timeframeStart;
      } else {
        filterText += " | " + currentQtr;
      }
      
    return filterText;
    }

    function getFriendlyNameForFilterText(filterText, arrWAllInitialData, valueToCheck, fieldToCheckAgainst, fieldToAppend, separator) {
      $.each(arrWAllInitialData, function(k, v) {
        var fieldToCompare;
        switch(fieldToCheckAgainst) {
          case 'fipsCode':
            fieldToCompare = v.fipsCode;
            break;
          case 'cbsaCode':
            fieldToCompare = v.cbsaCode;
            break;
          case 'id':
            fieldToCompare = v.id;
            break;
          case 'value':
            fieldToCompare = v.value;
            break;
          case 'communityByPluralityID':
            fieldToCompare = v.communityByPluralityID;
            break;
          case 'code':
            fieldToCompare = v.code;
            break;
        }
        if(fieldToCompare == valueToCheck) {
          var valueToAppend;
          switch(fieldToAppend) {
            case 'description':
              valueToAppend = v.description;
              break;
            case 'cbsaName':
              valueToAppend = v.cbsaName;
              break;
            case 'name':
              valueToAppend = v.name;
              break;
            case 'text':
              valueToAppend = v.text;
              break;
            case 'communityByPlurality':
              valueToAppend = v.communityByPlurality;
              break;
          }
          filterText += (separator ? " " + separator + " " : "") + valueToAppend;
          return false;
        }
      });
      return filterText;
    }

    function getCurrentQuarter() {
      var url = apiSettings.url + "/trends/currentQuarter";
      var deferred = $q.defer();
      $http.get(url, { cache: trendsCache })
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function getCurrentIQ() {
      var url = apiSettings.url + "/trends/currentIQ";
      var deferred = $q.defer();
      $http.get(url)
        .then(function (rsp) {
          deferred.resolve(rsp.data);
        }, function (err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function getMarkets() {
      var url = apiSettings.url + "/trends/markets";
      var deferred = $q.defer();
      $http.get(url, { cache: trendsCache })
        .then(function (rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function getCommunityTypes() {
      var url = apiSettings.url + "/trends/communitytypes";
      var deferred = $q.defer();
      $http.get(url, { cache: trendsCache })
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function getCommunityCampusTypes(communityType) {
      var url = apiSettings.url + "/trends/communitycampustypes?communityID=" + communityType;
      var deferred = $q.defer();
      $http.get(url, { cache: trendsCache })
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function getAgeCategory() {
      var url = apiSettings.url + "/trends/propertyages";
      var deferred = $q.defer();
      $http.get(url, { cache: trendsCache })
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function getPaymentMethods() {
      var url = apiSettings.url + "/trends/paymentmethods";
      var deferred = $q.defer();
      $http.get(url, { cache: trendsCache })
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function getSavedPropertyTypeFilters(useCache) {
      var url = apiSettings.url + "/trends/filters/saved/PropertyType/" + useCache;
      var deferred = $q.defer();
      $http.get(url)
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function getSavedCommunityTypeFilters(useCache) {
      var url = apiSettings.url + "/trends/filters/saved/CommunityType/" + useCache;
      var deferred = $q.defer();
      $http.get(url)
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function getSavedPropertyTypeFilter(name) {
      var url = apiSettings.url + "/trends/filters/PropertyType/" + name;
      var deferred = $q.defer();
      $http.get(url)
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function getSavedPropertyTypeFilterByQueryID(queryID) {
      var url = apiSettings.url + "/trends/filters/PropertyType/" + queryID;
      var deferred = $q.defer();
      $http.get(url)
        .then(function (rsp) {
          deferred.resolve(rsp.data);
        }, function (err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function getSavedCommunityTypeFilter(name) {
      var url = apiSettings.url + "/trends/filters/CommunityType/" + name;
      var deferred = $q.defer();
      $http.get(url)
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function getSavedCommunityTypeFilterByQueryID(queryID) {
      var url = apiSettings.url + "/trends/filters/CommunityType/" + queryID;
      var deferred = $q.defer();
      $http.get(url)
        .then(function (rsp) {
          deferred.resolve(rsp.data);
        }, function (err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function createSavedPropertyTypeFilter(filterScope, filterName) {
      var url = apiSettings.url + "/trends/filters/PropertyType/" + filterName;
      var deferred = $q.defer();
      $http.post(url, filterScope)
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function createSavedCommunityTypeFilter(filterScope, filterName) {
      var url = apiSettings.url + "/trends/filters/CommunityType/" + filterName;
      var deferred = $q.defer();
      $http.post(url, filterScope)
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function deleteSavedPropertyTypeFilter(filterName) {
      var url = apiSettings.url + "/trends/filters/PropertyType/" + filterName;
      var deferred = $q.defer();
      $http.delete(url)
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function deleteSavedCommunityTypeFilter(filterName) {
      var url = apiSettings.url + "/trends/filters/CommunityType/" + filterName;
      var deferred = $q.defer();
      $http.delete(url)
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function getStabilityStatus() {
      return [
        getDescriptionIdObj("All Stability Statuses", null),
        getDescriptionIdObj("Stabilized", "true"),
        getDescriptionIdObj("Non-Stabilized", "false")
      ];
    };

    function getProfitStatus() {
      return [
        getDescriptionIdObj("All Profit Statuses", null),
        getDescriptionIdObj("Not for Profit", "false"),
        getDescriptionIdObj("Profit", "true")
      ];
    };

    function getDescriptionIdObj(d, i) {
      return {
        description: d,
        id: i
      };
    }

    function getReportingPeriodTypes() {
      var url = apiSettings.url + "/trends/vintages";
      var deferred = $q.defer();
      $http.get(url)
        .then(function (rsp) {
          deferred.resolve(rsp.data);
        }, function (err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function subHasAccessToFilters(filterScope) {
      var url = apiSettings.url + "/trends/subHasAccessToFilters";
      var deferred = $q.defer();
      $http.post(url, filterScope)
        .then(function(rsp) {
          console.log(rsp);
          deferred.resolve(rsp.data);
        }, function(err) {
          console.error(err);
          deferred.reject(err);
        });
      return deferred.promise;
    }

    return {
      getFilterData: getFilterData,
      getFilteredMetros: getFilteredMetros,
      getFilteredCounties: getFilteredCounties,
      getChartData: getChartData,
      getCommunityChartData: getCommunityChartData,
      getPropertyExcel: getPropertyExcel,
      getCommunityExcel: getCommunityExcel,
      getPropertyPdf: getPropertyPdf,
      getCommunityPdf: getCommunityPdf,
      logResetClick: logResetClick,
      getFilterText: getFilterText,
      getCurrentQuarter: getCurrentQuarter,
      getCurrentIQ: getCurrentIQ,
      buildFilter: buildFilter,
      getMarkets: getMarkets,
      getAgeCategory: getAgeCategory,
      getPaymentMethods: getPaymentMethods,
      getCommunityTypes: getCommunityTypes,
      getCommunityCampusTypes: getCommunityCampusTypes,
      getProfitStatus: getProfitStatus,
      getStabilityStatus: getStabilityStatus,
      getSavedPropertyTypeFilter: getSavedPropertyTypeFilter,
      getSavedPropertyTypeFilters: getSavedPropertyTypeFilters,
      createSavedPropertyTypeFilter: createSavedPropertyTypeFilter,
      deleteSavedPropertyTypeFilter: deleteSavedPropertyTypeFilter,
      getSavedCommunityTypeFilter: getSavedCommunityTypeFilter,
      getSavedCommunityTypeFilters: getSavedCommunityTypeFilters,
      createSavedCommunityTypeFilter: createSavedCommunityTypeFilter,
      deleteSavedCommunityTypeFilter: deleteSavedCommunityTypeFilter,
      getSavedCommunityTypeFilterByQueryID: getSavedCommunityTypeFilterByQueryID,
      getSavedPropertyTypeFilterByQueryID: getSavedPropertyTypeFilterByQueryID,
      getReportingPeriodTypes: getReportingPeriodTypes,
      subHasAccessToFilters: subHasAccessToFilters
    };
  }
})();
