(function () {
  "use strict";
  app.controller('TrendsController', TrendsController);

  TrendsController.$inject = ['$scope', '$rootScope', '$state', '$q', '$sce', 'TrendsService', 'SearchService', 'SpinnerService', 'PopupHandlerService', 'DataAttributionService'];

  function TrendsController($scope, $rootScope, $state, $q, $sce, TrendsService, SearchService, SpinnerService, PopupHandlerService, DataAttributionService) {
    var primaryAndSecondaryMarket = 4;
    var child = {};

    $rootScope.hideLeftNav = true; //when you enter trends the left nav should collapse
    $scope.promisesCompleted = false;
    $scope.attributionHtml = "";

    $scope.propertyTypeDesc = "Property Type is the industry’s traditional categorization where Memory Care (MC) and CCRC/LPCs are not distinct classifications.";
    $scope.communityTypeDesc = "Community Type is a new categorization where both Memory Care (MC) and CCRC/LPCs are distinct classifications.";
    //initalization
    $scope.$on('chartReady', chartReady);
    $scope.$on('chartLoaded', chartLoaded);
    $scope.$on('chartLoading', chartLoading);
    $scope.$on('reset', reset);
    setCurrentTab();
    init();
    
    //when the chartController (not the chart data) is ready will trigger this. This allows a callback from this parent controller to the chart controller
    function chartReady(event, payload) {
      child.chartLoaded = payload.chartLoaded;
      child.chartLoading = payload.chartLoading;
      event.stopPropagation();
    }

    function setCurrentTab() {
      if($state.current.name == 'trends.communityType') {
        $scope.communityTabActive = true;
        return;
      }
      if($state.current.name == 'trends.actualRents') {
        $scope.actualRentsActive = true;
        return;
      }
      //by default the first tab will be selected
    }

    function init() {
      SpinnerService.start();

      $q.all([
        TrendsService.getFilterData('propertyTypes'),
        TrendsService.getFilterData('campusTypes'),
        TrendsService.getFilterData('regions'),
        TrendsService.getFilterData('metros'),
        TrendsService.getCurrentQuarter(),
        TrendsService.getMarkets(),
        TrendsService.getAgeCategory(),
        TrendsService.getCommunityTypes(),
        TrendsService.getCommunityCampusTypes('communityTypes'),
        TrendsService.getProfitStatus(),
        TrendsService.getStabilityStatus(),
        DataAttributionService.getAttributionHtml(),
        DataAttributionService.getDataServiceSourceHtml(),
        TrendsService.getReportingPeriodTypes(),
      ]).then(function(responses){
        $scope.propertyTypes = responses[0];
        $scope.campusTypes = _.orderBy(responses[1], 'id');
        $scope.regions = responses[2];
        $scope.metros = responses[3];
        $scope.currentQuarter = responses[4];
        $scope.markets = marketText(moveMarkets(responses[5])); 
        $scope.propertyAges = responses[6];
        $scope.communityTypes = responses[7];
        $scope.communityCampusTypes = responses[8];
        $scope.profitStatus = responses[9];
        $scope.stabilityStatus = responses[10];
        var attributionHtml = $sce.trustAsHtml(responses[11]);
        var dataServiceSource = $sce.trustAsHtml(responses[12]);
        $scope.vintages = responses[13];

        $scope.attributionHtml = dataServiceSource + ' ' + attributionHtml;
        $scope.promisesCompleted = true;
      })
      .catch(function (error) {
        // error
        PopupHandlerService.openSimplePopup("There was an error when trying to load the page. Please try again or contact us.");
      })
      .finally(function () {
        SpinnerService.stop();
      });
    }

    function chartLoading(event) {
      if(child.chartLoading) {
        child.chartLoading();
      }
      event.stopPropagation();
    }

    function chartLoaded(event, payload) {
      if(child.chartLoaded) {
        child.chartLoaded(payload);
      }
      event.stopPropagation();
    }

    function reset() {
      if(child.chartLoading) {
        child.chartLoading();
      }

      TrendsService.logResetClick($state.current.name);

      $state.go($state.current.name, {}, {
        reload: true,
        inherit: false
      });
    }

    function moveMarkets(arrMarkets){
      return _.concat(_.takeRight(arrMarkets), _.remove(arrMarkets, function(obj){
          if(obj.value == 4){
            return;
          }
          return obj;
        }));
    }

    function marketText(arrMarkets) {
      return _.forEach(arrMarkets, function (value, key) {
        if (value.value == primaryAndSecondaryMarket || /Markets$/i.test(value.text)) {
          return;
        } else {
          value.text = value.text + " Markets";
          return;
        }
      });
    }
  }
})();
