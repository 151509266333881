(function() {
  "use strict";

  app.factory("BingMapUtils", bingMapUtils);

  function bingMapUtils() {

    function changeZoom(map, z){
      map.setView({
        zoom: z
      });
    }

    function centerMap(map, bbox) {
      //is it the same???
      var rect = Microsoft.Maps.LocationRect.fromEdges(bbox[0], bbox[1], bbox[2], bbox[3]);
      map.setView({
        bounds: rect,
        animate:false
      });
    }

    function centerMapToLocations(map, locs) {
      if (locs.length > 0) {
        var rect = Microsoft.Maps.LocationRect.fromLocations(locs);
        map.setView({
          bounds: rect,
          animate: false,
        });
      }
    }

    function buildCircle(latin, lonin, radius) {
      var locs = new Array();
      var lat1 = latin * Math.PI / 180.0;
      var lon1 = lonin * Math.PI / 180.0;
      var d = radius / 3956;
      var x;
      for (x = 0; x <= 360; x += 1) {
        var tc = (x / 90) * Math.PI / 2;
        var lat = Math.asin(Math.sin(lat1) * Math.cos(d) + Math.cos(lat1) * Math.sin(d) * Math.cos(tc));
        lat = 180.0 * lat / Math.PI;
        var lon;
        if (Math.cos(lat1) == 0) {
          lon = lonin; // endpoint a pole
        } else {
          lon = ((lon1 - Math.asin(Math.sin(tc) * Math.sin(d) / Math.cos(lat1)) + Math.PI) % (2 * Math.PI)) - Math.PI;
        }
        lon = 180.0 * lon / Math.PI;
        locs[x] = {
          "latitude": lat,
          "longitude": lon
        };
      }

      return locs;
    }

    function boundsFromMap(bingMap){
      var bounds = bingMap.getBounds();
      var bbox = [bounds.getNorth(), bounds.getWest(), bounds.getSouth(), bounds.getEast()];
      var latitude = bounds.center.latitude;
      var longitude = bounds.center.longitude;
      return {
        bbox:bbox,
        latitude:latitude,
        longitude:longitude
      }
    }

    function getCurrentZoom(bingMap) {
      return bingMap.getZoom();
    }

    function boundsFromPushpins(bingMap, pushpins){
      var bestView = {};
      var locs = [];
      $.each(pushpins, function(index, value) {
        var loc = new Microsoft.Maps.Location(value.latitude, value.longitude);
        locs.push(loc);
      });
      bestView = Microsoft.Maps.LocationRect.fromLocations(locs);
      bingMap.setView({bounds:bestView});
    }

    function getPolygonOptions(id, locations) {
      var options = {
        id: id,
        locations: locations,
        strokeColor: '#00FF00',
        fillColor: 'rgba(0,51,102,0.3)',
      };

      return options;
    }

    function getDefaultViewOptions(zoom, centerLatitude, centerLongitude) {
      var options = {
        mapType: 'r',
        options: {
          disablePanning: false,
          disableZooming: false,
          showScalebar: false,
          enableClickableLogo: false,
          enableSearchLogo: false,
          navigationBarMode: Microsoft.Maps.NavigationBarMode.compact,
          disableStreetside: true
        }
      };

      if (centerLatitude && centerLongitude) {
        options.center = {
          latitude: centerLatitude,
          longitude: centerLongitude
        }
      }

      if (zoom) {
        options.zoom = 10;
      }

      return options;
    }
    
    //Client Side Polygon Search
    //https://msdn.microsoft.com/en-us/library/cc451895.aspx
    function pointInPolygon(points,lat,lon) {
      var i;
      var j=points.length-1;
      var inPoly=false;

      for (i=0; i<points.length; i++) 
      {
        if (points[i].longitude<lon && points[j].longitude>=lon 
          || points[j].longitude<lon && points[i].longitude>=lon) 
        {
          if (points[i].latitude+(lon-points[i].longitude)/ 
            (points[j].longitude-points[i].longitude)*(points[j].latitude 
              -points[i].latitude)<lat) 
          {
            inPoly=!inPoly; 
          }
        }
        j=i; 
      }
      return inPoly; 
    }

    return {
      centerMap: centerMap,
      centerMapToLocations: centerMapToLocations,
      buildCircle: buildCircle,
      boundsFromMap: boundsFromMap,
      getCurrentZoom: getCurrentZoom,
      boundsFromPushpins: boundsFromPushpins,
      getPolygonOptions: getPolygonOptions,
      getDefaultViewOptions: getDefaultViewOptions,
      pointInPolygon: pointInPolygon,
      changeZoom: changeZoom
    };
  };
})();
