(function() {
  "use strict";

  app.controller('UserPortfolioController', UserPortfolioController);

  UserPortfolioController.$inject = ['$rootScope', '$scope', '$state', '$window', 'data', 'segmentTypes', 'currentQuarter', 'PortfolioService', 'FileSaver', 'isSubscriber', 'AuditService', 'knownEventConstant', 'knownProductConstant', 'SpinnerService', 'SignalRService', '$timeout', 'CSV'];

  function UserPortfolioController($rootScope, $scope, $state, $window, data, segmentTypes, currentQuarter, PortfolioService, FileSaver, isSubscriber, AuditService, knownEventConstant, knownProductConstant, SpinnerService, SignalRService, $timeout, CSV) {
    var onDetailsPage = $state.current.name.indexOf('.details') >= 0;

    $scope.pageConfig = {
      onDetailsPage: onDetailsPage,
      header: onDetailsPage ? "Property Details" : "Summary",
      selectHeader: onDetailsPage ? "Subject Properties" : "Master/Sub Portfolios",
      searchPlaceholder: onDetailsPage ? "property" : "portfolio",
      leftListCssOverride: onDetailsPage ? "portfolio-list-details" : "",
      leftAnchorNav: onDetailsPage ? 'propertyCompare' : 'portfolios.details'
    };

    if (data && data[0] && data[0].portfolioName) {
      // append portfolio name
      $scope.pageConfig.selectedName = data[0].portfolioName;
    }
    if (data && data[0] && data[0].name) {
      // append portfolio name
      $scope.pageConfig.selectedName = data[0].name;
    }

    $scope.isRawDataFileTrendedSubscriber = isSubscriber;
    $scope.leftListItemClick = leftListItemClick;
    $scope.leftSelectableReportClick = leftSelectableReportClick;
    $scope.displayData = displayData;
    $scope.exportExcel = exportExcel;

    $scope.search = '';    
    if (!onDetailsPage && data && data[0] && !data[0].subPortfolios) {
      $scope.data = PortfolioService.transformFlatPortfoliosArrayToMasterSubTree(data);
    }
    else {
      $scope.data = _.sortBy(data, ['name']);
    }
    
    $scope.filters = {
      segmentTypes: segmentTypes
    };
    $rootScope.hideLeftNav = true;
    $scope.vm = {}; //vm.selectedPortfolioId is the selected portfolio id. for could put directly on scope because of prototypical inheritace inside the ng-repeat
    $scope.filter = {
      segmentType: "SH"//default selection of Senior Housing
    };
    $scope.segmentTypeChanged = segmentTypeChanged;
    $scope.$watch("vm.selectedId", selectionChanged);

    if (($state.current.name == "portfolios" || $scope.pageConfig.onDetailsPage) && data && data.length) {
      $scope.vm.selectedId = _.minBy(data, function (item) { return item.name.toLowerCase(); }).id;
      update($scope.data, {id: $scope.vm.selectedId}, {highlighted: true});
    } else if ($state.params.id) {
      $scope.vm.selectedId = onDetailsPage ? parseInt($state.params.propertyId) : parseInt($state.params.id);
      update($scope.data, {id: $scope.vm.selectedId}, {highlighted: true});
    }

    function exportExcel() {
      SpinnerService.start();
      var promise;
      var name = $scope.selectedItemData ?  $scope.selectedItemData.name : '';
      if (onDetailsPage) {
        promise = PortfolioService.exportPropertyDetails($state.params.id, $state.params.propertyId);
        AuditService.logEvent(knownEventConstant.exportPortfolioPropDetails.id,
          knownEventConstant.exportPortfolioPropDetails.message + " Excel - " + name + " PortfolioId: " + $state.params.id, knownProductConstant.port, $state.params.propertyId);
      } else {
        promise = PortfolioService.exportPortfolio($state.params.id);
        AuditService.logEvent(knownEventConstant.exportPortfolioSummary.id,
          knownEventConstant.exportPortfolioSummary.message + " Excel - " + name + " PortfolioId: " + $state.params.id, knownProductConstant.port);
      }
      
      promise
        .then(function(buffer) {
          var file = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          });

          SpinnerService.stop();
          var fileName = 'NICMAP_Portfolio_' + _.words(name).join('_') + '_' + currentQuarter + '.xlsx';
          FileSaver.saveAs(file, fileName);
        });
    }

    $scope.exportTrendedCsv = function () {
      SpinnerService.start();

      SignalRService.genericHub('RawTrendedGenerated', $scope, function(rawTrendResult, portfolioId) {
        if (portfolioId != $state.params.id) return;
      
        var options = {
          fieldSep: ",",
          txtDelim: '"',
          decimalSep: '.',
          addByteOrderMarker: false,
          header: [
            "PortfolioID", "PropertyID", "ContributorID", "PropertyName", "Metro", "PropertyTypeDesc", "CampusTypeDesc",
            "CompProperties", "ReportingPeriod", "Property_SH_Occupancy", "Property_SH_UnitRent",
            "Property_SH_NbrUnits", "Property_IL_Occupancy", "Property_IL_UnitRent", "Property_IL_NbrUnits",
            "Property_AL_Occupancy", "Property_AL_UnitRent", "Property_AL_NbrUnits", "Property_MC_Occupancy",
            "Property_MC_UnitRent", "Property_MC_NbrUnits", "Property_NC_Occupancy", "Property_NC_BedRent",
            "Property_NC_NbrBeds", "CompSet_SH_Occupancy", "CompSet_SH_UnitRent", "CompSet_SH_NbrUnits",
            "CompSet_IL_Occupancy", "CompSet_IL_UnitRent", "CompSet_IL_NbrUnits", "CompSet_AL_Occupancy",
            "CompSet_AL_UnitRent", "CompSet_AL_NbrUnits", "CompSet_MC_Occupancy", "CompSet_MC_UnitRent",
            "CompSet_MC_NbrUnits", "CompSet_NC_Occupancy", "CompSet_NC_BedRent", "CompSet_NC_NbrBeds"
          ]
        };
        options.fieldSep = CSV.isSpecialChar(options.fieldSep) ? CSV.getSpecialChar(options.fieldSep) : options.fieldSep;
        var dataHolder = JSON.parse(rawTrendResult.value);
        CSV.stringify(dataHolder.data, options)
          .then(function(result) {
            var blob = new Blob([result], {
              type: "text/csv;charset=utf-8;"
            });
            var fileName = 'NICMAP_Portfolios_Raw_Data_File_(Trended)_' + currentQuarter + '.csv';

            var portfolioName = _.find($scope.data, function (i) { return i.id == $scope.vm.selectedId }).name;
            AuditService.logEvent(knownEventConstant.portfolioReportRawDataFileTrended.id,
                                  knownEventConstant.portfolioReportRawDataFileTrended.message + " - " + portfolioName + "," + $state.params.id + " fileName - " + fileName,
                                  knownProductConstant.port,
                                  $state.params.propertyId);

            FileSaver.saveAs(blob, fileName);
          })
          .finally(function () {
            // since this is a ui-view page, we have to make sure that we only attach the event once and remove it when done
            SignalRService.removeHubEvent('RawTrendedGenerated');
            SpinnerService.stop();
          });
      });

      getTrendedCSVData();
    } 

    function getTrendedCSVData() {
      PortfolioService.exportRawDataFileTrended($state.params.id)
        .then(function(rsp) {
          //Handled by SignalR
        }, function(err) {
          console.log(err);
        });
    }

    function displayData(dataItemName) {
      return $scope.search.length == 0 || dataItemName.indexOf($scope.search) >= 0;
    }

    function selectionChanged(newValue, oldValue) {
      var addDetailsState = $scope.pageConfig.onDetailsPage ? '.details' : '';
      $scope.selectedItemData = _.find(data, { id: $scope.vm.selectedId });
      if ($state.current.name.indexOf('.charts') < 0 || oldValue != newValue)
        if (newValue) {
          var idSelect = $scope.pageConfig.onDetailsPage ? { propertyId: newValue } : { id: newValue };
          update($scope.data, {id: oldValue}, {highlighted: false});
          update($scope.data, {id: newValue}, {highlighted: true});
          $state.go('portfolios' + addDetailsState + '.charts', idSelect);
        } else if (newValue === false) //unchecking the current selection
          $state.go('portfolios' + addDetailsState);
    }

    function childStateSetsDataIdOnRefresh(event, selectedId) {
      if (!$scope.vm.selectedId) {
        $scope.vm.selectedId = parseInt(selectedId);
      }
      event.stopPropagation();
    }

    function segmentTypeChanged() {
      AuditService.logEvent(knownEventConstant.selectSegment.id, knownEventConstant.selectSegment.message + " - " + $scope.filter.segmentType + " PortfolioId: " + $state.params.id, knownProductConstant.port, $state.params.propertyId);
      //let the children know the segmentTypeChanged
      $scope.$broadcast("segmentTypeChanged", $scope.filter.segmentType);
    }

    function leftListItemClick(dataItem) {
      if (onDetailsPage) {
        AuditService.logEvent(knownEventConstant.propertyCompareReport.id, knownEventConstant.propertyCompareReport.message + " - " + dataItem.portfolioName + "," + $state.params.id + " Name - " + dataItem.name, knownProductConstant.port, dataItem.id);
        // property detail page... so need to open new tab for property compare
        $window.open(
          $state.href($scope.pageConfig.leftAnchorNav, {
            portfolioId: $state.params.id, 
            propertyId: dataItem.id,
            propertyGuid: dataItem.propertyGuid
          }, { absolute: true }), '_blank');
      } else {
        AuditService.logEvent(knownEventConstant.selectPortfolioDetails.id, knownEventConstant.selectPortfolioDetails.message + ": Id - " + dataItem.id + " Name - " + dataItem.name, knownProductConstant.port);
        // on portfolios page... need to show detail of portfolio... so stay on the same page
        $state.go($scope.pageConfig.leftAnchorNav, { id: dataItem.id });
      }
    }

    function leftSelectableReportClick(productName) {
      var portfolioName = _.find($scope.data, function (i) { return i.id == $scope.vm.selectedId }).name;
      var fileName = "Report Under Development";
      var selectedId = "";
      var selectedMessage = "";
      switch (productName) {
        case 'PropSegment':
          selectedId = knownEventConstant.portfolioReportCorporateCompare.id;
          selectedMessage = knownEventConstant.portfolioReportCorporateCompare.message;
          break;
        case 'PropCBSASegment':
          selectedId = knownEventConstant.portfolioReportMetroComparison.id;
          selectedMessage = knownEventConstant.portfolioReportMetroComparison.message;
          break;
        case 'PropStateSegment':
          selectedId = knownEventConstant.portfolioReportStateComparison.id;
          selectedMessage = knownEventConstant.portfolioReportStateComparison.message;
          break;
        case 'PropSegmentTrend':
          selectedId = knownEventConstant.portfolioReportAllProperties.id;
          selectedMessage = knownEventConstant.portfolioReportAllProperties.message;
          break;
        case 'RawDataPropList':
          selectedId = knownEventConstant.portfolioReportRawDataFile.id;
          selectedMessage = knownEventConstant.portfolioReportRawDataFile.message;
          break;
        case 'RawDataSegmentTrends':
          selectedId = knownEventConstant.portfolioReportRawDataFileTrended.id;
          selectedMessage = knownEventConstant.portfolioReportRawDataFileTrended.message;
          break;
        case 'Bandwidth':
          selectedId = knownEventConstant.portfolioReportPCR.id;
          selectedMessage = knownEventConstant.portfolioReportPCR.message;
          break;
        
        default:
          break;
      }
      AuditService.logEvent(selectedId,
                            selectedMessage + " - " + portfolioName + "," + $state.params.id + " fileName - " + fileName,
                            knownProductConstant.port,
                            $state.params.propertyId);
    }

    function logInit() {
      if (onDetailsPage) {
        AuditService.logEvent(knownEventConstant.selectPortfolio.id,
          knownEventConstant.selectPortfolio.message +
          " - " +
          $scope.pageConfig.header +
          "," +
          $state.params.id +
          " - " +
          dataItem.name,
          knownProductConstant.port,
          dataItem.id);
      } else {
        AuditService.logEvent(knownEventConstant.selectPortfolio.id,
          knownEventConstant.selectPortfolio.message +
          " - " +
          dataItem.portfolioName +
          "," +
          $state.params.id +
          " - " +
          dataItem.name,
          knownProductConstant.port,
          dataItem.id);
      }
      logInit();
    }

    function update(arr, key, newval) {
      var match = _.find(arr, key);
      if(match)
        _.merge(match, newval);
      else {
        //could use a refactor to not find twice, but couldn't find anything online that worked
        var subCheck = _.find(arr, {subPortfolios: [key]})
        if(subCheck && subCheck.subPortfolios)
          _.merge(_.find(subCheck.subPortfolios, key), newval);
      }
    }
  }  
}());
