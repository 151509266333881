/*!
 * Copyright 2013 Phil DeJarnett - http://www.overzealous.com
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

(function () {
  "use strict";

  app.factory("NaturalSort", NaturalSort);
  
  NaturalSort.$inject = [];
  
  function NaturalSort() {
    function compare(a, b) {
      var aConverted = getSortableValue(a);
      var bConverted = getSortableValue(b);
      return (aConverted < bConverted) ? -1 : ((aConverted > bConverted) ? 1 : 0);
    }

    function padding(value) {
      // amount of extra zeros to padd for sorting
      return "00000000000000000000".slice(value.length);
    }

    function getSortableValue(value) {
      if (value == undefined || value == null)
        return 0;

      // First, look for anything in the form of d.d or d.d.d...
      return value.replace(/(\d+)((\.\d+)+)?/g, function ($0, integer, decimal, $3) {
      	// If there's more than 2 sets of numbers...
        if (decimal !== $3) {
          // treat as a series of integers, like versioning,
          // rather than a decimal
          return $0.replace(/(\d+)/g, function ($d) {
            return padding($d) + $d;
          });
        } else {
          // add a decimal if necessary to ensure decimal sorting
          decimal = decimal || ".0";
          return padding(integer) + integer + decimal + padding(decimal);
        }
      });
    }

    return {
      compare: compare,
      getSortableValue: getSortableValue
    };
  }
}());