(function() {
  "use strict";

  app.factory("HtmlCacheBuster", HtmlCacheBuster);

  HtmlCacheBuster.$inject = ["buildId", '$templateCache'];

  function HtmlCacheBuster(buildId, $templateCache) {
    return {
      // On request success
      request: function(config) {
        if(!config.url) {
          return config;
        }
        if($templateCache.get(config.url)) {
          //simple way to avoid breaking bootstrap templates
          //seams cleaner than doing config.url.indexOf("uib/template") != -1 which relies on knowing implementation details of angular bootstrap templates
          return config;
        }

        if(/\.html$/i.test(config.url)) {
          config.url += "?b=" + buildId;//add on random id - which changes each time the site is built. This should force browsers to reload the HTML
        }

        return config;
      }
    }
  }
})();
