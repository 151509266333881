(function () {
  "use strict";

  app.factory("VersioningService", VersioningService);
  
  VersioningService.$inject = ['$http'];
  
  function VersioningService($http) {
    return {
      pingForVersion: pingForVersion
    };

    function pingForVersion() {
      // fire off a request to client server to see what the version comes back as.
      var d = new Date();
      var n = d.getTime();
      $http
        .get('/js/site-version.js?n=' + n)
        .then(function (rsp) {
          // The VersioningMiddleware will handle the logic of the response...
          // we dont need to have anything here.
        }, function (err) {
          // The VersioningMiddleware will handle the logic of the response...
          // we dont need to have anything here.
        });
    }
  }
})();
