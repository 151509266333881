(function() {
  "use strict";

  app.factory("FilterMapper", filterMapper);

  filterMapper.$inject = ['$q', 'BingMapUtils', 'GeoDataService'];

  function filterMapper($q, BingMapUtils, GeoDataService) {
    var service = {
      map: map,
      addGeoData: addGeoData,
      addSelectedProperties: addSelectedProperties,
      setFiltersFromSavedSearch: setFiltersFromSavedSearch,
      getDriveTimePolygons: getDriveTimePolygons,
      buildObjectListFromCheckBoxes: buildObjectListFromCheckBoxes
    };

    return service;

    //hoisting
    function getDriveTimePolygons(savedFilter, driveTimeEsriPolygons, forceTravelSearch, isButtonClickSearch, isAdvancedSearchButtonClick) {
      if ((forceTravelSearch || isButtonClickSearch || isAdvancedSearchButtonClick) &&
        savedFilter.search.driveTimeInfo && 
        savedFilter.search.driveTimeInfo.drive1 && 
        parseInt(savedFilter.search.driveTimeInfo.drive1.breakValue) && 
        savedFilter.search.driveTimeInfo.drive1.trafficFlow) {
        var defer = $q.defer();

        var travelTimeRequest = {};
        travelTimeRequest.drive1 = {
          latitude: savedFilter.search.coordinates[0],
          longitude: savedFilter.search.coordinates[1],
          breakValue: savedFilter.search.driveTimeInfo.drive1.breakValue,
          trafficFlow: savedFilter.search.driveTimeInfo.drive1.trafficFlow
        };
        if (savedFilter.search.driveTimeInfo.drive2 &&
          parseInt(savedFilter.search.driveTimeInfo.drive2.breakValue) && 
          savedFilter.search.driveTimeInfo.drive2.trafficFlow) {
          travelTimeRequest.drive2 = {
            latitude: savedFilter.search.coordinates[0],
            longitude: savedFilter.search.coordinates[1],
            breakValue: savedFilter.search.driveTimeInfo.drive2.breakValue,
            trafficFlow: savedFilter.search.driveTimeInfo.drive2.trafficFlow
          };
        } else {
          savedFilter.search.driveTimeInfo.drive2 = {
            breakValue: null,
            trafficFlow: null,
            driveTimeLocs: []
          };
        }

        GeoDataService
          .getEsriDriveTimeShapes(travelTimeRequest)
          .then(function (polygons) {
            defer.resolve(polygons);
          }, function (err) {
            defer.reject(err);
          });

        return defer.promise;
      } else {
        return $q.resolve(driveTimeEsriPolygons || []);
      }
    }

    function addGeoData(savedFilter, searchDetails, bounds) {
      var isDriveTimeOnlySearch = savedFilter.search.driveTimeInfo &&
        savedFilter.search.driveTimeInfo.drive1 &&
        parseInt(savedFilter.search.driveTimeInfo.drive1.breakValue);

      if (bounds && bounds.bbox) {
        savedFilter.search.bbox = bounds.bbox
      }
      savedFilter.search.zipCode = searchDetails.zipCode;
      savedFilter.search.stateProvince = searchDetails.stateCode;
      savedFilter.search.countyName = searchDetails.countyName;
      if(savedFilter.search.radius) {
        savedFilter.radiusLatitude = searchDetails.latitude || bounds.latitude;
        savedFilter.radiusLongitude = searchDetails.longitude || bounds.longitude;
        savedFilter.circle = BingMapUtils.buildCircle(savedFilter.radiusLatitude, savedFilter.radiusLongitude, savedFilter.search.radius);
        if(savedFilter.search.radiusOuter) {
          savedFilter.circle2 = BingMapUtils.buildCircle(savedFilter.radiusLatitude, savedFilter.radiusLongitude, savedFilter.search.radiusOuter);
        }
      } else {
        savedFilter.circle = {}
      }
      // For distance calculation in the search proc, determine where to base distances from.
      // if empty search query, rely on the center of the map bounding box,
      // otherwise use the bing result for the search query (the black pin)
      if (savedFilter.search.radius || isDriveTimeOnlySearch || !bounds) {
        savedFilter.search.coordinates = [searchDetails.latitude, searchDetails.longitude];
      } else {
        savedFilter.search.coordinates = savedFilter.query ? [searchDetails.latitude, searchDetails.longitude]
                                                           : [bounds.latitude, bounds.longitude];
      }
    }

    function setFiltersFromSavedSearch(savedFilter, campusTypes, segmentTypes, allRadiusList) {
      var recreateFilter = {
        // Common
        name: savedFilter.name,
        query: savedFilter.query || "",
        transactionOnly: savedFilter.transactionOnly,
        latitude: savedFilter.latitude,
        longitude: savedFilter.longitude,
        propertyTypeIds: savedFilter.search.propertyTypeIds,
        propertyName: savedFilter.search.propertyName,
        includeSelectedPropertyIDs: savedFilter.search.includeSelectedPropertyIDs,
        countyName: savedFilter.search.countyName,
        stateCode: savedFilter.search.stateProvince,
        zipCode: savedFilter.search.zipCode,
        vanilla: {},
        transaction: {},
        walkScoreMin: savedFilter.search.walkScoreMin,
        walkScoreMax: savedFilter.search.walkScoreMax,
        cmsScoreMin: savedFilter.search.cmsScoreMin,
        cmsScoreMax: savedFilter.search.cmsScoreMax,
        vintageId: savedFilter.search.vintageId
      };

      // bind CampusTypes
      if (savedFilter.search.campusTypeIds) {
        recreateFilter.campusTypeCheck = [];
        $.each(savedFilter.search.campusTypeIds, function (k, v) {
          recreateFilter.campusTypeCheck[v] = true;
        });
      }

      // bind drive times
      if (savedFilter.driveTimeInfo) {
        recreateFilter.driveTimeInfo = {
          drive1: {
            breakValue: savedFilter.driveTimeInfo.drive1.breakValue,
            trafficFlow: savedFilter.driveTimeInfo.drive1.trafficFlow,
            driveTimeLocs: []
          }
        };
        if (savedFilter.driveTimeInfo.drive2) {
          recreateFilter.driveTimeInfo.drive2 = {
            breakValue: savedFilter.driveTimeInfo.drive2.breakValue,
            trafficFlow: savedFilter.driveTimeInfo.drive2.trafficFlow,
            driveTimeLocs: []
          }
        }
      }

      recreateFilter.userDrawingLocs = savedFilter.userDrawingLocs || [];

      // handle custom radius and regular radius
      savedSearchRadiusToFilter(allRadiusList, savedFilter.search, recreateFilter, 'radius', 'radius1');
      savedSearchRadiusToFilter(allRadiusList, savedFilter.search, recreateFilter, 'radiusOuter', 'radius2');

      if (savedFilter.transactionOnly) {
        //transaction search only fields
        recreateFilter.transaction.saleMin = savedFilter.search.salePrices[0];
        recreateFilter.transaction.saleMax = savedFilter.search.salePrices[1];
        recreateFilter.transaction.unitMin = savedFilter.search.pricesPerUnit[0];
        recreateFilter.transaction.unitMax = savedFilter.search.pricesPerUnit[1];
        recreateFilter.transaction.minDate = savedFilter.search.saleDates[0];
        recreateFilter.transaction.maxDate = savedFilter.search.saleDates[1];
      } else {
        //vanilla search only fields
        recreateFilter.vanilla.isForProfit = savedFilter.search.isForProfit;
        recreateFilter.vanilla.operator = savedFilter.search.operator;
        recreateFilter.vanilla.propertyAgeCategoryIds = savedFilter.search.propertyAgeCategoryIds;
        if (savedFilter.search.segmentTypeIds) {
          recreateFilter.vanilla.segmentTypeCheck = [];
          $.each(savedFilter.search.segmentTypeIds, function (k, v) {
            recreateFilter.vanilla.segmentTypeCheck[v] = true;
          });
        }
        recreateFilter.vanilla.propertyStatuses = savedFilter.search.propertyStatuses;
      }

      return recreateFilter;
    }

    function savedSearchRadiusToFilter(options,search, filter, searchField, filterField) {
      if(!search[searchField]) return;
      var customFilterField = "custom" + _.capitalize(filterField);
      var valToFind = _.toNumber(search[searchField]);
      if(_.some(options, ['id', valToFind])) {
        filter[filterField] = valToFind;
      } else {
        filter[filterField] = -1;
        filter[customFilterField] = valToFind;
      }
    }

    function map(filter, campusTypes, segmentTypes, properties) {
      var savedFilter = {
        search: {
          propertyTypeIds: angular.copy(filter.propertyTypeIds), //copy because this collection constantly changes and we want a snapshot
          radius: radiusMapper(filter, 'radius1'),
          radiusOuter: radiusMapper(filter, 'radius2'),
          userDrawingLocs: filter.userDrawingLocs,
          campusTypeIds: buildObjectListFromCheckBoxes(campusTypes, filter.campusTypeCheck),
          includeSelectedPropertyIDs: angular.copy(filter.includeSelectedPropertyIDs),
          propertyName: filter.propertyName,
          walkScoreMin: filter.walkScoreMin,
          walkScoreMax: filter.walkScoreMax,
          cmsScoreMin: filter.cmsScoreMin,
          cmsScoreMax: filter.cmsScoreMax,
          vintageId: filter.vintageId,
          driveTimeInfo: {
            drive1: {
              breakValue: null,
              trafficFlow: null,
              driveTimeLocs: []
            },
            drive2: {
              breakValue: null,
              trafficFlow: null,
              driveTimeLocs: []
            },
          }
        },
        query: filter.query,
        transactionOnly: filter.transactionOnly //not used by API, but needed when trying to figure out if the properties displayed are result of vanilla or transaction search
      };

      if (filter.driveTimeInfo) {
        savedFilter.search.driveTimeInfo.drive1 = {
          breakValue: filter.driveTimeInfo.drive1.breakValue,
          trafficFlow: filter.driveTimeInfo.drive1.trafficFlow,
          driveTimeLocs: filter.driveTimeInfo.drive1.driveTimeLocs || []
        };

        if (filter.driveTimeInfo.drive2) {
          savedFilter.search.driveTimeInfo.drive2 = {
            breakValue: filter.driveTimeInfo.drive2.breakValue,
            trafficFlow: filter.driveTimeInfo.drive2.trafficFlow,
            driveTimeLocs: filter.driveTimeInfo.drive2.driveTimeLocs || []
          }
        }
      }

      if(filter.transactionOnly) {
        //transaction search only fields
        savedFilter.search.salePrices = [filter.transaction.saleMin, filter.transaction.saleMax]
        savedFilter.search.pricesPerUnit = [filter.transaction.unitMin, filter.transaction.unitMax]
        savedFilter.search.saleDates = [filter.transaction.minDate, filter.transaction.maxDate]
      } else {
        //vanilla search only fields
        savedFilter.search.propertyStatuses = _.isEmpty(filter.vanilla.propertyStatuses) ?  undefined : angular.copy(filter.vanilla.propertyStatuses);
        savedFilter.search.segmentTypeIds = buildObjectListFromCheckBoxes(segmentTypes, filter.vanilla.segmentTypeCheck, "code");
        savedFilter.search.operator = filter.vanilla.operator;
        savedFilter.search.propertyAgeCategoryIds = _.isEmpty(filter.vanilla.propertyAgeCategoryIds) ? undefined : filter.vanilla.propertyAgeCategoryIds;
        if(filter.vanilla.isForProfit != "null") { //kendo forces this to a string
          savedFilter.search.isForProfit = filter.vanilla.isForProfit;
        }
      }
      return savedFilter;
    }

    function radiusMapper(filter, field) {
      var radius;
      var customField = "custom" + _.capitalize(field);
      if(filter[field] && filter[field] != "null") {
        if(filter[field] == -1) {
          if(filter[customField]) {
            radius = filter[customField];
          }
        } else {
          radius = filter[field]
        }
      }
      return radius;
    }

    function addSelectedProperties(savedFilter, selectedProperties) {
      savedFilter.search.includeSelectedPropertyIDs = [];
      selectedProperties = selectedProperties || [];
      _.each(selectedProperties, function (v, k) {
        savedFilter.search.includeSelectedPropertyIDs.push(v);
      });
      return savedFilter.search.includeSelectedPropertyIDs;
    }

    function buildObjectListFromCheckBoxes(masterOptionList, filterList, identifierName) {
      if(identifierName == undefined)
        identifierName = 'id';
      var returnList = [];
      angular.forEach(masterOptionList, function(item) {
        var itemId = item[identifierName].toString(); //need to switch to string or else it will do an array index.
        if(filterList && filterList[itemId]) {
          returnList.push(itemId); //fill in dropdown based on advanced property search
        }
      });
      if(returnList.length === 0) {
        return null;
      }
      return returnList;
    }
  }

})();
