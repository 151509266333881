(function() {
  "use strict";
  //should probably be a PortfolioService
  app.factory("PropertyAdvisorService", PropertyAdvisorService);

  PropertyAdvisorService.$inject = ["$http", "$q", "apiSettings", 'DateOffsetUtils'];

  function PropertyAdvisorService($http, $q, apiSettings, DateOffsetUtils) {
    return {
      createReport: createReport,
      getReport: getReport,
      hasReport: hasReport,
      getGeoData: getGeoData,
      exportComps: exportComps,
      exportTransactions: exportTransactions,
      getSavedPARReports: getSavedPARReports,
      savePARReport: savePARReport,
      exportChartsData: exportChartsData,
      logExportComps: logExportComps,
      logExportOccupancy: logExportOccupancy,
      logExportAverageRent: logExportAverageRent,
      logExportRpi: logExportRpi,
      logExportTransactions: logExportTransactions,
      logRunSavedPar: logRunSavedPar,
      exportToPDF: exportToPDF,
      exportToExcel: exportToExcel,
      getGlossaryTerms: getGlossaryTerms,
      deleteSavedReportQuery: deleteSavedReportQuery,
      autoGenParReportName: autoGenParReportName,
      autoGeneratePAR: autoGeneratePAR 
    };

    function createReport(propertyData, reportName) {
      var deferred = $q.defer();
      var dataStringify = JSON.stringify({ selectedPropertyData: propertyData, reportName: reportName});
      $http.post(apiSettings.url + '/PropertyAdvisor', dataStringify)
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });

      return deferred.promise;
    }

    function hasReport(reportGuid) {
      var deferred = $q.defer();
      $http.get(apiSettings.url + '/PropertyAdvisor/' + reportGuid)
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });

      return deferred.promise;
    }

    function getReport(reportGuid) {
      var deferred = $q.defer();
      $http
        .get(apiSettings.url + '/PropertyAdvisor/' + reportGuid + "/report")
        .then(function(rsp) {
          //deferred.resolve(rsp.data);
          deferred.resolve(rsp);
        });//, function (err) {
        //  deferred.reject(err);
        //});

      return deferred.promise;
    }

    function getGeoData(reportGuid, countyOrMetro) {
      var deferred = $q.defer();
      var postUrl = apiSettings.url + '/PropertyAdvisor/' + reportGuid + "/geo";

      $http
        .post(postUrl, countyOrMetro)
        .then(function (rsp) {
          //deferred.resolve(rsp.data);
          deferred.resolve(rsp);
        });//, function(err) {
        //  deferred.reject(err);
        //});

      return deferred.promise;
    }

    function exportComps(reportGuid, date) {
      var deferred = $q.defer();
      var uri = apiSettings.url + '/PropertyAdvisor/' + reportGuid + '/export/comps/' + date;
        
      $http
        .post(uri, null, {
          responseType: 'arraybuffer'
        })
        .then(function (rsp) {
          if (rsp.data.length == 0) {
            deferred.reject();
          } else {
            deferred.resolve(rsp.data);
          }
        }, function (err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function exportTransactions(reportGuid, date) {
      var deferred = $q.defer();
      var uri = apiSettings.url + '/PropertyAdvisor/' + reportGuid + '/export/transactions/' + date;
        
      $http
        .post(uri, null, {
          responseType: 'arraybuffer'
        })
        .then(function (rsp) {
          if (rsp.data.length == 0) {
            deferred.reject();
          } else {
            deferred.resolve(rsp.data);
          }
        }, function (err) {
          deferred.reject(err);
        });
      return deferred.promise; 
    }

    function getSavedPARReports() {
      var deferred = $q.defer();
      $http
        .get(apiSettings.url + '/PropertyAdvisor')
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });

      return deferred.promise;
    }

    function savePARReport(reportGuid, reportName, reportUserSavedQueryId) {
      var deferred = $q.defer();
      $http
        .put(apiSettings.url + '/PropertyAdvisor/savePARReport', {
          id: reportGuid,
          name: reportName,
          userSavedQueryId: reportUserSavedQueryId
        })
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });

      return deferred.promise;
    }

    function exportChartsData(reportGuid, geoSection, countyOrMetro, title) {
      var deferred = $q.defer();
      var dateExportedOn = new Date();

      var data = {
        name: countyOrMetro.name,
        date: dateExportedOn.toString(),
        dateOffset: DateOffsetUtils.getTimezoneOffset(dateExportedOn),
        title: title,
        selectedGeo: countyOrMetro
      };

      var appendUrl = apiSettings.url + '/PropertyAdvisor/' + reportGuid + "/export/" + geoSection;

      $http
        .post(appendUrl, data, {
          responseType: 'arraybuffer'
        })
        .then(function (rsp) {
          if (rsp.data.byteLength === 0) {
            deferred.reject();
          } else {
            deferred.resolve(rsp.data);
          }
        }, function(err) {
          deferred.reject(err);
        });

      return deferred.promise;
    }

    function logExportComps(reportGuid) {
      var deferred = $q.defer();
      var uri = apiSettings.url + '/PropertyAdvisor/' + reportGuid + '/log/export/comps/';
      $http
        .post(uri)
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function (err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function logExportOccupancy(reportGuid) {
      var deferred = $q.defer();
      var uri = apiSettings.url + '/PropertyAdvisor/' + reportGuid + '/log/export/occupancy/';
      $http
        .post(uri)
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function (err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function logExportAverageRent(reportGuid) {
      var deferred = $q.defer();
      var uri = apiSettings.url + '/PropertyAdvisor/' + reportGuid + '/log/export/averageRent/';
      $http
        .post(uri)
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function (err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function logExportRpi(reportGuid) {
      var deferred = $q.defer();
      var uri = apiSettings.url + '/PropertyAdvisor/' + reportGuid + '/log/export/rpi/';
      $http
        .post(uri)
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function (err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function logExportTransactions(reportGuid) {
      var deferred = $q.defer();
      var uri = apiSettings.url + '/PropertyAdvisor/' + reportGuid + '/log/export/transactions/';
      $http
        .post(uri)
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function (err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function logRunSavedPar(reportGuid) {
      var deferred = $q.defer();
      var uri = apiSettings.url + '/PropertyAdvisor/' + reportGuid + '/log/runSaved/';
      $http
        .post(uri)
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function (err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function exportToPDF(reportGuid, occCountyOrMetro, rentCountyOrMetro, rpiCountyOrMetro, chartInformation, reportName, latitude, longitude) {
      var deferred = $q.defer();

      var dateExportedOn = new Date();

      var data = {
        occSelectedGeo: occCountyOrMetro,
        rentSelectedGeo: rentCountyOrMetro,
        rpiSelectedGeo: rpiCountyOrMetro,
        chartMinMaxInfo: chartInformation,
        date: dateExportedOn.toString(),
        reportName: reportName,
        mapCenterLatitude: latitude,
        mapCenterLongitude: longitude
      };

      var uri = apiSettings.url + '/PropertyAdvisor/' + reportGuid + '/export/report/pdf';
      $http
        .post(uri, data, {
          responseType: 'arraybuffer'
        })
        .then(function (rsp) {
          if (rsp.data.length == 0) {
            deferred.reject();
          } else {
            deferred.resolve(rsp.data);
          }
        }, function (err) {
          deferred.reject(err);
        });
      return deferred.promise; 
    }

    function getCompProperties(properties) {
      var compset = [];
      for (var i = 0; i < properties.length; i++) {
        var currentProperty = properties[i];
        compset.push({
          id: currentProperty.id,
          pinNumber: currentProperty.pinNumber
        });
      }
      return compset;
    }

    function getHospitals(properties) {
        var hospitals = [];
        for (var i = 0; i < properties.length; i++) {
            var currentProperty = properties[i];
            hospitals.push({
                latitude: currentProperty.latitude,
                longitude: currentProperty.longitude,
                pinNumber: currentProperty.pinNumber
            });
        }
        return hospitals;
    }

    function getConstructionProperties(properties) {
        var const_Inventory = [];
        for (var i = 0; i < properties.length; i++) {
            var currentProperty = properties[i];
            const_Inventory.push({
                id: currentProperty.id,
                pinNumber: currentProperty.pinNumber
            });
        }
        return const_Inventory;
    }

    function getTransactionData(properties) {
      return properties.map(function (prop) {
        return {
          id: prop.id,
          pinNumber: prop.pinNumber
        }
      });
    }

    function exportToExcel(reportGuid, occCountyOrMetro, rentCountyOrMetro, rpiCountyOrMetro, chartInformation, reportName,
      compset, hospitals, construction_inventory, transactions, latitude, longitude ) {
      var deferred = $q.defer();
      var dateExportedOn = new Date();
      
      var data = {
        occSelectedGeo: occCountyOrMetro,
        rentSelectedGeo: rentCountyOrMetro,
        rpiSelectedGeo: rpiCountyOrMetro,
        chartMinMaxInfo: chartInformation,
        date: dateExportedOn.toString(),
        compsetData: getCompProperties(compset),
        hospitalData: getHospitals(hospitals),
        constructionData: getConstructionProperties(construction_inventory),
        transactionData: getTransactionData(transactions),
        reportName: reportName,
        mapCenterLatitude: latitude,
        mapCenterLongitude: longitude
      };

      var uri = apiSettings.url + '/PropertyAdvisor/' + reportGuid + '/export/report/excel';
      $http
        .post(uri, data, {
          responseType: 'arraybuffer'
        })
        .then(function (rsp) {
          if (rsp.data.length == 0) {
            deferred.reject();
          } else {
            deferred.resolve(rsp.data);
          }
        }, function (err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function getGlossaryTerms() {
      var deferred = $q.defer();
      $http
        .get(apiSettings.url + '/Glossary')
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });

      return deferred.promise;
    }

    function deleteSavedReportQuery(queryName, queryGuid) {
      var url = apiSettings.url + "/PropertyAdvisor/" + queryGuid + "/" + queryName;
      var deferred = $q.defer();
      $http
        .delete(url)
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function autoGenParReportName(filter, tradeAreaFlags) {
      if (filter.query) {
      } else {
        //falsy
        return "";
      }
      var name = filter.query;
      if (tradeAreaFlags.driveTimeOnly) {
        if (filter.driveTimeInfo.drive1.breakValue && parseInt(filter.driveTimeInfo.drive1.breakValue)) {
          name = name + ' - ' + filter.driveTimeInfo.drive1.breakValue;
        }
        if (filter.driveTimeInfo.drive2 && filter.driveTimeInfo.drive2.breakValue && parseInt(filter.driveTimeInfo.drive2.breakValue)) {
          name = name + ' and ' + filter.driveTimeInfo.drive2.breakValue;
        }
        name = name + ' Min';
      }
      var isMiles = false;
      if (tradeAreaFlags.radiusOnly) {
        if (filter.radius1 && parseInt(filter.radius1)) {
          if (filter.radius1 == "-1") {
             name = name + ' - ' + filter.customRadius1;
             if (Number(filter.customRadius1) > 1) {
              isMiles = true;
             }

          } else {
             name = name + ' - ' + filter.radius1;
          }
        }
        if (filter.radius2 && parseInt(filter.radius2)) {
          if (filter.radius2 == "-1") {
             name =  name + ' and ' + filter.customRadius2 + ' Miles';
             
          } else {
             name = name + ' and ' + filter.radius2 + ' Miles';
          }
        } else {
          if (isMiles) {
              name = name + ' Miles';
          } else {
             name = name + ' Mile';
          }
         
        }
      }
      return name;
    }

    function autoGeneratePAR(propertyData){
      var deferred = $q.defer();
      $http.post(apiSettings.url + '/PropertyAdvisor/autoGeneratePAR', propertyData)
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }
  }
})();
