(function() {
  "use strict";

  app.factory("KendoTemplateFunctions", KendoTemplateFunctions);

  function KendoTemplateFunctions() {
    return {
      yearLabels: yearLabels,
      labelForSegmentAndUnitType: labelForSegmentAndUnitType,
      defaultChartLegend: defaultChartLegend,
      labelForQualityMetrics: labelForQualityMetrics
    }

    //Dont call explicitly. Need to wrap in a function on the controller that has access to the length of the data in the chart, to be passed in as dataLength.
    //This determines whether to display "quarters" or "years"
    function yearLabels(kendoDataItem, dataLength) {
      if (kendoDataItem.dataItem && kendoDataItem.dataItem.marketType && dataLength <= 8) {
        return kendoDataItem.value;
      } else {
        if (kendoDataItem.value.indexOf('1Q') === 0) {
          return kendoDataItem.value.substring(2, 6);
        };
        if (kendoDataItem.value.indexOf('01') === 0) {
          return kendoDataItem.value.substring(3, 7);
        };
        return "&nbsp;";
      }
    };
    
    function labelForQualityMetrics(kendoDataItem, publishMonth) {
      if (kendoDataItem.value.indexOf(publishMonth) === 0) {
        return kendoDataItem.value;
      };
      return "&nbsp;";
    };

    function labelForSegmentAndUnitType(kendoDataItem) {
      var unitData = kendoDataItem.dataItem;
      if(!unitData.segment || !unitData.segment.length) {
        return unitData.unitType;
      }
      var firstLettersOfSegment = _.reduce(unitData.segment.split(' '), function(existingString, nextWord) {
        var firstCharacterOfWord = "";
        if(nextWord.length) {
          firstCharacterOfWord = nextWord[0];
        }
        return existingString + firstCharacterOfWord;
      }, "");
      return firstLettersOfSegment + " " + unitData.unitType;
    }

    function defaultChartLegend(overrides) {
      /* This overrides the Kendo 2017 chart legends to go back to our original style.
         Overrides are allowed to be passed in to set the position of the legend and stuff.
      */
      var legendDefinition = function(e) {
        var color = e.options.markers.background;
        var labelColor = e.options.labels.color;
        var rect = new kendo.geometry.Rect([0, 0], [60, 65]);
        var layout = new kendo.drawing.Layout(rect, {
          spacing: 1,
          alignItems: "start",
          orientation: "vertical",
          cursor: "pointer"
        });

        var group = new kendo.drawing.Group();
        var marker = kendo.drawing.Path.fromRect(new kendo.geometry.Rect([0, 3], [7, 7])).fill(color).stroke(color);
        var label = new kendo.drawing.Text(e.series.name, [15, 0], {
          fill: {
            color: labelColor
          }
        });

        group.append(marker, label);
        layout.append(group);
        layout.reflow();
        return layout;
      }
      return _.extend({
        item: {
          visual: legendDefinition
        }
      }, overrides);
    }
  }
})();
