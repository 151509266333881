(function () {
  'use strict';

  app.controller('PropertyDetailsController', PropertyDetailsController);

  PropertyDetailsController.$inject = ['$scope', '$state', '$rootScope', '$q', '$filter', '$sce', '$window', 'PropertyDetailsService'
    , 'BingMapUtils', 'PinFactory', 'PinGeneratorUtil', 'GeoDataService', 'BingGeoDataService', 'FileSaver', 'AuditService', 'SpinnerService', 'vintageTypeConstant'
    , 'TrendsService', 'knownEventConstant', 'knownProductConstant', 'pushpinImages', 'reportSectionTypeConstant', 'PopupHandlerService', 'PropertyAdvisorService', 'DataAttributionService'];

  function PropertyDetailsController($scope, $state, $rootScope, $q, $filter, $sce, $window, PropertyDetailsService
    , BingMapUtils, pinFactory, pinGeneratorUtil, GeoDataService, BingGeoDataService, FileSaver, AuditService, SpinnerService, vintageTypeConstant
    , TrendsService, knownEventConstant, knownProductConstant, pushpinImages, reportSectionTypeConstant, PopupHandlerService, PropertyAdvisorService, DataAttributionService) {
    $rootScope.hideLeftNav = true;
    SpinnerService.start();

    var pageSize = 5;
    var bingMap;
    var isDestroyed = false, isInit = false;
    
    $scope.autoParPopoverMsg = 
      'Run an automated Property Advisor Report that displays aggregated rate and occupancy benchmarks for nearby properties with the same care segment(s).';
    $scope.property = {};
    $scope.tradeAreaDriveTimeEsriPolygons = [];
    $scope.tradeAreaOneRadius = BingMapUtils.getPolygonOptions('tradeAreaOneRadius', []);
    $scope.tradeAreaTwoRadius = BingMapUtils.getPolygonOptions('tradeAreaTwoRadius', []);
    $scope.customCohortClick = customCohortClick;
    $scope.propertyDetailsClick = propertyDetailsClick;

    $scope.showHospitals = false;
    $scope.showExistingInventory = false;
    $scope.showConstructionInventory = false;
    $scope.showMetroArea = false;
    $scope.showCounties = false;
    $scope.coveredCountiesPolygons = [];
    $scope.AttributionHtml = "";

    $scope.onClickShowHospitals = onClickShowHospitals;
    $scope.onClickShowExistingInventory = onClickShowExistingInventory;
    $scope.onClickShowConstructionInventory = onClickShowConstructionInventory;
    $scope.onMapReady = onMapReady;
    $scope.isThreeMonthRolling = isThreeMonthRolling;

    $scope.qualityMetricsInfo = {
      showProductAccessError: false,
      showQualityMetrics: true,
      id: null,
      cmsProviderId: null,
      cmsScore: null,
      data: {}
    };

    $scope.$on("$destroy", function() {
      isDestroyed = true;
    });

    function onMapReady(map) {
      bingMap = map;
      BingGeoDataService.setupSdsManager();
    }

    $scope.mapOptions = BingMapUtils.getDefaultViewOptions();
    $scope.mapOptions.options.disableScrollWheelZoom = true;
    $scope.mapOptions.events =  {
      viewchangeend: viewChange
    };

    function isThreeMonthRolling() {
      return $scope.property.pirTradeArea.vintageId == vintageTypeConstant.threeMonthRolling;
    }

    function getPropertyOrRedirect() {
      PropertyDetailsService
        .getPropertyDetails($state.params.id)
        .then(function (property) {
          $scope.property = property;
          if (isThreeMonthRolling()) {
            $scope.autoParPopoverMsg = 'Property Advisor Report functionality is not available for the three-month rolling data reporting type.';
          }
          if (!$scope.property.propertiesNearBy) { $scope.property.propertiesNearBy = []; }
          if (!$scope.property.constructionInventory) { $scope.property.constructionInventory = []; }
          if (!$scope.property.transactions) { $scope.property.transactions = []; }
          if (!$scope.property.hospitals) { $scope.property.hospitals = []; }

          $scope.qualityMetricsInfo.id = $scope.property.id;
          $scope.property.profitStatusText = $scope.property.forProfitFlag ? "For Profit" : "Not for Profit";
          $scope.qualityMetricsInfo.showProductAccessError = !$scope.property.showQualityMetrics 
            && _.some($scope.property.inventory, ['segmentType', 'Nursing Care']);

          init();
          initPropInventoryGrid();
          initDemographicsGrid();
          initTransactionGrid();
          
          SpinnerService.stop();
        }, function (errorInfo) {
          if (errorInfo && errorInfo.status && errorInfo.status == 401) {
            // user does not have access to product
            $state.go("noProductAccess", { productCode: 'PropertyInformationReport' });
          } else {
            // user has access to the product but not the property id being requested
            $state.go("areaAccessWarning", { productCode: 'PropertyInformationReport' });
          }
        });
    }

    getPropertyOrRedirect();

    function viewChange(){
      if (isDestroyed) {
        return;
      }
      if (isInit) {
        isInit = false;

        var bounds = BingMapUtils.boundsFromMap(bingMap);
        $scope.property.pirTradeArea.bbox = bounds.bbox;
        $q.all([
          TrendsService.getCurrentQuarter(),
          PropertyDetailsService.getDemographicYear(),
          PropertyDetailsService.getPropertyDetailsTradeViews($scope.property.pirTradeArea),
          DataAttributionService.getAttributionHtml()
        ]).then(function success(responses) {
          $scope.currentQuarter = responses[0];
          $scope.demographicYear = responses[1];
          $scope.property.propertiesNearBy = responses[2].propertiesNearBy;
          $scope.property.constructionInventory = responses[2].constructionInventory;
          $scope.property.hospitals = responses[2].hospitals;
          $scope.property.medicareAPIFailure = responses[2].medicareAPIFailure;
          $scope.AttributionHtml = $sce.trustAsHtml(responses[3]);
          setUpCounts();
          initExistingInventoryGrid();
          initConstructionInventoryGrid();
          initHospitalGrid();

          if ($state.params.exportType) {
            var exportType = $state.params.exportType.toLowerCase();
            if (exportType == "xlsx") $scope.exportToExcel();
            if (exportType == "pdf") $scope.pirToPDF();
          }

          SpinnerService.stop();
        });
      }
      $scope.toggleCounties();
    }

    $scope.existingInventory = {
      properties: [],
      tradeAreaOne: {
        count: 0
      },
      tradeAreaTwo: {
        count: 0
      },
      spaces: {
        IL: 0,
        AL: 0,
        MC: 0,
        NC: 0,
        totalSpaces: function () {
          return this.IL + this.AL + this.MC + this.NC;
        },
        ILFormatted: function () {
          return $filter('number')(this.IL, 0);
        },
        ALFormatted: function () {
          return $filter('number')(this.AL, 0);
        },
        MCFormatted: function () {
          return $filter('number')(this.MC, 0);
        },
        NCFormatted: function () {
          return $filter('number')(this.NC, 0);
        },
        totalSpacesFormatted: function () {
          return $filter('number')(this.totalSpaces(), 0);
        },
        trusted: {}
      }
    };

    $scope.constructionInventory = {
      properties: [],
      tradeAreaOne: {
        count: 0,
        expansionCount: 0,
        newCount: 0,
        trusted: {}
      },
      tradeAreaTwo: {
        count: 0,
        expansionCount: 0,
        newCount: 0,
        trusted: {}
      },
      spaces: {
        IL: 0,
        AL: 0,
        MC: 0,
        NC: 0,
        totalSpaces: function () {
          return this.IL + this.AL + this.MC + this.NC;
        }, ILFormatted: function () {
          return $filter('number')(this.IL, 0);
        },
        ALFormatted: function () {
          return $filter('number')(this.AL, 0);
        },
        MCFormatted: function () {
          return $filter('number')(this.MC, 0);
        },
        NCFormatted: function () {
          return $filter('number')(this.NC, 0);
        },
        totalSpacesFormatted: function () {
          return $filter('number')(this.totalSpaces(), 0);
        },
        trusted: {}
      }
    };

    $scope.hospitals = {
      properties: [],
      tradeAreaOne: {
        count: 0
      },
      tradeAreaTwo: {
        count: 0
      }
    };

    $scope.popoverTotalSpacesContent = function (spaces) {
      var content = (spaces.IL != "0" ? '<b>IL:</b> ' + spaces.ILFormatted() : '')
        + (spaces.AL != "0" ? ' <b>AL:</b> ' + spaces.ALFormatted() : '')
        + (spaces.MC != "0" ? ' <b>MC:</b> ' + spaces.MCFormatted() : '')
        + (spaces.NC != "0" ? ' <b>NC:</b> ' + spaces.NCFormatted() : '');
      return spaces.trusted[content] || (spaces.trusted[content] = $sce.trustAsHtml(content));
    }

    $scope.popoverConstructionRingContent = function (ring) {
       var content = (ring.expansionCount != "0" ? '<b>Expansion:</b> ' + ring.expansionCount : '')
         + (ring.newCount != "0" ? ' <b>New Construction:</b> ' + ring.newCount : '')

       return ring.trusted[content] || (ring.trusted[content] = $sce.trustAsHtml(content));
    }

    $scope.generateTransactionTooltipText = function (dataItem) {
      var content = dataItem.description ? '<b>' + dataItem.description + '</b>' : '';
      return dataItem.tooltip || (dataItem.tooltip = $sce.trustAsHtml(content));
    };

    function init() {
      $scope.cbsaArea = BingMapUtils.getPolygonOptions('cbsaArea');
      $scope.property.options = pinFactory.buildBlackPinOptions();
      var locsToCenterTo = [];

      if ($scope.property.driveTimeFeature && $scope.property.driveTimeFeature.features && $scope.property.driveTimeFeature.features.length) {
        // driveTime
        $scope.tradeAreaDriveTimeEsriPolygons = GeoDataService
          .convertFeatureCollectionToPolygon($scope.property.driveTimeFeature.features, {
            drive1: { trafficFlow: $scope.property.pirTradeArea.shape1.driveTime.trafficFlow },
            drive2: { trafficFlow: $scope.property.pirTradeArea.shape2.driveTime.trafficFlow }
          });
        if (_.has($scope.tradeAreaDriveTimeEsriPolygons, '[0].locations.length')) {
          $scope.property.pirTradeArea.shape1.driveTime.driveTimeLocs = $scope.tradeAreaDriveTimeEsriPolygons[0].locations;
          var driveTime1Color = GeoDataService.getColors($scope.property.pirTradeArea.shape1.driveTime.trafficFlow);
          $scope.property.pirTradeArea.shape1.strokeColor = driveTime1Color.argbStrokeColor;
          $scope.property.pirTradeArea.shape1.fillColor = driveTime1Color.argbFillColor;
          locsToCenterTo = $scope.tradeAreaDriveTimeEsriPolygons[0].locations;
          if (_.has($scope.tradeAreaDriveTimeEsriPolygons, '[1].locations.length')) {
            $scope.property.pirTradeArea.shape2.driveTime.driveTimeLocs = $scope.tradeAreaDriveTimeEsriPolygons[1].locations;
            var driveTime2Color = GeoDataService.getColors($scope.property.pirTradeArea.shape2.driveTime.trafficFlow);
            $scope.property.pirTradeArea.shape2.strokeColor = driveTime2Color.argbStrokeColor;
            $scope.property.pirTradeArea.shape2.fillColor = driveTime2Color.argbFillColor;
            locsToCenterTo = _.concat(locsToCenterTo, $scope.tradeAreaDriveTimeEsriPolygons[1].locations);
          }
        }
      } else {
        if ($scope.property.pirTradeArea.shape1.hasShape) {
          if ($scope.property.pirTradeArea.shape1.radius) {
            $scope.tradeAreaOneRadius = BingMapUtils
              .getPolygonOptions('tradeAreaOneRadius', BingMapUtils.buildCircle($scope.property.latitude, $scope.property.longitude, $scope.property.pirTradeArea.shape1.radius));
            locsToCenterTo = $scope.tradeAreaOneRadius.locations;
          }
        }

        if ($scope.property.pirTradeArea.shape2.hasShape) {
          if ($scope.property.pirTradeArea.shape2.radius) {
            $scope.tradeAreaTwoRadius = BingMapUtils
              .getPolygonOptions('tradeAreaTwoRadius', BingMapUtils.buildCircle($scope.property.latitude, $scope.property.longitude, $scope.property.pirTradeArea.shape2.radius));
            locsToCenterTo = $scope.tradeAreaTwoRadius.locations;
          }
        }
      }

      if (locsToCenterTo.length) {
        isInit = true;
        SpinnerService.start();
        BingMapUtils.centerMapToLocations(bingMap, locsToCenterTo);
      }
    }

    function setUpCounts() {
      var pirTradeArea = $scope.property.pirTradeArea;
      var hasTradeAreOne = pirTradeArea.shape1.hasShape;
      var tradeAreaOnePolygons = [];
      if (hasTradeAreOne) {
        if ($scope.tradeAreaDriveTimeEsriPolygons && $scope.tradeAreaDriveTimeEsriPolygons.length) {
          tradeAreaOnePolygons = $scope.tradeAreaDriveTimeEsriPolygons[0].locations;
        } else {
          tradeAreaOnePolygons = $scope.tradeAreaOneRadius.locations;
        }
      }
      var hasTradeAreTwo = pirTradeArea.shape2.hasShape;
      var tradeAreaTwoPolygons = [];
      if (hasTradeAreTwo) {
        if ($scope.tradeAreaDriveTimeEsriPolygons && $scope.tradeAreaDriveTimeEsriPolygons.length > 1) {
          tradeAreaTwoPolygons = $scope.tradeAreaDriveTimeEsriPolygons[1].locations;
        } else {
          tradeAreaTwoPolygons = $scope.tradeAreaTwoRadius.locations;
        }
      }

      /*Existing Inventory*/
      var existingInvData = pinGeneratorUtil.generatePropertyData($scope.property.propertiesNearBy,
       reportSectionTypeConstant.existingInventory, tradeAreaOnePolygons, tradeAreaTwoPolygons, $scope, $scope.showExistingInventory);
      $scope.existingInventory.properties = existingInvData.mapPins;
      $scope.property.propertiesNearBy = existingInvData.properties;
      $scope.existingInventory.tradeAreaOne.count = existingInvData.tradeAreaOne.count;
      $scope.existingInventory.tradeAreaTwo.count = existingInvData.tradeAreaTwo.count;
      $scope.existingInventory.spaces.IL = existingInvData.spaces.IL;
      $scope.existingInventory.spaces.AL = existingInvData.spaces.AL;
      $scope.existingInventory.spaces.MC = existingInvData.spaces.MC;
      $scope.existingInventory.spaces.NC = existingInvData.spaces.NC;

      /*Construction Inventory*/
      var constructionData = pinGeneratorUtil.generatePropertyData($scope.property.constructionInventory, 
        reportSectionTypeConstant.construction, tradeAreaOnePolygons, tradeAreaTwoPolygons,$scope, $scope.showConstructionInventory);
      $scope.constructionInventory.properties = constructionData.mapPins;
      $scope.property.constructionInventory = constructionData.properties;
      $scope.constructionInventory.tradeAreaOne = constructionData.tradeAreaOne;
      $scope.constructionInventory.tradeAreaTwo = constructionData.tradeAreaTwo;
      $scope.constructionInventory.spaces.IL = constructionData.spaces.IL;
      $scope.constructionInventory.spaces.AL = constructionData.spaces.AL;
      $scope.constructionInventory.spaces.MC = constructionData.spaces.MC;
      $scope.constructionInventory.spaces.NC = constructionData.spaces.NC;

      /*Hospitals*/
      var hospitalData = pinGeneratorUtil.generatePropertyData($scope.property.hospitals, 
        reportSectionTypeConstant.hospital, tradeAreaOnePolygons, tradeAreaTwoPolygons,$scope, $scope.showHospitals);
      $scope.hospitals.properties = hospitalData.mapPins;
      $scope.property.hospitals = hospitalData.properties;
      $scope.hospitals.tradeAreaOne.count = hospitalData.tradeAreaOne.count;
      $scope.hospitals.tradeAreaTwo.count = hospitalData.tradeAreaTwo.count;
    }
    
    // configure grids
    function initPropInventoryGrid() {
      $scope.gridOpts_PropInventory = {
        columns: [
          {
            field: "segmentType",
            title: "Segment",
            attributes: {
              'class': 'customKendo propertyInventory'
            }
          }, {
            field: "roomUnitType",
            title: "Room",
            attributes: {
              'class': 'customKendo propertyInventory'
            }
          },
          {
            field: "units",
            title: "Units",
            attributes: {
              'class': 'text-right customKendo propertyInventory'
            }
          }, {
            field: "roomStatus",
            title: "Status",
            attributes: {
              'class': 'customKendo propertyInventory'
            }
          }, {
            field: "openDate",
            title: "Open Date",
            format: "{0:MM/dd/yyyy}",
            attributes: {
              'class': 'customKendo propertyInventory'
            }
          }],
        dataSource: new kendo.data.DataSource({
          data: $scope.property.inventory,
          error: function (err) {
            console.log('error - ' +err);
          },
          schema: {
            model: {
              fields: {
                'segmentType': { type: 'string' },
                'roomUnitType': { type: 'string' },
                'units': { type: 'number' },
                'roomStatus': { type: 'string' },
                'openDate': { type: 'date' }
              }
            }
          },
          serverPaging: false,
          serverFiltering: false,
          serverSorting: false
        }),
        scrollable: false,
        sortable: false,
        pageable: false
      };
    };

    function initDemographicsGrid() {
      var dynamicColumns = [{
        title: "County",
        columns: [{
          field: "county.age45to64",
          title: "45-64 yrs.",
          headerTemplate: "45-64<br>yrs.",
          attributes: {
            'class': 'text-right customKendo demographics'
          },
          template: '{{gridOpts_Demographics.changeRowTemplate(dataItem.index, 0, dataItem.county.age45to64)}}'
        }, {
          field: "county.age65to74",
          title: "65-74 yrs.",
          headerTemplate: "65-74<br>yrs.",
          attributes: {
            'class': 'text-right customKendo demographics'
          },
          template: '{{gridOpts_Demographics.changeRowTemplate(dataItem.index, 1, dataItem.county.age65to74)}}'
        }, {
          field: "county.age75plus",
          title: "75+ yrs.",
          headerTemplate: "75+<br>yrs.",
          attributes: {
            'class': 'text-right customKendo demographics'
          },
          template: '{{gridOpts_Demographics.changeRowTemplate(dataItem.index, 2, dataItem.county.age75plus)}}'
        }]
      }, {
        title: "Metro",
        columns: [{
          field: "metro.age45to64",
          title: "45-64 yrs.",
          headerTemplate: "45-64<br>yrs.",
          attributes: {
            'class': 'text-right customKendo demographics'
          },
          template: '{{gridOpts_Demographics.changeRowTemplate(dataItem.index, 0, dataItem.metro.age45to64)}}'
        }, {
          field: "metro.age65to74",
          title: "65-74 yrs.",
          headerTemplate: "65-74<br>yrs.",
          attributes: {
            'class': 'text-right customKendo demographics'
          },
          template: '{{gridOpts_Demographics.changeRowTemplate(dataItem.index, 1, dataItem.metro.age65to74)}}'
        }, {
          field: "metro.age75plus",
          title: "75+ yrs.",
          headerTemplate: "75+<br>yrs.",
          attributes: {
            'class': 'text-right customKendo demographics'
          },
          template: '{{gridOpts_Demographics.changeRowTemplate(dataItem.index, 2, dataItem.metro.age75plus)}}'
        }]
      }, {
        title: "National",
        columns: [{
          field: "national.age45to64",
          title: "45-64 yrs.",
          headerTemplate: "45-64<br>yrs.",
          attributes: {
            'class': 'text-right customKendo demographics'
          },
          template: '{{gridOpts_Demographics.changeRowTemplate(dataItem.index, 0, dataItem.national.age45to64)}}'
        }, {
          field: "national.age65to74",
          title: "65-74 yrs.",
          headerTemplate: "65-74<br>yrs.",
          attributes: {
            'class': 'text-right customKendo demographics'
          },
          template: '{{gridOpts_Demographics.changeRowTemplate(dataItem.index, 1, dataItem.national.age65to74)}}'
        }, {
          field: "national.age75plus",
          title: "75+ yrs.",
          headerTemplate: "75+<br>yrs.",
          attributes: {
            'class': 'text-right customKendo demographics'
          },
          template: '{{gridOpts_Demographics.changeRowTemplate(dataItem.index, 2, dataItem.national.age75plus)}}'
        }]
      }];

      if ($scope.property.pirTradeArea.shape2.hasShape) {
        dynamicColumns.unshift({
          title: $scope.property.pirTradeArea.shape2.name,
          columns: [{
            field: "tradeAreaTwo.age45to64",
            title: "45-64 yrs.",
            headerTemplate: "45-64<br>yrs.",
            attributes: {
              'class': 'text-right customKendo demographics'
            },
            template: '{{gridOpts_Demographics.changeRowTemplate(dataItem.index, 0, dataItem.tradeAreaTwo.age45to64)}}'
          }, {
            field: "tradeAreaTwo.age65to74",
            title: "65-74 yrs.",
            headerTemplate: "65-74<br>yrs.",
            attributes: {
              'class': 'text-right customKendo demographics'
            },
            template: '{{gridOpts_Demographics.changeRowTemplate(dataItem.index, 1, dataItem.tradeAreaTwo.age65to74)}}'
          }, {
            field: "tradeAreaTwo.age75plus",
            title: "75+ yrs.",
            headerTemplate: "75+<br>yrs.",
            attributes: {
              'class': 'text-right customKendo demographics'
            },
            template: '{{gridOpts_Demographics.changeRowTemplate(dataItem.index, 2, dataItem.tradeAreaTwo.age75plus)}}'
          }]
        });
      }

      if ($scope.property.pirTradeArea.shape1.hasShape) {
        dynamicColumns.unshift({
          title: $scope.property.pirTradeArea.shape1.name,
          columns: [{
            field: "tradeAreaOne.age45to64",
            title: "45-64 yrs.",
            headerTemplate: "45-64<br>yrs.",
            attributes: {
              'class': 'text-right customKendo demographics'
            },
            template: '{{gridOpts_Demographics.changeRowTemplate(dataItem.index, 0, dataItem.tradeAreaOne.age45to64)}}'
          }, {
            field: "tradeAreaOne.age65to74",
            title: "65-74 yrs.",
            headerTemplate: "65-74<br>yrs.",
            attributes: {
              'class': 'text-right customKendo demographics'
            },
            template: '{{gridOpts_Demographics.changeRowTemplate(dataItem.index, 1, dataItem.tradeAreaOne.age65to74)}}'
          }, {
            field: "tradeAreaOne.age75plus",
            title: "75+ yrs.",
            headerTemplate: "75+<br>yrs.",
            attributes: {
              'class': 'text-right customKendo demographics'
            },
            template: '{{gridOpts_Demographics.changeRowTemplate(dataItem.index, 2, dataItem.tradeAreaOne.age75plus)}}'
          }]
        });
      }

      dynamicColumns.unshift({ 
        field: "name", 
        title: " ",
        attributes: {
          'class': 'space-nowrap customKendo demographics'
        }
      });

      $scope.gridOpts_Demographics = {
        columns: dynamicColumns,
        dataSource: new kendo.data.DataSource({
          data: $scope.property.demographics,
          error: function (err) {
            console.log('error - ' +err);
          },
          serverPaging: false,
          serverFiltering: false,
          serverSorting: false
        }),
        scrollable: false,
        sortable: false,
        pageable: false,
        changeRowTemplate: function (rowIndex, colIndex, value) {
          switch (rowIndex) {
            case 0:
              return kendo.format("{0:n0}", value);
            case 1:
              return kendo.format("{0:p}", value);
            case 2:
              return kendo.format("{0:p}", value);
            case 3:
              return kendo.format("{0:c0}", value);
            case 4:
              if (colIndex == 1) {
                return kendo.format("{0:c0}", value);
              }
          };
        }
      };
    };

    function initExistingInventoryGrid() {
      $scope.gridOpts_ExistingInventory = {
        columns: [
        {
          field: "id",
          title: "propertyID",
          hidden: "hidden"
        }, {
          field: "pinNumber",
          title: "Map Pin No.",
          headerTemplate: "<span>Map <br/> Pin No.",
          attributes: {
              'class': 'text-right'
          },
          width: "3%",
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          }
        },
        {
          field: "name",
          title: "Property Name",
          template: '<a ng-show="#= id #" ng-click="propertyDetailsClick($event, this.dataItem)">#= name #</a><span ng-hide="#= id #">#= name #</span>',
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "addressLine1",
          title: "Address",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "city",
          title: "City",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "stateProvinceCode",
          title: "State",
          width: "3%",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "zipCode",
          title: "ZIP",
          width: "3%",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "operator",
          title: "Operator",
          template: "#= (operator && operator != 'Unknown Stakeholder') ? kendo.toString(operator) : 'N/A' #",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "constructionStatus",
          title: "Property Status",
          headerTemplate: "<span>Property<br/>Status</span>",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "units",
          title: "Total Open Spaces",
          template: '<div class="text-nowrap">IL Units : #:units.ilUnits#<br/>AL Units : #:units.alUnits#<br/>MC Units : #:units.mcUnits#<br/>NC Beds : #:units.ncUnits#</div>',
          filterable: {
            cell: {
              enabled: false
            }
          }
        }, {
          field: "distance",
          title: "Distance",
          format: '{0:n1}',
          attributes: {
              'class': 'text-right'
          },
          width: "3%",
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          }
        }],
        dataSource: new kendo.data.DataSource({
          data: $scope.existingInventory.properties,
          pageSize: pageSize,
          error: function (err) {
             console.log('error - ' + err);
          },
          schema: {
            model: {
              fields: {
                'pinNumber': { type: 'number' },
                'id': { type: 'number' },
                'name': { type: 'string' },
                'distance': { type: 'number' },
                'addressLine1': { type: 'string' },
                'city': { type: 'string' },
                'stateProvinceCode': { type: 'string' },
                'zipCode': { type: 'string' },
                'constructionStatus': { type: 'string' }
              }
            }
          },
          sort: {
            field: "pinNumber",
            dir: "asc"
          },
          serverPaging: false,
          serverFiltering: false,
          serverSorting: false
        }),
        dataBinding: function (e) {
          Nic.kendoGrid.filtersOnTop('gridExistingInventory');
        },
        filterable: {
          mode: "row"
        },
        scrollable: false,
        sortable: {
          mode: "single",
          allowUnsort: false
        },
        pageable: $scope.existingInventory.properties && $scope.existingInventory.properties.length > pageSize ? true : false,
        resetFilters: function () {
          this.dataSource.filter([]);
          this.dataSource.sort({ field: "pinNumber", dir: "asc" });
        }
      };
    }

    function initConstructionInventoryGrid() {
      $scope.gridOpts_ConstructionInventory = {
        columns: [
        {
          field: "id",
          title: "propertyID",
          hidden: "hidden"
        }, {
          field: "pinNumber",
          title: "Map Pin No.",
          headerTemplate: "<span>Map <br/> Pin No.",
          attributes: {
              'class': 'text-right'
          },
          width: "3%",
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          }
        }, {
          field: "name",
          title: "Property Name",
          template: '<a ng-show="#= id #" ng-click="propertyDetailsClick($event, this.dataItem)">#= name #</a><span ng-hide="#= id #">#= name #</span>',
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "constructionOpenDate",
          title: "U/C Scheduled Open Date",
          headerTemplate: "<span>U/C Scheduled <br/> Open Date</span>",
          format: "{0:MM/dd/yyyy}",
          filterable: {
            cell: {
              showOperators: false,
              operator: "gte"
            }
          }
        }, {
            field: "addressLine1",
            title: "Address",
            filterable: {
              cell: {
                showOperators: false,
                operator: "contains"
              }
            }
        }, {
          field: "city",
          title: "City",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "stateProvinceCode",
          title: "State",
          width: "3%",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "zipCode",
          title: "ZIP",
          width: "3%",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "operator",
          title: "Operator",
          template: "#= (operator && operator != 'Unknown Stakeholder') ? kendo.toString(operator) : 'N/A' #",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "constructionStatus",
          title: "Construction Type",
          headerTemplate: "<span>Construction Type</span>",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "units",
          title: "Inventory Under Construction",
          headerTemplate: "<span>Inventory <br/> Under Construction</span>",
          template: '<div class="text-nowrap">IL Units : #:units.ilConstructionUnits#<br/>AL Units : #:units.alConstructionUnits#<br/>MC Units : #:units.mcConstructionUnits#<br/>NC Beds : #:units.ncConstructionUnits#</div>',
          filterable: {
            cell: {
              enabled: false
            }
          }
        }, {
          field: "distance",
          title: "Distance",
          format: '{0:n1}',
          attributes: {
            'class': 'text-right'
          },
          width: "3%",
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          }
        }],
        dataSource: new kendo.data.DataSource({
          data: $scope.constructionInventory.properties,
          pageSize: pageSize,
          error: function (err) {
            console.log('error - ' + err);
          },
          schema: {
            model: {
              fields: {
                'pinNumber': { type: 'number' },
                'id': { type: 'number' },
                'name': { type: 'string' },
                'distance': { type: 'number' },
                'addressLine1': { type: 'string' },
                'city': { type: 'string' },
                'stateProvinceCode': { type: 'string' },
                'zipCode': { type: 'string' },
                'constructionStatus': { type: 'string' },
                'constructionOpenDate': { type: 'date' }
              }
            }
          },
          sort: {
            field: "pinNumber",
            dir: "asc"
          },
          serverPaging: false,
          serverFiltering: false,
          serverSorting: false
        }),
        dataBinding: function (e) {
          Nic.kendoGrid.filtersOnTop('gridConstructionInventory');
        },
        filterable: {
          mode: "row"
        },
        scrollable: false,
        sortable: {
          mode: "single",
          allowUnsort: false
        },
        pageable: $scope.constructionInventory && $scope.constructionInventory.properties.length > pageSize ? true : false,
        resetFilters: function () {
          this.dataSource.filter([]);
          this.dataSource.sort({ field: "pinNumber", dir: "asc" });
        }
      };
    };

    function initTransactionGrid() {
      $scope.gridOpts_Transaction = {
        columns: [
        {
          field: "saleDate",
          title: "Sale Date",
          format: "{0:MM/dd/yyyy}",
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          }
        }, {
          field: "propertyAndCampus",
          headerTemplate: '<span>Property Type / <br> Campus Type</span>',
          template: '<span> #:propertyType.description# <br> #:campusType.description# </span>',
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "buyerCompanyName",
          title: "Players",
          template: "<span popover-trigger='mouseenter' uib-popover-html='generateTransactionTooltipText(dataItem.buyerCapitalInvestmentType)' >Buyer: #= buyerCompanyName || '' #</span></br><span popover-trigger='mouseenter' uib-popover-html='generateTransactionTooltipText(dataItem.sellerCapitalInvestmentType)'>Seller: #= sellerCompanyName ? sellerCompanyName : '' #</span>",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "salePrice",
          title: "Close Price",
          format: "{0:c0}",
          attributes: {
            'class': 'text-right'
          },
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          }
        }, {
          field: "pricePerUnit",
          title: "Price Per Unit",
          format: "{0:c0}",
          attributes: {
            'class': 'text-right'
          },
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          }
        }, {
          field: "closeCapRate",
          title: "Cap Rate",
          template: "#= closeCapRate ? kendo.toString(closeCapRate, 'p2') : 'N/A' #",
          attributes: {
            'class': 'text-right'
          },
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          }
        }],
        dataSource: new kendo.data.DataSource({
          data: $scope.property.transactions,
          pageSize: pageSize,
          error: function (err) {
            console.log('error - ' + err);
          },
          schema: {
            model: {
              fields: {
                'saleDate': { type: 'date' },
                'buyerCompanyName': { type: 'string' },
                'buyerCapitalInvestmentType.description': { type: 'string' },
                'sellerCompanyName': { type: 'string' },
                'sellerCapitalInvestmentType.description': { type: 'string' },
                'salePrice': { type: 'number' },
                'pricePerUnit': { type: 'number'}
              }
            },
            parse: function (data) {
              _.each(data, function (item) {
                item.buyerCapitalInvestmentType = item.buyerCapitalInvestmentType || { description: '' };
                item.sellerCapitalInvestmentType = item.sellerCapitalInvestmentType || { description: '' };
                item.propertyAndCampus = item.propertyType.description + ' ' + item.campusType.description;
              });
              return data;
            }
          },
          sort: {
            field: "saleDate",
            dir: "desc"
          },
          serverPaging: false,
          serverFiltering: false,
          serverSorting: false
        }),
        dataBinding : function(e){
          Nic.kendoGrid.filtersOnTop('gridTransaction');
        },
        filterable: {
          mode: "row"
        },
        scrollable: false,
        sortable: {
          mode: "single",
          allowUnsort: false
        },
        pageable: $scope.property.transactions && $scope.property.transactions.length > pageSize ? true : false,
        resetFilters: function () {
          this.dataSource.filter([]);
          this.dataSource.sort({ field: "saleDate", dir: "desc" });
        }
      };
    };

    function initHospitalGrid() {
      $scope.gridOpts_Hospital = {
        columns: [
        {
          field: "pinNumber",
          title: "Map Pin No.",
          headerTemplate: "<span>Map <br/> Pin No.",
          width: "3%",
          attributes: {
            'class': 'text-right'
          },
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          }
        }, {
          field: "name",
          title: "Hospital Name",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "type",
          title: "Hospital Type",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "ownership",
          title: "Ownership Type",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "address",
          title: "Address",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "city",
          title: "City",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "state",
          title: "State",
          width: "3%",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "zipCode",
          title: "ZIP",
          width: "3%",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "emergencyServices",
          title: "Emergency Services",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "distance",
          title: "Distance",
          format: '{0:n1}',
          width: "3%",
          attributes: {
            'class': 'text-right'
          },
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          }
        }],
        dataSource: new kendo.data.DataSource({
          data: $scope.property.hospitals,
          pageSize: pageSize,
          error: function (err) {
            console.log('error - ' + err);
          },
          schema: {
            model: {
              fields: {
                'pinNumber': { type: 'number' },
                'name': { type: 'string' },
                'type': { type: 'string' },
                'ownership': { type: 'string' },
                'address': { type: 'string' },
                'city': { type: 'string' },
                'state': { type: 'string' },
                'zipCode': { type: 'string' },
                'emergencyServices': { type: 'string' },
                'distance': { type: 'number' }
              }
            }
          },
          sort: {
            field: "pinNumber",
            dir: "asc"
          },
          serverPaging: false,
          serverFiltering: false,
          serverSorting: false
        }),
        dataBinding: function (e) {
          Nic.kendoGrid.filtersOnTop('gridHospital');
        },
        filterable: {
          mode: "row"
        },
        scrollable: false,
        sortable: {
          mode: "single",
          allowUnsort: false
        },
        pageable: $scope.property.hospitals && $scope.property.hospitals.length > pageSize ? true : false,
        resetFilters: function () {
          this.dataSource.filter([]);
          this.dataSource.sort({ field: "pinNumber", dir: "asc" });
        }
      };
    };

    $scope.exportToExcel = function () {
      SpinnerService.start();
      var propertyVm = angular.copy($scope.property);
      delete propertyVm.driveTimeFeature;
      delete propertyVm.showQualityMetrics;
      delete propertyVm.options;
      var title = 'Property Information ' + $scope.property.name + ' ' + $scope.property.dataAsOf.replace('/', '');
      PropertyDetailsService
        .getExcel(propertyVm)
        .then(function (data) {
          SpinnerService.stop();
          var excelFile = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          });
          var fileName = 'NICMAP_' + title.replace(/ /g, '_') + '.xlsx';
          FileSaver.saveAs(excelFile, fileName);
        });
    };

    $scope.pirToPDF = function () {
      SpinnerService.start();
      var propertyVm = angular.copy($scope.property);
      delete propertyVm.driveTimeFeature;
      delete propertyVm.options;
      propertyVm.qualityMetrics = $scope.qualityMetricsInfo.data;
      if ($scope.qualityMetricsInfo.getChartSeriesInfo) {
        propertyVm.seriesInfo = $scope.qualityMetricsInfo.getChartSeriesInfo();
      }
       
      propertyVm.tradeAreaTotals = {
        existingInventoryOne: $scope.existingInventory.tradeAreaOne.count, 
        existingInventoryTwo: $scope.existingInventory.tradeAreaTwo.count, 
        existingInventoryTotal: $scope.existingInventory.spaces.totalSpaces(),
        constructionInventoryOne: $scope.constructionInventory.tradeAreaOne.count, 
        constructionInventoryTwo: $scope.constructionInventory.tradeAreaTwo.count, 
        constructionInventoryTotal: $scope.constructionInventory.spaces.totalSpaces(), 
        hospitalsOne: $scope.hospitals.tradeAreaOne.count, 
        hospitalsTwo: $scope.hospitals.tradeAreaTwo.count,
      }
      PropertyDetailsService
        .exportPropertyInventoryToPDF(propertyVm)
        .then(function (data) {
          var pdfFile = new Blob([data], {
            type: 'application/pdf'
          });
          var title = "Property Information " + propertyVm.name + ' ' + $scope.property.dataAsOf.replace('/', '');
          var fileName = 'NICMAP_' + title.replace(/ /g, '_') + '.pdf';
          SpinnerService.stop();
          FileSaver.saveAs(pdfFile, fileName);
        }, function (err) {
          console.log(err);
          SpinnerService.stop();
        });
    }

    $scope.generatePAR = function() {
      if ($rootScope.loading || isThreeMonthRolling()) 
        return;

      SpinnerService.start();
      var propertyData = {
        propertyId: $scope.property.id,
        latitude: $scope.property.latitude,
        longitude: $scope.property.longitude,
        segments: $scope.property.segmentTypes,
        reportName: $scope.property.name
      };
      PropertyAdvisorService
        .autoGeneratePAR(propertyData)
        .then(function(reportGuid){
          PopupHandlerService.openReportInNewTab('propertyAdvisor', reportGuid, 'Property Advisor Report');
          AuditService.logEvent(knownEventConstant.pirAutoPAR.id, knownEventConstant.pirAutoPAR.message + ' - ' + $scope.property.name,
            knownProductConstant.local, $scope.property.id);
        }, function(error) {
          PopupHandlerService.openSimplePopup(error.data);
        })
        .finally(function() {
          SpinnerService.stop();
        });
    };

    $scope.togglePushPins = function (pins, newValue) {
      $.each(pins, function (index, value) {
        value.visible = newValue;
      });
    };

    $scope.toggleMetroArea = function () {
      if ($scope.showMetroArea) {
        if ($scope.cbsaAreaLocationCache) {
          $scope.cbsaArea.sections = angular.copy($scope.cbsaAreaLocationCache);
        }
        else {
          GeoDataService
            .getCBSAGeoData($scope.property.cbsaCode)
            .then(function (data) {
              if (data && data.length > 0) {
                $scope.cbsaArea.sections = data;
                $scope.cbsaAreaLocationCache = angular.copy(data);
              }
            }, function (err) {
              // Do Nothing - No Shape to Render.
            })
            .finally(function () {
              // nothing to do
            });
        }
        AuditService.logEvent(knownEventConstant.pirShowMetroArea.id, knownEventConstant.pirShowMetroArea.message,
        knownProductConstant.local, $scope.property.id);
      }
      else {
        $scope.cbsaArea.sections = [];
      }
    };

    $scope.toggleCounties = function() {
      if ($scope.showCounties && BingMapUtils.getCurrentZoom(bingMap) >= 9) {
        SpinnerService.start();
        BingGeoDataService
          .getAllCountiesBoundariesInBbox(bingMap, true)
          .then(function (bingPolygons) {
            $scope.coveredCountiesPolygons = bingPolygons;
            SpinnerService.stop();
          }, function (err) { 
            console.log(err);
            SpinnerService.stop();
          });
        AuditService.logEvent(knownEventConstant.pirShowCounties.id, knownEventConstant.pirShowCounties.message,
          knownProductConstant.local, $scope.property.id);
      } 
      else
        $scope.coveredCountiesPolygons = [];
    };

    function customCohortClick() {
      $state.go('customCohortDemographics', { id: $state.params.id });
    }

    function propertyDetailsClick(ev, prop) {
      var pirTradeArea = $scope.property.pirTradeArea;
      var windowOpen = $window.open('', '_blank');
      var propertyDetailsHrefLocation = $state.href('propertyDetails', { id: 'pirGuid' }, { inherit: false });
      PropertyDetailsService.getPropertyDetailsIdBasedOnVintage(prop, pirTradeArea.vintageId)
        .then(function (rsp) {
          if (!rsp.wasPirGuidCreated)
            AuditService.logEvent(knownEventConstant.propertyInformationReport.id, knownEventConstant.propertyInformationReport.message + " - " + prop.name,
              knownProductConstant.local, prop.id);
          windowOpen.location.href = propertyDetailsHrefLocation.replace('pirGuid', rsp.id);
        });
    }

    function onClickShowHospitals(pins, showHospitals) {
      $scope.togglePushPins(pins, showHospitals);
      if (showHospitals) {
        AuditService.logEvent(knownEventConstant.pirShowHospitals.id, knownEventConstant.pirShowHospitals.message,
        knownProductConstant.local, $scope.property.id);
      }
    }

    function onClickShowExistingInventory(pins, showExistingInventory) {
      $scope.togglePushPins(pins, showExistingInventory);
      if (showExistingInventory) {
       AuditService.logEvent(knownEventConstant.pirShowExistingInventory.id, knownEventConstant.pirShowExistingInventory.message,
        knownProductConstant.local, $scope.property.id); 
      }
    }

    function onClickShowConstructionInventory(pins, showConstructionInventory) {
      $scope.togglePushPins(pins, showConstructionInventory);
      if (showConstructionInventory) {
        AuditService.logEvent(knownEventConstant.pirShowConstructionInventory.id, knownEventConstant.pirShowConstructionInventory.message,
        knownProductConstant.local, $scope.property.id);
      }
    }
  };
})();
