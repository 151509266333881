(function () {
  "use strict";

  app.directive('nicDrawingTools', nicDrawingTools);

  function nicDrawingTools() {
    'use strict';
    
    function link(scope, element, attrs, mapCtrl) {
      var tools;
      var managerObj;
      var currentShape;
      var savedManager = [];
      
      var options = {};
  
      function setOptions() {
        if (scope.strokeColor) {
          if (!options.hasOwnProperty('shapeOptions')) {
            options.shapeOptions = {};
          }
          options.shapeOptions.strokeColor = getMapColor(scope.strokeColor);
        }
        if (scope.fillColor) {
          if (!options.hasOwnProperty('shapeOptions')) {
            options.shapeOptions = {};
          }
          options.shapeOptions.fillColor = getMapColor(scope.fillColor);
        }
      }

      var drawingStartedHandler;
      var drawingEndedHandler;

      function createDrawingManager(startMode) {
        if (tools && !managerObj) {
          tools.showDrawingManager(function (manager) {
            manager._drawingBar._viewModel.setFillColor(options.shapeOptions.fillColor);
            manager._drawingBar._viewModel.setStrokeColor(options.shapeOptions.strokeColor);

            managerObj = manager;
            if (startMode) {
              manager.setDrawingMode(startMode);
            } else {
              manager.setDrawingMode(Microsoft.Maps.DrawingTools.DrawingMode.none);
            }

            //Add drawingChanged and drawingEnded events to the drawing manager to keep track of the curren shape.
            Microsoft.Maps.Events.addHandler(manager, 'drawingChanged', function (shape) {
              currentShape = shape;
            });

            drawingEndedHandler = Microsoft.Maps.Events.addHandler(manager, 'drawingEnded', function (primitive) {
              currentShape = null;
              var shapes = manager.getPrimitives();
              if (shapes && shapes.length > 0) {
                scope.onShapeChange({ shapes: primitive });
              }
            });
          });
        }
      }

      function removeDrawingManager() {
        if (managerObj) {
          
          if (drawingStartedHandler) {
            Microsoft.Maps.Events.removeHandler(drawingStartedHandler);
          }
          if (drawingEndedHandler){
            Microsoft.Maps.Events.removeHandler(drawingEndedHandler);
          }

          managerObj.dispose();
          managerObj = null;
        }
      }

      function init() {
        //Load the DrawingTools module.
        Microsoft.Maps.loadModule(['Microsoft.Maps.DrawingTools'], function () {
          if (!tools) {
            //Create an instance of the DrawingTools class and bind it to the map.
            tools = new Microsoft.Maps.DrawingTools(mapCtrl.map);
          }
        
          //just create drawing manager on non drawing mode and we will have it hidden by CSS always.  no menu bar is needed
          createDrawingManager(Microsoft.Maps.DrawingTools.DrawingMode.none); 
        });
      }

      init();

      function getMapColor(arrayRGBA) {
        var a, r, g, b;
        r = arrayRGBA[0];
        g = arrayRGBA[1];
        b = arrayRGBA[2];
        a = arrayRGBA[3];
        return new Microsoft.Maps.Color(a, r, g, b)
      }

      setOptions();

      function startDrawing() {
        if (tools && managerObj) {
          var mode = managerObj.getDrawingMode();
          if (mode != Microsoft.Maps.DrawingTools.DrawingMode.polygon) {
            managerObj.setDrawingMode(Microsoft.Maps.DrawingTools.DrawingMode.polygon);
          }
        }
      }

      function stopDrawing() {
        if (tools && managerObj) {
          var mode = managerObj.getDrawingMode();
          if (mode != Microsoft.Maps.DrawingTools.DrawingMode.none) {
            managerObj.setDrawingMode(Microsoft.Maps.DrawingTools.DrawingMode.none);
          }
        }
      }
      
      scope.$watch('drawThisShape', function (shape) {
        if (shape == null) {
          stopDrawing();
        } else {
          startDrawing();
        }
      });
      
      scope.$on('DRAWINGTOOLS.CLEAR', function () {
        clear();
      });

      function clear() {
        if (managerObj) {
          managerObj.clear();
        }
        _.forEach(savedManager, function (manager) {
          manager.clear();
        });
        savedManager = [];
      }
      
      scope.$on('drawSavedShape', function (evt, locs) {
        clear();
        var polygonLocations = [];
        _.forEach(locs, function (loc) {
          polygonLocations.push(new Microsoft.Maps.Location(loc.latitude, loc.longitude));
        });
        var savedShapeConvertedToPolygon = new Microsoft.Maps.Polygon(polygonLocations, {
          fillColor: options.shapeOptions.fillColor,
          strokeColor: options.shapeOptions.strokeColor
        });
        Microsoft.Maps.loadModule(['Microsoft.Maps.DrawingTools'], function () {
          //Create an instance of the DrawingTools class and bind it to the map.
          var savedPolygonTool = new Microsoft.Maps.DrawingTools(mapCtrl.map);

          savedPolygonTool.showDrawingManager(function (manager) {
            if (savedManager.length > 1) {
              _.forEach(savedManager, function (eachSavedManager) {
                eachSavedManager.clear();
              });
              savedManager = [];
            }
            manager.clear();
            manager.add(savedShapeConvertedToPolygon);
            savedManager.push(manager);
          });

          savedPolygonTool = null;
        });
      });

      element.on('$destroy', function () {
        removeDrawingManager();
      });

      scope.$on('pushpinClickedWhileInDrawingMode', function (evt, params) {
        // http://bingmapsv8samples.azurewebsites.net/#Snap%20drawing%20to%20pushpins
        if (!params.isInDrawingMode) return;

        //Get the loction of the click pushpin.
        var loc = params.location;
        //Get the current drawing mode.
        if (currentShape instanceof Microsoft.Maps.Polyline) {
          var locs = currentShape.getLocations();
          if (locs.length > 0) {
            locs[locs.length - 1] = loc;    //Update preview location.
            locs.push(loc);                 //Add new snapped location.
            currentShape.setLocations(locs);
          } else {
            //Not supported.
          }
        } else if (currentShape instanceof Microsoft.Maps.Polygon) {
          var locs = currentShape.getLocations();
          if (locs.length > 0) {
            locs[locs.length - 2] = loc;    //Update preview location.
            locs[locs.length - 1] = loc;   //Add new snapped location.
            currentShape.setLocations(locs);
          } else {
            //Not supported.
          }
        }
      });
    }

    return {
      link: link,
      restrict: 'EA',
      scope: {
        onShapeChange: '&',
        drawThisShape: '=',
        strokeColor: '=?',
        fillColor: '=?'
      },
      require: '^bingMap'
    };
  }
})();

