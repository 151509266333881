(function() {
  "use strict";

  app.controller('SubscriptionPortfoliosController', SubscriptionPortfoliosController);

  SubscriptionPortfoliosController.$inject = ['$scope', '$rootScope', '$state', '$uibModal', 'knownProductConstant',
    'PortfolioService', 'SubscriptionDetailsService', 'AdminPortfolioService', 'SpinnerService']

  function SubscriptionPortfoliosController($scope, $rootScope, $state, $uibModal, knownProductConstant,
    PortfolioService, SubscriptionDetailsService, AdminPortfolioService, SpinnerService) {
    $rootScope.hideLeftNav = true;
    $scope.subscriptionPortfolios = [];
    $scope.paramSubscriptionID = $state.params.id;
    $scope.showChildPortfolios = false;
    $scope.isSubscribedtoPortfolio = false;

    $scope.$on('SilentSubscriptionDetailRefreshComplete', productCheck);

    $scope.addNewPortfolio = function () {
      var subId = $state.params.id;
      var newPortfolioPopup = $uibModal.open({ 
        templateUrl: "app/partials/adminAddPortfolioPopup.html", 
        controller: 'AdminPortfolioPopupController', 
        backdrop: 'static', 
        windowClass: 'modify-access-popup', 
        resolve: { 
          subscriptions: function () { return SubscriptionDetailsService.getSubscriber(); },
          portfolioTypes: function() { return AdminPortfolioService.getPortfolioTypes(); }
        } 
      });  
        
      newPortfolioPopup.result.then(function (dataReturned) {   
        $state.go('admin.portfolios.details',  
        { 
          id: dataReturned.portfolioId,  
        },  
        { reload: true });  
      });  
    };

    $scope.resetFilters = function () {
      $scope.gridOpts.dataSource.filter({});
      $scope.gridOpts.dataSource.sort({ field: "name", dir: "asc" });
    };

    function productCheck() {
      var subscribedProducts = $scope.$parent.subscribedProducts;
      var hasAccess = false;

      if (subscribedProducts != null && subscribedProducts.length > 0) {
        var subscribed = _.findIndex(subscribedProducts, function (o) { return o.productId == knownProductConstant.port; });
        if (subscribed >= 0) {
          hasAccess = true;
        }
      }

      $scope.isSubscribedtoPortfolio = hasAccess;
    }

    function initGrid() {
      $scope.gridOpts = {
        columns: [
          {
            field: "id",
            title: "PortfolioID",
            hidden: "hidden"
          },
          {
            field: "subscriptionId",
            title: "SubscriptionId",
            hidden: "hidden"
          },
          {
            field: "name",
            title: "Portfolio Name",
            template: '<span class="space-nowrap"><img ng-show="#= type.id == 4 #" ng-src="assets/portfolio/subportfolio.png"> <a ng-class="{ \'appendTab\': #= type.id == 4 # }" ui-sref="#= \'admin.portfolios.details({ id: \' + id + \', subscriptionId: \' + subscriptionId + \' })\' #">#= name #</a></span>',
            filterable: {
              cell: {
                showOperators: false,
                operator: "contains"
              }
            }
          },
          {
            field: "numberOfProperties",
            title: "No. of Properties",
            filterable: {
              cell: {
                showOperators: false
              }
            }
          },
          {
            field: "type.name",
            title: "Portfolio Type",
            filterable: {
              cell: {
                showOperators: false
              }
            }
          },
          {
            field: "ownerFullName",
            title: "Portfolio Administrator",
            filterable: {
              cell: {
                showOperators: false,
                operator: "contains"
              }
            }
          }, {
            field: "dateModified",
            title: "Last Modified On",
            format: "{0:MM/dd/yyyy h:mm tt}",
            filterable: {
              cell: {
                showOperators: false,
                operator: "gte"
              }
            }
          },
          {
            field: "modifiedFullName",
            title: "Last Modified By",
            filterable: {
              cell: {
                showOperators: false,
                operator: "contains"
              }
            }
          }
        ],
        dataSource: new kendo.data.DataSource({
          transport: {
            type: "json",
            read: function (opts) {
              opts.success($scope.subscriptionPortfolios);
            }
          },
          pageSize: 25,
          error: function (err) {
            console.log('error - ' + err);
          },
          schema: {
            model: {
              fields: {
                'id': { type: 'number' },
                'subscriptionId': { type: 'number' },
                'name': { type: 'string' },
                'numberOfProperties': { type: 'number' },
                'type.id': { type: 'string' },
                'ownerFullName': { type: 'string' },
                'modifiedFullName': { type: 'string' },
                'dateModified': {type: 'date'}
              }
            }
          },
          sort: { field: "name", dir: "asc" },
          serverPaging: false,
          serverFiltering: false,
          serverSorting: false
        }),
        dataBinding: function (e) {
          Nic.kendoGrid.filtersOnTop('portfolioGrid');
        },
        filterable: {
          mode: "row"
        },
        scrollable: false,
        sortable: {
          mode: "single",
          allowUnsort: false
        },
        pageable: true
      };
    };

    function getPortfolios() {
      SpinnerService.start();
      PortfolioService
        .getSubscriptionPortfolios($scope.paramSubscriptionID, $scope.showChildPortfolios)
        .then(function (data) {
          $scope.subscriptionPortfolios = data;
          $scope.gridOpts.dataSource.read();
          SpinnerService.stop();
        });
    }

    productCheck();
    initGrid();
    getPortfolios();
  }
}());