(function() {
  "use strict";
  
  app.factory("GeoDataService", GeoDataService);
  
  GeoDataService.$inject = ['$q', '$http', 'apiSettings', 'GeoJsonUtils'];

  function GeoDataService($q, $http, apiSettings, GeoJsonUtils) {

    return {
      getZipCodeGeoData: getZipCodeGeoData,
      getCountyGeoData: getCountyGeoData,
      getAnyShapes: getAnyShapes,
      getBoundariesForCountiesInBBox: getBoundariesForCountiesInBBox,
      getCBSAGeoData: getCBSAGeoData,
      geocodeLocation: geocodeLocation,
      getEsriDriveTimeShapes: getEsriDriveTimeShapes,
      getColors: getColors,
      getCountiesInBoundingBox: getCountiesInBoundingBox,
      convertFeatureCollectionToPolygon: convertFeatureCollectionToPolygon,
      getMultipleCBSAGeoData: getMultipleCBSAGeoData

    };

    function polygonFactory(geoJson, polygonFill, polygonStroke) {
      var polygons = [];
      var shapes = GeoJsonUtils.toCoordinates( geoJson );
      var propertyName = GeoJsonUtils.toName(geoJson);
  
      angular.forEach(shapes, function(shape, key) {
        var polygon = {};
        polygon.id = 'myPolygonId' + key;
        polygon.locations = shape;
        polygon.strokeColor = polygonStroke || '#FF0000';
        polygon.fillColor = polygonFill || 'rgba(0,0,0,0)';
        polygon.name = propertyName;
        
        polygons.push(polygon);
      });
  
      return polygons;
    }
  
    function getZipCodeGeoData(zipCode) {
      var deferred = $q.defer();
  
      var uri = apiSettings.url + "/GeoData/ZipCode/" + zipCode;
  
      $http
        .get(encodeURI(uri))
        .then(function ( rsp ) {
          deferred.resolve(polygonFactory(rsp.data));
        }, function(err) {
          deferred.reject(err);
        });
  
      return deferred.promise;
    }
  
    //gets zip or county GeoData. Will resolve with null if there is no need to get the new geodata
    function getAnyShapes(query, oldQuery) {
      var deferred = $q.defer();
  
      if(query.zipCode) {
        if (oldQuery && oldQuery.zipCode && oldQuery.zipCode == query.zipCode) {
          deferred.resolve(null);
          return deferred.promise;
        }
        getZipCodeGeoData(query.zipCode)
          .then(function(data) {
              deferred.resolve(data);
            },
            function(error) {
              deferred.reject(error); //should we do something with the error here?
            });
        return deferred.promise;
      }
      if(query.countyName && query.stateCode) {
        if (oldQuery && oldQuery.countyName && oldQuery.stateCode && oldQuery.countyName == query.countyName && oldQuery.stateCode == query.stateCode) {
          deferred.resolve(null);
          return deferred.promise;
        }
        getCountyGeoData(query.countyName, query.stateCode)
          .then(function(data) {
              deferred.resolve(data);
            },
            function(error) {
              deferred.reject(error); //should we do something with the error here?
            });
        return deferred.promise;
      }
      deferred.resolve([]);
      return deferred.promise;
    }
  
    function getCountyGeoData(countyName, stateCode) {
      var deferred = $q.defer();
  
      var uri = apiSettings.url + "/GeoData/County/" + countyName + "/" + stateCode;
  
      $http
        .get(uri)
        .then(function(rsp) {
          deferred.resolve(polygonFactory(rsp.data));
        }, function(err) {
          deferred.reject(err);
        });
  
      return deferred.promise;
    }
  
    function getBoundariesForCountiesInBBox(bounds, isCollected, isNonCoverageCounties) {
      var deferred = $q.defer();
      var data = {
        topLeftLatitude: bounds.bbox[0],
        topLeftLongitude: bounds.bbox[1],
        bottomRightLatitude: bounds.bbox[2],
        bottomRightLongitude: bounds.bbox[3],
        isCollected: isCollected
      };
  
      var uri = apiSettings.url + "/GeoData/GetBoundariesForCountiesInBBox";
      var polygonFill = isNonCoverageCounties ? 'rgba(0,51,102,0.3)' : 'rgba(0,0,0,0)';
  
      $http
        .post(uri, JSON.stringify(data))
        .then(function (rsp) {
          var polygons = [];
          $.each( rsp.data.features, function ( key, value ) {
            polygons = polygons.concat(polygonFactory(value, polygonFill));
          });
          deferred.resolve(polygons);
        }, function(err) {
          deferred.reject(err);
        });
  
      return deferred.promise;
    }
  
    function getCBSAGeoData(cbsa) {
      var deferred = $q.defer();
      
      var uri = apiSettings.url + "/GeoData/CBSA/" + cbsa;
      
      $http
        .get(encodeURI(uri))
        .then(function (rsp) {
          deferred.resolve(polygonFactory(rsp.data));
        }, function (err) {
          deferred.reject(err);
        });
      
      return deferred.promise;
    }

    function getMultipleCBSAGeoData(cbsas) {
      var deferred = $q.defer();
      var uri = apiSettings.url + "/GeoData/MultipleCBSA/";
      $http
       .post(uri, JSON.stringify(cbsas))
        .then(function (rsp) {
          var polygons = [];
          _.each(rsp.data.features, function (value) {
              polygons = polygons.concat(polygonFactory(value));
            }); 
          deferred.resolve(polygons);
        }, function (err) {
          deferred.reject(err);
        });
      
      return deferred.promise;
    }
  
    function geocodeLocation(lat, long) {
      var deferred = $q.defer();

      var uri = apiSettings.url +
        '/GeoData/geocodeLatLong/{latitude}/{longitude}'.replace('{latitude}', lat).replace('{longitude}', long);

      $http
        .get(uri)
        .then(function (rsp) {
          deferred.resolve(rsp);
        }, function(err) {
          deferred.reject(err);
        });

      return deferred.promise;
    }

    function convertFeatureCollectionToPolygon(features, travelTimeRequest) {
      var polygons = [];
      _.forEach(features, function ( value, key ) {
        var keyToGet = key == 0 ? "drive1" : "drive2";
        var trafficFlow = travelTimeRequest[keyToGet].trafficFlow;
        var color = getColors(trafficFlow);
        polygons = polygons.concat(polygonFactory(value, color.fillColor, color.strokeColor));
      });
      return polygons;
    }

    function getEsriDriveTimeShapes(travelTimeRequest) {
      var deferred = $q.defer();
      var uri = apiSettings.url + "/demographics/driveTime";

      $http
        .post(uri, JSON.stringify(travelTimeRequest))
        .then(function (rsp) {
          var polygons = convertFeatureCollectionToPolygon(rsp.data.features, travelTimeRequest);
          deferred.resolve(polygons);
        }, function(err) {
          deferred.reject(err);
        });

      return deferred.promise;
    }

    function getColors(trafficFlow) {
      switch(trafficFlow) {
        case "High":
          return {
            fillColor:   'rgba(217,83,79,0.1)',
            strokeColor: 'rgba(217,83,79,1)',
            argbFillColor: [ 10, 217, 83, 79 ],
            argbStrokeColor: [ 100, 217, 83, 79 ]
          };
        case "Medium":
          return {
            fillColor:   'rgba(240,173,78,0.1)',
            strokeColor: 'rgba(240,173,78,1)',
            argbFillColor: [ 10, 240, 173, 78 ],
            argbStrokeColor: [ 100, 240, 173, 78 ]
          };
        case "Low":
          return {
            fillColor:   'rgba(0,51,102,0.1)',
            strokeColor: 'rgba(0,51,102,1)',
            argbFillColor: [ 10, 0, 51, 102 ],
            argbStrokeColor: [ 100, 0, 51, 102 ]
          };
        default:
          return {
            fillColor:   'rgba(0,0,0,0)',
            strokeColor: 'rgba(0,0,0,0)',
            argbFillColor: [ 0, 0, 0, 0 ],
            argbStrokeColor: [ 0, 0, 0, 0 ]
          };
      }
    }

    function getCountiesInBoundingBox(bounds) {
      var deferred = $q.defer();
      var data = {
        topLeftLatitude: bounds.bbox[0],
        topLeftLongitude: bounds.bbox[1],
        bottomRightLatitude: bounds.bbox[2],
        bottomRightLongitude: bounds.bbox[3]
      };
  
      var uri = apiSettings.url + "/GeoData/GetCountiesInBBox";
  
      $http
        .post(uri, JSON.stringify(data))
        .then(function (rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });
  
      return deferred.promise;
    }
  }
})();
