(function () {
  "use strict";

  app.controller('FaqController', FaqController);
  FaqController.$inject = ['$scope', '$rootScope', '$sce', 'faqItems', 'AuditService', 'knownEventConstant'];
  function FaqController($scope, $rootScope, $sce, faqItems, AuditService, knownEventConstant) {
    $rootScope.hideLeftNav = true;
    $scope.items = faqItems;

    $scope.toTrustedHTML = function(html){
      return $sce.trustAsHtml(html);
    }

    $scope.logClick = function(question) {
      AuditService.logEvent(knownEventConstant.faqQuestionClick.id, knownEventConstant.faqQuestionClick.message + " - " + question);
    }
  };
})();
