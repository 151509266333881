(function () {
  "use strict";
  app.controller('SubscriptionDetailsController', SubscriptionDetailsController);

  SubscriptionDetailsController.$inject = ['$scope', '$rootScope', '$state', '$q', 'PopupHandlerService', 'SubscriptionDetailsService',
    'SubscriptionHistoryService', 'SpinnerService', 'ClientApiService'];

  function SubscriptionDetailsController($scope, $rootScope, $state, $q, PopupHandlerService, SubscriptionDetailsService,
    SubscriptionHistoryService, SpinnerService, ClientApiService) {
    $scope.showMetroBankHistory = false;
    $scope.paramSubscriptionID = $state.params.id;
    $scope.paramCompanyID = $state.params.companyId;
    $scope.promisesCompleted = false;
  
    $scope.salesforceRefresh = function () {
      SpinnerService.start();
      SubscriptionDetailsService.refreshFromSalesforce($scope.paramSubscriptionID)
      .finally(function () {
        SpinnerService.stop();
        $state.reload();
      });
    };

    $scope.$on("SilentSubscriptionDetailRefresh", function (evt, dataReturned) {
      var promises = {};
      var isProductUpdated = dataReturned && dataReturned.productsUpdated;
      var isGeoUpdated = dataReturned && dataReturned.geoUpdated;

      promises.subscriptionData = SubscriptionDetailsService.getSubscriptionData($scope.paramSubscriptionID);
      
      if (isProductUpdated) {
        promises.subscriptionProductsData = SubscriptionDetailsService.getSubscriptionProductsData($scope.paramSubscriptionID);
        promises.clientApiKeys = ClientApiService.getClientApiKeys($scope.paramCompanyID);
      }
      if (isGeoUpdated) {
        promises.subscriptionCBSAsData = SubscriptionDetailsService.getSubscriptionCBSAsData($scope.paramSubscriptionID);
        promises.metroBankHistory = SubscriptionHistoryService.getMetroBankHistory($scope.paramCompanyID);
      }

      $q.all(promises)
        .then(function (responses) {
          $scope.subscription = responses.subscriptionData;
          SubscriptionDetailsService.setSubscriber($scope.subscription);

          if (responses.subscriptionProductsData) {
            SubscriptionDetailsService.setSubscribedProducts(responses.subscriptionProductsData);
            $scope.subscribedProducts = SubscriptionDetailsService.getSubscribedProducts();
          }
          if (responses.clientApiKeys) {
            SubscriptionDetailsService.setClientApiKeys(responses.clientApiKeys);
            $scope.clientApiKeys = SubscriptionDetailsService.getClientApiKeys();
          }
          if (responses.subscriptionCBSAsData) {
            SubscriptionDetailsService.setSubscribedCBSAs(responses.subscriptionCBSAsData);
            $scope.subscribedCBSAs = SubscriptionDetailsService.getSubscribedCBSAs();
            $scope.metroBankHistory = responses.metroBankHistory;
            SubscriptionDetailsService.setMetroBankHistory($scope.metroBankHistory);
            if(Array.isArray($scope.metroBankHistory) && $scope.metroBankHistory.length > 0){
              $scope.showMetroBankHistory = true;
            }
            else{
              $scope.showMetroBankHistory = false;
            }
          }
          $scope.$broadcast('SilentSubscriptionDetailRefreshComplete');
        }).finally(function (){
          SpinnerService.stop()
        });
    });
    SpinnerService.start();

    var promises = {
      companyData : SubscriptionDetailsService.getCompanyData($scope.paramCompanyID),
      subscriptionData : SubscriptionDetailsService.getSubscriptionData($scope.paramSubscriptionID),
      subscriptionProductsData : SubscriptionDetailsService.getSubscriptionProductsData($scope.paramSubscriptionID),
      subscriptionCBSAsData : SubscriptionDetailsService.getSubscriptionCBSAsData($scope.paramSubscriptionID),
      subscriptionHistory : SubscriptionHistoryService.getSubscriptionHistory($scope.paramCompanyID),
      allProductGroupsAndProductsData : SubscriptionDetailsService.getAllProductGroupsAndProductsData(),
      allRegionsAndCBSAsData : SubscriptionDetailsService.getAllRegionsAndCBSAsData(),
      subscriptionTypes : SubscriptionDetailsService.getSubscriptionTypes(),
      clientApiKeys : ClientApiService.getClientApiKeys($scope.paramCompanyID),
      metroBankHistory: SubscriptionHistoryService.getMetroBankHistory($scope.paramCompanyID)
    };

    $q.all(promises).then(function (responses) {        
      $scope.companyInfo = responses.companyData;
      $scope.subscription = responses.subscriptionData;
      $scope.subscribedProducts = responses.subscriptionProductsData;
      $scope.subscribedCBSAs = responses.subscriptionCBSAsData;
      $scope.subscriptionHistory = responses.subscriptionHistory;
      $scope.allProducts = responses.allProductGroupsAndProductsData;
      $scope.allRegionsAndCBSAs = responses.allRegionsAndCBSAsData;
      $scope.allSubscriptionTypes = responses.subscriptionTypes;
      $scope.clientApiKeys = responses.clientApiKeys;
      $scope.metroBankHistory = responses.metroBankHistory;

      $scope.subProductsData = {
        allSubscriptionTypes: responses.subscriptionTypes,
        allProducts: responses.allProductGroupsAndProductsData,
        subscribedProducts: responses.subscriptionProductsData,
        subscription: responses.subscriptionData,
        isInPopup: false
      };

      $scope.isSubExpired = $scope.subscription.accountStatus.toLowerCase().indexOf("expired") >= 0;

      $scope.isSubTerminated = $scope.subscription.isTerminated;
      $scope.isPending = $scope.subscription.accountStatus.toLowerCase().indexOf("pending") >= 0;

      SubscriptionDetailsService.setSubscriber($scope.subscription);
      SubscriptionDetailsService.setCompany($scope.companyInfo);
      SubscriptionDetailsService.setSubscribedProducts($scope.subscribedProducts);
      SubscriptionDetailsService.setAllProducts($scope.allProducts);
      SubscriptionDetailsService.setSubscribedCBSAs($scope.subscribedCBSAs);
      SubscriptionDetailsService.setAllRegionsAndCBSAs($scope.allRegionsAndCBSAs);
      SubscriptionDetailsService.setAllSubscriptionTypes($scope.allSubscriptionTypes);
      SubscriptionDetailsService.setSubscriptionHistory($scope.subscriptionHistory);
      SubscriptionDetailsService.setClientApiKeys($scope.clientApiKeys);
      SubscriptionDetailsService.setMetroBankHistory($scope.metroBankHistory);

      if(Array.isArray($scope.metroBankHistory) && $scope.metroBankHistory.length > 0){
        $scope.showMetroBankHistory = true;
      } 
      SpinnerService.stop();
      $scope.promisesCompleted = true;
    }, function (error) {
      // error
      SpinnerService.stop();
      PopupHandlerService.openSimplePopup("There was an error when trying to load the page. Please try again or contact us.");
    });
  }
})();