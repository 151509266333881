(function () {
  "use strict";
  app.factory("DataAttributionService", DataAttributionService);

  DataAttributionService.$inject = ['$http', '$q', 'apiSettings'];

  function DataAttributionService($http, $q, apiSettings, DateOffsetUtils) {
    function getAttributionHtml() {
      var defer = $q.defer();

      var uri = apiSettings.url + "/reports/GetDataAttribution/?name=" + "AttributionHtml";
      
      $http
        .get(uri)
        .then(function (rsp) {
          var attributionHtml = rsp.data.replace('href=\\"', 'href="').replace('\\"', '"');
          defer.resolve(attributionHtml);
        }, function (err) {
          defer.reject(err);
        });

      return defer.promise;
    }

    function getDataServiceSource() {
      var defer = $q.defer();
      var uri = apiSettings.url + "/reports/GetDataAttribution/?name=" + "DataServiceSource";

      $http
        .get(uri)
        .then(function (rsp) {
          defer.resolve(rsp.data);
        }, function (err) {
          defer.reject(err);
        });

      return defer.promise;
    }

    function getDataServiceSourceHtml() {
      var defer = $q.defer();
      var uri = apiSettings.url + "/reports/GetDataAttribution/?name=" + "DataServiceSourceHtml";

      $http
        .get(uri)
        .then(function (rsp) {
          defer.resolve(rsp.data);
        }, function (err) {
          defer.reject(err);
        });

      return defer.promise;
    }

    return {
      getAttributionHtml: getAttributionHtml,
      getDataServiceSource: getDataServiceSource,
      getDataServiceSourceHtml: getDataServiceSourceHtml
  };
  }
}());

