(function() {
  "use strict";
  
  /*
    This service will go to bing maps spatial service api and get the geography for locations as needed.
    https://msdn.microsoft.com/en-us/library/mt750552.aspx
    https://msdn.microsoft.com/en-us/library/mt712855.aspx
    https://msdn.microsoft.com/en-us/library/mt712851.aspx
    https://msdn.microsoft.com/en-us/library/mt712877.aspx
    https://msdn.microsoft.com/en-us/library/dn306801.aspx
    https://blogs.bing.com/maps/2013/11/13/introducing-support-for-custom-geospatial-data-in-bing-sds
  */

  app.factory("BingGeoDataService", BingGeoDataService);
  
  BingGeoDataService.$inject = ['$q', 'apiSettings', 'BingMapUtils', 'GeoDataService'];

  function BingGeoDataService($q, apiSettings, BingMapUtils, GeoDataService) {
    var sdsManager;
    var getBoundaryRequestOptions = {
      lod: 0,
      entityType: null, // must be assigned before call
      /*
        Note: Some boundaries consist of multiple polygons. By default, only the first polygon will be returned. 
        Set the getAllPolygons property to true to have all the polygons that make up the boundary returned.
      */
      getAllPolygons: true 
    };
    var polygonOptions = {
      strokeColor: '#FF0000',
      fillColor: 'rgba(0,0,0,0)'
    };
    var geographyTracker = {};

    return {
      setupSdsManager: setupSdsManager,
      getBoundariesFromBing: getBoundariesFromBing,
      getAllCountiesBoundariesInBbox: getAllCountiesBoundariesInBbox
    };

    function setupSdsManager(){
      if (sdsManager) return;
      Microsoft.Maps.loadModule(['Microsoft.Maps.SpatialDataService'], function () {
        sdsManager = Microsoft.Maps.SpatialDataService;;
      });
    }

    function getBoundariesFromBing(locations, entityType, callback) {
      if (!sdsManager) return $q.resolve([]);

      var polygonsForAllLocations = [];

      var genericLocationObj = _.isArrayLikeObject(locations) ? locations : [locations];
      for (var i = genericLocationObj.length - 1; i >= 0; i--) {
        var locationToLookfor = genericLocationObj[i].toLowerCase();
        if (geographyTracker[locationToLookfor]) {
          var matchFound = geographyTracker[locationToLookfor];
          processPolygonResultFromBing(matchFound, polygonsForAllLocations, callback);
          genericLocationObj.splice(i, 1);
        }
      }

      if (!genericLocationObj.length)
        return $q.resolve(polygonsForAllLocations);

      var deferred = $q.defer();
      var processedGeoCounter = 0;

      getBoundaryRequestOptions.entityType = entityType;
      
      sdsManager.GeoDataAPIManager.getBoundary(
        genericLocationObj,
        getBoundaryRequestOptions,
        apiSettings.bingMapsKey,
        function (data) {
          if (data && data.results && data.results.length > 0) {
            processPolygonResultFromBing(data, polygonsForAllLocations, callback);
            geographyTracker[data.location.toLowerCase()] = data;
          }
          processedGeoCounter++;
          if (processedGeoCounter == genericLocationObj.length) {
            deferred.resolve(polygonsForAllLocations);
          }
        },
        null, 
        function errCallback(locationValue, networkStatus) {
          // skip the geo that errored out
          console.log('error occured when trying to get boundaries:', locationValue, networkStatus);
          processedGeoCounter++;
          if (processedGeoCounter == genericLocationObj.length) {
            deferred.resolve(polygonsForAllLocations);
          }
        });

      return deferred.promise;
    }

    function processPolygonResultFromBing(locationResultFromBing, polygonsForAllLocations, callback) {
      _.each(locationResultFromBing.results[0].Polygons, function (bingPolygon, idx) {
        var polygon = {
          id: locationResultFromBing.location + '_' + idx,
          name: locationResultFromBing.location,
          locations: bingPolygon.getLocations(),
          strokeColor: polygonOptions.strokeColor,
          fillColor: polygonOptions.fillColor,
          isInCoverage: true
        };
        if (callback)
          callback(locationResultFromBing, polygon);
        polygonsForAllLocations.push(polygon);
      });
    }

    function getAllCountiesBoundariesInBbox(bingMap, showAllCounties, nonCoveredCounties) {
      var deferred = $q.defer();
      var showNonCoveredCounties = nonCoveredCounties || false;
      
      var bounds = BingMapUtils.boundsFromMap(bingMap);
      GeoDataService
        .getCountiesInBoundingBox(bounds)
        .then(function (counties) {
          var nonCoveredCounties = [], allCounties = [];
          _.each(counties, function (county) {
            var countyToAdd = (county.nameLSAD + ", " + county.stateProvinceCD).replace("'", ""); // counties with apostrophe can cause 400 error
            if (showNonCoveredCounties && county.isCollected == 0)
              nonCoveredCounties.push(countyToAdd);
            if (showAllCounties)
              allCounties.push(countyToAdd);
          });

          if (!nonCoveredCounties.length && !allCounties.length) {
            deferred.resolve([]);
          } else {
            var polygonsToReturnPromise = getBoundariesFromBing(_.union(nonCoveredCounties, allCounties), "AdminDivision2", 
              function (bingData, polygon) { setupPolygon(counties, showAllCounties, showNonCoveredCounties, bingData, polygon); });
            polygonsToReturnPromise
              .then(function (polygonsToReturn) {
                deferred.resolve(polygonsToReturn);
              });
          }
        }, function(err) {
          deferred.reject(err);
        });

      return deferred.promise;
    }

    function setupPolygon(counties, showAllCounties, showNonCoveredCounties, bingData, polygon) {
      if (showNonCoveredCounties) {
        _.each(counties, function (county) {
          var countyNameMatch1 = county.countyName + ", " + county.stateProvinceCD;
          var countyNameMatch2 = county.nameLSAD + ", " + county.stateProvinceCD;
          if ((bingData.location == countyNameMatch1 || bingData.location == countyNameMatch2) && county.isCollected == 0) {
            polygon.isInCoverage = false;
            return;
          }
        });
      }
    }
  }
})();
