(function () {
  "use strict";

  app.factory("ElementSizeWatcher", ElementSizeWatcher);

  ElementSizeWatcher.$inject = ['$interval'];

  function ElementSizeWatcher($interval) {
    return function (element, rate) {
      var self = this;
      (self.update = function () { self.dimensions = [element.offsetWidth, element.offsetHeight]; })();
      self.group = [function () { return self.dimensions[0]; }, function () { return self.dimensions[1]; }];
      self.monitor = $interval(self.update, rate);
      self.cancel = function () { $interval.cancel(self.monitor); };
    }
  }
})();
