(function () {
  "use strict";

  app.factory('SiteInformationReportService', SiteInformationReportService);

  SiteInformationReportService.$inject = ['$http', '$q', '$cookies', 'apiSettings', 'knownEventConstant', 'CacheFactory', 'AuditService'];

  function SiteInformationReportService($http, $q, $cookies, apiSettings, knownEventConstant, CacheFactory, AuditService) {
    if (!CacheFactory.get('demographicsCache')) {
      CacheFactory.createCache('demographicsCache', {
        maxAge: 24 * 60 * 60 * 1000, // Items added to this cache expire after 1 day
        cacheFlushInterval: 48 * 60 * 60 * 1000, // This cache will clear itself every 2 days
        deleteOnExpire: 'aggressive', // Items will be deleted from this cache when they expire
        storageMode: 'sessionStorage'
        });
    }
    var demographicsCache = CacheFactory.get('demographicsCache');

    return {
      getReport: getReport,
      getDemographics: getDemographics,
      getCohortData: getCohortData,
      getExcel: getExcel,
      getPdf: getPdf
    };
    
    function getReport(queryGuid, isPageLoad) {
      var deferred = $q.defer();
      if (queryGuid) {
        $http.get(apiSettings.url + "/siteInformationReports/" + queryGuid)
          .then(function (rsp) {
            if (isPageLoad && rsp.data) {
              // log event
              AuditService.logEvent(knownEventConstant.cohortDemographicsSIR.id, 
                knownEventConstant.cohortDemographicsSIR.message + ": " + rsp.data.areaAccessed);
            }
            deferred.resolve(rsp.data);
          },
          function (error) {
            deferred.reject(error);
          });
      } else {
        deferred.reject({
          source: "Missing Parameter : (queryGuid)",
          error: "Query guid is required"
        });
      }

      return deferred.promise;
    };

    function getDemographics(queryGuid) {
      var deferred = $q.defer();
      if (queryGuid) {
          $http
            .get(apiSettings.url + "/siteInformationReports/demographics/" + queryGuid)
            .then(function (rsp) {
              var apiQueryObj = rsp.data;
              
              deferred.resolve(apiQueryObj);
            },
            function (error) {
              deferred.reject(error);
            });
        } else {
          deferred.reject({
            source: "Missing Parameter : (queryGuid)",
            error: "Query guid is required"
          });
        }
      

      return deferred.promise;
    }

    function getCohortData(passedInData) {
      var url = apiSettings.url + "/siteInformationReports/cohortdata";
      var deferred = $q.defer();
      $http
        .post(url, passedInData)
        .then(function (rsp) {
          deferred.resolve(rsp.data);
        }, function (err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }


    function getExcel(sirPropertyVm) {
      var deferred = $q.defer();              
      var uri = apiSettings.url + '/siteInformationReports/export/excel?' +
        $.param({
          date: new Date()
        });      
      $http
        .post(uri, sirPropertyVm, {
          responseType: 'arraybuffer'
        })
        .then(function (rsp) {
          if (rsp.data.length == 0) {
            deferred.reject();
          } else {
            deferred.resolve(rsp.data);
          }
        }, function (err) {
            deferred.reject(err);
        });
      return deferred.promise;
    }

    function getPdf(queryId, title, sirVm) {
      var deferred = $q.defer();
      var uri = apiSettings.url + '/siteInformationReports/export/pdf/' + queryId + '?' +
        $.param({
          title: title,
          date: new Date()
        });
      $http
        .post(uri, sirVm, {
          responseType: 'arraybuffer'
        })
        .then(function (rsp) {
          if (rsp.data.length == 0) {
            deferred.reject();
          } else {
            deferred.resolve(rsp.data);
          }
        }, function (err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }
  }
})();
