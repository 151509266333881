(function() {
  "use strict";
  
  app.directive('propertyQualityMetrics', PropertyQualityMetricsDirective);

  function PropertyQualityMetricsDirective() {
    return {
      restrict: 'E',
      templateUrl: '/app/partials/propertyInfo/propertyQualityMetrics.html',
      controller: PropertyQualityMetricsController,
      controllerAs: 'propertyQualityMetrics',
      scope: {},
      bindToController: {
        qualityMetrics: '=qualityMetrics'
      }
    };
  }

  PropertyQualityMetricsController.$inject = ['$scope', 'KendoTemplateFunctions', 'SpinnerService', 'PropertyDetailsService'];

  function PropertyQualityMetricsController($scope, KendoTemplateFunctions, SpinnerService, PropertyDetailsService) {
    var vm = this;
    vm.$onInit = init;
    vm.defaultLegend = KendoTemplateFunctions.defaultChartLegend;
    vm.seriesDefaults = {
      type: 'line',
      style: 'smooth',
      markers: {
        visible: false
      },
      missingValues: 'gap'
    };
    vm.categoryAxis = {
      majorGridLines: {
        visible: false
      },
      majorTicks: {
        visible: false
      },
      minorTicks: {
        visible: false
      },
      line: {
        visible: false
      },
      labels: {
        template: xAxisLabels
      }
    };
    vm.propertyCMSData = [];
    vm.propertyDeficiencies = [];
    var chartPercentageAxis = {
      name: "percentageAxis",
      color: "#959595",
      labels: {
        format: "p1",
        color: "#232323"
      },
      majorTicks: {
        visible: false
      },
      minorTicks: {
        visible: false
      },
      line: {
        visible: false
      },
      narrowRange: true
    };
    var chartMinMax = {
      minChart1Chart2: null,
      maxChart1Chart2: null,
      minChart3Chart4: null,
      maxChart3Chart4: null
    };

    function init() {
      SpinnerService.start();
      PropertyDetailsService
        .getQualityMetricsforProperty(vm.qualityMetrics.id)
        .then(function (rsp) {
          vm.qualityMetrics.data = rsp; // pass the data to parent so we can use it in pdf export (for now)
          vm.qualityMetrics.showQualityMetrics = rsp.showQualityMetrics;
          if (!vm.qualityMetrics.showQualityMetrics) {
            SpinnerService.stop();
            return;
          }
          vm.publishDate = rsp.publishDate;
          vm.qualityMetrics.cmsProviderId = rsp.cmsProviderId;
          vm.qualityMetrics.cmsScore = rsp.cmsFiveStarRating;
          chartMinMax.minChart1Chart2 = rsp.minChart1Chart2;
          chartMinMax.maxChart1Chart2 = rsp.maxChart1Chart2;
          chartMinMax.minChart3Chart4 = rsp.minChart3Chart4;
          chartMinMax.maxChart3Chart4 = rsp.maxChart3Chart4;

          vm.propertyCMSData = rsp.propertyCMSData;
          setupScoreAndMeansGrid();
          
          vm.propertyDeficiencies = rsp.propertyDeficiencies;
          setupSurveyDeficienciesGrid();
          
          setupCharts();
          vm.qualityMetrics.getChartSeriesInfo = getChartSeriesInfo; // hook for parent to be able to call the function to get series info

          SpinnerService.stop();
        }, function (err) {
          console.log(err);
          SpinnerService.stop();
        });
    }

    function setupCharts() {
      var categories = _.map(vm.propertyCMSData, function (cmsData) {
        return cmsData.metricDateStr;
      });

      var proGeographyChart = $scope['proGeography'];
      proGeographyChart.setOptions({
        series: chartSeries('proGeography'),
        categoryAxis: { categories: categories },
        valueAxis: _.extend(angular.copy(chartPercentageAxis), { min: chartMinMax.minChart1Chart2, max: chartMinMax.maxChart1Chart2 })
      });
      var proLongStayGeographyChart = $scope['proLongStayGeography'];
      proLongStayGeographyChart.setOptions({
        series: chartSeries('proLongStayGeography'),
        categoryAxis: { categories: categories },
        valueAxis: _.extend(angular.copy(chartPercentageAxis), { min: chartMinMax.minChart1Chart2, max: chartMinMax.maxChart1Chart2 })
      });
      var proPercentileChart = $scope['proPercentile'];
      proPercentileChart.setOptions({
        series: chartSeries('proPercentile'),
        categoryAxis: { categories: categories },
        valueAxis: _.extend(angular.copy(chartPercentageAxis), { min: chartMinMax.minChart3Chart4, max: chartMinMax.maxChart3Chart4 })
      });
      var proLongStayPercentileChart = $scope['proLongStayPercentile'];
      proLongStayPercentileChart.setOptions({
        series: chartSeries('proLongStayPercentile'),
        categoryAxis: { categories: categories },
        valueAxis: _.extend(angular.copy(chartPercentageAxis), { min: chartMinMax.minChart3Chart4, max: chartMinMax.maxChart3Chart4 })
      });
    }

    function chartSeries(chartName) {
      switch(chartName) {
        case 'proGeography':
          return [
            getSeries('National Median', '#950000', 'nationalPro30AdjMedian'),
            getSeries('State Median', '#00A7CE', 'statePro30AdjMedian'),
            getSeries('Metro Median', '#959595', 'metroPro30AdjMedian'),
            getSeries('Property', '#CBB54C', 'pro30Adjusted')
          ];
        case 'proLongStayGeography':
          return [
            getSeries('National Median', '#950000', 'natLsAdjMedian'),
            getSeries('State Median', '#00A7CE', 'stateLsAdjMedian'),
            getSeries('Metro Median', '#959595', 'metroLsAdjMedian'),
            getSeries('Property', '#CBB54C', 'lsAdjusted')
          ];
        case 'proPercentile':
          return [
            getSeries('Metro 10th Percentile', '#FF9900', 'pro30Adj10th'),
            getSeries('Metro 90th Percentile', '#084865', 'pro30Adj90th'),
            getSeries('Metro Median', '#959595', 'metroPro30AdjMedian'),
            getSeries('Property', '#CBB54C', 'pro30Adjusted')
          ];
        case 'proLongStayPercentile':
          return [
            getSeries('Metro 10th Percentile', '#FF9900', 'lsAdj10th'),
            getSeries('Metro 90th Percentile', '#084865', 'lsAdj90th'),
            getSeries('Metro Median', '#959595', 'metroLsAdjMedian'),
            getSeries('Property', '#CBB54C', 'lsAdjusted'),
          ];
      }
    }

    function getSeries(seriesName, color, fieldName) {
      return {
        field: fieldName,
        color: color,
        name: seriesName,
        axis: 'percentageAxis',
        data: vm.propertyCMSData
      };
    }

    function xAxisLabels(kendoDataItem) {
      var publishDateMonth = vm.publishDate.substring(0, vm.publishDate.indexOf('/'));
      return KendoTemplateFunctions.labelForQualityMetrics(kendoDataItem, publishDateMonth);
    }

    function getChartSeriesInfo() {
      var seriesInfo = [];
      seriesInfo.push(getMinMaxForChart($scope['proGeography']));
      seriesInfo.push(getMinMaxForChart($scope['proLongStayGeography']));
      seriesInfo.push(getMinMaxForChart($scope['proPercentile']));
      seriesInfo.push(getMinMaxForChart($scope['proLongStayPercentile']));
      return seriesInfo;
    }

    function getMinMaxForChart(chart) {
      if (chart) {
        var valueOptions = chart._plotArea.valueAxis.plotArea.axisY.options;
        return {
          majorUnit: valueOptions.majorUnit,
          max: valueOptions.max,
          min: valueOptions.min
        };
      }
      return {};
    }

    function setupScoreAndMeansGrid() {
      vm.gridOpts_gridScoreAndMeans = {
        columns: [
        {
          field: 'metricDateStr',
          title: 'Period',
          attributes: {
            'class': 'text-right'
          }
        }, {
          title: "Overall",
          columns: [{
            field: 'propertyOverall',
            title: 'Property',
            attributes: {
              'class': 'text-right'
            }
          }, {
            field: 'metroOverall',
            title: 'Metro',
            format: "{0:n1}",
            attributes: {
              'class': 'text-right'
            }
          }, {
            field: 'stateOverall',
            title: 'State',
            format: "{0:n1}",
            attributes: {
              'class': 'text-right'
            }
          }, {
            field: 'nationalOverall',
            title: 'National',
            format: "{0:n1}",
            attributes: {
              'class': 'text-right'
            }
          }]
        }, {
          title: "Health Inspection",
          columns: [{
            field: 'propertyHealthInspection',
            title: 'Property',
            attributes: {
              'class': 'text-right'
            }
          }, {
            field: 'metroHealthInspection',
            title: 'Metro',
            format: "{0:n1}",
            attributes: {
              'class': 'text-right'
            }
          }, {
            field: 'stateHealthInspection',
            title: 'State',
            format: "{0:n1}",
            attributes: {
              'class': 'text-right'
            }
          }, {
            field: 'nationalHealthInspection',
            title: 'National',
            format: "{0:n1}",
            attributes: {
              'class': 'text-right'
            }
          }]
        }, {
          title: "Staffing",
          columns: [{
            field: 'propertyStaffing',
            title: 'Property',
            attributes: {
              'class': 'text-right'
            }
          }, {
            field: 'metroStaffing',
            title: 'Metro',
            format: "{0:n1}",
            attributes: {
              'class': 'text-right'
            }
          }, {
            field: 'stateStaffing',
            title: 'State',
            format: "{0:n1}",
            attributes: {
              'class': 'text-right'
            }
          }, {
            field: 'nationalStaffing',
            title: 'National',
            format: "{0:n1}",
            attributes: {
              'class': 'text-right'
            }
          }]
        }, {
          title: "Quality Measures",
          columns: [{
            field: 'propertyQuality',
            title: 'Property',
            attributes: {
              'class': 'text-right'
            }
          }, {
            field: 'metroQuality',
            title: 'Metro',
            format: "{0:n1}",
            attributes: {
              'class': 'text-right'
            }
          }, {
            field: 'stateQuality',
            title: 'State',
            format: "{0:n1}",
            attributes: {
              'class': 'text-right'
            }
          }, {
            field: 'nationalQuality',
            title: 'National',
            format: "{0:n1}",
            attributes: {
              'class': 'text-right'
            }
          }]
        }],
        dataSource: new kendo.data.DataSource({
          transport: {
            read: function (options) {
              var publishDateMonth = vm.publishDate.substring(0, vm.publishDate.indexOf('/'));
              var dataForCmsTable = _.filter(vm.propertyCMSData, function (cmsData) {
                return cmsData.metricDateStr.indexOf(publishDateMonth) >= 0;
              });
              options.success(dataForCmsTable);
            }
          },
          schema: {
            model: {
              fields: {
                'metricDateStr': { type: 'string' },
                'propertyOverall': { type: 'number' },
                'metroOverall': { type: 'number' },
                'stateOverall': { type: 'number' },
                'nationalOverall': { type: 'number' },
                'propertyHealthInspection': { type: 'number' },
                'metroHealthInspection': { type: 'number' },
                'stateHealthInspection': { type: 'number' },
                'nationalHealthInspection': { type: 'number' },
                'propertyStaffing': { type: 'number' },
                'metroStaffing': { type: 'number' },
                'stateStaffing': { type: 'number' },
                'nationalStaffing': { type: 'number' },
                'propertyQuality': { type: 'number' },
                'metroQuality': { type: 'number' },
                'stateQuality': { type: 'number' },
                'nationalQuality': { type: 'number' }
              }
            }
          },
          serverPaging: false,
          serverFiltering: false,
          serverSorting: false
        }),
        scrollable: false,
        sortable: false,
        pageable: false
      };
    }

    function setupSurveyDeficienciesGrid() {
      var pageSize = 5;
      vm.gridOpts_gridSurveyDeficiencies = {
        columns: [
        {
          field: 'deficiencyTag',
          title: 'Deficiency<br />Tag*'
        }, {
          field: 'description',
          title: 'Description',
          width: '70%'
        }, {
          field: 'scopeAndSeverity',
          title: 'Scope and<br />Severity**',
          attributes: {
            'class': 'text-right'
          }
        }, {
          field: 'mostRecentCycle',
          title: 'Most Recent<br />Cycle',
          attributes: {
            'class': 'text-right'
          }
        }, {
          field: 'previousTwoCycles',
          title: 'Previous<br />Two Cycles',
          attributes: {
            'class': 'text-right'
          }
        }],
        dataSource: new kendo.data.DataSource({
          data: vm.propertyDeficiencies,
          pageSize: pageSize,
          schema: {
            model: {
              fields: {
                'deficiencyTag': { type: 'string' },
                'description': { type: 'string' },
                'scopeAndSeverity': { type: 'string' },
                'mostRecentCycle': { type: 'number' },
                'previousTwoCycles': { type: 'number' }
              }
            }
          },
          serverPaging: false,
          serverFiltering: false,
          serverSorting: false
        }),
        scrollable: false,
        sortable: false,
        pageable: vm.propertyDeficiencies.length > pageSize ? true : false,
      };
    }
  }
})();
