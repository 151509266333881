(function() {
  "use strict";
  app.controller("WordpressPreviewController", WordpressPreviewController);
  WordpressPreviewController.$inject = ['$scope', '$sce','content']
  function WordpressPreviewController($scope, $sce, content) {
  	$scope.postContent = content;
  	
    function init() {
  		switch($scope.postContent.type) {
  			case "newsletter":
  				$scope.insights = $scope.postContent;
  				$scope.insights.content = $sce.trustAsHtml($scope.insights.content);
  				break;
  			case "conference-recording":
  				$scope.recordings = $scope.postContent;
  				$scope.recordings.content = $sce.trustAsHtml($scope.recordings.content);
  				break;
        case "news":
          setHomePageBG();
          $scope.newses = [];
          $scope.newses.push($scope.postContent);
          break;
        case "webinar-information":
          setHomePageBG();
          $scope.webinarInfo = $scope.postContent;
          break;
        case "webinar-slides":
          setHomePageBG();
          $scope.slides = $scope.postContent.slides;
          break;
        case "page":
          $scope.pages = $scope.postContent;
          $scope.pages.content = $sce.trustAsHtml($scope.pages.content);
          break;
  			default:
  				break;
  		}
  	}

    function setHomePageBG() {
      document.body.className = document.body.className + ' home-page';
    }

  	init();
  }
})();