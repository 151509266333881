(function () {
  "use strict";

  app.controller('DataReleaseWebinarController', DataReleaseWebinarController);
  DataReleaseWebinarController.$inject = ['$scope', 'libraryItems', 'ReportsService', 'SpinnerService', 'AuditService', 'FileSaver', 'currentQuarter', '$sce', 'knownEventConstant'];
  function DataReleaseWebinarController($scope, libraryItems, ReportsService, SpinnerService, AuditService, FileSaver, currentQuarter, $sce, knownEventConstant) {

    $scope.quarterDropdownChanged = quarterDropdownChanged;
    $scope.downloadNote = downloadNote;
    $scope.downloadSlide = downloadSlide;
    $scope.init = init;

    $scope.player = {
      API: null,
      config: {
        theme: null,
        autoplay: false,
        autohide: true,
        autohideTime: 2000,
        sources: [{ src: null, type: null }]
      },
      onPlayerReady: function (API) {
        // Wrap logging ability onto play() defined in Videogular API
        var originalPlayFunc = API.play;
        API.play = function play() {
          AuditService.logEvent(knownEventConstant.dataReleaseWebinarPlayVideo.id,
                                knownEventConstant.dataReleaseWebinarPlayVideo.message + ' - ' + $scope.selectedQuarter.video.titleText);
          return originalPlayFunc.apply(this, arguments);
        }

        $scope.player.API = API;
        setPlayerSource();
      }
    };


    function quarterDropdownChanged (e) {
      SpinnerService.start();
      setPlayerSource();
      AuditService.logEvent(knownEventConstant.dataReleaseWebinarQuarterSelect.id,
                            knownEventConstant.dataReleaseWebinarQuarterSelect.message + ': ' + $scope.selectedQuarter.quarterForDisplay);
      SpinnerService.stop();
    }

    function setPlayerSource() {
      $scope.player.config.sources = [{ src: null, type: null }];

      if ($scope.player.API){
        $scope.player.API.stop();
        $scope.player.API.clearMedia();
        if ($scope.selectedQuarter && $scope.selectedQuarter.video && $scope.selectedQuarter.video.fileURL) {
          $scope.player.config.sources = [{
            src: $sce.trustAsResourceUrl($scope.selectedQuarter.video.fileURL),
            type: "video/mp4"
          }]
        }
      }
    }

    function downloadNote() {
      if ($scope.selectedQuarter) {
        fileProxyDownload($scope.selectedQuarter.note, knownEventConstant.dataReleaseWebinarDownloadNotes.id);
      }
    }

    function downloadSlide() {
      if ($scope.selectedQuarter) {
        fileProxyDownload($scope.selectedQuarter.slide, knownEventConstant.dataReleaseWebinarDownloadSlides.id);
      }
    }

    function fileProxyDownload(dataItem, eventId) {
      if (dataItem) {
        dataItem.fileType = 'pdf';
        SpinnerService.start();
        var params = {
          target: dataItem.fileURL,
          fileName: dataItem.fileName,
          fromWhere: "Data Release Webinar",
          userEventId: eventId
        };
        ReportsService
          .fileProxyDownload(params)
          .then(function (data) {
            var file = new Blob([data], {
              type: dataItem.contentType
            });
            var fileName = dataItem.fileName.replace(/ /g, '_') + '.' + dataItem.fileType;
            FileSaver.saveAs(file, fileName);
          })
          .finally(function () {
            SpinnerService.stop();
          });
      }
    }

    function init(){
      libraryItems = _.each(libraryItems, function (element) {
        element.quarterForSorting = element['wpcf-data-release-year'] + "_" + element['wpcf-data-release-quarter'];
        element.quarterForDisplay = element['wpcf-data-release-quarter'] + "Q" + element['wpcf-data-release-year'];
        element.description = element['wpcf-data-release-description'];
        element.webinarItemType = element['wpcf-data-release-webinar-type'];
        element.fileURL = element['wpcf-data-release-upload-file'];
        element.titleText = element.title.rendered;
        element.fileName = fileNameBuilder();

        function fileNameBuilder() {
          switch (element.webinarItemType) {
            case '2':
              return element.quarterForDisplay + '_' + 'NICMAP_DataReleaseWebinarSpeakerNotes';
            case '3':
              return element.quarterForDisplay + '_' + 'NICMAP_DataReleaseWebinarSlides';
            default:
              return null;
          }
        }
      });

      $scope.webinarData = _(libraryItems)
            .groupBy('quarterForDisplay')
            .map(function(value, key){
              return{
                  quarterForDisplay: key,
                  quarterForSorting : value[0].quarterForSorting,
                  video :  filterByTypeAndGetLatest(value, '1'),
                  note :  filterByTypeAndGetLatest(value, '2'),
                  slide :  filterByTypeAndGetLatest(value, '3'),

              };
            })
            .orderBy('quarterForSorting','desc')
            .value();

      if ($scope.webinarData && $scope.webinarData.length > 0) {
        var currentQuarterIndex = _.findIndex($scope.webinarData, function (o) { return o.quarterForDisplay == currentQuarter; });
        if (currentQuarterIndex == -1) {
          $scope.selectedQuarter = $scope.webinarData[0];
        } else {
          $scope.selectedQuarter = $scope.webinarData[currentQuarterIndex];
        }
        setPlayerSource();
      }
    }

    function filterByTypeAndGetLatest(items, webinarItemType) {
      return _(items)
      .filter(function(x){ return x.webinarItemType == webinarItemType})
        .orderBy(['date'], ['desc'])
        .value()[0];
    }

    init();
  }
}());