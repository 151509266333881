(function () {
  //http://onehungrymind.com/winning-http-interceptors-angularjs/
  //http://stackoverflow.com/questions/20647483/angularjs-injecting-service-into-a-http-interceptor-circular-dependency
  //http://blog.ionic.io/angularjs-authentication/

  "use strict";

  app.factory("APIInterceptor", APIInterceptor);

  APIInterceptor.$inject = ['$q', '$injector', '$rootScope'];

  function APIInterceptor($q, $injector, $rootScope) {
    return {
      'request': function (config) {
        //injected manually to get around circular dependency problem.
        var authService = $injector.get('AuthService');
        var identity = authService.identity;
    
        if (identity && identity.sessionId()) {
          config.headers['X-CSRFToken'] = identity.sessionId(); //Cross Site Request Forgery (CSRF)
        }
    
        return config;
      },
      'responseError': function (rejection) {
        var authService = $injector.get('AuthService');
        var identity = authService.identity;
        if (identity.isAuth() && identity.sessionId() && rejection.status == 501) {
          var header = rejection.headers("DuplicateLogin");
          if (header) {
            $rootScope.$broadcast("kickedOut");
          }
        }
        return $q.reject(rejection);
      }
    };
  }
}());