(function () {
  "use strict";

  app.controller('SubscriptionClientApiSendEmailPopupController', SubscriptionClientApiSendEmailPopupController);

  SubscriptionClientApiSendEmailPopupController.$inject = ['$scope', 'subscriptionId', 'ClientApiService', 'data', 'SpinnerService', 'SubscriptionDetailsService',
    'SubscriptionUsersService'];

  function SubscriptionClientApiSendEmailPopupController($scope, subscriptionId, ClientApiService, data, SpinnerService, SubscriptionDetailsService,
    SubscriptionUsersService) {
    $scope.activeSubscriptionContacts = [];
    $scope.emailAddress = null;
    $scope.message = null;
    $scope.alreadyTriedToSend = false;
    $scope.environmentTypeName = data.environmentTypeName;
    $scope.companyInfo = SubscriptionDetailsService.getCompany();
    $scope.onSelectedCallback = onSelectedCallback;
    $scope.formatLabel = formatLabel;
    $scope.sendEmail = sendEmail;
    $scope.cancelClick = cancelClick;
    $scope.okClick = okClick;

    function getActiveSubscriptionContacts() {
      SpinnerService.start();
      return SubscriptionUsersService.getSubscriptionContacts(subscriptionId, true)
        .then(function (data) {
          $scope.activeSubscriptionContacts = data;
        })
        .finally(function () {
          SpinnerService.stop();
        });
    }

    getActiveSubscriptionContacts();

    function sendEmail() {
      if ($scope.emailAddress) {
        SpinnerService.start();
        ClientApiService
          .sendApiKeyEmail(data.id, $scope.emailAddress)
          .then(function success() {
            $scope.message = 'The ' + $scope.environmentTypeName + ' API Key was successfully sent to ' + $scope.emailAddress + '.';
          }, function error(response) {
            $scope.message = 'The ' + $scope.environmentTypeName + ' API Key was not successfully sent to ' + $scope.emailAddress + '. Please try again. If the problem persists, ' +
              'contact Akash #senior.';
          })
          .finally(function () {
            $scope.alreadyTriedToSend = true;
            SpinnerService.stop();
          }); 
      }
    };

    function okClick() {
      $scope.$close($scope.message);
    };

    function cancelClick() {
        $scope.$dismiss();
    };

    function onSelectedCallback($item, $model, $label) {
      if ($item) {
        $scope.emailAddress = $item.email;
      }
    }

    function formatLabel($model) {
      return $scope.emailAddress;
    };
  }
}());