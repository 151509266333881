(function() {
  "use strict";
  app.factory("PopupHandlerService", PopupHandlerService);

  PopupHandlerService.$inject = ["$state", "$uibModal", "$window"];

  function PopupHandlerService($state, $uibModal, $window) {
    PopupController.$inject = ['link', 'reportType'];
    SimplePopupController.$inject = ["message"];
    return {
      openReportInNewTab: openReportInNewTab,
      openSimplePopup: openSimplePopup,
      openConfirmPopup: openConfirmPopup,
      openPopupForTemplate: openPopupForTemplate,
      openPopupViaObject: openPopupViaObject,
    }

    function PopupController(link, reportType) {
      this.link = link;
      this.reportType = reportType;
    }

    function openReportInNewTab(stateToGoTo, reportGuid, reportType) {
      //$state.href will be something like #/siteInformationReport/7A28BA51-8570-4002-9E37-C49899448251
      var url = "/" + $state.href(stateToGoTo, {
        id: reportGuid
      });

      var popUp = $window.open(url, '_blank');
      //when popup blocked popUp will be undefined
      //when successful popUp will be a an object with popUp.closed === false

      if(!popUp || popUp.closed || typeof popUp.closed == 'undefined') {
        //Browser except Chrome - POPUP BLOCKED
        $uibModal.open({
          templateUrl: "app/partials/popupBlocker.html",
          controller: PopupController,
          controllerAs: "ctrl",
          resolve: {
            link: function() {
              return url;
            },
            reportType: function() {
              return reportType;
            }
          }
        });
      } else {
        popUp.focus();
      }
    }

    function SimplePopupController(message) {
      this.message = message;
    }


    function openSimplePopup(messageText, overrides) {
      var modalDefaults = _.assign({
        templateUrl: "app/partials/nodata.html",
        controller: SimplePopupController,
        controllerAs: "ctrl",
        resolve: {
          message: function () {
            return messageText;
          }
        }
      }, overrides);
      return $uibModal.open(modalDefaults);
    }

    
    function openConfirmPopup(overrides) {
      var options = _.assign({
        message: 'Placeholder Text',
        confirmText: 'Yes',
        dismissText: 'No',
        confirmValue: undefined,
        dismissValue: undefined
      }, overrides);

      ConfirmationPopupController.$inject = ['popupOptions'];
      function ConfirmationPopupController(popupOptions) {
        this.popup = popupOptions;
      };

      return $uibModal.open({
        backdrop: 'static',
        keyboard: false,
        templateUrl: 'app/partials/confirmPopup.html',
        controller: ConfirmationPopupController,
        controllerAs: "ctrl",
        resolve: {
          popupOptions: function () {
            return options;
          }
        }
      });
    }

    function openPopupForTemplate(templateUrl, popupController) {
      return $uibModal.open({
        templateUrl: templateUrl, //"app/partials/saveDataPopup.html",
        controller: popupController
      });
    }

    function openPopupViaObject(popupObject) {
      return $uibModal.open(popupObject);
    }
  }
})();
