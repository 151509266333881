(function () {
  'use strict';

  app.controller('SiteInformationReportController', SiteInformationReportController);

  SiteInformationReportController.$inject = ['$scope', '$state', '$rootScope', '$q', '$sce', '$filter', '$window', 'FileSaver', 'BingMapUtils', 'PinFactory', 'PinGeneratorUtil'
    , 'GeoDataService', 'BingGeoDataService', 'SiteInformationReportService', 'AuditService', 'knownEventConstant', 'knownProductConstant'
    , 'SpinnerService', 'TrendsService', 'PropertyDetailsService', 'pushpinImages', 'reportSectionTypeConstant', 'DataAttributionService'];

  function SiteInformationReportController($scope, $state, $rootScope, $q, $sce, $filter, $window, FileSaver, BingMapUtils, PinFactory, pinGeneratorUtil
    , GeoDataService, BingGeoDataService, SiteInformationReportService, AuditService, knownEventConstant, knownProductConstant
    , SpinnerService, TrendsService, PropertyDetailsService, pushpinImages, reportSectionTypeConstant, DataAttributionService) {
    $rootScope.hideLeftNav = true;
    SpinnerService.start();

    var pageSize = 5;
    var bingMap;
    var isDestroyed = false, isInit = false;

    $scope.sirReport = {};
    $scope.tradeAreaDriveTimeEsriPolygons = [];
    $scope.tradeAreaOneRadius = BingMapUtils.getPolygonOptions('tradeAreaOneRadius', []);
    $scope.tradeAreaTwoRadius = BingMapUtils.getPolygonOptions('tradeAreaTwoRadius', []);
    $scope.customCohortClick = customCohortClick;
    $scope.propertyDetailsClick = propertyDetailsClick;

    $scope.showHospitals = false;
    $scope.showExistingInventory = false;
    $scope.showConstructionInventory = false;
    $scope.showMetroArea = false;
    $scope.showDemographicsData = false;
    $scope.showCounties = false;
    $scope.showTransactionActivity = false;
    $scope.coveredCountiesPolygons = [];
    $scope.AttributionHtml = "";
    
    $scope.onClickShowHospitals = onClickShowHospitals;
    $scope.onClickShowExistingInventory = onClickShowExistingInventory;
    $scope.onClickShowConstructionInventory = onClickShowConstructionInventory;
    $scope.onClickShowTransactionActivity = onClickShowTransactionActivity;
    $scope.onMapReady = onMapReady;
    
    $scope.existingInventory = {
      properties: [],
      tradeAreaOne: {
        count: 0
      },
      tradeAreaTwo: {
        count: 0
      },
      spaces: {
        IL: 0,
        AL: 0,
        MC: 0,
        NC: 0,
        totalSpaces: function () {
          return this.IL + this.AL + this.MC + this.NC;
        },
        ILFormatted: function () {
          return $filter('number')(this.IL, 0);
        },
        ALFormatted: function () {
          return $filter('number')(this.AL, 0);
        },
        MCFormatted: function () {
          return $filter('number')(this.MC, 0);
        },
        NCFormatted: function () {
          return $filter('number')(this.NC, 0);
        },
        totalSpacesFormatted: function () {
          return $filter('number')(this.totalSpaces(), 0);
        },
        trusted: {}
      }
    };

    $scope.constructionInventory = {
      properties: [],
      tradeAreaOne: {
        count: 0,
        expansionCount: 0,
        newCount: 0,
        trusted: {}
      },
      tradeAreaTwo: {
        count: 0,
        expansionCount: 0,
        newCount: 0,
        trusted: {}
      },
      spaces: {
        IL: 0,
        AL: 0,
        MC: 0,
        NC: 0,
        ILFormatted: function () {
          return $filter('number')(this.IL, 0);
        },
        ALFormatted: function () {
          return $filter('number')(this.AL, 0);
        },
        MCFormatted: function () {
          return $filter('number')(this.MC, 0);
        },
        NCFormatted: function () {
          return $filter('number')(this.NC, 0);
        },
        totalSpacesFormatted: function () {
          return $filter('number')(this.totalSpaces(), 0);
        },
        totalSpaces: function () {
          return this.IL + this.AL + this.MC + this.NC;
        },
        trusted: {}
      }
    };

    $scope.transactionActivity = {
      properties: [],
      tradeAreaOne: {
        count: 0
      },
      tradeAreaTwo: {
        count: 0
      }
    };

    $scope.hospitals = {
      properties: [],
      tradeAreaOne: {
        count: 0
      },
      tradeAreaTwo: {
        count: 0
      }
    };
    
    $scope.mapOptions = BingMapUtils.getDefaultViewOptions();
    $scope.mapOptions.options.disableScrollWheelZoom = true;
    $scope.mapOptions.events = {
      viewchangeend: viewChange
    };
    
    $scope.$on("$destroy", function () {
      isDestroyed = true;
    });

    function onMapReady(map) {
      bingMap = map;
      BingGeoDataService.setupSdsManager();
    }

    function getSirOrRedirect() {
      SiteInformationReportService
        .getReport($state.params.id)
        .then(function (sirReport) {
          $scope.sirReport = sirReport;

          if (!$scope.sirReport.existingInventory) { $scope.sirReport.existingInventory = []; }
          if (!$scope.sirReport.constructionInventory) { $scope.sirReport.constructionInventory = []; }
          if (!$scope.sirReport.transactions) { $scope.sirReport.transactions = []; }
          if (!$scope.sirReport.hospitals) { $scope.sirReport.hospitals = []; }
          if (!$scope.sirReport.demographics) { $scope.sirReport.demographics = []; }

          init();
          SpinnerService.stop();
        }, function (errorInfo) {
          $state.go('siteInformationReportWarning');
        });
    }

    getSirOrRedirect();

    function viewChange() {
      if (isDestroyed) {
        return;
      }
      if (isInit) {
        isInit = false;
      }

      $scope.toggleCounties();
    }

    function init() {
      SpinnerService.start();
      $q.all([
        TrendsService.getCurrentQuarter(),
        PropertyDetailsService.getDemographicYear(),
        SiteInformationReportService.getDemographics($state.params.id),
        DataAttributionService.getAttributionHtml()
      ]).then(function success(responses) {
        $scope.currentQuarter = responses[0];
        $scope.demographicYear = responses[1];
        if (responses[2]) {
          $scope.demographicsData = responses[2].data;
          $scope.isDemographicsAPIDown = !responses[2].apiSuccess;
        }
        $scope.AttributionHtml = $sce.trustAsHtml(responses[3]);
        initTradeArea();
        setUpCounts();
        initDemographicsGrid();
        initExistingInventoryGrid();
        initConstructionInventoryGrid();
        initTransactionGrid();
        initHospitalGrid();
        SpinnerService.stop();
      }, function (error) {
        // error
        SpinnerService.stop();
        PopupHandlerService.openSimplePopup("There was an error when trying to load the page. Please try again or contact us.");
      });
    }
    
    function initTradeArea() {
      $scope.cbsaArea = BingMapUtils.getPolygonOptions('cbsaArea');
      $scope.sirReport.options = PinFactory.buildBlackPinOptions();
      var locsToCenterTo = [];

      if ($scope.sirReport.driveTimeFeature && $scope.sirReport.driveTimeFeature.features && $scope.sirReport.driveTimeFeature.features.length) {
        // driveTime
        $scope.tradeAreaDriveTimeEsriPolygons = GeoDataService
          .convertFeatureCollectionToPolygon($scope.sirReport.driveTimeFeature.features, {
            drive1: { trafficFlow: $scope.sirReport.tradeAreaData.shape1.driveTime.trafficFlow },
            drive2: { trafficFlow: $scope.sirReport.tradeAreaData.shape2.driveTime.trafficFlow }
          });
        if (_.has($scope.tradeAreaDriveTimeEsriPolygons, '[0].locations.length')) {
          $scope.sirReport.tradeAreaData.shape1.driveTime.driveTimeLocs = $scope.tradeAreaDriveTimeEsriPolygons[0].locations;
          var driveTime1Color = GeoDataService.getColors($scope.sirReport.tradeAreaData.shape1.driveTime.trafficFlow);
          $scope.sirReport.tradeAreaData.shape1.strokeColor = driveTime1Color.argbStrokeColor;
          $scope.sirReport.tradeAreaData.shape1.fillColor = driveTime1Color.argbFillColor;
          locsToCenterTo = $scope.tradeAreaDriveTimeEsriPolygons[0].locations;
          if (_.has($scope.tradeAreaDriveTimeEsriPolygons, '[1].locations.length')) {
            $scope.sirReport.tradeAreaData.shape2.driveTime.driveTimeLocs = $scope.tradeAreaDriveTimeEsriPolygons[1].locations;
            var driveTime2Color = GeoDataService.getColors($scope.sirReport.tradeAreaData.shape2.driveTime.trafficFlow);
            $scope.sirReport.tradeAreaData.shape2.strokeColor = driveTime2Color.argbStrokeColor;
            $scope.sirReport.tradeAreaData.shape2.fillColor = driveTime2Color.argbFillColor;
            locsToCenterTo = _.concat(locsToCenterTo, $scope.tradeAreaDriveTimeEsriPolygons[1].locations);
          }
        }
      } else {
        if ($scope.sirReport.tradeAreaData.shape1.hasShape) {
          if ($scope.sirReport.tradeAreaData.shape1.radius) {
            $scope.tradeAreaOneRadius = BingMapUtils
              .getPolygonOptions('tradeAreaOneRadius', BingMapUtils.buildCircle($scope.sirReport.latitude, $scope.sirReport.longitude, $scope.sirReport.tradeAreaData.shape1.radius));
            locsToCenterTo = $scope.tradeAreaOneRadius.locations;
          }
        }

        if ($scope.sirReport.tradeAreaData.shape2.hasShape) {
          if ($scope.sirReport.tradeAreaData.shape2.radius) {
            $scope.tradeAreaTwoRadius = BingMapUtils
              .getPolygonOptions('tradeAreaTwoRadius', BingMapUtils.buildCircle($scope.sirReport.latitude, $scope.sirReport.longitude, $scope.sirReport.tradeAreaData.shape2.radius));
            locsToCenterTo = $scope.tradeAreaTwoRadius.locations;
          }
        }
      }

      if (locsToCenterTo.length) {
        isInit = true;
        BingMapUtils.centerMapToLocations(bingMap, locsToCenterTo);
      }
    }
      
    function setUpCounts() {
      var sirTradeArea = $scope.sirReport.tradeAreaData;
      var hasTradeAreOne = sirTradeArea.shape1.hasShape;
      var tradeAreaOnePolygons = [];
      if (hasTradeAreOne) {
        if ($scope.tradeAreaDriveTimeEsriPolygons && $scope.tradeAreaDriveTimeEsriPolygons.length) {
          tradeAreaOnePolygons = $scope.tradeAreaDriveTimeEsriPolygons[0].locations;
        } else {
          tradeAreaOnePolygons = $scope.tradeAreaOneRadius.locations;
        }
      }
      var hasTradeAreTwo = sirTradeArea.shape2.hasShape;
      var tradeAreaTwoPolygons = [];
      if (hasTradeAreTwo) {
        if ($scope.tradeAreaDriveTimeEsriPolygons && $scope.tradeAreaDriveTimeEsriPolygons.length > 1) {
          tradeAreaTwoPolygons = $scope.tradeAreaDriveTimeEsriPolygons[1].locations;
        } else {
          tradeAreaTwoPolygons = $scope.tradeAreaTwoRadius.locations;
        }
      }

      /*Existing Inventory*/
      var existingInvData = pinGeneratorUtil.generatePropertyData($scope.sirReport.existingInventory, reportSectionTypeConstant.existingInventory, tradeAreaOnePolygons, tradeAreaTwoPolygons,$scope, $scope.showExistingInventory);
      $scope.existingInventory.properties = existingInvData.mapPins;
      $scope.sirReport.existingInventory = existingInvData.properties;
      $scope.existingInventory.tradeAreaOne.count = existingInvData.tradeAreaOne.count;
      $scope.existingInventory.tradeAreaTwo.count = existingInvData.tradeAreaTwo.count;
      $scope.existingInventory.spaces.IL = existingInvData.spaces.IL;
      $scope.existingInventory.spaces.AL = existingInvData.spaces.AL;
      $scope.existingInventory.spaces.MC = existingInvData.spaces.MC;
      $scope.existingInventory.spaces.NC = existingInvData.spaces.NC;

      /*Construction Activity*/
      var constructionData = pinGeneratorUtil.generatePropertyData($scope.sirReport.constructionInventory, reportSectionTypeConstant.construction, tradeAreaOnePolygons, tradeAreaTwoPolygons,$scope, $scope.showConstructionInventory);
      $scope.constructionInventory.properties = constructionData.mapPins;
      $scope.sirReport.constructionInventory = constructionData.properties;
      $scope.constructionInventory.tradeAreaOne = constructionData.tradeAreaOne;
      $scope.constructionInventory.tradeAreaTwo = constructionData.tradeAreaTwo;
      $scope.constructionInventory.spaces.IL = constructionData.spaces.IL;
      $scope.constructionInventory.spaces.AL = constructionData.spaces.AL;
      $scope.constructionInventory.spaces.MC = constructionData.spaces.MC;
      $scope.constructionInventory.spaces.NC = constructionData.spaces.NC;

      /*Transaction*/
      var transactionData = pinGeneratorUtil.getTransactionPins($scope.sirReport.transactions, tradeAreaOnePolygons, tradeAreaTwoPolygons,$scope, $scope.showTransactionActivity);
      $scope.transactionActivity.properties = transactionData.mapPins;
      $scope.sirReport.transactions = transactionData.properties;
      $scope.transactionActivity.tradeAreaOne.count = transactionData.tradeAreaOne.count;
      $scope.transactionActivity.tradeAreaTwo.count = transactionData.tradeAreaTwo.count;

      /*Hospitals*/
      var hospitalData = pinGeneratorUtil.generatePropertyData($scope.sirReport.hospitals, reportSectionTypeConstant.hospital, tradeAreaOnePolygons, tradeAreaTwoPolygons,$scope, $scope.showHospitals);
      $scope.hospitals.properties = hospitalData.mapPins;
      $scope.sirReport.hospitals = hospitalData.properties;
      $scope.hospitals.tradeAreaOne.count = hospitalData.tradeAreaOne.count;
      $scope.hospitals.tradeAreaTwo.count = hospitalData.tradeAreaTwo.count;    
    }

    $scope.popoverTotalSpacesContent = function (spaces) {
      var content = (spaces.IL != "0" ? '<b>IL:</b> ' + spaces.ILFormatted() : '')
        + (spaces.AL != "0" ? ' <b>AL:</b> ' + spaces.ALFormatted() : '')
        + (spaces.MC != "0" ? ' <b>MC:</b> ' + spaces.MCFormatted() : '')
        + (spaces.NC != "0" ? ' <b>NC:</b> ' + spaces.NCFormatted() : '');
      return spaces.trusted[content] || (spaces.trusted[content] = $sce.trustAsHtml(content));
    }

    $scope.popoverConstructionRingContent = function (ring) {
     var content = (ring.expansionCount != "0" ? '<b>Expansion:</b> ' + ring.expansionCount : '')
         + (ring.newCount != "0" ? ' <b>New Construction:</b> ' + ring.newCount : '')

       return ring.trusted[content] || (ring.trusted[content] = $sce.trustAsHtml(content));
    }

    $scope.generateTransactionTooltipText = function (dataItem) {
      var content = dataItem.description ? '<b>' + dataItem.description + '</b>' : '';
      return dataItem.tooltip || (dataItem.tooltip = $sce.trustAsHtml(content));
    };

    function customCohortClick() {
      $state.go('customSiteCohortDemographics', { id: $state.params.id });
    }

    // configure grids
    function initDemographicsGrid() {
      if (!$scope.demographicsData) return;
      $scope.showDemographicsData = true;
      var dynamicColumns = [{
        title: "County",
        columns: [{
          field: "county.age45to64",
          title: "45-64 yrs.",
          headerTemplate: "45-64<br>yrs.",
          attributes: {
            'class': 'text-right customKendo demographics'
          },
          template: '{{gridOpts_Demographics.changeRowTemplate(dataItem.index, 0, dataItem.county.age45to64)}}'
        }, {
          field: "county.age65to74",
          title: "65-74 yrs.",
          headerTemplate: "65-74<br>yrs.",
          attributes: {
            'class': 'text-right customKendo demographics'
          },
          template: '{{gridOpts_Demographics.changeRowTemplate(dataItem.index, 1, dataItem.county.age65to74)}}'
        }, {
          field: "county.age75plus",
          title: "75+ yrs.",
          headerTemplate: "75+<br>yrs.",
          attributes: {
            'class': 'text-right customKendo demographics'
          },
          template: '{{gridOpts_Demographics.changeRowTemplate(dataItem.index, 2, dataItem.county.age75plus)}}'
        }]
      }, {
        title: "Metro",
        columns: [{
          field: "metro.age45to64",
          title: "45-64 yrs.",
          headerTemplate: "45-64<br>yrs.",
          attributes: {
            'class': 'text-right customKendo demographics'
          },
          template: '{{gridOpts_Demographics.changeRowTemplate(dataItem.index, 0, dataItem.metro.age45to64)}}'
        }, {
          field: "metro.age65to74",
          title: "65-74 yrs.",
          headerTemplate: "65-74<br>yrs.",
          attributes: {
            'class': 'text-right customKendo demographics'
          },
          template: '{{gridOpts_Demographics.changeRowTemplate(dataItem.index, 1, dataItem.metro.age65to74)}}'
        }, {
          field: "metro.age75plus",
          title: "75+ yrs.",
          headerTemplate: "75+<br>yrs.",
          attributes: {
            'class': 'text-right customKendo demographics'
          },
          template: '{{gridOpts_Demographics.changeRowTemplate(dataItem.index, 2, dataItem.metro.age75plus)}}'
        }]
      }, {
        title: "National",
        columns: [{
          field: "national.age45to64",
          title: "45-64 yrs.",
          headerTemplate: "45-64<br>yrs.",
          attributes: {
            'class': 'text-right customKendo demographics'
          },
          template: '{{gridOpts_Demographics.changeRowTemplate(dataItem.index, 0, dataItem.national.age45to64)}}'
        }, {
          field: "national.age65to74",
          title: "65-74 yrs.",
          headerTemplate: "65-74<br>yrs.",
          attributes: {
            'class': 'text-right customKendo demographics'
          },
          template: '{{gridOpts_Demographics.changeRowTemplate(dataItem.index, 1, dataItem.national.age65to74)}}'
        }, {
          field: "national.age75plus",
          title: "75+ yrs.",
          headerTemplate: "75+<br>yrs.",
          attributes: {
            'class': 'text-right customKendo demographics'
          },
          template: '{{gridOpts_Demographics.changeRowTemplate(dataItem.index, 2, dataItem.national.age75plus)}}'
        }]
      }];

      if ($scope.sirReport.tradeAreaData.shape2.hasShape) {
        dynamicColumns.unshift({
          title: $scope.sirReport.tradeAreaData.shape2.name,
          columns: [{
            field: "tradeAreaTwo.age45to64",
            title: "45-64 yrs.",
            headerTemplate: "45-64<br>yrs.",
            attributes: {
              'class': 'text-right customKendo demographics'
            },
            template: '{{gridOpts_Demographics.changeRowTemplate(dataItem.index, 0, dataItem.tradeAreaTwo.age45to64)}}'
          }, {
            field: "tradeAreaTwo.age65to74",
            title: "65-74 yrs.",
            headerTemplate: "65-74<br>yrs.",
            attributes: {
              'class': 'text-right customKendo demographics'
            },
            template: '{{gridOpts_Demographics.changeRowTemplate(dataItem.index, 1, dataItem.tradeAreaTwo.age65to74)}}'
          }, {
            field: "tradeAreaTwo.age75plus",
            title: "75+ yrs.",
            headerTemplate: "75+<br>yrs.",
            attributes: {
              'class': 'text-right customKendo demographics'
            },
            template: '{{gridOpts_Demographics.changeRowTemplate(dataItem.index, 2, dataItem.tradeAreaTwo.age75plus)}}'
          }]
        });
      }

      if ($scope.sirReport.tradeAreaData.shape1.hasShape) {
        dynamicColumns.unshift({
          title:  $scope.sirReport.tradeAreaData.shape1.name,
          columns: [{
            field: "tradeAreaOne.age45to64",
            title: "45-64 yrs.",
            headerTemplate: "45-64<br>yrs.",
            attributes: {
              'class': 'text-right customKendo demographics'
            },
            template: '{{gridOpts_Demographics.changeRowTemplate(dataItem.index, 0, dataItem.tradeAreaOne.age45to64)}}'
          }, {
            field: "tradeAreaOne.age65to74",
            title: "65-74 yrs.",
            headerTemplate: "65-74<br>yrs.",
            attributes: {
              'class': 'text-right customKendo demographics'
            },
            template: '{{gridOpts_Demographics.changeRowTemplate(dataItem.index, 1, dataItem.tradeAreaOne.age65to74)}}'
          }, {
            field: "tradeAreaOne.age75plus",
            title: "75+ yrs.",
            headerTemplate: "75+<br>yrs.",
            attributes: {
              'class': 'text-right customKendo demographics'
            },
            template: '{{gridOpts_Demographics.changeRowTemplate(dataItem.index, 2, dataItem.tradeAreaOne.age75plus)}}'
          }]
        });
      }
         
      dynamicColumns.unshift({ 
        field: "name", 
        title: " ",
        attributes: {
          'class': 'space-nowrap customKendo demographics'
        }
      });

      $scope.gridOpts_Demographics = {
        columns: dynamicColumns, 
        dataSource: new kendo.data.DataSource({
          data: $scope.demographicsData,
          error: function (err) {
            console.log('error - ' + err);
          },
          serverPaging: false,
          serverFiltering: false,
          serverSorting: false
        }),
        scrollable: false,
        sortable: false,
        pageable: false,
        changeRowTemplate: function (rowIndex, colIndex, value) {
          switch (rowIndex) {
            case 0:
              return kendo.format("{0:n0}", value);
            case 1:
              return kendo.format("{0:p}", value);
            case 2:
              return kendo.format("{0:p}", value);
            case 3:
              return kendo.format("{0:c0}", value);
            case 4:
              if (colIndex == 1) {
                return kendo.format("{0:c0}", value);
              }
          };
        }
      };
    };

    function initExistingInventoryGrid() {
      $scope.gridOpts_ExistingInventory = {
        columns: [
        {
          field: "id",
          title: "propertyID",
          hidden: "hidden"
        }, {
          field: "pinNumber",
          title: "Map Pin No.",
          headerTemplate: "<span>Map <br/> Pin No.",
          width: "3%",
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          }
        },
        {
          field: "name",
          title: "Property Name",
          template: '<a ng-show="#= id #" ng-click="propertyDetailsClick($event, this.dataItem)">#= name #</a><span ng-hide="#= id #">#= name #</span>',
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "addressLine1",
          title: "Address",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "city",
          title: "City",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "stateProvinceCode",
          title: "State",
          width: "3%",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "zipCode",
          title: "ZIP",
          width: "3%",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "operator",
          title: "Operator",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          },
          template: "#= (operator && operator != 'Unknown Stakeholder') ? kendo.toString(operator) : 'N/A' #"

        }, {
          field: "constructionStatus",
          title: "Property Status",
          headerTemplate: "<span>Property<br/>Status</span>",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "units",
          title: "Total Open Spaces",
          filterable: {
            cell: {
              enabled: false
            }
          },
          template: '<div class="text-nowrap">IL Units : #:units.ilUnits#<br/>AL Units : #:units.alUnits#<br/>MC Units : #:units.mcUnits#<br/>NC Beds : #:units.ncUnits#</div>'

        }, {
          field: "distance",
          title: "Distance",
          attributes: {
            'class': 'text-right'
          },
          format: '{0:n1}',
          width: "3%",
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          }
        }],
        dataSource: new kendo.data.DataSource({
          data: $scope.existingInventory.properties,
          pageSize: pageSize,
          error: function (err) {
            console.log('error - ' + err);
          },
          schema: {
            model: {
              fields: {
                'pinNumber': { type: 'number' },
                'id': { type: 'number' },
                'name': { type: 'string' },
                'distance': { type: 'number' },
                'addressLine1': { type: 'string' },
                'city': { type: 'string' },
                'stateProvinceCode': { type: 'string' },
                'zipCode': { type: 'string' },
                'constructionStatus': { type: 'string' }
              }
            }
          },
          sort: {
            field: "pinNumber",
            dir: "asc"
          },
          serverPaging: false,
          serverFiltering: false,
          serverSorting: false
        }),
        dataBinding: function (e) {
          Nic.kendoGrid.filtersOnTop('gridExistingInventory');
        },
        filterable: {
          mode: "row"
        },
        scrollable: false,
        sortable: {
          mode: "single",
          allowUnsort: false
        },
        pageable: $scope.existingInventory.properties && $scope.existingInventory.properties.length > pageSize ? true : false,
        resetFilters: function () {
          this.dataSource.filter([]);
          this.dataSource.sort({ field: "pinNumber", dir: "asc" });
        }
      };
    };

    function initConstructionInventoryGrid() {
      $scope.gridOpts_ConstructionInventory = {
        columns: [
        {
          field: "id",
          title: "propertyID",
          hidden: "hidden"
        }, {
          field: "pinNumber",
          title: "Map Pin No.",
          headerTemplate: "<span>Map <br/> Pin No.",
          width: "3%",
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          }
        },
        {
          field: "name",
          title: "Property Name",
          template: '<a ng-show="#= id #" ng-click="propertyDetailsClick($event, this.dataItem)">#= name #</a><span ng-hide="#= id #">#= name #</span>',
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "constructionOpenDate",
          title: "U/C Scheduled Open Date",
          headerTemplate: "<span>U/C Scheduled <br/> Open Date</span>",
          format: "{0:MM/dd/yyyy}",
          filterable: {
            cell: {
              showOperators: false,
              operator: "gte"
            }
          } 
        },{
          field: "addressLine1",
          title: "Address",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "city",
          title: "City",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "stateProvinceCode",
          title: "State",
          width: "3%",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "zipCode",
          title: "ZIP",
          width: "3%",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "operator",
          title: "Operator",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          },
          template: "#= (operator && operator != 'Unknown Stakeholder') ? kendo.toString(operator) : 'N/A' #"
        }, {
          field: "constructionStatus",
          title: "Construction Type",
          headerTemplate: "<span>Construction <br/> Type</span>",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "units",
          title: "Inventory Under Construction",
          headerTemplate: "<span>Inventory <br/> Under Construction</span>",
          filterable: {
            cell: {
              enabled: false
            }
          },
          template: '<div class="text-nowrap">IL Units : #:units.ilConstructionUnits#<br/>AL Units : #:units.alConstructionUnits# <br/>MC Units : #:units.mcConstructionUnits# <br/>NC Beds : #:units.ncConstructionUnits# </div>'
        }, {
          field: "distance",
          title: "Distance",
          attributes: {
            'class': 'text-right'
          },
          format: '{0:n1}',
          width: "3%",
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          }
        }],
        dataSource: new kendo.data.DataSource({
          data: $scope.constructionInventory.properties,
          pageSize: pageSize,
          error: function (err) {
            console.log('error - ' + err);
          },
          schema: {
            model: {
              fields: {
                'pinNumber': { type: 'number' },
                'id': { type: 'number' },
                'name': { type: 'string' },
                'distance': { type: 'number' },
                'addressLine1': { type: 'string' },
                'city': { type: 'string' },
                'stateProvinceCode': { type: 'string' },
                'zipCode': { type: 'string' },
                'constructionStatus': { type: 'string' },
                'constructionOpenDate': { type: 'date' }
              }
            }
          },
          sort: {
            field: "pinNumber",
            dir: "asc"
          },
          serverPaging: false,
          serverFiltering: false,
          serverSorting: false
        }),
        dataBinding: function (e) {
          Nic.kendoGrid.filtersOnTop('gridConstructionInventory');
        },
        filterable: {
          mode: "row"
        },
        scrollable: false,
        sortable: {
          mode: "single",
          allowUnsort: false
        },
        pageable: $scope.constructionInventory && $scope.constructionInventory.properties.length > pageSize ? true : false,
        resetFilters: function () {
          this.dataSource.filter([]);
          this.dataSource.sort({ field: "pinNumber", dir: "asc" });
        }
      };
    };

    function initTransactionGrid() {
      $scope.gridOpts_Transaction = {
        columns: [
        {
          field: "pinNumber",
          title: "Map Pin No.",
          headerTemplate: "<span>Map <br/> Pin No.",
          width: "3%",
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          }
        },{
          field: "propertyId",
          title: "propertyID",
          hidden: "hidden"
        },
        {
          field: "saleDate",
          title: "Sale Date",
          format: "{0:MM/dd/yyyy}",
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          }
        },
        {
          field: "propertyName",
          title: "Property Name",
          template: '<a ng-show="#= propertyId #" ng-click="propertyDetailsClick($event, this.dataItem)">#= propertyName #</a><span ng-hide="#= propertyId #">#= propertyName #</span>',
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        },
        {
          field: "address",
          title: "Address",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          },
          template: '{{gridOpts_Transaction.formatAddress(dataItem)}}'
        },
        {
          field: "propertyAndCampus",
          headerTemplate: '<span>Property Type / <br> Campus Type</span>',
          template: '<span> #:propertyType# <br> #:campusType# </span>',
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "buyerCompanyName",
          title: "Players",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          },
          template: "<span popover-trigger='mouseenter' uib-popover-html='generateTransactionTooltipText(dataItem.buyerCapitalInvestmentType)' >Buyer: #= buyerCompanyName || '' #</span></br><span popover-trigger='mouseenter' uib-popover-html='generateTransactionTooltipText(dataItem.sellerCapitalInvestmentType)'>Seller: #= sellerCompanyName ? sellerCompanyName : '' #</span>"
        }, {
          field: "closePrice",
          title: "Close Price",
          format: "{0:c0}",
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          }
        }, {
          field: "pricePerUnit",
          title: "Price Per Unit",
          format: "{0:c0}",
          attributes: {
            'class': 'text-right'
          },
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          },
        }, {
          field: "capRateForFilter",
          title: "Cap Rate",
          attributes: {
            'class': 'text-right'
          },
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          }
        }, {
          field: "distancefromCenter",
          title: "Distance",
          format: '{0:n1}',
          attributes: {
            'class': 'text-right'
          },
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          }
        }
        ],
        dataSource: new kendo.data.DataSource({
          data: $scope.sirReport.transactions,
          pageSize: pageSize,
          error: function (err) {
            console.log('error - ' + err);
          },
          schema: {
            model: {
              fields: {
                'saleDate': { type: 'date' },
                'closePrice': { type: 'number' },
                'pricePerUnit': { type: 'number' },
                'distancefromCenter': { type: 'number' }
              }
            },
            parse: function (data) {
              _.each(data, function (item) {
                item.buyerCapitalInvestmentType = { description: item.buyerCapitalInvestmentType || '' };
                item.sellerCapitalInvestmentType = { description: item.sellerCapitalInvestmentType || '' };
                item.capRateForFilter = item.closeCapRate ? kendo.toString(item.closeCapRate, 'p2') : 'N/A';
                item.propertyAndCampus = item.propertyType + ' ' + item.campusType;
              });
              return data;
            }
          },
          sort: {
            field: "saleDate",
            dir: "desc"
          },
          serverPaging: false,
          serverFiltering: false,
          serverSorting: false
        }),
        dataBinding: function (e) {
          Nic.kendoGrid.filtersOnTop('gridTransaction');
        },
        filterable: {
          mode: "row"
        },
        scrollable: false,
        sortable: {
          mode: "single",
          allowUnsort: false
        },
        pageable: $scope.sirReport.transactions && $scope.sirReport.transactions.length > pageSize ? true : false,
        resetFilters: function () {
          this.dataSource.filter([]);
          this.dataSource.sort({ field: "saleDate", dir: "desc" });
        },
        formatAddress: function (row) {
          var strToReturn = '';
          if (row.address)
            strToReturn += row.address + ' ';
          if (row.city)
            strToReturn += row.city + ' ';
          if (row.state)
            strToReturn += row.state + ' ';
          if (row.zip)
            strToReturn += row.zip + ' ';
          return strToReturn.trim();
        }
      };
    };

    function initHospitalGrid() {
      $scope.gridOpts_Hospital = {
        columns: [
        {
          field: "pinNumber",
          title: "Map Pin No.",
          headerTemplate: "<span>Map <br/> Pin No.",
          width: "3%",
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          }
        },
        {
          field: "name",
          title: "Hospital Name",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        },
        {
          field: "type",
          title: "Hospital Type",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        },
        {
          field: "ownership",
          title: "Ownership Type",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "address",
          title: "Address",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "city",
          title: "City",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "state",
          title: "State",
          width: "3%",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "zipCode",
          title: "ZIP",
          width: "3%",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "emergencyServices",
          title: "Emergency Services",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "distance",
          title: "Distance",
          format: '{0:n1}',
          attributes: {
              'class': 'text-right'
          },
          width: "3%",
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          }
        }],
        dataSource: new kendo.data.DataSource({
          data: $scope.sirReport.hospitals,
          pageSize: pageSize,
          error: function (err) {
            console.log('error - ' + err);
          },
          schema: {
            model: {
              fields: {
                'pinNumber': { type: 'number' },
                'name': { type: 'string' },
                'type': { type: 'string' },
                'ownership': { type: 'string' },
                'address': { type: 'string' },
                'city': { type: 'string' },
                'state': { type: 'string' },
                'zipCode': { type: 'string' },
                'emergencyServices': { type: 'string' },
                'distance': { type: 'number' }
              }
            }
          },
          sort: {
            field: "pinNumber",
            dir: "asc"
          },
          serverPaging: false,
          serverFiltering: false,
          serverSorting: false
        }),
        dataBinding: function (e) {
          Nic.kendoGrid.filtersOnTop('gridHospital');
        },
        filterable: {
          mode: "row"
        },
        scrollable: false,
        sortable: {
          mode: "single",
          allowUnsort: false
        },
        pageable: $scope.sirReport.hospitals && $scope.sirReport.hospitals.length > pageSize ? true : false,
        resetFilters: function () {
          this.dataSource.filter([]);
          this.dataSource.sort({ field: "pinNumber", dir: "asc" });
        }
      };
    };

    function getGridCurrentFilterAndSort(sourceGrid) {
      var filters = sourceGrid.dataSource.filter();
      filters = (filters === undefined) ? [] : filters.filters;

      var gridData = {
        filters: filters,
        sorts: sourceGrid.dataSource.sort() || []
      };
      return gridData;
    };

    $scope.exportToExcel = function () {
      var demoData = null;
      if ($scope.showDemographicsData !== null) {
        if ($scope.demographicsData !== null) { //use cookie filled demo data
          demoData = $scope.demographicsData;
        } else if ($scope.sirReport.demographics.length > 0) { //otherwise check sirReport for data
          demoData = $scope.sirReport.demographics;
        }
      };

      var sirReportVm = angular.copy($scope.sirReport);
      delete sirReportVm.driveTimeFeature;
      delete sirReportVm.options;
      sirReportVm.transactions = _.map(sirReportVm.transactions, function (transaction) {
        return { 
          id: transaction.id,
          cbsaCode: transaction.cbsaCode,
          pinNumber: transaction.pinNumber
        };
      });
      sirReportVm.demographics = _.map(demoData, function(data){
        return {
          index: data.index,
          name: data.name,
          county:{age45to64:data.county.age45to64,age65to74:data.county.age65to74,age75plus:data.county.age75plus},
          metro: {age45to64:data.metro.age45to64,age65to74:data.metro.age65to74,age75plus:data.metro.age75plus},
          national: {age45to64:data.national.age45to64,age65to74:data.national.age65to74,age75plus:data.national.age75plus},
          tradeareaone: {age45to64:data.tradeAreaOne.age45to64,age65to74:data.tradeAreaOne.age65to74,age75plus:data.tradeAreaOne.age75plus},
          tradeareatwo: {age45to64:data.tradeAreaTwo.age45to64,age65to74:data.tradeAreaTwo.age65to74,age75plus:data.tradeAreaTwo.age75plus}
        };
      });

      var title = 'Site Information ' + $scope.sirReport.areaAccessed + ' ' + $scope.sirReport.dataAsOf.replace('/', '');
      var logMessage = 'Site Information Report: ' + $scope.sirReport.areaAccessed + ' Excel';
     
      SpinnerService.start();
      AuditService.logEvent(knownEventConstant.siteInformationReportExcel.id, logMessage,
        knownProductConstant.local, null, $scope.sirReport.cbsaCode);
      SiteInformationReportService
        .getExcel(sirReportVm)
        .then(function success(data) {
          var excelFile = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          });
          var fileName = 'NICMAP ' + title.replace(/ /g, '_') + '.xlsx';
          FileSaver.saveAs(excelFile, fileName);
        }, function (err) {
          console.error(err);
        })
        .finally(function () {
          SpinnerService.stop();
        });
    };

    $scope.printToPDF = function () {
      var title = 'Site Information ' + $scope.sirReport.areaAccessed + ' ' + $scope.sirReport.dataAsOf.replace('/', '');
      var demoData = null;
      if ($scope.showDemographicsData !== null) {
        if ($scope.demographicsData !== null) { //use cookie filled demo data
          demoData = $scope.demographicsData;
        } else if ($scope.sirReport.demographics.length > 0) { //otherwise check sirReport for data
          demoData = $scope.sirReport.demographics;
        }
      };
      var sirPdfVm = angular.copy($scope.sirReport);
      delete sirPdfVm.driveTimeFeature;
      delete sirPdfVm.options;
      sirPdfVm.transactions = _.map(sirPdfVm.transactions, function (transaction) {
        return { 
          saleDate: transaction.saleDate, 
          propertyName: transaction.propertyName,
          address: transaction.address,
          city: transaction.city,
          state: transaction.state,
          zip: transaction.zip,
          buyerCompanyName: transaction.buyerCompanyName,
          sellerCompanyName: transaction.sellerCompanyName,
          propertyType: transaction.propertyType,
          closePrice: transaction.closePrice,
          pricePerUnit: transaction.pricePerUnit,
          closeCapRate: transaction.closeCapRate,
          distancefromCenter: transaction.distancefromCenter,
          latitude: transaction.latitude,
          longitude: transaction.longitude,
          id: transaction.id,
          propertyId: transaction.propertyId,
          pinNumber: transaction.pinNumber,
          campusType: transaction.campusType,
          constructionStatus: transaction.constructionStatus
        };
      });
      sirPdfVm.demographics = _.map(demoData, function(data){
        return {
          index: data.index,
          name: data.name,
          county:{age45to64:data.county.age45to64,age65to74:data.county.age65to74,age75plus:data.county.age75plus},
          metro: {age45to64:data.metro.age45to64,age65to74:data.metro.age65to74,age75plus:data.metro.age75plus},
          national: {age45to64:data.national.age45to64,age65to74:data.national.age65to74,age75plus:data.national.age75plus},
          tradeAreaOne: {age45to64:data.tradeAreaOne.age45to64,age65to74:data.tradeAreaOne.age65to74,age75plus:data.tradeAreaOne.age75plus},
          tradeAreaTwo: {age45to64:data.tradeAreaTwo.age45to64,age65to74:data.tradeAreaTwo.age65to74,age75plus:data.tradeAreaTwo.age75plus}
        };
      });
      sirPdfVm.tradeAreaTotals = 
      {
        existingInventoryOne: $scope.existingInventory.tradeAreaOne.count, 
        existingInventoryTwo: $scope.existingInventory.tradeAreaTwo.count, 
        existingInventoryTotal: $scope.existingInventory.spaces.totalSpaces(),
        constructionInventoryOne: $scope.constructionInventory.tradeAreaOne.count, 
        constructionInventoryTwo: $scope.constructionInventory.tradeAreaTwo.count, 
        constructionInventoryTotal: $scope.constructionInventory.spaces.totalSpaces(), 
        hospitalsOne: $scope.hospitals.tradeAreaOne.count, 
        hospitalsTwo: $scope.hospitals.tradeAreaTwo.count,
        transactionActivityOne: $scope.transactionActivity.tradeAreaOne.count,
        transactionActivityTwo: $scope.transactionActivity.tradeAreaTwo.count
      };
      SpinnerService.start();
      SiteInformationReportService
        .getPdf($scope.sirReport.id, title, sirPdfVm)
        .then(function (data) {
          var pdfFile = new Blob([data], {
            type: 'application/pdf'
          });
          var fileName = 'NICMAP_' + title.replace(/ /g, '_') + '.pdf';
          SpinnerService.stop();
          FileSaver.saveAs(pdfFile, fileName);
        }, function (err) {
          SpinnerService.stop();
        })
        .finally(function () {
          SpinnerService.stop();
        });
    };

    $scope.togglePushPins = function (pins, newValue) {
      $.each(pins, function (index, value) {
        value.visible = newValue;
      });
    };

    $scope.toggleMetroArea = function () {
      if ($scope.showMetroArea) {
        if ($scope.cbsaAreaLocationCache) {
          $scope.cbsaArea.sections = angular.copy($scope.cbsaAreaLocationCache);
        }
        else {
          GeoDataService
            .getCBSAGeoData($scope.sirReport.cbsaCode)
            .then(function (data) {
              if (data && data.length > 0) {
                $scope.cbsaArea.sections = data;
                $scope.cbsaAreaLocationCache = angular.copy(data);
              }
            }, function (err) {
              // Do Nothing - No Shape to Render.
            })
            .finally(function () {
              // nothing to do
            });
        }
        AuditService.logEvent(knownEventConstant.sirShowMetroArea.id, knownEventConstant.sirShowMetroArea.message, 
          knownProductConstant.local);
      }
      else {
        $scope.cbsaArea.sections = [];
      }
    };

    $scope.toggleCounties = function () {
      if ($scope.showCounties && BingMapUtils.getCurrentZoom(bingMap) >= 9) {
        SpinnerService.start();
        BingGeoDataService
          .getAllCountiesBoundariesInBbox(bingMap, true)
          .then(function (bingPolygons) {
              $scope.coveredCountiesPolygons = bingPolygons;
              SpinnerService.stop();
          }, function (err) {
            console.log(err);
            SpinnerService.stop();
          });
        AuditService.logEvent(knownEventConstant.sirShowCounties.id, knownEventConstant.sirShowCounties.message, 
          knownProductConstant.local);
      }
      else
        $scope.coveredCountiesPolygons = [];
    };

    function propertyDetailsClick(ev, dataItem) {
      var propId = dataItem.propertyId || dataItem.id; // transaction vs (Nearby Props or Construction) pin
      var propName = dataItem.propertyName || dataItem.name;
      var prop = {
        id: propId,
        name: propName,
        latitude: dataItem.latitude,
        longitude: dataItem.longitude
      };
      var sirTradeArea = $scope.sirReport.tradeAreaData;
      var windowOpen = $window.open('', '_blank');
      var propertyDetailsHrefLocation = $state.href('propertyDetails', { id: 'pirGuid' }, { inherit: false });
      PropertyDetailsService.getPropertyDetailsIdBasedOnVintage(prop, sirTradeArea.vintageId)
        .then(function (rsp) {
          if (!rsp.wasPirGuidCreated)
            AuditService.logEvent(knownEventConstant.propertyInformationReport.id, knownEventConstant.propertyInformationReport.message + " - " + prop.name,
              knownProductConstant.local, prop.id);
          windowOpen.location.href = propertyDetailsHrefLocation.replace('pirGuid', rsp.id);
        });
    }

    function onClickShowHospitals(pins, showHospitals) {
      $scope.togglePushPins(pins, showHospitals);
      if (showHospitals) {
        AuditService.logEvent(knownEventConstant.sirShowHospitals.id, knownEventConstant.sirShowHospitals.message, 
          knownProductConstant.local);
      }
    }

    function onClickShowExistingInventory(pins, showExistingInventory) {
      $scope.togglePushPins(pins, showExistingInventory);
      if (showExistingInventory) {
        AuditService.logEvent(knownEventConstant.sirShowExistingInventory.id, knownEventConstant.sirShowExistingInventory.message,
          knownProductConstant.local); 
      }
    }

    function onClickShowConstructionInventory(pins, showConstructionInventory) {
      $scope.togglePushPins(pins, showConstructionInventory);
      if (showConstructionInventory) {
        AuditService.logEvent(knownEventConstant.sirShowConstructionInventory.id, knownEventConstant.sirShowConstructionInventory.message,
          knownProductConstant.local);
      }
    }

    function onClickShowTransactionActivity(pins, showTransactionActivity) {
      $scope.togglePushPins(pins, showTransactionActivity);
    }
  };
})();
