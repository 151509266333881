(function () {
	"use strict";
  app.directive('propertyListHalf', PropertyListHalfDirective);

  function PropertyListHalfDirective () {
    return {
      restrict: 'E',
      templateUrl: '/app/partials/local/propertyListHalf.html',
      controller: PropertyListHalfController,
      controllerAs: 'list',
      scope: {},
      bindToController: {
        propertyDetailsClick: "<propertyDetailsClick",
        properties: '=properties',
        searchFilter: '<searchFilter'
      }
    };
  };

  PropertyListHalfController.$inject = ['$scope', '$anchorScroll', 'AuditService', 'knownEventConstant', 'knownProductConstant'];
  function PropertyListHalfController($scope, $anchorScroll, AuditService, knownEventConstant, knownProductConstant) {
    var vm = this;

    vm.itemTracker = itemTracker;
    vm.fireScrollEvent = fireScrollEvent;
    vm.toggleExpandAll = toggleExpandAll;
    vm.jumpToSearchMatch = jumpToSearchMatch;

    vm.displayCount = initDisplayCount();
    vm.expandedState = false;
    vm.nameSearch = {
      query: "",
      matches: [],
      currentMatchIndex: 0
    };

    $scope.$on('updateDisplayCountToShowHighlightPin', updateDisplayCountToShowHighlightPin);
    $scope.$on('expandButtonToggle', recalculateExpandedState);
    $scope.$on('propertyListSortChanged', function(e,data) { vm.currentSort = data; });
    $scope.$on('clearNameSearch', function() { vm.nameSearch.query = ""; });

    $scope.$watchCollection(function () {
        return vm.properties.items;
      }, function () {
        vm.displayCount = initDisplayCount();
    });

    $scope.$watch(function() { return vm.nameSearch.query; }, findSearchMatches);

    function itemTracker(item) {
      return item.transactionId || item.id || item;
    }

    function fireScrollEvent() {
      vm.displayCount = _.clamp(vm.displayCount + 5, 10, vm.properties.items.length);
    }

    function toggleExpandAll() {
      vm.expandedState = !vm.expandedState;
      _.each(vm.properties.items, function(property) {
        if (property.id || property.transactionId) {
          property.expanded = vm.expandedState;
        }
      });
      AuditService.logEvent(knownEventConstant.expandCollapseAllProperties.id, knownEventConstant.expandCollapseAllProperties.message, determineLogProduct());
    }

    function recalculateExpandedState() {
      var numDifferentFromExpandAllButton = 0;
      _.each(vm.properties.items, function(property) {
        if (property.id && property.expanded != vm.expandedState) {
          numDifferentFromExpandAllButton++;
        }
      });

      if (vm.properties.items.length == numDifferentFromExpandAllButton) {
        vm.expandedState = !vm.expandedState;
      }
    }

    function findSearchMatches() {
      var matches = _.filter(vm.properties.items, function(property) {
        if (property.name.toLowerCase().indexOf(vm.nameSearch.query.toLowerCase()) > -1) {
          return true;
        }
      });
      if (matches.length >= 1) {
        updateDisplayCountToShowHighlightPin(null, matches[matches.length - 1]); //currently operates on assumption they are in pin order
        vm.properties.items = _.map(vm.properties.items, function(property) {
          if (_.includes(matches, property)) {
            property.searchMatch = matches.length > 1 ? "partial": "full";
          } else {
            property.searchMatch = "none";
          }
          return property;
        });
      }
      vm.nameSearch.matches = matches;
      vm.nameSearch.currentMatchIndex = 0;
      if (vm.nameSearch.matches.length) {
        var id = ['property',vm.nameSearch.matches[vm.nameSearch.currentMatchIndex].id, vm.nameSearch.matches[vm.nameSearch.currentMatchIndex].transactionId].join('');
        $anchorScroll(id);
      }
    }

    function updateDisplayCountToShowHighlightPin(event, data) {
      var dataPropertyIndex = _.findIndex(vm.properties.items, ['id', data.id]);
      if (dataPropertyIndex >= vm.displayCount) {
        vm.displayCount = _.clamp(dataPropertyIndex + 10, 10, vm.properties.items.length);
      }
    }

    function jumpToSearchMatch(direction) {
      if (vm.nameSearch.matches.length < 1) {
        vm.nameSearch.currentMatchIndex = 0;
      } else {
        switch (direction) {
          case "up":
            var newIndex = vm.nameSearch.currentMatchIndex - 1;
            if (!_.inRange(newIndex, vm.nameSearch.matches.length)) {
              vm.nameSearch.currentMatchIndex = vm.nameSearch.matches.length - 1;
              break;
            }
            vm.nameSearch.currentMatchIndex = vm.nameSearch.currentMatchIndex - 1;
            break;
          case "down":
            var newIndex = vm.nameSearch.currentMatchIndex + 1;
            if (!_.inRange(newIndex, vm.nameSearch.matches.length)) {
              vm.nameSearch.currentMatchIndex = 0;
              break;
            }
            vm.nameSearch.currentMatchIndex = newIndex;
            break;
        }
      }
  
      if (vm.nameSearch.matches.length) {
        var id = ['property',vm.nameSearch.matches[vm.nameSearch.currentMatchIndex].id, vm.nameSearch.matches[vm.nameSearch.currentMatchIndex].transactionId].join('');
        $anchorScroll(id);
      }
    }

    function initDisplayCount() {
      var height = $('.map-container').height();
      return _.max([_.floor(height/50), 15]); //50 is list item height
    }

    function determineLogProduct() { 
      return vm.searchFilter().transactionOnly ? knownProductConstant.trans : knownProductConstant.local;
    } 
  };
})();
