(function () {
  'use strict';
  app.directive('compBuilderAggregateSearchCriteria', CompBuilderAggregateSearchCriteriaDirective);

  function CompBuilderAggregateSearchCriteriaDirective() {
    return {
      restrict: 'E',
      templateUrl: '/app/partials/compbuilder/aggregateSearchCriteria.html',
      controller: AggregateSearchCriteriaController,
      controllerAs: 'vm',
      scope: {},
      bindToController: {
        radiusOptions: '<radiusOptions',
        driveTimeOptions: '<driveTimeOptions',
        segmentTypeOptions: '<segmentTypeOptions',
        propertyTypeOptions: '<propertyTypeOptions',
        campusTypeOptions: '<campusTypeOptions',
        includeCCRCOptions: '<includeCcrcOptions',
        searchCriteria: '=searchCriteria',
        readonly: '<?readonly'
      }
    };
  }

  AggregateSearchCriteriaController.$inject = ['$scope', 'FilterMapper'];
  function AggregateSearchCriteriaController($scope, FilterMapper) {
    var vm = this;

    vm.$onInit = onInit;

    vm.propertyTypeChanged = propertyTypeChanged;
    vm.campusTypeChanged = campusTypeChanged;
    vm.segmentTypeChanged = segmentTypeChanged;
    vm.driveTimeBreakValueChanged = driveTimeBreakValueChanged;
    vm.resetDriveTime = resetDriveTime;
    vm.resetRadius = resetRadius;
    vm.selectedOptions = {
      propertyType: null,
      campusType: null,
      segmentType: null
    };

    function onInit() {
      // Preselect options based on search criteria
      // Not really super general. But reverts the filtermapper function
      if (Array.isArray(vm.searchCriteria.propertyTypeIds)) {
        vm.selectedOptions.propertyType = vm.searchCriteria.propertyTypeIds.reduce(function (acc, item) {
          acc[item] = true;
          return acc;
        }, {});
      }

      if (Array.isArray(vm.searchCriteria.campusTypeIds)) {
        vm.selectedOptions.campusType = vm.searchCriteria.campusTypeIds.reduce(function (acc, item) {
          acc[item] = true;
          return acc;
        }, {});
      }

      if (Array.isArray(vm.searchCriteria.segmentTypeIds)) {
        vm.selectedOptions.segmentType = vm.searchCriteria.segmentTypeIds.reduce(function (acc, item) {
          acc[item] = true;
          return acc;
        }, {});
      }
    }

    function propertyTypeChanged() {
      vm.searchCriteria.propertyType = 
        FilterMapper.buildObjectListFromCheckBoxes(vm.propertyTypeOptions, vm.selectedOptions.propertyType);
    }

    function campusTypeChanged() {
      vm.searchCriteria.campusType = 
        FilterMapper.buildObjectListFromCheckBoxes(vm.campusTypeOptions, vm.selectedOptions.campusType);
    }

    function segmentTypeChanged() {
      vm.searchCriteria.segmentType = 
        FilterMapper.buildObjectListFromCheckBoxes(vm.segmentTypeOptions, vm.selectedOptions.segmentType, 'code');
    }

    function driveTimeBreakValueChanged(selectedItem) {
      vm.searchCriteria.tradeArea.driveTime.breakValue = selectedItem.id;
      if (!vm.searchCriteria.tradeArea.driveTime.breakValue) {
        vm.searchCriteria.tradeArea.driveTime.trafficFlow = null;
        return;
      }
      if (!vm.searchCriteria.tradeArea.driveTime.trafficFlow) {
        vm.searchCriteria.tradeArea.driveTime.trafficFlow = "Low";
      }
    }

    function resetDriveTime() {
      vm.searchCriteria.tradeArea.driveTime.breakValue = null;
      vm.searchCriteria.tradeArea.driveTime.trafficFlow = null;
    }

    function resetRadius() {
      vm.searchCriteria.tradeArea.radius1 = null;
      vm.searchCriteria.tradeArea.customRadius = null;
    }
  }
})();
