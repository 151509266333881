(function() {
  "use strict";
  app.controller('ProductAccessController', ProductAccessController);

  ProductAccessController.$inject = ['$scope', '$state'];

  function ProductAccessController($scope, $state) {
    var title = '';
    switch ($state.params.productCode) {
      case "Trends":
        title = "Trends";
        break;
      case "Local":
        title = "Local Property Search";
        break;
      case "MAPPortfolios": 
        title = "Portfolios";
        break;
        //Portfolios is a Alias to MAPPortfolios in case the
        //MAPPortfolios productCode is renamed.
      case "Portfolios": 
        title = "Portfolios";
        break;
      case "PropertyInformationReport": 
        title = "Property Information Report";
        break;
      case "CustomCohortDemographics": 
        title = "Custom Cohort Demographics";
        break;
    }
    $scope.productName = title;    
  } 
})();