(function () {

  /*
   *
   *
   *        DELETE THIS FILE AT THE SAME TIME WHEN THE IMPORT FILE FUNCTIONALITY CARD #13963 IS BEING WORKED ON.  
   *
   *
   *
  */

  'use strict';
  app.directive('oldCompBuilderAggregateSubjectLocationGrid', OldCompBuilderAggregateSubjectLocationGridDirective);

  function OldCompBuilderAggregateSubjectLocationGridDirective() {
    return {
      restrict: 'E',
      templateUrl: '/app/partials/compbuilder/oldAggregateSubjectLocationGrid.html',
      controller: OldCompBuilderAggregateSubjectLocationGridController,
      controllerAs: 'vm',
      scope: {},
      bindToController: {
        aggregateName: '=aggregateName',
        peerGroups: '=peerGroups',
        editMode: '=editMode',
        validationErrors: '<validationErrors'
      }
    };
  }

  OldCompBuilderAggregateSubjectLocationGridController.$inject = ['$scope', '$uibModal', 'apiSettings', 'SpinnerService', 'AuditService', 'knownEventConstant', 'Upload'];
  function OldCompBuilderAggregateSubjectLocationGridController($scope, $uibModal, apiSettings, SpinnerService, AuditService, knownEventConstant, Upload) {
    var vm = this;
    var allowedMimeTypes = [
      '.csv',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ];

    vm.peerGroupGridOptions = {
      columns: [
        {
          template: '<a ng-class="{ \'disabled\': vm.editMode }" ng-click="vm.deleteRow(dataItem)"><span class="k-icon glyphicon glyphicon glyphicon-minus-sign primary-blue-dark"></span></a>',
          width: '69px',
          attributes: {
            "class": "text-center"
          }
        },
        {
          template: '<a ng-class="{ \'disabled\': vm.isEditButtonDisabled(dataItem) }" class="k-grid-edit" ng-show="!vm.isRowBeingEdited(dataItem)" ng-click="vm.editRow(dataItem)"><span class="k-icon k-i-close"></span></a>\
                     <a class="k-grid-update" ng-show="vm.isRowBeingEdited(dataItem)" ng-click="vm.updateRow(dataItem)"><span class="k-icon k-i-close"></span></a>\
                     <a class="k-grid-cancel" ng-show="vm.isRowBeingEdited(dataItem)" ng-click="vm.cancelEdit(dataItem)"><span class="k-icon k-i-close"></span></a>',
          width: '69px',
          attributes: {
            "class": "text-center"
          }
        },
        {
          name: 'Peer Group Name',
          field: 'name',
          title: 'Peer Group Name',
        }, {
          name: 'Subject Location',
          field: 'subjectLocation',
          title: 'Subject Location',
        }
      ],
      dataBound: function(e) {
        // https://www.telerik.com/forums/custom-class-to-row-based-on-dataitem
        var items = e.sender.items();
        _.each(items, function(item) {
          var item2 = e.sender.dataItem(item);
          if(item2.isValid == false) {
            $(item).addClass('bright-red');
          }
        })
      },
      dataSource: new kendo.data.DataSource({
        pageSize: 5,
        transport: {
          read: function (options) {
            var merged = _.map(vm.peerGroups, function (peerGroup) {
              var matchedError = _.find(vm.validationErrors, function (error) {
                if(error && error.peerGroup && error.peerGroup.id == peerGroup.id){
                  return error;
                }
                return undefined;
              });
              if(matchedError) {
                _.extend(peerGroup, { isValid: matchedError.isValid})
              }
              return peerGroup;
            });
            options.success(merged);
          },
          update: function (e) {
            e.success();
          },
          destroy: function (e) {
            // find and remove the match from peerGroups
            _.remove(vm.peerGroups, { id: e.data.id });
            e.success();
          }
        },
        schema: {
          model: {
            id: 'id',
            fields: {
              'id': { type: 'number' },
              'name': {
                type: 'string',
                validation: {
                  required: true
                }
              },
              'subjectLocation': {
                type: 'string',
                validation: {
                  required: true
                }
              },
              'isValid': {
                type: 'boolean'
              }
            }
          }
        },
        sort: {
          field: 'subjectLocation',
          dir: 'desc'
        },
        serverPaging: false,
        serverFiltering: false,
        serverSorting: false,
      }),
      scrollable: false,
      pageable: true,
      editable: {
        mode: 'inline',
        confirmation: false // We handle this ourselves.
      },
      sortable: {
        mode: 'single',
        allowUnsort: false
      },
      resetFilters: function () {
        this.dataSource.filter([]);
        this.dataSource.sort({ field: 'subjectLocation', dir: 'desc' });
      }
    };

    vm.hasValidationErrors = false;
    vm.hasImportError = false;
    vm.errorMessage = '';
    vm.currentlyEditedRow = null;
    vm.file = null;
    vm.fileUploadAccept = allowedMimeTypes.join(', ');

    vm.addButtonClick = addButtonClick;
    vm.getFakeData = getFakeData;
    vm.getValidationErrors = getValidationErrors;
    vm.editRow = editRow;
    vm.updateRow = updateRow;
    vm.deleteRow = deleteRow;
    vm.cancelEdit = cancelEdit;
    vm.isRowBeingEdited = isRowBeingEdited;
    vm.isEditButtonDisabled = isEditButtonDisabled;
    vm.isAddButtonDisabled = isAddButtonDisabled;
    vm.isSelectListButtonDisabled = isSelectListButtonDisabled;
    vm.upload = upload;

    function getFakeData() {
      var subjectLocation = vm.subjectLocation;
      vm.subjectLocation = null;
      return _.concat(vm.peerGroups, {
        id: Date.now(),
        name: subjectLocation,
        subjectLocation: subjectLocation
      });
    }

    function addButtonClick() {
      if (vm.isAddButtonDisabled()) { return; };
      vm.peerGroups = vm.getFakeData();
      vm.peerGroupGridOptions.dataSource.read();
    }

    function getValidationErrors() {
      var hasErrors = $('#peerGroupGrid .k-invalid').length;
      vm.hasValidationErrors = hasErrors > 0;
    }

    function editRow(row) {
      vm.editMode = true;
      vm.currentlyEditedRow = row.id;
    }

    function updateRow(row) {
      if (vm.hasValidationErrors) { return; }
      vm.editMode = false;
      vm.currentlyEditedRow = null;
    }

    function deleteRow(row) {
      var confirmDeletePopup = $uibModal.open({
        templateUrl: 'app/partials/confirmDeletePopup.html',
        controller: ['$scope', 'deleteName', function ($scope, deleteName) {
          $scope.deleteName = deleteName;
        }],
        resolve: {
          deleteName: function() { return row.name; }
        }
      });
      confirmDeletePopup.result.then(function (confirmDeleteResult) {
        if(confirmDeleteResult) {
          vm.peerGroupGridOptions.dataSource.remove(row);
          vm.peerGroupGridOptions.dataSource.sync();
        }
      });
    }

    function cancelEdit(row) {
      vm.editMode = false;
      vm.currentlyEditedRow = null;
      vm.hasValidationErrors = false;
    }

    function isRowBeingEdited(row) {
      return vm.editMode && (row.id === vm.currentlyEditedRow);
    }

    function isEditButtonDisabled(row) {
      return vm.editMode && (row.id !== vm.currentlyEditedRow);
    }

    function isAddButtonDisabled() {
      return !vm.subjectLocation || vm.editMode;
    }

    function isSelectListButtonDisabled() {
      return vm.editMode;
    }

    function upload(file) {
      // if no file, opt out
      if (!file) return;

      AuditService.logEvent(knownEventConstant.selectListCompBuilder.id,
                            knownEventConstant.selectListCompBuilder.message);

      // if file does exist, verify that mime type is allowed
      if (!_.includes(allowedMimeTypes, file.type)) {
        vm.hasImportError = true;
        vm.errorMessage = 'Invalid file format. Only XLS, XLSX, or CVS file types are supported.';
        return;
      }

      SpinnerService.start();
      vm.hasImportError = false;
      vm.errorMessage = '';
      vm.file = file;
      // upload file
      var data = {
        file: file
      };
      Upload.upload({
        url: apiSettings.url + '/compbuilder/upload',
        data: data,
        bypassErrorInterceptor: true
      })
      .progress(function (evt) {
        // to track the progress of the upload
        console.log(evt);
      })
      .success(function (data, status, headers, config) {
        // console.log(data, status, headers, config);
        SpinnerService.stop();
        vm.hasImportError = false;
        if (!data && !data.length <= 0) return;
        var mappedData = data.map(function (row, idx) {
          return {
            id: Date.now() + idx,
            name: row.name,
            subjectLocation: row.location
          }
        });
        vm.peerGroups = _.concat(vm.peerGroups, mappedData);
        vm.peerGroupGridOptions.dataSource.read();
      })
      .error(function (data, status, headers, config) {
        console.error(data, status, headers, config);
        SpinnerService.stop();
        vm.errorMessage = data.Message;
        vm.hasImportError = true;
      });
    }

    // When validation errors change, reload the grid to highlight rows with issues
    $scope.$watch(function () {
      return vm.validationErrors;
    }, function () {
      vm.peerGroupGridOptions.dataSource.read();
    });
  }
})();
