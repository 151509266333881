(function () {
  "use strict";
  
  app.controller('SubscriptionProductsController', SubscriptionProductsController);
  
  SubscriptionProductsController.$inject = ['$scope', '$rootScope', '$state', '$window'
    , 'SubscriptionDetailsService', 'SubscriptionProductMapper', 'subscriptionTypesConstant', 'productGroupConstant', 'clientApiSubscriptionTypesConstant'];
  
  function SubscriptionProductsController($scope, $rootScope, $state, $window
    , SubscriptionDetailsService, SubscriptionProductMapper, subscriptionTypesConstant, productGroupConstant, clientApiSubscriptionTypesConstant) {

    $scope.productBySubscriptionTypes = {
      first: {},
      second: {}
    };
    $scope.nationalProductsBySubscriptionTypes = {};
    $scope.qualityMetricProductsBySubscriptionTypes = {};
    $scope.clientApiProductsBySubscriptionTypes = {};
    $scope.iqDataAccessProductsBySubscriptionTypes = {};

    $scope.isUpdating = false;
    $scope.onClearProductsClicked = onClearProductsClicked;
    $scope.onEditClicked = onEditClicked;
    $scope.onSaveClicked = onSaveClicked;
    $scope.onCancelClicked = onCancelClicked;
    $scope.isSaveNotAllowed = isSaveNotAllowed;

    var subProductsData = $scope.$parent.subProductsData;
    var subscriptionTypes = subProductsData.allSubscriptionTypes;
    var allProducts = subProductsData.allProducts;
    var subscribedProducts = subProductsData.subscribedProducts;
    var subscriptionInfo = subProductsData.subscription;
    $scope.isInPopup = subProductsData.isInPopup;
    if ($scope.isInPopup) {
      // if in a popup than it is always going to be in edit mode
      // order matters... so do this after the initialization
      $scope.isUpdating = true;
      $scope.$on('parentSaveClicked', parentSaveClicked);
      $scope.$on('parentIsSaveNotAllowed', parentIsSaveNotAllowed);
    }

    $scope.$on('SilentSubscriptionDetailRefreshComplete', function () {
      subscriptionInfo = SubscriptionDetailsService.getSubscriber();
      subscribedProducts = SubscriptionDetailsService.getSubscribedProducts();
      $scope.fakeClientApiSubscriptionType.selected = subscriptionInfo.clientApiSubscriptionTypeId ? true : false;
    });

    var initializing = true;
    var lastSaveUpdateData = {
      allSubscriptionTypes: [],
      productBySubscriptionTypes: {},
      nationalProductsBySubscriptionTypes: {},
      qualityMetricProductsBySubscriptionTypes: {},
      clientApiProductsBySubscriptionTypes: {},
      iqDataAccessProductsBySubscriptionTypes: {}
    };

    function initProductAccess() {
      var mappedProducts = [];
      $scope.allSubscriptionTypes = _.orderBy(subscriptionTypes, ['sortOrder'], ['asc']);
      _.forEach($scope.allSubscriptionTypes, function (subType, index) {
        // mark the subscription type as selected or not
        var hasAccess = SubscriptionProductMapper.hasAccessToSubscriptionType(subType, subscriptionInfo);
        _.assign(subType, {'selected': hasAccess, 'visible': subType.id < subscriptionTypesConstant.proport});
        if (subType.id < subscriptionTypesConstant.proport) {
          $scope.$watch('allSubscriptionTypes[' + index + ']', onSubscriptionTypeChanged, true);
        }
      });
      $scope.subscriptionTypesFirstHalf = $scope.allSubscriptionTypes.slice(0, 2);
      $scope.subscriptionTypesSecondHalf = $scope.allSubscriptionTypes.slice(2, $scope.allSubscriptionTypes.length);

      _.forEach($scope.allSubscriptionTypes, function (value) {
        if (value.id == subscriptionTypesConstant.proport || value.id == subscriptionTypesConstant.locport || value.id == subscriptionTypesConstant.funport)
          return; //skip through these steps

        var productBySubscriptionTypeKey = value.subscriptionCode;

        var productsBySubscriptionType =
          SubscriptionProductMapper.getAvailableProducts(productBySubscriptionTypeKey, allProducts);

        var availableProducts = productsBySubscriptionType.availableProducts;
        var availableNationalProducts = productsBySubscriptionType.availableNationalProducts;
        var availableQualityMetricsProducts = productsBySubscriptionType.availableQualityMetricsProducts;
        var availableIQDataAccessProducts = productsBySubscriptionType.availableIQDataAccessProducts;
        var chunkSize = productsBySubscriptionType.chunkSize;

        // separate the array in chunk
        var flattenedOutData = angular.copy(_.flattenDepth(availableProducts));
        var flattenedWUniqData = _.uniqBy(flattenedOutData, 'id');

        _.forEach(flattenedWUniqData, function (eachProduct) {
          // mark the subscribed products as selected
          var hasAccess = SubscriptionProductMapper.hasAccessToProduct(eachProduct, value, subscriptionInfo, subscribedProducts);
          if (hasAccess)
            mappedProducts.push(eachProduct);
          _.assign(eachProduct, {'selected': hasAccess ? true : false});
        });

        var sortedFlattenedOutData = _.orderBy(flattenedWUniqData, ['name'], ['asc']);
        $scope.productBySubscriptionTypes.first[productBySubscriptionTypeKey] = sortedFlattenedOutData.slice(0, chunkSize);
        $scope.productBySubscriptionTypes.second[productBySubscriptionTypeKey] = sortedFlattenedOutData.slice(chunkSize);

        $scope.nationalProductsBySubscriptionTypes[productBySubscriptionTypeKey] = 
          setUpCustomProductGroups('National', availableNationalProducts, value, mappedProducts);
        $scope.qualityMetricProductsBySubscriptionTypes[productBySubscriptionTypeKey] = 
          setUpCustomProductGroups('Quality Metrics', availableQualityMetricsProducts, value, mappedProducts);
        $scope.iqDataAccessProductsBySubscriptionTypes[productBySubscriptionTypeKey] =
          setUpCustomProductGroups('Intra-Quarterly Data Access', availableIQDataAccessProducts, value, mappedProducts);
      });

      $scope.$watch('nationalProductsBySubscriptionTypes', onCustomGroupSelectionChanged, true);
      $scope.$watch('qualityMetricProductsBySubscriptionTypes', onCustomGroupSelectionChanged, true);
      $scope.$watch('iqDataAccessProductsBySubscriptionTypes', onCustomGroupSelectionChanged, true);

      // map the products that the user has subscribed to but does not belong to the same subscriptiontype...
      // use Professional section to select those products
      _.forEach(subscribedProducts, function (eachSubscribedProduct) {
        var isItBeenMapped = _.find(mappedProducts, function (eachMappedProduct) {
          return eachMappedProduct.id == eachSubscribedProduct.product.id;
        });
        if (!isItBeenMapped) {
          var productToFind = eachSubscribedProduct.product;
          if (mapUnmappedProducts($scope.productBySubscriptionTypes.first['PRO'], productToFind)) return;
          if (mapUnmappedProducts($scope.productBySubscriptionTypes.second['PRO'], productToFind)) return;
          if (mapUnmappedProductThroughProductGroups($scope.nationalProductsBySubscriptionTypes['PRO'], productToFind)) return;
          if (mapUnmappedProductThroughProductGroups($scope.qualityMetricProductsBySubscriptionTypes['PRO'], productToFind)) return;
          if (mapUnmappedProductThroughProductGroups($scope.iqDataAccessProductsBySubscriptionTypes['PRO'], productToFind)) return;
        }
      });

      addFakeClientApiSubscriptionType();
      initClientApiProductGroupsandProducts();
      
      initializing = false;
    }

    function mapUnmappedProductThroughProductGroups(arrayToUse, productToFind) {
      var wasMapped = false;
      _.forEach(arrayToUse, function (obj) {
        if (mapUnmappedProducts(obj.products, productToFind)) {
          wasMapped = true;
          return false;
        }
      });
      return wasMapped;
    }

    function mapUnmappedProducts(arrayToUse, productToFind) {
        var wasMapped = false;
        var unmappedProdFound = _.find(arrayToUse, function (eachProduct) {
          return productToFind.id == eachProduct.id;
        });
        if (unmappedProdFound) {
          unmappedProdFound.selected = true;
          wasMapped = true;
        }
        return wasMapped;
    }

    function setUpCustomProductGroups(groupName, productsInGroup, subscriptionType, mappedProductsTracker) {
      var flattenedOutData = angular.copy(_.flattenDepth(productsInGroup));
      _.forEach(flattenedOutData, function (eachProduct) {
        // mark the subscribed products as selected
        var hasAccess = SubscriptionProductMapper.hasAccessToProduct(eachProduct, subscriptionType, subscriptionInfo, subscribedProducts);
        if (hasAccess)
          mappedProductsTracker.push(eachProduct);
        _.assign(eachProduct, { 'selected': hasAccess ? true : false });
      });
      var dataValueStructure = [{
        name: groupName,
        selected: false,
        products: flattenedOutData
      }];
      return angular.copy(dataValueStructure);
    }

    initProductAccess();

    function onClearProductsClicked() {
      _.forEach($scope.allSubscriptionTypes, function (subType) {
        subType.selected = false;
        changeProductSelectionPerSubscriptionType(subType.subscriptionCode, false, true);
      });
    }

    var stopAngularWatchForSubscriptionType = false, wasCancelClicked = false;
    function onSubscriptionTypeChanged(newVal, oldVal) {
      if (initializing)
        return;

      if (stopAngularWatchForSubscriptionType) {
        stopAngularWatchForSubscriptionType = false;
        return;
      }

      if (wasCancelClicked) {
        wasCancelClicked = false;
        stopAngularWatchForSubscriptionType = true;
        return;
      }

      if ($scope.isInPopup) {
        $scope.$emit("isSaveNotAllowed", isSaveNotAllowed());
      }

      if (newVal.id == subscriptionTypesConstant.port) {
        // change happened to portfolio subscription type so bypass this
        if (newVal != oldVal) {
          changeProductSelectionPerSubscriptionType(newVal.subscriptionCode, newVal.selected);
        } else {
          stopAngularWatchForSubscriptionType = true;
        }
        return;
      }

      var selectedSubTypes = _.filter($scope.allSubscriptionTypes, function (subType) {
        return subType.selected == true && subType.id != subscriptionTypesConstant.port && subType.visible;
      });

      if (selectedSubTypes.length > 1 && newVal.selected && !oldVal.selected) {
        // and the value changed to true from false for one of the subscription type from Local, Professional, or Fundamental & Insights
        _.forEach(selectedSubTypes, function (subType, index) {
          if (newVal === subType) {
            // select all the products that are available to this subscriptiontype
            changeProductSelectionPerSubscriptionType(subType.subscriptionCode, true);
            return;
          }

          // unselect all the products
          subType.selected = false;
          changeProductSelectionPerSubscriptionType(subType.subscriptionCode, false);
          clearCustomGroupProducts();
        });
        stopAngularWatchForSubscriptionType = true;
      } else if (selectedSubTypes.length <= 1 && newVal != oldVal) {
        changeProductSelectionPerSubscriptionType(newVal.subscriptionCode, newVal.selected);
        clearCustomGroupProducts();
      } else {
        stopAngularWatchForSubscriptionType = true;
      }
    }

    function onCustomGroupSelectionChanged(newVal, oldVal) {
      if (initializing)
        return;

      if (newVal != oldVal) {
        _.forOwn(newVal, function (newValvalue, newValkey) {
          _.forOwn(oldVal, function (oldValvalue, oldValkey) {
            if (newValkey == oldValkey && newValvalue[0].selected != oldValvalue[0].selected) {
              _.forEach(newValvalue[0].products, function (eachProduct, itrIndex) {
                eachProduct.selected = newValvalue[0].selected;
              });
            }
          });
        });
      }
    }

    function changeProductSelectionPerSubscriptionType(subCode, selectionFlag, clearCustomGroup) {
      _.forEach($scope.productBySubscriptionTypes.first[subCode], function (eachProduct, index) {
        eachProduct.selected = selectionFlag;
      });
      _.forEach($scope.productBySubscriptionTypes.second[subCode], function (eachProduct, index) {
        eachProduct.selected = selectionFlag;
      });
      if (clearCustomGroup) {
        updateCustomGroupSelection($scope.nationalProductsBySubscriptionTypes[subCode], selectionFlag);
        updateCustomGroupSelection($scope.qualityMetricProductsBySubscriptionTypes[subCode], selectionFlag);
        updateCustomGroupSelection($scope.iqDataAccessProductsBySubscriptionTypes[subCode], selectionFlag);
      }
    }

    function clearCustomGroupProducts() {
      _.forEach($scope.allSubscriptionTypes, function (subType) {
        updateCustomGroupSelection($scope.nationalProductsBySubscriptionTypes[subType.subscriptionCode], false);
        updateCustomGroupSelection($scope.qualityMetricProductsBySubscriptionTypes[subType.subscriptionCode], false);
        updateCustomGroupSelection($scope.iqDataAccessProductsBySubscriptionTypes[subType.subscriptionCode], false);
      });
    }

    function updateCustomGroupSelection(arrToUse, selectionFlag) {
      _.forEach(arrToUse, function (eachCustomGroup, index) {
        eachCustomGroup.selected = selectionFlag;
        _.forEach(eachCustomGroup.products, function (eachProduct, itrIndex) {
          eachProduct.selected = selectionFlag;
        });
      });
    }

    function onEditClicked() {
      $scope.isUpdating = true;
      // save the current view so that canceling the edits would go back to last saved result set
      angular.copy($scope.allSubscriptionTypes, lastSaveUpdateData.allSubscriptionTypes);
      angular.copy($scope.productBySubscriptionTypes, lastSaveUpdateData.productBySubscriptionTypes);
      angular.copy($scope.nationalProductsBySubscriptionTypes, lastSaveUpdateData.nationalProductsBySubscriptionTypes);
      angular.copy($scope.qualityMetricProductsBySubscriptionTypes, lastSaveUpdateData.qualityMetricProductsBySubscriptionTypes);
      angular.copy($scope.clientApiProductsBySubscriptionTypes, lastSaveUpdateData.clientApiProductsBySubscriptionTypes);
      angular.copy($scope.iqDataAccessProductsBySubscriptionTypes, lastSaveUpdateData.iqDataAccessProductsBySubscriptionTypes);
      stopAngularWatchForSubscriptionType = false;
      wasCancelClicked = false;
    }

    function parentSaveClicked(evt) {
      $scope.$emit("subTypeWProds", subTypeWProds());
    }

    function parentIsSaveNotAllowed(evt) {
      $scope.$emit("isSaveNotAllowed", isSaveNotAllowed());
    }

    function subTypeWProds() {
      var dataToReturn = {};
      var subscriptionTypeToSave = SubscriptionProductMapper.getSelectedSubscriptionType($scope.allSubscriptionTypes);
      if (subscriptionTypeToSave)
        dataToReturn.subscriptionTypeId = subscriptionTypeToSave;

      var clientApiSubscriptionTypeToSave = getSelectedClientApiSubscriptionType();
      if (clientApiSubscriptionTypeToSave)
        dataToReturn.clientApiSubscriptionTypeId = clientApiSubscriptionTypeToSave;

      dataToReturn.subscriptionProducts = [];
      getSelectedProducts($scope.productBySubscriptionTypes.first, dataToReturn, false);
      getSelectedProducts($scope.productBySubscriptionTypes.second, dataToReturn, false);
      getSelectedProducts($scope.nationalProductsBySubscriptionTypes, dataToReturn, true);
      getSelectedProducts($scope.qualityMetricProductsBySubscriptionTypes, dataToReturn, true);
      getSelectedProducts($scope.iqDataAccessProductsBySubscriptionTypes, dataToReturn, true);

      _.forEach($scope.clientApiProductsBySubscriptionTypes, function (group, index) {
        if (group) {
          getSelectedProducts({ clientApi: [group] }, dataToReturn, true);
        }
      });
      
      if (dataToReturn.subscriptionProducts.length) {
        dataToReturn.subscriptionProducts = _.uniqBy(angular.copy(_.flattenDepth(dataToReturn.subscriptionProducts)), 'id');
      }
      return dataToReturn;
    }

    function onSaveClicked() {
      // Save startdate, enddate, price, subscriptiontype, subscriptionproducts
      var subTypeWProdsSelection = subTypeWProds();
      var dataToSave = {
        startDate: subscriptionInfo.startDate,
        endDate: subscriptionInfo.endDate,
        price: subscriptionInfo.price,
        isRenewal: subscriptionInfo.isRenewal,
        subscriptionTypeId: subTypeWProdsSelection.subscriptionTypeId,
        isMetroBank: subscriptionInfo.isMetroBank,
        metrosPurchased: subscriptionInfo.metrosPurchased,
        subscriptionProducts: subTypeWProdsSelection.subscriptionProducts,
        clientApiSubscriptionTypeId: subTypeWProdsSelection.clientApiSubscriptionTypeId
      };

      // save product access
      SubscriptionDetailsService
        .updateSubscriptionAccess(subscriptionInfo.id, 'products', dataToSave)
        .then(function (rsp) {
          // update successful
          // if the subscription type has changed then the page needs to be refreshed.
          if (subscriptionInfo.subscriptionTypeID != dataToSave.subscriptionTypeId) {
            $state.go('admin.subscriptiondetails',
            {
              id: subscriptionInfo.id,
              companyId: $state.params.companyId
            },
            { reload: true });
          } else {
            // else continue on
            // update subscriptioninfo in service so it is available across all views on page
            // get the products again and set them to the service so that all the subview use the correct product mapping
            $rootScope.$broadcast("SilentSubscriptionDetailRefresh", { productsUpdated: true });
          }
        }, function (err) {
          $window.alert('Update to subscription failed: ' + (err.data || err.statusText));
        })
        .finally(function () {
          $scope.isUpdating = false;
        });
    }

    function isSaveNotAllowed() {
      return SubscriptionProductMapper.getSelectedSubscriptionType($scope.allSubscriptionTypes) == null ? true : false;
    }

    function getSelectedProducts(prodsBySubTypes, dataToSave, isCustomGroupSelection) {
      _.forEach(prodsBySubTypes, function (eachProd) {
        var arrOfProds = isCustomGroupSelection ? eachProd[0].products : eachProd;
        var selectedProds = _.filter(arrOfProds, function (eachSelectedProds) {
          return eachSelectedProds.selected == true;
        });
        if (selectedProds.length)
          dataToSave.subscriptionProducts.push(selectedProds);
      });
    }

    function onCancelClicked() {
      $scope.isUpdating = false;
      // since cancel was clicked go back to last saved data
      angular.copy(lastSaveUpdateData.allSubscriptionTypes, $scope.allSubscriptionTypes);
      angular.copy(lastSaveUpdateData.productBySubscriptionTypes, $scope.productBySubscriptionTypes);
      angular.copy(lastSaveUpdateData.nationalProductsBySubscriptionTypes, $scope.nationalProductsBySubscriptionTypes);
      angular.copy(lastSaveUpdateData.qualityMetricProductsBySubscriptionTypes, $scope.qualityMetricProductsBySubscriptionTypes);
      angular.copy(lastSaveUpdateData.clientApiProductsBySubscriptionTypes, $scope.clientApiProductsBySubscriptionTypes);
      angular.copy(lastSaveUpdateData.iqDataAccessProductsBySubscriptionTypes, $scope.iqDataAccessProductsBySubscriptionTypes);
      $scope.subscriptionTypesFirstHalf = $scope.allSubscriptionTypes.slice(0, 2);
      $scope.subscriptionTypesSecondHalf = $scope.allSubscriptionTypes.slice(2, $scope.allSubscriptionTypes.length);
      addFakeClientApiSubscriptionType();
      stopAngularWatchForSubscriptionType = true;
      wasCancelClicked = true;
    }

    function addFakeClientApiSubscriptionType() {
      // add a fake subscription type for the client api in the correct place in subscriptionTypesFirstHalf to get it to show up in the correct place on the screen
      $scope.fakeClientApiSubscriptionType = {
        active: true,
        id: 1000,
        selected: subscriptionInfo.clientApiSubscriptionTypeId ? true : false,
        sortOrder: 5,
        subscriptionCode: 'CLIENTAPI',
        subscriptionType: 'Client API',
        visible: true
      };
      $scope.subscriptionTypesFirstHalf.push($scope.fakeClientApiSubscriptionType);

      $scope.$watch('fakeClientApiSubscriptionType.selected', onClientApiRootSelectionChanged, true); 
    }

    function initClientApiProductGroupsandProducts() {
      //add client api productgroups and products
      var availableClientApiBasicProducts = _.find(allProducts, { id: productGroupConstant.basic });
      var availableClientApiEnterpriseProducts = _.find(allProducts, { id: productGroupConstant.enterprise }); 
      $scope.clientApiProductsBySubscriptionTypes['Basic'] = setUpClientApiProductGroups('Basic', availableClientApiBasicProducts, clientApiSubscriptionTypesConstant.basic );
      $scope.clientApiProductsBySubscriptionTypes['Enterprise'] = setUpClientApiProductGroups('Enterprise', availableClientApiEnterpriseProducts, clientApiSubscriptionTypesConstant.enterprise);
      $scope.$watch('clientApiProductsBySubscriptionTypes', onClientApiProductGroupSelectionChanged, true);  
    }   

    function setUpClientApiProductGroups(groupName, productGroup, clientApiSubscriptionTypeId ) {
      if (!productGroup)
        return;

      var checkProductAccess = false;
      if (subscriptionInfo.clientApiSubscriptionTypeId == clientApiSubscriptionTypeId) {
        checkProductAccess = true;
      }
      var groupHasAtleast1ProductSelected = false;
      var products = angular.copy(productGroup.products);
      _.forEach(products, function (eachProduct) {
        // mark the subscribed products as selected
        var hasAccess = false;
        if (checkProductAccess) {
          var hasAccess = _.find(subscribedProducts, { productId: eachProduct.id });
          if (hasAccess) {
            groupHasAtleast1ProductSelected = true;
          }
        }
        _.assign(eachProduct, { 'selected': hasAccess ? true : false });
      });
      var sortedProducts = _.orderBy(products, ['name'], ['asc']);

      var dataValueStructure = {
        id: productGroup.id,
        name: groupName,
        selected: groupHasAtleast1ProductSelected,
        clientApiSubscriptionTypeId: clientApiSubscriptionTypeId,
        products: sortedProducts
      };
      return angular.copy(dataValueStructure);
    }

    function getSelectedClientApiSubscriptionType(){
      var selectedClientApiSubscriptionTypeId;

      if ($scope.clientApiProductsBySubscriptionTypes) {
        _.forEach($scope.clientApiProductsBySubscriptionTypes, function (group, index) {
          if (group && group.selected) {
            selectedClientApiSubscriptionTypeId = group.clientApiSubscriptionTypeId;
          }
        });
      }

      return selectedClientApiSubscriptionTypeId;
    }
    
    var stopWatchForProductGroupSelection = false;
    function onClientApiProductGroupSelectionChanged(newVal, oldVal) {
      if (initializing)
        return;
      
      if (stopWatchForProductGroupSelection) {
        stopWatchForProductGroupSelection = false;
        return;
      }

      //figure out if a group was changed or a product, and then figure out if we need to flip flop groups
      var sourceProductGroupThatChanged;
      var sourceProductThatChanged;
      var sourceProductGroupForProductThatChanged;

      if (newVal != oldVal) {
        _.forOwn(newVal, function (newValvalue, newValkey) {
          _.forOwn(oldVal, function (oldValvalue, oldValkey) {
            if (newValkey == oldValkey) {
              if (newValvalue.selected != oldValvalue.selected) {
                // a product group selection was changed
                sourceProductGroupThatChanged = newValvalue;
                _.forEach(newValvalue.products, function (eachProduct, itrIndex) {
                  eachProduct.selected = newValvalue.selected;
                });
              } else {
                // the product group was not change, check to see if any of the products selection were changed
                _.forEach(newValvalue.products, function (eachProduct, itrIndex) {
                  if (eachProduct.selected != oldValvalue.products[itrIndex].selected) {
                    sourceProductThatChanged = eachProduct;
                    sourceProductGroupForProductThatChanged = newValvalue;
                  }
                });
              }
            }
          });
        });

        if (sourceProductGroupThatChanged && sourceProductGroupThatChanged.selected == true) {
          // a product group selection was channged, unselect all the other product groups
          _.forOwn($scope.clientApiProductsBySubscriptionTypes, function (group, groupkey) {
            if (group.id != sourceProductGroupThatChanged.id) {
              group.selected = false;
              _.forEach(group.products, function (subProduct) {
                subProduct.selected = false;
              });
              stopWatchForProductGroupSelection = true;
            }
          });
        }

        if (!sourceProductGroupThatChanged && sourceProductThatChanged && sourceProductThatChanged.selected == true) {
          // a product was selected
          if (!sourceProductGroupForProductThatChanged.selected) {
            // the product that was changed, it's product group is not selected.  So select it
            sourceProductGroupForProductThatChanged.selected = true;
            stopWatchForProductGroupSelection = true;
            // Then unselect the other subproducts of the originally selected product group
            _.forOwn($scope.clientApiProductsBySubscriptionTypes, function (group) {
              if (group.id != sourceProductGroupForProductThatChanged.id) {
                group.selected = false;
                _.forEach(group.products, function (subProduct) {
                  subProduct.selected = false;
                });
              }
            });
          }
        }
      } 
    }

    function onClientApiRootSelectionChanged(newVal, oldVal) {
      if (initializing)
        return;
    
      if (newVal != oldVal) {
        if (newVal == true) {
          // try to select the basic option and all products under it if neither basic or enterprise is selected
          var selectedgroups = _.find($scope.clientApiProductsBySubscriptionTypes, { selected: true });
          if (!selectedgroups) {
            if ($scope.clientApiProductsBySubscriptionTypes['Basic']) {
              $scope.clientApiProductsBySubscriptionTypes['Basic'].selected = true;
            }
          }
        } else {
          //try to unselect all Client Api product groups when the main Client Api fake subscription type is unchecked.  This will also uncheck the products as well.
          _.forEach($scope.clientApiProductsBySubscriptionTypes, function (group, groupkey) {
            group.selected = false;          
          });
        }
      }
    }

  }
})();
