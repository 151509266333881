(function () {
  "use strict";

  app.factory("SpinnerService", SpinnerService);
  
  SpinnerService.$inject = ['$rootScope'];
  
  function SpinnerService($rootScope) {
    var loadingEventCount = 0;
    return {
      start: start,
      stop: stop,
      reset: reset,
      getLoadingCount: getLoadingCount
    };
   
    function start() {
      loadingEventCount++;
      if(loadingEventCount > 0) {
        $rootScope.loading = true;
      }
    }

    function stop() {
      loadingEventCount--;
      if(loadingEventCount <= 0) {
        loadingEventCount = 0; //If we are at 0, no need to go further
        $rootScope.loading = false;
      }
    }

    function reset() {
      loadingEventCount = 0;
      $rootScope.loading = false;
    }

    function getLoadingCount() {
      return loadingEventCount;
    }
  }
})();
