(function () {
  'use strict';

  app.controller('CustomSiteCohortDemographicsController', CustomSiteCohortDemographicsController);
  
  CustomSiteCohortDemographicsController.$inject = ['$scope', '$state', '$q', '$sce', '$window', 'FileSaver', 'Blob', 'SiteInformationReportService', 'PropertyDetailsService', 
    'currentQuarter', 'demographicYear', 'demographicData', 'sirReport', 'ageBands', 'householdTypes', 'DataAttributionService'];

  function CustomSiteCohortDemographicsController($scope, $state, $q, $sce, $window, FileSaver, Blob, SiteInformationReportService, PropertyDetailsService,
    currentQuarter, demographicYear, demographicData, sirReport, ageBands, householdTypes, DataAttributionService) {

    $window.scrollTo(0, 0);
    $scope.demographicData = demographicData.data;
    $scope.sirReport = sirReport;
    $scope.demographicYear = demographicYear;
    $scope.isValid = isValid;
   
    $scope.numHousehold = null;
    $scope.pctGrowthRate = null;
    $scope.pctHousehold = null;
    $scope.numIncome = null;

    $scope.minAgeBand = {};
    $scope.minAgeBand.data = ageBands.minBand;
    $scope.minAgeBand.selected = "2"; // 45

    $scope.maxAgeBand = {};
    $scope.maxAgeBand.data = ageBands.maxBand;
    $scope.maxAgeBand.selected = "2"; // 54

    $scope.minIncome = {};
    $scope.minIncome.data = householdTypes;
    $scope.minIncome.selected = "1"; // All Households

    $scope.AttributionHtml = "";

    $scope.refreshClicked = refreshClicked;
    $scope.exportClicked = exportClicked;

    var customCohortSelectionTracker = {
      sirQueryID: null,
      tradeArea: null,
      minAge: null,
      maxAge: null,
      minIncome: null,
    };

    function initDemographicsGrid() {
      var tradeAreasData = [];
      var dynamicColumns = [{
        title: "County",
        columns: [{
          field: "county.age45to64",
          title: "45-64 yrs.",
          headerTemplate: "45-64<br>yrs.",
          attributes: {
            'class': 'text-right customKendo demographics'
          },
          template: '{{gridOpts_Demographics.changeRowTemplate(dataItem.index, 0, dataItem.county.age45to64)}}'
        }, {
          field: "county.age65to74",
          title: "65-74 yrs.",
          headerTemplate: "65-74<br>yrs.",
          attributes: {
            'class': 'text-right customKendo demographics'
          },
          template: '{{gridOpts_Demographics.changeRowTemplate(dataItem.index, 1, dataItem.county.age65to74)}}'
        }, {
          field: "county.age75plus",
          title: "75+ yrs.",
          headerTemplate: "75+<br>yrs.",
          attributes: {
            'class': 'text-right customKendo demographics'
          },
          template: '{{gridOpts_Demographics.changeRowTemplate(dataItem.index, 2, dataItem.county.age75plus)}}'
        }]
      }, {
        title: "Metro",
        columns: [{
          field: "metro.age45to64",
          title: "45-64 yrs.",
          headerTemplate: "45-64<br>yrs.",
          attributes: {
            'class': 'text-right customKendo demographics'
          },
          template: '{{gridOpts_Demographics.changeRowTemplate(dataItem.index, 0, dataItem.metro.age45to64)}}'
        }, {
          field: "metro.age65to74",
          title: "65-74 yrs.",
          headerTemplate: "65-74<br>yrs.",
          attributes: {
            'class': 'text-right customKendo demographics'
          },
          template: '{{gridOpts_Demographics.changeRowTemplate(dataItem.index, 1,dataItem.metro.age65to74)}}'
        }, {
          field: "metro.age75plus",
          title: "75+ yrs.",
          headerTemplate: "75+<br>yrs.",
          attributes: {
            'class': 'text-right customKendo demographics'
          },
          template: '{{gridOpts_Demographics.changeRowTemplate(dataItem.index, 2, dataItem.metro.age75plus)}}'
        }]
      }, {
        title: "National",
        columns: [{
          field: "national.age45to64",
          title: "45-64 yrs.",
          headerTemplate: "45-64<br>yrs.",
          attributes: {
            'class': 'text-right customKendo demographics'
          },
          template: '{{gridOpts_Demographics.changeRowTemplate(dataItem.index, 0, dataItem.national.age45to64)}}'
        }, {
          field: "national.age65to74",
          title: "65-74 yrs.",
          headerTemplate: "65-74<br>yrs.",
          attributes: {
            'class': 'text-right customKendo demographics'
          },
          template: '{{gridOpts_Demographics.changeRowTemplate(dataItem.index, 1, dataItem.national.age65to74)}}'
        }, {
          field: "national.age75plus",
          title: "75+ yrs.",
          headerTemplate: "75+<br>yrs.",
          attributes: {
            'class': 'text-right customKendo demographics'
          },
          template: '{{gridOpts_Demographics.changeRowTemplate(dataItem.index, 2, dataItem.national.age75plus)}}'
        }]
      }];

      if ($scope.sirReport.tradeAreaData.shape2.hasShape) {
        tradeAreasData.unshift($scope.sirReport.tradeAreaData.shape2);
        dynamicColumns.unshift({
          title: $scope.sirReport.tradeAreaData.shape2.name,
          columns: [{
            field: "tradeAreaTwo.age45to64",
            title: "45-64 yrs.",
            headerTemplate: "45-64<br>yrs.",
            attributes: {
              'class': 'text-right customKendo demographics'
            },
            template: '{{gridOpts_Demographics.changeRowTemplate(dataItem.index, 0, dataItem.tradeAreaTwo.age45to64)}}'
          }, {
            field: "tradeAreaTwo.age65to74",
            title: "65-74 yrs.",
            headerTemplate: "65-74<br>yrs.",
            attributes: {
              'class': 'text-right customKendo demographics'
            },
            template: '{{gridOpts_Demographics.changeRowTemplate(dataItem.index, 1, dataItem.tradeAreaTwo.age65to74)}}'
          }, {
            field: "tradeAreaTwo.age75plus",
            title: "75+ yrs.",
            headerTemplate: "75+<br>yrs.",
            attributes: {
              'class': 'text-right customKendo demographics'
            },
            template: '{{gridOpts_Demographics.changeRowTemplate(dataItem.index, 2, dataItem.tradeAreaTwo.age75plus)}}'
          }]
        });
      }

      if ($scope.sirReport.tradeAreaData.shape1.hasShape) {
        tradeAreasData.unshift($scope.sirReport.tradeAreaData.shape1);
        dynamicColumns.unshift({
          title: $scope.sirReport.tradeAreaData.shape1.name,
          columns: [{
            field: "tradeAreaOne.age45to64",
            title: "45-64 yrs.",
            headerTemplate: "45-64<br>yrs.",
            attributes: {
              'class': 'text-right customKendo demographics'
            },
            template: '{{gridOpts_Demographics.changeRowTemplate(dataItem.index, 0, dataItem.tradeAreaOne.age45to64)}}'
          }, {
            field: "tradeAreaOne.age65to74",
            title: "65-74 yrs.",
            headerTemplate: "65-74<br>yrs.",
            attributes: {
              'class': 'text-right customKendo demographics'
            },
            template: '{{gridOpts_Demographics.changeRowTemplate(dataItem.index, 1, dataItem.tradeAreaOne.age65to74)}}'
          }, {
            field: "tradeAreaOne.age75plus",
            title: "75+ yrs.",
            headerTemplate: "75+<br>yrs.",
            attributes: {
              'class': 'text-right customKendo demographics'
            },
            template: '{{gridOpts_Demographics.changeRowTemplate(dataItem.index, 2, dataItem.tradeAreaOne.age75plus)}}'
          }]
        });
      }
      
      $scope.tradeAreas = {};
      $scope.tradeAreas.data = tradeAreasData;
      $scope.tradeAreas.selected = tradeAreasData[0]; // trade area 1
     
      dynamicColumns.unshift({ 
        field: "name", 
        title: " ",
        attributes: {
          'class': 'space-nowrap customKendo demographics'
        }
      });

      $scope.gridOpts_Demographics = {
        columns: dynamicColumns, 
        dataSource: new kendo.data.DataSource({
          data: $scope.demographicData,
          error: function (err) {
            console.log('error - ' + err);
          },
          serverPaging: false,
          serverFiltering: false,
          serverSorting: false
        }),
        scrollable: false,
        sortable: false,
        pageable: false,
        changeRowTemplate: function (rowIndex, colIndex, value) {
          switch (rowIndex) {
            case 0:
              return kendo.format("{0:n0}", value);
            case 1:
              return kendo.format("{0:p}", value);
            case 2:
              return kendo.format("{0:p}", value);
            case 3:
              return kendo.format("{0:c0}", value);
            case 4:
              if (colIndex == 1) {
                return kendo.format("{0:c0}", value);
              }
          };
        }
      };
    };

    init();
    initDemographicsGrid();
    refreshClicked();

    function refreshClicked() {
      if (!isValid()) return;

      customCohortSelectionTracker = {
        sirQueryId: $state.params.id,
        tradeArea: $scope.tradeAreas.selected,
        minAge: _.find($scope.minAgeBand.data, function (t) { return t.id == _.toNumber($scope.minAgeBand.selected); }).minAge,
        maxAge: _.find($scope.maxAgeBand.data, function (t) { return t.id == _.toNumber($scope.maxAgeBand.selected); }).maxAge,
        minIncome: _.find($scope.minIncome.data, function (t) { return t.id == _.toNumber($scope.minIncome.selected); }).minimumIncome
      };

      SiteInformationReportService
        .getCohortData(customCohortSelectionTracker)
        .then(function (cohortData) {
          $scope.numHousehold = cohortData.cohortHouseholds;
          $scope.pctGrowthRate = cohortData.growthRate;
          $scope.pctHousehold = cohortData.pctOfTotalHousholds;
          $scope.numIncome = cohortData.medianIncome;
        }, function (err) {
          console.log(err);
        });
    }

    function init() {
     DataAttributionService.getAttributionHtml()
     .then(function (htmlString) {
       $scope.AttributionHtml = $sce.trustAsHtml(htmlString);
     })
     .catch(function(error) {
       console.error(error);
      });
    }

    function isValid() {
      // return false to make error visible
      var minAgeRow = _.find($scope.minAgeBand.data, function (obj) { return obj.id.toString() === $scope.minAgeBand.selected });
      var maxAgeRow = _.find($scope.maxAgeBand.data, function (obj) { return obj.id.toString() === $scope.maxAgeBand.selected });
      if (minAgeRow && maxAgeRow) {
        if(minAgeRow.minAge < maxAgeRow.maxAge) {
          return true;
        }
      return false;
      }
    }

    function exportClicked() {
      var title =  $scope.sirReport.areaAccessed + ' Metro: ' + $scope.sirReport.cbsaName + ' County: ' + $scope.sirReport.countyName;
      title = _.replace(title, /'/g, "");
      var tradeAreaData = angular.copy($scope.sirReport.tradeAreaData);
      // I was unable to map $scope.sirReport.tradeAreaData directly to the TradeAreaDataViewModel on the C# side
      // [FromBody] kept the view model null when the shape1 and shape2 were attached to the tradeAreaData.
      // so to get across it... I deleted the shape first and then reattached them back.  Ugly but works...
      delete tradeAreaData.shape1;
      delete tradeAreaData.shape2;
      tradeAreaData.shape1 = {
        radius: $scope.sirReport.tradeAreaData.shape1.radius,
        driveTime: {
          breakValue: $scope.sirReport.tradeAreaData.shape1.driveTime.breakValue,
          trafficFlow: $scope.sirReport.tradeAreaData.shape1.driveTime.trafficFlow
        },
        hasShape: $scope.sirReport.tradeAreaData.shape1.hasShape
      };
      tradeAreaData.shape2 = {
        radius: $scope.sirReport.tradeAreaData.shape2.radius,
        driveTime: {
          breakValue: $scope.sirReport.tradeAreaData.shape2.driveTime.breakValue,
          trafficFlow: $scope.sirReport.tradeAreaData.shape2.driveTime.trafficFlow
        },
        hasShape: $scope.sirReport.tradeAreaData.shape2.hasShape
      };
      var dataToPass = {
        propertyName: $scope.sirReport.areaAccessed,
        latitude: $scope.sirReport.latitude,
        longitude: $scope.sirReport.longitude,
        countyFIPS: $scope.sirReport.countyFips,
        cbsaCode: $scope.sirReport.cbsaCode,
        tradeArea: tradeAreaData,
        customCohort: customCohortSelectionTracker   // cohort selections
      };

      PropertyDetailsService
        .exportCustomCohort(title, dataToPass)
        .then(function (data) {
          var excelFile = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          });
          var fileName = title.replace(/ /g, '_') + '.xlsx';
          FileSaver.saveAs(excelFile, fileName);
        });
    }
  }
})();
