(function() {
  "use strict";
  app.directive('quickPropertySearch', QuickPropertySearchDirective);
  function QuickPropertySearchDirective() {
    return {
      templateUrl: '/app/partials/quickSearch.html',
      controllerAs: 'quickSearch',
      controller: QuickPropertySearchController,
      scope: {}
    }
  }

  QuickPropertySearchController.$inject = ['$scope','$state', 'PopupHandlerService', 'AuditService', 'knownEventConstant'];
  function QuickPropertySearchController($scope, $state, PopupHandlerService, AuditService, knownEventConstant) {
    var vm = this;
    vm.searchText = null;

    vm.search = function() {
      if(!vm.searchText) {
        PopupHandlerService.openSimplePopup("Please fill in search.");
        return;
      }
      AuditService.logEvent(knownEventConstant.navLocalSearch.id, knownEventConstant.navLocalSearch.message + " - " + vm.searchText);
      $state.go("localpropertysearch.base", {
        query: vm.searchText
      });
    }
  }
})();
