(function() {
  "use strict";

  app.controller('AdminPortfolioDetailsController', AdminPortfolioDetailsController);
  AdminPortfolioDetailsController.$inject = ['$scope', '$state', '$rootScope', '$uibModal', 'portfolio', 'AdminPortfolioService',
    'PopupHandlerService', 'SearchService', 'TrendsService', 'SubscriptionUsersService', 'SpinnerService', 'NaturalSort'];

  function AdminPortfolioDetailsController($scope, $state, $rootScope, $uibModal, portfolio, AdminPortfolioService,
    PopupHandlerService, SearchService, TrendsService, SubscriptionUsersService, SpinnerService, NaturalSort) {
    $rootScope.hideLeftNav = true;
    $scope.portfolio = portfolio;
    $scope.getContacts = getContacts;
    $scope.savePortfolioInfo = savePortfolioInfo;
    $scope.toggleEditingState  = toggleEditingState;
    $scope.deletePortfolioModal = deletePortfolioModal;
    $scope.selectPropertiesgridOpts = buildGridOptions(true, 'grid'); //bottom grid
    $scope.selectPropertyResultsOptions = buildGridOptions(false, 'results'); //top grid
    $scope.searchNewProperties = function() {
      $scope.selectPropertyResultsOptions.dataSource.read(); //click search only needs to call the kendodataSource.read()
    }
    $scope.editingState = false;
    SpinnerService.start();
    $scope.resetGrid = resetGrid;
    $scope.addProperties = addProperties;
    $scope.toggleAll = toggleAll;
    $scope.addCommaProperties = addCommaProperties;
    $scope.searchStakeHolders = SearchService.searchStakeHolders;
    $scope.compFilters = {
      distance: 10.10,
      minCompetitorCount: 5,
      maxCompetitorCount: 20
    };
    $scope.isAlreadyInPortfolio = isAlreadyInPortfolio;
    $scope.portfolioTypeOptions = portfolioTypeOptions(angular.copy(portfolio.portfolioTypes));
    $scope.wasUpdatedFromWatch = false;
    $scope.refreshPortfolio = refreshPortfolio;

    $scope.modifiedBy = function() {
      if($scope.portfolio.modifiedContact) {
        return $scope.portfolio.modifiedContact.firstName + " " + $scope.portfolio.modifiedContact.lastName;
      }
      return "";
    }

    $scope.cbsaChanged = function(e) {
      $scope.search.countyId = "";
      $scope.countiesForCBSA.read();
    };

    //initalization
    $scope.search = {};

    function portfolioTypeOptions(types) {
      var result = types;
      if($scope.portfolio.type.name == "Master" || $scope.portfolio.type.name == "Custom") { //Filter subportfolio
        result = _.pullAllBy(types, [{'name': 'Sub Portfolio'}],'name');
      }
      if($scope.portfolio.type.name == "Sub Portfolio") { //filter Master and Custom
        result = _.pullAllBy(types, [{'name': 'Master'},{'name': 'Custom'}],'name');
      }
      if($scope.portfolio.type.name == "Deleted") { //if had master portfolio only allow reenabling to sub, otherwise limit to custom or master
        if($scope.portfolio.isMaster == false) {
          result = _.pullAllBy(types, [{'name': 'Master'}, {'name': 'Custom'}], 'name');
        } else {
          result = _.pullAllBy(types, [{'name': 'Sub Portfolio'}], 'name');         
        }
      }
      return result;
    };

    function savePortfolioInfo() {
      SpinnerService.start();
      $scope.portfolio.newOwnerContactId = $scope.portfolio.owner.contactId;      
      $scope.portfolio.newOwnerSubscriptionId = $scope.portfolio.owner.subscriptionId;
      AdminPortfolioService.savePortfolioDetails($scope.portfolio).then(function() {
        AdminPortfolioService.getPortfolioData(portfolio.id).then(function(portfolio) {
          $scope.portfolio = portfolio;
          $scope.oldPortfolioInfo = null;
          toggleEditingState();
          SpinnerService.stop();
        });
      }, function(fail) {
        SpinnerService.stop();
      });
    }
 
    function toggleEditingState() {
      $scope.editingState = !$scope.editingState;
      if($scope.editingState  == true) { 
        //temp store old data for potential cancel of edit
        $scope.oldPortfolioInfo = angular.copy($scope.portfolio);
      } else {
        if($scope.oldPortfolioInfo) {
          //reset to old data "CANCEL"
          $scope.portfolio = angular.copy($scope.oldPortfolioInfo);
        }
      }
    }

    var getPropertiesPromise = AdminPortfolioService.properties(portfolio.id);
    getPropertiesPromise.finally(function() {
      SpinnerService.stop();
    });

    $scope.allCBSAs = new kendo.data.DataSource({
      transport: {
        read: function(options) {
          TrendsService.getFilteredMetros().then(function(data) {
            options.success(data);
          });
        }
      }
    });

    $scope.countiesForCBSA = new kendo.data.DataSource({
      transport: {
        read: function(options) {
          TrendsService.getFilteredCounties($scope.search.cbsaCode)
            .then(function(counties) {
              options.success(counties);
            });
        }
      }
    });

    //hoisting section
    function getContacts() {
      SpinnerService.start();
      SubscriptionUsersService
        .getSubscriptionContacts($scope.portfolio.company.subscriptionId, true)
        .then(function (rsp) {
          $scope.portfolio.companyContacts = rsp;
          $scope.portfolio.owner = rsp[0];
          SpinnerService.stop();
        }, function(err) {
          SpinnerService.stop();
        });
    };

    function deletePortfolioModal(id) {
      //can't do '_.find({id:id});' because kendo stores id as string and subjectProperty.id is a number. Then lodash doesn't match that. bug?
      $scope.subjectProperty = _.find($scope.selectPropertiesgridOpts.dataSource.data(), function(property) {
        return property.id == id;
      });
      $uibModal.open({
        animation: true,
        templateUrl: 'app/partials/adminSubjectPropertyDelete.html',
        size: 10,
        scope: $scope
      }).result.then(deleteSubjectProperty);
    };

    function resetGrid(gridOptions) {
      gridOptions.dataSource.filter([]);
      gridOptions.dataSource.sort({
        field: "name",
        dir: "asc"
      });
    }
    var getPropertiesPromise;

    function buildDataSource(forSubjectProperties, gridId) {
      var subjectPropertyGridRead = function(opts) {
        Nic.kendoGrid.filtersOnTop(gridId); //really only needs called the first time
        if(forSubjectProperties) {
          getPropertiesPromise.then(function(data) {
            var isSelected = _.filter(data, 'isSelected');
            $scope.numberSubjectProperties = isSelected.length;
            opts.success(isSelected);
          });
        } else {
          if($scope.portfolio.isMaster) {
            SpinnerService.start();
            $scope.searchErrors = null;//clear previous error message
            AdminPortfolioService.searchNewPropertiesNoComps($scope.search).then(
              function(data) {
                var properties = data;
                $scope.numberAddableProperties = properties.length;
                //$scope.searchErrors = data;
                opts.success(properties);
              },
              function(error) {
                $scope.numberAddableProperties = 0;
                displayError(error);
                opts.success([]);
              }).finally(function() {
              SpinnerService.stop();
            });
          } else {
            getPropertiesPromise.then(function(data) {
              var isSelected = _.filter(data, function(item) {
                return !item.isSelected;
              });
              $scope.numberAddableProperties = isSelected.length;
              opts.success(isSelected);
            });
          }
        }
      }
      return subjectPropertyGridRead;
    }


    function deleteSubjectProperty() {
      var removedProperty = $scope.subjectProperty;

      SpinnerService.start();
      AdminPortfolioService.deleteSubjectProperty(portfolio.id, $scope.subjectProperty.id).then(function() {
          $scope.numberSubjectProperties = $scope.numberSubjectProperties - 1;

          removedProperty.isSelected = false;
          $scope.selectPropertiesgridOpts.dataSource.remove(removedProperty);
          if(!$scope.portfolio.isMaster) {
            //for sub portfolio we can re-add property to top table
            $scope.numberAddableProperties = $scope.numberAddableProperties + 1;
            $scope.selectPropertyResultsOptions.dataSource.add(removedProperty);
          }

          SpinnerService.stop();
          refreshPortfolioData();
        },
        function () {
          SpinnerService.stop();
          PopupHandlerService.openSimplePopup("Delete failed on subject property: " + $scope.subjectProperty.name + ". Please contact an administrator.");
        });
    }

    function addProperties() {
      var kendoAddedProperties = [];
      var propertyList = _.chain($scope.selectPropertyResultsOptions.dataSource.view())
        .filter(function(propertiesInTop) {
          if(!propertiesInTop.isSelected) {
            return false;
          }
          if(isAlreadyInPortfolio(propertiesInTop)) {
            return false;
          }
          return true;
        })
        .map(function(property) {
          kendoAddedProperties.push(property);
          return  property.id;
        }).value();
      //above lodash builds 2 data structures...
      //1 for calling insert API (propertyList) and 1 for adding the property to the bottom table (kendoAddedProperties)
      SpinnerService.start();
      AdminPortfolioService.addCommaProperties($scope.compFilters,portfolio.id, propertyList.toString()).then(function(rsp) {
          //update comp value for bottom grid
          if(rsp.errors.length == 0)
          {
            kendoAddedProperties = _.merge(kendoAddedProperties,rsp.properties);
          }

          //you could re-call the API to update the bottom table
          //but you can't re-call the API to update the upper table because if it's a master portfolio, the upper table isn't actually in the DB
          _.each(kendoAddedProperties, function(addedProperty) {
            $scope.selectPropertiesgridOpts.dataSource.add(addedProperty);
            if(!$scope.portfolio.isMaster) {
              //if not Master we remove from top table. For Master is will auto-magically remove checkbox.
              $scope.selectPropertyResultsOptions.dataSource.remove(addedProperty);
              $scope.numberAddableProperties = $scope.numberAddableProperties - 1
            }
          });
          $scope.numberSubjectProperties = $scope.numberSubjectProperties + kendoAddedProperties.length;

          refreshPortfolioData();
        })
        .finally(function() {
          SpinnerService.stop();
        });
    }

    function toggleAll(dataItem) {
      var selected = dataItem.isSelected;
      var rows = $scope.selectPropertyResultsOptions.dataSource.view();
      for(var i = 0; i < rows.length; i++) {
        rows[i].isSelected = selected;
      }
    }

    function addCommaProperties() {
      $scope.commaError = false;
      SpinnerService.start();
      AdminPortfolioService.addCommaProperties($scope.compFilters, portfolio.id, $scope.commaDelimitedProperties).then(
        function(data) {
          var properties = data.properties;
          $scope.numberSubjectProperties = $scope.numberSubjectProperties + properties.length;

          _.forEach(properties, function(addedProperty) {
            $scope.selectPropertiesgridOpts.dataSource.add(addedProperty);
          });

          refreshPortfolioData();
          // if(data.errors && data.errors.length){
          //   var error = data.errors[0];
          //   PopupHandlerService.openSimplePopup("PropertyID(s) "+error.propertyIds+" could not be added because of the following error: "+error.errorMessage);
          // }
        },
        function(error) {
          if(!displayError(error)) {
            $scope.commaError = true;
            //display message above the field in red:
            //The Property ID's listed cannot be added. Enter them in a comma separated list to continue.
          }
        }
      ).finally(function(){
        SpinnerService.stop();
      });
    }

    function displayError(error) {
      if(!error) {
        return false;
      }
      if(angular.isString(error.data)) { //if not string something bad happened
        PopupHandlerService.openSimplePopup(error.data);
        return true;
      }
      if(error.status) {
        PopupHandlerService.openSimplePopup("unknown error occured code:" + error.status);
        return true;
      }
      return false;
    }

    function isAlreadyInPortfolio(currentProperty) {
      if(_.some($scope.selectPropertiesgridOpts.dataSource.data(), function(propertyInPortfolio) {
          return propertyInPortfolio.id == currentProperty.id;
        })) {
        return true;
      }
      return false;
    }

    function buildGridOptions(forSubjectProperties, gridId) {
      var columns = [{
        field: "id",
        title: "Property ID",
        filterable: {
          cell: {
            showOperators: false,
            operator: "contains"
          }
        }
      }, {
        field: "name",
        title: "Property Name",
        filterable: {
          cell: {
            showOperators: false
          }
        }
      }, {
        field: "type",
        title: "Property Type",
        filterable: {
          cell: {
            showOperators: false,
            operator: "contains"
          }
        }
      }, {
        field: "addressLine1",
        title: "Address",
        filterable: {
          cell: {
            showOperators: false,
            operator: "contains"
          }
        },
        sortable: {
          compare: addressCompare
        }
      }, {
        field: "city",
        title: "City",
        filterable: {
          cell: {
            showOperators: false,
            operator: "contains"
          }
        }
      }, {
        field: "countyName",
        title: "County",
        filterable: {
          cell: {
            showOperators: false,
            operator: "contains"
          }
        }
      }, {
        field: "stateProvinceCD",
        title: "State",
        filterable: {
          cell: {
            showOperators: false,
            operator: "contains"
          }
        }
      }, {
        field: "zipCode",
        title: "ZIP",
        filterable: {
          cell: {
            showOperators: false,
            operator: "contains"
          }
        }
      }, {
        field: "stakeholders",
        title: "Stakeholder",
        hidden: true,
        filterable: {
          cell: {
            showOperators: false,
            operator: "contains"
          }
        }
      }, {
        field: "operator",
        title: "Operator",
        filterable: {
          cell: {
            showOperators: false,
            operator: "contains"
          }
        },
        template: "#= (operator && operator != 'Unknown Stakeholder') ? kendo.toString(operator) : '' #",
      }, {
        field: "owner",
        title: "Owner",
        filterable: {
          cell: {
            showOperators: false,
            operator: "contains"
          }
        },
        template: "#= (owner && owner != 'Unknown Stakeholder') ? kendo.toString(owner) : '' #",
      }];
      if(forSubjectProperties) {
        columns.splice(1,1, {
          field: "name",
          title: "Property Name",
          template: '<a ui-sref="#= \'admin.portfolios.details.editcomps({ detailsId: \' + id + \' })\' #">#= name #</a>',
          filterable: {
            cell: {
              showOperators: false
            }
          }
        });
      }
      if(forSubjectProperties) {
        columns.push({
          field: "compCount",
          title: "Comps",
          template: '<span class= "bold" ng-class="{green : !portfolio.needsToBeRefreshed && dataItem.compsMeetProtections, \'bright-red\': !portfolio.needsToBeRefreshed && !dataItem.compsMeetProtections}">{{dataItem.compCount}}<span>',
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        });
      };
      if(forSubjectProperties) {
        columns.push({
          template: '<a class="manageDelete" ng-click="deletePortfolioModal(#= id #)"> </a>',
          title: "Delete",
          filterable: false,
          attributes: {
            'style': 'text-align: center;'
          }
        });
      } else {
        //insert column at begininng
        columns.splice(0, 0, {
          field: "",
          title: "",
          template: '<input type="checkbox" ng-model="dataItem.isSelected" ng-hide="isAlreadyInPortfolio(dataItem)" />',
          headerTemplate: '<input type="checkbox" ng-model="dataItem.isSelected" ng-change="toggleAll(dataItem)" style="margin-left: 7px;" />'
        });
      }

      return {
        columns: columns,
        dataSource: new kendo.data.DataSource({
          transport: {
            read: buildDataSource(forSubjectProperties, gridId)
          },
          error: function(err) {
            console.log('error - ' + err);
          },
          schema: {
            model: {
              fields: {
                'id': { type: 'number' },
                'name': { type: 'string' },
                'type': { type: 'string' },
                'addressLine1': { type: 'string' },
                'city': { type: 'string' },
                'countyName': { type: 'string' },
                'stateProvinceCD': { type: 'string' },
                'zipCode': { type: 'string' },
                'stakeholders': { type: 'string' },
                'compCount': { type: 'number' },
                'operator': { type: 'string' },
                'owner': { type: 'string' }
              }
            }
          },
          pageSize: 20,
          serverPaging: false,
          serverFiltering: false,
          serverSorting: false,
          sort: {
            field: "name",
            dir: "asc"
          }
        }),
        filterable: {
          mode: "row"
        },
        scrollable: false,
        sortable: {
          mode: "single",
          allowUnsort: false
        },
        pageable: true
      }
    }

    function refreshPortfolio() {
      SpinnerService.start();
      AdminPortfolioService
        .refreshPortfolio(portfolio.id)
        .then(function (rsp) {
          SpinnerService.stop();
          $state.go('admin.portfolios.details', { id: portfolio.id }, { reload: true });
        }, function (err) {
          SpinnerService.stop();
        });
    }

    function refreshPortfolioData() {
      SpinnerService.start();
      AdminPortfolioService.getPortfolioData($scope.portfolio.id)
        .then(function (data) {
          //$scope.portfolio = data;
          $scope.portfolio.percentCompleted = data.percentCompleted;
          $scope.portfolio.lastRefreshed = data.lastRefreshed;
          $scope.portfolio.dateModified = data.dateModified;

          $scope.portfolio.modifiedContact = data.modifiedContact;
          $scope.portfolio.needsToBeRefreshed = data.needsToBeRefreshed;
        })
      .finally(function () {
        SpinnerService.stop();
      });
    }

    function addressCompare(a, b) {
      return NaturalSort.compare(a.addressLine1, b.addressLine1);
    }
  }
})();