(function () {
	"use strict";
  app.directive('propertyListHalfItem', PropertyListHalfItemDirective);

  function PropertyListHalfItemDirective () {
    return {
      restrict: 'E',
      templateUrl: '/app/partials/local/propertyListHalfItem.html',
      controller: PropertyListHalfItemController,
      controllerAs: 'item',
      require: '^propertyListHalf',
      scope: {},
      bindToController: {
        propertyDetailsClick: "<propertyDetailsClick",
        property: '=property',
        searchFilter: '<searchFilter',
        propertySearch: '<propertySearch'
      }
    };
  };

  PropertyListHalfItemController.$inject = ['$scope', '$sce','AuditService', 'knownEventConstant', 'knownProductConstant', 'vintageTypeConstant', 'PropertyAdvisorService', 'PopupHandlerService', 'SpinnerService'];
  function PropertyListHalfItemController ($scope, $sce, AuditService, knownEventConstant, knownProductConstant, vintageTypeConstant, PropertyAdvisorService, PopupHandlerService, SpinnerService) {
    var vm = this;
    var popoverContent = {};
    
    vm.autoParPopoverMsg = 
      'Run an automated Property Advisor Report that displays aggregated rate and occupancy benchmarks for nearby properties with the same care segment(s).';
    vm.selectProperty = selectProperty;
    vm.expandButtonToggle = expandButtonToggle;
    vm.propertyPopoverContent = propertyPopoverContent;
    vm.highlightSearchText = highlightSearchText;
    vm.getWalkScore = getWalkScore;
    vm.generatePAR = generatePAR;
    vm.isThreeMonthRolling = isThreeMonthRolling;

    $scope.$watch(function() {
        return vm.property.searchMatch;
      }, function () {
        if (vm.property.searchMatch == "full") {
          $scope.$emit('highlightProperty', vm.property);
        }
    });

    function selectProperty () {
      if (vm.property.selected){
        AuditService.logEvent(knownEventConstant.selectProperty.id, knownEventConstant.selectProperty.message + " - " + vm.property.name, determineLogProduct(), vm.property.id);
      }
      $scope.$emit("propertySelectionsChangedViaPropertyListHalf", { sourceProperty: vm.property, isSelected: vm.property.selected });
    }

    function expandButtonToggle () {
      vm.property.expanded = !vm.property.expanded;
      $scope.$emit("expandButtonToggle");
    }

    function propertyPopoverContent(property) {
      var content = (property.units.ilUnits != "0" ? '<b>IL:</b> ' + property.units.ilUnits : '')
        + (property.units.alUnits != "0" ? ' <b>AL:</b> ' + property.units.alUnits : '')
        + (property.units.mcUnits != "0" ? ' <b>MC:</b> ' + property.units.mcUnits : '')
        + (property.units.ncUnits != "0" ? ' <b>NC:</b> ' + property.units.ncUnits : '');
       return popoverContent[content] || (popoverContent[content] = $sce.trustAsHtml(content));
    }

    function highlightSearchText (search) {
      if (!search.query) {
        return $sce.trustAsHtml(vm.property.name);
      }
      if (_.isEqual(vm.property,search.matches[search.currentMatchIndex])) {
        return $sce.trustAsHtml(vm.property.name.replace(new RegExp(search.query, 'gi'), '<span class="selectedHighlightedText">$&</span>'));
      }
      return $sce.trustAsHtml(vm.property.name.replace(new RegExp(search.query, 'gi'), '<span class="highlightedText">$&</span>'));
    }

    function determineLogProduct () { 
      return vm.searchFilter().transactionOnly ? knownProductConstant.trans : knownProductConstant.local;
    }

    function getWalkScore() {
      if (vm.property.walkScoreInfo 
        && vm.property.walkScoreInfo.value 
        && vm.property.walkScoreInfo.value.status == 1) {
        return vm.property.walkScoreInfo.value.walkScore;
      }
      return "N/A";
    }

    function generatePAR(){
      if(isThreeMonthRolling())
        return;
        
      SpinnerService.start();
      var propertyData = {
        propertyId: vm.property.id,
        latitude: vm.property.latitude,
        longitude: vm.property.longitude,
        segments: vm.property.segmentTypes.toString(),
        reportName: vm.searchFilter().query
      }
      
      PropertyAdvisorService
        .autoGeneratePAR(propertyData)
        .then(function(reportGuid){
          PopupHandlerService.openReportInNewTab('propertyAdvisor', reportGuid, 'Property Advisor Report');
          AuditService.logEvent(knownEventConstant.propertyListAutoPAR.id, knownEventConstant.propertyListAutoPAR.message + " - " + vm.property.name, knownProductConstant.local, vm.property.id);
          SpinnerService.stop();
        }, function(error) {
          PopupHandlerService.openSimplePopup(error.data);
          SpinnerService.stop();
        });
    }

    function isThreeMonthRolling() {
      if (isNaN(vm.searchFilter().search.vintageId)) return false;
      var isThreeMonthRolling = parseInt(vm.searchFilter().search.vintageId) == vintageTypeConstant.threeMonthRolling;
      if (isThreeMonthRolling)
        vm.autoParPopoverMsg = 'Property Advisor Report functionality is not available for the three-month rolling reporting period type.';
      return isThreeMonthRolling;
    }
  };
})();
