(function () {
  "use strict";
  app.directive('propertyListFull', PropertyListFullDirective);

  function PropertyListFullDirective () {
    return {
      restrict: 'E',
      templateUrl: '/app/partials/local/propertyListFull.html',
      controller: PropertyListFullController,
      controllerAs: 'list',
      scope: {},
      bindToController: {
        exportSearchResultClick: '<exportSearchResultClick',
        propertyDetailsClick: '<propertyDetailsClick',
        propertySelectedCount: '<propertySelectedCount',
        stakeholderSelectedCount: '<stakeholderSelectedCount',
        properties: '=properties',
        currentSort: '<currentSort',
      }
    };
  };

  PropertyListFullController.$inject = ['$scope', 'AuditService', 'knownEventConstant', 'knownProductConstant'];
  function PropertyListFullController ($scope, AuditService, knownEventConstant, knownProductConstant) {
    var vm = this;

    vm.$onInit = init;
    vm.$onDestroy = destroy;
    vm.selectProperty = selectProperty;
    vm.selectAllProperties = selectAllProperties;
    
    vm.propertyGrid = {};
    vm.gridOptions = {};
    vm.unitTotal = 0;
    vm.unitTotalAL = 0;
    vm.unitTotalIL = 0;
    vm.unitTotalMC = 0;
    vm.unitTotalNC = 0;
    vm.propertySelectedCount = 0;
    vm.stakeholderSelectedCount = 0;

    $scope.$watchCollection(function() { return vm.properties.items; }, refreshOnSearch);

    function init() {
      $(window).on("resize", function() {
        kendo.resize($(".property-grid"));
      });

      vm.gridOptions = initGrid({ field: vm.currentSort.field, dir: vm.currentSort.dir });
    }

    function destroy() {
      $(window).off("resize", function() {
        kendo.resize($(".property-grid"));
      });
    }

    function refreshOnSearch(newVal, oldVal) {
      if(newVal != oldVal){
        vm.gridOptions.dataSource.read();
        vm.propertyGrid.setOptions(initGrid());
      }
      calculateUnitTotals();
    }

    function selectProperty(property) {
      // Because the kendo grid item isn't the actual item, I need to bind it back to the actual item. Alternative solutions welcome!!!
      vm.properties.items[_.findIndex(vm.properties.items, ['id', property.id])].selected = property.selected;

      if (property.selected){
        AuditService.logEvent(knownEventConstant.selectProperty.id, knownEventConstant.selectProperty.message + " - " + property.name, determineLogProduct(), property.id);
      }
      $scope.$emit("propertySelectionsChangedViaPropertyListFull", { sourceProperty: property, isSelected: property.selected });
    }

    function selectAllProperties() {
      // TODO: Move the checkAll function from LPSC into a separate service for manipulating the property list. That way it can be imported into LPSC and here and PropertyListHalf
      $scope.$parent.$parent.checkAll(); // not ideal code
      vm.gridOptions.dataSource.read();
    }

    function calculateUnitTotals() {
      vm.unitTotalAL = _.sumBy(vm.properties.items, function(o) { return _.toNumber(o.units.alUnits); });
      vm.unitTotalIL = _.sumBy(vm.properties.items, function(o) { return _.toNumber(o.units.ilUnits); });
      vm.unitTotalMC = _.sumBy(vm.properties.items, function(o) { return _.toNumber(o.units.mcUnits); });
      vm.unitTotalNC = _.sumBy(vm.properties.items, function(o) { return _.toNumber(o.units.ncUnits); });
      vm.unitTotal = _.sumBy(vm.properties.items, function(o) { return _.toNumber(o.units.total); });
    }

    function isTransactionSearch() {
      return vm.properties && vm.properties.items && _.some(vm.properties.items, { 'isTransactionSearch': true});
    }

    function determineLogProduct () { 
      return isTransactionSearch() ? knownProductConstant.trans : knownProductConstant.local;
    } 

    // Sort by the field given, then by pinNumber in ascending order
    function customCompare(a, b, descending, field) {
      var dir = descending ? -1 : 1; 
      var valueA = _.get(a, field);
      var valueB = _.get(b, field);
      var pinA = _.get(a, 'pinNumber');
      var pinB = _.get(b, 'pinNumber');

      if(_.isEqual(valueA, valueB)) {
        if(_.isEqual(pinA, pinB)) {
          return 0;
        } else {
          return _.gt(pinA, pinB) ? 1 * dir : -1 * dir;
        }
      } else {
        if(_.isNil(valueA)) return -1;
        if(_.isNil(valueB)) return 1;
        return _.gt(valueA, valueB) ? 1 : -1;
      }
    }

    function initGrid(initialSort) {
      var dataSourceSorting = initialSort || {field: 'pinNumber', dir: 'asc'};

      return {
        columns: [
          {
            title: 'Selected',
            field: 'selected',
            template: '<input type="checkbox" ng-model="dataItem.selected" ng-show="::dataItem.id" ng-click="list.selectProperty(dataItem)"/>',
            headerTemplate: '',
            width: '2%',
            sortable: {
              compare: function(a, b, descending) {
                return customCompare(a, b, descending, 'selected');
              }
            }
          }, {
            title: 'Map Pin No.',
            field: 'pinNumber',
            template: '{{::dataItem.pinNumber}}.',
            headerTemplate: "<span>Map <br/> Pin No.</span>",
            attributes: {
              class: 'text-left',
            },
            width: '5%',
          }, {
            title: 'Property Name',
            field: 'name',
            template: '<a ng-show="{{::dataItem.id}}" ng-click="list.propertyDetailsClick(dataItem)" target="_blank">{{::dataItem.name}}</a><span ng-hide="{{::dataItem.id}}">{{::dataItem.name}}</span>',
            width: '15%',
            sortable: {
              compare: function(a, b, descending) {
                return customCompare(a, b, descending, 'name');
              }
            }
          }, {
            title: 'Address',
            field: 'addressLine1',
            template: '{{::dataItem.addressLine1}} {{::dataItem.city}}, {{::dataItem.stateProvinceCode}} {{::dataItem.zipCode}}',
            width: '15%',
            sortable: {
              compare: function(a, b, descending) {
                return customCompare(a, b, descending, 'addressLine1');
              }
            }
          }, {
            title: 'Sale Date',
            field: 'saleDate',
            template: '{{::dataItem.saleDate | date : "M/d/yyyy"}}',
            hidden: !isTransactionSearch(),
            width: '6%',
            sortable: {
              compare: function (a, b, descending) {
                // because a date set to null only goes to 1969. Which is bad for sorting...
                // Setting values for function then returning them back to the original after call otherwise it changes the display value
                var prevA = _.cloneDeep(a.saleDate);
                var prevB = _.cloneDeep(b.saleDate);
                a.saleDate = a.saleDate === null ? new Date('Jan 01 1000 00:00:00 GMT-0500 (Eastern Standard Time)') : a.saleDate;
                b.saleDate = b.saleDate === null ? new Date('Jan 01 1000 00:00:00 GMT-0500 (Eastern Standard Time)') : b.saleDate;
      
                var result = customCompare(a, b, descending, 'saleDate');
                a.saleDate = prevA;
                b.saleDate = prevB;

                return result;
              }
            }
          }, {
            title: 'Sale Price',
            field: 'salePrice',
            template: '{{::dataItem.salePrice | currency:"$"}}',
            hidden: !isTransactionSearch(),
            width: '12%',
            sortable: {
              compare: function (a, b, descending) {
                return customCompare(a, b, descending, 'salePrice');
              }
            }
          }, {
            title: 'Price Per Unit',
            field: 'pricePerUnit',
            template: '{{::dataItem.pricePerUnit | currency:"$"}}',
            hidden: !isTransactionSearch(),
            width: '12%',
            sortable: {
              compare: function (a, b, descending) {
                return customCompare(a, b, descending, 'pricePerUnit');
              }
            }
          }, {
            title: 'County',
            field: 'county',
            template: '{{::dataItem.county}}',
            hidden: isTransactionSearch(),
            width: '8%',
            sortable: {
              compare: function (a, b, descending) {
                return customCompare(a, b, descending, 'county');
              }
            }
          }, {
            title: 'Age',
            field: 'buildingAgeInYears',
            template: '{{::dataItem.buildingAgeInYears}}',
            hidden: isTransactionSearch(),
            width: '4%',
            sortable: {
              compare: function (a, b, descending) {
                return customCompare(a, b, descending, 'buildingAgeInYears');
              }
            }
          }, {
            title: 'Open Date',
            field: 'openDate',
            template: '{{::dataItem.openDate | date : "M/d/yyyy"}}',
            hidden: isTransactionSearch(),
            width: '7%',
            sortable: {
              compare: function (a, b, descending) {
                // because a date set to null only goes to 1969. Which is bad for sorting...
                // Setting values for function then returning them back to the original after call otherwise it changes the display value
                var prevA = _.cloneDeep(a.openDate);
                var prevB = _.cloneDeep(b.openDate);
                a.openDate = a.openDate === null ? new Date('Jan 01 1000 00:00:00 GMT-0500 (Eastern Standard Time)') : a.openDate;
                b.openDate = b.openDate === null ? new Date('Jan 01 1000 00:00:00 GMT-0500 (Eastern Standard Time)') : b.openDate;
      
                var result = customCompare(a, b, descending, 'openDate');
                a.openDate = prevA;
                b.openDate = prevB;

                return result;
              }
            }
          }, {
            title: 'Property Type',
            field: 'typeDescription',
            template: '{{::dataItem.typeDescription}}',
            headerTemplate: '<span>Property<br>Type</span>',
            hidden: isTransactionSearch(),
            width: '6%',
            sortable: {
              compare: function (a, b, descending) {
                return customCompare(a, b, descending, 'typeDescription');
              }
            }
          }, {
            title: 'IL Units',
            field: 'units.ilUnits',
            template: '{{::dataItem.units.ilUnits}}',
            headerTemplate: '<span>IL<br>Units</span>',
            width: '4%',
            sortable: {
              compare: function (a, b, descending) {
                return customCompare(a, b, descending, 'units.ilUnits');
              }
            }
          }, {
            title: 'AL Units',
            field: 'units.alUnits',
            template: '{{::dataItem.units.alUnits}}',
            headerTemplate: '<span>AL<br>Units</span>',
            width: '4%',
            sortable: {
              compare: function (a, b, descending) {
                return customCompare(a, b, descending, 'units.alUnits');
              }
            }
          }, {
            title: 'MC Units',
            field: 'units.mcUnits',
            template: '{{::dataItem.units.mcUnits}}',
            headerTemplate: '<span>MC<br>Units</span>',
            width: '4%',
            sortable: {
              compare: function (a, b, descending) {
                return customCompare(a, b, descending, 'units.mcUnits');
              }
            }
          }, {
            title: 'NC Beds',
            field: 'units.ncUnits',
            template: '{{::dataItem.units.ncUnits}}',
            headerTemplate: '<span>NC<br>Beds</span>',
            width: '4%',
            sortable: {
              compare: function (a, b, descending) {
                return customCompare(a, b, descending, 'units.ncUnits');
              }
            }
          }, {
            title: 'Total Spaces',
            field: 'units.total',
            template: '{{::dataItem.units.total}}',
            headerTemplate: '<span>Total<br>Spaces</span>',
            width: '5%',
            sortable: {
              compare: function (a, b, descending) {
                return customCompare(a, b, descending, 'units.total');
              }
            }
          }, {
            title: 'Property Status',
            field: 'constructionStatus',
            template: '{{::dataItem.constructionStatus}}',
            headerTemplate: '<span>Property<br>Status</span>',
            hidden: isTransactionSearch(),
            width: '6%',
            sortable: {
              compare: function (a, b, descending) {
                return customCompare(a, b, descending, 'constructionStatus');
              }
            }
          }, {
            title: 'Profit Status',
            field: 'profitStatus',
            template: '{{::dataItem.profitStatus ? "Profit" : "Not For Profit"}}',
            headerTemplate: '<span>Profit<br>Status</span>',
            hidden: isTransactionSearch(),
            width: '5%',
            sortable: {
              compare: function (a, b, descending) {
                return customCompare(a, b, descending, 'profitStatus');
              }
            }
          }, {
            title: 'Operator',
            field: 'operator',
            template: "{{ ::dataItem.operator && dataItem.operator != 'Unknown Stakeholder' ? dataItem.operator : 'N/A'}}",
            width: '10%',
            sortable: {
              compare: function (a, b, descending) {
                return customCompare(a, b, descending, 'operator');
              }
            }
          }, {
            title: 'Walk Score®',
            field: 'walkScoreInfo.value.walkScore',
            template: walkScoreColumnTemplate,
            headerTemplate: '<span>Walk<br>Score®</span>',
            width: '4%',
            sortable: {
              compare: function (a, b, descending) {
                return customCompare(a, b, descending, 'walkScoreInfo.value.walkScore');
              }
            }
          }, {
            title: 'Distance',
            field: 'distance',
            template: '{{::dataItem.distance}}',
            width: '5%',
            sortable: {
              compare: function (a, b, descending) {
                return customCompare(a, b, descending, 'distance');
              }
            }
          }
        ],
        dataSource: new kendo.data.DataSource({
          data: vm.properties.items,
          schema: {
            model: {
              fields: {
                pinNumber: { type: 'number' },
                name: { type: 'string' },
                addressLine1: { type: 'string' },
                saleDate: { type: 'date' },
                salePrice: { type: 'number' },
                pricePerUnit: { type: 'number' },
                county: { type: 'string' },
                buildingAgeInYears: { type: 'number' },
                openDate: { type: 'date' },
                typeDescription: { type: 'string' },
                units: {},
                constructionStatus: { type: 'string' },
                profitStatus: {},
                operator: { type: 'string' },
                distance: { type: 'number' },
              }
            },
          },
          sort: dataSourceSorting,
          pageSize: 25,
        }),
        scrollable: {
          virtual: true
        },
        filterable: false,
        pageable: false,
        sortable: {
          mode: 'single',
          allowUnsort: false
        },
        height: '100%',
        sort: function(e) {
          var field = _.get(e, '[0].sort.field', _.get(e, 'sort.field', _.get(e, '[0].field', _.get(e, 'field', null))));
          var previousSort = _.cloneDeep(vm.currentSort);  
          var currentSort = _.find(this.columns, ['field', field]); 
          _.extend(currentSort, e.sort);
          // Add property to currentSort that is more easily digestible by the angular orderby filter
          // Used a mix of answers from https://stackoverflow.com/questions/25662001/angular-sorting-with-null-objects
          if(currentSort.dir == 'asc') {  
            currentSort.shorthand = [ currentSort.field + '== null ? "+" : "-"', '+' + currentSort.field, '+pinNumber'];
          } else { 
            currentSort.shorthand = [ currentSort.field + '== null ? "-" : "+"', '-' + currentSort.field, '+pinNumber'];
          } 
          
          if(currentSort != previousSort) { 
            $scope.$emit('propertyListSortChangedViaPropertyListFull', currentSort); 
          } 
        },
      };
    }

    function walkScoreColumnTemplate(dataItem) {
      var walkScore = "N/A";
      if (dataItem.walkScoreInfo 
        && dataItem.walkScoreInfo.value 
        && dataItem.walkScoreInfo.value.status == 1) {
        walkScore = dataItem.walkScoreInfo.value.walkScore;
      }
      return "<a target='_blank' href='//www.walkscore.com/how-it-works/'>" + walkScore + "</a>";
    }
  }
})();
