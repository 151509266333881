(function () {
  "use strict";

  app.controller('SubscriptionClientApiController', SubscriptionClientApiController);

  SubscriptionClientApiController.$inject = ['$scope', '$rootScope', '$state', '$uibModal', 'clientApiSubscriptionTypesConstant',
    '$filter', 'SpinnerService']

  function SubscriptionClientApiController($scope, $rootScope, $state, $uibModal, clientApiSubscriptionTypesConstant,
    $filter, SpinnerService) {
    $rootScope.hideLeftNav = true;
    $scope.clientApiData = $scope.$parent.clientApiKeys
    $scope.paramSubscriptionID = $state.params.id;
    $scope.clientApiSubscriptionTypeName = null;
    $scope.spinnerStarted = false;
    
    $scope.$on('SilentSubscriptionDetailRefreshComplete', productCheck);

    function productCheck() {
      $scope.clientApiData = $scope.$parent.clientApiKeys;

      var hasAccess = false;
      $scope.clientApiSubscriptionTypeName = null;

      if ($scope.$parent.subscription && $scope.$parent.subscription.clientApiSubscriptionType) {
        hasAccess = $scope.$parent.subscription.clientApiSubscriptionType.id == clientApiSubscriptionTypesConstant.basic || $scope.$parent.subscription.clientApiSubscriptionType.id == clientApiSubscriptionTypesConstant.enterprise
        if (hasAccess) {
          $scope.clientApiSubscriptionTypeName = $scope.$parent.subscription.clientApiSubscriptionType.clientApiSubscriptionType;
        }
      }

      if (!hasAccess) {
        $scope.clientApiData = [];
      }

      $scope.hasAccessToClientApi = hasAccess;
      refreshGrid();
      if ($scope.spinnerStarted) {
        $scope.spinnerStarted = false;
        SpinnerService.stop();
      }
    }

    function initGrid() {
      $scope.gridOpts = {
        columns: [
          {
            title: " ",
            filterable: false,
            template: editActionColumnHtml,
            attributes: {
              'class': 'text-center'
            }
          },
          {
            title: " ",
            filterable: false,
            template: sendEmailActionColumnHtml,
            attributes: {
              'class': 'text-center'
            }
          },
          {
            width: "25%",
            field: "environmentTypeName",
            title: "Environment"
          },
          {
            width: "15%",
            field: "callsPerDay",
            title: "Calls per Day",
            format: '{0:n0}',
          },
          {
            width: "25%",
            field: "apiKey",
            title: "API Key"
          },
          {
            width: "35%",
            field: "modifiedByAndModifiedDate",
            title: "Last Modified"
          }
        ],
        dataSource: new kendo.data.DataSource({
          transport: {
            type: "json",
            read: function (opts) {
              opts.success($scope.clientApiData);
            }
          },
          pageSize: 25,
          error: function (err) {
            console.log('error - ' + err);
          },
          schema: {
            model: {
              fields: {
                'id': { type: 'number' },
                'environmentTypeId': { type: 'number' },
                'environmentTypeName': { type: 'string' },
                'callsPerDay': { type: 'number' },
                'apiKey': { type: 'string' },
                'modifiedByEmail': { type: 'string' },
                'dateModified': { type: 'date' },
                'modifiedByAndModifiedDate': { type: 'string' }
              }
            },
            parse: function (data) {
              _.forEach(data, function (item, idx) {
                if (item.apiKey && item.apiKey != "") {
                  if (item.modifiedByEmail && item.dateModified) {
                    item.modifiedByAndModifiedDate = item.modifiedByEmail + " "
                      + $filter('nicDateFilter')(item.dateModified, 'MM/dd/yyyy hh:mm a');
                  }
                }
              });
              return data;
            } 
          },
          sort: { field: "environmentTypeName", dir: "asc" },
          serverPaging: false,
          serverFiltering: false,
          serverSorting: false
        }),
        dataBinding: function (e) {
          Nic.kendoGrid.filtersOnTop('clientApiGrid');
        },
        scrollable: false,
        pageable: true,
        resetFilters: function () {
          this.dataSource.filter([]);
          this.dataSource.sort({ field: "environmentTypeName", dir: "asc" });
        }
      };
    };

    function refreshGrid() {      
      $scope.gridOpts.dataSource.read();
      $scope.gridOpts.resetFilters();
    }

    $scope.editClientApi = function (dataItem) {
      var editClientApiPopup = $uibModal.open({
        templateUrl: "app/partials/subscriptionClientApiEditKeyPopup.html",
        backdrop: 'static',
        windowClass: 'modify-access-popup',
        controller: 'SubscriptionClientApiEditKeyPopupController',
        resolve: {
          companyId: function () { return $state.params.companyId; },
          data: function () {
            return {
              id: dataItem.id,  // primary key of the tbl_companyKey table
              apiKey: dataItem.apiKey,
              callsPerDay: dataItem.callsPerDay,
              environmentTypeId: dataItem.environmentTypeId,
              environmentTypeName: dataItem.environmentTypeName,
              lastModified: dataItem.modifiedByAndModifiedDate ? dataItem.dateModified : undefined
            };
          }  
        }
      });

      editClientApiPopup.result.then(function () {
        $scope.spinnerStarted = true;
        SpinnerService.start();
        $rootScope.$broadcast("SilentSubscriptionDetailRefresh", { productsUpdated: true });
      });
    };

    function editActionColumnHtml(dataItem) {
      return "<div ng-click='editClientApi(this.dataItem)' ng-class='{ \"disabled\": $parent.isSubExpired || $parent.isSubTerminated }'><span class='glyphicon glyphicon-pencil primary-blue-dark'></span></div>";
    }

    $scope.sendEmail = function (dataItem) {
      var sendEmailPopup = $uibModal.open({
        templateUrl: "app/partials/subscriptionClientApiSendEmailPopup.html",
        backdrop: 'static',
        windowClass: 'modify-access-popup',
        controller: 'SubscriptionClientApiSendEmailPopupController',
        resolve: {
          subscriptionId: function () { return $state.params.id; },
          data: function () {
            return {
              id: dataItem.id,  // primary key of the tbl_companyKey table
              environmentTypeId: dataItem.environmentTypeId,
              environmentTypeName: dataItem.environmentTypeName,
            };
          }
        }
      });
    };

    function sendEmailActionColumnHtml(dataItem) {
      return "<div ng-click='sendEmail(this.dataItem)' ng-class='{ \"disabled\": $parent.isSubExpired || $parent.isSubTerminated }'><span class='glyphicon glyphicon-envelope primary-blue-dark'></span></div>";
    }

    initGrid();
    productCheck();
  }
})();