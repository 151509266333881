(function () {
  "use strict";

  app.factory("VerifyingDataInterceptor", VerifyingDataInterceptor);
//Note: cannot inject state or AuthService because it makes it a sircular dependency. Injector hacks us around this
  VerifyingDataInterceptor.$inject = ['$q', '$injector'];

  function VerifyingDataInterceptor($q, $injector) {
    return {
      'responseError': function (rejection) {
        //SiteOffLine: SiteOffLineWhileVerificationInProgress
        if(rejection.status == 501){
          var header = rejection.headers("SiteOffLine");
          if (header) {
            var $rootScope = $injector.get('$rootScope');
            $rootScope.$broadcast('siteOffLine');
          }
        }
        return $q.reject(rejection);
      }
    };
  }
}());