(function () {
  "use strict";
  app.controller('DataAttributionController', DataAttributionController);

  DataAttributionController.$inject = ['$scope', '$rootScope', '$state', 'AuthService', 'SpinnerService'];
  function DataAttributionController($scope, $rootScope, $state, AuthService, SpinnerService) {
    $rootScope.hideLeftNav = true;
    $scope.acceptBtnDisabled = true;
    
    $scope.cancel = function () {
      AuthService.logout();
    }

    $scope.accept = function () {
      SpinnerService.start();
      AuthService.updateDataAttributionAcceptanceDate()
      .then(function (rsp) {
        AuthService.reAuthenticate()
        .then(function (reAuthResp) {
          $state.go("home");
        }, function (reAuthErr) {
          SpinnerService.stop();
        })
      }, function (err) {
        SpinnerService.stop()
      });
    }

    $scope.enableAcceptButton = function () {
      $scope.acceptBtnDisabled = false;
    }
  }
})();
