(function() {
  "use strict";

  app.controller('LayoutController', LayoutController);

  LayoutController.$inject = ['$scope', '$state', 'AuthService'];

  function LayoutController($scope, $state, authService) {
    $scope.$on("loggedIn", function() {
      displayUser();
    });
    
    $scope.$on("loggedOut", function() {
      displayUser();
      $state.go('login.home');
    });
    
    $scope.$on("kickedOut", function () {
      authService.kickOutUnauthenticatedSession();
      $state.go('login.forceKickout');
    });

    $scope.$on("siteOffLine", function () {
      authService.logout(false);
      displayUser();
      $state.go('login.siteOffLine');
    });

    $scope.changePasswordMessage = "This is the change password page.";
    
    function displayUser() {
      var identity = authService.identity;
    
      if(identity.isAuth())
        $scope.welcomeMessage = "Welcome, " + (identity.firstName() ? identity.firstName() + " " + identity.lastName() : "User");
      else
        $scope.welcomeMessage = "";
    
      if (identity.isAuth()) {
        authService.getSubscriptionExpiration()
          .then(function (currentSubscription) {
            $scope.subscriptionExpiresIn = currentSubscription.expiresIn;
            $scope.subscriptionExpiresOn = currentSubscription.expiresOn;
            $scope.showExpirationMessage = (currentSubscription.expiresIn != null && currentSubscription.expiresIn <= 90) ? true : false;
          }
        );
      }
      
      $scope.isImpersonated = identity.isImpersonated();
      $scope.showAccountAdmin = identity.isAccountAdmin();
    }
    
    $scope.stopImpersonating = function() {
      authService
        .stopImpersonate()
        .then(function() {
          authService
            .reAuthenticate()
            .then(function() {
              authService.bootIntercom();
              $state.go("admin.subscriptions");
            }, function() {
              // Do Nothing.
            });
        }, function() {
          // Do Nothing.
        });
    };
    
    $scope.isAdmin = function () {
      return authService.identity.isInRole("Administrator");
    };
    
    $scope.logout = function () {
      authService.logout();
    };
  } 
})();
