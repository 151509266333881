(function () {
  "use strict";
  
  app.controller('SubscriptionGeographicalController', SubscriptionGeographicalController);
  
  SubscriptionGeographicalController.$inject = ['$scope', '$rootScope', '$window', 'SubscriptionDetailsService',
    'SubscriptionGeographyService', 'SpinnerService', 'FileDownloadService'];
  
  function SubscriptionGeographicalController($scope, $rootScope, $window, SubscriptionDetailsService,
    SubscriptionGeographyService, SpinnerService, FileDownloadService) {

    var allRegionsAndCBSAs = SubscriptionDetailsService.getAllRegionsAndCBSAs();
    var subscribedCBSAs = SubscriptionDetailsService.getSubscribedCBSAs();
    var subscriptionInfo = SubscriptionDetailsService.getSubscriber();
    var tempScopeTracker = {};

    $scope.allRegionsAndCBSAs = [];
    $scope.scopeTracker = {};
    $scope.getOverallSelectionCount = getOverallSelectionCount;
    $scope.isUpdating = false;
    $scope.renderFinished = renderFinished;
    $scope.updateClicked = updateClicked;
    $scope.saveClicked = saveClicked;
    $scope.cancelClicked = cancelClicked;
    $scope.changeCBSAAccessFlag = changeCBSAAccessFlag;
    $scope.exportToExcel = exportToExcel;

    // Alias to make testing easier
    $scope.subscriptionId = _.get($scope, '$parent.subscription.id');

    $scope.$on('SilentSubscriptionDetailRefreshComplete', function () {
      subscriptionInfo = SubscriptionDetailsService.getSubscriber();
      subscribedCBSAs = SubscriptionDetailsService.getSubscribedCBSAs();
    });

    function initGeographicalAccess() {
      $scope.allRegionsAndCBSAs = _.orderBy(allRegionsAndCBSAs, ['sortOrder'], ['asc']);
      _.forEach($scope.allRegionsAndCBSAs, function (eachRegionAndCBSA, index) {
        var accessedRegions = [];
        _.forEach(eachRegionAndCBSA.cbsAs, function (eachCBSAs) {
          var hasAccess = _.find(subscribedCBSAs, function (eachSubscribedCBSA) {
            return eachSubscribedCBSA.cbsaCode == eachCBSAs.id;
          });
          if (hasAccess) {
            accessedRegions.push(eachCBSAs);
          }
          return eachCBSAs;
        });

        var scopeName = 'RegionId' + eachRegionAndCBSA.id;
        $scope.scopeTracker[scopeName] = {
          name: scopeName,
          model: accessedRegions, //holds the regions that subscription is subscribed to
          settings: {
            bootstrap2: false,
            filterClear: null,//'Show all!',
            filterPlaceHolder: null,//'Filter!',
            moveSelectedLabel: 'Move selected only',
            moveAllLabel: 'Move all!',
            removeSelectedLabel: 'Remove selected only',
            removeAllLabel: 'Remove all!',
            moveOnSelect: false,//true,
            preserveSelection: 'moved',
            selectedListLabel: 'Metro Markets Accessed',
            nonSelectedListLabel: 'Metro Markets Available',
            postfix: scopeName + '_helperz',
            selectMinHeight: 130,
            filter: false,//true,
            filterNonSelected: null,//'1',
            filterSelected: null,//'4',
            infoAll: null,//'Showing all {0}!',
            infoFiltered: null,//'<span class="label label-warning">Filtered</span> {0} from {1}!',
            infoEmpty: null,//'Empty list!',
            filterValues: false//true
          }
        };
      });
    }

    function enableDisableListbox(flagToUse) {
      var regionObj = $("select[name^='RegionId']");
      regionObj.prop('disabled', flagToUse);
      var parentObj = regionObj.parent();
      parentObj.find('.moveall').prop('disabled', flagToUse);
      parentObj.find('.move').prop('disabled', flagToUse);
      parentObj.find('.removeall').prop('disabled', flagToUse);
      parentObj.find('.remove').prop('disabled', flagToUse);
    }

    initGeographicalAccess();

    function getOverallSelectionCount() {
      var selectedCbsas = 0, totalCbsas = 0;
      _.forEach($scope.allRegionsAndCBSAs, function (eachRegionAndCBSA) {
        var scopeName = 'RegionId' + eachRegionAndCBSA.id;
        selectedCbsas += $scope.scopeTracker[scopeName].model.length;
        totalCbsas += eachRegionAndCBSA.cbsAs.length;
      });
      return selectedCbsas + '/' + totalCbsas;
    }

    function renderFinished() {
      var flagToUse = true;
      if ($scope.isUpdating) {
        // Edit was clicked so enable everything
        flagToUse = false;
      }
      enableDisableListbox(flagToUse);
    }

    function updateClicked() {
      enableDisableListbox(false);
      $scope.isUpdating = true;
      tempScopeTracker = {};
      _.forEach($scope.allRegionsAndCBSAs, function (eachRegionAndCBSA) {
        var scopeName = 'RegionId' + eachRegionAndCBSA.id;
        var currentSelectedCbsas = [];
        _.forEach($scope.scopeTracker[scopeName].model, function (cbsasInModel) {
          currentSelectedCbsas.push(cbsasInModel);
        });
        tempScopeTracker[scopeName] = currentSelectedCbsas;
      });
    }

    function saveClicked() {
      SpinnerService.start();
      // Save startdate, enddate, price, subscriptiontype, subscriptionproducts
      var dataToSave = {
        startDate: subscriptionInfo.startDate,
        endDate: subscriptionInfo.endDate,
        price: subscriptionInfo.price,
        isRenewal: subscriptionInfo.isRenewal,
        subscriptionTypeId: subscriptionInfo.subscriptionType.id,
        isMetroBank: subscriptionInfo.isMetroBank,
        metrosPurchased: subscriptionInfo.metrosPurchased,
        geographicAccess: getSelectedCbsas()
      };

      // save geography access
      SubscriptionDetailsService
        .updateSubscriptionAccess(subscriptionInfo.id, 'cbsas', dataToSave)
        .then(function (rsp) {
          // update successful
          // update subscriptioninfo in service so it is available across all views on page
          // update subscribedcbsas in service so it is available across all view on page
          $rootScope.$broadcast("SilentSubscriptionDetailRefresh", { geoUpdated: true });
        }, function (err) {
          $window.alert('Update to subscription failed: ' + (err.data || err.statusText));
          SpinnerService.stop();    
        })
        .finally(function () {
          enableDisableListbox(true);
          $scope.isUpdating = false;
        });
    }

    function getSelectedCbsas() {
      var cbsasAccessTo = [];
      _.forEach($scope.allRegionsAndCBSAs, function (eachRegionAndCBSA) {
        var scopeName = 'RegionId' + eachRegionAndCBSA.id;
        if ($scope.scopeTracker[scopeName].model.length <= 0) return;
        cbsasAccessTo.push(_.map($scope.scopeTracker[scopeName].model, function (cbsa) {
          return cbsa.id;
        }));
      });
      return cbsasAccessTo.join();
    }

    function changeCBSAAccessFlag(marketType, selectionFlag) {
      _.forEach($scope.allRegionsAndCBSAs, function (eachRegionAndCBSA) {
        var scopeName = 'RegionId' + eachRegionAndCBSA.id;
        if (marketType == null) {
          if (selectionFlag) {
            // select all clicked
            $scope.scopeTracker[scopeName].model = eachRegionAndCBSA.cbsAs;
          } else {
            // clear clicked
            $scope.scopeTracker[scopeName].model = [];
          }
        } else {
          var gatherAllCbsas = [];
          _.forEach(eachRegionAndCBSA.cbsAs, function (eachCbsa) {
            if (marketType == eachCbsa.marketType.toLowerCase()) {
              gatherAllCbsas.push(eachCbsa);
            }
          });
          _.forEach($scope.scopeTracker[scopeName].model, function (alreadySelectedCbsas) {
            var hasItInCollectionAlready = gatherAllCbsas.indexOf(alreadySelectedCbsas);
            if (hasItInCollectionAlready >= 0) return;
            gatherAllCbsas.push(alreadySelectedCbsas);
          });
          $scope.scopeTracker[scopeName].model = gatherAllCbsas;
        }
      });
    }

    function cancelClicked() {
      enableDisableListbox(true);
      $scope.isUpdating = false;

      _.forEach($scope.allRegionsAndCBSAs, function (eachRegionAndCBSA) {
        var scopeName = 'RegionId' + eachRegionAndCBSA.id;
        $scope.scopeTracker[scopeName].model = tempScopeTracker[scopeName];
      });
    }

    function exportToExcel() {
      SpinnerService.start();
      SubscriptionGeographyService.exportToExcel($scope.subscriptionId)
      .then(function (response) {
        FileDownloadService.saveReportFile(response);
      })
      .catch(function (error) {
        console.error(error);
      })
      .finally(function () {
        SpinnerService.stop();
      })
    }
  }
})();
