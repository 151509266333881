(function () {
  'use strict';
  
  app.factory('PlannedPropertyService', PlannedPropertyService);
  
  PlannedPropertyService.$inject = ["$http", "$q", "apiSettings"];
  function PlannedPropertyService($http, $q, settings) {
    function getByBBox(bbox, isExport) {
      var deferred = $q.defer();
      var uri = settings.url + "/localpropertysearch/plannedpropertysearch";
      if (isExport) {
        uri += 'export';
      }
      $http
        .post(uri, bbox)
        .then(function(rsp) {
          deferred.resolve(rsp.data)
        }, function(err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    return {
      getByBBox: getByBBox
    };
  }
})();
