(function () {
  "use strict";
  
  app.controller("CompBuilderAddController", CompBuilderAddController);
  
  CompBuilderAddController.$inject = ['$scope', '$state', '$q', 'SpinnerService'];
  function CompBuilderAddController($scope, $state, $q, SpinnerService) {
    var FORMSTATE_KEY = 'CompBuilderFormState';

    $scope.formSteps = [
      {id: 1, name: "Enter Aggregate Name", stateName: 'compBuilder.compBuilderAdd.aggregateName'},
      {id: 2, name: "Add Subject Locations", stateName: 'compBuilder.compBuilderAdd.aggregateLocation'},
      {id: 3, name: "Select Search Criteria", stateName: 'compBuilder.compBuilderAdd.aggregateCriteria'},
      {id: 4, name: "Select Distance From Subject Locations", stateName: 'compBuilder.compBuilderAdd.aggregateDistance'} ,
      {id: 5, name: "Share Aggregate", stateName: 'compBuilder.compBuilderAdd.shareAggregate'}
    ];

    /**
     * This is the model that will ultimately be sent to the API on submit
     * Additional properties should be added/referenced from here
     */
    $scope.submissionModel = {
      jobId: null,
      requestId: null,
      aggregateName: '',
      peerGroups: [],
      searchParameters: {
        propertyTypeIds: [],
        campusTypeIds: [],
        segmentTypeIds: [],
        isIncludeCCRC: false,
        tradeArea: {
          radius: null,
          driveTime: {
            breakValue: null,
            trafficFlow: null
          },
          // always true for this page since without this FindCompset would never return any result
          hasShape: true
        }
      },
      shareInfo: null
    };

    $scope.currentStep = $state.params.step;

    /**
     * Holder for form data metadata and client side validity. Accessible by the child views, the content of the form is swapped out on each step.
     */
    $scope.form = {};

    /**
     * User added or pulled from the form, these are currently a collection of {message: 'string or htmlString'} objects
     * Displayed below the next/cancel buttons.
     * They are cleared when the user switches pages.
     */
    $scope.errors = [];

    // Functions
    $scope.removeFormState = removeFormState;
    $scope.nextStep = nextStep;
    $scope.nextStepAction = function () { return $q.resolve(); }; // Override this on the step controllers to handle API calls and validation? Currently requiring this to be a promise for simplicity of nextStep
    $scope.previousStep = previousStep;
    $scope.previousStepAction = function () { return $q.resolve(); }; 
    $scope.cancelClick = cancelClick;
    $scope.isActive = isActive;
    $scope.$onInit = $onInit;
    $scope.$onInit();

    // Watches
    $scope.$watch('$state.params.step', updateProgressSection);
    $scope.$watch('submissionModel', saveFormState, true); // Third param for deep value checking
    function $onInit() {
      if (sessionStorage.getItem(FORMSTATE_KEY)) {
        $scope.submissionModel = JSON.parse(sessionStorage.getItem(FORMSTATE_KEY));
      }
    }

    function isActive(currStep) {
      return $state.is(currStep.stateName)? 'is-active' : '';
    };

    function nextStep() {
      $scope.errors = []; // clear the errors once NEXT is clicked
      $scope.form.$setValidity('nextClick', false, $scope); // when NEXT clicked disable it right away to avoid double click
      SpinnerService.start();
      // Currently requiring nextStepAction() to return Promise or $q equivalent.
      $scope.nextStepAction()
      .then(function (actionResponse) {
        if ($state.params.step == $scope.formSteps.length) {
          removeFormState();
          $state.go('compBuilder.aggregateSummary', {
            aggregateCreationSummary: actionResponse
          });
          return;
        }
        
        $scope.formSteps.forEach(function (stepDetails) {
          if (stepDetails.id == $state.params.step + 1 && ($state.params.step < $scope.formSteps.length)) {
            $state.go(stepDetails.stateName);
          }
        });
        
      })
      .catch(function (err) { // catch if anything blew up in the nextStepAction() i.e the promise threw an error or was rejected. nextStepAction should return an error object that contains a message property.
        $scope.errors = Array.isArray(err) ? err : [err];
        console.error(err);
      })
      .finally(function () {
        $scope.form.$setValidity('nextClick', true, $scope); // when NEXT done enable the button
        SpinnerService.stop();
      });
    }

    function previousStep() {
      $scope.errors = [];
      $scope.previousStepAction()
      .then(function (actionResponse) {
        $scope.formSteps.forEach(function(stepDetails){
          if(stepDetails.id == $state.params.step -1 && ($state.params.step > 1)) {
            $state.go(stepDetails.stateName);
          }
        });
      });
    }

    function cancelClick() {
      removeFormState();
      $state.go('compBuilder');
    }

    function updateProgressSection() {
      $scope.currentStep = $state.params.step;
      $scope.btnNextText = ($state.params.step < $scope.formSteps.length) ? 'Next': 'Finish';
    }

    function saveFormState() {
      sessionStorage.setItem(FORMSTATE_KEY, JSON.stringify($scope.submissionModel));
    }

    function removeFormState() {
      sessionStorage.removeItem(FORMSTATE_KEY);
    }
  }
})();
