(function() {
  "use strict";

  app.controller("CompBuilderController", CompBuilderController);
  
  CompBuilderController.$inject = ['$rootScope', '$scope', 'AuthService', 'CompBuilderService', 'NotificationService',
    'PopupHandlerService', 'SignalRService', 'SpinnerService', 'AuditService', 'knownEventConstant'];

  function CompBuilderController($rootScope, $scope, AuthService, CompBuilderService, NotificationService,
    PopupHandlerService, SignalRService, SpinnerService, AuditService, knownEventConstant) {
    $rootScope.hideLeftNav = true;
    $scope.checkboxStatus = {
      showIL: true,
      showAL: true,
      showMC: true,
      showNC: true,
      showSharedAggregate: false
    };
    $scope.formatTableRowsAndCells = formatTableRowsAndCells;
    $scope.formatValueInCell = formatValueInCell;
    $scope.arrowDirection = arrowDirection;
    $scope.checkboxClicked = checkboxClicked;
    $scope.deleteAggregate = deleteAggregate;
    $scope.logDetailsViewClick = logDetailsViewClick;
    $scope.inProgressAggregates = [];
    $scope.init = init;
    init();

    var completedAggregates = [];
    var notificationPopups = [];

    $scope.$on("$destroy", function() {
      SignalRService.removeHubEvent('compbuilderGet');
      SignalRService.removeHubEvent('compbuilderGetError');
      SignalRService.removeHubEvent('compbuilderAggregateProcessed');
      notificationPopups.forEach(function (popupInstance) {
        popupInstance.dismiss(); // using dismiss instead of close as to not trigger notification removal from backend
      });
    });

    SignalRService.genericHub("compbuilderAggregateProcessed", $scope, function() {
      CompBuilderService.getData($scope.checkboxStatus.showSharedAggregate);
    });

    SignalRService.genericHub("compbuilderGet", $scope, function(_data, _requestId) {
      $scope.inProgressAggregates = _data.inProgressCollections || [];
      completedAggregates = _data.completedCollections || [];
      $scope.gridCompBuilder.dataSource.data(completedAggregates);
      SpinnerService.stop();
      $scope.$apply();

      if (_data.notifications && _data.notifications.length) {
        notificationPopups = _data.notifications.map(function (notification) {
          var instance = PopupHandlerService.openSimplePopup(notification.message, { backdrop: 'static', keyboard: false });
          instance.result.then(function () {
            NotificationService.remove(notification.id);
          });
          return instance;
        });
      }
    });

    SignalRService.genericHubWError("compbuilderGetError", $scope, function(_data, _requestId) {
      $scope.inProgressAggregates = [];
      completedAggregates = [];
      $scope.gridCompBuilder.dataSource.data(completedAggregates);
      PopupHandlerService.openSimplePopup("An error occurred while processing the request. Please try again later or contact us.");
      SpinnerService.stop();
      $scope.$apply();
    });

    function init() {
      SpinnerService.start();
      completedAggregates = [];
      var userProfile = AuthService.identity.userProfile();
      if (userProfile) {
        $scope.checkboxStatus.showIL = userProfile.showIL == false ? false : true;
        $scope.checkboxStatus.showAL = userProfile.showAL == false ? false : true;
        $scope.checkboxStatus.showMC = userProfile.showMC == false ? false : true;
        $scope.checkboxStatus.showNC = userProfile.showNC == false ? false : true;
        $scope.checkboxStatus.showSharedAggregate = userProfile.showAggregate == false ? false : true;
      }
      CompBuilderService
        .getData($scope.checkboxStatus.showSharedAggregate)
        .then(function (data) {
          bindCompBuilderGrid();
        }, function (err) {
          console.error(err);
          SpinnerService.stop();
        });
    }

    function bindCompBuilderGrid() {
      var dynamicColumns = [];

      // add NC columns
      addSegmentSpecificColumns($scope.checkboxStatus.showNC, dynamicColumns, "nc", "Nursing Care");
      // add MC columns
      addSegmentSpecificColumns($scope.checkboxStatus.showMC, dynamicColumns, "mc", "Memory Care");
      // add AL columns
      addSegmentSpecificColumns($scope.checkboxStatus.showAL, dynamicColumns, "al", "Assisted Living");
      // add IL columns
      addSegmentSpecificColumns($scope.checkboxStatus.showIL, dynamicColumns, "il", "Independent Living");

      // fifth column with CompSetCount
      dynamicColumns.unshift({
        headerTemplate: '<span>Competitive<br>Set Count</span>',
        headerAttributes: {
          'class': 'text-center verticalAlignMiddle'
        },
        field: 'compSetCount',
        attributes: {
          'class': 'text-right nowrap'
        },
      });

      // fourth column with name
      dynamicColumns.unshift({
        title: "Aggregate Name",
        template: function (dataItem) {
          if (dataItem.collectionTypeId != 5)
            return '{{dataItem.peerGroupName}}';
          return '{{dataItem.aggregateName}}';
        },
        attributes: {
          'class': 'ow-break-word'
        },
        headerAttributes: {
          'class': 'text-center noLeftBorder verticalAlignMiddle'
        }
      });

      // third column with checkbox in header and rows
      dynamicColumns.unshift({ 
        headerTemplate: '<input type="checkbox" class="pointer-cursor" />',
        headerAttributes: {
          'class': 'text-center noLeftBorder verticalAlignMiddle'
        },
        template: '<input type="checkbox" class="pointer-cursor" />',
        attributes: {
          'class': 'text-center'
        },
        width: "31px"
      });

      // second column with edit/search image
      dynamicColumns.unshift({ 
        title: " ",
        headerAttributes: {
          'class': 'noLeftBorder'
        },
        template: function (dataItem) {
          if (!dataItem.isShared && dataItem.collectionTypeId != 5) {
            return "<a ui-sref='compBuilderPeerGroupDetails({peerGroupGuid: dataItem.peerGroupGuid})' ng-click='logDetailsViewClick(dataItem)'><span class='glyphicon glyphicon-pencil primary-blue-dark pointer-cursor'></span></a>";
          } else if (dataItem.isShared && dataItem.collectionTypeId != 5) {
            return "<a ui-sref='compBuilderPeerGroupDetails({peerGroupGuid: dataItem.peerGroupGuid})' ng-click='logDetailsViewClick(dataItem)'><span class='glyphicon glyphicon-search primary-blue-dark pointer-cursor'></span></a>";
          }
          return '';
        },
        width: "15px"
      });

      // first column with substract image
      dynamicColumns.unshift({ 
        title: " ",
        template: function (dataItem) {
          if(dataItem.collectionTypeId == 5){
            return "<a class='manageDelete' ng-click='deleteAggregate(dataItem)' type='button'></a>";
          }
          return '';
        },
        width: "0px"
      });

      var options = {
        columns: dynamicColumns,
        dataSource: new kendo.data.DataSource({
          data: completedAggregates,
          error: function (err) {
            console.log('error - ' + err);
          },
          serverPaging: false,
          serverFiltering: false,
          serverSorting: false,
          pageSize: 14,
        }),
        scrollable: false,
        sortable: false,
        pageable: true,
        dataBound: function (evt){
          var grid = evt.sender;
          var columns = grid.columns;
          _.each(columns, function (column) {
            if(column.hidden === true) {
              grid.hideColumn(column);
            } else {
              grid.showColumn(column);
            }
          });
          $scope.formatTableRowsAndCells(evt);
        }
      };

      $scope.gridCompBuilderOptions = options;
    }

    function addSegmentSpecificColumns(
      isSegmentCheckboxSelected, 
      dynamicColumns,
      segmentCode,
      segmentName) {
      var lastSubColumnName = /^NC/i.test(segmentCode) ? "Beds" : "Units";
      dynamicColumns.unshift({
        hidden: !isSegmentCheckboxSelected,
        title: segmentName,
        headerAttributes: {
          'class': 'text-center noBottomBorder'
        },
        columns: [
        {
          title: "Occ",
          headerAttributes: {
            'class': 'text-right noBorderRadius'
          },
          attributes: {
            'class': 'text-right nowrap'
          },
          template: function (dataItem) {
            return $scope.formatValueInCell(dataItem[segmentCode + 'RawOccupancy'], "p1");
          }
        }, {
          //arrow image
          headerAttributes: {
            'class': 'noLeftBorder'
          },
          attributes: {
            'class': 'paddingLeft0 paddingRight0'
          },
          template: function (dataItem) {
            return $scope.arrowDirection(dataItem[segmentCode + 'RawOccupancyQOQ'], -100, 100);
          }
        }, {
          title: "Rate",
          headerAttributes: {
            'class': 'text-right noLeftBorder'
          },
          attributes: {
            'class': 'text-right nowrap'
          },
          template: function (dataItem) {
            return $scope.formatValueInCell(dataItem[segmentCode + 'Rate'], "c0");
          }
        }, {
          //arrow image
          headerAttributes: {
            'class': 'noLeftBorder'
          },
          attributes: {
            'class': 'paddingLeft0 paddingRight0'
          },
          template: function (dataItem) {
            return $scope.arrowDirection(dataItem[segmentCode + 'RateQOQ'], -0.1, 0.1);
          }
        }, {
          title: lastSubColumnName,
          headerAttributes: {
            'class': 'text-right noLeftBorder'
          },
          attributes: {
            'class': 'text-right nowrap'
          },
          template: function (dataItem) {
            return $scope.formatValueInCell(dataItem[segmentCode + "Inventory"], "n0");
          }
        }, {
          //arrow image
          headerAttributes: {
            'class': 'noLeftBorder noBorderRadius'
          },
          attributes: {
            'class': 'paddingLeft0 paddingRight0'
          },
          template: function (dataItem) {
            return $scope.arrowDirection(dataItem[segmentCode + 'InventoryQOQ'], -0.1, 0.1);
          }
        }]
      });
    }

    function checkboxClicked(index) {
      SpinnerService.start();
      if($scope.gridCompBuilder && index != undefined) {
        if ($scope.gridCompBuilder.columns[index].hidden) {
          $scope.gridCompBuilder.showColumn(index);
        } else {
          $scope.gridCompBuilder.hideColumn(index);
        }
      }
      AuthService
        .updateUserProfile('/CompBuilder/UpdateCompBuilderSetting', $scope.checkboxStatus)
        .then(function (data) {
          CompBuilderService.getData($scope.checkboxStatus.showSharedAggregate);
        }, function (err) {
          bindCompBuilderGrid();
        });
    }

    function deleteConfirmation(aggregate, message, errorMessage){
      PopupHandlerService.openConfirmPopup({
        message: message,
        confirmValue: aggregate.savedQueryId
      }).result
      .then(function (savedQueryIdToDelete) {
        SpinnerService.start();
        return CompBuilderService.deleteAggregate(savedQueryIdToDelete)
          .catch(function () {
            return PopupHandlerService.openSimplePopup(errorMessage);
          });
      })
      .then(function () {
        return $scope.init(); // rebuild / rebind the grid
      })
      .finally(function () {
        SpinnerService.stop();
      });
    }

    function deleteAggregate(aggregate) {
      var message;
      var errorMessage;
      
      if (aggregate.isShared) {
        message = 'Do you want to remove access for <b>' + aggregate.aggregateName + '</b> aggregate that was shared with you?';
        errorMessage = 'Removing shared access failed. Please try again. If problem persists, please contact Client Services.';
        return deleteConfirmation(aggregate, message, errorMessage);
      } else {
        message = 'Do you want to permanently delete <b>' + aggregate.aggregateName + '</b> aggregate and the associated peer groups?';
        errorMessage = 'Delete failed. There was an issue deleting the aggregate. Please try again. If problem persists, please contact Client Services.';
        return deleteConfirmation(aggregate, message, errorMessage);
      }
    }

    function formatTableRowsAndCells(e) {
      var grid = e.sender || e;
      var rows = grid.tbody.find('tr');
      var colorForNonAggregated = "#ffffff"; // default to white
      _.each(rows, function (row, idx) {
        var dataItem = grid.dataItem(row);
        if (dataItem.collectionTypeId == 5) {
          // handle styling of aggregated rows
          row.style.backgroundColor = "#d9d9d9";
          colorForNonAggregated = "#ffffff"; // reset to white for default since new group
          _.each(row.cells, function (cell, cellIdx) {
            if (cellIdx == 3)
              cell.style.fontWeight = "bold";
            cell.style["border-left-width"] = "0px";
          });
          return;
        }
        // non-aggregated rows styling
        row.style.backgroundColor = colorForNonAggregated;
        $(row).hover(
          function () {
            $(this).addClass('k-state-hover');
          },
          function () {
            $(this).removeClass('k-state-hover');
          }
        );

        _.each(row.cells, function (cell, cellIdx) {
          if (cellIdx <= 3 || (cellIdx > 5 && (cellIdx + 1) % 2 != 0))
            cell.style["border-left-width"] = "0px";
        });
        // alter color for next row renderation
        if (colorForNonAggregated == "#f5f5f5") colorForNonAggregated = "#ffffff";
        else colorForNonAggregated = "#f5f5f5";
      });
    }

    function formatValueInCell(arg1, arg2) {
      if (!arg1) return isNaN(parseInt(arg1)) ? '' : arg1; // case where arg1 is null or 0
      if (!isNaN(arg1))
        return kendo.format("{0:" + arg2 + "}", parseFloat(arg1));
      return arg1; // case where arg1 is "Protected"
    }

    function arrowDirection(val, min, max) {
      if (!val || isNaN(val)) return '';
      if (val < min)
        // red arrow
        return '<span class="glyphicon glyphicon-arrow-down bright-red"></span>';
      else if (val > max)
        // gree arrow
        return '<span class="glyphicon glyphicon-arrow-up green"></span>';
      else
        return '';
    }

    function logDetailsViewClick(dataItem) {
      if (dataItem.isShared) {
        AuditService.logEvent(knownEventConstant.viewSubjectLocation.id,
          knownEventConstant.viewSubjectLocation.message + ' - ' + dataItem.peerGroupLocation);
      } else {
        AuditService.logEvent(knownEventConstant.editSubjectLocation.id,
          knownEventConstant.editSubjectLocation.message + ' - ' + dataItem.peerGroupLocation);
      }
    }

    $scope.logCreateNewAggregateEvent = function (){
      AuditService.logEvent(knownEventConstant.navCreateAggregate.id, knownEventConstant.navCreateAggregate.message);
    }
  }
})();
