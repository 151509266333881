var app = (function() {
  "use strict";

  config.$inject = ["$compileProvider", "$httpProvider", "$qProvider", "$locationProvider", "angularBingMapsProvider", 
    "applicationInsightsServiceProvider", "vcRecaptchaServiceProvider", "apiSettings", "environment"];

  return angular
    .module("NicMapApp", ["NicMapApp.config", "NicMapApp.routes", "NicMapApp.constants",
      "ui.bootstrap", "kendo.directives", "ui.router", "truncate", "ngMessages",
      "ngFileSaver", "ngFileUpload", "angularBingMaps", "ng.deviceDetector", "ngCookies", "angular-click-outside",
      "ngSanitize", "ngCsv", "ngAnimate", "angular-cache", "ApplicationInsightsModule", "vcRecaptcha", "com.2fdevs.videogular", 
      "com.2fdevs.videogular.plugins.controls", "com.2fdevs.videogular.plugins.overlayplay"
    ]).config(config);

  function config($compileProvider, $httpProvider, $qProvider, $locationProvider, angularBingMapsProvider, 
    applicationInsightsServiceProvider, vcRecaptchaServiceProvider, apiSettings, environment) {
    $qProvider.errorOnUnhandledRejections(false);
    $locationProvider.hashPrefix('');
    if(environment == "prod" || environment == "stage") {
      $compileProvider.debugInfoEnabled(false);
    }
    
    applicationInsightsServiceProvider.configure(
      apiSettings.applicationInsightsKey, {
        appName: environment
      });
    
    initHttpProvider();

    angularBingMapsProvider.setDefaultMapOptions({
      credentials: apiSettings.bingMapsKey,
      enableClickableLogo: false, // Microsoft copyright logo
      customizeOverlays: true, //new layout
      showMapTypeSelector: false, // Bird, aerial, etc.
      showBreadcrumb: false // Bing map search 'history' for locations
    });
    
    vcRecaptchaServiceProvider.setDefaults({
      setSiteKey: apiSettings.reCaptchaPublicKey,
      setTheme:'light',
      setSize:'compact',
      setType:'image',
      setLang:'en'
    });

    function initHttpProvider() {
      $httpProvider.interceptors.push('HtmlCacheBuster');
      $httpProvider.interceptors.push('APIInterceptor');
      $httpProvider.interceptors.push('VerifyingDataInterceptor');
      $httpProvider.interceptors.push('VersioningMiddleware');
      $httpProvider.defaults.withCredentials = true;

      //initialize [get] if not there
      if(!$httpProvider.defaults.headers.get) {
        $httpProvider.defaults.headers.get = {};
      }

      // Disable IE ajax request caching
      $httpProvider.defaults.headers.get['If-Modified-Since'] =
        'Mon, 26 Jul 1997 05:00:00 GMT';
      $httpProvider.defaults.headers.get['Cache-Control'] =
        'no-cache';
      $httpProvider.defaults.headers.get['Pragma'] = 'no-cache';
    }
  }
}());
