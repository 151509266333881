(function() {
  "use strict";

  app.controller('SiteToolsController', SiteToolsController);

  SiteToolsController.$inject = ['$rootScope', '$scope', '$state', '$uibModal', '$interval', 'AdminService', 'SignalRService', 'knownEventConstant', 'SpinnerService'];

  function SiteToolsController($rootScope, $scope, $state, $uibModal, $interval, AdminService, SignalRService, knownEventConstant, SpinnerService) {
    var syncProps = {
      inProgress: false,
      btnMessage: 'Sync',
      errorMessage: null,
      syncedOn: null
    };

    $scope.refreshLoginStatusSync = refreshLoginStatusSync;
    $scope.refreshSync = refreshSync;
    $scope.saveGeneralAlert = saveGeneralAlert;
    $scope.clearGeneralAlert = clearGeneralAlert;
    $scope.lastRefreshed = {
      cache: [],
      salesforce: {
        fullName: '',
        activityDate: null
      },
      loginStatus: {
        fullName: '',
        activityDate: null
      }
    };
    $scope.salesforceSync = angular.copy(syncProps);
    $scope.loginStatusSync = angular.copy(syncProps);
    $scope.generalAlertText = "";

    function initGeneralAlert() {
      if ($rootScope.generalAlertMessage)
      { $scope.generalAlertText = $rootScope.generalAlertMessage }
    }

    function getRecentRunSyncJobInfo() {
      SpinnerService.start();
      AdminService
        .getUserEventInfo(knownEventConstant.refreshSalesforceSync.id)
        .then(function (data) {
          $scope.salesforceSync.inProgress = false;
          $scope.salesforceSync.btnMessage = 'Sync';
          $scope.lastRefreshed.salesforce = {
            fullName: data.fullName,
            activityDate: data.activityDate,
          };
          SpinnerService.stop();
        }, function (err) {
          SpinnerService.stop();
          console.log(err);
        });
    }

    function getRecentRunActiveUsersSyncJobInfo() {
      SpinnerService.start();
      AdminService
        .getUserEventInfo(knownEventConstant.refreshActiveUsersSync.id)
        .then(function (data) {
          $scope.loginStatusSync.inProgress = false;
          $scope.loginStatusSync.btnMessage = 'Sync';
          $scope.lastRefreshed.loginStatus = {
            fullName: data.fullName,
            activityDate: data.activityDate,
          };
          SpinnerService.stop();
        }, function (err) {
          SpinnerService.stop();
          console.log(err);
        });
    }

    function isSalesforceSyncInProgress() {
      AdminService
        .isSyncInProgress('Salesforce')
        .then(function (syncStatus) {
          $scope.salesforceSync.inProgress = syncStatus;
          $scope.salesforceSync.btnMessage = syncStatus ? 'Sync in progress...' : 'Sync';
        }, function (err) {
          console.log(err);
        });
    }

    function isActiveUsersSyncInProgress() {
      AdminService
        .isSyncInProgress('ActiveUsers')
        .then(function (syncStatus) {
          $scope.loginStatusSync.inProgress = syncStatus;
          $scope.loginStatusSync.btnMessage = syncStatus ? 'Sync in progress...' : 'Sync';
        }, function (err) {
          console.log(err);
        });
    }

    function init(){
      initGeneralAlert();
      getRecentRunSyncJobInfo();
      getRecentRunActiveUsersSyncJobInfo();
      isSalesforceSyncInProgress();
      isActiveUsersSyncInProgress();
    }

    init();

    function refreshSync() {
      var syncStartedOn = new Date();
      $scope.salesforceSync.inProgress = true;
      $scope.salesforceSync.btnMessage = 'Sync in progress...';
      $scope.salesforceSync.errorMessage = null;

      AdminService
        .syncThroughSignalR('Salesforce', syncStartedOn)
        .then(function (rsp) {
          $scope.salesforceSync.inProgress = true;
          $scope.salesforceSync.btnMessage = 'Sync in progress...';
        }, function (err) {
          if (err && err.status == 400) {
            // trying to avoid api timeout issue since that will also error this call out.
            // only go here if there was an error.
            console.log(err);
            $scope.salesforceSync.inProgress = false;
            $scope.salesforceSync.btnMessage = 'Sync';
          }
        });
    }

    function refreshLoginStatusSync() {
      var syncStartedOn = new Date();
      $scope.loginStatusSync.inProgress = true;
      $scope.loginStatusSync.btnMessage = 'Sync in progress...';
      $scope.loginStatusSync.errorMessage = null;

      AdminService
        .syncThroughSignalR('ActiveUsers', syncStartedOn)
        .then(function (rsp) {
          $scope.loginStatusSync.inProgress = true;
          $scope.loginStatusSync.btnMessage = 'Sync in progress...';
        }, function (err) {
          if (err && err.status == 400) {
            // trying to avoid api timeout issue since that will also error this call out.
            // only go here if there was an error.
            console.log(err);
            $scope.loginStatusSync.inProgress = false;
            $scope.loginStatusSync.btnMessage = 'Sync';
          }
        });
    }

    SignalRService.syncComplete('syncComplete', $scope, function (message, syncedOn) {
      console.log(message);
      getRecentRunSyncJobInfo();
    });

    SignalRService.genericHubWError('syncError', $scope, function (message, syncedOn) {
      $scope.salesforceSync.errorMessage = message;
      $scope.salesforceSync.inProgress = false;
      $scope.salesforceSync.btnMessage = 'Sync';
      $scope.salesforceSync.syncedOn = syncedOn;
    });

    SignalRService.activeContactsSyncComplete('activeContactsSyncComplete', $scope, function (message, syncedOn) {
      console.log(message);
      getRecentRunActiveUsersSyncJobInfo();
    });

    SignalRService.activeContactsSyncGenericHubWError('activeContactsSyncError', $scope, function (message, syncedOn) {
      $scope.loginStatusSync.errorMessage = message;
      $scope.loginStatusSync.inProgress = false;
      $scope.loginStatusSync.btnMessage = 'Sync';
      $scope.loginStatusSync.syncedOn = syncedOn;
    });

    function saveGeneralAlert() {
      if (($scope.generalAlertText.trim() !== "") || ($scope.generalAlertText.length < 1)) {
        AdminService.saveGeneralAlert($scope.generalAlertText)
          .then(function success() {
            //nothing to do
          }, function error() {
            //save failed
          });
      }
    }

    function clearGeneralAlert() {
     AdminService.clearGeneralAlert()
       .then(function success() {
         $scope.generalAlertText = "";
       }, function error(rspErr) {
         //save failed
       });
    }

    var stopSalesforceSyncInterval = $interval(isSalesforceSyncInProgress, 5000);
    var stopActiveUsersSyncInterval = $interval(isActiveUsersSyncInProgress, 5000);
    $scope.$on('$destroy', function() {
      console.log('Interval stopped.');
      $interval.cancel(stopSalesforceSyncInterval);
      $interval.cancel(stopActiveUsersSyncInterval);
    });
  }
})();
