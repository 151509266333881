(function () {
  "use strict";

  app.controller("StepAddAggregateShare", StepAddAggregateShare);

  StepAddAggregateShare.$inject = ['$scope', '$state', '$q', 
    'CompBuilderService', 'PopupHandlerService', 'SignalRService'];
  function StepAddAggregateShare($scope, $state, $q, 
    CompBuilderService, PopupHandlerService, SignalRService) {
    $scope.$parent.nextStepAction = validation;
    $scope.$parent.previousStepAction = updateSubmissionModel;
    $scope.activeUsersGridOptions = {};
    $scope.activeUsersGridDataBound = activeUsersGridDataBound;
    $scope.selectedUsersCount = selectedUsersCount;
    $scope.isSharedChanged = isSharedChanged;
    $scope.shareAllClick = shareAllClick;
    $scope.sendEmailAllClick = sendEmailAllClick;
    $scope.$onInit = $onInit;

    $scope.$onInit();

    function $onInit() {
      $scope.activeUsersGridOptions = {
        columns: [
          {
            title: "Share",
            field: "isShared",
            headerAttributes: {
              'class': 'text-center vertical-align-middle'
            },
            filterable: {
              cell: {
                showOperators: false
              }
            },
            template: '<input type="checkbox" ng-model="dataItem.isShared" ng-change="isSharedChanged(dataItem)" />',
            attributes: {
              'class': 'text-center'
            },
            width: "49px"
          },
          {
            title: "Send<br/>Email",
            field: "isSendEmail",
            headerAttributes: {
              'class': 'text-center vertical-align-middle'
            },
            filterable: {
              cell: {
                showOperators: false
              }
            },
            template: '<input type="checkbox" ng-model="dataItem.isSendEmail" ng-disabled="!dataItem.isShared" />',
            attributes: {
              'class': 'text-center'
            },
            width: "49px"
          },
          {
            field: 'firstName',
            title: 'First Name',
            headerAttributes: {
              'class': 'vertical-align-middle'
            },
            filterable: {
              cell: {
                showOperators: false,
                operator: "contains"
              }
            }
          }, {
            field: 'lastName',
            title: 'Last Name',
            headerAttributes: {
              'class': 'vertical-align-middle'
            },
            filterable: {
              cell: {
                showOperators: false,
                operator: "contains"
              }
            }
          }, {
            field: 'email',
            title: 'Email',
            headerAttributes: {
              'class': 'vertical-align-middle'
            },
            filterable: {
              cell: {
                showOperators: false,
                operator: "contains"
              }
            }
          }
        ],
        dataBound: activeUsersGridDataBound,
        dataSource: new kendo.data.DataSource({
          transport: {
            read: function (opts) {
              opts.success($scope.$parent.submissionModel.shareInfo.userInfo);
            }
          },
          pageSize: 10,
          error: function (err) {
            console.log('error - ' + err);
          },
          schema: {
            model: {
              fields: {
                'isShared': { type: 'boolean' },
                'isSendEmail': { type: 'boolean' },
                'firstName': { type: 'string' },
                'lastName': { type: 'string' },
                'email': { type: 'string' }
              }
            }
          },
          sort: {
            field: "lastName",
            dir: "asc"
          },
          serverPaging: false,
          serverFiltering: false,
          serverSorting: false
        }),
        dataBinding: function (e) {
          Nic.kendoGrid.filtersOnTop('activeUsersGrid');
        },
        filterable: {
          mode: "row"
        },
        sortable: {
          mode: "single",
          allowUnsort: false
        },
        scrollable: false,
        pageable: true,
        resetFilters: function () {
          this.dataSource.filter({});
          this.dataSource.sort({ field: "lastName", dir: "asc" });
        }
      };
    }

    function activeUsersGridDataBound(e) {
      var sharedFilter = e.sender.thead.find("span[data-field='isShared']");
      var sendEmailFilter = e.sender.thead.find("span[data-field='isSendEmail']");
      if (sharedFilter) {
        var cbShareAll = $('<input id="cbShareAll" name="cbShareAll" type="checkbox" />');
        cbShareAll.click(function (s) {
          $scope.shareAllClick(s);
        });
        sharedFilter.wrap(cbShareAll);
      }
      if (sendEmailFilter) {
        var cbSendEmailAll = $('<input id="cbSendEmailAll" name="cbSendEmailAll" type="checkbox" />');
        cbSendEmailAll.click(function (s) {
          $scope.sendEmailAllClick(s);
        });
        sendEmailFilter.wrap(cbSendEmailAll);
      }
    }

    function shareAllClick(sender) {
      _.map($scope.activeUsersGridOptions.dataSource.data(), function (userInfo) {
        userInfo.isShared = sender.currentTarget.checked;
        $scope.isSharedChanged(userInfo);
      });
      $scope.$apply();
    }

    function sendEmailAllClick(sender) {
      _.map($scope.activeUsersGridOptions.dataSource.data(), function (userInfo) {
        userInfo.isSendEmail = userInfo.isShared && sender.currentTarget.checked;
      });
      $scope.$apply();
    }

    function selectedUsersCount() {
      return _.filter($scope.activeUsersGridOptions.dataSource.data(), 'isShared', true).length;
    }

    function isSharedChanged(dataItem) {
      if (!dataItem.isShared)
        dataItem.isSendEmail = false;
    }

    function updateSubmissionModel() {
      var sharedUsers = _.filter($scope.activeUsersGridOptions.dataSource.data(), 'isShared', true);
      $scope.$parent.submissionModel.shareInfo = {
        userInfo: sharedUsers,
        compBuilderHomeUrl: $state.href('compBuilder', {}, { absolute: true })
      };
      return $q.resolve();
    }

    function validation() {
      updateSubmissionModel();
      return CompBuilderService
        .createAggregate($scope.$parent.submissionModel)
        .then(function (rsp) {
          $scope.defer = $q.defer();

          SignalRService.genericHub('createAggregate', $scope, function(_peerGroupsubmitRsp, _requestId) {
            if (_requestId != rsp.requestId) return;
            $scope.defer.resolve(_peerGroupsubmitRsp);
          });

          SignalRService.genericHubWError('createAggregateError', $scope, function(_createAggregateErr, _requestId) {
            if (_requestId != rsp.requestId) return;
            showCreateAggregateErrorPopup();
            $scope.defer.reject([]);
          });

          return $scope.defer.promise;
        }, function (err) {
          // some unknown error?! Never a good thing
          console.error(err);
          throw {
            message: 'An unknown error occured. Please try clicking Finish again.'
          };
        })
        .finally(function () {
          SignalRService.removeHubEvent('createAggregate');
          SignalRService.removeHubEvent('createAggregateError');
        });
    }

    function showCreateAggregateErrorPopup() {
      var message = 'There was a problem saving data for <b>{aggregateName}</b> aggregate. Details have been submitted for inquiry. We will get in touch with you shortly to resolve this issue.'
        .replace('{aggregateName}', $scope.$parent.submissionModel.aggregateName);
      var cancelPopup = PopupHandlerService.openSimplePopup(message, { backdrop: 'static', keyboard: false });
      cancelPopup
        .result
        .then(function () {
          $scope.$parent.removeFormState();
          $state.go('compBuilder', {}, { reload: true });
        });
    }
  }
})();