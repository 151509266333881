(function() {
  return angular.module('NicMapApp.constants', [])
    .constant("AuthError", {
                  "Unknown": 0,
                  "Invalid": 1,
                   "Locked": 2,
      "InvalidSubscription": 3,
      "ExpiredSubscription": 4,
           "DuplicateLogin": 5,
           "isSubTerminated": 6
    })
    .constant('additionalMarketId', 3)
    .constant('subscriptionTypesConstant', {
         'port': 12,       // Portfolios
          'fun': 18,       // Fundamentals & Insights
          'loc': 19,       // Local
          'pro': 20,       // Professional
      'proport': 21,       // Professional & Portfolios
      'locport': 22,       // Local & Portfolios
      'funport': 23        // Fundamentals & Insights & Portfolios
    })
    .constant('productGroupConstant', {
            'port': 1,        // Portfolios
             'fun': 3,        // Fundamentals & Insights
             'loc': 23,       // Local
             'nat': 25,       // National
         'qualmet': 26,       // Quality Metrics
           'basic': 27,       // Basic ClientApi
      'enterprise': 28,       // Enterprise ClientApi
      'iqDataAccess': 29      // Intra-Quarterly Data Access
    })
    .constant('nationalProdGroupConstant', {
              'conMonitor': 10,      // Local
                     'mpr': 43,      // Fundamentals & Insights
          'supplyForecast': 60,      // Fundamentals & Insights
               'rcaTrends': 61       // Fundamentals & Insights
    })
    .constant('qualityMetricsProdGroupConstant', {
                     'pir': 55,      // Local
              'pointRight': 54       // Fundamentals & Insights
    })
    .constant('iqDataAccessProdGroupConstant', {
      'intraQuarterlyData': 69,     // Fundamentals & Insights
      'iqPropertyInventory': 70,    // Local
      'iqLocal': 71,                // Local
      'iqTrends': 72                // Fundamentals & Insights
    })
    .constant('recentActivitiesConstant', {
             'propertyTypeDisplayData': 104,       // Trends
  'propertyTypeDisplayDataSavedFilter': 108,       // Trends
                   'propertyTypeExcel': 105,       // Trends
                     'propertyTypePdf': 106,       // Trends
            'communityTypeDisplayData': 110,       // Trends
 'communityTypeDisplayDataSavedFilter': 114,       // Trends
                  'communityTypeExcel': 111,       // Trends
                    'communityTypePdf': 112,       // Trends
                              'search': 39,        // LocalPropertySearch
                        'runParReport': 31,        // LocalPropertySearch
                       'viewParReport': 33,        // LocalPropertySearch
                     'viewSavedSearch': 34,        // LocalPropertySearch
                 'runMostRecentSearch': 128,       // LocalPropertySearch
                         'exportComps': 20,        // PAR Report
                     'exportChartData': 27,        // PAR Report
                    'exportSalesTrans': 8,         // PAR Report
                        'exportParPdf': 133,       // PAR Report
           'propertyInformationReport': 5,         // PIR Report
       'propertyInfoReportExportExcel': 126,       // PIR Report
         'propertyInfoReportExportPdf': 127,       // PIR Report
    'propertyCompareReportExportExcel': 143,       // PCR Report
      'propertyCompareReportExportPdf': 61         // PCR Report
    })
    .constant('navigationEventConstant', {
                                   "home": { id: 116, message: "Navigation Home" },
                    "trends.propertyType": { id: 96, message: "Navigation Trends Property Type" },
                   "trends.communityType": { id: 97, message: "Navigation Trends Community Type" },
               "localpropertysearch.base": { id: 98, message: "Navigation Local Property Search" },
                                "reports": { id: 99, message: "Navigation Reports" },
                             "portfolios": { id: 100, message: "Navigation Portfolios" },
                                "support": { id: 101, message: "Navigation Support" },
                    "admin.subscriptions": { id: 102, message: "Navigation Administration" },
                                "contact": { id: 103, message: "Navigation Contact Us" },
                               "document": { id: 175, message: "Navigation Document Library" },
                                    "faq": { id: 177, message: "Navigation FAQ" },
                               "glossary": { id: 178, message: "Navigation Glossary" },
                               "coverage": { id: 179, message: "Navigation Metro Coverage Map" },
                           "videoLibrary": { id: 223, message: "Navigation Video Library" },
                     "dataReleaseWebinar": { id: 323, message: "Navigation Data Release Webinar Support page"}
    })
    .constant('recentActivitiesLinkConstant', {
                'propertyTypeDisplayData': {id: 199, message: "Home Recent Activity Trends"},         // Trends
                      'propertyTypeExcel': {id: 200, message: "Home Recent Activity Trends"},         // Trends
                        'propertyTypePdf': {id: 201, message: "Home Recent Activity Trends"},         // Trends
                  'propertyTypeGetFilter': {id: 202, message: "Home Recent Activity Trends"},         // Trends
               'communityTypeDisplayData': {id: 203, message: "Home Recent Activity Trends"},         // Trends
                     'communityTypeExcel': {id: 204, message: "Home Recent Activity Trends"},         // Trends
                       'communityTypePdf': {id: 205, message: "Home Recent Activity Trends"},         // Trends
    'communityTypeDisplayDataSavedFilter': {id: 206, message: "Home Recent Activity Trends"},         // Trends
                                 'search': {id: 207, message: "Home Recent Activity Local"},          // LocalPropertySearch
              'propertyInformationReport': {id: 208, message: "Home Recent Activity Local"},          // PIR Report
                        'viewSavedSearch': {id: 209, message: "Home Recent Activity Local"},          // LocalPropertySearch
                          'viewParReport': {id: 210, message: "Home Recent Activity Local"},          // LocalPropertySearch
                           'runParReport': {id: 211, message: "Home Recent Activity Local"},          // LocalPropertySearch
          'propertyInfoReportExportExcel': {id: 212, message: "Home Recent Activity Local"},          // PIR Report
            'propertyInfoReportExportPdf': {id: 225, message: "Home Recent Activity Local"},          // PIR Report
                    'runMostRecentSearch': {id: 213, message: "Home Recent Activity Local"},          // LocalPropertySearch
                        'exportChartData': {id: 214, message: "Home Recent Activity Local"},          // PAR Report
                       'exportSalesTrans': {id: 215, message: "Home Recent Activity Local"},          // PAR Report
                            'exportComps': {id: 216, message: "Home Recent Activity Local"},          // PAR Report
                           'exportParPdf': {id: 217, message: "Home Recent Activity Local"},          // PAR Report
                         'pcrExportExcel': {id: 226, message: "Home Recent Activity Portfolios"},     // PCR Report
                           'pcrExportPdf': {id: 227, message: "Home Recent Activity Portfolios"}      // PCR Report
    })
    .constant('knownEventConstant', {
                            'unknown': {id: 0, message: "" },                                             // leave logged product Id blank
          'propertyInformationReport': {id: 5, message: "Property Information Report" },                  // LocalPropertySearch View
                   'exportSalesTrans': {id: 8, message: "Export Sales Trans"},                            // Property Advisor Report View
                         'saveSearch': {id: 13, message: "Save Search"},                                  // LocalPropertySearch View
                            'mapMove': {id: 16, message: "Map Move"},                                     // LocalPropertySearch View
                 'exportPropertyList': {id: 19, message: "Export Property List"},                         // LocalPropertySearch View
                        'exportComps': {id: 20, message: "MAP Local Detail Comps Export"},                // Property Advisor Report View
                       'mapMoveTrans': {id: 22, message: "Map Move Transaction"},                         // LocalPropertySearch View
                    'exportChartData': {id: 27, message: "Export Chart Data" },                           // Property Advisor Report View

                       'runParReport': {id: 31, message: "Property Advisor Report"},                      // LocalPropertySearch View
            'exportPropertyListTrans': {id: 32, message: "Export Transaction Property List"},             // LocalPropertySearch View
                      'viewParReport': {id: 33, message: "Saved Property Advisor Report"},                // LocalPropertySearch View
                    'viewSavedSearch': {id: 34, message: "View Saved Search"},                            // LocalPropertySearch View
                    'showNonCoverage': {id: 37, message: "Show Non Coverage"},                            // LocalPropertySearch View
                       'showCounties': {id: 38, message: "Show Counties"},                                // LocalPropertySearch View
                             'search': {id: 39, message: "Search"},                                       // LocalPropertySearch View
                    'saveSearchTrans': {id: 40, message: "Save Transaction Search" },                     // LocalPropertySearch View

                 'adminResetPassword': {id: 84, message: "PASS CUSTOM MESSAGE BASED ON CLICK" },          // SubscriptionUsersController ResetPassword
                 'adminAddNewSubscription': {id: 319, message: "PASS CUSTOM MESSAGE BASED ON CLICK" },    // SubscriptionPopupController ProceedToSave

                              'reset': {id: 117, message: "Clicked Reset"},                               // LocalPropertySearch View
        'expandCollapseAllProperties': {id: 118, message: "Expand/Collapse All Properties"},              // LocalPropertySearch View
                     'selectProperty': {id: 119, message: "Select Property"},                             // LocalPropertySearch View
        'selectUnselectAllProperties': {id: 120, message: "Select/Unselect All Properties"},              // LocalPropertySearch View
    'selectUnselectAllPropertyRadius': {id: 237, message: "Select/Unselect All in Property Radius"},      // LocalPropertySearch View
           'selectUnselectAllPolygon': {id: 238, message: "Select/Unselect All in Polygon"},              // LocalPropertySearch View
         'selectUnselectAllRadiusOne': {id: 239, message: "Select/Unselect All in Radius 1"},             // LocalPropertySearch View
         'selectUnselectAllRadiusTwo': {id: 240, message: "Select/Unselect All in Radius 2"},             // LocalPropertySearch View
      'selectUnselectAllDriveTimeOne': {id: 241, message: "Select/Unselect All in Drive Time 1"},         // LocalPropertySearch View
      'selectUnselectAllDriveTimeTwo': {id: 242, message: "Select/Unselect All in Drive Time 2"},         // LocalPropertySearch View

                        'drawPolygon': {id: 121, message: "Draw Polygon"},                                // LocalPropertySearch View
                   'removeBoundaries': {id: 122, message: "Remove Boundaries"},                           // LocalPropertySearch View
                      'showHospitals': {id: 123, message: "Show Hospitals"},                              // LocalPropertySearch View
                           'pinClick': {id: 124, message: "Clicked Pushpin"},                             // LocalPropertySearch View
             'expandCollapseSideMenu': {id: 125, message: "Expand/Collapse Side Menu"},                   // LocalPropertySearch View
      'propertyInfoReportExportExcel': {id: 126, message: "Property Information Report"},                 // LocalPropertySearch View
         'propertyInfoReportPrintPDF': {id: 127, message: "Property Info Report Print PDF"},              // LocalPropertySearch View
                'runMostRecentSearch': {id: 128, message: "Run Most Recent search"},                      // LocalPropertySearch View
              'siteInformationReport': {id: 129, message: "Show Site Information Report"},                // LocalPropertySearch View
                         'clearCache': {id: 130, message: "Clear Redis Cache" },                          // SiteTools View
              'refreshSalesforceSync': {id: 131, message: "Refresh Salesforce Sync" },                    // SiteTools View
             'refreshActiveUsersSync': {id: 174, message: "Refresh Active Users Sync" },                  // SiteTools View
              'propertyDetailsReport': {id: 132, message: "Property Details Report" },                    // Property Details Report View
                       'parReportPdf': {id: 133, message: "Property Advisor Report Print PDF" },          // LocalPropertySearch View
                 'selectIncludeComps': {id: 135, message: "Property Compare Report Include Comps" },      // PCR
                    'selectMetroArea': {id: 136, message: "Property Compare Report Show Metro Area" },    // PCR
                    'selectPortfolio': {id: 137, message: "Select Portfolio" },                           // Portfolios
                      'selectSegment': {id: 138, message: "Select Segment" },                             // Portfolio Summary, Property Details, and PCR
         'exportPortfolioPropDetails': {id: 139, message: "Export Portfolio Property Details" },          // Portfolio Property
             'exportPortfolioSummary': {id: 140, message: "Export Portfolio Summary" },                   // Portfolio Summary
              'propertyCompareReport': {id: 141, message: "Property Compare Report" },                    // Property Compare Report View
         'siteInformationReportExcel': {id: 142, message: "SIR - Export Excel" },                         // SIR
              "cohortDemographicsPIR": {id: 144, message: "Custom Cohort Demographics PIR" },             // PIR
           'siteInformationReportPdf': {id: 229, message: "SIR - Print PDF" }, 
             'selectPortfolioDetails': {id: 151, message: "Select Portfolio Details" },                   // Portfolios
                   'selectSegmentPCR': {id: 172, message: "Property Compare Report - Select Segment"},    // PCR
               'saveSiteGeneralAlert': {id: 150, message: "Save Site General Alert Message" },            // SiteTools Save Alert
                   'faqQuestionClick': {id: 177, message: "Navigation FAQ" },                             // Support
            'documentLibraryDownload': {id: 180, message: "Document Library Download" },                  // Support
    'portfolioReportCorporateCompare': {id: 193, message: "Portfolio Report Corporate Compare" },         // Portfolios Report
     'portfolioReportMetroComparison': {id: 187, message: "Portfolio Report Metro Comparison" },          // Portfolios Report
     'portfolioReportStateComparison': {id: 188, message: "Portfolio Report State Comparison" },          // Portfolios Report
       'portfolioReportAllProperties': {id: 189, message: "Portfolio Report All Properties w/Comps" },    // Portfolios Report
         'portfolioReportRawDataFile': {id: 190, message: "Portfolio Report Raw Data File" },             // Portfolios Report
  'portfolioReportRawDataFileTrended': {id: 191, message: "Portfolio Report Raw Data File (Trended)" },   // Portfolios Report
                 'portfolioReportPCR': {id: 192, message: "Portfolio Report Property Compare Reports" },  // Portfolios Report
                     'blsExcelExport': {id: 194, message: "BLS - Excel Export"},                          // BLS Report
                    'blsRawDataExcel': {id: 195, message: "BLS - Raw Data Excel Export" },                // BLS Report
                   'navConfRecording': {id: 218, message: "Navigation Conference Recordings" },           // Navigation
                     'navInsightNews': {id: 219, message: "Navigation Insights Newsletter" },             // Navigation
                   'navNewsfeedStory': {id: 220, message: "Home Newsfeed Story" },                        // Navigation
                     'navLocalSearch': {id: 221, message: "Home Local Search" },                          // Navigation 
                      'navImageClick': {id: 222, message: "Home Image Click" },                           // Navigation
            'videoLibraryVideoPlayed': {id: 224, message: "Video Library - Video Played" },               // Support
                  'reportsSearchText': {id: 228, message: "Reports Search" },                             // Reports
                 'videoLibrarySearch': {id: 230, message: "Video Library - Search" },                     // Video
              "cohortDemographicsSIR": {id: 231, message: "Custom Cohort Demographics SIR" },             // SIR
                 "showPropertyRadius": {id: 234, message: "Show Property Radius" },                       // LocalPropertySearch View
                  "localRadiusSearch": {id: 235, message: "Local - Radius Search"},                       // LocalRadiusSearch
               "localDriveTimeSearch": {id: 236, message: "Local - Drive Time Search"},                   // LocalDriveTimeSearch
          "parSegmentDropdownChanged": {id: 243, message: "Segment Dropdown Changed"},                    // Property Advisor Report View
                   "pirShowHospitals": {id: 244, message: "PIR Map Show Hospitals"},                      // PIR Report Hospitals
           "pirShowExistingInventory": {id: 245, message: "PIR Map Show Existing Inventory"},             // PIR Report Existing Inventory
       "pirShowConstructionInventory": {id: 246, message: "PIR Map Show Construction Inventory"},         // PIR Report Construction Inventory
                   "pirShowMetroArea": {id: 247, message: "PIR Map Show Metro Area"},                     // PIR Report Metro Area
                   "sirShowHospitals": {id: 248, message: "SIR Map Show Hospitals"},                      // Sir Report Hospitals
           "sirShowExistingInventory": {id: 249, message: "SIR Map Show Existing Inventory"},             // Sir Report Existing Inventory
       "sirShowConstructionInventory": {id: 250, message: "SIR Map Show Construction Inventory" },        // Sir Report Construction Inventory
                   "sirShowMetroArea": {id: 251, message: "SIR Map Show Metro Area" },                    // Sir Report Metro Area
                    "pirShowCounties": {id: 252, message: "PIR Map Show Counties" },                      // PIR Show Counties
                    "sirShowCounties": {id: 253, message: "SIR Map Show Counties" },                      // SIR Show Counties
              "pointRightExcelExport": {id: 254, message: "PointRight - Excel Export"},                   // PointRight Report
                "propertyListAutoPAR": {id: 288, message: "Auto PAR run from Local"},                     // Property List Auto PAR
                         "pirAutoPAR": {id: 289, message: "Auto PAR run from PIR"},                       // PIR Auto PAR
              "showPlannedProperties": {id: 290, message: "Planned properties added to the map in Local"},// Show Planned Properties checked
              "selectListCompBuilder": {id: 296, message: "Selected a location list on Create New Aggregate page"}, // CompBuilder
                "editSubjectLocation": {id: 299, message: "Edit Subject Location"},                             // CompBuilder
                "viewSubjectLocation": {id: 300, message: "View Subject Location"},                             // CompBuilder
       "subjectLocationShowMetroArea": {id: 302, message: "Show Metro Area"},                             // CompBuilder
        "subjectLocationShowCounties": {id: 303, message: "Show Counties"},                               // CompBuilder
                 "navCreateAggregate": {id: 307, message: "Navigation Create New Aggregate page"},        // Compbuilder
    "dataReleaseWebinarQuarterSelect": {id: 324, message: "Data Release Webinar - Select quarter"},       // Data Release Webinar
   "dataReleaseWebinarDownloadSlides": {id: 325, message: "EVENT MESSAGE SET ON API"},                    // Data Release Webinar
    "dataReleaseWebinarDownloadNotes": {id: 326, message: "EVENT MESSAGE SET ON API"},                    // Data Release Webinar
        "dataReleaseWebinarPlayVideo": {id: 327, message: "Data Release Webinar Video Played"},           // Data Release Webinar
  })
  .constant('knownProductConstant', {
    'local': 5,
    'trans': 24,
     'port': 15
  })
  .constant('gridPageSize', 5)
  .constant('navBarHeight', 75) //Primary use for $anchorScroll.yOffset
  .constant("ChangePassError", {
    "PasswordMismatch": "The current password entered does not match our records. Please try again.",
    "ToShort": "Your new password must be at least eight (8) characters long.  Please try again.",
    "PasswordRequiresLower": "Password Requirement: Password must contain at least one lowercase letter. Please try again."
  })
  .constant("sessionStorageKeys", {
    "adminSubscriptions": "admin-all-subscriptions-page",
       "adminPortfolios": "admin-all-portfolios-page"
  })
  .constant("reportCategoryConstant", {
          "batchDataFiles": { id: 10, name: "Batch Data Files" },
    "metroRegionalReports": { id: 20, name: "Metro/Regional Reports" },
         "nationalReports": { id: 30, name: "National Reports" },
  })
  .constant('clientApiSubscriptionTypesConstant', {
    'basic': 1,       // Basic
    'enterprise': 2,       // Enterprise
  })
  .constant('reportSectionTypeConstant',{
    "existingInventory": 1,
    "construction": 2,
    "hospital": 3
  })
  .constant('compBuilderValidationErrors', {
    "None": -1,
    "NotInCoverage": 1,
    "NotInSubscription": 2,
    "MaxTradeAreaReachedNoComps": 3
  })
  .constant('fileTypeConstant', {
    "csv": "text/csv;charset=utf-8;",
    "excel": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "pdf": "application/pdf"
  })
  .constant('knownSubscriptionIds', {
    "nmv": 5180
  })
  .constant('vintageTypeConstant', {
    "quarterly": 1,
    "threeMonthRolling": 2
  })
})();
