//http://plnkr.co/edit/LbHRBB?p=preview
(function() {
  
  focusMeDirective.$inject = ['$timeout', '$parse'];
  
  angular
    .module('NicMapApp')
    .directive('focusMe', focusMeDirective);
    
  function focusMeDirective($timeout, $parse) {
    
    return {
        link: function(scope, element, attrs) {
        var model = $parse(attrs.focusMe);
        scope.$watch(model, function(value) {
          if(value === true) { 
            $timeout(function() {
              element[0].focus(); 
            });
          }
        });
        element.bind('blur', function() {
          scope.$apply(model.assign(scope, false));
        })
      }
    };
    
  }
  
})();