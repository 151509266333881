(function() {
  'use strict';
  app.factory("CompanyAuditService", CompanyAuditService);

  CompanyAuditService.$inject = ['$http', '$q', 'apiSettings'];

  function CompanyAuditService($http, $q, apiSettings) {

    return {
      query: query,
      getProducts: getProducts,
      generateExcel: generateExcel
    };

    function query(companyId, startDate, endDate) {
      var url = apiSettings.url + "/companyAudit/query";
      var queryData = {
        companyId: companyId,
        startDate: startDate,
        endDate: endDate
      };

      var deferred = $q.defer();
      $http.post(url, queryData)
        .then(function (rsp) {
          deferred.resolve(rsp.data);
        }, function (err) {
          deferred.reject(err);
        });

      return deferred.promise;
    }

    function getProducts() {
      var defer = $q.defer();
      var uri = apiSettings.url + "/companyAudit/getProducts";

      $http
        .get(uri)
        .then(function (rsp) {
          defer.resolve(rsp.data);
        }, function (err) {
          defer.reject(err);
        });

      return defer.promise;
    }

    function generateExcel(title, date, data) {
      var deferred = $q.defer();
      var uri = apiSettings.url + '/companyAudit/generateExcel?' +
        $.param({
          title: title,
          date: date
        });
      $http
        .post(uri, data, {
          responseType: 'arraybuffer'
        })
        .then(function (rsp) {
          if (rsp.data.length == 0) {
            deferred.reject();
          } else {
            deferred.resolve(rsp.data);
          }
        }, function (err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }
  }
})();
