(function () {
  "use strict"

  app.controller('GeneralAlertController', GeneralAlertController);
  GeneralAlertController.$inject = ['$rootScope', '$scope', '$state', 'AdminService', 'AuthService'];

  function GeneralAlertController($rootScope, $scope, $state, AdminService, AuthService) {
    $scope.getAlertMessage = getAlertMessage;
    $rootScope.generalAlertMessage = null;
    $scope.$root.$on("loggedIn", function() {
      getAlertMessage();
    });

    function getAlertMessage() {
      if(AuthService.identity.isAuth()) {
        AdminService.getGeneralAlert()
          .then(function(data) {
            if($rootScope.generalAlertMessage != data) {
              $rootScope.generalAlertMessage = data;
              $rootScope.$broadcast('generalAlertUpdated');
            }
          });
      }
    }
  }
}());