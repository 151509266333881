(function() {
  "use strict";

  app.controller('SubscriptionUsersController', SubscriptionUsersController);

  SubscriptionUsersController.$inject = [
    '$http', '$q', '$rootScope', '$scope', '$state', '$timeout', '$uibModal', 'apiSettings', 'knownEventConstant',
    'AdminService', 'AuditService', 'AuthService', 'Blob', 'FileSaver', 'SpinnerService', 'SubscriptionDetailsService', 'SubscriptionUsersService'];

  function SubscriptionUsersController(
    $http, $q, $rootScope, $scope, $state, $timeout, $uibModal, apiSettings, knownEventConstant,
    AdminService, AuditService, AuthService, Blob, FileSaver, SpinnerService, SubscriptionDetailsService, SubscriptionUsersService) {
    $rootScope.hideLeftNav = true; //when you enter admin the left nav should collapse

    // the subscription detail must have a subscriptionID passed in
    $scope.userData = [];
    $scope.subscriptionID = $state.params.id;
    $scope.CompanyInfo = null;
    $scope.isCallingApi = false;
    $scope.isInEditMode = false;
    $scope.recentUserNames = [];
    
    $scope.$watch('showDeactivatedUsers', function(newVal, oldVal) {
      $scope.includeDeactivatedUsers(newVal);
    });

    $scope.$on('$stateChangeStart',
      function(event, toState, toParams, fromState, fromParams){
        if($scope.isCallingApi) {
          SpinnerService.start();
          event.preventDefault(); //cancel change
          $timeout(function() {
              $scope.$apply(function() {
                $state.go(toState, toParams); //Try again
              });
            },300);
        }
      }
    );

    $scope.loginAsUser = function(rowValue) {
      var userName = rowValue.email;
      AuthService
        .impersonate(userName)
        .then(function() {
          AuthService
            .shutdownIntercom();
          AuthService
            .reAuthenticate()
            .then(function() {
              $state.go("home");
            });
        }, function() {
          alert("Unable To Impersonate (" + email + ")");
        });
    };

    $scope.resetPassword = function(rowValue) {
      SpinnerService.start();
      var resetEmailData = {
        email: rowValue.email,
        returnHomeUrl : $state.href('home', {}, {absolute: true})
      }
      $scope.isCallingApi = true;
      AuthService
        .resetPassEmail(resetEmailData)
        .then(function() {
          var companyInfo = SubscriptionDetailsService.getCompany();
          var messageToLog = 'Reset password for {userEmail} for {companyName}'
            .replace('{userEmail}', resetEmailData.email)
            .replace('{companyName}', companyInfo.companyName);
          AuditService
            .logEvent(knownEventConstant.adminResetPassword.id, messageToLog, null, null, null, $scope.subscriptionID);
          $uibModal.open({
            templateUrl: "app/partials/passwordResetConfirmPopup.html",
            controller: 'PasswordResetConfirmPopupController',
            resolve: {
              paramsPassedIn: function () { return resetEmailData; }
            }
          });
        }, function(err) {
          alert("Unable To ResetPassword (" + resetEmailData.email + ")");
          console.error(err);
        })
        .finally(function () {
          $scope.isCallingApi = false;
          SpinnerService.stop();
        });
    };

    function initGrid() {
      $scope.gridOpts = {
        columns: [{
          field: "contactId",
          title: "ContactId",
          hidden: true
        }, {
          field: "companyId",
          title: "CompanyId",
          hidden: true
        }, {
          title: "First Name",
          field: "firstName",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          title: "Last Name",
          field: "lastName",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          title: "Title",
          field: "title",
          width: '13%',
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          title: "Email",
          field: "email",
          attributes: { 'class': 'word-break-150-width' },
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          headerTemplate: '<span>Account<br>Admin</span>',
          field: "isPrimary",
          filterable: {
            cell: {
              enabled: false
            }
          },
          template: '<input type="checkbox" ng-change="setDirtyFlag(this.dataItem)" ng-model="dataItem.isPrimary" ng-disabled="isEditing() == false"  />'
        }, {
          headerTemplate: '<span>Portfolio<br>Admin</span>',
          field: "isPortfolioAdmin",
          filterable: {
            cell: {
              enabled: false
            }
          },
          template: '<input type="checkbox" #= isPortfolioAdmin ? \'checked="checked"\' : "" # disabled text-align="center" />'
        }, {
          field: "lastLogin",
          title: "Last Login",
          template: "{{ dataItem.lastLogin | nicDateFilter: 'MM/dd/yyyy hh:mm a' }}",
          filterable: {
            cell: {
              showOperators: false,
              operator: "gte"
            }
          }
        }, {
          title: "User Access",
          filterable: {
            cell: {
              enabled: false
            }
          },
          template: userAccessTemplate
        }, {
          title: "Reset Password",
          filterable: {
            cell: {
              enabled: false
            }
          },
          template: resetPasswordTemplate
        }, {
          title: "Active",
          field: "active",
          filterable: {
            cell: {
              enabled: false
            }
          },
          template: '<input type="checkbox" ng-change="setDirtyFlag(this.dataItem)" ng-model="dataItem.active" ng-disabled="isEditing() == false" text-align="center" />'
        }, {
          hidden : true,
          title: "activeOriginalValue",
          field: "activeOriginalValue",
          filterable: {
            cell: {
              enabled: false
            }
          }
        }, {
          title: " ",
          field: "dirty",
          template: '<i class="glyphicon glyphicon-pencil" aria-hidden="true" ng-show="isDirty(this.dataItem) == true"></i>',
          filterable: {
            cell: {
              enabled: false
            }
          },
          hidden: true
        }],
        dataSource: new kendo.data.DataSource({
          filter: {
            logic: "and",
            filters: []
          },
          transport: {
            read: function(opts) {
              opts.success($scope.userData);
            }
          },
          pageSize: 25,
          error: function(err) {
            console.log('error - ' + err);
          },
          schema: {
            model: {
              fields: {
                'contactId': { type: 'number' },
                'companyId': { type: 'number' },
                'firstName': { type: 'string' },
                'lastName': { type: 'string' },
                'title': { type: 'string' },
                'email': { type: 'string' },
                'phone': { type: 'string' },
                'isPrimary': { type: 'boolean' },
                'isPortfolioAdmin': { type: 'boolean' },
                'lastLogin': {type: 'date'},
                'userId': { type: 'string' },
                'active': { type: 'boolean' },
                'activeOriginalValue' : {type: 'boolean'}
              }
            },
            parse: function (response) {
              for (var i = 0; i < response.length; i++) {
                var p = response[i];
                p.activeOriginalValue = p.active;
              }
              return response;
            }
          },
          sort: {
            field: "lastName",
            dir: "asc"
          },
          serverPaging: false,
          serverFiltering: false,
          serverSorting: false
        }),
        dataBinding: function (e) {
          Nic.kendoGrid.filtersOnTop('grid');
        },
        filterable: {
          mode: "row"
        },
        scrollable: false,
        sortable: {
          mode: "single",
          allowUnsort: false
        },
        pageable: true,
        resetFilters: function () {
          this.dataSource.filter([]);
          if (!$scope.showDeactivatedUsers) {
            var fieldname = $scope.isInEditMode == true ? "activeOriginalValue" : "active";
            this.dataSource.filter({ field: fieldname, operator: "eq", value: true });
          }

          this.dataSource.sort({ field: "lastName", dir: "asc" });
        }
      };
    }

    function userAccessTemplate(dataItem){
      //'#if(active){#<a href ng-click="loginAsUser(this.dataItem)">Impersonate user</a>#}else{#Impersonate user#}#'
      if (dataItem.active && !$scope.$parent.isSubExpired) {

        var match = false;
        if ($scope.recentUserNames && $scope.recentUserNames.length > 0) {
          match = _.find($scope.recentUserNames,
            function(o) { return o.toLowerCase() == dataItem.email.toLowerCase() });
        }

        if (match || SubscriptionUsersService.isDateTimewithinTimeout(dataItem.lastLogin)) {
          return '<a href class="red-link" ng-click="loginAsUser(this.dataItem)">Impersonate User</a>';
        }

        return '<a href class="green-link" ng-click="loginAsUser(this.dataItem)">Impersonate User</a>';
      }
      return 'Impersonate User';
    }

    function resetPasswordTemplate(dataItem){
      //'#if(active){#<a href ng-click="resetPassword(this.dataItem)">Reset Password</a>#}else{#Reset Password#}#'
      if (dataItem.active && !$scope.$parent.isSubExpired) {
        return '<a href ng-click="resetPassword(this.dataItem)">Reset Password</a>';
      }
      return 'Reset Password';
    }

    function getRecentUserNames() {
      return AdminService.getRecentUserNamesFromUserEvent()
        .then(function (data) {
          $scope.recentUserNames = data;
        });
    }

    function getSubscriptionContacts() {
      return SubscriptionUsersService.getSubscriptionContacts($state.params.id, false)
        .then(function (data) {
          $scope.userData = data;
        });
    }

    function getUsers() {
      SpinnerService.start();
      var promiseGetRecentUserNamesFromUserEvent = getRecentUserNames();
      var promiseGetSubscriptionContacts = getSubscriptionContacts();
      
      $q.all([promiseGetRecentUserNamesFromUserEvent, promiseGetSubscriptionContacts]).then(function () {
        $scope.gridOpts.dataSource.read();
        initHideUsersCheckbox();
        SpinnerService.stop();
      });
    }

    function initHideUsersCheckbox() {
      $scope.showDeactivatedUsers = !_.some($scope.userData, ['active', true]);
    }

    $scope.includeDeactivatedUsers = function (includeUsers) {
      if(includeUsers === false) {
        $scope.gridOpts.dataSource.filter({ field: "active", operator: "eq", value: true });
      } else if (includeUsers === true) {
        var currFilterObj = $scope.gridOpts.dataSource.filter();
        var currentFilters = currFilterObj ? currFilterObj.filters : [];
        $scope.gridOpts.dataSource.filter(_.reject(currentFilters, { field: "active" }));
      }
    };

    $scope.exportExcel = function() {
      SpinnerService.start();
      // get the array of sort values
      // currSort = [ { dir: 'asc' || 'desc', field: columnName }, ...]
      var currSortVal = $scope.gridOpts.dataSource.sort();
      var currSort = currSortVal ? currSortVal : [];

      // if no filters selected then the currFilterObj = undefined
      var currFilterObj = $scope.gridOpts.dataSource.filter();
      // get the array of filters
      // currFilterObj.filters = [ { field: columnName, operator: operator, value: filterValue }, ...]
      var currentFilters = currFilterObj ? currFilterObj.filters : [];
      $scope.CompanyInfo = SubscriptionDetailsService.getCompany();

      var title = 'NICMAP Users ' + $scope.CompanyInfo.companyName;
      var exportedOn = new Date();
      SubscriptionUsersService
        .generateSubscriptionUsersExcel($scope.subscriptionID, title, currentFilters, currSort, $scope.showDeactivatedUsers ? 0 : 1, exportedOn)
        .then(function(data) {
          var excelFile = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          });
          var fileName = title.replace(/ /g, '_') + '.xlsx';
          SpinnerService.stop();
          FileSaver.saveAs(excelFile, fileName);
        });
    };

    $scope.associateUser = function () {
      $scope.stopEditing();
      var passInCopyOfData = angular.copy($scope.gridOpts.dataSource.data());
      var newSubscriptionUserPopup = $uibModal.open({
        templateUrl: "app/partials/subscriptionUserPopup.html",
        controller: 'SubscriptionUserPopupController',
        backdrop: 'static',
        windowClass: 'modify-access-popup',
        resolve: {
          subscriptionId: function () { return $state.params.id},
          companyId: function () { return $state.params.companyId },
          subscriptionUsers: function () { return passInCopyOfData || []; }
        }
      });

      newSubscriptionUserPopup.result.then(function (result) {
        if (result && (result.added && result.added.length != 0 || result.errors && result.errors.length != 0)) {
          var SubscriptionUserSummaryPopupContoller = ['$scope', 'saveResults', 'companyInfo', function($scope, saveResults, companyInfo) {
            $scope.saveResults = saveResults;
            $scope.companyInfo = companyInfo;
          }];

          var viewSummaryPC = $uibModal.open({
            templateUrl: "app/partials/subscriptionUserSummaryPopup.html",
            controller: SubscriptionUserSummaryPopupContoller,
            controllerAs: 'ctrl',
            backdrop: 'static',
            resolve: {
              saveResults: function () { return result; },
              companyInfo: function () { return SubscriptionDetailsService.getCompany(); }
            }
          });

          viewSummaryPC.result.then(function () {
            $rootScope.$broadcast("SilentSubscriptionDetailRefresh");
            getUsers();
            $scope.gridOpts.resetFilters();
          });
        } else {
          $rootScope.$broadcast("SilentSubscriptionDetailRefresh");
          getUsers();
          $scope.gridOpts.resetFilters();
        }
      });
    };

    $scope.isDirty = function (dataItem) {
      return dataItem.dirty;
    };

    $scope.setDirtyFlag = function (rowValue) {
      rowValue.dirty = true;
    };

    $scope.edit = function () {
      // change the filter to be on the active original column so rows don't hide automatically
      $scope.switchGridFilterIfNeeded("active", "activeOriginalValue")

      $scope.isInEditMode = true;
      var grd = $("#grid").data('kendoGrid');
      if (grd) {
        grd.showColumn("dirty");
      }
    };

    $scope.save = function () {
      if ($scope.isInEditMode) {
        var dirty = [];
        var data = $scope.gridOpts.dataSource.data();
        angular.forEach(data, function (value) {
          if (value.dirty) {
            dirty.push({
              subscriptionID: value.subscriptionId,
              contactID: value.contactId,
              sfContactID: value.contactSalesforceId,
              isPrimary: value.isPrimary,
              isDemographicUser: value.isDemographicUser,
              active: value.active,
              email: value.email
            })
          }
        });

        if (dirty.length > 0) {
          SpinnerService.start();
          $scope.isCallingApi = true;
          SubscriptionUsersService.update(dirty)
            .then(function (result) {
              SpinnerService.stop();
              $scope.isCallingApi = false;
              if (result.status == 200) {
                // the update was successful
                $rootScope.$broadcast("SilentSubscriptionDetailRefresh");
                $scope.stopEditing();
                $scope.clearAllDirtyFlags($scope.gridOpts.dataSource.data());
                getUsers();
              }
            }, function (err) {
              SpinnerService.stop();
              $scope.isCallingApi = false;
              // there was an error when updating users
              // api failed
              if (err.status == 400) {
                // err.data comes back grouped by subscription so we can just get the first one
                if (err.data && err.data.length > 0) {
                  $scope.updateDirtyFlagsToFalseForSucceededRecords(err.data[0], $scope.gridOpts.dataSource.data());
                }
              }
            });
        }
      }
    };

    $scope.stopEditing = function () {
      $scope.switchGridFilterIfNeeded("activeOriginalValue", "active")

      $scope.isInEditMode = false;
      var grd = $("#grid").data('kendoGrid');
      if (grd) {
        grd.hideColumn("dirty");
      }
    }

    $scope.cancel = function () {
      $scope.stopEditing();
      $scope.gridOpts.dataSource.read();
    };

    $scope.isEditing = function (dataItem) {
      return $scope.isInEditMode;
    };

    $scope.clearAllDirtyFlags = function (data) {
      angular.forEach(data, function (value) {
        value.dirty = false;
      });
    }

    $scope.updateDirtyFlagsToFalseForSucceededRecords = function (resultData, gridData) {
      if (resultData && gridData) {
        angular.forEach(resultData.value, function (result) {
          if (result.succeeded) {
            // find item in griddata to update
            for (var i = 0; i < gridData.length ; i++){
              var dataItem = gridData[i];
              if (dataItem.contactId == result.subscriptionContact.contactID) {
                dataItem.dirty = false;
                break;
              }
            }
          }
        });
      }
    };

    $scope.switchGridFilterIfNeeded = function (currentField, newField) {
      var currFilterObj = $scope.gridOpts.dataSource.filter();
      var currentFilters = currFilterObj ? currFilterObj.filters : [];
      for (var x = 0; x < currentFilters.length; x++) {
        if (currentFilters[x].field == currentField) {
          currentFilters[x].field = newField;
        }
      }
    };

    initGrid();
    getUsers();
  }
})();
