(function () {
  "use strict";
  
  IntegerOnlyInputDirective.$inject = ['$parse'];
  
  app.directive('integerOnlyInput', IntegerOnlyInputDirective);
  
  function IntegerOnlyInputDirective($parse) {

    function link(scope, element, attr, ngModel) {
      var reg = new RegExp(/^\d+$/);

      scope.$watch(function () {
        return ngModel.$modelValue;
      }, function (newValue, oldValue) {
        var val = newValue;
        var regMatch = reg.exec(newValue);
        if (regMatch) {
          var numMatch = parseFloat(regMatch[0]);
          val = (!attr.max || numMatch <= parseFloat(attr.max)) && (!attr.min || numMatch >= parseFloat(attr.min))
            ? regMatch[0]
            : oldValue;
        } else if (!regMatch && newValue)
          val = oldValue;

        ngModel.$setViewValue(val);
        ngModel.$render();
      });
    }

    return {
      restrict: 'A',
      require: 'ngModel',
      link: link,
      scope: {
        min: '=?',
        max: '=?'
      },
    }
  }
})();
