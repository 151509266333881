(function () {
  "use strict";

  app.factory("TransitionService", TransitionService);
  
  TransitionService.$inject = ['$rootScope'];
  
  function TransitionService($rootScope) {
    return {
      activate: activate,
      deactivate: deactivate
    };
    
    function activate(toState, fromState) {
      //Prevent transition on tabs
      var toName = toState.name.substring(0, toState.name.lastIndexOf("."));
      var fromName = fromState.name.substring(0, fromState.name.lastIndexOf("."));
      if(toState.data && fromState.data) {
        if(!(toState.data.transition === false && fromState.data.transition === false && toName == fromName)) {
          $rootScope.stateLoading = true;
        }
      }
    }
   
    function deactivate() {
      $rootScope.stateLoading = false;
    }
  }
})();
