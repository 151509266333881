(function() {
  "use strict";

  app.controller('ClientApiUsageController', ClientApiUsageController);

  ClientApiUsageController.$inject = ['$rootScope', '$scope', '$state', 'SpinnerService', 'CompanySearchService', 'CompanyAuditService', '$filter', 'DateOffsetUtils', 'FileSaver'];

  function ClientApiUsageController($rootScope, $scope, $state, SpinnerService, CompanySearchService, CompanyAuditService, $filter, DateOffsetUtils, FileSaver) {

    $scope.gridDataBinding = gridDataBinding;
    $scope.searchCompanies = CompanySearchService.search;
    $scope.formatJson = formatJson;

    $scope.searchValues = {
      company: null,
      startDate: null,
      endDate: null
    };
    $scope.lastSearchedValues = null;

    function formatJson(jsonTxt) {
      //https://stackoverflow.com/questions/44550903/styling-a-big-json-string-to-show-in-multiple-lines-instead-of-1-big-line/50044930#50044930
      return (jsonTxt.replace(/([{},:])/g, ' $1 ')).trim();
    }

    initSearchDates();

    $scope.gridData = [];
    $scope.productsFilterData = [];
    var gridProductDataSource = new kendo.data.DataSource({
      transport: {
        read: function (options) {
          options.success($scope.productsFilterData);
        }
      }
    });

    CompanyAuditService.getProducts()
      .then(function (data) {
        $scope.productsFilterData = _.sortBy(data, ['name']);
        gridProductDataSource.read();
      });
                    
    $scope.onDateChange = function (kendoEvent, otherScopeDate) {
      var sender = kendoEvent.sender;
      var selectedDate = sender.value();
      var inputName = sender.element[0].name;

      clearDateErrors();
      if (selectedDate == null || otherScopeDate == null) {
        if (selectedDate == null && otherScopeDate == null) {
          $scope.frmData.startDate.$setValidity("rangeIsRequired", false);
          $scope.frmData.endDate.$setValidity("rangeIsRequired", false);
        } else {
          $scope.frmData.startDate.$setValidity("endBeforeStart", false);
          $scope.frmData.endDate.$setValidity("endBeforeStart", false);
        }
        return;
      }

      if (inputName == 'startDate') {
        $scope.searchValues.startDate = $filter('date')(selectedDate, 'MM/dd/yyyy');
      } else {
        $scope.searchValues.endDate = $filter('date')(selectedDate, 'MM/dd/yyyy');
      }
      
      var dateCompare = DateOffsetUtils.compareDates($scope.searchValues.startDate, $scope.searchValues.endDate);
      $scope.frmData.startDate.$setValidity("endBeforeStart", dateCompare <= 0);
      $scope.frmData.endDate.$setValidity("endBeforeStart", dateCompare <= 0);
    };

    $scope.gridOpts = {
      columns: [
        {
          field: "id",
          title: "id",
          hidden: true,
          filterable: false
        }, {
          field: "environmentTypeName",
          title: "Environment",
          width: '40px',
          filterable: false
          // filterable: {
          //   cell: {
          //     showOperators: false,
          //     template: function (args) {
          //       args.element.kendoDropDownList({
          //         autoBind: false,
          //         optionLabel: " ",
          //         dataTextField: "name",
          //         dataValueField: "name",
          //         valuePrimitive: true,
          //         dataSource: new kendo.data.DataSource({
          //           data: [
          //             { name: 'Production' },
          //             { name: 'Sandbox' }
          //           ]
          //         })
          //       });
          //     }
          //   }
          // }
        }, {
          field: "eventDateNoTimeForFiltering",
          title: "Requested Date/Time",
          width: '50px',
          template: "{{ dataItem.eventDate | nicDateFilter: 'MM/dd/yyyy hh:mm a' }}",
          filterable: {
            cell: {
              showOperators: true,
              operator: "eq"
            }
          },
          sortable: {
            compare: function (a, b, descending) {
              return customEventDateCompare(a, b, descending);
            }
          }
        }, {
          field: "productName",
          title: "Data Set Requested",
          width: '50px',
          filterable: {
            cell: {
              showOperators: false,
              template: function (args) {
                args.element.kendoDropDownList({
                  autoBind: false,
                  optionLabel: " ",
                  dataTextField: "name",
                  dataValueField: "name",
                  valuePrimitive: true,
                  dataSource: gridProductDataSource
                });
              }
            }
          }
        }, {
          field: "clientKey",
          title: "API Key",
          width: '60px',
          filterable: false
        }, {
          field: "responseMessage",
          title: "Request Status",
          width: '80px',
          template: "<div class='txt-overflow pointer-cursor' uib-popover='{{ dataItem.responseMessage }}' popover-trigger='outsideClick'>{{dataItem.responseMessage}}</div>",
          filterable: {
            cell: {
              showOperators: false,
              operator: "Contains"
            }
          }
        }, {
          field: "message",
          title: "Requested Data",
          width: '80px', 
          template: formatRequstedData, 
          filterable: false
        }],
      dataSource: new kendo.data.DataSource({
        transport: {
          read: function (options) {
            options.success($scope.gridData);
          }
        },
        pageSize: 25,
        error: function (err) {
          console.log('error - ' + err);
        },
        schema: {
          model: {
            fields: {
              'id': { type: 'int' },
              'eventDate': { type: 'date' },
              'eventDateNoTimeForFiltering': { type: 'date' },
              'companyName': { type: 'string' },
              'userEventTypeName': { type: 'string' },
              'productName': { type: 'string' },
              'clientKey': { type: 'string' },
              'message': { type: 'string' },
              'responseId': {type: 'int'},
              'responseErrorCodeName': { type: 'string' },
              'responseHttpStatusCode': { type: 'int' },
              'responseMessage': { type: 'string' },
              'responseEventDate': { type: 'date' }
            }
          },
          parse: function (data) {
            for (var i = 0; i < data.length; i++) {
              var companyEvent = data[i];

              companyEvent.eventDateNoTimeForFiltering = new Date(companyEvent.eventDate);
              companyEvent.eventDateNoTimeForFiltering.setHours(0);
              companyEvent.eventDateNoTimeForFiltering.setMinutes(0);
              companyEvent.eventDateNoTimeForFiltering.setSeconds(0);
              companyEvent.eventDateNoTimeForFiltering.setMilliseconds(0);

              if (companyEvent.response) {
                companyEvent.responseId = companyEvent.response.id;
                companyEvent.responseMessage = companyEvent.response.message;
                companyEvent.responseErrorCodeName = companyEvent.response.errorCodeName;
                companyEvent.responseHttpStatusCode = companyEvent.response.httpStatusCode;
                companyEvent.responseEventDate = companyEvent.response.eventDate;
              }
            }
            return data;
          }
        },
        sort: {
          field: "eventDateNoTimeForFiltering",
          dir: "desc",
          compare: function (a, b) {
            return customEventDateCompare(a, b, true);
          }
        },
        serverPaging: false,
        serverFiltering: false,
        serverSorting: false
      }),
      dataBinding: gridDataBinding,
      filterable: {
        mode: "row",
        extra: false,
        operators: {
          date: {
            eq: "Equal to",
            gte: "On or After",
            lte: "On or Before"
          }
        }
      },
      scrollable: false,
      sortable: {
        mode: "single",
        allowUnsort: false
      },
      pageable: true,
      resetFilters: function () {
        this.dataSource.filter({});
        this.dataSource.sort(
        {
          field: "eventDateNoTimeForFiltering",
          dir: "desc",
          compare: function (a, b) {
            return customEventDateCompare(a, b, true);
          }
        });
      }
    };

    function formatRequstedData(dataItem) {
      if (!dataItem || !dataItem.message)
        return '';
      var message = formatJson(dataItem.message);
      return "<div class='txt-overflow pointer-cursor word-wrap' uib-popover='" + message + "' popover-trigger='outsideClick' popover-append-to-body='true'>" + message + "</div>";
    }

    function gridDataBinding(e) {
      Nic.kendoGrid.filtersOnTop('grid');
    }

    $scope.exportExcel = function () {
      var title = 'Client API Usage';
      var fileName = 'NICMAP_ClientAPIUsage';
      if ($scope.lastSearchedValues) {
        title = 'Client API Usage | {0} | {1} - {2}';  //Client API Usage | [Company Name] | [Start Date] - [End Date]
        title = title.replace('{0}', $scope.lastSearchedValues.company.name);
        title = title.replace('{1}', $scope.lastSearchedValues.startDate);
        title = title.replace('{2}', $scope.lastSearchedValues.endDate);

        fileName = 'NICMAP_ClientAPIUsage_{0}';    //NICMAP_ClientAPIUsage_[CompanyName]
        fileName = fileName.replace('{0}', $scope.lastSearchedValues.company.name);
      }

      SpinnerService.start();

      var clonedData = _.cloneDeep($scope.gridData);
      _.each(clonedData, function (companyEvent) {
        companyEvent.eventDate = $filter('nicDateFilter')(companyEvent.eventDate, 'MM/dd/yyyy hh:mm a');
      });

      CompanyAuditService
        .generateExcel(title, new Date(), clonedData)
        .then(function (data) {
          var excelFile = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          });
          fileName = fileName.replace(/ /g, '_') + '.xlsx';
          SpinnerService.stop()
          FileSaver.saveAs(excelFile, fileName);
        });
    };

    $scope.search = function () {
      if (!$scope.searchValues.company || $scope.frmData.$invalid) {
        return;
      }

      $scope.lastSearchedValues = _.cloneDeep($scope.searchValues);

      SpinnerService.start();
      CompanyAuditService.query($scope.searchValues.company.id, $scope.searchValues.startDate, $scope.searchValues.endDate)
        .then(function (data) {
          $scope.gridData = data;
          $scope.gridOpts.dataSource.read();
        })
        .finally(function () {
          SpinnerService.stop();
          $scope.frmData.$setPristine();
        })
    };

    $scope.onCompanySelect = function ($item, $model, $label) {
      if ($item) {
        $scope.search();
      }
    };

    $scope.resetFilters = function () {
      $scope.gridOpts.resetFilters();
      initSearchDates();
      clearDateErrors();
      $scope.search();
    };

    function initSearchDates() {
      var currentDate = new Date();
      var lastMonth = currentDate.setMonth(currentDate.getMonth() - 1);

      $scope.searchValues.startDate = $filter('date')(lastMonth, 'MM/dd/yyyy');
      $scope.searchValues.endDate =  $filter('date')(new Date(), 'MM/dd/yyyy');
    }

    function clearDateErrors() {
      // we are setting the errors on each date field so that we can get the correct css styling of the red border for errors
      $scope.frmData.startDate.$setValidity("endBeforeStart", true);
      $scope.frmData.startDate.$setValidity("rangeIsRequired", true);
      $scope.frmData.endDate.$setValidity("endBeforeStart", true);
      $scope.frmData.endDate.$setValidity("rangeIsRequired", true);
    }

    function customEventDateCompare(a, b, descending) {
      var dir = descending ? -1 : 1;

      var valueA = _.get(a, 'eventDate');
      var valueB = _.get(b, 'eventDate');

      if (_.isEqual(valueA, valueB)) {
        return 0;
      } else {
        if (_.isNil(valueA)) return -1;
        if (_.isNil(valueB)) return 1;
        return _.gt(valueA, valueB) ? 1 : -1;
      }
    }
  }
})();
