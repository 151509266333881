(function() {
  'use strict';

  angular.module('NicMapApp')
    .factory('AuditService', AuditService)
    .run(['$rootScope','AuditService','navigationEventConstant', function ($rootScope, AuditService, navEvents) {
        var tracked = navEvents;
        $rootScope.$on('$stateChangeStart', function(event, toState) {
          //These are external links and so never "Succeed", so need to check on start
          if(tracked[toState.name] && (toState.name == "coverage")) {
            AuditService.logEvent(tracked[toState.name].id, tracked[toState.name].message);
          }
        })
        $rootScope.$on('$stateChangeSuccess', function(event, toState) {
          if (tracked[toState.name]) {
            AuditService.logEvent(tracked[toState.name].id, tracked[toState.name].message);
          }
        });
    }]);

  AuditService.$inject = ['$http', 'apiSettings'];
  function AuditService($http, apiSettings) {
    function logEvent(event, msg, product, propertyId, cbsaCode, subscriptionId) {
      var url = apiSettings.url + "/audit/log";
      return $http
        .post(url, {
          'event': event,
          message: msg,
          product: product,
          propertyId: propertyId,
          cbsaCode: cbsaCode,
          subscriptionId: subscriptionId
        });
    }

    return {
      logEvent: logEvent
    };
  }
})();
