(function () {
  "use strict";

  app.controller('SubscriptionClientApiEditKeyPopupController', SubscriptionClientApiEditKeyPopupController);

  SubscriptionClientApiEditKeyPopupController.$inject = ['$scope', 'companyId', 'ClientApiService', 'data', 'SpinnerService'];

  function SubscriptionClientApiEditKeyPopupController($scope, companyId, ClientApiService, data, SpinnerService) {

    $scope.environmentTypeName = data.environmentTypeName;
    $scope.lastGenerated = data.lastModified;
    $scope.apiKey = data.apiKey;
    $scope.callsPerDay = data.callsPerDay;
    $scope.apiKeyHasBeenRegenerated = false;
    $scope.errorMessage = null;

    $scope.saveApiKey = saveApiKey;
    $scope.cancelClick = cancelClick;
    $scope.isValid = isValid;
    $scope.generateNewKey = generateNewKey;
    $scope.checkIfNeedToDisplayAsterisk = checkIfNeedToDisplayAsterisk;

    function saveApiKey() {
      if ($scope.isValid()) {
        SpinnerService.start();

        var newApiKey = null;
        if ($scope.apiKeyHasBeenRegenerated) {
          newApiKey = $scope.apiKey;
        }

        if ($scope.callsPerDay <= 0) {
          $scope.callsPerDay = 0;
        } else {
          $scope.callsPerDay = Math.floor($scope.callsPerDay);
        }

        $scope.errorMessage = null;
        ClientApiService
          .saveClientApiKey(data.id, companyId, newApiKey, $scope.callsPerDay )
          .then(function success() {
            $scope.$close();
          }, function error(response) {
            if (response && response.data) {
              $scope.errorMessage = response.data;
            } else {
              $scope.errorMessage = 'Unable to save data. Please try again.';
            }      
          })
          .finally(function () {
            SpinnerService.stop();
          });        
      }
    }

    function cancelClick() {
        $scope.$dismiss();
    }

    function isValid() {
      return ($scope.apiKey) && ($scope.callsPerDay);
    }

    function generateNewKey() {
      SpinnerService.start();
      $scope.errorMessage = null;
      ClientApiService
        .generateApiKey()
        .then(function success(data) {
          $scope.apiKey = data;
          $scope.apiKeyHasBeenRegenerated = true;
        }, function error() {
          $scope.errorMessage = 'Unable to generate key api key.  Please try again.';
        })
        .finally(function () {
          SpinnerService.stop();
        });
    }

    function checkIfNeedToDisplayAsterisk() {
      return !$scope.callsPerDay;
    }
  }
}());