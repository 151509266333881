(function () {
  "use strict";

  app.controller("StepAddAggregateLocation", StepAddAggregateLocation);

  StepAddAggregateLocation.$inject = ['$scope', '$uibModal', 'apiSettings', '$q', 'CompBuilderService', 'GeocodeService', 'SpinnerService', 'AuditService', 'knownEventConstant', 'Upload'];
  function StepAddAggregateLocation($scope, $uibModal, apiSettings, $q, CompBuilderService, GeocodeService, SpinnerService, AuditService, knownEventConstant, Upload) {

    $scope.submission = $scope.$parent.submissionModel;
    $scope.submission.peerGroups = $scope.submission.peerGroups || [];  // this is what gets saved which will be sent on to the next step
    $scope.$parent.nextStepAction = validation;
    $scope.$parent.previousStepAction = validateFormOnBackClick;

    $scope.subjectLocation = null;
    $scope.editMode = false;
    $scope.validationErrors;
    $scope.hasValidationErrors = false;
    $scope.hasStatusMessage = false;
    $scope.statusMessage = '';
    var maxRows = 100; 

    // allowed types of uploads
    var allowedMimeTypes = [
      '.csv',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ];

    $scope.peerGroupGridOptions = {
      columns: [
        {
          title: 'Delete',
          template: '<a ng-class="{ \'disabled\': editMode }" ng-click="deleteRow(dataItem)"><span class="k-icon glyphicon glyphicon glyphicon-minus-sign primary-blue-dark"></span></a>',
          width: '69px',
          attributes: {
            "class": "text-center"
          },
          headerAttributes: {
            style: "text-align: center"
          }
        },
        {
          title: 'Edit',
          template: '<a ng-class="{ \'disabled\': isEditButtonDisabled(dataItem) }" class="k-grid-edit" ng-show="!isRowBeingEdited(dataItem)" ng-click="editRow(dataItem)"><span class="k-icon k-i-close"></span></a>\
                    <span style="display: none;visibility:hidden" class="stopButtonsFlashingOnAdd" ng-show="isRowBeingEdited(dataItem)">\
                      <a class= "k-grid-update"  ng-click="updateRow(dataItem)"> <span class="k-icon k-i-close"></span></a >\
                      <a class="k-grid-cancel"  ng-click="cancelEdit(dataItem)"><span class="k-icon k-i-close"></span></a>\
                    </span>'
        ,
          width: '69px',
          attributes: {
            "class": "text-center stopFlashingOnAdd"
          },
          headerAttributes: {
            style: "text-align: center"
          }
        },
        {
          name: 'Subject Location',
          field: 'subjectLocation',
          title: 'Subject Location'
        },
        {
          field: 'id',
          hidden: true
        }

      ],
      noRecords: {
        template: "Enter a Subject Location and click Add or click Import File to get started."
      },
      dataBound: function (e) {
        // https://www.telerik.com/forums/custom-class-to-row-based-on-dataitem
        var items = e.sender.items();
        _.each(items, function (item) {
          var item2 = e.sender.dataItem(item);
          if (item2.isValid == false) {
            $(item).addClass('bright-red');
          }
        });
      },
      dataSource: new kendo.data.DataSource({
        pageSize: 10,
        transport: {
          read: function (options) {

            var gridData = _.map($scope.submission.peerGroups, function (row) {
              var newObject = {
                id: row.peerGroup.id,
                subjectLocation: row.peerGroup.subjectLocation,
                cbsa: row.cbsa,
                countyFIPS: row.countyFIPS,
                latitude: row.latitude,
                longitude: row.longitude,
                isValid: row.isValid,
                reason: row.reason
              };

              return newObject;
            });

            options.success(gridData);
          },
          update: function (e) {
            SpinnerService.start();

            GeocodeService.geocode(e.data.subjectLocation).then(function (addressModel) {
              e.data = $scope.updateObjectWithGeoCode(e.data, addressModel.value);
            }, function (err) {
              //if there was some kind of error, just null out the lat and long
              e.data.latitude = null;
              e.data.longitude = null;
              console.log(err);
            })
            .finally(function () {
              var peerGroupToUpdate = _.find($scope.submission.peerGroups, function (row) {
                if (row && row.peerGroup && row.peerGroup.id == e.data.id) {
                  return row;
                }
              });
              if (peerGroupToUpdate) {
                peerGroupToUpdate.peerGroup.subjectLocation = e.data.subjectLocation;
                peerGroupToUpdate.latitude = e.data.latitude;
                peerGroupToUpdate.longitude = e.data.longitude;
              }

              $scope.editMode = false;
              $scope.currentlyEditedRow = null;
              $scope.$parent.form.$setValidity('editmode', true, $scope.$parent);
              SpinnerService.stop();
              e.success();
            });
          },
          destroy: function (e) {
            _.remove($scope.submission.peerGroups, function (row) {
              if (row && row.peerGroup && row.peerGroup.id == e.data.id) {
                return true;
              }
            });

            e.success();
          }
        },
        schema: {
          model: {
            id: 'id',
            fields: {
              'id': { type: 'number' },
              'subjectLocation': {
                type: 'string',
                validation: {
                  required: true,
                  subjectLocationvalidation: function (input) {
                    var value = input[0].value;
                    return value && value.trim() != '';
                  }
                }
              },
              'isValid': {
                type: 'boolean'
              }
            }
          }
        },
        sort: {
          field: 'subjectLocation',
          dir: 'desc'
        },
        serverPaging: false,
        serverFiltering: false,
        serverSorting: false
      }),
      scrollable: false,
      pageable: true,
      editable: {
        mode: 'inline',
        confirmation: false // We handle this ourselves.
      },
      edit: function (e) {
        var input = e.container.find("[name=subjectLocation]");

        input.keyup(function (k) {
          var btn = $(input.prevObject[0]).find(".k-grid-update");
          var value = k.target.value;
          if (value && value.trim() != '') {
            btn.removeClass("k-state-disabled");
          }
          else {
            btn.addClass("k-state-disabled");
          }
        });
      },
      cancel: function(e) {
        if (e.model.isValid == false){
            this.refresh();
        }
      },
      sortable: {
        mode: 'single',
        allowUnsort: false
      },
      resetFiltersAndSorts: function () {
        this.dataSource.filter([]);
        this.dataSource.sort([{ field: 'isValid', dir: 'asc' }, { field: 'subjectLocation', dir: 'asc' }, { field: 'id', dir: 'desc' }]);
      }
    };

    $scope.addNewPeerGroupData = function (subjectLocation, geocode) {
      var newSubmissionModelPeerGroup = {
        peerGroup: {
          id: Date.now(),
          subjectLocation: subjectLocation
        }
      };
      $scope.submission.peerGroups.unshift($scope.updateObjectWithGeoCode(newSubmissionModelPeerGroup, geocode));
    };

    $scope.addNewPeerGroupDataArray = function (data) {
      var peerGroups = data.map(function (loc, idx) {
        if(loc.geocode == null){
          return $scope.updateObjectWithGeoCode({peerGroup: {id: Date.now() + idx, subjectLocation: loc.location}}, null);
        }else{
          return $scope.updateObjectWithGeoCode({peerGroup: {id: Date.now() + idx, subjectLocation: loc.location}}, loc.geocode);
        }
      })
      _.forEach(peerGroups, function(row){
        $scope.submission.peerGroups.unshift(row);
      });  
    };

    $scope.updateObjectWithGeoCode = function (row, geocode) {
      row.latitude = null;
      row.longitude = null;
      if (geocode && geocode.latitude) {
        row.latitude = geocode.latitude;
      }
      if (geocode && geocode.longitude) {
        row.longitude = geocode.longitude;
      }

      return row;
    };

    $scope.isAddButtonDisabled = function() {
      return !$scope.subjectLocation || $scope.editMode || ($scope.submission.peerGroups.length >= maxRows);
    };

    $scope.isSelectListButtonDisabled = function() {
      return $scope.editMode || ($scope.submission.peerGroups.length >= maxRows);
    }

    $scope.fileUploadAccept = allowedMimeTypes.join(', ');
    
    $scope.upload = function (file) {
      // if no file, opt out
      if (!file) return;

      AuditService.logEvent(knownEventConstant.selectListCompBuilder.id,
                            knownEventConstant.selectListCompBuilder.message);

      // if file does exist, verify that mime type is allowed
      if (!_.includes(allowedMimeTypes, file.type)) {
        $scope.statusMessage = 'Invalid file format. Only XLS, XLSX, or CSV file types are supported.';
        $scope.hasStatusMessage = true;
        return;
      }

      SpinnerService.start();
      $scope.hasStatusMessage = false;
      $scope.statusMessage = '';
      $scope.file = file;
      // upload file
      var data = {
        file: file
      };
      Upload.upload({
        url: apiSettings.url + '/compbuilder/upload',
        data: data,
        bypassErrorInterceptor: true
      })
      .progress(function (evt) {
        // to track the progress of the upload
        console.log(evt);
      })
      .success(function (data, status, headers, config) {
        $scope.hasStatusMessage = true;
        if(($scope.submission.peerGroups.length + data.length) >= maxRows){
          var numToImport = maxRows - $scope.submission.peerGroups.length;
          $scope.statusMessage = 'Subject location limit reached. The first ' + numToImport + ' locations were successfully imported.';
          $scope.addNewPeerGroupDataArray(data.slice(0, numToImport));
        }else{
          $scope.statusMessage = data.length + ' locations were successfully imported.';
          $scope.addNewPeerGroupDataArray(data);
        }
        
        //new records need to be in first row, so auto set the page and sort to show user the first rows
        if ($scope.peerGroupGridOptions.dataSource.page() != 1) {
          $scope.peerGroupGridOptions.dataSource.page(1);
        }
        var sortedBy = $scope.peerGroupGridOptions.dataSource.sort();
        if (sortedBy && Array.isArray(sortedBy) && sortedBy[0].field != 'id') {
          $scope.peerGroupGridOptions.dataSource.sort({ field: 'id', dir: 'desc' });
        }
        $scope.peerGroupGridOptions.dataSource.read();
        $scope.subjectLocation = null;
        SpinnerService.stop();
      })
      .error(function (data, status, headers, config) {
        SpinnerService.stop();
        $scope.statusMessage = data.Message;
        $scope.hasStatusMessage = true;
      });
    }
    
    $scope.addButtonClick = function () {
      if ($scope.isAddButtonDisabled()) { return; }
      
      if($scope.submission.peerGroups.length >= maxRows - 1){
        $scope.hasStatusMessage = true;
        $scope.statusMessage = 'Subject location limit reached. Additional subject locations cannot be added.';
      }else{
        $scope.hasStatusMessage = false;
        $scope.statusMessage = '';
      }
      
      var geocode;
      SpinnerService.start();
      GeocodeService.geocode($scope.subjectLocation).then(function (addressModel) {
        geocode = addressModel.value;
      }, function (err) {
          console.log(err);
          geocode = {
            latitude : null,
            longitude : null
          }
         })
      .finally(function () {
        $scope.addNewPeerGroupData($scope.subjectLocation, geocode);
        //new records need to be in first row, so auto set the page and sort to show user the first rows
        if ($scope.peerGroupGridOptions.dataSource.page() != 1) {
          $scope.peerGroupGridOptions.dataSource.page(1);
        }
        var sortedBy = $scope.peerGroupGridOptions.dataSource.sort();
        if (sortedBy && Array.isArray(sortedBy) && sortedBy[0].field != 'id') {
          $scope.peerGroupGridOptions.dataSource.sort({ field: 'id', dir: 'desc' });
        }
        $scope.peerGroupGridOptions.dataSource.read();
        $scope.subjectLocation = null;
        SpinnerService.stop();
      });
    };

    $scope.getValidationErrors = function() {
      var hasErrors = $('#peerGroupGrid .k-invalid').length;
      $scope.hasValidationErrors = hasErrors > 0;
    };

    $scope.editRow = function(row) {
      $scope.editMode = true;
      $scope.currentlyEditedRow = row.id;
      $scope.$parent.form.$setValidity('editmode', false, $scope.$parent);
    };

    $scope.updateRow = function (row) {
      if ($scope.hasValidationErrors) { return; }

      if (!row.dirty) {
        $scope.editMode = false;
        $scope.currentlyEditedRow = null;
        $scope.$parent.form.$setValidity('editmode', true, $scope.$parent);
      }
    };

    $scope.deleteRow = function (row) {
      var confirmDeletePopup = $uibModal.open({
        templateUrl: 'app/partials/confirmDeletePopup.html',
        controller: ['$scope', 'deleteName', function ($scope, deleteName) {
          $scope.deleteName = deleteName;
        }],
        resolve: {
          deleteName: function () { return row.subjectLocation; }
        }
      });
      confirmDeletePopup.result.then(function (confirmDeleteResult) {
        if (confirmDeleteResult) {
          $scope.peerGroupGridOptions.dataSource.remove(row);
          $scope.peerGroupGridOptions.dataSource.sync();
          $scope.hasStatusMessage = false;
          $scope.statusMessage = '';
        }
      });
    };

    $scope.cancelEdit = function (row) {
      $scope.editMode = false;
      $scope.currentlyEditedRow = null;
      $scope.hasValidationErrors = false;
      $scope.$parent.form.$setValidity('editmode', true, $scope.$parent);
    };

    $scope.isRowBeingEdited = function (row) {
      return $scope.editMode && (row.id === $scope.currentlyEditedRow);
    };

    $scope.isEditButtonDisabled = function (row) {
      return $scope.editMode && (row.id !== $scope.currentlyEditedRow);
    };

    $scope.showErrorPopup = function (showDeleteAndContinueAction) {
      var confirmValidationPopup = $uibModal.open({
        templateUrl: 'app/partials/compbuilder/aggregateSubjectLocationValidationErrorPopup.html',
        controller: ['$scope', 'errors', '$state', '$window', 'showDeleteAndContinue', function ($scope, errors, $state, $window, showDeleteAndContinue) {
          this.errors = errors;
          this.showDeleteAndContinue = showDeleteAndContinue;
        }],
        controllerAs: 'popup',
        resolve: {
          errors: function () { return $scope.validationErrors; },
          showDeleteAndContinue: function(){ return showDeleteAndContinueAction; }
        }
      });
      return confirmValidationPopup.result;
    };

    $scope.removeAllInvalidRows = function (peerGroups) {
      _.remove(peerGroups, function (row) {
        if (row && row.isValid == false) {
          return true;
        }
      });
    };

    function validation() {
      var peerGroupsToValidate = _.map($scope.submission.peerGroups, function (row) {
        var transformed = { peerGroup: row.peerGroup };
        if (row.latitude && row.longitude ) {
          transformed.geocode = {
            latitude: parseFloat(row.latitude),
            longitude: parseFloat(row.longitude)
          };
        }

        return transformed;
      });

      return CompBuilderService
        .validate(peerGroupsToValidate)
        .then(function (validationResults) {
          $scope.validationErrors = validationResults.filter(function (error) {
            return error.isValid == false;
          });

          //merge the results into the submission model which are in local storage
          _.forEach($scope.submission.peerGroups, function (peerGroup) {
            var validationResult = _.find(validationResults, function(vr) {
              if (vr.peerGroup.id == peerGroup.peerGroup.id)
                return vr;
              });

            if (validationResult) {
              peerGroup.cbsa = validationResult.cbsa;
              peerGroup.countyFIPS = validationResult.countyFIPS;
              peerGroup.latitude = validationResult.latitude;
              peerGroup.longitude = validationResult.longitude;
              peerGroup.isValid = validationResult.isValid;
              peerGroup.reason = validationResult.reason;
            }
          });

          if (_.some(validationResults, ['isValid', false])) {
            SpinnerService.stop();

            return $scope.showErrorPopup(_.some(validationResults, ['isValid', true])).then(function (manualOrAutoSelected) {
              if (manualOrAutoSelected == "Auto") {
                SpinnerService.start();
                $scope.removeAllInvalidRows($scope.submission.peerGroups);
                $scope.validationErrors = null;

                if (!$scope.submission.peerGroups || $scope.submission.peerGroups.length == 0) {
                  //in case we auto deleted all the rows, don't allow the next to happen
                  $scope.peerGroupGridOptions.dataSource.read();
                  throw { message: '' };
                }
              }
              else {
                $scope.peerGroupGridOptions.dataSource.read();
                if ($scope.peerGroupGridOptions.dataSource.page() != 1) {
                  $scope.peerGroupGridOptions.dataSource.page(1);
                }
                $scope.peerGroupGridOptions.resetFiltersAndSorts();

                throw { message: '' };
              }
            });
          }
        }, function (err) {
          console.log(err);
          //if there was some kind of server error, default all to be invalid
          $scope.validationErrors = _.map($scope.submission.peerGroups, function (element) {
            return _.extend({}, element, { isValid: false, reason: 'unknown' });
          });
        });
    }

    function validateFormOnBackClick() {
      $scope.$parent.form.$setValidity('HasAtLeast1SubjectLocation', true, $scope.$parent);
      return $q.resolve();
    }

    $scope.$watchCollection(function() { return $scope.submission.peerGroups; }, function () {
      $scope.$parent.form.$setValidity('HasAtLeast1SubjectLocation', $scope.submission.peerGroups.length > 0, $scope.$parent);
    });
  }
})();
