(function() {
  "use strict";

  app.controller('LocalPropertySearchController', LocalPropertySearchController);

  LocalPropertySearchController.$inject = ['$rootScope', '$scope', '$state', '$stateParams', '$q', 'PropertyExport', 'SearchService', 'PortfolioService', 'BingGeoDataService', 'GeoDataService', 'BingMapUtils',
    'initialBingResult', 'currentQuarter', 'propertyTypes', 'campusTypes', 'segmentTypes', 'ageCategories', 'vintages', 'allRadiusList', 'savedSearches', 'PinFactory', 'FilterMapper',
    'PropertyAdvisorService', '$sce', 'HospitalsService', 'PlannedPropertyService', '$window', 'PopupHandlerService', 'savedPARReports', 'AuditService', 'knownEventConstant', 'knownProductConstant', 'vintageTypeConstant', '$anchorScroll', '$location', 'SpinnerService',
    'pushpinImages', '$timeout', 'PropertyDetailsService', 'WalkScoreService'
  ];

  function LocalPropertySearchController($rootScope, $scope, $state, $stateParams, $q, PropertyExport, SearchService, PortfolioService, BingGeoDataService, GeoDataService, BingMapUtils,
    initialBingResult, currentQuarter, propertyTypes, campusTypes, segmentTypes, ageCategories, vintages, allRadiusList, savedSearches, pinFactory, FilterMapper,
    PropertyAdvisorService, $sce, HospitalsService, PlannedPropertyService, $window, PopupHandlerService, savedPARReports, AuditService, knownEventConstant, knownProductConstant, vintageTypeConstant, $anchorScroll, $location, SpinnerService, 
    pushpinImages, $timeout, PropertyDetailsService, WalkScoreService) {
    
    //Local Variables
    var bingMap;
    var trusted = {};
    var selectedSearchId;
    var ignoreSavedDisplayChangedEvent;
    var isDestroyed;
    var magicObjectToGetKendoToSelectFirstOption = {name: ""};
    var previousBounds;
    var currentSearch;
    var searchDetails; //parking spot for lat/long, zip, county, state returned from bing search
    var savedFilter;
    var savePopupModalInstance;
    var propertiesOwnedByUser;
    var clearPropertyRadiusFlag = false;
    var popupController = ['$scope', function($scope) {
      $scope.isExistingName = isExistingName;
      $scope.filterNameEnterClick = filterNameEnterClick;
      $scope.saveType = 'search';
      $scope.placeholderText = 'Search Name';
    }];
    var forceSearchInBingMapViewChange = false;
    $scope.addressSearchReRun = false;

    /* begin scope-only-initilization section*/
    $scope.isPARRunning = true; //To disable run PAR button onload.
    $scope.expandedPropertiesDisplayCount = initPropertiesDisplayCount();
    $scope.fullyCollapsed = calcFullyCollapsedState(); // defaults to true
    $scope.fullyExpanded = false;
    $scope.waitingForSearchTimeout = false;
    $scope.checkAllButton = false;
    $scope.canRunPars = canRunPars;
    $scope.checkAll = checkAll;
    $scope.propertySelectedCount = 0;
    $scope.stakeholdersSelected = 0;
    $rootScope.showAlertMessage = checkAlertMessage();
    $scope.tradeAreaFlags = {
      radiusOnly: false,
      driveTimeOnly: false,
      customPolygonOnly: false
    };
    $scope.showTradeAreaOne = false;
    $scope.showTradeAreaTwo = false;
    $scope.properties = {
      items: [],
      totalPropertiesWIdCount: 0,
      inTradeAreaOneCount: 0,
      inTradeAreaTwoCount: 0,
      inPropertyRadiusCount: 0,
      showResultsCount: false
    };

    $scope.pinClusters = [];
    $scope.cbsaPins = [];
    $scope.showPinClusters = false;
    $scope.showCbsaPins = true;
    $scope.cbsaPinZoomLevelCutoff = 8; // Defines which zoom level do CBSA pins change over to Property Pin Clusters
    $scope.pinClusterOptions = {
      gridSize: 90,
      clusteredPinCallback: function (cluster) { pinFactory.clusterPin(cluster, bingMap, $scope); }
    }

    var bingMapWidth = null;
    var tradeAreaBoundsTracker = {}; // This will track the bounds where all points were shown on the map for tradeArea 

    function resetAllPropertyCounts() {
      var totalPropertiesWIdCount = 0, inTradeAreaOneCount = 0, inTradeAreaTwoCount = 0, inPropertyRadiusCount = 0;
      var showResultsCount = false;
      _.each($scope.properties.items, function (property) {
        if (!property.id) return; // property with no id should not get counted
        if (property.transactionId) showResultsCount = true;
        totalPropertiesWIdCount++;
        if (property.isInTradeAreaOne || property.isInUserPolygon) inTradeAreaOneCount++;
        if (property.isInTradeAreaTwo) inTradeAreaTwoCount++;
        if (property.isInPropertyRadius) inPropertyRadiusCount++;
      });
      $scope.properties.showResultsCount = showResultsCount;
      $scope.properties.totalPropertiesWIdCount = totalPropertiesWIdCount;
      $scope.properties.inTradeAreaOneCount = inTradeAreaOneCount;
      $scope.properties.inTradeAreaTwoCount = inTradeAreaTwoCount;
      $scope.properties.inPropertyRadiusCount = inPropertyRadiusCount;
    }

    //used with both child tabs, so just define here
    $scope.filterOptions = {
      valueTemplate: '<span>{{dataItem.name}}</span>',
      template: '<span class="k-state-default">{{dataItem.name}}</span><span class="pull-right"><button data-id="{{dataItem.id}}" type="button" class="close deleteFilter"><span aria-hidden="true">&times;</span></button></span>'
    }
    $scope.deleteMessage = {
      savedSearch: 'Do you want to delete SAVED_NAME search?',
      savedPAR: 'Do you want to delete SAVED_NAME saved report?'
    };
    $scope.deleteSavedSearch = deleteSavedSearch;
    $scope.deleteSavedPAR = deleteSavedPAR;
    $scope.setSavedSearchNameOrId = setSavedSearchNameOrId;

    function assignDriveTimePropertyForExport(property, exportProperties) {
      var defaultProperty = { 'driveTimeArea': 'N/A' };
      if (!exportProperties.shape1.name || !property.id) return defaultProperty;

      var tracker = [];
      if (property.isInTradeAreaOne) tracker.push(exportProperties.shape1.name);
      if (exportProperties.shape2.name && property.isInTradeAreaTwo) tracker.push(exportProperties.shape2.name);
      return tracker.length > 0 ? { 'driveTimeArea': tracker.join(', ') } : defaultProperty;
    }

    $scope.exportSearchResultSelections = function () {
      var bounds = BingMapUtils.boundsFromMap(bingMap);
      var exportProperties = {
        shape1: {
          name: null,
          locs: [],
          fillColor: [],
          strokeColor: []
        },
        shape2: {
          name: null,
          locs: [],
          fillColor: [],
          strokeColor: []
        },
        hospitals: [],
        plannedProperties: [],
        zoom: BingMapUtils.getCurrentZoom(bingMap),
        width: bingMapWidth,
        latitude: bounds.latitude,
        longitude: bounds.longitude,
        searchArea: savedFilter.query,
        sitePin: null,
        vintageId: savedFilter.search.vintageId
      };

      if ($scope.customSearch.options && $scope.customSearch.options.visible &&
        $scope.customSearch.latitude && $scope.customSearch.longitude) {
        exportProperties.sitePin = {
          pinNumber: -1,
          latitude: $scope.customSearch.latitude,
          longitude: $scope.customSearch.longitude,
          visible: true,
          customMapPin: null
        };
      }

      if (isDriveTimeOneSearch()) {
        var driveTimeInfo = savedFilter.search.driveTimeInfo;
        var driveTime1Color = GeoDataService.getColors(driveTimeInfo.drive1.trafficFlow);
        exportProperties.shape1.name = driveTimeInfo.drive1.breakValue + ' Min ' + driveTimeInfo.drive1.trafficFlow;
        exportProperties.shape1.locs = driveTimeInfo.drive1.driveTimeLocs;
        exportProperties.shape1.strokeColor = driveTime1Color.argbStrokeColor;
        exportProperties.shape1.fillColor = driveTime1Color.argbFillColor;
        if (isDriveTimeTwoSearch()) {
          var driveTime2Color = GeoDataService.getColors(driveTimeInfo.drive2.trafficFlow);
          exportProperties.shape2.name = driveTimeInfo.drive2.breakValue + ' Min ' + driveTimeInfo.drive2.trafficFlow;
          exportProperties.shape2.locs = driveTimeInfo.drive2.driveTimeLocs;
          exportProperties.shape2.strokeColor = driveTime2Color.argbStrokeColor;
          exportProperties.shape2.fillColor = driveTime2Color.argbFillColor;
        }
      } else if (isRadiusOneSearch()) {
        exportProperties.shape1.locs = $scope.searchRadius.locations;
        if (isRadiusTwoSearch()) exportProperties.shape2.locs = $scope.searchRadius2.locations;
      } else if (isUserPolygonSearch()) {
        exportProperties.shape1.locs = $scope.filter.userDrawingLocs;
      } else if (isUserPolygonSearchButtonSearch()){
        exportProperties.shape1.locs = savedFilter.search.userDrawingLocs;
      } else { /*NON-TRADE AREA SEARCH*/ }

      var propertiesToExport = _.chain(_.cloneDeep($scope.properties.items))
        .map(function (obj) { return _.extend(obj, assignDriveTimePropertyForExport(obj, exportProperties)); })
        .value();
      getPlannedPropertyData(true)
        .then(function (plannedProps) {
          exportProperties.plannedProperties = plannedProps;
          return getHospitalData();
        })
        .then(function(hospitals) {
          if(hospitals.apiSuccess)
            exportProperties.hospitals = hospitals.data;
          PropertyExport.openOrExport(propertiesToExport, savedFilter, currentQuarter, exportProperties);
        }, function(err) {
          PropertyExport.openOrExport(propertiesToExport, savedFilter, currentQuarter, exportProperties);
        });
      if ($scope.filter.transactionOnly) {
        AuditService.logEvent(knownEventConstant.exportPropertyListTrans.id, knownEventConstant.exportPropertyListTrans.message, $scope.product);
      } else {
        AuditService.logEvent(knownEventConstant.exportPropertyList.id, knownEventConstant.exportPropertyList.message, $scope.product);
      }
    };

    $scope.searchStakeHolders = SearchService.searchStakeHolders;
    $rootScope.hideLeftNav = true; //when you enter a product section the left nav should collapse
    $scope.hideLegend = true;
    $scope.hideEditMap = false;

    $scope.product = {};
    $scope.propertyRadiusOptions = {
      data: _.filter(angular.copy(allRadiusList), function(radius) { return radius.id >= 1}),
      radius: 10
    }
    $scope.highlightedProperty = null;

    $scope.bingMapResized = function (newWidth) {
      bingMapWidth = newWidth;
      if (!$scope.fullyCollapsed && !$scope.waitingForSearchTimeout) {
        viewChange();
      }
    };

    $scope.filters = {
      campusTypes: campusTypes,
      segmentTypes: segmentTypes,
      propertyTypes: propertyTypes,
      ageCategories: customSortAgeCategories(),
      radius: allRadiusList,
      isForProfit: SearchService.getProfitStatus(),
      constructionStatuses: SearchService.constructionStatuses(),
      driveTimeOptions: SearchService.getDriveTimeOptions(),
      vintages: vintages,
    };

    $scope.driveTimeEsriPolygons = [];

    $scope.$watch('filter.transactionOnly', function() {
      $scope.product = $scope.filter.transactionOnly ? knownProductConstant.trans : knownProductConstant.local;
    });

    $scope.tradeAreaDetailsClick = function() {
       // pass in default setting all the time. so no need to check for nulls later on       
      var bounds = BingMapUtils.boundsFromMap(bingMap);
      var sirInfo = {
        bbox: bounds.bbox,
        shape1: {
          radius: 5,
          driveTime: {
            breakValue: null,
            trafficFlow: null
          },
          hasShape: true
        },
        shape2: {
          radius: 10,
          driveTime: {
            breakValue: null,
            trafficFlow: null
          },
          hasShape: true
        },
        vintageId: savedFilter.search.vintageId
      };
      if (isDriveTimeOneSearch()) {
        var driveTimeInfo = savedFilter.search.driveTimeInfo;
        sirInfo.bbox = tradeAreaBoundsTracker.bbox;
        sirInfo.shape1.driveTime.breakValue = driveTimeInfo.drive1.breakValue;
        sirInfo.shape1.driveTime.trafficFlow = driveTimeInfo.drive1.trafficFlow;
        sirInfo.shape1.radius = null;
        sirInfo.shape2.radius = null;
        if (isDriveTimeTwoSearch()) {
          sirInfo.shape2.driveTime.breakValue = driveTimeInfo.drive2.breakValue;
          sirInfo.shape2.driveTime.trafficFlow = driveTimeInfo.drive2.trafficFlow;
        } else {
          sirInfo.shape2.hasShape = false;
        }
      } else if (isRadiusOneSearch()) {
        sirInfo.bbox = tradeAreaBoundsTracker.bbox;
        sirInfo.shape1.radius = savedFilter.search.radius;
        sirInfo.shape2.radius = null;
        if (isRadiusTwoSearch()) {
          sirInfo.shape2.radius = savedFilter.search.radiusOuter;
        } else {
          sirInfo.shape2.hasShape = false;
        }
      }

      return sirInfo;
    }

    $scope.searchFilter = function () {
      return savedFilter;
    }

    $scope.isThreeMonthRolling = function (vintageId) {
      return vintageId == vintageTypeConstant.threeMonthRolling;
    }

    $scope.propertyDetailsClick = function (property, isInfoboxClick) {
      var pirInfo = {
        id: property.id,
        name: property.name,
        vintageId: savedFilter.search.vintageId,
        latitude: property.latitude,
        longitude: property.longitude,
        product: $scope.product,
        isInfoboxClick: isInfoboxClick,
        shape1: {
          radius: null,
          driveTime: {
            breakValue: null,
            trafficFlow: null
          },
          hasShape: false
        },
        shape2: {
          radius: null,
          driveTime: {
            breakValue: null,
            trafficFlow: null
          },
          hasShape: false
        }
      };
      if (isDriveTimeOneSearch()) {
        var driveTimeInfo = savedFilter.search.driveTimeInfo;
        pirInfo.shape1.driveTime.breakValue = driveTimeInfo.drive1.breakValue;
        pirInfo.shape1.driveTime.trafficFlow = driveTimeInfo.drive1.trafficFlow;
        pirInfo.shape1.hasShape = true;
        if (isDriveTimeTwoSearch()) {
          pirInfo.shape2.driveTime.breakValue = driveTimeInfo.drive2.breakValue;
          pirInfo.shape2.driveTime.trafficFlow = driveTimeInfo.drive2.trafficFlow;
          pirInfo.shape2.hasShape = true;
        }
      } else if (isRadiusOneSearch()) {
        pirInfo.shape1.radius = savedFilter.search.radius;
        pirInfo.shape1.hasShape = true;
        if (isRadiusTwoSearch()) {
          pirInfo.shape2.radius = savedFilter.search.radiusOuter;
          pirInfo.shape2.hasShape = true;
        }
      } else if (pirInfo.vintageId == vintageTypeConstant.quarterly) {
        // a pid click that has vintage as quarterly 
        // just go to the page with default selections
        AuditService.logEvent(knownEventConstant.propertyInformationReport.id, 
          knownEventConstant.propertyInformationReport.message + (isInfoboxClick ? " - Infobox - " : " - ") + property.name,
          $scope.product, property.id);
        $window.open(
          $state.href('propertyDetails', {
            id: property.id
          }, { absolute: true }), '_blank');
  
        return;
      } else if (pirInfo.vintageId == vintageTypeConstant.threeMonthRolling) {
        // no tradeArea but vintage is IQ hence we need to create a guid rather than using a pid.
        // force the tradeArea to be default 5 and 10 mile radius
        pirInfo.shape1.hasShape = true;
        pirInfo.shape1.radius = 5;
        pirInfo.shape2.hasShape = true;
        pirInfo.shape2.radius = 10;
      } else {
        // some unknown?
        console.error('Not sure why it went here!');
      }

      // putting $window.open inside the promise causes browsers to block the new tab creation since it is not driven by a user activity
      // https://stackoverflow.com/questions/19485594/angularjs-window-open-popups-being-blocked
      var windowOpen = $window.open('', '_blank');
      var propertyDetailsHrefLocation = $state.href('propertyDetails', { id: 'pirGuid' }, { absolute: true });
      PropertyDetailsService.getPropertyDetailsId(pirInfo)
        .then(function (pirGuid) {
          windowOpen.location.href = propertyDetailsHrefLocation.replace('pirGuid', pirGuid);
        }, function (err) { console.log(err); });
    };

    $scope.selectProperty = function (property) {
      if (property.selected){
        AuditService.logEvent(knownEventConstant.selectProperty.id, knownEventConstant.selectProperty.message + " - " + property.name, $scope.product, property.id);
      }
      $scope.$broadcast("propertySelectionsChanged", { sourceProperty: property, isSelected: property.selected });
    };

    $scope.userDrawing = {
      onShapeChange: function (shape) {
        $scope.userDrawing.currentShape = null;

        if (!shape || !shape.geometry || !shape.geometry.boundingBox)
          return;

        var c = shape.geometry.boundingBox.center;
        var locs = shape.getLocations();

        var valid = _.every(locs, function (loc) {
          return Nic.maps.twoPointDistance(c.latitude, c.longitude, loc.latitude, loc.longitude) <= 30;
        });

        if (valid) {
          $scope.filter.userDrawingLocs = locs;
          $scope.showTradeAreaOne = true;
          $scope.tradeAreaFlags.customPolygonOnly = true;

          _.each($scope.properties.items, function (property) {
            var detectionResults = detectIfPropertyFromResultNeedsToShow($scope.filter.userDrawingLocs, property);
            property.isInUserPolygon = detectionResults.isInPolygon;
          });
          resetAllPropertyCounts();
        } else {
          $scope.filter.userDrawingLocs = [];
          $rootScope.$broadcast('DRAWINGTOOLS.CLEAR');
          $window.alert('Please limit drawn polygon to 30mi radius.');
        }
        AuditService.logEvent(knownEventConstant.drawPolygon.id, knownEventConstant.drawPolygon.message, $scope.product);
      },
      enabled: true,
      currentShape: null,
      fillColor: [0, 113, 174, 0.42], //$SecondaryBlueLight
      //strokeColor: [20, 47, 83, 1] //'#142f53' //$PrimaryBlueDark
      strokeColor: [0, 255, 0, 1] //'#00FF00' //Lime green
    };
    $scope.polygons = [];

    $scope.mapOptions = BingMapUtils.getDefaultViewOptions();
    setMapCenter(initialBingResult);
    $scope.mapOptions.events =  {
      viewchangeend: viewChange,
      click: function(){ closeThis('ALL'); }
    };
    $scope.mapViewTypes = [
      {name: "Road", id: 0},
      {name: "Aerial", id: 1}
      //{name: "Birdseye", id: 2}  I tried to do this in V8 but it seems buggy.  so I'm just turning it off
    ];

    $scope.altInputFormats = ['M!/d!/yyyy'];
    $scope.toggleTransactionOnly = function() {
      $scope.filter.transaction = {};
      $scope.filter.vanilla = {};
    };
    $scope.resetSearchOptions = resetSearchOptions;
    $scope.customSearch = {};
    $scope.propertyRadius = BingMapUtils.getPolygonOptions('propertyRadius',[]);
    $scope.searchRadius = BingMapUtils.getPolygonOptions('searchRadius',[]);
    $scope.searchRadius2 = BingMapUtils.getPolygonOptions('searchRadius2',[]);
    $scope.$on("$destroy", function() {
      isDestroyed = true;
    });

    $scope.runPars = runPars;
    $scope.applyAdvancedSearch = applyAdvancedSearch;
    $scope.onMapReady = onMapReady;
    $scope.showCountyOutlines = {
      coverageCountychecked: false,
      nonCoverageCountychecked: false
    };
    $scope.no_query = initialBingResult.no_query || false;
    $scope.showHospitals = false;
    $scope.showPlannedProperties = false;
    $scope.coveredCountiesPolygons = [];
    $scope.nonCoveredCountiesPolygons = [];
    $scope.showHospitalsClick = showHospitalsClick;
    $scope.showPlannedPropertiesClick = showPlannedPropertiesClick;
    $scope.showCoveredCountyOutlinesClick = showCoveredCountyOutlinesClick;
    $scope.showNonCoveredCountyOutlinesClick = showNonCoveredCountyOutlinesClick;
    $scope.removeboundaries = removeboundaries;
    $scope.openSaveDialog = openSaveDialog;
    $scope.viewTypeChanged = viewTypeChanged;
    $scope.runMostRecentSearch = runMostRecentSearch;
    $scope.searchButtonClick = searchButtonClick;
    $scope.advancedSearchButtonClick = advancedSearchButtonClick;
    $scope.showPropertyRadiusClick = showPropertyRadiusClick;
    $scope.highlightPin = highlightPin;

    //$scope.selectAllPropertiesInPolygon = selectAllPropertiesInPolygon;
    $scope.selectPropertiesInTradeAreaOne = selectPropertiesInTradeAreaOne;
    $scope.selectPropertiesInTradeAreaTwo = selectPropertiesInTradeAreaTwo;
    $scope.selectPropertiesInPropertyRadius = selectPropertiesInPropertyRadius;
    $scope.isDriveTimeOnlySearch = isDriveTimeOnlySearch;
    $scope.isRadiusOnlySearch = isRadiusOnlySearch;
    $scope.driveTimeLabelColor = driveTimeLabelColor;
    
    $scope.savedDisplays = savedSearches;
    $scope.savedDisplayChanged = savedDisplayChanged;
    $scope.selectedSearch = '';
    $scope.savedPARReportDisplays = savedPARReports;
    $scope.savedPARReportDisplayChanged = savedPARReportDisplayChanged;
    $scope.validateWalkScoreFilter = validateWalkScoreFilter;
    $scope.validateCMSFilter = validateCMSFilter;
    $scope.selectedPARReport = '';
    $scope.propertyListSort = { title: 'Map Pin No.', dir: 'asc', field: 'pinNumber', shorthand: ['!!pinNumber', '+pinNumber'] };
    $scope.$on("propertySelectionsChanged", propertySelectionsChanged);
    $scope.$on("propertySelectionsChangedViaPropertyListHalf", rebroadcastPropertySelectionsChanged);
    $scope.$on("propertySelectionsChangedViaPropertyListFull", rebroadcastPropertySelectionsChanged);
    $scope.$on('propertyListSortChangedViaPropertyListFull', rebroadcastPropertyListSortChanged)

    function rebroadcastPropertySelectionsChanged (event, args){ 
      $scope.$broadcast("propertySelectionsChanged", { sourceProperty: args.sourceProperty, isSelected: args.isSelected });
    }

    function rebroadcastPropertyListSortChanged (event, args) {
      $scope.propertyListSort = args;
      $scope.$broadcast('propertyListSortChanged', args);
    }
    
    $scope.$watch('propertyListSort', function(newVal) {
      rebroadcastPropertyListSortChanged(undefined, newVal);
    });

    $scope.closeThis = closeThis;
    $scope.collapseSideMenu = collapseSideMenu;
    $scope.expandSideMenu = expandSideMenu;
    $scope.keepSideMenuHidden = false;
    $scope.selectSectionVisible = true;

    $scope.initializeAlertPusherValues = initializeAlertPusherValues;

    $scope.$on('infoBoxToggled', function(event, data) { $scope.highlightPin(data);});
    $scope.$on('highlightProperty', function(event, data) { $scope.highlightPin(data);});
    $scope.$on('propertyDetailsInfoboxClick', function (event, args) {
      if (args.sourceProperty && args.sourceProperty.id) {
        var property = _.find($scope.properties.items, ['id', args.sourceProperty.id]);
        if (property) 
          $scope.propertyDetailsClick(property, true);
      }
    });
    $scope.$on('changePropertySelectedValue', function (event, args) {
      if (args.sourceProperty && args.sourceProperty.id) {
        var property = _.find($scope.properties.items, ['id', args.sourceProperty.id]);
        if (property) {
          property.selected = args.newValue;
          $scope.$broadcast("propertySelectionsChanged", { sourceProperty: property, isSelected: property.selected });
        }
      }
    });

    $scope.nicinfobox = { visible: false, pin: null };

    /* begin additional initalization section */
    resetFilter();
    selectedSearchId = '';
    isDestroyed = false;
    getPropertiesOwnByUser();
    setBlackPinOptions(false); //even though this is set in $scope.onMapReady bing maps doesn't like the lat long not being initialized we'll initalize this here.

    SearchService.propertyLocations()
      .then(function (result) {
        var cbsaLocs = result.map(function (cbsa) {
          // Don't show pins you don't have access to
          if (!cbsa.hasAccessToCbsa) { return []; }
          return cbsa.locations.map(function (property) {
            // pass cbsaName through to children
            return _.extend(property, { cbsaName: cbsa.cbsaName });
          });
        });

        $scope.cbsaPins = result.map(function (cbsa) { return pinFactory.cbsaPin(cbsa, bingMap, $scope); });
        $scope.pinClusters = _.flatten(cbsaLocs);
        $scope.showPinClusters = false;
      });

    function initSearch() {
      if ($location.search()['query']) {
        //query parameter existing 
        $scope.filter.query = $location.search()['query'];
      }
    };
    initSearch();

    /* begin javascript hoisting section */
    function onMapReady(map) {
      bingMap = map;

      BingGeoDataService.setupSdsManager();

      if (initialBingResult.search) {
        // SHOULD ONLY FIRE FOR SAVED SEARCHES BEING LOADED THE FIRST TIME
        setSavedSearchNameOrId($stateParams.id, 'id');
        runSavedSearch(initialBingResult);
        applyAdvancedSearch(initialBingResult);
      } else {
        updatesFromSearchResults(initialBingResult);
        if (initialBingResult.message) {
          $scope.errorMessage = initialBingResult.message;
          initialBingResult.message = null;
        }
      }
    }

    function calcFullyCollapsedState() {
      if ($stateParams.resetState) { // currently only set on resetState.
        return !angular.isUndefined($stateParams.resetState.fullyCollapsed) && $stateParams.resetState.fullyCollapsed;
      }
      // default.
      return true;
    }

    function checkAlertMessage() {
      if ($rootScope.generalAlertMessage) { 
        return true;
      }
      // default.
      return false;
    }

    function initializeAlertPusherValues() {
      if(!$scope.alertPushAmount){//init if not exist
        $scope.alertPushAmount = {
          mapSection: 0,
          fullExpanded: 175,
          fullExpandedDetails: 229,
          siteMessageHeight: 0
        }
      }
      $timeout(function() {
        $scope.alertPushAmount.siteMessageHeight = $('.site-message').first().height();
      },0,false);
      //check because of ng-if
      var flexboxFull = $('.flxbx-full').get(0);
      var fullExpandedDetails = $('.full-expanded-details').get(0);
      if(flexboxFull){
        $scope.alertPushAmount.fullExpanded = $(flexboxFull).first().offset().top;
        $scope.alertPushAmount.fullExpandedDetails = $(fullExpandedDetails).first().offset().top;
      }
    }

    $scope.alertPusherStyle = function(element) {
      switch(element){
        case "map-section":
          return {'top':  $scope.alertPushAmount.siteMessageHeight + "px"};
        case "flxbx-full":
          return {'top': ($scope.alertPushAmount.fullExpanded + $scope.alertPushAmount.siteMessageHeight) + "px"};
        case "full-expanded-details":
          return {'top': ($scope.alertPushAmount.fullExpandedDetails + $scope.alertPushAmount.siteMessageHeight) + "px"};
      }
      return "";
    }

    $scope.initializeAlertPusherValues();
    $scope.$on('generalAlertUpdated', function() {
      $scope.initializeAlertPusherValues();
    });

    function getSideMenuState() {
      if($scope.fullyCollapsed){
        return " - Close";
      }

      if($scope.fullyExpanded){
        return " - Full";
      }

      return " - Half";
    }

    function customSortAgeCategories()
    {
      return _.sortBy(ageCategories, 'sortOrder');
    }

    function collapseSideMenu(){
      $scope.keepSideMenuHidden = true;
      $scope.fullyCollapsed = !$scope.fullyCollapsed;
      var state = getSideMenuState();
      AuditService.logEvent(knownEventConstant.expandCollapseSideMenu.id, knownEventConstant.expandCollapseSideMenu.message + state, $scope.product);
    }

    function expandSideMenu(){
      if($scope.properties.items.length)
      {  
        $scope.fullyExpanded = !$scope.fullyExpanded;
        var state = getSideMenuState();
        AuditService.logEvent(knownEventConstant.expandCollapseSideMenu.id, knownEventConstant.expandCollapseSideMenu.message + state, $scope.product);
        $scope.initializeAlertPusherValues();
      }
    }

    function advancedSearchButtonClick() {
      // reset PropertyList Sort to default (pin number)
      $scope.propertyListSort = { title: 'Map Pin No.', dir: 'asc', field: 'pinNumber', shorthand: ['!!pinNumber', '+pinNumber'] };
      clearPolygonsOnSearchClick();
      applyAdvancedSearch(false, true, true);
      $scope.selectedSearch = null;
    }

    function searchButtonClick() {
      // reset PropertyList Sort to default (pin number)
      $scope.propertyListSort = { title: 'Map Pin No.', dir: 'asc', field: 'pinNumber', shorthand: ['!!pinNumber', '+pinNumber'] };
      clearPolygonsOnSearchClick();
      applyAdvancedSearch(false, true, false);
      $scope.selectedSearch = null;
    }

    function closeThis(elementId) {
      if (elementId == 'divAdvancedPC')
        $scope.filter.showAdvancedSearch = false;
      else if (elementId == 'divMapLegend')
        $scope.hideLegend = true;
      else if (elementId == 'trade-area')
        $scope.filter.showTradeArea = false;
      else if (elementId == 'ALL') {
        $scope.filter.showAdvancedSearch = false;
        $scope.filter.showTradeArea = false;
        $scope.hideLegend = true;
      }
      else {
        // nothing to do
      }
    }

    function applyAdvancedSearch(savedSearchData, searchButtonClicked, isAdvancedSearchButtonClick) {
      var searchBing = needToReRunBingSearch() || savedSearchData;
      var isButtonClickSearch = searchButtonClicked || false;
      var isAdvancedSearchButtonClickSearch = isAdvancedSearchButtonClick || false;

      $scope.filter.showAdvancedSearch = false;
      // Clear the property name search filter textbox in PropertyListHalf
      if (searchButtonClicked || isAdvancedSearchButtonClick) {
        $scope.$broadcast('clearNameSearch');
      }
      if($scope.fullyCollapsed) {
        $scope.fullyCollapsed = !$scope.fullyCollapsed;

        // the left side was collapsed so we want to display it without doing a search and wait a little bit then do the search to give bing maps time to resize correctly
        $scope.waitingForSearchTimeout = true;
        $timeout(function () {
          $scope.waitingForSearchTimeout = false;
          applyAdvancedSearch(savedSearchData, searchButtonClicked, isAdvancedSearchButtonClick)
        }, 200);
        return;  // bail out without searching
      }
      if(savedSearchData && !$scope.filter.query) {
        // This scenario is for the time when the user is rerunning a saved search
        // that was just a moved map search and not a query search
        updatesFromSearchResults(savedSearchData, searchDetails);
        return;
      }
      //if there's a query, we should do a bing search and get a new bounding box.
      //if there's not just run a propertySearch on the existing bounding box.
      SpinnerService.start();
      if (searchBing) {
        return SearchService
          .searchBing($scope.filter.query)
          .then(function(result) {
            if(result.entityType == "Address" && (!isRadiusOnlySearch() || !isDriveTimeOnlySearch() || !isUserPolygonSearch())){
              $scope.addressSearchReRun = true;
            }
            setApiModelFromFilter();
            updatesFromSearchResults(result, searchDetails);
            searchDetails = result;             
            runRadiusOrRegularSearch(isButtonClickSearch, isAdvancedSearchButtonClickSearch)
            .then(function(result){
              searchCompleted();
              return result;
            },function(error){
              if(searchDetails.entityType != "Address"){
                searchCompleted(error);
              }
              return error;
            })
            .then(function(response){
              verifyReRunAddressSearchConditions(response);
              if (searchButtonClicked) {
                createSavedSearch('SaveRecentSearch');
              }
            });
          }, function(error){
            searchCompleted(error);
          });
      }
      if (!$scope.filter.query) {
        setBlackPinOptions(false);
        if($scope.polygons.length) {
          $scope.polygons = [];
        }
      }
      setApiModelFromFilter();
      clearPropertyRadius();
      runRadiusOrRegularSearch(isButtonClickSearch, isAdvancedSearchButtonClickSearch)
      .then(function(result){
        searchCompleted();
      }, function(error){
        verifyReRunAddressSearchConditions(error);
      });

      if (searchButtonClicked && !searchBing) {
        createSavedSearch('SaveRecentSearch');
      }
    }

    function verifyReRunAddressSearchConditions(response){
      if(searchDetails.entityType == "Address" && !avoidIterationForSearchErrors(response)){
        if($scope.properties.items 
          && (!isRadiusOnlySearch() || !isDriveTimeOnlySearch() || !isUserPolygonSearch())
          && ($scope.properties.items.length == 0 || $scope.properties.items.length == savedFilter.search.includeSelectedPropertyIDs.length)
          && currentZoomGreaterThanCutoff()){
            $scope.addressSearchReRun = true;
            zoomOutAndReRunAddressSearch();
        }
        else{
          searchCompleted(response);
        }
      }
      else{
        SpinnerService.stop();
      }
    }

    function avoidIterationForSearchErrors(error){
      //if the area searched is outside the metro market covered by NIC do not create a loop.
      //We can later on add conditions here if we want to skip iterations for any error types. 
      if(angular.isString(error)){
        if(error.includes("falls outside of our current metro market coverage")){
          searchCompleted(error);
          return true;
        }
      }
      else{
        var errorMsg = error.message;
        if(errorMsg.includes("falls outside of our current metro market coverage")){
          searchCompleted(errorMsg);
          return true;
        }
      }
      return false;
    }

    function searchCompleted(error){
      SpinnerService.stop();
      $scope.addressSearchReRun = false;
      if(error != undefined){
        bingSearchHasError(error);
      }
      else{
        $scope.errorMessage = "";
      }
    }

    function zoomOutAndReRunAddressSearch(){
      var currentZoom = BingMapUtils.getCurrentZoom(bingMap);
      BingMapUtils.changeZoom(bingMap, currentZoom-1);
      searchDetails.bbox = undefined;
      searchDetails.zoom = currentZoom-1;
      getPins().then(function(result){
        var reRunBounds = BingMapUtils.boundsFromMap(bingMap);
        searchDetails.bbox = reRunBounds.bbox;
        searchCompleted();
        return;
      }, function(error){
        if(!currentZoomGreaterThanCutoff()){
          searchCompleted(error);
          return;
        }
        zoomOutAndReRunAddressSearch();
      })
    }

    function currentZoomGreaterThanCutoff(){
      var currentZoom = BingMapUtils.getCurrentZoom(bingMap);
      if(currentZoom > $scope.cbsaPinZoomLevelCutoff + 1){
        return true;
      }
      return false;
    }

    function runRadiusOrRegularSearch(isButtonClickSearch, isAdvancedSearchButtonClickSearch) {
      var defer = $q.defer();
      var bounds = BingMapUtils.boundsFromMap(bingMap);
      getPinsFilterSetup(bounds, isButtonClickSearch);  // this will populate the radius locations if applicable

      var isRadiusOnly1SearchSelected = _.isArray($scope.searchRadius.locations);
      var isRadiusOnly2SearchSelected = _.isArray($scope.searchRadius2.locations);

      if ($scope.tradeAreaFlags.radiusOnly) {
        logUserEventRadiusOnlySearch(isRadiusOnly1SearchSelected, isRadiusOnly2SearchSelected);
      }

      if(isRadiusOnly2SearchSelected && $scope.searchRadius2.locations.length > 0) {
        $scope.showTradeAreaOne = true;
        $scope.showTradeAreaTwo = true;

        //if a search button is clicked, then always force a search in the map viewChange event
        forceSearchInBingMapViewChange = isButtonClickSearch;

        BingMapUtils.centerMapToLocations(bingMap, $scope.searchRadius2.locations);
        tradeAreaBoundsTracker = BingMapUtils.boundsFromMap(bingMap);
        defer.resolve({});
      } else if (isRadiusOnly1SearchSelected && $scope.searchRadius.locations.length > 0) {
        $scope.showTradeAreaOne = true;
        $scope.showTradeAreaTwo = false;

        //if a search button is clicked, then always force a search in the map viewChange event
        forceSearchInBingMapViewChange = isButtonClickSearch;

        BingMapUtils.centerMapToLocations(bingMap, $scope.searchRadius.locations);
        tradeAreaBoundsTracker = BingMapUtils.boundsFromMap(bingMap);
        defer.resolve({});
      } else {
        getPins(bounds, isButtonClickSearch, isAdvancedSearchButtonClickSearch)
        .then(function(resolve){
          defer.resolve(resolve);
        },function(error){
          defer.reject(error)
        });
      }
      return defer.promise;
    }

    function logUserEventRadiusOnlySearch(isRadiusOnly1SearchSelected, isRadiusOnly2SearchSelected){
      if(isRadiusOnly2SearchSelected && isRadiusOnly1SearchSelected) {
        var radius1Miles;
        var radius2Miles;

        if ($scope.filter.radius1 == -1) {
          radius1Miles = $scope.filter.customRadius1;
        } else {
           radius1Miles = $scope.filter.radius1;
        }

        if ($scope.filter.radius2 == -1) {
          radius2Miles = $scope.filter.customRadius2;
        } else {
          radius2Miles = $scope.filter.radius2;
        }

        var message = "Radius 1: " +  radius1Miles + " Miles; Radius 2: " + radius2Miles + " Miles";
        AuditService.logEvent(knownEventConstant.localRadiusSearch.id, message, $scope.product);
      } else if(isRadiusOnly1SearchSelected) {
        var radius1Miles;
        
        if ($scope.filter.radius1 == -1) {
          radius1Miles = $scope.filter.customRadius1;
        } else {
          radius1Miles = $scope.filter.radius1;
        }

        var message = "Radius 1: " +  radius1Miles + " Miles; Radius 2: No Radius";
        AuditService.logEvent(knownEventConstant.localRadiusSearch.id, message, $scope.product);
      }
    }

    function needToReRunBingSearch() {
      //the text box is the same so no need to do a bing query again
      if(savedFilter.query == $scope.filter.query) {
        return false;
      }
      if(!$scope.filter.query) {
        return false;
      }
      return true;
    }

    function runPars() {
      if(!canRunPars()) {
        return;
      }
      
      $scope.isPARRunning = true;
      var selectedPropdata = _.chain($scope.properties.items).filter('selected')
        .map(function (obj) {
          return { id: obj.id, city: obj.city, stateProvinceCode: obj.stateProvinceCode};
        }).value();
      var parReportName = PropertyAdvisorService.autoGenParReportName($scope.filter, $scope.tradeAreaFlags);
      PropertyAdvisorService
        .createReport(selectedPropdata, parReportName)
        .then(function(reportGuid) {
          PopupHandlerService.openReportInNewTab('propertyAdvisor', reportGuid, 'Property Advisor Report');
          $scope.isPARRunning = false;
        }, function(error) {
          alert("Error creating par report" + error.data);
          $scope.isPARRunning = false;
        });
    }

    function drawPolygonFromBing(bingMapResult) {
      if ($scope.polygons.length) {
        $scope.polygons = [];
      }

      if (!bingMapResult) return;

      var bingQuery = null, bingEnityType = null;

      if (bingMapResult.zipCode) {
        bingQuery = bingMapResult.zipCode;
        bingEnityType = 'Postcode1';
      } else if (bingMapResult.countyName) {
        bingQuery = bingMapResult.countyName.replace("'", "");
        /*Carroll county and baltimore city, md issue*/
        if (!(/ city/i.test(bingQuery) || / county/i.test(bingQuery)))
          bingQuery += ' County'; // append county text if there is no city or county appended at the end
        if (bingMapResult.stateCode)
          bingQuery += ", " + bingMapResult.stateCode;
        bingEnityType = 'AdminDivision2';
      } else {
        return;
      }

      BingGeoDataService
        .getBoundariesFromBing(bingQuery, bingEnityType)
        .then(function (bingPolygon) {
          $scope.polygons = bingPolygon;
        }, function (err) {
          console.log(err);
        });
    }
    
    //called from onMapReady and after searching
    //takes a bing result (or saved query) and updates the map
    function updatesFromSearchResults(bingMapResult, previousResult) {

      setBlackPinOptions(bingMapResult.query, bingMapResult.latitude, bingMapResult.longitude); //show or hide the black pin

      drawPolygonFromBing(bingMapResult);

      //will bingMapResult.bbox ever not be set?
      if(bingMapResult.bbox) {
        //will triggers a viewchangeend EVENT!!!
        BingMapUtils.centerMap(bingMap, bingMapResult.bbox);
      } else {
        //should only happen for maybe a saved query?
      }
    }

    function setBlackPinOptions(visible, latitude, longitude) {
      var customSearchPushpin = {};
      customSearchPushpin.options = pinFactory.buildBlackPinOptions();
      customSearchPushpin.options.visible = visible ? true : false;

      if(latitude) {
        customSearchPushpin.latitude = latitude;
      } else {
        customSearchPushpin.latitude = 0;
      }
      if(longitude) {
        customSearchPushpin.longitude = longitude;
      } else {
        customSearchPushpin.longitude = 0;
      }
      customSearchPushpin.id = 'customSearchPin';
      customSearchPushpin.query = $scope.filter.query;
      customSearchPushpin.product = $scope.product;
      customSearchPushpin.events = {
        click: function(eventArgs) {
          if ($scope.userDrawing.currentShape != null) {
            var loc = eventArgs.target.getLocation();
            $scope.$broadcast("pushpinClickedWhileInDrawingMode", {
              location: new Microsoft.Maps.Location(loc.latitude, loc.longitude),
              isInDrawingMode: true 
            });
          }

          $scope.$broadcast("infoBoxToggled", {
            id: customSearchPushpin.id,
            type: 'site',
            object: customSearchPushpin
          });
          AuditService.logEvent(knownEventConstant.pinClick.id, knownEventConstant.pinClick.message + " - " + $scope.filter.query, $scope.product);
        }
      };

      $scope.customSearch = customSearchPushpin;
    }

    function viewTypeChanged(e) {
      var mapType = Microsoft.Maps.MapTypeId.road;
      var value = parseInt(e.sender._old);
      if(value == 1) {
        mapType = Microsoft.Maps.MapTypeId.aerial;
      } else if(value == 2) {
        mapType = Microsoft.Maps.MapTypeId.birdseye;
      }
      bingMap.setView({ mapTypeId: mapType });
    }

    function viewChange() {
      if (isDestroyed) {
        return;
      }
      var previouslyEnabled = $scope.userDrawing.enabled;
      $scope.userDrawing.enabled = bingMap.getZoom() >= 9;

      if ($scope.no_query) {
        $scope.no_query = false;
        return;
      }
      if (initialBingResult.default_view) {
        initialBingResult.default_view = false;
      }

      var bounds = BingMapUtils.boundsFromMap(bingMap);
      //if there's zero properties or if the Latitude changed, just get the pins.
      if(forceSearchInBingMapViewChange ||
         (previouslyEnabled && !$scope.userDrawing.enabled) ||
          $scope.properties.items.length == 0 ||
         (previousBounds && previousBounds.bbox && (bounds.bbox[0] != previousBounds.bbox[0] || bounds.bbox[2] != previousBounds.bbox[2]))) {
        previousBounds = bounds;
        forceSearchInBingMapViewChange = false;
        //Log map move event
        if ($scope.filter.transactionOnly) {
          AuditService.logEvent(knownEventConstant.mapMoveTrans.id, knownEventConstant.mapMoveTrans.message, $scope.product);
        } else {
          AuditService.logEvent(knownEventConstant.mapMove.id, knownEventConstant.mapMove.message, $scope.product);
        }
        SpinnerService.start();
        getPins(bounds)
        .finally(function(){
          SpinnerService.stop();
        });
        return;
      }
      previousBounds = bounds;
    }

    $scope.$watch('filter.propertyTypeIds.length', function propertyTypeChanged() {
      updateMultiSelectEllipsisDiv($scope.filter.propertyTypeIds, 'multiPropertyType', 'propertyTypePeriods');
    });

    $scope.$watch('filter.vanilla.propertyStatuses.length', function constructionStatusChanged() {
      updateMultiSelectEllipsisDiv($scope.filter.vanilla.propertyStatuses, 'multiConstructionStatus', 'constructionStatusPeriods');
    });

    function updateMultiSelectEllipsisDiv(multiSelectArr, multiSelectedListId, ellipsisId) {
      if(!multiSelectArr)
        return;

      var numSelectedItems = multiSelectArr.length;
      var ellipsisDiv = $('#' + ellipsisId);
      var multiSelectedList = $('#' + multiSelectedListId + '_taglist');

      if(!ellipsisDiv.length) {
        multiSelectedList.after("<div id='" + ellipsisId + "' style='position: static; padding-top: 8px'>&nbsp;&nbsp;&nbsp;...</div>");
        ellipsisDiv = $('#' + ellipsisId);
      } else
        multiSelectedList.after(ellipsisDiv);

      numSelectedItems > 1 ? ellipsisDiv.show() : ellipsisDiv.hide();
    }

    function detectIfPropertyFromResultNeedsToShow(polyPoints, sqlPin) {
      // this function will check if the pin returned from db for radius or drive time search
      // is inpolygon or not when checking on javascript side due to logic difference between SQL and JS
      var modelToReturn = { isInPolygon: false, isAlreadySelected: false };
      if (BingMapUtils.pointInPolygon(polyPoints, sqlPin.latitude, sqlPin.longitude)) {
        modelToReturn.isInPolygon = true;
      } 
      modelToReturn.isAlreadySelected = isAlreadySelectedPin(sqlPin.id);
      return modelToReturn;
    }

    function isAlreadySelectedPin(id) {
      var alreadySelectedProps = savedFilter.search.includeSelectedPropertyIDs;
      if (alreadySelectedProps && alreadySelectedProps.length > 0 && alreadySelectedProps.indexOf(id) > -1) {
        // check if the property was preselected and which is why sql returned it
        return true;
      }
      return false;
    }

    function processSearchResults(results) {
      //Set the initial state of the side bar to half collapsed after a search.
      //Do not show the menu if the user has hidden it.
      if(results && results.length > 0 && !$scope.keepSideMenuHidden) {
        $scope.fullyCollapsed = false;
      }

      var properties = [];
      var pinCounter = 0;

      // take care of the common calls ahead of time
      var localIsDriveTimeOneSearch = isDriveTimeOneSearch(), localIsDriveTimeTwoSearch = isDriveTimeTwoSearch();
      var localIsRadiusOneSearch = isRadiusOneSearch(), localIsRadiusTwoSearch = isRadiusTwoSearch();
      var localIsUserPolygonSearch = isUserPolygonSearch(), localIsUserPolygonSearchButtonSearch = isUserPolygonSearchButtonSearch();
      var localIsPropertyRadiusSearch = $scope.showPropertyRadius && $scope.propertyRadius.locations && $scope.propertyRadius.locations.length > 0;
      var localIsWalkScoreMinSearch = isWalkScoreMinSearch(), localIsWalkScoreMaxSearch = isWalkScoreMaxSearch();
      var localIsCMSScoreMinSearch = isCMSScoreMinSearch(), localIsCMSScoreMaxSearch = isCMSScoreMaxSearch(); 

      var sortedByDistanceResults = _.sortBy(results, ['distanceInMiles'], ['asc']);
      
      //western most pin is the pin with the 'smallest' longitude
      _.each(sortedByDistanceResults, function (value) {
        var isInTradeAreaOne = false, isInTradeAreaTwo = false; // for search radius options and drive times
        var isInUserPolygon = false, isInPropertyRadius = false;
        var isInWalkScoreMinRange = false, isInWalkScoreMaxRange = false;
        var isInCMSScoreMinRange = false, isInCMSScoreMaxRange = false;
        var isAlreadySelected = false;
        if (localIsDriveTimeOneSearch) {
          var detectionResults = detectIfPropertyFromResultNeedsToShow($scope.driveTimeEsriPolygons[0].locations, value);
          isInTradeAreaOne = detectionResults.isInPolygon;
          isAlreadySelected = detectionResults.isAlreadySelected;
        }
        if (localIsDriveTimeTwoSearch) {
          var detectionResults = detectIfPropertyFromResultNeedsToShow($scope.driveTimeEsriPolygons[1].locations, value);
          isInTradeAreaTwo = detectionResults.isInPolygon;
          isAlreadySelected = detectionResults.isAlreadySelected;
        }
        if (localIsRadiusOneSearch) {
          var detectionResults = detectIfPropertyFromResultNeedsToShow($scope.searchRadius.locations, value);
          isInTradeAreaOne = detectionResults.isInPolygon;
          isAlreadySelected = detectionResults.isAlreadySelected;
        }
        if (localIsRadiusTwoSearch) {
          var detectionResults = detectIfPropertyFromResultNeedsToShow($scope.searchRadius2.locations, value);
          isInTradeAreaTwo = detectionResults.isInPolygon;
          isAlreadySelected = detectionResults.isAlreadySelected;
        }
        if (localIsUserPolygonSearchButtonSearch) {
          var detectionResults = detectIfPropertyFromResultNeedsToShow(savedFilter.search.userDrawingLocs, value);
          isInUserPolygon = detectionResults.isInPolygon;
          isAlreadySelected = detectionResults.isAlreadySelected;
        }
        if (localIsPropertyRadiusSearch) {
          var detectionResults = detectIfPropertyFromResultNeedsToShow($scope.propertyRadius.locations, value);
          isInPropertyRadius = detectionResults.isInPolygon;
          isAlreadySelected = detectionResults.isAlreadySelected;
        }
        if(localIsWalkScoreMinSearch || localIsWalkScoreMaxSearch) {
          isInWalkScoreMinRange = (_.toNumber(savedFilter.search.walkScoreMin) || 0) <= getWalkScore();
          isInWalkScoreMaxRange = (_.toNumber(savedFilter.search.walkScoreMax) || 100) >= getWalkScore();
          isAlreadySelected = isAlreadySelectedPin(value.id);
        }
        if(localIsCMSScoreMinSearch || localIsCMSScoreMaxSearch) {
          isInCMSScoreMinRange = (_.toNumber(savedFilter.search.cmsScoreMin) || 0) <= getCMSScore();
          isInCMSScoreMaxRange = (_.toNumber(savedFilter.search.cmsScoreMax) || 5) >= getCMSScore();
          isAlreadySelected = isAlreadySelectedPin(value.id);
        }

        function getWalkScore() {
          if (value.walkScoreInfo 
            && value.walkScoreInfo.value 
            && value.walkScoreInfo.value.status == 1) {
            return value.walkScoreInfo.value.walkScore;
          }
          return 0;
        }

        function getCMSScore() {
          if (value.cmsScore) {
            return value.cmsScore;
          }
          return 0;
        }

        var isPolygonSearch = (localIsDriveTimeOneSearch || localIsDriveTimeTwoSearch || localIsRadiusOneSearch || localIsRadiusTwoSearch || localIsUserPolygonSearchButtonSearch);
        var isInPolygon = (isInTradeAreaOne || isInTradeAreaTwo || isInUserPolygon);
        var isWalkscoreSearch = (localIsWalkScoreMinSearch || localIsWalkScoreMaxSearch);
        var isInWalkScoreRange = (isInWalkScoreMinRange && isInWalkScoreMaxRange);
        var isCMSScoreSearch = (localIsCMSScoreMinSearch || localIsCMSScoreMaxSearch);
        var isInCMSScoreRange = (isInCMSScoreMinRange && isInCMSScoreMaxRange);
        
        if (!isAlreadySelected && ((isPolygonSearch && !isInPolygon) || (isWalkscoreSearch && !isInWalkScoreRange) || (isCMSScoreSearch && !isInCMSScoreRange))) {
          // if it is a radiusSearch or driveTimeSearch or a user polygon search 
          // and the sqlPin returned does not belong to any of the polygons
          // and the pin was not preselected
          // and not within the bounds of walkscore min and max
          // than do not insert the pin into list or map
          return;
        }

        if (localIsUserPolygonSearch) {
          var detectionResults = detectIfPropertyFromResultNeedsToShow($scope.filter.userDrawingLocs, value);
          isInUserPolygon = detectionResults.isInPolygon;
        }
        
        var pin = pinFactory.buildPin(value, pinCounter, $scope, savedFilter.search.includeSelectedPropertyIDs);
        _.extend(pin, {
          timestamp: new Date(),
          isInTradeAreaOne: isInTradeAreaOne,
          isInTradeAreaTwo: isInTradeAreaTwo,
          isInUserPolygon: isInUserPolygon,
          isInPropertyRadius: isInPropertyRadius,
          segmentTypes: value.segmentTypes
        });
        if ($scope.nicinfobox.pin && $scope.nicinfobox.pin.id == pin.id && $scope.nicinfobox.pin.timestamp != pin.timestamp) {
          $scope.nicinfobox.pin = pin;
        }

        properties.push(pin);
        pinCounter++;
      });
        
      if ($scope.filter.includeSelectedPropertyIDs) {
        // go throught the filter properties and see if any of them did not come back...
        // if they did not come back then remove them from the instance
        // this is for the scenario where the user saved a search in an area that they had access with pins selected and now they don't have access to that area
        var mappedSearchReturnedPropertyIds = _.map(properties, 'id');
        _.remove($scope.filter.includeSelectedPropertyIDs, function (propIdToCheck) {
          return _.indexOf(mappedSearchReturnedPropertyIds, propIdToCheck) === -1;
        });
      }
      if($scope.showHospitals){
        drawHospitalPins();
      }
      if($scope.showPlannedProperties){
        drawPlannedPropertyPins();
      }
      return properties;
    }

    function addSelectedProperties() {
      var selectedPropIds = $scope.filter.includeSelectedPropertyIDs || [];
      _.forEach($scope.properties.items, function (props) {
        if (props.id && (props.selected === true && _.indexOf(selectedPropIds, props.id) === -1)) {
          selectedPropIds.push(props.id);
        }
      });
      FilterMapper.addSelectedProperties(savedFilter, selectedPropIds);
    }

    function clearPropertyRadius() {
      if ($scope.showPropertyRadius && 
          $scope.propertyRadius.locations  && 
          $scope.propertyRadius.locations.length > 0) {
        $scope.propertyRadius.locations = [];
        $scope.showPropertyRadius = false;  
        $scope.highlightedProperty = false;
      }
      clearPropertyRadiusFlag = false;
    }

    function setClearPropertyRadiusFlag() {
      clearPropertyRadiusFlag = true;
    }

    function isDriveTimeOnlySearch() {
      return isDriveTimeOneSearch() || isDriveTimeTwoSearch();
    }

    function isDriveTimeOneSearch() {
      if (savedFilter.search.driveTimeInfo &&
        savedFilter.search.driveTimeInfo.drive1 &&
        parseInt(savedFilter.search.driveTimeInfo.drive1.breakValue)) {
        return true;
      }
      return false;
    }

    function isDriveTimeTwoSearch() {
      if (savedFilter.search.driveTimeInfo &&
        savedFilter.search.driveTimeInfo.drive2 &&
        parseInt(savedFilter.search.driveTimeInfo.drive2.breakValue)) {
        return true;
      }
      return false;
    }

    function isRadiusOnlySearch() {
      return isRadiusOneSearch() || isRadiusTwoSearch();
    }

    function isRadiusOneSearch() {
      if ($scope.searchRadius.locations && $scope.searchRadius.locations.length > 0) {
        return true;
      }
      return false;
    }

    function isRadiusTwoSearch() {
      if ($scope.searchRadius2.locations && $scope.searchRadius2.locations.length > 0) {
        return true;
      }
      return false;
    }

    function isUserPolygonSearch() {
      return ($scope.filter.userDrawingLocs && $scope.filter.userDrawingLocs.length > 0);
    }

    function isUserPolygonSearchButtonSearch() {
      return (savedFilter.search.userDrawingLocs && savedFilter.search.userDrawingLocs.length > 0);
    }

    function isWalkScoreMinSearch() {
      return _.defaultTo(_.toNumber(savedFilter.search.walkScoreMin) > 0, false);
    }
    function isWalkScoreMaxSearch() {
      return _.defaultTo(_.toNumber(savedFilter.search.walkScoreMax) > 0, true);
    }
    function isCMSScoreMinSearch() {
      return _.defaultTo(_.toNumber(savedFilter.search.cmsScoreMin) > 0, false);
    }
    function isCMSScoreMaxSearch() {
      return _.defaultTo(_.toNumber(savedFilter.search.cmsScoreMax) > 0, false);
    }

    function getPinsFilterSetup(bounds, isButtonClickSearch) {
      addSelectedProperties();
      if (isButtonClickSearch) {
        // need to override the searchDetails parameter so that we always have the correct lat long that came back 
        // from hitting a search button.
        if (searchDetails && (searchDetails.latitude || searchDetails.longitude)) {
          // if the searchdetails is defined than update
          // we want to make sure that any other search detail properties dont get lost when setting searchDetails as a new object..
          // particularly the parameters for county and zip search.
          searchDetails.latitude = bounds.latitude;
          searchDetails.longitude = bounds.longitude;
        } else {
          // otherwise insert
          searchDetails = {
            latitude: bounds.latitude,
            longitude: bounds.longitude
          };
        }
        if ($scope.customSearch.latitude && $scope.customSearch.longitude) {
          searchDetails.latitude = $scope.customSearch.latitude;
          searchDetails.longitude = $scope.customSearch.longitude;
        }
        
        if ($scope.showPropertyRadius) {
          setClearPropertyRadiusFlag();
          if(isUserPolygonSearchButtonSearch()) {
            // clear property radius if any available before performing search
            clearPropertyRadius();
          }
        }
      }
      FilterMapper.addGeoData(savedFilter, searchDetails, bounds);

      if(!$scope.showPropertyRadius) {
        $scope.searchRadius.locations = savedFilter.circle;
        if ($scope.searchRadius.locations && $scope.searchRadius.locations.length > 0) {
          $scope.tradeAreaFlags.radiusOnly = true;
          $scope.tradeAreaFlags.driveTimeOnly = false;
          $scope.tradeAreaFlags.customPolygonOnly = false;
          $scope.showTradeAreaOne = true;
        } else {
          $scope.tradeAreaFlags.radiusOnly = false;
          $scope.showTradeAreaOne = false;
        }
        $scope.searchRadius2.locations = savedFilter.circle2;
        if ($scope.searchRadius2.locations && $scope.searchRadius2.locations.length > 0) {
          $scope.showTradeAreaTwo = true;
        } else {
          $scope.showTradeAreaTwo = false;
        }
      }
      if (savedFilter.query) {
        savedFilter.search.query = savedFilter.query;
      }
      if (isDriveTimeOneSearch()) {
        $scope.tradeAreaFlags.driveTimeOnly = true;
        $scope.tradeAreaFlags.radiusOnly = false;
        $scope.tradeAreaFlags.customPolygonOnly = false;
        $scope.showTradeAreaOne = true;
      } else {
        $scope.tradeAreaFlags.driveTimeOnly = false;
      }

      if (isDriveTimeTwoSearch()) {
        $scope.showTradeAreaTwo = true;
      }
      
      if (isRadiusOnlySearch() || isDriveTimeOnlySearch()) {
        $rootScope.$broadcast('DRAWINGTOOLS.CLEAR');
        if(clearPropertyRadiusFlag) {
          clearPropertyRadius();
        }
      } else {
        var userDrawingsToUse = null;
        if (isUserPolygonSearchButtonSearch()) {
          userDrawingsToUse = savedFilter.search.userDrawingLocs;
        } else if (isUserPolygonSearch()) {
          userDrawingsToUse = $scope.filter.userDrawingLocs;
        } else {
          // nothing to do
        }
        if (userDrawingsToUse) {
          $scope.showTradeAreaOne = true;
          $scope.tradeAreaFlags.customPolygonOnly = true;
          $rootScope.$broadcast('drawSavedShape', userDrawingsToUse);
        }
      }
    }

    function getPins(bounds, searchButtonClicked, isAdvancedSearchButtonClick) {
      var defer = $q.defer();
      var isButtonClickSearch = searchButtonClicked || false;
      var isAdvancedSearchButtonClickSearch = isAdvancedSearchButtonClick || false;
      var forceTravelSearch = false;

      $scope.$broadcast("closeCustomSearchPinInfobox");

      getPinsFilterSetup(bounds, isButtonClickSearch);
      if (!(isButtonClickSearch || isAdvancedSearchButtonClickSearch)) {
        if (isDriveTimeOnlySearch() && !$scope.driveTimeEsriPolygons.length) {
          forceTravelSearch = true;
        }
      }
      
      var currentZoom = BingMapUtils.getCurrentZoom(bingMap);
      var lastSearch;


      //Set the drive time coordinates to match the black pin's coordinates
      if (isDriveTimeOnlySearch() && $scope.customSearch.latitude && $scope.customSearch.longitude) {
        savedFilter.search.coordinates[0] = $scope.customSearch.latitude;
        savedFilter.search.coordinates[1] = $scope.customSearch.longitude;
      }

      $scope.errorMessage = "";
      FilterMapper
        .getDriveTimePolygons(savedFilter, $scope.driveTimeEsriPolygons, forceTravelSearch, isButtonClickSearch, isAdvancedSearchButtonClick)
        .then(function (polygons) {
          var isDriveTimeOnly1SearchSelected = false; 
          var isDriveTime2SearchSelected = false;

          if ($scope.tradeAreaFlags.driveTimeOnly && _.has(polygons, '[0].locations.length')) {
            $scope.driveTimeEsriPolygons = polygons;
            savedFilter.search.driveTimeInfo.drive1.driveTimeLocs = polygons[0].locations;
            isDriveTimeOnly1SearchSelected = true;
            if (_.has(polygons, '[1].locations.length')){
              savedFilter.search.driveTimeInfo.drive2.driveTimeLocs = polygons[1].locations;
              isDriveTime2SearchSelected = true;
            } else {
              savedFilter.search.driveTimeInfo.drive2.driveTimeLocs = [];
            }
            if (isButtonClickSearch || isAdvancedSearchButtonClick) {
              logUserEventDriveTimeOnlySearch(isDriveTimeOnly1SearchSelected, isDriveTime2SearchSelected);
            }
          } else {
            savedFilter.search.driveTimeInfo.drive1.driveTimeLocs = [];
          }

          if (isButtonClickSearch || isAdvancedSearchButtonClickSearch) {
            var locs = isDriveTime2SearchSelected
              ? _.concat(savedFilter.search.driveTimeInfo.drive1.driveTimeLocs, savedFilter.search.driveTimeInfo.drive2.driveTimeLocs) 
              : savedFilter.search.driveTimeInfo.drive1.driveTimeLocs;
            BingMapUtils.centerMapToLocations(bingMap, locs);
            tradeAreaBoundsTracker = BingMapUtils.boundsFromMap(bingMap);
          }
          if (!isDriveTimeOnlySearch()) {
            $scope.driveTimeEsriPolygons = [];
            resetDriveTime();
          }
          currentSearch = SearchService.search(savedFilter, isButtonClickSearch, isAdvancedSearchButtonClickSearch, currentZoom);
          lastSearch = currentSearch;
          return currentSearch;
        })
        .then(function(properties) {
          // get walkscore for properties
          return WalkScoreService.getWalkScoreForProperties(properties);
        })
        .then(function(properties) {
          if(lastSearch != currentSearch) {
            defer.resolve(properties);
            return defer.promise; //this is a previous result. don't process
          }
          $scope.properties.items = processSearchResults(properties);
          resetAllPropertyCounts();
          $scope.expandedPropertiesDisplayCount = initPropertiesDisplayCount();
          $scope.showPinClusters = false;
          $scope.showCbsaPins = false;
          // Hide the infoboxes for pins that are now hidden
          $scope.$broadcast('pinHoverOut', { type: 'county' });
          $scope.$broadcast('pinHoverOut', { type: 'cbsa' });
          canRunPars();
          defer.resolve(properties);
        })
        .catch(function (error) {
          searchHasError(error);
          defer.reject(error);
        });

      // Draw county outlines if the checkboxes are checked
      if($scope.showCountyOutlines.coverageCountychecked || $scope.showCountyOutlines.nonCoverageCountychecked) {
        drawCountyOutlines();
      }
      return defer.promise;
    }

    function logUserEventDriveTimeOnlySearch(isDriveTimeOnly1SearchSelected, isDriveTime2SearchSelected) {
      if (isDriveTimeOneSearch() && isDriveTimeTwoSearch()) {
        var driveTime1Minutes = savedFilter.search.driveTimeInfo.drive1.breakValue;
        var traffic1 = savedFilter.search.driveTimeInfo.drive1.trafficFlow;
        var driveTime2Minutes = savedFilter.search.driveTimeInfo.drive2.breakValue;
        var traffic2 = savedFilter.search.driveTimeInfo.drive2.trafficFlow;

        var message = "Drive Time 1: " +  driveTime1Minutes + " Minutes " + traffic1 + " Traffic;" +
                     " Drive Time 2: " +  driveTime2Minutes + " Minutes " + traffic2 + " Traffic";
        AuditService.logEvent(knownEventConstant.localDriveTimeSearch.id, message, $scope.product);
      } else if (isDriveTimeOneSearch()) {
        var driveTime1Minutes = savedFilter.search.driveTimeInfo.drive1.breakValue;
        var traffic1 = savedFilter.search.driveTimeInfo.drive1.trafficFlow;
        
        var message = "Drive Time 1: " +  driveTime1Minutes + " Minutes " + traffic1 + " Traffic"; 
        AuditService.logEvent(knownEventConstant.localDriveTimeSearch.id, message, $scope.product);
      }
    }

    function searchHasError(errorResponse) {
      var message;
      $scope.errorMessage = "";
      var properties = [];
      var bTooManyPropertiesReturned = false;
      if(angular.isString(errorResponse)) {
        // Due to having cluster pins and cbsa pins we are swapping the error message that is displayed to the user.
        // In some cases the message was being set within SearchService.js, in others it was coming directly from the DW.PropertySearch stored procedure
        // Hence why this is done in one place here. Might be able to work something out within SearchService...
        if (errorResponse.includes('There are too many properties for the area displayed')) {
          bTooManyPropertiesReturned = true;
          errorResponse = 'Enter a location and click Search or click a pin group below to get started.';
        }
        message = errorResponse;
      } else {
        message = errorResponse.message;
        if (errorResponse.message.includes('There are too many properties for the area displayed')) {
          bTooManyPropertiesReturned = true;
          message = 'Enter a location and click Search or click a pin group below to get started.';
        }
        if(errorResponse.includedProperties) {
          properties = processSearchResults(errorResponse.includedProperties);
        }
      }
      if(!$scope.addressSearchReRun){
        $scope.errorMessage = $sce.trustAsHtml(message);
      }
      $scope.properties.items = properties;
      resetAllPropertyCounts();
      $scope.hospitals = [];
      $scope.plannedProperties = [];
      $scope.showCbsaPins = bingMap.getZoom() < $scope.cbsaPinZoomLevelCutoff;
      $scope.showPinClusters = !$scope.showCbsaPins && bTooManyPropertiesReturned;

      canRunPars();
    }

    function bingSearchHasError(message) {
      $scope.errorMessage = $sce.trustAsHtml(message);
    }

    function resetSearchOptions() {
      AuditService.logEvent(knownEventConstant.reset.id, knownEventConstant.reset.message, $scope.product);
      var bounds = BingMapUtils.boundsFromMap(bingMap);
      var zoom = BingMapUtils.getCurrentZoom(bingMap);
      $state.go('localpropertysearch.base',
        {
          id: null,
          resetState: {
            mapState: {
              latitude: bounds.latitude,
              longitude: bounds.longitude,
              zoom: zoom
            },
            fullyCollapsed: $scope.fullyCollapsed,
          }
        }, { reload: true });
    }

    //used for reset button and for initializing $scope.filter
    //to initialize $scope.filter for saved filters we're going to need to tease these apart.
    function resetFilter() {
      $scope.userDrawing.currentShape = null;
      $rootScope.$broadcast('DRAWINGTOOLS.CLEAR');
      $scope.properties.items = [];
      resetAllPropertyCounts();
      $scope.hospitals = [];
      $scope.showHospitals = false;
      $scope.showPlannedProperties = false;
      $scope.plannedProperties = [];
      $scope.filter = {
        showAdvancedSearch: false,
        communityTypeIds: null, //setting to [] gives zero results
        vanilla: {},
        transaction: {},
        transactionOnly: false,
        userDrawingLocs: [],
        vintageId: vintageTypeConstant.quarterly
      };
      resetDriveTime();

      setApiModelFromFilter();

      $scope.selectedSearch = angular.copy(magicObjectToGetKendoToSelectFirstOption);
      selectedSearchId = '';
    };

    function setApiModelFromFilter() {
      savedFilter = FilterMapper.map($scope.filter, campusTypes, segmentTypes);
    }
    
    //will initialize map center and re-center map when re-called
    function setMapCenter(setMapCenter) {
      searchDetails = {
        latitude: setMapCenter.latitude,
        longitude: setMapCenter.longitude
      };
      $scope.mapOptions.center = searchDetails;
      if(setMapCenter.zoom) {
        $scope.mapOptions.zoom = setMapCenter.zoom; //setting this after initalization doesn't work because zoom doesn't 2 way databing
      }
    }

    function canRunPars() {
      var selectedCount = 0;
      var numOwnersNowPartOfCompany = 0;

      var ownerCount = {};
      var canRunParsCheck = false;
      _.each($scope.properties.items, function (v, k) {
        if (v.id === null || v.id === undefined) {
          return;
        }
        if(!propertiesOwnedByUser[v.id]) {
          if(v.selected == true) {
            selectedCount++;
            if (!ownerCount[v.stakeholders]) { //could do unique stakeholderID if transaciton search also returned stakeholderID
              ownerCount[v.stakeholders] = true;
              numOwnersNowPartOfCompany++;
            }
          }
        }
        if(v.selected == true) {
          if(selectedCount >= 4 && numOwnersNowPartOfCompany >= 4) {
            canRunParsCheck = true;
          }
        }
      });

      $scope.propertySelectedCount = selectedCount;
      $scope.stakeholdersSelected = numOwnersNowPartOfCompany;
      
      if (selectedCount > 50)
        canRunParsCheck = false;

      if (savedFilter && savedFilter.search && parseInt(savedFilter.search.vintageId) == vintageTypeConstant.threeMonthRolling) {
        // if three month rolling selected then by default never enable PAR.
        canRunParsCheck = false;
        $scope.autoParPopoverMsg = 
          'Property Advisor Report functionality is not available for the three-month rolling reporting period type. Please change your selection under Advanced to run this report.';
      } else {
        $scope.autoParPopoverMsg = 
          'Please select at least 4 properties with 4 different Operators to run a Property Advisor Report. The maximum comparable set is 50 properties.';
      }

      $scope.isPARRunning = !canRunParsCheck;
      return canRunParsCheck;
    }

    function propertySelectionsChanged(event, args) {
      if (args && args.sourceProperty) {
        args.sourceProperty.updateIconImage();
      }
      updateCheckAllButton();
      canRunPars();
    }

    function updateCheckAllButton() {
      var numPropertiesWithID = $scope.properties.totalPropertiesWIdCount;
      var numDifferentFromCheckAllButton = 0;
      angular.forEach($scope.properties.items, function(pin) {
        if(pin.id && pin.selected != $scope.checkAllButton) {
          numDifferentFromCheckAllButton++;
        }

        if (pin.id && !pin.selected && _.indexOf($scope.filter.includeSelectedPropertyIDs, pin.id) !== -1) {
          // pin was marked as selected but no longer the case
          _.remove($scope.filter.includeSelectedPropertyIDs, function (removePin) { return removePin === pin.id; });
        }
      });

      if(numPropertiesWithID == numDifferentFromCheckAllButton) {
        $scope.checkAllButton = $scope.properties.items[0].selected;
        $scope.selectAllInTradeAreaOne = $scope.properties.items[0].selected;
        $scope.selectAllInTradeAreaTwo = $scope.properties.items[0].selected;
        $scope.selectAllInRadius = $scope.properties.items[0].selected;
      }
    }

    function checkAll() {
      var eveythingMatchesCheckAllButton = true;
      $scope.selectAllInPolygon = $scope.checkAllButton;
      $scope.selectAllInTradeAreaOne = $scope.checkAllButton;
      $scope.selectAllInTradeAreaTwo = $scope.checkAllButton;
      $scope.selectAllInRadius = $scope.checkAllButton;
      
      _.each($scope.properties.items, function(pin) {
        if(pin.id) {
          pin.selected = $scope.checkAllButton;
          $scope.$broadcast("propertySelectionsChanged", { sourceProperty: pin, isSelected: pin.selected });
        }
      });

      var evtMessage = ($scope.checkAllButton ? "Select" : "Unselect") + " All Properties";
      AuditService.logEvent(knownEventConstant.selectUnselectAllProperties.id, evtMessage, $scope.product);
    }

    function driveTimeLabelColor(driveTimeNumber) {
      var sourceToUse = savedFilter.search.driveTimeInfo;
      if (sourceToUse && sourceToUse[driveTimeNumber]) {
       return GeoDataService.getColors(sourceToUse[driveTimeNumber].trafficFlow).strokeColor;
      }
      return GeoDataService.getColors().strokeColor;
    }

    function getPropertiesOwnByUser() {
      propertiesOwnedByUser = {}; //initialize
      PortfolioService.isPortfolioSubscriber().then(function(isSubscriber) {
        if(isSubscriber) {
          PortfolioService.portfolioPropertyIds().then(function(propertyIds) {
            //cpmvert from list of numbers to a hash of numbers
            angular.forEach(propertyIds, function(value, key) {
              propertiesOwnedByUser[value] = true;
            });
          });
        }
      });
    }

    function showCoveredCountyOutlinesClick() {
      $scope.coveredCountiesPolygons = [];
      if($scope.showCountyOutlines.coverageCountychecked) {
        drawCountyOutlines();
        AuditService.logEvent(knownEventConstant.showCounties.id, knownEventConstant.showCounties.message, $scope.product);
      }
      // this one needs to be set to false to make it work for the 'outside-if-not' behavior
      $scope.hideLegend = false;
    }

    function showNonCoveredCountyOutlinesClick() {
      $scope.nonCoveredCountiesPolygons = [];
      if($scope.showCountyOutlines.nonCoverageCountychecked) {
        drawCountyOutlines();
        AuditService.logEvent(knownEventConstant.showNonCoverage.id, knownEventConstant.showNonCoverage.message, $scope.product);
      }
      // this one needs to be set to false to make it work for the 'outside-if-not' behavior
      $scope.hideLegend = false;
    }

    function showHospitalsClick() {
      $scope.hospitals = [];
      if($scope.showHospitals) {
        AuditService.logEvent(knownEventConstant.showHospitals.id, knownEventConstant.showHospitals.message, $scope.product);
        if ($scope.properties.items.length > 0) 
          drawHospitalPins();
      }
    }

    function showPlannedPropertiesClick() {
      $scope.plannedProperties = [];
      if($scope.showPlannedProperties) {
        AuditService.logEvent(knownEventConstant.showPlannedProperties.id, knownEventConstant.showPlannedProperties.message, $scope.product);
        if ($scope.properties.items.length > 0) {
          drawPlannedPropertyPins();
        }
      }
    }

    function drawCountyOutlines() {
      if (BingMapUtils.getCurrentZoom(bingMap) >= 9) {
        SpinnerService.start();
        BingGeoDataService
          .getAllCountiesBoundariesInBbox(bingMap, $scope.showCountyOutlines.coverageCountychecked, $scope.showCountyOutlines.nonCoverageCountychecked)
          .then(function (bingPolygons) {
            if ($scope.showCountyOutlines.coverageCountychecked)
              $scope.coveredCountiesPolygons = bingPolygons;
            if ($scope.showCountyOutlines.nonCoverageCountychecked)
              $scope.nonCoveredCountiesPolygons = bingPolygons;
            SpinnerService.stop();
          });
      }
      else {
        $scope.coveredCountiesPolygons = [];
        $scope.nonCoveredCountiesPolygons = [];
      }
    }
    
    function getTradeAreaDataViewModel(bounds, isInTradeAreaOne, isInTradeAreaTwo, localIsRadiusOneSearch, localIsRadiusTwoSearch, isInUserPolygon, isInUserPolygonButtonSearch){
      var tradeAreaDataViewModel = {
        latitude: 0,
        longitude: 0,
        topLeftLatitude: 0,
        topLeftLongitude: 0,
        bottomRightLatitude: 0,
        bottomRightLongitude: 0,
        radius1: null,
        radius2: null,
        userDrawingLocs: null,
        hasuserDrawing: false,
        hasShape1: false,
        hasShape2: false
      }

      tradeAreaDataViewModel.hasShape1 = isInTradeAreaOne;
      tradeAreaDataViewModel.hasShape2 = isInTradeAreaTwo;
      
      if (!isInUserPolygonButtonSearch && (savedFilter.search.radius || savedFilter.search.radiusOuter || (savedFilter.search.userDrawingLocs && savedFilter.search.userDrawingLocs.length > 0)))
      {
        if (parseInt(savedFilter.search.radius))
          tradeAreaDataViewModel.radius1 = parseInt(savedFilter.search.radius);
        
        if (parseInt(savedFilter.search.radiusOuter) )
          tradeAreaDataViewModel.radius2 = parseInt(savedFilter.search.radiusOuter);

        if (localIsRadiusOneSearch || localIsRadiusTwoSearch){
          tradeAreaDataViewModel.latitude = savedFilter.radiusLatitude;
          tradeAreaDataViewModel.longitude = savedFilter.radiusLongitude;
        
        } else{
        tradeAreaDataViewModel.latitude = savedFilter.search.coordinates[0];
        tradeAreaDataViewModel.longitude = savedFilter.search.coordinates[1];

          if(isInUserPolygon)
          {
            tradeAreaDataViewModel.userDrawingLocs = savedFilter.search.userDrawingLocs
            tradeAreaDataViewModel.hasuserDrawing = true;
          }
        } 
      } else {
         tradeAreaDataViewModel.latitude = savedFilter.search.coordinates[0];
         tradeAreaDataViewModel.longitude = savedFilter.search.coordinates[1];

        if (isInUserPolygon) {
          tradeAreaDataViewModel.userDrawingLocs = $scope.filter.userDrawingLocs;
          tradeAreaDataViewModel.hasuserDrawing = true;
        }
      } 
   
      tradeAreaDataViewModel.topLeftLatitude = bounds.bbox[0];
      tradeAreaDataViewModel.topLeftLongitude = bounds.bbox[1];
      tradeAreaDataViewModel.bottomRightLatitude = bounds.bbox[2];
      tradeAreaDataViewModel.bottomRightLongitude = bounds.bbox[3];

      return tradeAreaDataViewModel;
    }

    function getHospitalData(){
      var localIsDriveTimeOneSearch = isDriveTimeOneSearch(), localIsDriveTimeTwoSearch = isDriveTimeTwoSearch();
      var localIsRadiusOneSearch = isRadiusOneSearch(), localIsRadiusTwoSearch = isRadiusTwoSearch();
      var localIsUserPolygonSearch = isUserPolygonSearch(), localIsUserPolygonSearchButtonSearch = isUserPolygonSearchButtonSearch();
      var isInTradeAreaOne = false, isInTradeAreaTwo = false, isInUserPolygon = false, isInUserPolygonButtonSearch = false;
      
      if (localIsDriveTimeOneSearch) {isInTradeAreaOne = true;}
      if (localIsDriveTimeTwoSearch) {isInTradeAreaTwo = true;}
      if (localIsRadiusOneSearch) {isInTradeAreaOne = true;}
      if (localIsRadiusTwoSearch) {isInTradeAreaTwo = true;} 
      if (localIsUserPolygonSearch) {isInUserPolygon = true;}
      if (localIsUserPolygonSearchButtonSearch) {isInUserPolygonButtonSearch = true;} 
      
      var localIsInPolygon = (isInTradeAreaOne || isInTradeAreaTwo || isInUserPolygon);

      var defer = $q.defer();
      var bounds = BingMapUtils.boundsFromMap(bingMap);
      var bbox = {
        topLeftLatitude: bounds.bbox[0],
        topLeftLongitude: bounds.bbox[1],
        bottomRightLatitude: bounds.bbox[2],
        bottomRightLongitude: bounds.bbox[3],
      };
    
      if (localIsInPolygon && (!localIsDriveTimeOneSearch && !localIsDriveTimeTwoSearch))
      {
        //Perform trade area hostpital search when a non drivetime tradeArea is selected
        var tradeAreaDataViewModel = getTradeAreaDataViewModel(bounds, isInTradeAreaOne, isInTradeAreaTwo, localIsRadiusOneSearch, localIsRadiusTwoSearch, isInUserPolygon, isInUserPolygonButtonSearch);
        HospitalsService
        .getByTradeArea(tradeAreaDataViewModel)
        .then(function(hospitals) {
          var hosp_data = [];
         _.each(hospitals.data, function (hospital, index) {
            hospital.distance = Nic.maps.twoPointDistance(tradeAreaDataViewModel.latitude, tradeAreaDataViewModel.longitude, hospital.location.coordinates[1], hospital.location.coordinates[0]);
            hospital.distance = Math.round(hospital.distance * 10) / 10;
            
            var pushpin = pinFactory.buildHospitalPin(hospital, index, null, $scope);
            hosp_data.push(pushpin);
          });
          defer.resolve({
            apiSuccess: hospitals.apiSuccess,
            data: hosp_data
          });
        },function(err){
          defer.reject({
            apiSuccess: hospitals.apiSuccess,
            data: null
          });
        });
        return defer.promise;
      } else {
        //Perform regular hostpital search when no tradeArea selected and when there is a DriveTime selected
        HospitalsService
        .getByBBox(bbox)
        .then(function(hospitals) {
          var hosp_data = [];
          var currentMapView = BingMapUtils.boundsFromMap(bingMap);
          _.each(hospitals.data, function (hospital, index) {
            var isInDriveTimeOne = false, isInDriveTimeTwo = false;
            // clone reference value so that mutating doesn't move black pin
            var sitePin = _.cloneDeep($scope.customSearch);
            sitePin.latitude = sitePin.options && sitePin.options.visible ? sitePin.latitude
                                                                          : currentMapView.latitude;
            sitePin.longitude = sitePin.options && sitePin.options.visible ? sitePin.longitude
                                                                           : currentMapView.longitude;
            
            hospital.distance = Nic.maps.twoPointDistance(sitePin.latitude, sitePin.longitude, hospital.location.coordinates[1], hospital.location.coordinates[0]);
            hospital.distance = Math.round(hospital.distance * 10) / 10;

            if (localIsDriveTimeOneSearch) {
              isInDriveTimeOne = BingMapUtils.pointInPolygon(savedFilter.search.driveTimeInfo.drive1.driveTimeLocs, hospital.location.coordinates[1], hospital.location.coordinates[0])
            } 
            if (localIsDriveTimeTwoSearch) {
              isInDriveTimeTwo = BingMapUtils.pointInPolygon(savedFilter.search.driveTimeInfo.drive2.driveTimeLocs, hospital.location.coordinates[1], hospital.location.coordinates[0])
            } 
            var isInTradeArea = (localIsDriveTimeOneSearch && isInDriveTimeOne) || (localIsDriveTimeTwoSearch && isInDriveTimeTwo);
            if (isInTradeArea || (!localIsDriveTimeOneSearch && !localIsDriveTimeTwoSearch)) {
              var pushpin = pinFactory.buildHospitalPin(hospital, index, null, $scope);
              hosp_data.push(pushpin);
            }
          });
          defer.resolve({
            apiSuccess: hospitals.apiSuccess,
            data: hosp_data
          });
        },function(err){
          defer.reject({
            apiSuccess: false,
            data: null
          });
        });
        return defer.promise;
      }
    }

    function getPlannedPropertyData(isExport){
      var defer = $q.defer();
      var bounds = BingMapUtils.boundsFromMap(bingMap);
      var bbox = {
        topLeftLatitude: bounds.bbox[0],
        topLeftLongitude: bounds.bbox[1],
        bottomRightLatitude: bounds.bbox[2],
        bottomRightLongitude: bounds.bbox[3],
      };
      var localIsDriveTimeOneSearch = isDriveTimeOneSearch(), localIsDriveTimeTwoSearch = isDriveTimeTwoSearch();
      var localIsRadiusOneSearch = isRadiusOneSearch(), localIsRadiusTwoSearch = isRadiusTwoSearch();
      var localIsUserPolygonSearch = isUserPolygonSearch(), localIsUserPolygonSearchButtonSearch = isUserPolygonSearchButtonSearch();
      var localIsPropertyRadiusSearch = $scope.showPropertyRadius && $scope.propertyRadius.locations && $scope.propertyRadius.locations.length > 0;

      PlannedPropertyService
        .getByBBox(bbox, isExport || false)
        .then(function(plannedProperties) {
          var isInTradeAreaOne = false, isInTradeAreaTwo = false; // for search radius options and drive times
          var isInUserPolygon = false, isInPropertyRadius = false;
          var filteredPlannedProperties = _.filter(plannedProperties, function(value){
            if (localIsDriveTimeOneSearch) {
              isInTradeAreaOne = BingMapUtils.pointInPolygon($scope.driveTimeEsriPolygons[0].locations, value.latitude, value.longitude);
            }
            if (localIsDriveTimeTwoSearch) {
              isInTradeAreaTwo = BingMapUtils.pointInPolygon($scope.driveTimeEsriPolygons[1].locations, value.latitude, value.longitude);
            }
            if (localIsRadiusOneSearch) {
              isInTradeAreaOne = BingMapUtils.pointInPolygon($scope.searchRadius.locations, value.latitude, value.longitude);
            }
            if (localIsRadiusTwoSearch) {
              isInTradeAreaTwo = BingMapUtils.pointInPolygon($scope.searchRadius2.locations, value.latitude, value.longitude);
            }
            if (localIsUserPolygonSearch) {
              isInUserPolygon = BingMapUtils.pointInPolygon($scope.filter.userDrawingLocs, value.latitude, value.longitude);
            }
            if (localIsUserPolygonSearchButtonSearch) {
              isInUserPolygon = BingMapUtils.pointInPolygon(savedFilter.search.userDrawingLocs, value.latitude, value.longitude);
            }
            if (localIsPropertyRadiusSearch) {
              isInPropertyRadius = BingMapUtils.pointInPolygon($scope.propertyRadius.locations, value.latitude, value.longitude);
            }

            if(localIsDriveTimeOneSearch || localIsDriveTimeTwoSearch || localIsRadiusOneSearch || localIsRadiusTwoSearch || localIsUserPolygonSearch || localIsUserPolygonSearchButtonSearch || localIsPropertyRadiusSearch){
              if(!isInTradeAreaOne && !isInTradeAreaTwo && !isInUserPolygon && !isInPropertyRadius){
                return false;
              }
            }
            return true;
          });

          var currentMapView = BingMapUtils.boundsFromMap(bingMap);
          var plannedProperty_data = _.map(filteredPlannedProperties, function (plannedProperty, index) {
            // clone reference value so that mutating doesn't move black pin
            var sitePin = _.cloneDeep($scope.customSearch);
            sitePin.latitude = sitePin.options && sitePin.options.visible ? sitePin.latitude
                                                                          : currentMapView.latitude;
            sitePin.longitude = sitePin.options && sitePin.options.visible ? sitePin.longitude
                                                                           : currentMapView.longitude;

            plannedProperty.distance = Nic.maps.twoPointDistance(sitePin.latitude, sitePin.longitude, plannedProperty.latitude, plannedProperty.longitude);
            plannedProperty.distance = Math.round(plannedProperty.distance * 10) / 10;
              
            var pushpin = pinFactory.buildPlannedPropertyPin(plannedProperty, index, $scope);
            return pushpin;
          });

          defer.resolve(isExport ? filteredPlannedProperties
                                 : plannedProperty_data);
        }, function() {
          defer.reject([]);
        });
      return defer.promise;
    }

    function drawHospitalPins() {
     
      getHospitalData().then(function(hospitals){
        $scope.hospitals = [];
        if(!hospitals.apiSuccess){
          $scope.showHospitals = false;
          PopupHandlerService
            .openSimplePopup("Hospital data cannot be retrieved from Data.Medicare.gov at this time. Please try again later.");
        }
        else{
          $scope.hospitals = hospitals.data;
        }
      });            
    }

    function drawPlannedPropertyPins() {
      getPlannedPropertyData().then(function(plannedProperties) {
        var pinsByIssueAndVersion = _.orderBy(plannedProperties, ['issueDate', function (x) { return parseInt(x.version); }], ['desc', 'desc']); // sort by issueDate then by version desc
        var pinsByLatLong = _.groupBy(pinsByIssueAndVersion, function (x) { return x.latitude.toString() + x.longitude.toString(); }); // group by lat/long
        var pinsByLatLongMostRecentOnly = _.map(pinsByLatLong, function (x) { return _.head(x); }); // for each lat/long key, return the first entry of the group. as the list was already sorted by issue/version
        $scope.plannedProperties = pinsByLatLongMostRecentOnly;
      });
    }

    function clearPolygonsOnSearchClick() {
      $scope.$broadcast("closePropertyPinInfobox"); // close the infobox manually
      // clear drive time polygons that are available on the map
      // mostly when doing a new search through a click
      if (isDriveTimeOnlySearch()) {
        savedFilter.search.driveTimeInfo.drive1.driveTimeLocs = [];
        savedFilter.search.driveTimeInfo.drive2.driveTimeLocs = [];
        $scope.driveTimeEsriPolygons = [];
      }

      if ($scope.showPropertyRadius &&
        (isRadiusOnlySearch() || isDriveTimeOnlySearch() || isUserPolygonSearch())) {
        $scope.highlightedProperty = null;
        setClearPropertyRadiusFlag();
      }
    }

    function resetDriveTime() {
      $scope.tradeAreaFlags.driveTimeOnly = false;
      $scope.filter.driveTimeInfo = {
        drive1: {
          breakValue: null,
          trafficFlow: null,
          driveTimeLocs: []
        },
        drive2: {
          breakValue: null,
          trafficFlow: null,
          driveTimeLocs: []
        }
      };
    }

    function removeboundaries() {
      $scope.filter.userDrawingLocs = [];
      $scope.userDrawing.currentShape = null;
      $rootScope.$broadcast('DRAWINGTOOLS.CLEAR');
      // clear out covered counties
      $scope.coveredCountiesPolygons = [];
      $scope.showCountyOutlines.coverageCountychecked = false;
      // clear out non-covered counties
      $scope.nonCoveredCountiesPolygons = [];
      $scope.showCountyOutlines.nonCoverageCountychecked = false;
      // clear out property radius
      $scope.searchRadius.locations = [];
      $scope.searchRadius2.locations = [];
      clearPropertyRadius();
      $scope.showTradeAreaOne = false;
      $scope.showTradeAreaTwo = false;
      // reset the radius selection
      $scope.filter.radius1 = null
      $scope.filter.radius2 = null;
      $scope.filter.customRadius1 = null;
      $scope.filter.customRadius2 = null;
      // reset drive times
      resetDriveTime();
      $scope.tradeAreaFlags.radiusOnly = false;
      //log event
      AuditService.logEvent(knownEventConstant.removeBoundaries.id, knownEventConstant.removeBoundaries.message, $scope.product);
      // perform search
      applyAdvancedSearch();
    }

    function savedDisplayChanged() {
      if(ignoreSavedDisplayChangedEvent) {
        ignoreSavedDisplayChangedEvent = false;
        return;
      }
      if($scope.selectedSearch) {
        setSavedSearchNameOrId($scope.selectedSearch, 'name');
        SearchService.getSavedSearch(selectedSearchId)
          .then(function(rsp) {
            runSavedSearch(rsp)
            applyAdvancedSearch(rsp);
          }, function(err) {
            console.log('Error while changing the saved display dropdown');
        });
      }
    }

    function runMostRecentSearch() {
      SearchService.getRecentSearch()
        .then(function(rsp) {
          runSavedSearch(rsp)
          applyAdvancedSearch(rsp);
        }, function(err) {
          $scope.errorMessage = 'No recent search was found.';
        });
    }

    function runSavedSearch(searchData) {
      $rootScope.$broadcast('DRAWINGTOOLS.CLEAR');
      var bounds = {
        bbox: searchData.search.bbox,
        latitude: searchData.latitude,
        longitude: searchData.longitude
      }
      resetDriveTime();
      $scope.tradeAreaFlags.radiusOnly = false;
      $scope.tradeAreaFlags.customPolygonOnly = false;
      $scope.driveTimeEsriPolygons = [];
      $scope.filter = FilterMapper.setFiltersFromSavedSearch(searchData, campusTypes, segmentTypes, allRadiusList);
      setApiModelFromFilter();
      if(searchData.search.includeSelectedPropertyIDs) {
        FilterMapper.addSelectedProperties(savedFilter, searchData.search.includeSelectedPropertyIDs);
      }
      FilterMapper.addGeoData(savedFilter, $scope.filter, bounds);
      // no need to call setMapCenter since the map gets set based on viewchanges that get fired.
      searchDetails = {
        latitude: searchData.latitude,
        longitude: searchData.longitude
      };
      $scope.$broadcast('RESETDRIVETIME.TRAFFICFLOW2', savedFilter);
    }

    function savedPARReportDisplayChanged() {
      if($scope.selectedPARReport) {
        PopupHandlerService.openReportInNewTab('propertyAdvisor', $scope.selectedPARReport.id, 'Property Advisor Report');

        var selectedPARRow = _.filter($scope.savedPARReportDisplays, function (obj) { return obj.id == $scope.selectedPARReport.id; });
        if (selectedPARRow.length) {
          PropertyAdvisorService.logRunSavedPar($scope.selectedPARReport.id);
        }
      }
    }

    // this will return actual values for the matches
    function setSavedSearchNameOrId(toCheckFor, fieldToLookInto) {
      if(toCheckFor) {
        var valueToCompare;
        if (toCheckFor.id && fieldToLookInto == 'id') {
          valueToCompare = toCheckFor.id;
        } else if (toCheckFor.name && fieldToLookInto == 'name') {
          valueToCompare = toCheckFor.name;
        } else {
          valueToCompare = toCheckFor;
        }
        _.each(savedSearches, function(v, k) {
          if((fieldToLookInto == 'id' && v.id == valueToCompare) ||
            (fieldToLookInto == 'name' && angular.lowercase(v.name) == angular.lowercase(valueToCompare))) {
            $scope.selectedSearch = v;
            selectedSearchId = v.id;
            return false;
          }
        });
      }
    }

    // this only checks if the value exists or not...
    // ideal thing to do would be to merge 'setSavedSearchNameOrId' inside here but for now this should suffice
    function isExistingName(newName) {
      var exists = false;
      if(!newName) {
        return false;
      }
      _.each(savedSearches, function(v, k) {
        if(angular.lowercase(v.name) == angular.lowercase(newName)) {
          exists = true;
          return false;
        }
      });
      return exists;
    }

    function filterNameEnterClick(newName) {
      if((!newName) || (newName.length > 32)) {
        return false;
      }
      savePopupModalInstance.close(newName);
    }

    function openSaveDialog() {
      if($scope.isSaveSearchDisabled()) {
        return;
      }
      savePopupModalInstance = PopupHandlerService.openPopupForTemplate("app/partials/saveDataPopup.html", popupController);
      savePopupModalInstance.result.then(function(newSearchName) {
        if(newSearchName.indexOf('/') != -1 || newSearchName.indexOf('\\') != -1) {
          $window.alert("slashed are not allowed in filter name. Nothing will be saved.");
          return;
        }
        // perform search and save
        $scope.closeThis('ALL');
        createSavedSearch(newSearchName, true);
      });
    }

    function createSavedSearch(newSearchName, saveAuditRecord) {
      var currentZoom = BingMapUtils.getCurrentZoom(bingMap);
      //add current selected properties to savedFilter
      addSelectedProperties();
      SearchService.createSavedSearch(savedFilter, currentZoom, newSearchName, (saveAuditRecord || false))
        .then(function() {
          SearchService.getSavedSearches()
            .then(function(data) {
              $scope.savedDisplays = data;
              savedSearches = data;
              if(saveAuditRecord) { //save recent search was setting this to true, only ignore when saving a real search.
                ignoreSavedDisplayChangedEvent = true;
              }
              setSavedSearchNameOrId(newSearchName, 'name');
            });
        });
    }

    function showPropertyRadiusClick() {
      if($scope.showPropertyRadius) {
        $scope.filter.selectAllInRadius = $scope.selectAllInRadius;
        var selectedProp = $scope.highlightedProperty;
        $scope.filter.latitude = selectedProp.latitude;
        $scope.filter.longitude = selectedProp.longitude;
        var propertyLatLong = {
          latitude: $scope.filter.latitude,
          longitude: $scope.filter.longitude
        };
        $scope.propertyRadius.locations = BingMapUtils.buildCircle($scope.filter.latitude, $scope.filter.longitude, $scope.propertyRadiusOptions.radius)
        if (angular.isArray($scope.propertyRadius.locations)) {
          BingMapUtils.centerMapToLocations(bingMap, $scope.propertyRadius.locations);
        }

        AuditService.logEvent(knownEventConstant.showPropertyRadius.id, knownEventConstant.showPropertyRadius.message + ": " + $scope.propertyRadiusOptions.radius,
          $scope.product, selectedProp.id);
      } else {
        $scope.propertyRadius.locations = [];
        setClearPropertyRadiusFlag();
      }
    }

    function selectAllPropertiesInPolygon(tradeSection, isSelected) {
      _.each($scope.properties.items, function(property, index) {
        var needToSelectProperty = false;
        if ((tradeSection == 'TradeAreaOne' && (property.isInTradeAreaOne || property.isInUserPolygon)) ||
            (tradeSection == 'TradeAreaTwo' && property.isInTradeAreaTwo) ||
            (tradeSection == 'PropertyRadius' && property.isInPropertyRadius)) {
          needToSelectProperty = true;
        }
        if (needToSelectProperty) {
          property.selected = isSelected;
          $scope.$broadcast("propertySelectionsChanged", { sourceProperty: property, isSelected: property.selected });
        }
      });
      updateCheckAllButton();
    }

    function selectPropertiesInPropertyRadius() {
      var isSelected = $scope.selectAllInRadius;
      selectAllPropertiesInPolygon('PropertyRadius', isSelected);
      logSelectAllEvent(knownEventConstant.selectUnselectAllPropertyRadius, isSelected);
      updateCheckAllButton();
    }

    function selectPropertiesInTradeAreaOne() {
      var isSelected = $scope.selectAllInTradeAreaOne;
      if (isRadiusOneSearch()) {
        selectAllPropertiesInPolygon('TradeAreaOne', isSelected);
        logSelectAllEvent(knownEventConstant.selectUnselectAllRadiusOne, isSelected);
        return;
      }
      if (isDriveTimeOneSearch()) {
        selectAllPropertiesInPolygon('TradeAreaOne', isSelected);
        logSelectAllEvent(knownEventConstant.selectUnselectAllDriveTimeOne, isSelected);
        return;
      }
      if ($scope.tradeAreaFlags.customPolygonOnly) {
        selectAllPropertiesInPolygon('TradeAreaOne', isSelected);
        logSelectAllEvent(knownEventConstant.selectUnselectAllPolygon, isSelected);
        return;
      }
    }

    function selectPropertiesInTradeAreaTwo() {
      var isSelected = $scope.selectAllInTradeAreaTwo;
      if (isRadiusTwoSearch()) {
        selectAllPropertiesInPolygon('TradeAreaTwo', isSelected);
        logSelectAllEvent(knownEventConstant.selectUnselectAllRadiusTwo, isSelected);
        $scope.selectAllInTradeAreaOne = isSelected;
        return;
      }
      if (isDriveTimeTwoSearch()) {
        selectAllPropertiesInPolygon('TradeAreaTwo', isSelected);
        logSelectAllEvent(knownEventConstant.selectUnselectAllDriveTimeTwo, isSelected);
        return;
      }
    }

    //Want to move out and into the sidebar directives but currently tied here due to it being called in search
    function highlightPin(data) {
      var update = function(arr, key, newval) {
        var match = _.find(arr, key);
        if(match)
          _.merge(match, newval);
      };
      //if pin clicked does not currently exist on displayed properties
      $scope.$broadcast('updateDisplayCountToShowHighlightPin', data);
      //unhighlight previous
      update($scope.properties.items, $scope.highlightedProperty, {highlighted: false});
      //highlight selected
      update($scope.properties.items, {id: data.id}, {highlighted: true});

      $scope.highlightedProperty = _.find($scope.properties.items, ['id', data.id]);
      // $scope.$digest();
      $timeout(function(){
        $anchorScroll("property" + data.id);         
      });
    }

    function deleteSavedSearch(filtername, filtervalue) {
      SearchService
        .deleteSavedSearchQuery(filtername, filtervalue)
        .then(function () {
          SearchService.getSavedSearches()
            .then(function(data) {
              $scope.savedDisplays = data;
              savedSearches = data;
              if (filtervalue == selectedSearchId) {
                $scope.selectedSearch = angular.copy(magicObjectToGetKendoToSelectFirstOption);
                selectedSearchId = '';
              }
            });
        });
    }

    function deleteSavedPAR(filtername, filtervalue) {
      PropertyAdvisorService
        .deleteSavedReportQuery(filtername, filtervalue)
        .then(function () {
          PropertyAdvisorService.getSavedPARReports()
            .then(function(data) {
              $scope.savedPARReportDisplays = data;
              $scope.selectedPARReport = null;
            });
        });
    }

    $scope.itemTracker = function (item) {
      return item.transactionId || item.id || item;
    }

    function initPropertiesDisplayCount() {
      var height = $('.map-container').height();
      if(height){
        return _.floor(height/50); //standard list item height
      } else {
        return 15;
      }
    }

    $scope.isSaveSearchDisabled = function() {
      if(initialBingResult.default_view) {
        return true;
      }
      var mutualProperties = ['query','campusTypeIds','driveTimeInfo.drive1.breakValue','driveTimeInfo.drive1.trafficFlow',
        'driveTimeInfo.drive2.breakValue','driveTimeInfo.drive2.trafficFlow','isForProfit','operator','propertyAgeCategoryIds',
        'propertyName','propertyStatuses','propertyTypeIds','radius','radiusOuter','segmentTypeIds','transactionOnly','userDrawingLocs'];
      var mapped = FilterMapper.map($scope.filter, campusTypes, segmentTypes);
      var narrowedSavedFilter = _.assign({query: ""}, _.pick(savedFilter.search, mutualProperties));
      var narrowedCurrentFilter = _.assign(_.defaults(_.pick(mapped, ['query']), {query: ""}), _.pick(mapped.search, mutualProperties));
      return !_.isEqual(narrowedSavedFilter, narrowedCurrentFilter);
    }

    $scope.$on('closeAll', function() {
      closeThis('ALL');
    });

    $scope.$on('triggerSearch', function () {
      advancedSearchButtonClick();
    });

    $scope.toggleSelectSectionVisible = function() {
      $scope.selectSectionVisible = !$scope.selectSectionVisible;
    }

    function logSelectAllEvent(eventConst, isSelected) {
      var message = eventConst.message.replace("Select/Unselect", isSelected ? "Select" : "Unselect"); 
      AuditService.logEvent(eventConst.id, message, $scope.product);
    }

    function validateWalkScoreFilter(minValue, maxValue) {
      if(parseInt(minValue) > parseInt(maxValue)) {
        return false;
      }
      return true;
    }

    function validateCMSFilter(minValue, maxValue) {
      if(parseInt(minValue) > parseInt(maxValue)) {
        return false;
      }
      return true;
    }
  }
}());
