(function() {
  "use strict";

  app.controller('DocumentLibraryController', DocumentLibraryController);
  DocumentLibraryController.$inject = ['$scope', '$rootScope', '$state', '$window',
    'FileSaver', 'Blob', 'ReportsService', 'SpinnerService', 'knownEventConstant','libraryItems'];

  function DocumentLibraryController($scope, $rootScope, $state, $window,
    FileSaver, Blob, ReportsService, SpinnerService, knownEventConstant, libraryItems) {
    $rootScope.hideLeftNav = true;
    $scope.backToSupportClick = backToSupportClick;
    $scope.fileProxyDownload = fileProxyDownload;
    $scope.libraryData = libraryItems;

    function init() {
      SpinnerService.start();
      $scope.gridLibrary.dataSource.read();
      SpinnerService.stop();
    }

    $scope.gridLibrary = {
      columns: [
        {
          field: "title",
          title: "File Name",
          template: "<a ng-click='fileProxyDownload(this.dataItem)' #if(!isFile){#download#}#>#=title#</a>",
          width: "300px",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "fileType",
          title: "File Type",
          width: "21px",
          filterable: {
            cell: {
              showOperators: false,
              operator: "eq"
            }
          }
        }, {
          field: "size",
          title: "Size",
          width: "100px",
          filterable: false
        }, {
          field: "description",
          title: "Description",
          filterable: false
        }, {
          field: "product",
          title: "Product",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "url",
          title: "Download",
          filterable: false,
          template: "<a ng-click='fileProxyDownload(this.dataItem)' #if(!isFile){#download#}#><img ng-src='assets/support/file-download.png' alt='#=title#' /></a>"
        }
      ],
      dataSource: new kendo.data.DataSource({
        transport: {
          read: function (opts) {
            opts.success($scope.libraryData);
          }
        },
        pageSize: 25,
        error: function (err) {
          console.log('error - ' + err);
        },
        schema: {
          model: {
            fields: {
              'title': { type: 'string' },
              'fileType': { type: 'string' },
              'size': { type: 'string' },
              'description': { type: 'string' },
              'product': { type: 'string' },
              'url': { type: 'string' }
            }
          }
        },
        sort: {
          field: "title",
          dir: "asc"
        },
        serverPaging: false,
        serverFiltering: false,
        serverSorting: false
      }),
      dataBinding: function (e) {
        Nic.kendoGrid.filtersOnTop('grid');
      },
      filterable: {
        mode: "row"
      },
      scrollable: false,
      sortable: {
        mode: "single",
        allowUnsort: false
      },
      pageable: true,
      resetFilters: function() {
        this.dataSource.filter({});
        this.dataSource.sort({ field: "title", dir: "asc" });
      }
    };

    init();

    function fileProxyDownload(dataItem) {
      if (!dataItem.isFile) {
        // url to a webpage
        if(!dataItem.isExternalLink){
          $window.open(dataItem.url, '_self');
        } else {
          $window.open(dataItem.url, '_blank');
        }
      } else {
        // download the file
        SpinnerService.start();
        var params = {
          target: dataItem.fileURL,
          fileName: dataItem.title,
          fromWhere: "Document Library",
          userEventId: knownEventConstant.documentLibraryDownload.id
        };
        ReportsService
          .fileProxyDownload(params)
          .then(function (data) {
            var file = new Blob([data], {
              type: dataItem.contentType
            });
            var fileName = dataItem.title.replace(/ /g, '_') + '.' + dataItem.fileType;
            FileSaver.saveAs(file, fileName);
            SpinnerService.stop();
          });
      }
  	}

    function backToSupportClick() {
      $state.go('support');
    };
  }
})();