(function () {
  "use strict";

  app.controller('AdminPortfoliosController', AdminPortfoliosController);

  AdminPortfoliosController.$inject = ['$scope', '$rootScope', '$uibModal', '$filter', '$state', 'sessionStorageKeys', 'PortfolioService', 'PopupHandlerService', 'AdminPortfolioService', 'SpinnerService'];

  function AdminPortfoliosController($scope, $rootScope, $uibModal, $filter, $state, sessionStorageKeys, PortfolioService, PopupHandlerService, AdminPortfolioService, SpinnerService) {
    $rootScope.hideLeftNav = true;
    $scope.subscriptionId = 0;
    $scope.showExpiredPortfolios = false;
    $scope.showDeletedPortfolios = false;
    $scope.showCustomPortfolios = false;
    $scope.portfolio = {};
    $scope.portfoliosData = [];
    $scope.filteredPortfoliosData = [];
    $scope.gridDataBinding = gridDataBinding;

    var pageInfoToSave = {
      key: sessionStorageKeys.adminPortfolios,
      value: {
        showExpired: false,
        showDeleted: false,
        showCustom: false,
        filters: null,
        sort: null
      }
    };

    $scope.$watchGroup(['showDeletedPortfolios','showExpiredPortfolios','showCustomPortfolios'], function(newVals, oldVals) {
      $scope.buildFilteredPortfolios();
      $scope.gridOpts.dataSource.read();
    });

    $scope.deletePortfolioModal = function (id, name) {
      $scope.portfolio = { id: id, name: name };

      $uibModal.open({
        animation: true,
        templateUrl: 'app/partials/adminPortfolioDelete.html',
        size: 10,
        scope: $scope
      }).result.then(deletePortfolio);
    };

    $scope.addNewPortfolio = function () {
      var newPortfolioPopup = $uibModal.open({ 
        templateUrl: "app/partials/adminAddPortfolioPopup.html", 
        controller: 'AdminPortfolioPopupController', 
        backdrop: 'static', 
        windowClass: 'modify-access-popup', 
        resolve: { 
          subscriptions: function () { return AdminPortfolioService.getSubscriptionsWithPortfolioAccess(); },
          portfolioTypes: function() { return AdminPortfolioService.getPortfolioTypes(); }
        } 
      });  
        
      newPortfolioPopup.result.then(function (dataReturned) {   
        $state.go('admin.portfolios.details',  
        { 
          id: dataReturned.portfolioId,  
        },  
        { reload: true });  
      });  
    };

    function deletePortfolio (modalReturn) {
      if (!modalReturn)
        return;

      PortfolioService.deletePortfolio($scope.portfolio.id)
        .then(function () {
          var currentPageIndex = $scope.gridOpts.dataSource.page();
          getPortfolios(true, currentPageIndex);
        },function(){
          PopupHandlerService.openSimplePopup("Delete failed on portfolio: " + $scope.portfolio.name + ". Please contact an administrator.");
        });
    }

    function includeDeletedPortfolios(sourceData, includeDeleted) {
      if(includeDeleted === false) {
        return _.reject(sourceData, ['portfolioType', "Deleted"]);
      } else if(includeDeleted === true) {
        return $scope.filteredPortfoliosData;
      }
    }

    function includeCustomPortfolios(sourceData, includeCustom) {
      if(includeCustom === false) {
        return _.reject(sourceData, ['portfolioType', "Custom"]);
      } else if(includeCustom === true) {
        return $scope.filteredPortfoliosData;
      }
    }

    function includeExpiredPortfolios(sourceData, includeExpired) {
      if(includeExpired === false) {
        return _.reject(sourceData, ['accountStatus', "Expired"]);
      } else if(includeExpired === true) {
        return $scope.filteredPortfoliosData;
      }
    }

    $scope.buildFilteredPortfolios = function buildFilteredPortfolios() {
      $scope.filteredPortfoliosData = $scope.portfoliosData;
      $scope.filteredPortfoliosData = includeExpiredPortfolios($scope.filteredPortfoliosData, $scope.showExpiredPortfolios);
      $scope.filteredPortfoliosData = includeCustomPortfolios($scope.filteredPortfoliosData, $scope.showCustomPortfolios);
      $scope.filteredPortfoliosData = includeDeletedPortfolios($scope.filteredPortfoliosData, $scope.showDeletedPortfolios);
    };

    function getPortfolios(isPageLoad, pageToGo) {
      var parsedOptions = null;
      if (isPageLoad) {
        // page load... check if session storage has the data saved or not?
        var options = sessionStorage[pageInfoToSave.key];
        if (options) {
          parsedOptions = JSON.parse(options);
          $scope.showExpiredPortfolios = parsedOptions.showExpired;
          $scope.showDeletedPortfolios = parsedOptions.showDeleted;
          $scope.showCustomPortfolios = parsedOptions.showCustom;
        }
      }

      SpinnerService.start();
      PortfolioService.getAllPortfolios(true)//$scope.showExpiredPortfolios)
        .then(function (data) {
          $scope.portfoliosData = data;
          $scope.buildFilteredPortfolios();
          $scope.gridOpts.dataSource.read();

          if (parsedOptions) {
            $scope.gridOpts.dataSource.sort(parsedOptions.sort);
            _.forEach(parsedOptions.filters.filters, function (eachFilterEntity) {
              if (eachFilterEntity.field && (eachFilterEntity.field === "startDate" || eachFilterEntity.field === "endDate")) {
                eachFilterEntity.value = new Date(eachFilterEntity.value);
              }
            });
            $scope.gridOpts.dataSource.filter(parsedOptions.filters);
          }

          if (pageToGo && pageToGo != $scope.gridOpts.dataSource.page()) {
            // on delete if the user was on a page that is not the first one
            // than redirect the user to the same page
            $scope.gridOpts.dataSource.page(pageToGo);
          }

          SpinnerService.stop();
        });
    }

    function initGrid() {
      $scope.gridOpts = {
        columns: [
        {
          field: "portfolioID",
          title: "PortfolioID",
          hidden: "hidden"
        },
        {
          field: "companyID",
          title: "CompanyID",
          hidden: "hidden"
        },
        {
          field: "subscriptionID",
          title: "SubscriptionID",
          hidden: "hidden"
        },
        {
          field: "companyName",
          title: "Company",
          template: '<a ui-sref="#= \'admin.subscriptiondetails({ id: \' + subscriptionID + \', companyId: \' + companyID + \' })\' #">#=companyName#</a>',
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        },
        {
          field: "startDate",
          title: "Start Date",
          format: "{0:MM/dd/yyyy}",
          filterable: {
            cell: {
              showOperators: false,
              operator: "gte"
            }
          }
        },
        {
          field: "endDate",
          title: "End Date",
          format: "{0:MM/dd/yyyy}",
          filterable: {
            cell: {
              showOperators: false,
              operator: "lte"
            }
          }
        }, {
          field: "accountStatus",
          title: "Account Status",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        },{
          field: "portfolioName",
          title: "Portfolio Name",
          template: '<a ui-sref="#= \'admin.portfolios.details({ id: \' + portfolioID + \', subscriptionId: \' + subscriptionID + \' })\' #">#=portfolioName#</a>',
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        },
        {
          field: "propertyCount",
          title: "No. of Properties",
          filterable: {
            cell: {
              showOperators: false
            }
          }
        },
        {
          field: "portfolioType",
          title: "Portfolio Type",
          filterable: {
            cell: {
              showOperators: false
            }
          }
        },
        {
          field: "ownerFullName",
          title: "Portfolio Admin",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        },
        {
          field: "modifiedByAndModifiedDate",
          title: "Last Modified By",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        },{
          title: "Delete",
          filterable: false,
          template: "<a class='manageDelete' href='' ng-hide='#= portfolioTypeID == 5 #' type='button' ng-click='deletePortfolioModal(#= portfolioID #, \"#= portfolioName.replace(/'/g, ' ') #\")'></a>",
          attributes: {
            'style': 'text-align: center;'
          }
        }],
        dataSource: new kendo.data.DataSource({
          transport: {
            type: "json",
            read: function (opts) {
              opts.success($scope.filteredPortfoliosData);
            }
          },
          pageSize: 25,
          error: function (err) {
            console.log('error - ' + err);
          },
          schema: {
            model: {
              fields: {
                'portfolioID': { type: 'number' },
                'companyID': { type: 'number' },
                'subscriptionID': { type: 'number' },
                'portfolioName': { type: 'string' },
                'propertyCount': { type: 'number' },
                'portfolioTypeID': { type: 'string' },
                'ownerFullName': { type: 'string' },
                'modifiedByFullName': { type: 'string' },
                'startDate': { type: 'date' },
                'endDate': { type: 'date' },
                'companyName': { type: 'string' },
                'dateModified': { type: 'date' },
                'modifiedByAndModifiedDate' : { type: 'string' }
              }
            },
            parse: function(response) {
              for (var i = 0; i < response.length; i++) {
                var p = response[i];
                if (p.modifiedByFullName && p.dateModified) {
                  p.modifiedByAndModifiedDate = p.modifiedByFullName + " "
                    + $filter('date')(p.dateModified, 'MM/dd/yyyy') + " at "
                    + $filter('date')(p.dateModified, 'hh:mm a');
                }
              }
              return response;
            }
          },
          sort: [{ field: "portfolioType", dir: "asc" }, { field: "companyName", dir: "asc" }],
          serverPaging: false,
          serverFiltering: false,
          serverSorting: false
        }),
        dataBinding: gridDataBinding,
        filterable: {
          mode: "row"
        },
        scrollable: false,
        sortable: {
          mode: 'multiple',
          allowUnsort: true
        },
        pageable: true,
        resetFilters: function() {
          this.dataSource.filter({});
          this.dataSource.sort([{ field: "portfolioType", dir: "asc" }, { field: "companyName", dir: "asc" }]);
        }
      };
    };

    initGrid();
    getPortfolios(true);

    function gridDataBinding(e) {
      Nic.kendoGrid.filtersOnTop('grid');

      pageInfoToSave.value.showExpired = $scope.showExpiredPortfolios;
      pageInfoToSave.value.showDeleted = $scope.showDeletedPortfolios;
      pageInfoToSave.value.showCustom = $scope.showCustomPortfolios;

      var currSortVal = $scope.gridOpts.dataSource.sort();
      var currSort = currSortVal ? currSortVal : [];
      pageInfoToSave.value.sort = currSort;

      var currFilterObj = $scope.gridOpts.dataSource.filter() || {};
      pageInfoToSave.value.filters = currFilterObj;

      sessionStorage[pageInfoToSave.key] = JSON.stringify(pageInfoToSave.value);
    }
  }
}());
