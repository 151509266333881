app.directive('activity', ['$window', '$document', function ($window, $document) {
  return {
    scope: {
      activity: '=onLoadCallback'
    },
    templateUrl: '/app/partials/activity.html',
    link: function (scope, element) {
      function showTooltip() {
        // Get the anchor element because that's the element whose height changes.
        if (scope.activity)
          scope.activity.showToolTip = element[0].firstElementChild.firstElementChild.offsetHeight > 50;
      }

      // Get the RecentActivity Main Container Div.
      angular.element(element[0].parentElement.parentElement).bind('mouseenter', showTooltip);
      angular.element($window).bind('resize', showTooltip);
    }
  };
}]);
