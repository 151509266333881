(function () {
  "use strict";

  app.factory("SubscriptionHistoryService", SubscriptionHistoryService);

  SubscriptionHistoryService.$inject = ["$http", "$q", "apiSettings", "FileDownloadService"];
  
  function SubscriptionHistoryService($http, $q, apiSettings, FileDownloadService) {
    return {
      getSubscriptionHistory: getSubscriptionHistory,
      getSubscriptionRenewalDate: getSubscriptionRenewalDate,
      generateSubscriptionHistoryExcel: generateSubscriptionHistoryExcel,
      getMetroBankHistory: getMetroBankHistory
    }
  
    function getSubscriptionHistory(companyId) {
      var deferred = $q.defer();
      var uri = apiSettings.url +"/subscriptions/{companyId}/history"
        .replace("{companyId}", companyId);

      $http.get(uri)
        .then(function (rsp) {
          deferred.resolve(rsp.data);
        }, function (err) {
          deferred.reject(err);
        });
    
      return deferred.promise;
    };

    function getSubscriptionRenewalDate(companyId) {
      var deferred = $q.defer();
      var uri = apiSettings.url + "/subscriptions/{companyId}/renewal"
        .replace("{companyId}", companyId);
      
      $http.get(uri)
        .then(function (rsp) {
          deferred.resolve(rsp.data);
        }, function (err) {
          deferred.reject(err);
        });
      
      return deferred.promise;
    };

    function generateSubscriptionHistoryExcel(companyId, companyName, gridFiltersAndSorts) {
      var uri = apiSettings.url + "/subscription/exports/history/{companyId}"
        .replace('{companyId}', companyId);

      return $http.post(uri, gridFiltersAndSorts, {
          params: {
            companyName: companyName
          },
          responseType: 'arraybuffer'
        })
        .then(function(response) {
          if (response.data.byteLength === 0) {
            throw new Error('No Data');
          }
          FileDownloadService.saveReportFile(response);
        })
        .catch(function (error) {
          throw error;
        });
    };

    function getMetroBankHistory(companyId) {
      var deferred = $q.defer();
      var uri = apiSettings.url +"/subscriptions/{companyId}/metrobankhistory"
        .replace("{companyId}", companyId);

      $http.get(uri)
        .then(function (rsp) {
          deferred.resolve(rsp.data);
        }, function (err) {
          deferred.reject(err);
        });
    
      return deferred.promise;
    };
  }
})();
