(function() {
  "use strict";

  app.controller('PortfoliosManageController', PortfoliosManageController);
  PortfoliosManageController.$inject = ['$rootScope', '$scope', '$http', '$q', 'apiSettings', 'AuthService', 'SpinnerService'];

  function PortfoliosManageController($rootScope, $scope, $http, $q, apiSettings, AuthService, SpinnerService) {
    $rootScope.hideLeftNav = true;

    function getActiveSubscriptionPortfolios() {
      var defer = $q.defer();

      $http
        .get(apiSettings.url + "/UserPortfolio/GetActiveSubscriptionPortfolios")
        .then(function (rsp) {
          defer.resolve(rsp.data);
        }, function (err) {
          defer.reject(err);
        });
      
      return defer.promise;
    }

    var defaultPage = 20;
    $scope.gridOptPage = defaultPage;
    $scope.pageSizes = [{ value: defaultPage, text: defaultPage + " rows" }, { value: 40, text: "40 rows" }, { value: 60, text: "60 rows" }];
    $scope.resetGrid = function () {
      $scope.gridOpts.dataSource.filter([]);
      $scope.gridOptPage = defaultPage;
    }
    $scope.$watch('gridOptPage', function () {
      if ($scope.gridOpts.dataSource.pageSize() != $scope.gridOptPage)
        $scope.gridOpts.dataSource.pageSize($scope.gridOptPage);
    });

    $scope.delete = function () {
      alert('you clicked the delete button!');
    }

    $scope.copy = function () {
      alert('you clicked the copy button!');
    }

    SpinnerService.start();
    $scope.gridOpts = {
      columns: [
        {
          field: "id",
          title: "PortfolioID",
          hidden: "hidden"
        },
        {
          field: "subscriptionId",
          title: "SubscriptionId",
          hidden: "hidden"
        },
        {
          attributes: {'style': 'min-width: 51px;' },
          template: '<a  href="" class="manageCopy" ng-click="copy()"></a> <a ng-show="#= type.id == 4 && userIsOwner #" href="" class="manageDelete" ng-click="delete()"> </a>',
        },
        {
          field: "name",
          title: "Portfolio Name",
          template: '<img ng-show="#= type.id == 4 #" ng-src="assets/portfolio/subportfolio.png"> <a ng-class="{ \'appendTab\': #= type.id == 4 # }" ui-sref="#= \'portfolios.details.edit({ id: \' + id + \' })\' #">#= name #</a>',
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        },
        {
          field: "numberOfProperties",
          title: "No. of Properties",
          width: '100px',
          filterable: {
            cell: {
              showOperators: false
            }
          }
        },
        {
          field: "ownerFullName",
          title: "Created By",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        },
        {
          field: "modifiedFullName",
          title: "Last Modified By",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        },
        {
          field: "dateModified",
          format: "{0:MM/dd/yyyy}",
          title: "Last Modified On",
          width: '175px',
          filterable: {
            cell: {
              showOperators: false,
              operator: "equals"
            }
          }
        },
        {
          field: "compStatus",
          filterable: false,
          title: "Comp Status",
          template: "<img ng-src='assets/portfolio/#= compStatus == true ? 'good' : 'bad' #-comp.png'>"
        }
      ],
      dataSource: new kendo.data.DataSource({
        transport: {
          type: "json",
          read: function (opts) {
            Nic.kendoGrid.filtersOnTop('grid');
            getActiveSubscriptionPortfolios()
                .then(function (data) {
                  opts.success(data);
                  SpinnerService.stop();
                });
          }
        },
        error: function (err) {
          console.log('error - ' + err);
        },
        schema: {
          model: {
            fields: {
              'id': { type: 'number' },
              'subscriptionId': { type: 'number' },
              'name': { type: 'string' },
              'numberOfProperties': { type: 'number' },
              'type.id': { type: 'string' },
              'ownerFullName': { type: 'string' },
              'modifiedFullName': { type: 'string' },
              'dateModified': { type: 'date' },
              'userIsOwner': { type: 'boolean' },
              'compStatus': { type: 'boolean' }
            }
          },
          parse: function (response) {
            for (var i = 0; i < response.length; i++) {
              var p = response[i];
              var authId = AuthService.identity.contactId();
              p.userIsOwner = authId == p.ownerContactID || authId == p.masterPortfolioOwnerContactID;
              if (p.modifiedContact) {
                p.modifiedFullName = p.modifiedContact.firstName + " " + p.modifiedContact.lastName;
              }
              if (p.dateModified) {
                p.dateModified = new Date(p.dateModified);
              }
            }

            return response;
          }
        },
        pageSize: defaultPage,
        serverPaging: false,
        serverFiltering: false,
        serverSorting: false
      }),
      filterable: {
        mode: "row"
      },
      scrollable: false,
      pageable: true
    };
  }
}());