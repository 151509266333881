(function () {
  'use strict';

  app.controller('CompBuilderPeerGroupDetailsController', CompBuilderPeerGroupDetailsController);

  CompBuilderPeerGroupDetailsController.$inject = ['$scope', '$state', '$window', 'peerGroupDetails', 'hasLocalPropertySearchAccess',
    'radiusOptions', 'propertyTypes', 'segmentTypes', 'campusTypes', 'driveTimeOptions', 'pushpinImages', 'BingMapUtils', 'PinFactory',
    'CompBuilderService', 'GeoDataService', 'WalkScoreService', 'SpinnerService', 'BingGeoDataService', 'AuditService', 'knownEventConstant'];
  function CompBuilderPeerGroupDetailsController($scope, $state, $window, peerGroupDetails, hasLocalPropertySearchAccess,
    radiusOptions, propertyTypes, segmentTypes, campusTypes, driveTimeOptions, pushpinImages, BingMapUtils, PinFactory,
    CompBuilderService, GeoDataService, WalkScoreService, SpinnerService, BingGeoDataService, AuditService, knownEventConstant) {
    var MIN_PROPERTY_COUNT = 4;
    var MIN_OPERATOR_COUNT = 4;
    var vm = this;
    var bingMap;
    var metroAreaLocationCache; 
  
    // Data
    vm.peerGroup = peerGroupDetails.peerGroup;
    vm.searchCriteria = peerGroupDetails.searchCriteria;
    vm.aggregate = peerGroupDetails.aggregate;
    vm.compSet = compSetPinFactory(peerGroupDetails.compSet);
    vm.searchRangePolygon = searchRangePolygonFactory();
    vm.metroAreaPolygon = BingMapUtils.getPolygonOptions('metroAreaPolygon');
    vm.countiesPolygon = null;
    vm.owner = peerGroupDetails.ownerName;
  
    // Configuration / State
    vm.submitEnabled = false;
    vm.hasLocalPropertySearchAccess = hasLocalPropertySearchAccess;
    vm.compSetGrid = compSetGridOptionsFactory();
    vm.filterOptions = filterOptionsFactory();
    vm.mapOptions = mapOptionsFactory();
    vm.showMetroArea = false;
    vm.showCounties = false;
    vm.viewMode = peerGroupDetails.isOwner ? 'Edit' : 'View';

    // Functions
    vm.submit = submit;
    vm.isSubmitEnabled = isSubmitEnabled;
    vm.onMapReady = onMapReady;
    vm.toggleMetroAreaDisplay = toggleMetroAreaDisplay;
    vm.toggleCountiesDisplay = toggleCountiesDisplay;

    // Events
    $scope.$on('propertyDetailsInfoboxClick', function(event, data) {
      propertyInformationReportClick(data.sourceProperty.id);
    });

    function submit() {
      if (!vm.submitEnabled) { return; }

      SpinnerService.start();
      var updatePeerGroupModel = {
        subjectLocation: vm.peerGroup.peerGroupLocation,
        compSet: _.map(vm.compSet, function (comp){return {id: comp.id, included: comp.included}})
      };
      CompBuilderService
        .updatePeerGroup(vm.peerGroup.guid, updatePeerGroupModel)
        .catch(function (err) {
          console.error(err);
        })
        .finally(function () {
          SpinnerService.stop();
          backToCompBuilder();
        }); 
    }

    function backToCompBuilder() {
      $state.go('compBuilder', {}, { reload: true });
    }

    function isSubmitEnabled() {
      vm.submitEnabled = false;
      // check if peer group name populated otherwise short circuit
      if(!vm.peerGroup || !vm.peerGroup.peerGroupName || vm.peerGroup.peerGroupName.toString().trim().length < 1) { 
        return;
      }
      
      var includedProperties = _.filter(vm.compSet, function(o){ return o.included });
      var uniqueOperators = _.uniqBy(includedProperties, 'operator');
      if(includedProperties.length >= MIN_PROPERTY_COUNT && uniqueOperators.length >= MIN_OPERATOR_COUNT) {
        vm.submitEnabled = true;
      }
    }

    function propertyInformationReportClick(propertyId) {
      $window.open(
        $state.href('propertyDetails', {
          id: propertyId
        }, { absolute: true }), '_blank');
    }

    function onMapReady(map) {
      bingMap = map;
      BingGeoDataService.setupSdsManager();
      BingMapUtils.centerMapToLocations(map, vm.searchRangePolygon.locations);
    }

    function toggleMetroAreaDisplay() {
      if (vm.showMetroArea) {
        AuditService.logEvent(knownEventConstant.subjectLocationShowMetroArea.id,
                              knownEventConstant.subjectLocationShowMetroArea.message + ' on ' + vm.viewMode + ' Subject Location page');

        if (metroAreaLocationCache) {
          vm.metroAreaPolygon.locations = _.cloneDeep(metroAreaLocationCache);
        } else {
          // pull distinct cbsas in compset
          var cbsas = _.uniq(vm.compSet.map(function (comp) { return comp.cbsaCode; }));
          return GeoDataService.getMultipleCBSAGeoData(cbsas)
            .then(function (polygons) {
              if (polygons && polygons.length > 0) {
                vm.metroAreaPolygon.locations = polygons;
                metroAreaLocationCache = _.cloneDeep(polygons);
              }
            });
        }
      } else {
        vm.metroAreaPolygon.locations = [];
      }
    }

    function toggleCountiesDisplay() {
      if (vm.showCounties && BingMapUtils.getCurrentZoom(bingMap) >= 9) {
        AuditService.logEvent(knownEventConstant.subjectLocationShowCounties.id,
          knownEventConstant.subjectLocationShowCounties.message + ' on ' + vm.viewMode + ' Subject Location page');

        SpinnerService.start();
        return BingGeoDataService.getAllCountiesBoundariesInBbox(bingMap, true)
          .then(function (polygons) {
            vm.countiesPolygon = polygons;
            SpinnerService.stop();
          })
          .catch(function (err) {
            vm.countiesPolygon = null;
            console.error(err);
            SpinnerService.stop();
          });
      } else {
        vm.countiesPolygon = null;
      }
    }

    function compSetPinFactory(compSet) {
      WalkScoreService.getWalkScoreForProperties(compSet)
        .then(function (compSet) {
          var pins = compSet.map(function (comp, index) { return PinFactory.buildPin(comp, index, $scope); });
          vm.compSet = pins;
          if(vm.compSetGrid && vm.compSetGrid.dataSource) {
            vm.compSetGrid.dataSource.read();
          }
        });
      return [];
    }

    function searchRangePolygonFactory() {
      if (vm.searchCriteria.tradeArea.type == 'radius') {
        return BingMapUtils.getPolygonOptions('radius', BingMapUtils.buildCircle(vm.peerGroup.peerGroupAddress.latitude, vm.peerGroup.peerGroupAddress.longitude, vm.searchCriteria.tradeArea.radius));
      }
      if (vm.searchCriteria.tradeArea.type == 'driveTime') {
        var colors = GeoDataService.getColors(vm.searchCriteria.tradeArea.driveTime.trafficFlow);
        var options =  BingMapUtils.getPolygonOptions('driveTime', vm.searchCriteria.tradeArea.driveTime.driveTimeLocs);
        options.strokeColor = colors.strokeColor;
        options.fillColor = colors.fillColor;
        return options;
      }
    }

    function filterOptionsFactory() {
      return {
        radius: radiusOptions,
        driveTime: driveTimeOptions,
        segmentType: segmentTypes,
        propertyType: _.reject(propertyTypes, ['description', 'Seniors Housing']),
        campusType: _.reject(campusTypes, ['description', 'CCRC / LPC']),
        includeCCRC: [{ id: 0, description: 'Exclude CCRC/LPC' }, { id: 1, description: 'Include CCRC/LPC' }]
      };
    }

    function mapOptionsFactory() {
      var mapOptions = BingMapUtils.getDefaultViewOptions(null, vm.peerGroup.peerGroupAddress.latitude, vm.peerGroup.peerGroupAddress.longitude);
      mapOptions.options.disableScrollWheelZoom = true;
      return mapOptions;
    }

    function compSetGridOptionsFactory() {
      return {
        columns: [
          {
            title: 'Include',
            field: 'included',
            template: '<input type=checkbox ng-disabled="vm.viewMode == \'View\'" ng-model="vm.compSet[vm.compSetGrid.dataSource.indexOf(dataItem)].included" ng-change="vm.isSubmitEnabled()"/>',
            width: '3%',
            filterable: false,
          },
          {
            title: 'Map Pin No.',
            field: 'pinNumber',
            headerTemplate: "<span>Map <br/> Pin No.",
            template: '{{::dataItem.pinNumber}}',
            width: '5%',
            filterable: {
              cell: {
                showOperators: false,
                operator: 'eq'
              }
            },
          },
          {
            title: 'Property Name',
            field: 'name',
            template: '{{::dataItem.name}}',
            filterable: {
              cell: {
                showOperators: false,
                operator: 'contains'
              }
            }
          },
          {
            title: 'Property Type',
            field: 'typeDescription',
            template: '{{::dataItem.typeDescription}}',
            filterable: {
              cell: {
                showOperators: false,
                operator: 'contains'
              }
            }
          },
          {
            title: 'Address',
            field: 'addressLine1',
            template: '{{::dataItem.addressLine1}}',
            filterable: {
              cell: {
                showOperators: false,
                operator: 'contains'
              }
            }
          },
          {
            title: 'City',
            field: 'city',
            template: '{{::dataItem.city}}',
            filterable: {
              cell: {
                showOperators: false,
                operator: 'contains'
              }
            }
          },
          {
            title: 'State',
            field: 'stateProvinceCode',
            width: '5%',
            template: '{{::dataItem.stateProvinceCode}}',
            filterable: {
              cell: {
                showOperators: false,
                operator: 'contains'
              }
            }
          },
          {
            title: 'ZIP',
            field: 'zipCode',
            template: '{{::dataItem.zipCode}}',
            width: '3%',
            filterable: {
              cell: {
                showOperators: false,
                operator: 'contains'
              }
            }
          },
          {
            title: 'Operator',
            field: 'operator',
            template: '{{::dataItem.operator}}',
            filterable: {
              cell: {
                showOperators: false,
                operator: 'contains'
              }
            }
          },
          {
            title: 'Distance',
            field: 'distance',
            width: '7%',
            template: '{{::dataItem.distance}}',
            filterable: {
              cell: {
                showOperators: false,
                operator: 'eq'
              }
            }
          },
        ],
        dataSource: new kendo.data.DataSource({
          data: vm.compSet,
          transport: {
            read: function (options) {
              options.success(vm.compSet);
            }
          },
          schema: {
            model: {
              id: 'pinNumber',
              fields: {
                'included': { type: 'boolean' },
                'pinNumber': { type: 'number' },
                'name': { type: 'string' },
                'typeDescription': { type: 'string' },
                'addressLine1': { type: 'string' },
                'city': { type: 'string' },
                'stateProvinceCode': { type: 'string' },
                'zipCode': { type: 'string' },
                'operator': { type: 'string' },
                'distance': { type: 'number' }
              },
              
            }
          },
          sort: { field: 'name', dir: 'asc' },
          pageSize: 10
        }),
        dataBinding: function (e) {
          Nic.kendoGrid.filtersOnTop('compSetGrid');
        },
        filterable: {
          mode: 'row'
        },
        scrollable: false,
        sortable: {
          mode: 'single',
          allowUnsort: false
        },
        pageable: true,
        resetFilters: function() {
          this.dataSource.filter([]);
          this.dataSource.sort({ field: 'name', dir: 'asc' })
        }
      }
    }
  }
})();
