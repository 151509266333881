(function() {
  "use strict";
  app.directive('webinarInfo', WebinarInfoDirective);
  function WebinarInfoDirective() {
    return {
      templateUrl: '/app/partials/webinarInfo.html',
      controllerAs: 'webinarInfo',
      controller: WebinarInfoController,
      scope: {},
    };
  }

  WebinarInfoController.$inject = ['$scope','WordpressService','AuditService','knownEventConstant'];
  function WebinarInfoController($scope, WordpressService, AuditService, knownEventConstant) {
    var vm = this;
    vm.webinar = [];

    vm.init = function() {
      return WordpressService.getWebinarInfo().then(function(data) {
        vm.webinar = data[0];
        return vm.webinar;
      });
    }

    vm.logWebinarInfoUserEvent = function() {
      AuditService.logEvent(knownEventConstant.navImageClick.id, knownEventConstant.navImageClick.message);
    }

    vm.imageChooser = function(image, sizes) {
      return WordpressService.imageChooser(image, sizes);
    }

    vm.init();
  }
})();
