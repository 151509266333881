(function () {
  "use strict";
  
  app.controller("StepAddAggregateSearchCriteria", StepAddAggregateSearchCriteria);
  
  StepAddAggregateSearchCriteria.$inject = ['$scope', '$q', 'FilterMapper', 'propertyTypes', 'segmentTypes', 'campusTypes'];
  function StepAddAggregateSearchCriteria($scope, $q, FilterMapper, propertyTypes, segmentTypes, campusTypes) {
    $scope.searchCriteria = $scope.$parent.submissionModel.searchParameters;

    // Intermediate mapping between the views' array of checkboxes, and the save model
    $scope.selectedCheckboxOptions = {
      propertyType: [],
      campusType: [],
      segmentType: []
    };

    $scope.filterOptions = {
      segmentType: segmentTypes,
      propertyType: _.reject(propertyTypes, ['description', 'Seniors Housing']),
      campusType: _.reject(campusTypes, ['description', 'CCRC / LPC']),
      includeCCRC: [{ id: 0, description: 'Exclude CCRC/LPC' }, { id: 1, description: 'Include CCRC/LPC' }]
    };
    
    // Functions
    $scope.$onInit = $onInit;
    $scope.propertyTypeChanged = propertyTypeChanged;
    $scope.campusTypeChanged = campusTypeChanged;
    $scope.segmentTypeChanged = segmentTypeChanged;
    $scope.$parent.nextStepAction = function () { return $q.resolve(); } // No validation on this page
    $scope.$parent.previousStepAction = function () { return $q.resolve(); } // No validation on this page

    $scope.$onInit();

    function $onInit() {
      // Preselect options based on search criteria from base or loaded from sessionStorage
      // Revert the filtermapper function to convert the view model to the save model
      $scope.selectedCheckboxOptions.propertyType = rebuildSelectionsFromSubmissionModel($scope.searchCriteria.propertyTypeIds);
      $scope.selectedCheckboxOptions.campusType = rebuildSelectionsFromSubmissionModel($scope.searchCriteria.campusTypeIds);
      $scope.selectedCheckboxOptions.segmentType = rebuildSelectionsFromSubmissionModel($scope.searchCriteria.segmentTypeIds);
    }

    function propertyTypeChanged() {
      $scope.searchCriteria.propertyTypeIds =
        FilterMapper.buildObjectListFromCheckBoxes($scope.filterOptions.propertyType, $scope.selectedCheckboxOptions.propertyType);
    };

    function campusTypeChanged() {
      $scope.searchCriteria.campusTypeIds =
        FilterMapper.buildObjectListFromCheckBoxes($scope.filterOptions.campusType, $scope.selectedCheckboxOptions.campusType);
    };

    function segmentTypeChanged() {
      $scope.searchCriteria.segmentTypeIds =
        FilterMapper.buildObjectListFromCheckBoxes($scope.filterOptions.segmentType, $scope.selectedCheckboxOptions.segmentType, 'code');
    };

    function rebuildSelectionsFromSubmissionModel(submissionModelSource) {
      if (Array.isArray(submissionModelSource)) {
        return submissionModelSource.reduce(function (acc, item) {
          acc[item] = true;
          return acc;
        }, {});
      }

      return null;
    }
  }
})();
