(function() {
  "use strict";
  app.directive('toolTipHelper', toolTipHelper);

  toolTipHelper.$inject = ['$timeout'];

  function toolTipHelper($timeout) {
    function wireInToolTip(element) {
      var chart = $(element).data("kendoChart");
      if(!chart) {
        //loop untill chart ready
        $timeout(function() {
          wireInToolTip(element)
        }, 50);
        return; //chart not ready
      }

      chart.setOptions({
        tooltip: {
          visible: true,
          shared: true,
          background: 'white',
          color: 'black',
          template: function (e) {
            var color = e.series.color;
            if (e.series.color instanceof Function) {
              color = e.series.color(e);
            }

            var value = e.value;
            if (value.from && value.to) {

              var fromValue = tryToFormatValue(e, value.from);
              var toValue = tryToFormatValue(e, value.to);

              return '<span style="color:' + color + '">' + fromValue + ' - ' + toValue + '</span>';
            }
            else {
              return '<span style="color:' + color + '">' + tryToFormatValue(e, value) + '</span>';
            }
          }
        }
      });
    }

    function tryToFormatValue(chart, value) {
      var numValue;
      if (value) {
        numValue = parseFloat(value)
      } else if (value === 0) {
        numValue = 0;
      } else { //undefined or null
        numValue = null;
      }


      if (chart.series.formatForToolTip) {
        value = kendo.toString(numValue, chart.formatForToolTip);
      }
      else {
        if (chart.series.axis == "percentageAxis") {
          value = kendo.toString(numValue, "p1");
        } else if (chart.series.axis == "currencyAxis") {
          value = kendo.toString(numValue, "c0");
        } else {
          value = kendo.toString(numValue, "n0");
        }
      }

      return value;
    }

    function link(scope, element, attrs) {
      wireInToolTip(element);
    }

    return {
      link: link
    };
  }
})();