(function() {
	"use strict";

	app.controller("AdminEditCompsController", AdminEditCompsController);
	AdminEditCompsController.$inject = ['$scope', '$state', '$q', 'portfolio', 'PopupHandlerService', '$uibModal', 'SpinnerService', 'AdminPortfolioService', 'AdminEditCompsService', 'PropertyDetailsService'];

	function AdminEditCompsController($scope, $state, $q, portfolio, PopupHandlerService, $uibModal, SpinnerService, AdminPortfolioService, AdminEditCompsService, PropertyDetailsService) {
		$scope.portfolio = portfolio;
		$scope.addCommaComps = addCommaComps;
		$scope.removeCompModal = removeCompModal;
    $scope.compDetails = [];
		
		function init() {
			PropertyDetailsService.getPropertyDetails($state.params.detailsId).then(function success(property) {
				$scope.property = property;
        initGrid();
        getGridData();  
			})
		}

		function addCommaComps() {
			$scope.commaError = false;
			$scope.addError = false;
			$scope.addErrorMessage = {
				notFound: {
					message: "Property ID given does not exist",
					ids: null
				},
				duplicate: {
					message: "Property ID already exists in Comp Set",
					ids: null
				}
			};
			SpinnerService.start();
			AdminEditCompsService.addComps($scope.portfolio.id, $scope.property.id, $scope.commaDelimitedComps).then(
				function(data) {
					if(data.errors.length > 0){
						$scope.addError = true;
						var duplicateItems = _.remove(data.errors, function(o){
							return o.errorMessage == "Property ID already exists in Comp Set";
						});
						var duplicateItemIds = _.map(duplicateItems, 'propertyIds');
						$scope.addErrorMessage.duplicate.ids = arrayToString(duplicateItemIds);

						var notFoundItems = _.remove(data.errors, function(o){
							return o.errorMessage == "Property ID given does not exist";
						});
						var notFoundItemIds = _.map(notFoundItems, 'propertyIds');
						$scope.addErrorMessage.notFound.ids = arrayToString(notFoundItemIds);						
					}
					getGridData(); //Have to update the grid
					SpinnerService.start();
					AdminPortfolioService.getPortfolioData($scope.portfolio.id)
            .then(function (data) {
              $scope.portfolio = data;
            })
            .finally(function () {
              SpinnerService.stop();
            })
        }, function (error) {
          if (!displayError(error)) {
              $scope.commaError = true;
          }
        }).finally(function () {
          SpinnerService.stop();
        });
    }
    
    function displayError(error) {
      if (!error) {
        return false;
      }
      if (angular.isString(error.data)) { //if not string something bad happened
        PopupHandlerService.openSimplePopup(error.data);
        return true;
      }
      if (error.status) {
        PopupHandlerService.openSimplePopup("unknown error occured code:" + error.status);
        return true;
      }
      return false;
    }
    
    function removeCompModal(id) {
      $scope.compToBeRemoved = _.find($scope.compDetails, function (property) {
        return property.propertyID == id;
      });
      $uibModal.open({
        animation: true,
        templateUrl: 'app/partials/AdminEditCompsRemoveModal.html',
        size: 10,
        scope: $scope
      }).result.then(removeComp);
    }
    
    function removeComp() {
      SpinnerService.start();
      AdminEditCompsService.removeComp($scope.portfolio.id, $scope.property.id, $scope.compToBeRemoved.propertyID)
        .then(function (data) {
          getGridData();
          SpinnerService.start();
          AdminPortfolioService.getPortfolioData($scope.portfolio.id)
            .then(function (data) {
              $scope.portfolio = data;
            })
            .finally(function () {
              SpinnerService.stop();
            });
        }, function (error) {
          PopupHandlerService.openSimplePopup("Delete failed on comp: " + $scope.compToBeRemoved.propertyName + ". Please contact an administrator.");
        })
        .finally(function () {
          SpinnerService.stop();
        });
    }
    
    function arrayToString(string) {
      return string.join(", ");
    };

    function getGridData() {
      SpinnerService.start();
      var promiseGetCompDetails = AdminEditCompsService.getCompDetails($scope.portfolio.id, $scope.property.id);
      $q.all([promiseGetCompDetails]).then(function(responses) {
        $scope.numberProperties = responses[0].length;
        $scope.compDetails = responses[0];
      }, function failure(responses) {
        $scope.numberProperties = 0;
        $scope.compDetails = [];
      }).finally(function() {
        $scope.compSetPropertiesGridOptions.dataSource.read();
        SpinnerService.stop();
      })
    }

    function initGrid() {
      $scope.compSetPropertiesGridOptions = {
        columns: [{
          field: "propertyID",
          title: "Property ID",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "propertyName",
          title: "Property Name",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "propertyTypeDesc",
          title: "Property Type",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "city",
          title: "City",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "stateProvinceCD",
          title: "State",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "zipCode",
          title: "Zip",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "cbsaName",
          title: "Metro",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "buildingAgeInYears",
          title: "Age",
          format: "{0:n0} yrs",
          filterable: {
            cell: {
              showOperators: false,
              operator: "equals"
            }
          }
        }, {
          field: "campusTypeDesc",
          title: "Campus Type",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "profitStatus",
          title: "Profit Status",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "operator",
          title: "Operator",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "owner",
          title: "Owner",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
            field: "distancefromCenter",
          title: "Distance",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          template: '<a class="manageDelete" ng-click="removeCompModal(#= propertyID #)"> </a>',
          title: "Delete",
          filterable: false,
          attributes: {
            'style': 'text-align: center;'
          }
        }],
        dataSource: new kendo.data.DataSource({
          transport: {
            read: function (opts) {
              opts.success($scope.compDetails);
            }
          },
          error: function (err) {
            console.log('Error - ' + err);
          },
          schema: {
            model: {
              fields: {
                'propertyID': { type: 'number' },
                'propertyName': { type: 'string' },
                'propertyTypeDesc': { type: 'string' },
                'city': { type: 'string' },
                'stateProvinceCD': { type: 'string' },
                'zipCode': { type: 'string' },
                'cbsaName': { type: 'string' },
                'buildingAgeInYears': { type: 'number' },
                'campusTypeDesc': { type: 'string' },
                'profitStatus': { type: 'string' },
                'operator': { type: 'string' },
                'owner': { type: 'string' },
                'distancefromCenter': { type: 'number' },
              }
            }
          },
          pageSize: 20,
          serverPaging: false,
          serverFiltering: false,
          serverSorting: false,
          sort: { field: "propertyName", dir: "asc" }
        }),
        dataBinding: function(e) {
          Nic.kendoGrid.filtersOnTop('compSetPropertiesGrid');
        },
        filterable: {
          mode: "row"
        },
        scrollable: false,
        sortable: {
          mode: "single",
          allowUnsort: false
        },
        pageable: true,
        resetFilters: function() {
          this.dataSource.filter({});
          this.dataSource.sort({ field: "propertyName", dir: "asc" });
        }
      }
    }
    init();
  };
})();