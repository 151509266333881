(function () {
  "use strict";

  app.factory("SubscriptionNotesService", SubscriptionNotesService);
  
  SubscriptionNotesService.$inject = ['$http', '$q', 'apiSettings', 'DateOffsetUtils'];
  
  function SubscriptionNotesService($http, $q, apiSettings, DateOffsetUtils) {
  	return {
      getNotes: getNotes,
      saveNote: saveNote,
      exportToExcel: exportToExcel
    };

    function getNotes(companyId) {
    	var defer = $q.defer();
      var uri = apiSettings.url + "/subscriptions/" + companyId + "/notes";
      $http.get(uri)
        .then(function (rsp) {
          defer.resolve(rsp.data);
        }, function (err) {
          defer.reject(err);
        });

      return defer.promise;
    }

    function saveNote(companyId, subscriptionId, dataToSave, noteId) {
    	var defer = $q.defer();
    	var note = {
    	    text: dataToSave,
    	    subscriptionId: subscriptionId,
          noteId: noteId
    	};
      var uri = apiSettings.url + "/subscriptions/" + companyId + "/notes";
      $http.post(uri, note)
        .then(function(rsp) {
          defer.resolve(rsp.data);
        }, function(err) {
          defer.reject(err);
        });

      return defer.promise;
    }

    function exportToExcel(companyId, filtersAndSorts, title) {
      var defer = $q.defer();
      var data = {
        title: title,
        exportedOn: new Date().toString(),
        filtersAndSorts: filtersAndSorts
      };
      var uri = apiSettings.url + "/subscription/exports/" + companyId + "/notes/?" +
        $.param({
            timeoffset: DateOffsetUtils.getTimezoneOffset(new Date())
        });
      $http.post(uri,data,{
        responseType: 'arraybuffer'
      })
      .then(function success(rsp) {
        if(rsp.data.length === 0) {
          defer.reject();
        } else {
          defer.resolve(rsp.data);
        }
      }, function error(err) {
        defer.reject(err);
      });
      return defer.promise;
    }
  }
})();
