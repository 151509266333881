(function() {
  'use strict';

  app.controller('LoginController', LoginController);

  LoginController.$inject = ['$cookies', '$interval', '$rootScope', '$scope', '$state', 'AuditService', 'AuthError', 'AuthService', 'AdminService', 'SpinnerService'];

  function LoginController($cookies, $interval, $rootScope, $scope, $state, auditService, AuthError, authService, AdminService, SpinnerService) {

    var nic_user_cookie = 'NIC_Username';

    $scope.shouldBeFocused = true;
    $scope.reLogInInfo = {
      isTrying: false,
      lastActiveLoginDateTime: null,
      lastActiveSessionId: null
    };
    $scope.login = login;
    $scope.forceLogin = forceLogin;

    var hasCookie = $cookies.get(nic_user_cookie) != undefined;
    $scope.loginData = {
      userName: $cookies.get(nic_user_cookie) || "",
      password: "",
      rememberMe: hasCookie
    };

    $scope.rememberMeClick = function() {
      if(!$scope.loginData.rememberMe)
        $cookies.remove(nic_user_cookie);
    };
    
    $scope.resetData = {
      email: "",
      returnHomeUrl: $state.href('login.home', {}, {absolute: true})
    };
    if($state.current.name == "login.maxLoginAttempts" && !authService.identity.isAuth() && authService.identity.userName()) {
      $scope.resetData.email = authService.identity.userName();
      authService.setAttemptedUserName("");
    }
    $scope.message = "";
    $scope.displayConfirmationMessage = false;

    // TODO: Need to initialize based on cookies, perhaps make a call to /account/me (or equivalent).
    if(authService.identity.isAuth()) {
      if ($scope.redirectState) {
        $state.go($scope.redirectState.name, $scope.redirectParams);
      }
      else
        $state.go("home");
    }

    function login() {
      if (!$scope.loginData.userName || !$scope.loginData.password) {
        // dont allow to proceed until some information provided in the textbox
        return;
      }

      var loginData = {
        userName: $scope.loginData.userName,
        password: $scope.loginData.password
      };
      SpinnerService.start();
      authService
        .login(loginData)
        .then(function (rsp) {
          SpinnerService.stop();
          if($scope.loginData.rememberMe) {
            var expDate = new Date();
            expDate.setFullYear(2050, 1, 1);
            $cookies.put(nic_user_cookie, $scope.loginData.userName, { expires: expDate });
          }

          var loginEvent = 3;
          auditService.logEvent(loginEvent, "Logged In with Current Subscription");
          if($scope.redirectState) {
            $state.go($scope.redirectState.name, $scope.redirectParams);
          } else {
            $state.go("home");
          }
        }, function (err) {
          SpinnerService.stop();
          $scope.message = "";
          switch (err.errorCode) {
            case AuthError.Unknown:
              $scope.message = "Failed Login Attempt.  The email address and password you entered do not match.  Please try again.";
              break;
            case AuthError.Invalid:
              var msg = "Failed Login Attempt :failedAttempt of :maxFailedAttempts.  The email address and password you entered do not match.  Please try again.";
              if(err.failedAttempts == err.maxLoginAttempts - 1) {
                msg = "Failed Login Attempt :failedAttempt of :maxFailedAttempts.  You have one more incorrect login attempt remaining before your account is locked for security.";
              }
              $scope.message = msg.replace(':failedAttempt', err.failedAttempts).replace(':maxFailedAttempts', err.maxLoginAttempts);
              if($scope.message == msg.replace(':failedAttempt', 'undefined').replace(':maxFailedAttempts', 'undefined')) {
                $scope.message = "We are currently experiencing intermittent system outages. Please check back soon.  We appreciate your patience.";
              }
              break;
            case AuthError.DuplicateLogin:
              $scope.reLogInInfo.isTrying = true;
              $scope.reLogInInfo.lastActiveLoginDateTime = err.lastActiveLogIn;
              $scope.reLogInInfo.lastActiveSessionId = err.lastActiveSessionId;
              break;
            case AuthError.Locked:
              authService.setAttemptedUserName($scope.loginData.userName);
              $state.go("login.maxLoginAttempts");
              break;
            case AuthError.InvalidSubscription:
              $state.go("login.invalidSubscription");
              break;
            case AuthError.ExpiredSubscription:
              $state.go("login.expiredSubscription");
              break;
            case AuthError.isSubTerminated:
              $state.go("login.terminatedSubscription");
              break;
          }
        });
    };

    function forceLogin() {
      authService
        .forceLogin($scope.loginData.userName)
        .then(function () {
          login();
        });
    }

    $scope.sendLockedAccountEmail = function() {
      $scope.message = "";
      if($scope.resetData.email) {
        SpinnerService.start();
        authService
          .lockedAccountEmail($scope.resetData)
          .then(function (rsp) {
            $state.go("login.resetPasswordConfirmation");
          }, function (err) {
            SpinnerService.stop();
            $scope.message = "The email address entered was not found in our system.  Please enter a valid email address.";
          });
      } else {
        $scope.message = "Please enter a valid email address.";
      }
    };

    $scope.sendResetEmail = function() {
      $scope.message = "";
      if($scope.resetData.email) {
        SpinnerService.start();
        authService
          .resetPassEmail($scope.resetData)
          .then(function (rsp) {
            SpinnerService.stop();
            if(!rsp.data.isActive) {
              if (rsp.data.hasAllExpiredSubscriptions) {
                $scope.message = "Please note that your NIC MAP subscription has expired. To reinstate service, please email NIC MAP Client Services or call 410-267-0504. We are available Monday - Friday from 9:00 AM to 5:00 PM ET to assist you.";
              } else {
                $scope.message = "The email address you entered was not found. For access to the NIC MAP Client portal, please contact NIC MAP Client Services by calling 410-267-0504. We are available Monday - Friday from 9:00 AM to 5:00 PM ET to assist you.";
              }
            } else {
              $scope.displayConfirmationMessage = true;
            }
          }, function (err) {
            SpinnerService.stop();
            if (err.data && err.data.isUnderMaintenance) {
              $rootScope.$broadcast('siteOffLine');
            } else {
              $scope.message = "The email address entered was not found in our system.  Please enter a valid email address.";
            }
          });
      } else {
        $scope.message = "Please enter a valid email address.";
      }
    };
  }

})();
