(function() {
  "use strict";

  app.controller("ChangePasswordController", ChangePasswordController);

  ChangePasswordController.$inject = ['$scope', '$state', '$rootScope', 'AuthService', 'SpinnerService', 'ChangePassError'];
  
  function ChangePasswordController($scope, $state, $rootScope, authService, SpinnerService, ChangePassError) {
    $rootScope.hideLeftNav = true;
    $scope.messages = [];
    $scope.changePassData = {
      currentpassword: "",
      newpassword: "",
      confirmpassword: ""
    };
  
    $scope.changePasswordClick = function () {
      SpinnerService.start();
      authService
        .changePassword($scope.changePassData)
        .then(function (rsp) {
          authService
            .reAuthenticate()
            .then(function (reAuthResp) {
              SpinnerService.stop();
              if($scope.redirectState) {
                $state.go($scope.redirectState.name, $scope.redirectParams);
              } else {
                $state.go("changePasswordConfirmation");
              }
            }, function (reAuthErr) {
              SpinnerService.stop();
            });
        }, function (err) {
          SpinnerService.stop();
          $scope.messages = [];
          if (err.status == "501"){
            // model passed in was valid but the fail happened due to the actual request
            populateErrorMessages(err.data.errors);
          } else {
            // request failed because of the model
            populateErrorMessages(err.data);
          }
        });
    };

    function populateErrorMessages(arrPassedIn) {
      angular.forEach(arrPassedIn, function (value) {
        switch(value.code){
          case "PasswordMismatch":
            $scope.messages.push(ChangePassError.PasswordMismatch);
            break;
          case "PasswordRequiresLower":
            $scope.messages.push(ChangePassError.PasswordRequiresLower);
            break;
          default:
            $scope.messages.push(value);
        }
      });
    }

    $scope.cancel = function () {
      if (!authService.identity.isPassChangeRequired()) {
        // the user does not require to have the password changed
        // so clicking cancel should take the user to home page
        $state.go('home');
      } else {
        authService.logout();
      }
    }
  }
})();
