(function () {
  "use strict";
  
  app.controller('AdminPortfolioPopupController', AdminPortfolioPopupController);
  
  AdminPortfolioPopupController.$inject = ['$scope', 'AdminPortfolioService', 'SubscriptionUsersService', 'DateOffsetUtils', 'subscriptions', 'portfolioTypes'];
  
  function AdminPortfolioPopupController($scope, AdminPortfolioService, SubscriptionUsersService, DateOffsetUtils, subscriptions, portfolioTypes) {
    var magicObjectToGetKendoToSelectFirstOption = {
      name: ""
    };
    $scope.savePortfolio = savePortfolio;
    $scope.isValid = isValid;
    if (subscriptions.length) {
      // add new portfolio from admin portfolios page
      $scope.companies = _.map(subscriptions, function (eachSub) {
        return {
          id: eachSub.companyID,
          companyName: eachSub.companyName,
          subscriptionId: eachSub.subscriptionID
        };
      });
  	} else {
      // add new portfolio from subscription detail page
  	  var subs = [];
  	  subs.push(subscriptions);
  	  $scope.companies = _.map(subs, function (eachSub) {
  	    if (eachSub.company) {
          return {
            id: eachSub.company.id,
            companyName: eachSub.company.companyName,
            subscriptionId: eachSub.id
          };
  	    }
      });
  	}

  	$scope.contacts = null;
  	$scope.masterPortfolios = null;
  	$scope.portfolioTypes = _.pullAllBy(portfolioTypes, [{'name': 'Deleted'}], 'name'); 
  	$scope.portfolio = {
  	  subscriptionId: null,
  	  company: $scope.companies.length > 1 ? null : $scope.companies[0],
  	  type: $scope.portfolioTypes[0],
  	  masterPortfolio: null,
  	  administrator: null,
  	  name: ""
  	};
  
  	$scope.companyDropdownChanged = function (e) {
  	  $scope.portfolio.subscriptionId = $scope.portfolio.company.subscriptionId;
  	  $scope.contacts = null;
  	  $scope.portfolio.administrator = null;
  	  AdminPortfolioService
        .getSubscriptionPortfolios($scope.portfolio.subscriptionId)
  	  	.then(function(rsp) {
  	  	  $scope.masterPortfolios = angular.copy(rsp);
  	  	  if($scope.portfolio.type.id == 4 && $scope.masterPortfolios.length > 0){
  	  	  	$scope.portfolio.masterPortfolio = $scope.masterPortfolios[0];
  	  	  } else {
  	  	  	$scope.portfolio.masterPortfolio = null;
  	  	  }
  	  	});
  	  SubscriptionUsersService
        .getSubscriptionContacts($scope.portfolio.subscriptionId)
  	  	.then(function(rsp) {
  	  	  $scope.contacts = angular.copy(_.orderBy(rsp, ['fullName']));
  	  	  $scope.contactList.enable(true);
  	  	  $scope.portfolio.administrator = $scope.contacts[0];
  	  	  isValid();
  	  	});
  	};
  
  	function companyInitHelper() {
  	  if($scope.portfolio.company == null) {
  	  	return;
  	  } else {
  	  	$scope.companyDropdownChanged();
  	  }
  	}

  	companyInitHelper();
  
  	$scope.typeDropdownChanged = function(e) {
      if($scope.portfolio.type.id == 4 && $scope.masterPortfolios.length > 0){
      	$scope.portfolio.masterPortfolio = $scope.masterPortfolios[0];
      } else {
      	$scope.portfolio.masterPortfolio = null;
      }
      isValid();
  	};
  
  	function isValid() {
      if($scope.portfolio.subscriptionId == null) {
  	  	return false;
  	  }
  	  if($scope.portfolio.company == null) {
  	  	return false;
  	  }
  	  if($scope.portfolio.type == null) {
  	  	return false;
  	  }
  	  if($scope.portfolio.type.id == 4 && $scope.portfolio.masterPortfolio == null) {
  	  	return false;
  	  }
  	  if($scope.portfolio.administrator == null) {
  	  	return false;
  	  }
  	  if($scope.portfolio.name == null || $scope.portfolio.name.trim() == "") {
  	  	return false;
  	  } else {
  	  	return true;
  	  }
  	  return false;
  	};
  
  	function savePortfolio(){
  	  if(!isValid()) return;
      
  	  var dataToSave = {
  	  	subscriptionId: $scope.portfolio.subscriptionId,
  	  	name: $scope.portfolio.name,
  	  	ownerContactId: $scope.portfolio.administrator.contactId,
  	  	type: $scope.portfolio.type,
  	  	masterPortfolioId: $scope.portfolio.masterPortfolio == null ? null : $scope.portfolio.masterPortfolio.id
  	  }

  	  AdminPortfolioService.createNewPortfolio(dataToSave)
  	  	.then(function(data) {
  	  	  //close the modal popup and go to the edit portfolio page of the newly created portfolio
  	  	  $scope.$close({ portfolioId: data });
  	  	});
  	}
  }
})();