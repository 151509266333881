(function () {
  'use strict';

  app.directive('countyInfoBox', countyInfoBoxDirective);
  //because this template needs to get passed to the bing API we can't use a regular directive template
  var template = '/app/partials/local/countyInfoBox.html';

  countyInfoBoxDirective.$inject = ['$http', '$interpolate', '$sce']
  function countyInfoBoxDirective($http, $interpolate, $sce) {
    //get the infobox template once
    var htmlTemplateCompiled;
    $http.get(template)
      .then(function (rsp) {
        htmlTemplateCompiled = $interpolate(rsp.data);
      });

    return {
      link: link,
      restrict: 'E',
      scope: {
        pin: '=?'
      },
      require: ['^bingMap']
    };

    function link(scope, element, attrs, ctrls) {
      scope.$watch('pin', updatePinChange);
      scope.$on('pinHover', pinHoverHandler);
      scope.$on('pinHoverOut', pinHoverOutHandler);
      scope.$on('$destroy', unregisterEventListeners);
      element.on('$destroy', unregisterEventListeners);

      var visible = false;
      var options = getDefaultOptions();
      var infobox = new Microsoft.Maps.Infobox(new Microsoft.Maps.Location(0, 0), options);
      infobox.setMap(ctrls[0].map);

      function getDefaultOptions() {
        return {
          visible: false,
          offset: infobox ? new Microsoft.Maps.Point(-1 * (infobox.getWidth() / 2), 30)
                          : new Microsoft.Maps.Point(-350, 30),
          maxWidth: 300,
          width: 150
        };
      }

      function createInfoBoxHtml() {
        if (htmlTemplateCompiled && scope.pin) {
          scope.pin.counties = interpolateRepeater(scope.pin.counties, '<div>{{item[0].data.countyName}} County: {{item.length}}</div>');
          //compile the html content that the info box will use
          var domTemplate = htmlTemplateCompiled(scope);
          var htmlFragment = $(document.createElement('div')).append(domTemplate);
          var htmlContentString = htmlFragment.html();
          return htmlContentString;
        }
      }

      /**
       * used as a replacement for ng-repeat as $interpolate does not have the ability to use ng-* directives
       * https://jsfiddle.net/ramonjd/eb3wp54c/
       * @param {*} array - collection of items
       * @param {*} template - html string with {{}} bindings
       */
      function interpolateRepeater(array, template) {
        if(!Array.isArray(array)) { return array; }
        return array.map(function (item, idx, array) {
          return $sce.trustAsHtml($interpolate(template)({item: item, array: array}));
        }).join('');
      }

      function pinHoverHandler(event, pin) {
        if (pin.type != 'county') {
          // a diferent type of infobox is getting shown or hidden so just hide this one
          updateVisibility(false);
          return;
        }
        scope.pin = pin.object;
        updateVisibility(true);
      }

      function pinHoverOutHandler(event, pin) {
        if (pin.type == 'county') {
          updateVisibility(false);
        }
      }

      function updateVisibility(newVisibility) {
        if (newVisibility === visible) {
          return; //no work necessary
        }
        visible = newVisibility;
        infobox.setOptions({
          visible: visible
        });
        if (!visible) {
          return; // rest of function has to do with positioning infobox, wiring in button click events, setting the checkbox's checked
        }

        var currentOffset = infobox.getOffset();
        var infoboxLocation = ctrls[0].map.tryLocationToPixel(infobox.getLocation(), Microsoft.Maps.PixelReference.control);
        var mapWidth = ctrls[0].map.getWidth();
        var directions = checkOverEdge(currentOffset.x, currentOffset.y, infobox, infoboxLocation, mapWidth);
        infobox.setOptions({
          offset: directions
        });
      }

      function unregisterEventListeners() {
        if (infobox) {
          infobox.setMap(null);
          infobox = null;
        }
      }

      function updatePinChange() {
        var html = createInfoBoxHtml();
        if (!html) {
          infobox.setOptions({ visible: false }); // turn off if we can't create html
        } else {
          infobox.setOptions({ htmlContent: html });
          updateLocation(scope.pin); // move to new pin location
        }
      }

      function updateLocation(pin) {
        if (pin.latitude && pin.longitude) {
          infobox.setOptions({ offset: getDefaultOptions().offset }); // put back to default offset
          infobox.setLocation(new Microsoft.Maps.Location(pin.latitude, pin.longitude));
        }
      }
    }

    function checkOverEdge(offsetX, offsetY, infobox, infoboxLocation, mapWidth) {
      var directions = new Microsoft.Maps.Point(offsetX, offsetY);
      var height = infobox.getHeight();
      var offetDistance = directions.y;
      var distanceAbovePinNeeded = height + offetDistance;
      var distanceToTopEdge = infoboxLocation.y - distanceAbovePinNeeded;
      if (distanceToTopEdge < 0) {
        //console.log("Hanging over top by " + distanceToTopEdge + " pixels");
        directions.y = height * -1;
      }
      var distanceToLeftEdge = infoboxLocation.x + directions.x;
      if (distanceToLeftEdge < 0) {
        //console.log("Hanging over left by " + distanceToLeftEdge + " pixels");
        directions.x = infoboxLocation.x * -1; //flush against edge
        return directions;
      }
      var infoBoxWidth = infobox.getWidth();

      var pixelsPastRightEdge = mapWidth - (infoboxLocation.x + directions.x + infoBoxWidth);
      if (pixelsPastRightEdge < 0) {
        //console.log("Hanging over right by " + pixelsPastRightEdge + " pixels");
        directions.x = mapWidth - infoboxLocation.x - infoBoxWidth;
      }
      return directions;
    }
  }
})();
