(function () {
  app.controller('TrendsFilterController', TrendsFilterController);

  TrendsFilterController.$inject = ['$scope', '$state', '$q', '$timeout', '$window', 'TrendsServiceSubset', 'SaveFilterService', 'SpinnerService',
    'additionalMarketId', 'savedFilters', 'queryFilters', 'filterName', 'vintageTypeConstant'];
  function TrendsFilterController($scope, $state, $q, $timeout, $window, TrendsServiceSubset, SaveFilterService, SpinnerService,
    additionalMarketId, savedFilters, queryFilters, filterName, vintageTypeConstant) {
    var vm = this;

    var magicObjectToGetKendoToSelectFirstOption = { name: ''};
    var primaryAndSecondaryMarket = 4;
    var defaultVintageId = 1;
    
    // Data
    vm.savedFilters = savedFilters;
    vm.selectedFilterName = angular.copy(magicObjectToGetKendoToSelectFirstOption);
    vm.filters = {
      propertyTypes: $scope.$parent.propertyTypes,
      communityTypes: $scope.$parent.communityTypes,
      campusTypes: $scope.$parent.campusTypes,
      communityCampusTypes: $scope.$parent.communityCampusTypes,
      propertyAges: $scope.$parent.propertyAges,
      profitStatus: $scope.$parent.profitStatus,
      stabilityStatus: $scope.$parent.stabilityStatus,
      markets: $scope.$parent.markets,
      regions: $scope.$parent.regions,
      metros: _.map($scope.$parent.metros, function (metro) { metro['isThreeMonthRolling'] = isThreeMonthRolling; return metro; }),
      counties: [],
      vintages: $scope.$parent.vintages
    };
    
    // selectedFilters has either a propertyType or communityType property assigned at runtime based on the active state
    vm.selectedFilters = {
      campusTypes: [],
      market: primaryAndSecondaryMarket,
      profitStatus: null,
      propertyAges: null,
      stabilityStatus: null,
      vintageId: defaultVintageId
    };
    
    // Configuration / State
    vm.filterType = $state.is('trends.propertyType') ? 'propertyType' :
                    $state.is('trends.communityType') ? 'communityType' : '';
    vm.filterOptions = {
      valueTemplate: '<span>{{dataItem.name}}</span>',
      template: '<span class="k-state-default">{{dataItem.name}}</span><span class="pull-right"><button data-id="{{dataItem.id}}" type="button" class="close deleteFilter"><span aria-hidden="true">&times;</span></button></span>'
    };
    vm.propertyTypeTemplate = "<span># if (data.description=='Majority IL' || data.description=='Majority AL') { # &nbsp;&nbsp; # } # #: data.description #</span>";
    vm.communityTypeTemplate = "<span># if (data.communityByPlurality=='IL Community' || data.communityByPlurality=='AL Community' || data.communityByPlurality == 'MC Community') {# &nbsp;&nbsp; #} ##: data.communityByPlurality #</span>";
    vm.filterDeleteMessage = 'Do you want to delete the saved filter SAVED_NAME?';
    
    // Functions
    vm.init = init;
    vm.displayData = displayData;
    vm.isThreeMonthRolling = isThreeMonthRolling;
    vm.marketChanged = marketChanged;
    vm.regionChanged = regionChanged;
    vm.metroChanged = metroChanged;
    vm.metroSelected = metroSelected;
    vm.metroTemplate = metroTemplate;
    vm.communityOrPropertyChanged = communityOrPropertyChanged;
    vm.bindCampusTypes = bindCampusTypes;
    vm.vintageTypeChanged = vintageTypeChanged;
    vm.openSaveDialog = openSaveDialog;
    vm.saveNewFilter = saveNewFilter;
    vm.deleteSavedFilter = deleteSavedFilter;
    vm.savedFilterChanged = savedFilterChanged;
    vm.resetFilters = resetFilters;
    vm.showCCRCMessage = showCCRCMessage;
    vm.showAdditionalMarketMessage = showAdditionalMarketMessage;
    vm.filtersWOAccessSelected = null;

    vm.init()

    function init() {
      vm.selectedFilters[vm.filterType] = 0;

      if (filterName) {
        vm.selectedFilterName = _.find(vm.savedFilters, { 'name': filterName });
      }
      
      if (queryFilters) {
        processFilter(queryFilters);
      } else {
        displayData();
      }
    }

    function displayData() {
      $scope.$emit('chartLoading');
      vm.filtersWOAccessSelected = null;

      var payload = {
        selection: vm.selectedFilters,
        dropdowns: vm.filters,
        getExcel: TrendsServiceSubset.getExcel,
        getPdf: TrendsServiceSubset.getPdf,
        filterText: TrendsServiceSubset.getFilterText(vm.selectedFilters, $scope.$parent.currentQuarter, vm.filters)
      };

      var savedFilterName = null;
      if (vm.selectedFilterName) {
        savedFilterName = vm.selectedFilterName.name;
      }

      TrendsServiceSubset.getChartData(vm.selectedFilters, payload.filterText, savedFilterName)
        .then(function (chartData) {
          payload.data = chartData;
          payload.length = chartData.length;
          $scope.$emit('chartLoaded', payload);
        })
        .catch(function () {
          payload.noData = true;
          $scope.$emit('chartLoaded', payload);
        });
    }

    function marketChanged() {
      vm.selectedFilters.regionIds = [];
      vm.selectedFilters.cbsaCodes = [];
      updateMetros(vm.selectedFilters.market);
    }

    function regionChanged() {
      vm.selectedFilters.cbsaCodes = [];
      vm.filters.counties = [];
      updateMetros(vm.selectedFilters.market, vm.selectedFilters.regionIds);
    }

    function metroChanged() {
      vm.selectedFilters.countyIds = [];
      updateCounties(vm.selectedFilters.cbsaCodes);
    }

    function communityOrPropertyChanged(communityOrPropertyType) {
      vm.selectedFilters.campusTypes = [];
      if (vm.filterType === 'communityType')
        updateCampusTypes(communityOrPropertyType);
    }

    function bindCampusTypes() {
      switch (vm.filterType) {
        case 'propertyType':
          return vm.filters.campusTypes;
        case 'communityType':
          return vm.filters.communityCampusTypes;
      }
      return [];
    }

    function updateCampusTypes(communityOrPropertyType) {
      // only update campus types if we are on CommunityType tab
      SpinnerService.start();
      return TrendsServiceSubset.getCampusTypes(communityOrPropertyType)
        .then( function (campusTypes) {
          vm.filters.communityCampusTypes = campusTypes;
        })
        .finally(function() {
          SpinnerService.stop();
        });
    }

    function updateMetros(selectedMarketValue, regionIds) {
      return TrendsServiceSubset.getFilteredMetros(selectedMarketValue, regionIds)
        .then(function (metros) {
          vm.filters.metros = _.map(metros, function (metro) { metro['isThreeMonthRolling'] = isThreeMonthRolling; return metro; });
        });
    }

    function updateCounties(cbsaCodes) {
      return TrendsServiceSubset.getFilteredCounties(cbsaCodes)
        .then(function (counties) {
          vm.filters.counties = counties;
        });
    }

    function updateVintage(vintageId) {
      vm.selectedFilters.vintageId = vintageId;
    }
    
    function savedFilterChanged() {
      if (!vm.selectedFilterName) { return; }
      if (!vm.selectedFilterName.id) { return; }
      vm.filtersWOAccessSelected = null;

      SpinnerService.start();
      TrendsServiceSubset.getFilterByName(vm.selectedFilterName.name)
        .then(function (filter) {
          processFilter(filter);
        })
        .finally(function () {
          $timeout(function () {
            SpinnerService.stop();
          }, 150);
        });
    }

    function processFilter(filter) {
      return TrendsServiceSubset
        .subHasAccessToFilters(filter)
        .then(function (rsp) {
          initializeFromFilter(filter);
        }, function (err) {
          vm.filtersWOAccessSelected = err.data;
          $scope.$emit('chartLoaded', {
            selection: vm.selectedFilters,
            dropdowns: vm.filters,
            getExcel: TrendsServiceSubset.getExcel,
            getPdf: TrendsServiceSubset.getPdf,
            filterText: TrendsServiceSubset.getFilterText(vm.selectedFilters, $scope.$parent.currentQuarter, vm.filters),
            filterAccessViolation: true
          });
        });
    }

    function initializeFromFilter(filter) {
      return $q.all([
        updateMetros(filter.market, filter.regionIds),
        updateCounties(filter.cbsaCodes),
        updateCampusTypes(filter.communityType),
        updateVintage(filter.vintageId)
      ])
      .then(function () {
        $timeout(function () {
          vm.selectedFilters = filter; //if this is set too early kendo sometimes gets mad and blanks out some values; so wait a fraction of a second before setting
          vm.displayData();
        }, 100);
      });
    }

    function deleteSavedFilter(filterName) {
      TrendsServiceSubset.deleteFilter(filterName)
        .then(function () {
          return TrendsServiceSubset.getFilters(false);
        })
        .then(function (filters) {
          vm.savedFilters = filters;
          vm.selectedFilterName = angular.copy(magicObjectToGetKendoToSelectFirstOption);
        });
    }
    
    function openSaveDialog() {
      var existingNames = _.map(vm.savedFilters, 'name');
      SaveFilterService.openSaveDialog(existingNames).then(vm.saveNewFilter);
    }

    function saveNewFilter(newFilterName) {
      if (newFilterName.indexOf('/') != -1 || newFilterName.indexOf('\\') != -1) {
        $window.alert("slashes are not allowed in filter name. Nothing will be saved.");
        return;
      }
      SpinnerService.start();
      TrendsServiceSubset.createFilter(vm.selectedFilters, newFilterName)
        .then(function() {
          return TrendsServiceSubset.getFilters(false);
        })
        .then(function(filters) {
          vm.savedFilters = filters;
          vm.selectedFilterName = _.find(filters,{
            'name': newFilterName
          });
          updateVintage(vm.selectedFilters.vintageId);
        })
        .catch(function() {
          $window.alert("Error occurred while saving");
        })
        .finally(function() {
          SpinnerService.stop();
        });
    }

    function resetFilters() {
      $scope.$emit('reset');
    }

    function showAdditionalMarketMessage() {
      return vm.selectedFilters.market == additionalMarketId;
    }

    function showCCRCMessage() {
      return vm.selectedFilters.communityType == 5 ||
             vm.selectedFilters.propertyType == 4;
    }

    function vintageTypeChanged() {
      if (isThreeMonthRolling()) {
        vm.selectedFilters.regionIds = [];
        vm.selectedFilters.cbsaCodes = [];
      }
      $scope.metroKendoMultiSelect.refresh();
    }

    function metroSelected(e) {
      if (isThreeMonthRolling() && /additional/i.test(e.dataItem.marketType)) {
        e.preventDefault();
      }
    }

    function metroTemplate() {
      return '# if (isThreeMonthRolling()) { #' +
          '<span # if (/additional/i.test(marketType)) { #class="k-state-disabled"# } #>#: cbsaName #</span>' +
        '# } else { #' +
          '<span>#: cbsaName #</span>' +
        '# } #';
    }

    function isThreeMonthRolling() {
      return vm.selectedFilters.vintageId == vintageTypeConstant.threeMonthRolling;
    }
  }
})();
