(function () {

  "use strict";
  
  app.factory("PropertyDetailsService", PropertyDetailsService);
  
  PropertyDetailsService.$inject = ['$http', '$q', 'apiSettings', 'knownEventConstant', 'knownProductConstant', 'vintageTypeConstant', 'AuditService'];

  function PropertyDetailsService($http, $q, apiSettings, knownEventConstant, knownProductConstant, vintageTypeConstant, AuditService) {
    function getPropertyDetailsId(params) {
      var deferred = $q.defer();
      $http.post(apiSettings.url + '/propertyDetails', params)
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });

      return deferred.promise;
    };

    function getPropertyDetails(id) {
      var deferred = $q.defer();
      if (id) {
        $http.get(apiSettings.url + "/propertyDetails/" + id)
          .then(function (rsp) {
            deferred.resolve(rsp.data);
          },
          function (error) {
            deferred.reject(error);
          });
      } else {
        deferred.reject({
          source:"Missing Parameter : (id)",
          error:"Property ID is required"
        });
      }
      
      return deferred.promise;
    };

    function getPropertyDetailsTradeViews(params) {
      var deferred = $q.defer();
      if (params) {
        $http.post(apiSettings.url + "/propertyDetails/getPropertyDetailsTradeArea", params)
          .then(function (rsp) {
            deferred.resolve(rsp.data);
          },
          function (error) {
            deferred.reject(error);
          });
      } else {
        deferred.reject({
          source:"Missing Parameter : (params)",
          error:"PropertyDetailsTradeArea param ismissing for the body of POST."
        });
      }
      
      return deferred.promise;
    };
    
    function getDemographicYear() {
      var url = apiSettings.url + "/propertyDetails/demographicYear";
      var deferred = $q.defer();
      $http
        .get(url)
        .then(function (rsp) {
          deferred.resolve(rsp.data);
        }, function (err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }
    
    function getExcel(propertyVm) {
      var deferred = $q.defer();              
      var uri = apiSettings.url + '/propertyDetails/generateExcel?' +
        $.param({
          date: new Date()
        });

      $http
        .post(uri, propertyVm, {
          responseType: 'arraybuffer'
        })
        .then(function (rsp) {
          if (rsp.data.length == 0) {
            deferred.reject();
          } else {
            deferred.resolve(rsp.data);
          }
        }, function (err) {
            deferred.reject(err);
        });
      return deferred.promise;
    }

    function getDemographicsData(id, isPageLoad) {
      var deferred = $q.defer();

      if (id) {
        var uri = apiSettings.url + "/propertyDetails/getDemographicsData/" + id;
        $http
          .get(uri)
          .then(function (rsp) {
            if (isPageLoad && rsp.data) {
              // log event
              AuditService.logEvent(knownEventConstant.cohortDemographicsPIR.id, 
                knownEventConstant.cohortDemographicsPIR.message + ": " + rsp.data.name,
                null, id);
            }
            deferred.resolve(rsp.data);
          },
          function (error) {
            deferred.reject(error);
          });
      } else {
        deferred.reject({
          source:"Missing Parameter : (id)",
          error:"Property ID is required"
        });
      }
      
      return deferred.promise;
    }
    
    function getDistances() {
      var url = apiSettings.url + "/propertyDetails/distances";
      var deferred = $q.defer();
      $http.get(url)
        .then(function (rsp) {
          deferred.resolve(rsp.data);
        }, function (err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }
    
    function getAgeBands() {
      var url = apiSettings.url + "/propertyDetails/agebands";
      var deferred = $q.defer();
      $http.get(url)
        .then(function (rsp) {
          deferred.resolve(rsp.data);
        }, function (err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }
    
    function getHouseholdTypes() {
      var url = apiSettings.url + "/propertyDetails/householdtypes";
      var deferred = $q.defer();
      $http.get(url)
        .then(function (rsp) {
          deferred.resolve(rsp.data);
        }, function (err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }
    
    function getCohortData(passedInData) {
      var url = apiSettings.url + "/propertyDetails/cohortdata";
      var deferred = $q.defer();
      $http
        .post(url, passedInData)
        .then(function (rsp) {
          deferred.resolve(rsp.data);
        }, function (err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function exportCustomCohort(title, dataToPass) {
      var deferred = $q.defer();
      var uri = apiSettings.url + '/propertyDetails/customCohort/generateExcel?' +
        $.param({
          title: title,
          date: new Date()
        });

      $http
        .post(uri, dataToPass, {
          responseType: 'arraybuffer'
        })
        .then(function (rsp) {
          if (rsp.data.length == 0) {
            deferred.reject();
          } else {
            deferred.resolve(rsp.data);
          }
        }, function (err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function exportPropertyInventoryToPDF(propertyInfo) {
      var deferred = $q.defer();
      var uri = apiSettings.url + '/propertyDetails/export/pdf';
      $http
        .post(uri, propertyInfo, {
          responseType: 'arraybuffer'
        })
        .then(function (rsp) {
          if (rsp.data.length == 0) {
            deferred.reject();
          } else {
            deferred.resolve(rsp.data);
          }
        }, function (err) {
          deferred.reject(err);
        });
      return deferred.promise; 
    }

    function getQualityMetricsforProperty(id) {
      var deferred = $q.defer();
      var uri = apiSettings.url + '/qualityMetrics/' + id;
      $http
        .get(uri)
        .then(function (rsp) {
          deferred.resolve(rsp.data);
        }, function (err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function getPropertyDetailsIdBasedOnVintage(property, vintageId) {
      // easy thing to do would be to pass the tradeArea from the page
      // but for now the criteria says that a new PIR should default to 5 and 10 mile radius
      var objToReturn = {
        id: property.id,
        wasPirGuidCreated: false
      };
      if (vintageId == vintageTypeConstant.threeMonthRolling) {
        var defer = $q.defer();
        var pirInfo = {
          id: property.id,
          name: property.name,
          vintageId: vintageId,
          latitude: property.latitude,
          longitude: property.longitude,
          product: knownProductConstant.local,
          isInfoboxClick: false,
          shape1: {
            radius: 5,
            driveTime: {
              breakValue: null,
              trafficFlow: null
            },
            hasShape: true
          },
          shape2: {
            radius: 10,
            driveTime: {
              breakValue: null,
              trafficFlow: null
            },
            hasShape: true
          }
        };
        getPropertyDetailsId(pirInfo)
          .then(function (pirGuid) {
            objToReturn.id = pirGuid;
            objToReturn.wasPirGuidCreated = true;
            defer.resolve(objToReturn);
          }, function (err) { 
            console.error(err);
            defer.reject(err);
          });
        return defer.promise;
      }
      return $q.resolve(objToReturn);
    }
    
    return {
      getPropertyDetailsId: getPropertyDetailsId,
      getPropertyDetails: getPropertyDetails,
      getPropertyDetailsTradeViews: getPropertyDetailsTradeViews,
      getDemographicYear: getDemographicYear,
      getExcel: getExcel,
      getDemographicsData: getDemographicsData,
      getDistances: getDistances,
      getAgeBands: getAgeBands,
      getHouseholdTypes: getHouseholdTypes,
      getCohortData: getCohortData,
      exportCustomCohort: exportCustomCohort,
      exportPropertyInventoryToPDF: exportPropertyInventoryToPDF,
      getQualityMetricsforProperty: getQualityMetricsforProperty,
      getPropertyDetailsIdBasedOnVintage: getPropertyDetailsIdBasedOnVintage
    };
  }
})();
