var Nic = (function() {

  function kendoFiltersOnTop( id ) {
    var header = $($('#' + id).find('.k-grid-header')[0]);
    header.prepend(header.find('.k-filter-row')[0]);
  }

  function distance(lat1, lon1, lat2, lon2, returnUnits) {
    var radlat1 = Math.PI * lat1 / 180;
    var radlat2 = Math.PI * lat2 / 180;
    var theta = lon1 - lon2;
    var radtheta = Math.PI * theta / 180;

    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;

    if (returnUnits == "K")
      return dist * 1.609344;
    else if (returnUnits == "N") //nautical miles
      return dist * 0.8684;
    return dist; //statute miles
  }

  return {
    kendoGrid : {
      filtersOnTop: kendoFiltersOnTop
    },
    maps: {
      twoPointDistance: distance,
    }
  };
})();
