// This filter will convert the datetime value coming back from database 
// to the user's browser's datetime.
(function () {
  "use strict";

  app.filter('nicDateFilter', nicDateFilter);

  nicDateFilter.$inject = ['$filter', 'DateOffsetUtils'];

  function nicDateFilter($filter, DateOffsetUtils) {
    var angularDateFilter = $filter('date');
    return function(input, format) {
      if(!input)
        return input;
      if (input.toString().indexOf('Z') >= 0) {
        // date is already in User zone so let it go
        return angularDateFilter(input, format, 'UTC');
      }

      var convertStrToDate = new Date(input + 'Z');
      if (Object.prototype.toString.call(convertStrToDate) === "[object Date]" && !isNaN(convertStrToDate.getTime())) {
        // this will work take care of the instance usually happening from Chrome
      } else {
        // #IE facepalm
        convertStrToDate = new Date(input);
        var offsetInMinutes = DateOffsetUtils.getTimezoneOffset(convertStrToDate);
        DateOffsetUtils.setDateOffsetToDateTime(convertStrToDate, offsetInMinutes);
      }
      return angularDateFilter(convertStrToDate, format);
    };
  }

})();