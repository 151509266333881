(function() {
  "use strict";

  app.filter('FormatBytes', FormatBytes);

  function FormatBytes() {
    return function(bytes) {
      if(isNaN(bytes))
        return bytes;

      var kilobytes = bytes / 1024;

      if(kilobytes < 1024) {
        return formatting(kilobytes, "KB");
      }
      var megabytes = kilobytes / 1024;
      return formatting(megabytes, "MB");

    };
  }

  function formatting(units, label) {
    units = Math.floor(units * 10) / 10;
    if(units === 0) {
      units = 0.1;
    }
    return units + " " + label;
  }

})();