(function() {
  "use strict";
  app.factory("PinGeneratorUtil", pinGeneratorUtil)
  pinGeneratorUtil.$inject = ['PinFactory', '$filter', 'BingMapUtils','reportSectionTypeConstant'];
  function pinGeneratorUtil(pinFactory, $filter, BingMapUtils, reportSectionTypeConstant){
    return {
      generatePropertyData: generatePropertyData,
      getTransactionPins: getTransactionPins,
      getCompDirectoryPins: getCompDirectoryPins,
      getPARCompDirectoryPins: getPARCompDirectoryPins
    };    
    function generatePropertyData(values, type, tradeAreaOnePolygons, tradeAreaTwoPolygons, scope, isVisible){
      var data = createDataObject();
      if (type == reportSectionTypeConstant.hospital) {
        // for hospital... if no location then skip the property from showing
        // in pir/sir/par/pcr/local
        values = _.filter(values, function (hosp) {
          return hosp.location;
        });
      }

      _.forEach(values, function(currobj, index){
        var lat;var lon;
        if(type == reportSectionTypeConstant.hospital){
          if(currobj.location != undefined){
            lat = currobj.location.coordinates[1];
            lon = currobj.location.coordinates[0];
          }
        }
        else{
          lat = currobj.latitude;
          lon = currobj.longitude;
        }
        
        if(lat != undefined && lon != undefined) {
          var isInTradeAreaOne;
          var isInTradeAreaTwo;
          if(!tradeAreaOnePolygons && !tradeAreaTwoPolygons) {
            isInTradeAreaOne = true;
            isInTradeAreaTwo = true;
          } else {
            isInTradeAreaOne = BingMapUtils.pointInPolygon(tradeAreaOnePolygons, lat, lon);
            isInTradeAreaTwo = BingMapUtils.pointInPolygon(tradeAreaTwoPolygons, lat, lon);
          }
          if(!isInTradeAreaOne && !isInTradeAreaTwo) return;
          if(isInTradeAreaOne) data.tradeAreaOne.count++;
          if(isInTradeAreaTwo) data.tradeAreaTwo.count++;
          var pin = generatePinType(type, currobj, index, scope, isVisible);
          if(pin != null) data.mapPins.push(pin);
          currobj.pinNumber = pin.pinNumber;
          if(type == reportSectionTypeConstant.construction){
            if(currobj.constructionStatus == "Expansion"){
              if(isInTradeAreaOne) data.tradeAreaOne.expansionCount++;
              if(isInTradeAreaTwo) data.tradeAreaTwo.expansionCount++; 
            } else if (currobj.constructionStatus == "New Construction") {
              if(isInTradeAreaOne) data.tradeAreaOne.newCount++;
              if(isInTradeAreaTwo) data.tradeAreaTwo.newCount++;
            }
          }
        }
        else{
          currobj.pinNumber = undefined;
        }
        if(type != reportSectionTypeConstant.hospital){
          var currSpace = addSpacesForSegments(type, currobj);
          data.spaces.IL += currSpace.IL;
          data.spaces.AL += currSpace.AL;
          data.spaces.MC += currSpace.MC;
          data.spaces.NC += currSpace.NC;
        }
        data.properties.push(currobj);
      });
      return data;
    }

    function addSpacesForSegments(type, currobj){
      var spaces = {
        IL:0,
        AL:0,
        MC:0,
        NC:0
      };
      switch(type){
        case reportSectionTypeConstant.existingInventory:
          spaces.IL = parseInt(currobj.units.ilUnits);
          spaces.AL = parseInt(currobj.units.alUnits);
          spaces.MC = parseInt(currobj.units.mcUnits);
          spaces.NC = parseInt(currobj.units.ncUnits);
        break;
        case reportSectionTypeConstant.construction:
          spaces.IL = parseInt(currobj.units.ilConstructionUnits);
          spaces.AL = parseInt(currobj.units.alConstructionUnits);
          spaces.MC = parseInt(currobj.units.mcConstructionUnits);
          spaces.NC = parseInt(currobj.units.ncConstructionUnits);
        break;
        default:
          spaces.IL = parseInt(currobj.ilUnitCount);
          spaces.AL = parseInt(currobj.alUnitCount);
          spaces.MC = parseInt(currobj.mcUnitCount);
          spaces.NC = parseInt(currobj.ncUnitCount);
      }
      return spaces;
    }

    function generatePinType(type, currobj, index, scope, isVisible){
      switch(type){
        case reportSectionTypeConstant.hospital:
          return generateHospitalPin(currobj, index, isVisible, scope);
        case reportSectionTypeConstant.construction:
          var constructionPin = generatePin(currobj, index, isVisible, scope);
          constructionPin.options.typeName = 'construction-pin-text';
          return constructionPin;
        default:
          return generatePin(currobj, index, isVisible, scope);
      }
    }

    function getTransactionPins(values, tradeAreaOnePolygons, tradeAreaTwoPolygons, scope, isVisible){
      var transactions = createDataObject();
      var transactionPinCounter = 0;
      _.reduce(values, function (hash, transaction){
        var lat = transaction.latitude;
        var lon = transaction.longitude;
        if(lat != undefined && lon != undefined) {
          var isInTradeAreaOne;
          var isInTradeAreaTwo;
          if(!tradeAreaOnePolygons && !tradeAreaTwoPolygons) {
            isInTradeAreaOne = true;
            isInTradeAreaTwo = true;
          } else {
            isInTradeAreaOne = BingMapUtils.pointInPolygon(tradeAreaOnePolygons, lat, lon);
            isInTradeAreaTwo = BingMapUtils.pointInPolygon(tradeAreaTwoPolygons, lat, lon);
          }
          if(!isInTradeAreaOne && !isInTradeAreaTwo) return hash;
          if(isInTradeAreaOne) transactions.tradeAreaOne.count++;
          if(isInTradeAreaTwo) transactions.tradeAreaTwo.count++;
          var curr_id = transaction.propertyId;
          if(curr_id != undefined && hash[curr_id] === undefined) {
            if(transaction.typeDescription == null && transaction.propertyType != null) {
              transaction.typeDescription = transaction.propertyType;
            }
            transactions.mapPins.push(generatePin(transaction, transactionPinCounter, isVisible, scope));
            transactionPinCounter++;
            hash[curr_id] = transactionPinCounter;
          }
          transaction.pinNumber = hash[curr_id];
        }
        transactions.spaces.IL += parseInt(transaction.ilUnitCount);
        transactions.spaces.AL += parseInt(transaction.alUnitCount);
        transactions.spaces.MC += parseInt(transaction.mcUnitCount);
        transactions.spaces.NC += parseInt(transaction.ncUnitCount);
        transactions.properties.push(transaction);
        return hash;
      }, {});
      return transactions;
    }

    // TODO: Come up with a better name for this. We use it for more than compDirectory pins in PAR.
    function getCompDirectoryPins(properties, scope, isVisible){
      var compPins = [];
      if (properties) {
        _.forEach(properties, function (comp, index) {
          var pin = generatePin(comp, index, isVisible, scope);
          comp.pinNumber = pin.pinNumber; // This backwards assigning seems dangerous
          compPins.push(pin);
        });
      }
      return compPins;
    }

    // TODO: Come up with a better name for this. Based on getCompDirectoryPins
    function getPARCompDirectoryPins(properties, scope, isVisible) {
      var compPins = [];
      if (properties) {
        _.forEach(properties, function (comp, index) {
          var pin = generatePin(comp, index, isVisible, scope);
          comp.pinNumber = pin.pinNumber;
          pin.campusTypeDesc = comp.campusTypeDesc;
          var units = {
            ilUnits: comp.ilUnitsTotal,
            ilConstructionUnits: comp.ilConstructionUnitsTotal,
            alUnits: comp.alUnitsTotal,
            alConstructionUnits: comp.alConstructionUnitsTotal,
            mcUnits: comp.mcUnitsTotal,
            mcConstructionUnits: comp.mcConstructionUnitsTotal,
            ncUnits: comp.ncBedsTotal,
            ncConstructionUnits: comp.ncConstructionBedsTotal
          };

          pin.units = units;
          compPins.push(pin);
        });
      }
      return compPins;
    }

    function generatePin(value, index, isVisible, scope){
      var pin = new pinFactory.pin(value, index, scope);
      pin.visible = isVisible;
      return pin;
    }

    function generateHospitalPin(value, index, isVisible, scope){
      var pushpin = pinFactory.buildHospitalPin(value, index, (index+1).toString(), scope);
      pushpin.visible = isVisible;
      return pushpin;
    }

    function createDataObject(){
      return {
        properties:[],
        mapPins:[],
        tradeAreaOne: {
          count: 0,
          expansionCount: 0,
          newCount: 0,
          trusted: {}
        },
        tradeAreaTwo: {
          count: 0,
          expansionCount: 0,
          newCount: 0,
          trusted: {}
        },
        spaces: {
          IL: 0,
          AL: 0,
          MC: 0,
          NC: 0,
          totalSpaces: function () {
            return this.IL + this.AL + this.MC + this.NC;
          },
          ILFormatted: function () {
            return $filter('number')(this.IL, 0);
          },
          ALFormatted: function () {
            return $filter('number')(this.AL, 0);
          },
          MCFormatted: function () {
            return $filter('number')(this.MC, 0);
          },
          NCFormatted: function () {
            return $filter('number')(this.NC, 0);
          },
          totalSpacesFormatted: function () {
            return $filter('number')(this.totalSpaces(), 0);
          },
          trusted: {}  
        }
      };
    }
  }
})();
