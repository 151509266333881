(function() {
  "use strict";

  app.controller('TermsOfUseController', TermsOfUseController);

  TermsOfUseController.$inject = ['$scope','$state','$rootScope','AuthService','SpinnerService'];
  
  function TermsOfUseController($scope, $state, $rootScope, authService, SpinnerService) {
    $rootScope.hideLeftNav = true;
    $scope.btnAccept = true;

    $scope.acknowledge = function () {
      SpinnerService.start();
      authService
        .updateTOUDate()
        .then(function (rsp) {
          authService
            .reAuthenticate()
            .then(function (reAuthResp) {
              $state.go("home");
            }, function (reAuthErr) {
              SpinnerService.stop()
            });
        }, function (err) {
          SpinnerService.stop()
        });
    }

    $scope.cancel = function () {
      authService.logout();
    }

    $scope.enableAcceptButton = function () {
      $scope.btnAccept = false;
    }
  }
})();
