(function () {
  "use strict";
  
  app.controller('StepAddAggregateNameController', StepAddAggregateName);
  
  StepAddAggregateName.$inject = ['$scope', 'CompBuilderService'];
  function StepAddAggregateName($scope, CompBuilderService) {
    $scope.submissionModel = $scope.$parent.submissionModel;
    $scope.$parent.nextStepAction = validation;

    function validation() {
      return CompBuilderService
        .isAggregateNameUnique($scope.submissionModel.aggregateName.trim())
        .then(function (aggregateNameResponse) {
          if (!aggregateNameResponse.isValid) {
            throw {
              // made this an object for the sake of future expansion if needed
              message: 'The aggregate name entered is not unique. <b>' + $scope.submissionModel.aggregateName + '</b> is currently owned by ' + aggregateNameResponse.userFullName + '. <p class="marginTop10"> Change name to continue. </p>'
            };
          }
        });
    }
  }
})();
