(function () {
  "use strict";
  
  app.controller("AddAggregateSummaryController", AddAggregateSummaryController);
  
  AddAggregateSummaryController.$inject = ['$scope', '$state', '$stateParams', 'NotificationService', 'PopupHandlerService', 'SignalRService'];
  function AddAggregateSummaryController($scope, $state, $stateParams, NotificationService, PopupHandlerService, SignalRService) {
    if (!$stateParams.aggregateCreationSummary) {
      $state.go('compBuilder');
      return;
    }

    $scope.aggregateName = 'PLACEHOLDER';
    $scope.finalTradeArea = 'PLACEHOLDER';
    $scope.$onInit = $onInit;
    
    $scope.$on("$destroy", function() {
      SignalRService.removeHubEvent('compbuilderAggregateProcessed');
    });

    SignalRService.genericHub("compbuilderAggregateProcessed", $scope, function(_requestId, _notification) {
      if (!_notification) return;
      if ($stateParams.aggregateCreationSummary.searchCriteria.shareInfo.id != _requestId) return;
      PopupHandlerService
        .openSimplePopup(_notification.message, { backdrop: 'static', keyboard: false })
        .result
        .then(function () {
          NotificationService.remove(_notification.id);
        });
    });

    var locationGridData = [];
    var sharedUserGridData = [];

    $scope.locationGridOptions = {
      columns: [
        {
          title: 'Subject Location',
          field: 'peerGroup.subjectLocation'
        },
        {
          title: 'Competitive Set Count',
          field: 'compCount',
          width: '150px'
        }
      ],
      dataSource: new kendo.data.DataSource({
        transport: {
          read: function (opts) {
            opts.success(locationGridData);
          }
        },
        pageSize: 5,
        schema: {
          model: {
            fields: {
              'peerGroup.subjectLocation': { type: 'string' },
              'compCount': { type: 'number' }
            }
          }
        },
        sort: {
          field: 'subjectLocation',
          dir: 'asc'
        },
        serverPaging: false,
        serverFiltering: false,
        serverSorting: false
      }),
      scrollable: false,
      pageable: true,
      sortable: {
        mode: 'single',
        allowUnsort: false
      }    
    };

    $scope.shareGridOptions = {
      columns: [
        {
          title: "Shared",
          headerAttributes: {
            'class': 'text-center vertical-align-middle'
          },
          attributes: {
            'class': 'text-center'
          },
          template: '<input type="checkbox" ng-model="dataItem.isShared" disabled />',
          width: "49px"
        },
        {
          title: "Email Sent",
          headerAttributes: {
            'class': 'text-center vertical-align-middle'
          },
          attributes: {
            'class': 'text-center'
          },
          template: '<input type="checkbox" ng-model="dataItem.isSendEmail" ng-show="dataItem.isSendEmail" disabled />',
          width: "80px"
        },
        {          
          title: "User's Full Name",
          template: '<div>{{dataItem.firstName}} {{dataItem.lastName}}</div>',
          headerAttributes: {
            'class': 'vertical-align-middle'
          }
        }
      ],
      dataSource: new kendo.data.DataSource({
        transport: {
          read: function (opts) {
            opts.success(sharedUserGridData);
          }
        },
        pageSize: 5,
        schema: {
          model: {
            fields: {
              'isShared': { type: 'boolean' },
              'isSendEmail': { type: 'boolean' },
              'firstName': { type: 'string' },
              'lastName': { type: 'string' },
              'email': { type: 'string' }
            }
          }
        },
        sort: {
          field: "firstName",
          dir: "asc"
        },
        serverPaging: false,
        serverFiltering: false,
        serverSorting: false
      }),
      scrollable: false,
      pageable: true      
    };

    function $onInit() {
      $scope.aggregateName = $stateParams.aggregateCreationSummary.aggregateName;
      var selectedTradeArea = $stateParams.aggregateCreationSummary.searchCriteria.searchParameters.tradeArea;
      if (selectedTradeArea.radius)
        $scope.finalTradeArea = selectedTradeArea.radius + " Mile Radius";
      else
        $scope.finalTradeArea = 
          selectedTradeArea.driveTime.breakValue + " Minutes " + selectedTradeArea.driveTime.trafficFlow + " Traffic Drive Time";

      locationGridData = $stateParams.aggregateCreationSummary.success.peerGroups;
      $scope.locationGridOptions.dataSource.read();
      sharedUserGridData = $stateParams.aggregateCreationSummary.searchCriteria.shareInfo.userInfo;
      $scope.shareGridOptions.dataSource.read();
    }

    $scope.$onInit();
  }
})();