(function () {
  "use strict";

  app.controller('SubscriptionHistoryController', SubscriptionHistoryController);

  SubscriptionHistoryController.$inject = ['$scope', '$state', '$stateParams', '$uibModal',
    'SubscriptionHistoryService', 'SubscriptionDetailsService', 'SpinnerService'];

  function SubscriptionHistoryController($scope, $state, $stateParams, $uibModal,
    SubscriptionHistoryService, SubscriptionDetailsService, SpinnerService) {

    $scope.currentSubInfo = $scope.$parent.subscription;
    $scope.subscribedCBSAs = $scope.$parent.subscribedCBSAs;
    $scope.subscribedProducts = $scope.$parent.subscribedProducts;
    $scope.allRegionsAndCBSAs = $scope.$parent.allRegionsAndCBSAs;
    $scope.subHistory = $scope.$parent.subscriptionHistory;
    $scope.switchBtnTxt = null;

    $scope.subscriptionPopup = subscriptionPopup;
    $scope.exportExcel = exportExcel;
    $scope.displaySwitchBtn = displaySwitchBtn;
    $scope.displayRenewSubscription = displayRenewSubscription;
    $scope.onSwitchBtnClick = onSwitchBtnClick;
    $scope.viewSubscription = viewSubscription;

    $scope.$on('SilentSubscriptionDetailRefreshComplete', function () {
      $scope.currentSubInfo = SubscriptionDetailsService.getSubscriber();
      $scope.subscribedProducts = SubscriptionDetailsService.getSubscribedProducts();
      $scope.subscribedCBSAs = SubscriptionDetailsService.getSubscribedCBSAs();
    });

    var activeSubscriptionRow, pendingSubscriptionRows, mostRecentExpired = null;
    var subscriptionIdToActionMapping = {};
    var EDIT_ACTION_TXT = "Edit", VIEW_ACTION_TXT = "View";
    var allProducts = $scope.$parent.allProducts;
    var allSubscriptionTypes = $scope.$parent.allSubscriptionTypes;

    function initGrid() {
      $scope.gridOpts = {
        columns: [
        {
          title: " ",
          filterable: false,
          template: showAppropriateHtml,
          attributes: {
            'class': 'text-center'
          }
        },
        {
          field: "id",
          title: "Subscription ID",
          filterable: {
            cell: {
              showOperators: false,
              operator: "equals"
            }
          }
        }, {
          field: "startDate",
          title: "Start Date",
          format: "{0:MM/dd/yyyy}",
          filterable: {
            cell: {
              showOperators: false,
              operator: "gte"
            }
          }
        }, {
          field: "endDate",
          title: "End Date",
          format: "{0:MM/dd/yyyy}",
          filterable: {
            cell: {
              showOperators: false,
              operator: "lte"
            }
          }
        }, {
          field: "subscriptionType",
          title: "Subscription Type",
          filterable: {
            cell: {
              showOperators: false,
              operator: "contains"
            }
          }
        }, {
          field: "accountType",
          title: "Account Type",
          filterable: {
            cell: {
              showOperators: false,
              template : function(args){
                args.element.kendoDropDownList({
                  autoBind: false,
                  optionLabel: " ",
                  dataTextField: "name",
                  dataValueField: "name",
                  valuePrimitive: true,
                  dataSource: new kendo.data.DataSource({
                    data: [
                      { name: 'New' },
                      { name: 'Renewal' }
                    ]
                  })                                                           
                });
              }        
            }
          }
        }, {
          field: 'accountStatusCalculated',
          title: 'Account Status',
          filterable: {
            cell: {
              showOperators: false,
              template: function(args) {
                args.element.kendoDropDownList({
                  autoBind: false,
                  optionLabel: ' ',
                  dataTextField: 'name',
                  dataValueField: 'name',
                  valuePrimitive: true,
                  dataSource: new kendo.data.DataSource({
                    data: [
                      { name: 'Active' },
                      { name: 'Pending' },
                      { name: 'Expired' },
                      { name: 'Terminated' }
                    ]
                  })
                })
              }
            }
          }
        }, {
          field: "price",
          title: "Contract Value",
          format: "{0:c0}",
          filterable: {
            cell: {
              showOperators: false,
              operator: "gte"
            }
          },
          width: '10%'
        }, {
          field: "cbsaCount",
          title: "Metro Count",
          filterable: {
            cell: {
              showOperators: false,
              operator: "gte"
            }
          },
          width: '5%'
        }],
        dataSource: new kendo.data.DataSource({
          transport: {
            type: "json",
            read: function(options) {
              Nic.kendoGrid.filtersOnTop('historyGrid');
              // get the active subscription record...
              activeSubscriptionRow = _.filter($scope.subHistory, function (row) {
                return row.accountStatus.toLowerCase().indexOf('active') >= 0;
              });
              pendingSubscriptionRows = _.filter($scope.subHistory, function (row) {
                return row.accountStatus.toLowerCase().indexOf('pending') >= 0;
              });
              _.map($scope.subHistory, function (row) {
                // map active subscription info for each row for later use
                if (activeSubscriptionRow && activeSubscriptionRow.length) {
                  row["activeSubscription"] = {
                    id: activeSubscriptionRow[0].id,
                    startDate: activeSubscriptionRow[0].startDate,
                    endDate: activeSubscriptionRow[0].endDate
                  };
                } else {
                  row["activeSubscription"] = null;
                }
              });
              // needs to be below since we are mapping the data to add a new field
              options.success($scope.subHistory);
            }
          },
          pageSize: 5,
          error: function(err) {
            console.log('error - ' + err);
          },
          schema: {
            model: {
              fields: {
                'id': { type: 'string' },
                'startDate': { type: 'date' },
                'endDate': { type: 'date' },
                'subscriptionType': { type: 'string' },
                'accountType': { type: 'string' },
                'accountStatus': { type: 'string' },
                'price': { type: 'number' },
                'cbsaCount': { type: 'number' },
                'isMetroBank': { type: 'boolean' },
                'metrosPurchased': { type: 'number' }
              }
            }
          },
          sort: {
            field: "endDate",
            dir: "desc"
          },
          serverPaging: false,
          serverFiltering: false,
          serverSorting: false
        }),
        filterable: {
          mode: "row"
        },
        scrollable: false,
        sortable: {
          mode: "single",
          allowUnsort: false
        },
        pageable: true
      };
    };

    function getSubscriptionHistory() {
      var data = $scope.subHistory;

      // if all subscriptions are expired then the most recent expired subscription should be "Editable" while all other should be "Viewable"
      // if there is an active or pending subscription then they should be "Editable" while all others should be "Viewable"
      $scope.hasActiveOrPendingSub = _.find(data, function (subHistory) {
        return subHistory.accountStatus.toLowerCase().indexOf('active') >= 0 ||
          subHistory.accountStatus.toLowerCase().indexOf('pending') >= 0;
      });
      
      // sort all by endDate so we know what to do for next couple of steps
      // where we try to assign Edit/View properties per row
      _.orderBy(data, ['endDate'], ['desc']);
      if ($scope.hasActiveOrPendingSub) {
        // there is at lease an active or pending subscription available
        var onlyHasPending = false;
        var hasAnActiveSub = _.filter(data, function (subHistory) {
          return subHistory.accountStatus.toLowerCase().indexOf('active') >= 0;
        });
        if (hasAnActiveSub && hasAnActiveSub.length) {
           onlyHasPending = false;
        } else {
          // no active sub found so there is only pending subscription with expired
          onlyHasPending = true;
        }

        var counterToCheckWhichExpiredIdxOn = 0;
        _.forEach(data, function (subHistory, idx) {
          if (subHistory.accountStatus.toLowerCase().indexOf("expired") >= 0) {
            if (onlyHasPending && counterToCheckWhichExpiredIdxOn == 0) {
              // since there is only pending subscription with expired ones
              // we need to allow the most recent expired sub to be editable
              subscriptionIdToActionMapping[subHistory.id] = EDIT_ACTION_TXT;
            } else {
              subscriptionIdToActionMapping[subHistory.id] = VIEW_ACTION_TXT;
            }
            counterToCheckWhichExpiredIdxOn += 1;
          } else {
            // active or pending so allow it to be editable
            subscriptionIdToActionMapping[subHistory.id] = EDIT_ACTION_TXT;
          }
        });
      } else {
        // all are expired
        _.forEach(data, function (subHistory, idx) {
          if (idx == 0) {
            // most recent expired so allow it to be edited
            subscriptionIdToActionMapping[subHistory.id] = EDIT_ACTION_TXT;
            mostRecentExpired = subHistory;
          }
          else {
            subscriptionIdToActionMapping[subHistory.id] = VIEW_ACTION_TXT;
          }
        });
      }
      
      $scope.gridOpts.dataSource.read();
    }

    initGrid();
    getSubscriptionHistory();

    function showAppropriateHtml(dataItem) {
      var actionAllowed = subscriptionIdToActionMapping[dataItem.id];
      if (actionAllowed == EDIT_ACTION_TXT) {
        return "<div ng-click='subscriptionPopup(this.dataItem)'><span class='glyphicon glyphicon-pencil primary-blue-dark'></span></div>"
      } else {
        return "<div ng-click='viewSubscription(this.dataItem.id)'><span class='glyphicon glyphicon-search primary-blue-dark'></span></div>"
      }
    }

    function displayRenewSubscription() {
      if ($scope.$parent.isSubExpired) {
        // check if it is the most recent expired sub or not if it is then return true else return false
        if (mostRecentExpired == null || (mostRecentExpired != null && mostRecentExpired.id != $scope.currentSubInfo.id)) {
          return true;
        }
      }
      return false;
    }

    function displaySwitchBtn() {
      if ($scope.$parent.isSubExpired) {
        // current subscription is expired
        // check if it has any active or pending sub
        if (activeSubscriptionRow && activeSubscriptionRow.length) {
          $scope.switchBtnTxt = "Active";
          return true;
        }
        if (pendingSubscriptionRows && pendingSubscriptionRows.length) {
          $scope.switchBtnTxt = "Pending";
          return true;
        }
        // since current sub is expired check if it is the most recent expired or not
        if (mostRecentExpired && mostRecentExpired.id != $scope.currentSubInfo.id) {
          $scope.switchBtnTxt = "Active";
          return true;
        }
      }
      return false;
    }

    function onSwitchBtnClick() {
      var subscriptionIdToUse;
      // selection flow matters... first assign the pending one but if active exists then overwrite it
      if (mostRecentExpired && mostRecentExpired.id) {
        subscriptionIdToUse = mostRecentExpired.id;
      }
      if (pendingSubscriptionRows && pendingSubscriptionRows.length) {
        var sortedByStartDate = _.orderBy(pendingSubscriptionRows, ['startDate'], ['asc']);
        subscriptionIdToUse = sortedByStartDate[0].id;
      }
      if (activeSubscriptionRow && activeSubscriptionRow.length) {
        subscriptionIdToUse = activeSubscriptionRow[0].id;
      }
      if (!subscriptionIdToUse) return;
      $scope.viewSubscription(subscriptionIdToUse);
    }

    function viewSubscription(subId, companyId) {
      $state.go('admin.subscriptiondetails',
      {
        id: subId,
        companyId: companyId || $stateParams.companyId
      },
      { reload: true });
    }

    $scope.resetFilters = function() {
      $scope.gridOpts.dataSource.filter([]);
      $scope.gridOpts.dataSource.sort({
        field: "endDate",
        dir: "desc"
      });
    };

    function subscriptionPopup(dataItem) {
      // if the pending subscription is getting edited and it is not the pending subscription that is currently open through URL than get the CBSAs
      if (dataItem && dataItem.accountStatus.toLowerCase().indexOf('pending') >= 0 && $stateParams.id != dataItem.id) {
        SubscriptionDetailsService
          .getSubscriptionCBSAsData(dataItem.id)
          .then(function (subscribedCbsas) {
            subscriptionPopupWindowOpener(dataItem, subscribedCbsas);
          });
      } else {
        subscriptionPopupWindowOpener(dataItem);
      }
    }

    function subscriptionPopupWindowOpener(dataItem, subscribedCbsas) {
      var subscriptionPopup = $uibModal.open({
        templateUrl: "app/partials/subscriptionPopup.html",
        controller: 'SubscriptionPopupController',
        backdrop: 'static',
        windowClass: 'modify-access-popup',
        resolve: {
          actionToPerform: function () { return dataItem ? 'edit' : 'renew'; },
          paramsPassedIn: function () {
            return {
              subscriptionId: dataItem ? dataItem.id : $stateParams.id, 
              companyId: $stateParams.companyId,
              activeSubRowInfo: dataItem || activeSubscriptionRow, // NOTE: 1) dataItem is an object {} 2) activeSubscriptionRow is an array [{}, ..., {}]
              hasActiveOrPendingSub: $scope.hasActiveOrPendingSub,
              lastSubscription: $scope.subHistory && $scope.subHistory.length > 0 ? $scope.subHistory[0] : undefined,
              pendingSubscriptions: pendingSubscriptionRows
            };
          },
          newCompanies: function () { return null; },
          subscribedCBSAs: function () { return subscribedCbsas || $scope.subscribedCBSAs; },
          pageSubInfo: function () {
            return { 
              subscribedProducts: $scope.subscribedProducts,
              parentPageSubInfo: $scope.currentSubInfo
            };
          },
          allRegionsAndCBSAs: function () { return $scope.allRegionsAndCBSAs; },
          allProducts: function () { return allProducts; },
          allSubscriptionTypes: function () { return allSubscriptionTypes; }
        }
      });
        
      subscriptionPopup.result.then(function (dataReturned) {
        $scope.viewSubscription(dataReturned.subscriptionId, dataReturned.companyId);
      });
    }

    function getGridCurrentFilterAndSort(sourceGrid) {
      var filters = sourceGrid.dataSource.filter();
      // filters.filters = [ { field: columnName, operator: operator, value: filterValue }, ...]
      filters = (filters === undefined) ? [] : filters.filters;

      var gridData = {
        filters: filters,
        sorts: sourceGrid.dataSource.sort() || [] // sourceGrid.dataSource.sort() = [ { dir: 'asc' || 'desc', field: columnName }, ...]
      };

      return gridData;
    };

    function exportExcel() {
      SpinnerService.start();
      var gridFiltersAndSorts = {
        subscriptionHistoryFiltersAndSorts: getGridCurrentFilterAndSort($scope.gridOpts),
      }
      var companyInfo = SubscriptionDetailsService.getCompany();

      SubscriptionHistoryService
        .generateSubscriptionHistoryExcel(companyInfo.id, companyInfo.companyName, gridFiltersAndSorts)
        .catch(function (error) {
          console.error(error);
        })
        .finally(function () {
          SpinnerService.stop();
        });
    }
  }	
})();
