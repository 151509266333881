(function() {
  "use strict";

  app.factory("PropertyExport", PropertyExport);

  PropertyExport.$inject = ['$q', '$uibModal', 'vintageTypeConstant', 'DateOffsetUtils', 'FileSaver', 'SearchService', 'SpinnerService', 'TrendsService'];

  function PropertyExport($q, $uibModal, vintageTypeConstant, DateOffsetUtils, FileSaver, SearchService, SpinnerService, TrendsService) {
    function getWalkScore(property) {
      if (property.walkScoreInfo 
        && property.walkScoreInfo.value 
        && property.walkScoreInfo.value.status == 1) {
        return property.walkScoreInfo.value.walkScore;
      }
      return "N/A";
    }

    function propertiesToExport(possibleProperties, transactionOnly, onlyExportSelected) {
      var idWDriveTimeArea = [];

      for (var i = 0; i < possibleProperties.length; i++) {
        if (possibleProperties[i].selected || (!onlyExportSelected && !possibleProperties[i].selected)) {
          var currentProperty = possibleProperties[i];
          var pinImageIcon, idToUse;
          if (!transactionOnly)
            idToUse = currentProperty.id;
          else
            idToUse = currentProperty.transactionId;
          
          if (currentProperty.propertyTypeIcon) {
            var forceEmptyPinIcon = currentProperty.propertyTypeIcon.replace('-solid', '-empty');
            pinImageIcon = forceEmptyPinIcon.substring(forceEmptyPinIcon.lastIndexOf('/') + 1);
          }

          idWDriveTimeArea.push({
            id: idToUse,
            pinNumber: currentProperty.pinNumber,
            driveTimeArea: currentProperty.driveTimeArea,
            distanceFromCenter: currentProperty.distance,
            walkScore: getWalkScore(currentProperty),
            pinImage: pinImageIcon
          });
        }
      }

      return idWDriveTimeArea;
    }

    function getFileTimeframe(savedFilter, currentQuarter) {
      if (!savedFilter.search.vintageId || 
        savedFilter.search.vintageId != vintageTypeConstant.threeMonthRolling) {
        return $q.resolve(currentQuarter);
      }
      var deferred = $q.defer();
      TrendsService
        .getCurrentIQ()
        .then(function (rsp) {
          deferred.resolve(rsp);
        }, function (err) {
          console.error(err);
          deferred.resolve(currentQuarter);
        });
      return deferred.promise;
    }

    function chooseTitle(transactionOnly, timeFrame) {
      if (transactionOnly)
        return "NICMAP_Transaction_" + timeFrame.replace('/', '');
      else
        return "NICMAP_PropertyInventory_" + timeFrame.replace('/', '');
    }

    function getExcelFile(possibleProperties, savedFilter, onlyExportSelected, currentQuarter, exportProperties) {
      var transactionOnly = savedFilter.transactionOnly;
      var properties = propertiesToExport(possibleProperties, transactionOnly, onlyExportSelected);
      _.extend(exportProperties, {
        properties: properties,
        timeoffset: DateOffsetUtils.getTimezoneOffset(new Date())
      });

      SpinnerService.start();
      var title = '';
      getFileTimeframe(savedFilter, currentQuarter)
        .then(function (timeFrame) {
          title = chooseTitle(transactionOnly, timeFrame);
          _.extend(exportProperties, {
            title: title
          });
          return SearchService.exportProperties(exportProperties, transactionOnly);
        })
        .then(function (data) {
          var excelFile = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          });
          FileSaver.saveAs(excelFile, title + ".xlsx");
        })
        .finally(function () {
          SpinnerService.stop();
        });
    }

    function openOrExport(possibleProperties, savedFilter, currentQuarter, exportProperties) {
      var anySelected = _.some(possibleProperties, function (property) {
        return property.selected;
      });
      if (possibleProperties && anySelected) {
        var modalInstance = $uibModal.open({
          animation: true,
          templateUrl: 'app/partials/localPropertyExport.html',
          size: 10
        });

        modalInstance.result.then(function (returnValue) {
          getExcelFile(possibleProperties, savedFilter, returnValue, currentQuarter, exportProperties);
        });
      } else {
        getExcelFile(possibleProperties, savedFilter, false, currentQuarter, exportProperties);
      }
    }

    return {
      openOrExport: openOrExport
    };
  }
}());
