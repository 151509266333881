(function() {
  "use strict";

  app.factory("PinFactory", pinFactory);

  pinFactory.$inject = ['AuditService', 'knownEventConstant', 'knownProductConstant', 'PushPinImageCache'];

  function pinFactory(AuditService, knownEventConstant, knownProductConstant, PushPinImageCache) {

    return {
      pin: pin,
      cbsaPin: cbsaPin,
      clusterPin: clusterPin,
      buildPin: buildPin,
      buildHospitalPin: buildHospitalPin,
      buildPlannedPropertyPin: buildPlannedPropertyPin,
      buildPinOptions: buildPinOptions,
      buildBlackPinOptions: buildBlackPinOptions
    };

    //have to invoke as constructor
    //Example var pin = new pinFactory.pin(json);
    //we could just make this a plain-old javascript class?
    function pin(value, index, broadcast, includeSelectedPropertyIDs) {
      var that = this;
      that.pinNumber = (index + 1);
      // manually insert following property to the value
      var selected = (includeSelectedPropertyIDs && includeSelectedPropertyIDs.length > 0 && includeSelectedPropertyIDs.indexOf(value.id) > -1) ? true : false;
      var propType = getPinImages(value, selected);

      var pushpinOptions = buildPinOptions(propType.icon, that.pinNumber);

      if (value.transactionId) {
        that.isTransactionSearch = true;
        that.transactionId = value.transactionId;
      } else {
        that.isTransactionSearch = false;
      }
      that.propertyTypeIcon = propType.icon;
      that.propertyType = propType.propertyType;
      that.propertyTypeCode = propType.propertyTypeCode;
      that.infoboxIcon = propType.infoboxIcon;
      that.infoboxStatus = propType.infoboxStatus;
      that.infoBoxHeaderCSSClass = propType.infoBoxHeaderCSSClass;
      that.latitude = value.latitude;
      that.longitude = value.longitude;
      that.constructionStatus = value.constructionStatus;
      that.addressLine1 = value.address ? value.address.addressLine1 : value.addressLine1;
      that.city = value.address ? value.address.city : value.city;
      that.stateProvinceCode = value.address ? value.address.stateProvinceCode : value.stateProvinceCode;
      that.zipCode = value.address ? value.address.zipCode : value.zipCode;
      that.options = pushpinOptions;
      that.stakeholderID = value.stakeholderID;
      that.distance = value.distanceInMiles;
      that.units = value.units;
      that.typeDescription = value.typeDescription;
      that.profitStatus = value.profitStatus;
      that.stakeholders = value.stakeholders;
      that.buildingAgeInYears = value.buildingAgeInYears;
      that.saleDate = value.saleDate;
      that.salePrice = value.salePrice;
      that.pricePerUnit = value.pricePerUnit;
      that.county = value.countyName;
      that.operator = value.operator;
      that.constructionUnits = value.constructionUnits;
      that.metro = value.metro;
      that.constructionOpenDate = value.constructionOpenDate;
      that.openDate = value.buildingDateOpen;
      that.included = value.included; // for compset edit does it make sense to break out into a new pin type?
      that.cbsaCode = value.cbsaCode; // for compset edit

      that.walkScoreInfo = value.walkScoreInfo;
      that.walkScoreImageCSSClass = "hidden";
      if (value.walkScoreInfo && value.walkScoreInfo.value.status == 1) {
        // walkscore was successful
        that.walkScoreImageCSSClass = "walkScoreImageDiv";
      }

      //left nav properties
      that.id = value.id;
      that.name = value.name;
      that.expanded = false;
      that.selected = selected;
      that.highlighted = false;
      that.events = {
        click: function (eventArgs) {
          pushpinClicked(broadcast, eventArgs);
          broadcast.$broadcast("infoBoxToggled", {
            id: that.id,
            type: 'property',
            object: that
          });
          var product = that.isTransactionSearch ? knownProductConstant.trans : knownProductConstant.local;
          AuditService.logEvent(knownEventConstant.pinClick.id, knownEventConstant.pinClick.message + " - " + that.name, product, that.id);
        }
      };
      that.visible = (value.id > 0);

      that.updateIconImage = function () {
        if (that.propertyTypeIcon) {
          if (that.selected) {
            if (that.propertyTypeIcon.indexOf("-empty") != -1) {
              that.propertyTypeIcon = that.propertyTypeIcon.replace("-empty", "-solid");
            }
          } else {
            if (that.propertyTypeIcon.indexOf("-solid") != -1) {
              that.propertyTypeIcon = that.propertyTypeIcon.replace("-solid", "-empty");
            }
          }

          var pushpinOptions = buildPinOptions(that.propertyTypeIcon, that.pinNumber);
          that.options = { icon: pushpinOptions.icon };
        }
      }
    }

    /**
     * Used to wire up a custom pushpin that denotes a CBSA with X number of properties contained within it
     * @param {*} cbsa - object with cbsaName, cbsaCode and a collection of lat/long objects for each property contained within the area
     * @param {*} bingMap - the bingMap instance to attach events to
     */
    function cbsaPin(cbsa, bingMap, scope) {
      var locations = cbsa.locations.map(function (loc) { return new Microsoft.Maps.Location(loc.latitude, loc.longitude); });
      var bounds = Microsoft.Maps.LocationRect.fromLocations(locations);
      var centerLocation = bounds.center;

      //Define variables for minimum cluster radius, and how wide the outline area of the circle should be.
      var minRadius = 12;
      var outlineWidth = 5;

      //Get the number of pushpins in the cluster
      var clusterSize = locations.length;

      //Calculate the radius of the cluster based on the number of pushpins in the cluster, using a logarithmic scale.
      var radius = Math.log(clusterSize) / Math.log(10) * 5 + minRadius;

      var fillColor = cbsa.hasAccessToCbsa ? 'rgba(20, 180, 20, 0.5)'
                                           : 'rgba(50, 50, 50, 0.5)';

      function onClick() {
        onHoverLeave(); // hide infobox on click
        bingMap.setView({ bounds: bounds });
      }

      function onHover() {
        scope.$broadcast("pinHover", {
          id: cbsa.cbsaCode,
          type: 'cbsa',
          object: angular.extend(cbsa, { latitude: centerLocation.latitude, longitude: centerLocation.longitude })
        });
      }

      function onHoverLeave() {
        scope.$broadcast("pinHoverOut", {
          id: cbsa.cbsaCode,
          type: 'cbsa',
          object: angular.extend(cbsa, { latitude: centerLocation.latitude, longitude: centerLocation.longitude })
        });
      }

      var events = cbsa.hasAccessToCbsa ? { click: onClick, mouseover: onHover, mouseout: onHoverLeave }
                                        : { mouseover: onHover, mouseout: onHoverLeave };

      //Create an SVG string of two circles, one on top of the other, with the specified radius and color.
      var svg = ['<svg xmlns="http://www.w3.org/2000/svg" width="', (radius * 2), '" height="', (radius * 2), '">',
        '<circle cx="', radius, '" cy="', radius, '" r="', radius, '" fill="', fillColor, '"/>',
        '<circle cx="', radius, '" cy="', radius, '" r="', radius - outlineWidth, '" fill="', fillColor, '"/>',
        '</svg>'];
      return {
        latitude: centerLocation.latitude,
        longitude: centerLocation.longitude,
        options: {
          text: '' + locations.length,
          icon: svg.join(''),
          anchor: new Microsoft.Maps.Point(radius, radius),
          textOffset: new Microsoft.Maps.Point(0, radius - 8) //Subtract 8 to compensate for height of text.
        },
        events: events
      };
    }

    /**
     * Used to style and wire up events on the ClusterPin Layer
     * This should be used within the clusteredPinCallback defined here:
     * https://docs.microsoft.com/en-us/bingmaps/v8-web-control/modules/clustering-module/clusterlayeroptions-object
     * @param {*} cluster - the clusterPin instance
     * @param {*} bingMap - the bingMap instance
     */
    function clusterPin(cluster, bingMap, scope) {
      var locations = cluster.containedPushpins.map(function (pin) { return pin.getLocation(); });
      var bounds = Microsoft.Maps.LocationRect.fromLocations(locations);
      var centerLocation = bounds.center;
      var clusterPinInfoboxData = infoboxDataFactory();

      // Define variables for minimum cluster radius, and how wide the outline area of the circle should be.
      var minRadius = 12;
      var outlineWidth = 5;

      // Get the number of pushpins in the cluster
      var clusterSize = cluster.containedPushpins.length;

      // Calculate the radius of the cluster based on the number of pushpins in the cluster, using a logarithmic scale.
      var radius = Math.log(clusterSize) / Math.log(10) * 5 + minRadius;

      var fillColor = 'rgba(20, 180, 20, 0.5)';

      // Create an SVG string of two circles, one on top of the other, with the specified radius and color.
      var svg = ['<svg xmlns="http://www.w3.org/2000/svg" width="', (radius * 2), '" height="', (radius * 2), '">',
        '<circle cx="', radius, '" cy="', radius, '" r="', radius, '" fill="', fillColor, '"/>',
        '<circle cx="', radius, '" cy="', radius, '" r="', radius - outlineWidth, '" fill="', fillColor, '"/>',
        '</svg>'];
        

      // Customize the clustered pushpin using the generated SVG and anchor on its center.
      cluster.setOptions({
        icon: svg.join(''),
        anchor: new Microsoft.Maps.Point(radius, radius),
        textOffset: new Microsoft.Maps.Point(0, radius - 8) //Subtract 8 to compensate for height of text.
      });

      // Wireup eventHandlers
      function clusterClicked(e) {
        if (!e.target.containedPushpins) { return; }
        onHoverLeave();
        bingMap.setView({ bounds: bounds, padding: 0 });
      }

      function onHover() {
        if (!cluster.containedPushpins) { return; }
        scope.$broadcast("pinHover", {
          id: cluster.gridKey,
          type: 'county',
          object: clusterPinInfoboxData
        });
      }

      function onHoverLeave() {
        if (!cluster.containedPushpins) { return; }
        scope.$broadcast("pinHoverOut", {
          id: cluster.gridKey,
          type: 'county',
          object: clusterPinInfoboxData
        });
      }

      function infoboxDataFactory() {
        return {
          latitude: centerLocation.latitude,
          longitude: centerLocation.longitude,
          cbsaName: _.uniq(cluster.containedPushpins.map(function(pin) { return pin.data.cbsaName})).join(', '),
          counties: _.values(_.groupBy(cluster.containedPushpins, 'data.countyName'))
        }
      }

      Microsoft.Maps.Events.addHandler(cluster, 'click', clusterClicked);
      Microsoft.Maps.Events.addHandler(cluster, 'mouseover', onHover);
      Microsoft.Maps.Events.addHandler(cluster, 'mouseout', onHoverLeave);
    }

    function getInfoboxIcon(prop, propType) {
      var dir = "assets/infobox/" + propType.toLowerCase();

      if(prop.isSaleDateLast24Month) {
        return {
          img: dir + "-sold.png",
          status: "SOLD IN THE LAST 24 MONTHS"
        };
      }
      if(!prop.constructionStatus) {
        return null;
      }
      var status = prop.constructionStatus.toLowerCase();

      if(status == "new construction") {
        return {
          img: dir + "-construction.png",
          status: "UNDER CONSTRUCTION"
        };
      }

      if(prop.isOpenDateLast24Month && (status == "open" || status == "expansion")) {
        return {
          img: dir + "-open.png",
          status: "OPENED IN THE LAST 24 MONTHS"
        };
      }
      return null; //do nothing
    }

    function getPinImages(prop, selected) {
      var dir = "assets/map/";
      var result;
      var pinFilling = selected ? "solid" : "empty";
      var squareOrRound = (prop.constructionStatus == "Expansion" || prop.constructionStatus == "New Construction" ? "-square" : "")
      var pinDefaultImgPath = "assets/map/";
      if(prop.campusTypeDescription == "CCRC" || 
        prop.campusTypeDesc == "CCRC" || 
        prop.campusTypeDescription == "CCRC / LPC" ||
        prop.campusTypeDesc == "CCRC / LPC") {
        result = {
          propertyType: "CCRC / LPC",
          propertyTypeCode: "CCRC",
          icon: pinDefaultImgPath.concat("red-", pinFilling, squareOrRound, ".png")
        };
      } else {
        var typeDesc = "";
        if(prop.typeDescription) {
          typeDesc = prop.typeDescription.toLowerCase();
        }
        if(typeDesc == "majority nc") {
          result = {
            propertyType: "NC",
            propertyTypeCode: "NC",
            icon: pinDefaultImgPath.concat("teal-", pinFilling, squareOrRound, ".png")
          };
        } else if(typeDesc == "majority al") {
          result = {
            propertyType: "AL",
            propertyTypeCode: "AL",
            icon: pinDefaultImgPath.concat("blue-", pinFilling, squareOrRound, ".png")
          };
        } else if(typeDesc == "majority il") {
          result = {
            propertyType: "IL",
            propertyTypeCode: "IL",
            icon: pinDefaultImgPath.concat("green-", pinFilling, squareOrRound, ".png")
          };
        } else {
          result = {
            propertyType: "",
            propertyTypeCode: "",
            icon: "",
          };
        }
      }

      var infobox = getInfoboxIcon(prop, result.propertyTypeCode);
      if(infobox) {
        result.infoboxIcon = infobox.img;
        result.infoboxStatus = infobox.status;
      } else {
        result.infoBoxHeaderCSSClass = "hidden" /*no header*/
      }

      return result;
    }

    //helper around pin function
    //don't have to invoke as constructor
    //var pin = new pinFactory.buildPin(json);
    function buildPin(value, index, broadcast, includeSelectedPropertyIDs) {
      return new pin(value, index, broadcast, includeSelectedPropertyIDs);
    }

    function buildPinOptions(icon, text) {
      var pushpinCanvasParameters = {
        text: text,
        textOffset: new Microsoft.Maps.Point(15, 21),
        canvasSize: { width: 30, height: 48 },
        imageRect: { x: 30, y: 0, width: 78, height: 120 },
        debug: { enabled: false, color: '#FF0000' }
      }

      if (icon.indexOf("-square") != -1) {
        pushpinCanvasParameters.textOffset = new Microsoft.Maps.Point(15, 19);
      }
  
      var image = getImage(icon);
      var customPushPinImage = createPushPinCanvasContent(image, pushpinCanvasParameters);

      var pushpinOptions = {
        icon: customPushPinImage,
        visible: true,
        typeName: "black-text",
        cursor: "pointer",
        anchor: new Microsoft.Maps.Point(15,44)
      };

      return pushpinOptions;
    }

    function buildBlackPinOptions() {
      var blackPinOptions = buildPinOptions('assets/map/black-star.png');
      blackPinOptions.typeName = 'site-pin';
      return blackPinOptions;
    }

    function buildHospitalPin(hospital, index, text, broadcast) {
      return new hospitalPin(hospital, index, text, broadcast);
    }

    function buildPlannedPropertyPin(plannedProperty, index, broadcast) {
      return new plannedPropertyPin(plannedProperty, index, broadcast);
    }

    function pushpinClicked(scope, evt) {
      var loc = evt.target.getLocation();
      scope.$broadcast("pushpinClickedWhileInDrawingMode", {
        location: new Microsoft.Maps.Location(loc.latitude, loc.longitude),
        isInDrawingMode: scope.userDrawing && scope.userDrawing.currentShape != null 
      });
    }

    function hospitalPin(hospital, index, text, broadcast) {
      var that = this;
      that.pinNumber = (index + 1).toString();

      var pushpinCanvasParameters = {
        textOffset: new Microsoft.Maps.Point(15, 27),
        canvasSize: { width: 35, height: 50 },
        imageRect: { x: 22, y: 8, width: 90, height: 135 },
        debug: { enabled: false, color: '#FF0000' }
      }

      var image;
      var customPushPinImage;
      if (text) {
        image = getImage('assets/map/hospitals/Local-hospital-pins-06.png');
        pushpinCanvasParameters.text = text;
      } else {
        image = getImage('assets/map/hospitals/Local-hospital-pin-24.png');
      }

      customPushPinImage = createPushPinCanvasContent(image, pushpinCanvasParameters);

      var pushpinOptions = {
        icon: customPushPinImage,
        visible: true,
        typeName: "hospital-pin-text",
        cursor: "pointer",
        anchor: new Microsoft.Maps.Point(18, 47)
      };

      that.name = hospital.hospital_name;     
      that.addressLine1 = hospital.address;
      that.city = hospital.city;
      that.stateProvinceCode = hospital.state;
      that.zipCode = hospital.zip_code;
      that.options = pushpinOptions;
      that.latitude = hospital.location.coordinates[1];
      that.longitude = hospital.location.coordinates[0];     
      that.distance = hospital.distance;
      that.countyName = hospital.county_name;
      that.emergencyServices = hospital.emergency_services;
      that.ownership = hospital.hospital_ownership;
      that.type = hospital.hospital_type;
      that.providerid = hospital.provider_id;
      that.address = hospital.address;
      that.state = hospital.state;
      if (!that.distance) {
        that.infoboxDistanceCss = 'hidden-until-later';
      }

      that.id = "hosp_" + that.pinNumber;
      that.expanded = false;
      that.events = {
        click: function (eventArgs) {
          pushpinClicked(broadcast, eventArgs);
          broadcast.$broadcast("infoBoxToggled", {
            id: that.id,
            type: 'hospital',
            object: that
          });
        }
      };
      that.visible = true;
    }

    function plannedPropertyPin(plannedProperty, index, broadcast) {
      var that = this;
      that.pinNumber = (index + 1).toString();

      var pushpinCanvasParameters = {
        textOffset: new Microsoft.Maps.Point(15, 27),
        canvasSize: { width: 35, height: 50 },
        imageRect: { x: 22, y: 8, width: 90, height: 135 },
        debug: { enabled: false, color: '#FF0000' }
      }

      var image;
      var customPushPinImage;
      image = getImage('assets/map/grey-striped-diamond.png');
      customPushPinImage = createPushPinCanvasContent(image, pushpinCanvasParameters);

      var pushpinOptions = {
        icon: customPushPinImage,
        visible: true,
        typeName: "planned-property-pin-text",
        cursor: "pointer",
        anchor: new Microsoft.Maps.Point(15, 44)
      };

      that.project = plannedProperty.project;
      that.addressLine1 = plannedProperty.address
      that.city = plannedProperty.city;
      that.stateProvinceCode = plannedProperty.state;
      that.zipCode = plannedProperty.zipCode;
      that.options = pushpinOptions;
      that.latitude = plannedProperty.latitude;
      that.longitude = plannedProperty.longitude;
      that.distance = plannedProperty.distance;
      that.address = plannedProperty.fullAddress;
      that.state = plannedProperty.state;
      that.issueDate = plannedProperty.issueDate;
      that.stage = plannedProperty.stage;
      that.subClass = plannedProperty.subClass;
      that.version = plannedProperty.version;
      if (!that.distance) {
        that.infoboxDistanceCss = 'hidden-until-later';
      }

      that.id = "planprop_" + that.pinNumber;
      that.expanded = false;
      that.events = {
        click: function (eventArgs) {
          pushpinClicked(broadcast, eventArgs);
          broadcast.$broadcast("infoBoxToggled", {
            id: that.id,
            type: 'plannedProperty',
            object: that
          });
        }
      };
      that.visible = true;
    }

    function createPushPinCanvasContent(img, parameters) {
      if (img) {
        var c = document.createElement('canvas');
        c.width = parameters.canvasSize.width || img.width;
        c.height = parameters.canvasSize.height || img.height;

        var context = c.getContext('2d');

        // draw red rect just for debug to see canvas
        if (parameters && parameters.debug && parameters.debug.enabled) {
          if (parameters.debug.color) {
            context.fillStyle = parameters.debug.color;
          } else {
            context.fillStyle = '#FF0000';  // just red
          }
          context.fillRect(0, 0, c.width, c.height);
          c.style.opacity = '0.6';
        }

        //Draw image
        if (parameters && parameters.imageRect) {
          if (parameters.imageRect.width > 0 && parameters.imageRect.height > 0) {
            context.drawImage(img, parameters.imageRect.x, parameters.imageRect.y, parameters.imageRect.width, parameters.imageRect.height, 0, 0, c.width, c.height);
          }
        } else {
          context.drawImage(img, 0, 0, c.width, c.height);
        }

        //Draw text
        if (parameters && parameters.text) {
          context.textAlign = "center";
          context.fillStyle = 'black';
          context.font = 'bold 9pt Arial';
          var textOffset;
          if (parameters && parameters.textOffset) {
            textOffset = parameters.textOffset;
          } else {
            textOffset = new Microsoft.Maps.Point(0, 0);
          }
          context.fillText(parameters.text, textOffset.x, textOffset.y);
        }
        return c.toDataURL();
      }
    }

    function getImage(icon) {
      var image = PushPinImageCache.getImage(icon);
      return image;
    }
  }

}());
