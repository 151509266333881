(function () {
  'use strict';
  app.factory('FileDownloadService', FileDownloadService);

  // Trying to keep file related actions together in one service. Will be trying to migrate
  // existing usage of filesaving to use this service going forward.
  FileDownloadService.$inject = ['Blob', 'FileSaver', 'fileTypeConstant'];
  function FileDownloadService(Blob, FileSaver, fileTypeConstant) {
    var service = {
      getContentTypeFromHeader: getContentTypeFromHeader,
      getFilenameFromHeader: getFilenameFromHeader,
      saveAs: saveAs,
      saveFile: saveFile,
      saveExcel: saveExcel,
      savePdf: savePdf,
      saveReportFile: saveReportFile
    }
    return service;

    // https://stackoverflow.com/a/38479871
    // Most likely could be made safer.. however it does work for our use case.
    function getFilenameFromHeader(httpResponse) {
      var contentDispositionHeader = httpResponse.headers('Content-Disposition');
      var result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
      return result.replace(/"/g, '').replace(/\'/g, '');
    }

    function getContentTypeFromHeader(httpResponse) {
      var type = httpResponse.headers('Content-Type');
      return type;
    }

    function saveAs(file, fileName) {
      var name = fileName.replace(/ /g, '_')
      FileSaver.saveAs(file, name);
    }

    function saveFile(data, fileType, filename) {
      var file = new Blob([data], { type: fileType });
      this.saveAs(file, filename);
    }

    function saveExcel(data, filename) {
      this.saveFile(data, fileTypeConstant.excel, filename);
    }

    function savePdf(data, filename) {
      this.saveFile(data, fileTypeConstant.pdf, filename);
    }

    function saveReportFile(httpResponse) {
      var report = new File(httpResponse, this); // Passing this context so that I can call the public side of the service to aid testing
      this.saveFile(report.data, report.contentType, report.filename);
    }

    function File(httpResponse, context) {
      var file = this;
      file.filename = context.getFilenameFromHeader(httpResponse);
      file.contentType = context.getContentTypeFromHeader(httpResponse);
      file.data = httpResponse.data;
    }
  }
})();
