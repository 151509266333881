(function () {
  "use strict";
  
  app.controller("StepAddAggregateDistance", StepAddAggregateDistance);
  
  StepAddAggregateDistance.$inject = [
    '$scope', '$state', '$q', 
    'compBuilderValidationErrors', 'driveTimeOptions', 'radiusOptions', 
    'CompBuilderService', 'PopupHandlerService', 'SignalRService'];
  function StepAddAggregateDistance(
    $scope, $state, $q, 
    compBuilderValidationErrors, driveTimeOptions, radiusOptions, 
    CompBuilderService, PopupHandlerService, SignalRService) {
    $scope.$parent.nextStepAction = validation;
    $scope.$parent.previousStepAction = validateFormOnBackClick;
    $scope.$onInit = $onInit;
    $scope.radiusOptions = radiusOptions;
    $scope.driveTimeOptions = driveTimeOptions;
    // local tradeArea scope for binding purposes to the control
    // since the submissionModel version does not care about custom radius
    $scope.tradeArea = {
      type: null,
      customRadius: null,
      radius1: null,
      driveTime: {
        breakValue: null,
        trafficFlow: null
      }
    };

    $scope.$onInit();

    function $onInit() {
      // Preselect options based on search criteria from base or loaded from sessionStorage
      rebuildTradeAreaFromSubmissionModel($scope.$parent.submissionModel.searchParameters.tradeArea);
    }

    function validateFormOnBackClick() {
      $scope.$parent.form.$setValidity('validateTradeArea', true, $scope.$parent);
      return $q.resolve();
    }
    
    $scope.$watch('tradeArea', function () {
      $scope.$parent.form.$setValidity('validateTradeArea', hasTradeAreaSelected(), $scope.$parent);
      buildSubmissionModelFromSelections();
    }, true);

    function hasTradeAreaSelected() {
      switch ($scope.tradeArea.type) {
        case "radius":
          var radius1 = $scope.tradeArea.radius1;
          var customRadius = $scope.tradeArea.customRadius;
          if (customRadius && radius1 && radius1 == -1) {
            return true;
          } else if (radius1 && radius1 != "null" && radius1 != -1) {
            return true;
          }
          break;
        case "driveTime":
          var breakValue = $scope.tradeArea.driveTime.breakValue;
          var trafficFlow = $scope.tradeArea.driveTime.trafficFlow;
          if (breakValue && trafficFlow) {
            return true;
          }
          break;
      }
      return false;
    }

    function buildSubmissionModelFromSelections() {
      $scope.$parent.submissionModel.searchParameters.tradeArea.radius =
        $scope.tradeArea.customRadius || $scope.tradeArea.radius1;
      $scope.$parent.submissionModel.searchParameters.tradeArea.driveTime = {
        breakValue: $scope.tradeArea.driveTime.breakValue,
        trafficFlow: $scope.tradeArea.driveTime.trafficFlow
      };
    }

    $scope.driveTimeBreakValueChanged = function (selectedItem) {
      $scope.tradeArea.driveTime.breakValue = selectedItem.id;
      if (!$scope.tradeArea.driveTime.breakValue) {
        $scope.tradeArea.driveTime.trafficFlow = null;
        return;
      }
      if (!$scope.tradeArea.driveTime.trafficFlow) {
        $scope.tradeArea.driveTime.trafficFlow = "Low";
      }
    };

    $scope.resetDriveTime = function () {
      $scope.tradeArea.driveTime.breakValue = null;
      $scope.tradeArea.driveTime.trafficFlow = null;
    };

    $scope.radius1Changed = function (selectedItem) {
      $scope.tradeArea.customRadius = null;
    }

    $scope.resetRadius = function () {
      $scope.tradeArea.radius1 = null;
      $scope.tradeArea.customRadius = null;
    };

    function validation() {
      // console.log($scope.submissionModel);
      return CompBuilderService
        .findCompSet($scope.$parent.submissionModel)
        .then(function (rsp) {
          $scope.defer = $q.defer();

          SignalRService.genericHub('findCompSet', $scope, function(_peerGroupsubmitRsp, _requestId) {
            if (_requestId != rsp.requestId) return;
            console.log(_peerGroupsubmitRsp, _requestId);
            if (_peerGroupsubmitRsp.error) {
              // submit errored out due to some validation error
              var addAggregateErrorRsp = _peerGroupsubmitRsp.error;
              var validationResult = addAggregateErrorRsp.validationResult;
              var message = 'An unknown error occured. Please try clicking Next again.';

              if (addAggregateErrorRsp.wasTradeAreaUpdated && addAggregateErrorRsp.tradeArea) {
                // tradeArea needs to be changed to a new value from the original that is passed
                if (addAggregateErrorRsp.tradeArea.radius) {
                  message = 'The distance selection must be increased to at least <b>{newRadius} Miles</b> to meet protections for all subject locations. Update the radius selection or search criteria to continue.'
                    .replace('{newRadius}', addAggregateErrorRsp.tradeArea.radius);
                } else {
                  message = 'The distance selection must be increased to at least <b>{newBreakValue} Minutes {newTrafficFlow} Traffic</b> to meet protections for all subject locations. Update the drive time selection or search criteria to continue.'
                    .replace('{newBreakValue}', addAggregateErrorRsp.tradeArea.driveTime.breakValue)
                    .replace('{newTrafficFlow}', addAggregateErrorRsp.tradeArea.driveTime.trafficFlow);
                }
              } else if (validationResult && 
                          validationResult.reason == compBuilderValidationErrors.MaxTradeAreaReachedNoComps) {
                message = 'Unable to find competitive set of properties that contain {minPropertyCount} properties with {minStakeholderCount} unique operators for each subject location. Click <b>Back</b> and update search criteria to continue.'
                  .replace('{minPropertyCount}', addAggregateErrorRsp.minPropertyCount)
                  .replace('{minStakeholderCount}', addAggregateErrorRsp.minStakeholderCount);
              } else {
                // unknown error?
                console.error(_peerGroupsubmitRsp.error);
              }
            } else if(_peerGroupsubmitRsp.success) {
              // submit was a success with the search criteria and distance provided...
              // continue on to next page with what kind of information????? 
              $scope.$parent.submissionModel.shareInfo = _peerGroupsubmitRsp.searchCriteria.shareInfo;
              return $scope.defer.resolve();
            } else {
              // something weird happened
              console.error(_peerGroupsubmitRsp);
            }
            return $scope.defer.reject({
              message: message
            });
          });

          SignalRService.genericHubWError('findCompSetError', $scope, function(_findCompSetErr, _requestId) {
            if (_requestId != rsp.requestId) return;
            console.log(_findCompSetErr, _requestId);
            showFindCompSetErrorPopup();
            return $scope.defer.reject([]);
          });

          $scope.$parent.submissionModel.jobId = rsp.jobId;
          $scope.$parent.submissionModel.requestId = rsp.requestId;
          return $scope.defer.promise;
        }, function (err) {
          // some unknown error?! Never a good thing
          console.error(err);
          throw {
            message: 'An unknown error occured. Please try clicking Next again.'
          };
        })
        .finally(function () {
          console.log('finally');
          SignalRService.removeHubEvent('findCompSet');
          SignalRService.removeHubEvent('findCompSetError');
        });
    }

    function rebuildTradeAreaFromSubmissionModel(submissionModelSource) {
      if (submissionModelSource.driveTime != null &&
          submissionModelSource.driveTime.breakValue &&
          submissionModelSource.driveTime.trafficFlow) {
        $scope.tradeArea.type = 'driveTime';
        $scope.tradeArea.driveTime = {
          breakValue: submissionModelSource.driveTime.breakValue,
          trafficFlow: submissionModelSource.driveTime.trafficFlow
        };
        $scope.tradeArea.radius1 = null;
        $scope.tradeArea.customRadius = null;
      } else {
        $scope.tradeArea.type = 'radius';
        $scope.tradeArea.driveTime = {
          breakValue: null,
          trafficFlow: null
        };
        if (submissionModelSource.radius) {
          // a radius is selected... check if a known radius or customRadius
          if(_.find($scope.radiusOptions, ['id', parseFloat(submissionModelSource.radius)])) {
            // a known radius entered
            $scope.tradeArea.radius1 = submissionModelSource.radius;
            $scope.tradeArea.customRadius = null;
          } else {
            $scope.tradeArea.customRadius = submissionModelSource.radius;
            $scope.tradeArea.radius1 = -1;
          }
        }
      }
    }

    function showFindCompSetErrorPopup(aggregateName) {
      PopupHandlerService
        .openConfirmPopup({
          message: 'There was a problem finding the competitive set properties for <b>{aggregateName}</b> aggregate. Please click Try Again to resubmit your request or Cancel to forward the issue to Client Services.'
            .replace('{aggregateName}', $scope.$parent.submissionModel.aggregateName),
          confirmText: 'Try Again',
          dismissText: 'Cancel'
        })
        .result
        .then(function (confirmAction) {
          $scope.$parent.nextStep();
        }, function (dismissAction) {
          var message = 'Creating <b>{aggregateName}</b> failed. Details have been submitted for inquiry. We will get in touch with you shortly to resolve this issue.'
            .replace('{aggregateName}', $scope.$parent.submissionModel.aggregateName);
          var cancelPopup = PopupHandlerService.openSimplePopup(message, { backdrop: 'static', keyboard: false });
          cancelPopup
            .result
            .then(function () {
              $scope.$parent.removeFormState();
              $state.go('compBuilder', {}, { reload: true });
            });
        });
    }
  }
})();