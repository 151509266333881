(function () {
  "use strict";

  app.factory("WordpressService", WordpressService);
  
  WordpressService.$inject = ['$http', '$q', 'apiSettings', 'CacheFactory'];
  
  function WordpressService($http, $q, apiSettings, CacheFactory) {
    if (!CacheFactory.get('wordpressCache')) {
      CacheFactory.createCache('wordpressCache', {
        maxAge: 60 * 60 * 1000, // Items added to this cache expire after 1 hour
        cacheFlushInterval: 24 * 60 * 60 * 1000, // This cache will clear itself every day
        deleteOnExpire: 'aggressive', // Items will be deleted from this cache when they expire
        storageMode: 'sessionStorage'
      });
    }
    var wordpressCache = CacheFactory.get('wordpressCache');
    return {
      getWebinarInfo: getWebinarInfo,
      getSlides: getSlides,
      getNews: getNews,
      getInsights: getInsights,
      getRecordings: getRecordings,
      getGlossary: getGlossary,
      getFaq: getFaq,
      getDocumentLibrary: getDocumentLibrary,
      getVideoLibrary: getVideoLibrary,
      previewContent: previewContent,
      exportGlossaryToPDF: exportGlossaryToPDF,
      imageChooser: imageChooser,
      getDataReleaseWebinar: getDataReleaseWebinar
    };
    
    function getWebinarInfo() {
      var defer = $q.defer();
      $http.get(apiSettings.url + "/content?contentType=webinar-information", { cache: wordpressCache })
        .then(function(rsp) {
          defer.resolve(rsp.data);
        }, function(err) {
          defer.reject(err);
        });
    
      return defer.promise;
    }
    
    function getSlides() {
      var deferred = $q.defer();
      $http.get(apiSettings.url + '/content?contentType=webinar-slides', { cache: wordpressCache })
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });
    
      return deferred.promise;
    }
    
    function getNews() {
      var deferred = $q.defer();
      $http.get(apiSettings.url + '/content?contentType=news', { cache: wordpressCache })
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });
    
      return deferred.promise;
    }
    
    function getInsights() {
      var deferred = $q.defer();
      $http.get(apiSettings.url + '/content?contentType=newsletter', { cache: wordpressCache })
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });
    
      return deferred.promise;
    }
    
    function getRecordings() {
      var deferred = $q.defer();
      $http.get(apiSettings.url + '/content?contentType=conference-recording', { cache: wordpressCache })
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });
    
      return deferred.promise;
    }
    
    function getGlossary() {
      var deferred = $q.defer();
      $http.get(apiSettings.url + '/content?contentType=glossary', { cache: wordpressCache })
        .then(function success(rsp) {
          deferred.resolve(rsp.data);
        }, function error(err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }
    
    function getFaq() {
      var deferred = $q.defer();
      $http.get(apiSettings.url + '/content?contentType=faq', { cache: wordpressCache })
        .then(function success(rsp) {
          deferred.resolve(rsp.data);
        }, function error(err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }
    
    function getDocumentLibrary() {
      var deferred = $q.defer();
      $http.get(apiSettings.url + '/content?contentType=document')
        .then(function success(rsp) {
          deferred.resolve(rsp.data);
        }, function error(err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function getVideoLibrary() {
      var deferred = $q.defer();
      $http.get(apiSettings.url + '/content?contentType=video')
        .then(function success(rsp) {
          deferred.resolve(rsp.data);
        }, function error(err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }
    
    function previewContent(type, id) {
      type == "page" ? type = "pages" : type; //Because the preview link for a "Pages type returns page"
      var deferred = $q.defer();
      $http.get(apiSettings.url + '/content/preview/' + type + '/' + id)
        .then(function success(rsp) {
          deferred.resolve(rsp.data);
        }, function error(err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }

    function exportGlossaryToPDF() {
      var deferred = $q.defer();

      var uri = apiSettings.url + '/content/export/glossary/pdf';
        
      $http
        .post(uri, {}, {
          responseType: 'arraybuffer'
        })
        .then(function (rsp) {
          if (rsp.data.length == 0) {
            deferred.reject();
          } else {
            deferred.resolve(rsp.data);
          }
        }, function (err) {
          deferred.reject(err);
        });
      return deferred.promise; 
    }

    function imageChooser(image, sizes) {
      var imageToReturn = null;
      _.each(sizes, function(size) {
        if(_.has(image, size)){
          imageToReturn = _.get(image, size + '.path');
          if(imageToReturn) {
            return false;
          }
        }
      });
      return imageToReturn;
    }

    function getDataReleaseWebinar() {
      var deferred = $q.defer();
      $http.get(apiSettings.url + '/content?contentType=data-release-webinar')
        .then(function success(rsp) {
          deferred.resolve(rsp.data);
        }, function error(err) {
          deferred.reject(err);
        });
      return deferred.promise;
    }
  }
})();
