(function() {
  "use strict";
  app.factory("AdminEditCompsService", AdminEditCompsService);

  AdminEditCompsService.$inject = ["$http", "$q", "apiSettings"];

  function AdminEditCompsService($http, $q, apiSettings) {
    return {
    	getCompDetails: getCompDetails,
    	removeComp: removeComp,
    	addComps: addComps
    };

    function adminPortfolioUrl(portfolioId) {
      return apiSettings.url + '/AdminPortfolios/' + portfolioId;
    }

    function getCompDetails(portfolioId,propertyId) {
    	var deferred = $q.defer();
      $http.get(adminPortfolioUrl(portfolioId) + "/" + propertyId + '/comps')
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });

      return deferred.promise;
    };

    function removeComp(portfolioId,propertyId, compId) {
    	var deferred = $q.defer();
      $http.delete(adminPortfolioUrl(portfolioId) + "/" + propertyId + '/comps/' + compId)
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });

      return deferred.promise;
    };
    
    function addComps(portfolioId, propertyId, commaDelimitedComps) {
    	var deferred = $q.defer();
      if(!commaDelimitedComps) {
        deferred.reject();
        return deferred.promise;
      }
      var compIds = commaDelimitedComps.split(",");
      var trimmedCompIds = [];
      if(!_.every(compIds, function(unTrimmedCompId) {
          var compId = unTrimmedCompId.trim();
          trimmedCompIds.push(compId);
          if(!/^[0-9]+$/.test(compId)) {
            return false;
          }
          var isInt = parseInt(compId);
          return isFinite(isInt);
        })) {
        deferred.reject();
        return deferred.promise;
      }

      $http.put(adminPortfolioUrl(portfolioId) + "/" + propertyId + '/comps', trimmedCompIds)
        .then(function(rsp) {
          deferred.resolve(rsp.data);
        }, function(err) {
          deferred.reject(err);
        });

      return deferred.promise;

    };
	}
})();