(function () {
  "use strict";
  app.directive('tradeArea', TradeAreaDirective);

  function TradeAreaDirective() {
    return {
      restrict: 'E',
      templateUrl: '/app/partials/local/tradeArea.html',
      controller: TradeAreaController,
      controllerAs: 'tradeArea',
      require: '^LocalPropertySearchController',
      scope: {},
      bindToController: {
        searchFilter: '=searchFilter',
        radiusOptions: '=radiusOptions', //This should change to < when upgraded past angular 1.5 (one-way-bind)
        drawing: '=drawing',
        driveTimeOptions: '=driveTimeOptions',
        defaultSelections: '=defaultSelections'
      }
    };
  };

  TradeAreaController.$inject = ['$rootScope', '$scope', '$sce', 'GeoDataService'];
  function TradeAreaController($rootScope, $scope, $sce, GeoDataService) {
    var vm = this;

    vm.$onInit = init;
    vm.zoomWarningPopoverContent = zoomWarningPopoverContent;
    vm.toggleUserDrawing = toggleUserDrawing;
    vm.resetRadii = resetRadii;
    vm.triggerSearch = triggerSearch;
    vm.innerRadiusChanged = innerRadiusChanged;
    vm.disableOuterRadius = disableOuterRadius;
    vm.customOuterRadiusMinValue = customOuterRadiusMinValue;
    vm.resetDriveTime = resetDriveTime;

    vm.driveTimeOneBreakValueChanged = driveTimeOneBreakValueChanged;
    vm.driveTimeOneTrafficFlowChanged = driveTimeOneTrafficFlowChanged;
    vm.driveTimeTwoBreakValueChanged = driveTimeTwoBreakValueChanged;
    vm.driveTimeTwoTrafficFlowChanged = driveTimeTwoTrafficFlowChanged;
    vm.driveTimeLabelColor = {
      drive1: null,
      drive2: null
    };

    var drive2TrafficFlowDataSource;

    $scope.$watch(function () {
      return vm.searchFilter.driveTimeInfo;
    }, function () {
      setDriveTimeLabelColor();
    }, true);

    $scope.$on('RESETDRIVETIME.TRAFFICFLOW2', function (evt, savedFilter) {
      var lastValue = drive2TrafficFlowDataSourceReset(true, savedFilter.search.driveTimeInfo);
      vm.searchFilter.driveTimeInfo.drive2.trafficFlow = lastValue.id;
    });

    function init() {
      vm.popoverContent = {};
      vm.outerRadiusOptions = _.cloneDeep(vm.radiusOptions);
      drive2TrafficFlowDataSource = _.chain(_.cloneDeep(vm.driveTimeOptions.trafficFlows))
        .map(function (obj) { return _.extend(obj, { visible: true }); })
        .value();
      vm.drive2TrafficFlowOptions = {
        dataSource: new kendo.data.DataSource({
          transport: {
            type: "json",
            read: function (opts) {
              opts.success(drive2TrafficFlowDataSource);
            }
          }
        }),
        dataTextField: "description",
        dataValueField: "id",
        resetFilters: function() {
          this.dataSource.filter([{ field: "visible", value: true }]);
        }
      };
      document.addEventListener ('keydown', function (e) {
        if(vm.drawing.currentShape != null){
          e = e || window.event;
          if (e.keyCode === 27) {
            toggleUserDrawing();
          }
        }
      },
      false);
    }

    function zoomWarningPopoverContent() {
      var content = "This feature is not available.<br/>Zoom in to enable this feature.";
      return vm.popoverContent[content] || (vm.popoverContent[content] = $sce.trustAsHtml(content));
    }

    function toggleUserDrawing() {
      if(vm.drawing.enabled){
        if (vm.defaultSelections.radiusOnly || vm.defaultSelections.driveTimeOnly) {
          vm.defaultSelections.radiusOnly = false;
          vm.resetRadii();
          vm.defaultSelections.driveTimeOnly = false;
          vm.resetDriveTime();
          $scope.$emit('triggerSearch');
        }
        clearDrawing();
        var polygonDrawingMode = 3;  //just hardcode the enum here so we don't have to load the module in this controller Microsoft.Maps.DrawingTools.DrawingMode.polygon
        vm.drawing.currentShape = vm.drawing.currentShape == null ? polygonDrawingMode : null;
        if(vm.drawing.currentShape != null) {
          $scope.$emit('closeAll');
          $rootScope.$broadcast('DRAWINGTOOLS.CLEAR');
        }
      }
    }    

    function resetRadii() {
      if(!vm.defaultSelections.radiusOnly) {
        vm.searchFilter.radius1 = null;
        vm.searchFilter.customRadius1 = null;
        vm.searchFilter.radius2 = null;
        vm.searchFilter.customRadius2 = null;
      } else if (vm.defaultSelections.radiusOnly && vm.defaultSelections.driveTimeOnly) {
        vm.defaultSelections.driveTimeOnly = false;
        vm.resetDriveTime();
      }
    }

    function triggerSearch() {
      clearDrawing();
      $scope.$emit('closeAll');
      $scope.$emit('triggerSearch');
    }

    function clearDrawing() {
      if ((vm.defaultSelections.radiusOnly || vm.defaultSelections.driveTimeOnly) &&
        _.isArrayLikeObject(vm.searchFilter.userDrawingLocs) && vm.searchFilter.userDrawingLocs.length > 0) {
        vm.searchFilter.userDrawingLocs = [];
        $rootScope.$broadcast('DRAWINGTOOLS.CLEAR');
      }
    }

    function innerRadiusChanged() {
      if(vm.searchFilter.radius1 != '-1') {
        vm.searchFilter.customRadius1 = null;
      }
      vm.outerRadiusOptions = filterRadiusOptions(vm.radiusOptions,vm.searchFilter);
      vm.searchFilter.radius2 = null;
      vm.searchFilter.customRadius2 = null;
    }

    function filterRadiusOptions(options, input) {      
      if(input.radius1) {
        var max = _.max([input.radius1, input.customRadius1]);
        return _.filter(options, function(o) {
          return o.id > max || o.id == -1 || o.id == null;
        });
      }
      return options;
    }

    function disableOuterRadius() {
      if(vm.defaultSelections.radiusOnly == false) return true;
      if(vm.searchFilter.radius1 == '-1' && (_.isNil(vm.searchFilter.customRadius1) || vm.searchFilter.customRadius1 == "" || vm.searchFilter.customRadius1 == "30")) return true;
      if(!parseInt(vm.searchFilter.radius1) || parseInt(vm.searchFilter.radius1) == 30) return true;
      return false;
    }

    function customOuterRadiusMinValue(innerRadiusValue) {
      var value = _.toNumber(innerRadiusValue);
      value = value + 0.01;
      return value;
    }

    function resetDriveTime() {
      if (!vm.defaultSelections.driveTimeOnly && vm.searchFilter.driveTimeInfo) {
        if (vm.searchFilter.driveTimeInfo.drive1) {
          vm.searchFilter.driveTimeInfo.drive1.breakValue = null;
          vm.searchFilter.driveTimeInfo.drive1.trafficFlow = null;
        }
        if (vm.searchFilter.driveTimeInfo.drive2) {
          vm.searchFilter.driveTimeInfo.drive2.breakValue = null;
          vm.searchFilter.driveTimeInfo.drive2.trafficFlow = null;
        }
      } else if (vm.defaultSelections.driveTimeOnly && vm.defaultSelections.radiusOnly) {
        vm.defaultSelections.radiusOnly = false;
        vm.resetRadii();
      }
    }
      
    function driveTimeOneBreakValueChanged() {
      vm.searchFilter.driveTimeInfo.drive1.breakValue = (vm.drive1BreakValue.dataItem()).id;
      if (!vm.searchFilter.driveTimeInfo.drive1.breakValue) {
        vm.searchFilter.driveTimeInfo.drive1.trafficFlow = null;
        vm.searchFilter.driveTimeInfo.drive2.breakValue = null;
        vm.searchFilter.driveTimeInfo.drive2.trafficFlow = null;
        return;
      }

      if (!vm.searchFilter.driveTimeInfo.drive1.trafficFlow)
        vm.searchFilter.driveTimeInfo.drive1.trafficFlow = "Low";

      // only null out DT2 if the breakvalues are same for DT1 and DT2
      if (hasSameSelectionForDT1AndDT2()) {
        vm.searchFilter.driveTimeInfo.drive2.breakValue = null;
        vm.searchFilter.driveTimeInfo.drive2.trafficFlow = null;
      } else {
        drive2TrafficFlowDataSourceReset(true, vm.searchFilter.driveTimeInfo);
      }
    }

    function driveTimeOneTrafficFlowChanged() {
      vm.searchFilter.driveTimeInfo.drive1.trafficFlow = (vm.drive1TrafficFlow.dataItem()).id;

      // only null out DT2 if the breakvalues are same for DT1 and DT2
      if (hasSameSelectionForDT1AndDT2()) {
        vm.searchFilter.driveTimeInfo.drive2.breakValue = null;
        vm.searchFilter.driveTimeInfo.drive2.trafficFlow = null;
      } else {
        drive2TrafficFlowDataSourceReset(true, vm.searchFilter.driveTimeInfo);
      }
    }

    function driveTimeTwoBreakValueChanged() {
      vm.searchFilter.driveTimeInfo.drive2.breakValue = (vm.drive2BreakValue.dataItem()).id;
      if (!vm.searchFilter.driveTimeInfo.drive2.breakValue) {
        drive2TrafficFlowDataSourceReset(false, vm.searchFilter.driveTimeInfo);
        vm.searchFilter.driveTimeInfo.drive2.trafficFlow = null;
      } else {
        var lastSelection = vm.searchFilter.driveTimeInfo.drive2.trafficFlow;
        var hasSameSelection = hasSameSelectionForDT1AndDT2();
        var lastValue = drive2TrafficFlowDataSourceReset(true, vm.searchFilter.driveTimeInfo);

        if (!lastSelection && !hasSameSelection) {
          if (lastValue == "Low") {
            vm.searchFilter.driveTimeInfo.drive2.trafficFlow = "Low";
          } else {
            vm.searchFilter.driveTimeInfo.drive2.trafficFlow = lastValue;
          }
        } else if (!lastSelection && hasSameSelection) {
          // i dont think this case is possible
          vm.searchFilter.driveTimeInfo.drive2.trafficFlow = lastValue;
        } else if (lastSelection && !hasSameSelection) {
          vm.searchFilter.driveTimeInfo.drive2.trafficFlow = lastSelection;
        } else if (lastSelection && hasSameSelection) {
          vm.searchFilter.driveTimeInfo.drive2.trafficFlow = lastValue;
        } else {
          /*
            reset drive2 trafficflow, if
            1) it was null and does not have same selection than reset to "Low"
            2) it was null and does have the same selection than reset to last most available -- not possible
            3) it was not null and does not have same selection than do not reset
            4) it was not null and does have same selection than reset to last most available
          */
        }
      }
    }

    function driveTimeTwoTrafficFlowChanged() {
      vm.searchFilter.driveTimeInfo.drive2.trafficFlow = (vm.drive2TrafficFlow.dataItem()).id;
    }

    function drive2TrafficFlowDataSourceReset(applyCondition, driveTimeInfo) {
      var lastValue;
      var sameBreakValue = false, drive1TrafficFlow = null;
      if (driveTimeInfo && driveTimeInfo.drive1) {
        drive1TrafficFlow = driveTimeInfo.drive1.trafficFlow;
        if (driveTimeInfo.drive2 && driveTimeInfo.drive1.breakValue == driveTimeInfo.drive2.breakValue) {
          sameBreakValue = true;
        }
      }
      _.forEach(drive2TrafficFlowDataSource, function (obj) {
        if (!applyCondition) {
          obj.visible = true;
          lastValue = obj.id;
        } else {
          if (sameBreakValue && obj.id == drive1TrafficFlow)
            obj.visible = false;
          else {
            obj.visible = true;
            lastValue = obj.id;
          }
        }
      });
      vm.drive2TrafficFlowOptions.resetFilters();
      vm.drive2TrafficFlowOptions.dataSource.read();
      return lastValue;
    }

    function setDriveTimeLabelColor() {
      if (vm.searchFilter.driveTimeInfo) {
        if (vm.searchFilter.driveTimeInfo['drive1'])
          vm.driveTimeLabelColor.drive1 = GeoDataService.getColors(vm.searchFilter.driveTimeInfo['drive1'].trafficFlow).strokeColor;
        else
          vm.driveTimeLabelColor.drive1 = GeoDataService.getColors().strokeColor;
          
        if (vm.searchFilter.driveTimeInfo['drive2'])
          vm.driveTimeLabelColor.drive2 = GeoDataService.getColors(vm.searchFilter.driveTimeInfo['drive2'].trafficFlow).strokeColor;
        else
          vm.driveTimeLabelColor.drive2 = GeoDataService.getColors().strokeColor;
      } else {
        vm.driveTimeLabelColor.drive1 = GeoDataService.getColors().strokeColor;
        vm.driveTimeLabelColor.drive2 = GeoDataService.getColors().strokeColor;
      }
    }

    function hasSameSelectionForDT1AndDT2() {
      var tempDriveTimeInfo = vm.searchFilter.driveTimeInfo;
      if (!(tempDriveTimeInfo && tempDriveTimeInfo.drive1 && tempDriveTimeInfo.drive2))
        return false;

      var hasSameBreakValue = false;
      if (tempDriveTimeInfo.drive1.breakValue && tempDriveTimeInfo.drive2.breakValue &&
        tempDriveTimeInfo.drive1.breakValue == tempDriveTimeInfo.drive2.breakValue)
        hasSameBreakValue = true;

      var hasSameTrafficFlow = false;
      if (hasSameBreakValue && tempDriveTimeInfo.drive1.trafficFlow && tempDriveTimeInfo.drive2.trafficFlow &&
        tempDriveTimeInfo.drive1.trafficFlow == tempDriveTimeInfo.drive2.trafficFlow)
        hasSameTrafficFlow = true;

      return hasSameBreakValue && hasSameTrafficFlow;
    }
  }
})();
